import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';

// Redux
import { connect } from 'react-redux';
import * as actions from './actions';
import * as commons from 'utils/UtilityStore/actions';

// Components
import BodyContainer from 'components/lib/BodyContainer';
import Icon from 'components/lib/Icon';
import SectionWrapper from 'components/SectionWrapper';
import SetThemeColor from 'components/SetThemeColor';

// Features
import UpdateClient from 'features/UpdateClient';

export class PublicApp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchCompany(this.props.match.params.hash);
  }

  render() {
    const company = this.props.company || {
      meta: { themeColor: null },
    };

    const feedbackCSSClass = `feedback-container${
      this.state.isSuccess === true ? ' is-success' : ''
      // }${
      //   this.state.isSuccess === false ? ' is-danger' : ''
    }${this.props.isUnauthorized === true ? ' is-unauthorized' : ''}`;

    return (
      <React.Fragment>
        <SetThemeColor color={company.meta.themeColor} />

        <div style={{ backgroundColor: company.meta.themeColor, height: 20 }} />

        <BodyContainer isLoading={this.props.isWorking['publicCompany']} style={{ padding: 0, height: 'calc(100vh - 30px)' }}>
          <div className={feedbackCSSClass} style={{ margin: 0 }}>
            <div className="feedback-container-success">
              <Icon name="fas fa-shield-check" />
              <br />
              <span>Formulário enviado com sucesso!</span>
            </div>
            <div className="feedback-container-danger">
              <Icon name="fas fa-exclamation-triangle" />
              <br />
              <span>
                Ocorreu um erro ao enviar o formulário!
                <br />
                Tente novamente mais tarde.
              </span>
            </div>
          </div>

          <SectionWrapper style={{ maxWidth: 768, margin: 'auto' }}>
            <div className="public-app-header" style={{ borderColor: company.meta.themeColor }}>
              {company.name || '...'}
            </div>
          </SectionWrapper>

          <SectionWrapper header="Formulário de pré-cadastro para locação de imóveis" style={{ maxWidth: 768, margin: 'auto', padding: 20 }}>
            <UpdateClient apikey={this.props.match.params.hash} onSuccess={this.handleFormSuccess.bind(this)} />
          </SectionWrapper>
        </BodyContainer>

        <div style={{ backgroundColor: company.meta.themeColor, height: 10 }} />
      </React.Fragment>
    );
  }

  handleFormSuccess(response) {
    this.setState({
      isSuccess: response.isSuccess,
    });
  }
}

PublicApp.defaultProps = {};

PublicApp.propTypes = {};

export default connect(
  (store) => ({
    company: store.public.company,
    isSuccess: store.public.isSuccess,
    isUnauthorized: store.public.isUnauthorized,
    isWorking: store.commons.isWorking,
  }),
  { ...actions, ...commons }
)(PublicApp);
