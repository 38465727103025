import React from 'react'

import EmptyState from 'components/EmptyState'
import Tooltip from 'components/lib/Tooltip'
import Table from 'components/lib/Table'
import Icon from 'components/lib/Icon'

import withFilter from 'components/HOCs/dashboardWIthFilter'
import ListEmpty from './ListEmpty'
import FlexBar from 'components/lib/FlexBar'
import Pagination from 'components/lib/Pagination'
import Space from 'components/lib/Space'

const colors = {
  STATUS_FINISHED: 'has-grey',
  STATUS_ARCHIVED: 'has-grey',
  STATUS_NEW: 'has-yellow',
  STATUS_REFUSED: 'has-red',
  STATUS_UNDER_ANALYSIS: 'has-yellow',
  STATUS_ONGOING_PROCESS: 'has-green',
  STATUS_CONTRACT_READY: 'has-green',
  STATUS_HANDOVER_KEYS: 'has-yellow',
}

const List = ({ hideAgent, ...props }) => {
  props.createFilterRequest(props.onSearch)

  const renderType = (type) => {
    let text = ''

    if (type === 'TYPE_SALE') {
      text = 'Venda'
    } else if (type === 'TYPE_RENTAL') {
      text = 'Locação'
    } else if (type === 'TYPE_ACQUISITION') {
      text = 'Captação'
    }

    return text
  }

  const renderAddress = (content, row) => {
    const { properties, temporary_property } = row
    const [mainProperty] = properties

    let fullAddressText = ''

    if (mainProperty) {
      fullAddressText = mainProperty?.address
        ? buildAddressString(mainProperty)
        : null
    } else if (temporary_property) {
      fullAddressText = temporary_property?.address
        ? buildAddressString(temporary_property)
        : null
    }

    return (
      <div style={{ textAlign: 'left' }}>
        {mainProperty?.code && (
          <>
            <span>Codigo: </span>
            <strong>{mainProperty.code}</strong>
          </>
        )}

        {fullAddressText ? <p>{fullAddressText}</p> : ''}
      </div>
    )
  }

  const buildAddressString = (mainProperty) => {
    const { address } = mainProperty

    const { address: street, additional_address, number } = address

    let fullAddress = []

    if (street) fullAddress.push(street)
    if (number) fullAddress.push(number)
    if (additional_address) fullAddress.push(additional_address)

    fullAddress = fullAddress.join(', ')

    return fullAddress
  }

  const isAcquisition = window.location.pathname.includes('property-capture')
  const isRental = window.location.pathname.includes('rentals')
  const isSale = window.location.pathname.includes('sales')

  let middleCells = []

  if (isAcquisition) {
    middleCells = [
      {
        name: 'Proprietário(s)',
        width: '25%',
        render: (content, row) => (
          <div className='tags'>
            {row.property_owners &&
              row.property_owners.map((item) => (
                <span className='tag' key={item.id}>
                  {item.name}
                </span>
              ))}
          </div>
        ),
      },
    ]
  } else if (isRental || isSale) {
    if (isRental) {
      middleCells.push({
        name: 'Garantia',
        render: (content, row) => row.guarantee && row.guarantee.name,
      })
    }

    middleCells.push({
      name: 'Cliente(s)',
      width: '25%',
      render: (content, row) => (
        <div className='tags'>
          {row.clients &&
            row.clients.map((item) => (
              <span className='tag' key={item.id}>
                {item.main && (
                  <>
                    <Icon name='fas fa-xs fa-crown' />
                    &nbsp;
                  </>
                )}{' '}
                {item.cnpj ? item.corporate_name : item.name}
              </span>
            ))}
        </div>
      ),
    })
  }

  const cells = [
    {
      name: 'Código',
      path: 'code',
      shrink: true,
      render: (content) => <strong>#{content}</strong>,
    },
    {
      name: 'Início',
      path: 'created_at',
      align: 'center',
      render: (content, row) => {
        const date = new Date(row.created_at.replace(/\s/, 'T') + 'Z')
        const year = date.getFullYear()
        const rawMonth = date.getMonth() + 1
        const rawDay = date.getDate()
        const month = rawMonth < 10 ? `0${rawMonth}` : rawMonth
        const day = rawDay < 10 ? `0${rawDay}` : rawDay
        const formattedDate = `${day}/${month}/${year}`

        return formattedDate
      },
    },
    {
      name: 'Identificação',
      align: 'center',
      render: renderAddress,
    },
    {
      name: 'Responsável',
      align: 'center',
      render: (content, row) => {
        if (row && row.responsible_user) {
          return (
            <strong>
              {row.responsible_user.name || row.responsible_user.email}
            </strong>
          )
        }

        return 'Sistema'
      },
    },
    ...middleCells,
    {
      name: 'Situação',
      align: 'center',
      shrink: true,
      render: (content, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {row.has_documents_in_analysis && (
            <Tooltip description='Esse processo contém documentos pendentes'>
              <Icon
                name='far fa-file-exclamation'
                color=''
                style={{
                  marginRight: 4,
                }}
              />
            </Tooltip>
          )}

          <span className={`tag ${colors[row.status.code]}`}>{row.status.name}</span>
        </div>
      ),
    },
  ]

  return (
    <>
      {props.isWorking && (
        <div>
          <div style={{ height: '250px', marginTop: 100 }}>
            <div
              style={{ margin: '0 auto', padding: '45px 60px' }}
              className='empty-state-spinner'
            />
          </div>
        </div>
      )}

      {props.items.length > 0 && !props.isWorking && (
        <>
          <Table
            lastOrder={props.lastOrder}
            orderDirection={props.orderDirection}
            onSort={props.orderBy}
            items={props.items}
            isUpdatingRows={props.isWorking}
            onClickRow={props.openProcess}
            cells={cells}
            emptyState={<EmptyState isLoading={props.isWorking} />}
          />

          <Space />

          <FlexBar>
            <FlexBar.Child isGrow>
              <Pagination
                current={props.pagination.currentPage}
                total={props.pagination.total}
                itemsPerPage={props.pagination.perPage}
                onChange={props.handlePagination}
              />
            </FlexBar.Child>
          </FlexBar>
        </>
      )}

      {props.items.length == 0 && !props.isWorking && (
        <ListEmpty createNewProposal={props.createNewProposal} />
      )}
    </>
  )
}

export default withFilter(List)
