import React from 'react';

import { AddButtonWrapper, AddButtonText } from './styles';

const AddButton = ({ onClick }) => {
  return (
    <AddButtonWrapper onClick={onClick}>
      <AddButtonText>Criar novo</AddButtonText>
    </AddButtonWrapper>
  );
};

export default AddButton;
