import { request } from 'core/constants';
import { requestState, notification } from 'utils/UtilityStore/actions';

export const fetch = (filter, path) => (dispatch, store) => {
  if(path.proposalId){
    path = `/proposal/${path.proposalId}/activity-log`;
  } else{
    path = '/activity-log';
  }
 
  dispatch(requestState(true, 'logs'));
  request.get('{company}' + path, { filter: filter })
    .then((response) => {
      dispatch(requestState(false, 'logs'));
      dispatch({
        type: 'FETCH_LOGS',
        payload: response.data,
        listInfo: response.resultsInfo
      });
    })
    .catch((error) => {});
};

