import React from 'react'

import Fichacerta from '../../../../services/integrations/components/Fichacerta'

class Analysis extends React.Component {
  render() {
    let companyId = this.props.activeCompany.id

    return <Fichacerta props={this.props} companyId={companyId} />
  }
}

export default Analysis
