import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
`;

export const Text = styled.span`
  font-size: 17px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  gap: 14px;
`;

export const Button = styled.button`
  align-self: center;

  margin: 4px 0;
  padding: 9px 32px;

  font-size: 17px;
  color: white;

  background-color: #2091ea;

  border: none;
  border-radius: 4px;

  cursor: pointer;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.95;
  }
`;
