import styled from 'styled-components'

export const ListMenu = styled.ul`
  list-style: none;
  display: flex;
  gap: 10px;
`
export const Menu = styled.li`
  padding: 5px 10px;
  border-bottom: 4px solid #f2f6fa;
  cursor: pointer;
  font-size: 14px;
  &.active,
  &:hover {
    border-bottom: 4px solid #2091ea;
    font-weight: 500;
  }
`
