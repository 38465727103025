import React from 'react';
import { connect } from 'react-redux';

// Components
import { FormFieldText } from 'components/lib/FormFields';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import FlexBar from 'components/lib/FlexBar';

import formCreator from 'features/components/formCreator';
import { fieldProps } from 'features/components/formCreator';
import { copyToClipboard } from 'utils/utils';

// Utils
import * as commons from 'utils/UtilityStore/actions';

export class AutentiqueIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
    this.toggleHidden = this.toggleHidden.bind(this);
    this.saveAndSubmit = this.saveAndSubmit.bind(this);
  }

  toggleHidden() {
    const { isEditing } = this.state;
    this.setState({
      isEditing: !isEditing,
    });
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  saveAndSubmit() {
    this.props.submitForm();
    return this.toggleHidden();
  }

  render() {
    const { formIsWorking, formIsReady, hideSubmitButton } = this.props;
    const { isEditing } = this.state;

    const autentiqueUrl = fieldProps(this.props, 'autentique_url');
    const url = autentiqueUrl.valueDefault;

    return (
      <div>
        {/*
        <FlexBar>
          <FlexBar.Child isGrow>
            <FormFieldText
              valueDefault={url}
              label="URL de Callback"
              info="Copie este link para adicionar nas configurações do site da Autentique"
              isHorizontal
              isDisabled
              isFullWidth
              isFlat
            />
          </FlexBar.Child>
          <FlexBar.Child>
            <Button
              onClick={this.handleURLCopy.bind(this, url)}
              isFlat
              title="Copiar URL"
              isTab>
              <Icon name="fas fa-clipboard" />
            </Button>
          </FlexBar.Child>
        </FlexBar>
        */}
        <FlexBar>
          <FlexBar.Child isGrow>
            <FormFieldText {...fieldProps(this.props, 'autentique_token')} label="Token" isDisabled={!isEditing} isHorizontal isFullWidth isFlat />
          </FlexBar.Child>
          <FlexBar.Child>
            {!isEditing ? (
              <Button hasThemeColor onClick={this.toggleHidden} isHorizontal isFlat>
                Editar
              </Button>
            ) : (
              <Button hasThemeColor isLoading={formIsWorking} onClick={this.saveAndSubmit} isDisabled={!formIsReady || formIsWorking} isHorizontal isFlat>
                Salvar
              </Button>
            )}
          </FlexBar.Child>
        </FlexBar>
      </div>
    );
  }

  createRequest(fields) {
    this.props.submit('{company}/settings', fields, 'put');
  }

  handleURLCopy(url) {
    copyToClipboard(url);
    this.props.notification({
      message: 'URL copiado para área de transferência',
    });
  }
}

export default connect((store) => ({}), { ...commons })(formCreator(AutentiqueIntegration));
