import { request } from 'core/constants';

import { notification } from 'utils/UtilityStore/actions';

export const updateCompanyMeta = (companyId, data) => async (dispatch) => {
  await request
    .put(`company/${companyId}`, data)
    .then((response) => {
      dispatch(
        notification({
          message: 'Lista de documentos salva com sucesso!',
        })
      );

      dispatch({
        type: 'APP_UPDATE_ACTIVE_COMPANY',
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log('Error: DocumentListModal, failed to save document list');

      dispatch(
        notification({
          message: 'Erro ao tentar alterar a lista de documentos, tente novamente!',
          status: 'danger',
        })
      );

      dispatch({
        type: 'APP_UPDATE_ACTIVE_COMPANY_ERROR',
        payload: error,
      });
    });
};
