import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Tooltip from 'components/lib/Tooltip';

import _propsObject from './propsObject';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Switch = (props) => {
  const switchCSSClass = `switch ${
    parsePropsAsBulmaClasses(props)
  }`;

  const tooltip = (
    props.info ? (
      <Tooltip description={props.info}>
        &nbsp;<Button isBare tabIndex={-1}><Icon name="fas fa-question-circle fa-xs" isInfo isSmall /></Button>
      </Tooltip>
    ) : null
  );

  const loading = (
    props.isLoading ? (
      <React.Fragment>
        &nbsp;<span className="loader" />
      </React.Fragment>
    ) : null
  );

  return (
    <label htmlFor={props.id || props.name} className={switchCSSClass} title={props.title}>
      <input
        {..._propsObject(props)}
      />
      {props.description ? <span className="switch-description">{props.description}{tooltip}{loading}</span> : null}
      <span className="switch-slider round" />
    </label>
  );
};

Switch.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

export const propsObject = _propsObject;

export default Switch;
