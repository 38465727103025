import React from 'react'

import { connect } from 'react-redux'

import GrupoRVLogo from '../../assets/svgs/GrupoRVLogo'

import { Container, Name } from './styles'

const AppLogo = (props) => {
  const {
    companyMeta,
    hashData,
    canCustomize,
    isApp,
    name,
    responsive = false,
    ...rest
  } = props

  let logo = <GrupoRVLogo />

  if (!isApp || canCustomize) {
    const appLogo =
      companyMeta?.logo ||
      rest.companyInfo?.companyMeta?.logo ||
      hashData?.company?.meta?.logo

    if (appLogo) {
      logo = (
        <figure className='image is-brand' role='button' style={{ height: 52 }}>
          <img src={appLogo} alt='Izee' style={{ height: 52 }} />
        </figure>
      )
    }
  }

  return (
    <Container responsive={responsive}>
      {logo}
      {(canCustomize || !isApp) && <Name>{name}</Name>}
    </Container>
  )
}

export default connect((state) => {
  const subscriptionPlan = state.app.activeCompany?.subscription_plan
  const canCustomize = subscriptionPlan ? subscriptionPlan.can_customize_app : true

  return {
    isApp: !!state.app.activeCompany,
    companyMeta: state.app.companyMeta,
    hashData: state.app.hashData,
    canCustomize,
  }
}, {})(AppLogo)
