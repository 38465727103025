import React from "react";

// Components
import {
  FormFieldText,
  FormFieldSwitch
} from "components/lib/FormFields";
import { fieldProps } from "../formCreator";
import Grid from "components/lib/Grid";

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeJuridical: props.defaultValue.cnpj ? true : false
    };
  }
  handleCompanyType(value) {
    this.setState({ typeJuridical: !value });
    if (value) {
      this.props.updateFieldValues([
        { fieldName: "cnpj", field: { value: "", isValid: true } },
        { fieldName: "trading_name", field: { value: "", isValid: true } },
        { fieldName: "state_enrollment", field: { value: "", isValid: true } },
        {
          fieldName: "municipal_enrollment",
          field: { value: "", isValid: true }
        }
      ]);
    } else {
      this.props.updateFieldValues([
        { fieldName: "cpf", field: { value: "", isValid: true } }
      ]);
    }
  }
  render() {
    const { typeJuridical } = this.state;
    const props = this.props;
    const { fields } = props;
    const disabledFields = (this.props.defaultValue?.cnpj ||
      this.props.defaultValue?.cpf) &&
      this.props.activeUser?.role != 'ROLE_IZEE'

    return (
      <React.Fragment>
        {!disabledFields ? (
          <FormFieldSwitch
            checked={typeJuridical}
            onClick={() => this.handleCompanyType(typeJuridical)}
            description={typeJuridical ? 'Pessoa Jurídica' : 'Pessoa Física'}
            type="checkbox"
          />
        ) : null}

        {typeJuridical ? (
          <React.Fragment>
            <Grid>
              <Grid.Col>
                <FormFieldText
                  {...fieldProps(props, "name")}
                  label="Nome da empresa"
                />
              </Grid.Col>
              <Grid.Col isOneThird>
                <FormFieldText
                  {...fieldProps(props, "cnpj")}
                  disabled={disabledFields}
                  label="CNPJ"
                  mask={["cnpj"]}
                  noValidate={fields.noValidate}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col>
                <FormFieldText
                  {...fieldProps(props, "trading_name")}
                  label="Razão Social"
                  noValidate
                />
              </Grid.Col>
              <Grid.Col>
                <FormFieldText
                  {...fieldProps(props, "municipal_enrollment")}
                  label="Inscrição Municipal"
                  noValidate
                />
              </Grid.Col>
              <Grid.Col>
                <FormFieldText
                  {...fieldProps(props, "state_enrollment")}
                  label="Inscrição Estadual"
                  noValidate
                />
              </Grid.Col>
              <Grid.Col isNarrow>
                <FormFieldText
                  {...fieldProps(props, "prefix")}
                  label="Prefixo"
                  info="Este código aparece antes do ID da proposta"
                  mask={[{ maxLength: 3 }]}
                  validator={[{ minLength: 2 }]}
                  style={{ maxWidth: 58 }}
                  noValidate
                />
              </Grid.Col>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid>
              <Grid.Col>
                <FormFieldText
                  {...fieldProps(props, "name")}
                  label="Nome da empresa"
                />
              </Grid.Col>
              <Grid.Col isOneThird>
                <FormFieldText
                  {...fieldProps(props, "cpf")}
                  disabled={disabledFields}
                  label="CPF"
                  mask={["cpf"]}
                  noValidate={fields.noValidate}
                />
              </Grid.Col>
              <Grid.Col isNarrow>
                <FormFieldText
                  {...fieldProps(props, "prefix")}
                  label="Prefixo"
                  info="Este código aparece antes do ID da proposta"
                  mask={[{ maxLength: 3 }]}
                  validator={[{ minLength: 2 }]}
                  style={{ maxWidth: 58 }}
                  noValidate
                />
              </Grid.Col>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Company;
