import React from 'react';

import moment from 'moment';

// Components
import SubmitButton from 'components/SubmitButton';

import formCreator from '../components/formCreator';
import ClientData from '../components/fieldsGroup/ClientData';
import FullAddress from '../components/fieldsGroup/FullAddress';
import PropertySelection from '../components/fieldsGroup/PropertySelection';

// Utils
import { goodObject, rawNumber } from 'utils/utils';

export class UpdateClient extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>

        <ClientData {...this.props} />

        {/* <FullAddress {...this.props} noValidate /> */}

        <SubmitButton
          label="Enviar pré-cadastro"
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {
    let goodFields = goodObject(fields, {
      'birth_date': {
        path: 'birth_date',
        format: (value) => moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      },
      'identity_expedition_date': {
        path: 'identity_expedition_date',
        format: (value) => moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      },
      'income': {
        path: 'income',
        format: (value) => rawNumber(value)
      },
      'estimated_rent_value': {
        path: 'estimated_rent_value',
        format: (value) => rawNumber(value)
      }
    });
    this.props.submit('public/client', goodFields, 'post', {
      headers: { Authorization: 'Bearer ' + this.props.apikey }
    });
  }

};

export default formCreator(UpdateClient);
