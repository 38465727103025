import React from 'react';
import { request } from 'core/constants';
// Components

import Button from 'components/lib/Button';
import formCreator from '../components/formCreator';
import moment from 'moment'

export class UpdateContractRegistrationDataConfirmation extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { manually_updated_at, formIsWorking, formIsReady } = this.props;

    const dateAndTime = manually_updated_at.split(' ')
    const date = dateAndTime[0]
    const time = dateAndTime[1]
    const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    const manuallyUpdatedDate = `${formattedDate} às ${time}`

    return (
      <React.Fragment>
        <div>
          <p>O Contrato Atual será totalmente reescrito atualizando todos os dados da Proposta e de Cadastros.</p><br/>
          <p>Verificamos que o Contrato Atual foi editado manualmente para essa Proposta em {manuallyUpdatedDate}.</p><br/>
          <p>Após a atualização certifique-se que alterações manuais feitas, como inclusão de cláusulas especiais por exemplo, sejam mantidas.</p><br/>
        </div>
        <div className="text-center">

        <Button style={{ marginRight: 30 }}
         isSuccess
         isLoading={formIsWorking}
         isDisabled={!formIsReady || formIsWorking}
         submit>Sim</Button>
        
        <Button isDanger onClick={this.props.onClose.bind(this)}>Não</Button>

        </div>

      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.loading()
    this.props.onClose()
    request
      .post(`{company}/process/${this.props.processId}/generate-contract`)
      .then((response) => {
        this.props.unloading()
        this.props.onSuccess({ isSuccess: true });
      })
      .catch((error) => {
        this.props.unloading()
        this.props.onSuccess({ isSuccess: false, message: error.message });
      });
  }

};


export default formCreator(UpdateContractRegistrationDataConfirmation);
