import React from "react";
import PropTypes from "prop-types";

import parsePropsAsBulmaClasses from "components/lib/_utils/parsePropsAsBulmaClasses";

const Title = props => {
  const titleCSSClass = `title ${parsePropsAsBulmaClasses(props)}`;

  let styleRight = null;
  if (props.makeRight) {
    styleRight = {
      display: "flex",
      flexDirection: "row-reverse"
    };
  }

  const component = <h1 className={titleCSSClass}>{props.children}</h1>;

  if (props.isH1)
    return (
      <h1 style={props.makeRight ? styleRight : null} className={titleCSSClass}>
        {props.children}
      </h1>
    );
  if (props.isH2) return <h2 className={titleCSSClass}>{props.children}</h2>;
  if (props.isH3) return <h3 className={titleCSSClass}>{props.children}</h3>;
  if (props.isH4) return <h4 className={titleCSSClass}>{props.children}</h4>;
  if (props.isH5) return <h5 className={titleCSSClass}>{props.children}</h5>;
  if (props.isH6) return <h6 className={titleCSSClass}>{props.children}</h6>;

  return component;
};

Title.defaultProps = {
  children: null
};

Title.propTypes = {
  children: PropTypes.any
};

export default Title;
