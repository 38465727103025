import React from 'react'
import Text from 'components/lib/Text'

const Administration = ({ children }) => (
  <Text>
    <span style={{ whiteSpace: 'pre-line' }}>{children}</span>
  </Text>
)

export default Administration
