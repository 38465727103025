import React, { useState, useRef } from 'react';

import { request } from 'core/constants';

import { FormFieldSwitch, FormFieldText } from 'components/lib/FormFields';
import withRedux from 'components/redux';

import {
  Container,
  SwitchLabel,
  SwitchContainer,
  InputLabel,
  Button,
} from './styles';

const Form = (props) => {
  const {
    useTerms,
    privacyPolitics,
    updateActiveCompany,
    setCreatedCompanyId,
    onSubmit,
  } = props;

  const nameRef = useRef(null);
  const documentRef = useRef(null);

  const [personType, setPersonType] = useState('fisica');
  const [nameFieldError, setNameFieldError] = useState('');
  const [documentFieldError, setDocumentFieldError] = useState('');

  const isPhysical = personType === 'fisica';

  const handlePersonType = () => {
    setPersonType(isPhysical ? 'juridica' : 'fisica');
  };

  const handleSubmit = async () => {
    setNameFieldError('');
    setDocumentFieldError('');

    try {
      const payload = {
        name: nameRef.current,
        [isPhysical ? 'cpf' : 'cnpj']: documentRef.current,
      };

      const { data } = await request.post('company', payload);

      onSubmit();

      setCreatedCompanyId(data.id);
      updateActiveCompany(data);
    } catch (err) {
      err.errors.forEach((error) => {
        if (error.field === 'name') {
          setNameFieldError(error.message);
        }
        if (isPhysical && error.field === 'cpf') {
          setDocumentFieldError(error.message);
        }
        if (!isPhysical && error.field === 'cnpj') {
          setDocumentFieldError(error.message);
        }
      });
    }
  };

  return (
    <>
      <Container>
        <SwitchContainer>
          <FormFieldSwitch
            defaultChecked={!personType}
            onClick={() => handlePersonType()}
          />

          <SwitchLabel>
            {isPhysical ? 'Pessoa física' : 'Pessoa jurídica'}
          </SwitchLabel>
        </SwitchContainer>

        <InputLabel>Nome da empresa</InputLabel>
        <FormFieldText
          value={nameRef.current}
          onChange={(v) => (nameRef.current = v)}
          placeholder="Nome da empresa"
          style={{ width: 300 }}
          errorMessage={nameFieldError}
          forceInvalid={!!nameFieldError}
        />

        <InputLabel>{isPhysical ? 'CPF' : 'CNPJ'}</InputLabel>
        <FormFieldText
          value={documentRef.current}
          onChange={(v) => (documentRef.current = v)}
          placeholder={isPhysical ? '000.000.000.00' : '00.000.000/0000-00'}
          mask={isPhysical ? ['cpf'] : ['cnpj']}
          style={{ width: 300 }}
          errorMessage={documentFieldError}
          forceInvalid={!!documentFieldError}
        />
      </Container>

      <Button onClick={handleSubmit} disabled={!(useTerms && privacyPolitics)}>
        Concluir
      </Button>
    </>
  );
};

export default withRedux(() => ({}), {})(Form);
