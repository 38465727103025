import React from 'react';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Space = (props) => {
  const spaceCSSClass = `space ${
    parsePropsAsBulmaClasses(props)
  }${
    props.isHorizontal ? ' is-horizontal' : ' is-vertical'
  }`;
  return (<div className={spaceCSSClass} />);
};

Space.propTypes = {};

export default Space;
