import request from 'axios'

const withToken = async (url, body) => {
  try {
    return new Promise((resolve, reject) => {
      request
        .post(url, body)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  } catch (error) {
    throw new Error('withToken error: ' + error)
  }
}

const url = INTEGRATIONS_API_URL
// const url = 'http://localhost:5001/izee-development/us-central1/integrations'; // local
// const url = 'https://us-central1-izee-development.cloudfunctions.net/integrations';

export default {
  enable: async (body) => {
    try {
      const response = await withToken(`${url}/enable`, body)

      return response
    } catch (error) {
      throw new Error(error)
    }
  },

  list: async (body) => {
    try {
      const response = await withToken(`${url}/list`, body)

      return response
    } catch (error) {
      throw new Error(error)
    }
  },
  apps: async (body) => {
    try {
      const response = await withToken(`${url}/apps`, body)

      return response
    } catch (error) {
      throw new Error(error)
    }
  },
}
