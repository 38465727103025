import React, { useState, memo } from 'react';
import ReactQuill from 'react-quill';
import Icon from 'components/lib/Icon';
import Tooltip from 'components/lib/Tooltip';
import Button from 'components/lib/Button';
import fieldCreator from '../fieldCreator';
import { addList, addCondition, addToken, insertBasicText } from './AddBlotMethods';

const Help = ({ margin, message }) => (
  <div style={{ display: 'inline', marginLeft: margin || 10 }}>
    <Tooltip description={message}>
      <Button isBare tabIndex={-1}>
        <Icon name="fas fa-question-circle fa-xs" isInfo />
      </Button>
    </Tooltip>
  </div>
);

const IndependentItem = ({ opt, type, editorRef, filter, shouldInsertRawValue }) => {
  const [show, toggle] = useState(false);
  const { has_conditional, has_list } = opt;
  const isEmpty = !Boolean(opt?.variables?.length);
  const isConditional = type === 'conditional' && has_conditional;
  const listTypeSelected = type === 'repeatable';
  const isRepeatable = listTypeSelected && has_list;
  const validOption = isConditional || isRepeatable || type === 'normal';
  const shouldRender = Boolean(!isEmpty && validOption);
  const list = opt?.variables?.filter((item) => (!filter ? true : item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1));

  return (
    shouldRender &&
    list?.length > 0 && (
      <>
        <a
          className="panel-block"
          style={{ userSelect: 'none' }}
          onClick={() => {
            if (!listTypeSelected) {
              toggle(!show);
            } else if (isRepeatable) {
              const content = `${opt.name}`;
              const funcParams = [editorRef, opt.name, content, opt];
              addList(...funcParams);
            }
          }}
          key={opt}
        >
          {!listTypeSelected && (
            <span className="panel-icon">
              <i className={`fas fa-xs fa-chevron-${show ? 'down' : 'right'}`} aria-hidden="true"></i>
            </span>
          )}
          <span>
            {!listTypeSelected && (
              <>
                <b>{opt.name}</b> <span style={{ color: 'gray', fontSize: '12px' }}> {list.length} items</span>
              </>
            )}
            {listTypeSelected && opt.name}
          </span>
        </a>
        {(show || filter) &&
          !listTypeSelected &&
          list.map((item) => (
            <a
              className="panel-block"
              style={{ backgroundColor: '#f5f5f5', fontSize: '14px' }}
              onClick={() => {
                const content = `${opt.name}: ${item.name}`;
                const funcParams = [editorRef, item.attribute, content, opt];

                if (isConditional) {
                  addCondition(...funcParams);
                } else if (!shouldInsertRawValue) {
                  addToken(...funcParams);
                } else if (item.value) {
                  insertBasicText(editorRef, item.value);
                }
              }}
              key={item.name}
            >
              <span
                style={{
                  marginLeft: shouldInsertRawValue ? '0px' : '18px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '300px',
                }}
              >
                <span style={{ color: 'gray', marginRight: '5px' }}>●</span> {item.name} {shouldInsertRawValue && <span style={{ color: 'gray', fontSize: '10px' }}>{item.value}</span>}
              </span>
            </a>
          ))}
      </>
    )
  );
};

const TokenSelector = ({ list, info, editorRef, shouldInsertRawValue }) => {
  const [filter, setFilter] = useState();
  const [type, setType] = useState('normal');

  const filteredList = list.map((opt) => <IndependentItem filter={filter} opt={opt} type={type} editorRef={editorRef} shouldInsertRawValue={shouldInsertRawValue} />);
  const emptyMessage = `Nenhum item encontrado para o filtro inserido.`;

  return (
    <article className="token-list-wrapper panel is-primary">
      <p className="panel-heading">
        Variáveis &nbsp;
        {info && <Help margin={1} message={info} />}
      </p>
      <div className="panel-block">
        <p className="control has-icons-left">
          <input className="input is-primary" type="text" placeholder={`Filtrar items`} onChange={(e) => setFilter(e.target.value)} />
          <span className="icon is-left">
            <i className="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div>
      <p className="panel-tabs">
        {!shouldInsertRawValue && (
          <>
            <a style={{ ':hover': { color: 'red' } }} onClick={() => setType('normal')} class={type === 'normal' ? 'is-active' : ''}>
              Dados
              <Help message={'A variável será substituída pelo dado cadastrado na proposta'} />
            </a>
            <a style={{ ':hover': { color: 'red' } }} onClick={() => setType('repeatable')} class={type === 'repeatable' ? 'is-active' : ''}>
              Listas
              <Help message={'Dentro de uma lista, as variáveis serão substituídas pelos dados cadastrados, exibindo todos os cadastros de uma das categorias abaixo'} />
            </a>
            <a style={{ ':hover': { color: 'red' } }} onClick={() => setType('conditional')} class={type === 'conditional' ? 'is-active' : ''}>
              Condicionais
              <Help message={'Dentro de uma condicional o texto só será exibido caso a variável em questão tenha sido preenchida no cadastro'} />
            </a>
          </>
        )}
      </p>
      {filter && !filteredList?.length && (
        <div className="panel-block">
          <center style={{ padding: '25px' }}>{emptyMessage}</center>
        </div>
      )}
      {filteredList}
    </article>
  );
};

export default memo(fieldCreator(TokenSelector, {}));
