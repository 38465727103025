const checkSanitizedItem = (value, check) => {
  if (check) {
    return check(value) ? value : undefined;
  }
  return value;
};

const sanitizeToFloat = (value, check) => {
  if (typeof value !== 'number') {
    if (typeof value !== 'string') {
      return checkSanitizedItem(value, check);
    }

    if (checkSanitizedItem(value, check) === undefined) {
      return undefined;
    }

    if (value.includes('.') && value.includes(',')) {
      value = value.replace(/\./g, '').replace(',', '.');
    } else if (value.includes(',')) {
      value = value.replace(',', '.');
    }
    value = parseFloat(value) || 0;
  }
  return checkSanitizedItem(value, check);
};

const formatValue = (value, decimalScale = 2) => {
  if (!value) {
    value = 0;
  }
  value = sanitizeToFloat(value);
  const strValue = new Intl.NumberFormat('en-US', { minimumFractionDigits: decimalScale, maximumFractionDigits: decimalScale, useGrouping: false }).format(value);

  return strValue.replace('.', ',');
};

module.exports = {
  sanitizeToFloat,
  formatValue,
};
