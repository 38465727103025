import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  inset: 0;
  z-index: 9999999999999999999999;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #0000007a;

  padding: 0px;

  @media (min-width: 600px) {
    padding: 40px;
  }

  @media (min-width: 800px) {
    padding: 80px;
  }
`;

export const Modal = styled.div`
  height: 100%;

  padding: 40px;

  overflow-y: scroll;

  background-color: white;

  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);

  @media (min-width: 600px) {
    max-height: 90%;
  }
`;

export const Button = styled.button`
  align-self: center;

  margin-top: 24px;
  padding: 9px 32px;

  font-size: 17px;
  color: white;

  background-color: #2091ea;

  border: none;
  border-radius: 4px;

  cursor: pointer;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.95;
  }
`;
