import React from 'react';

import { connect } from 'react-redux';
import * as actions from './actions';

// Components
import List from './components/List';

class Logs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      patch: {
        proposalId: this.props.proposalId || null,
      }
    };
  }

  componentDidMount() {
    this.props.fetch(this.state.filter, this.state.patch);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.filter != this.state.filter) {
      this.setState({
        items: this.props.fetch(this.state.filter, this.state.patch)
      })
    }
  }

  render() {
    

    return (
      <React.Fragment>
        <List
          listName="Lista de Atividades"
          items={this.props.items}
          pagination={this.props.listInfo}
          isWorking={this.props.isWorking}
          onChangePage={this.handlePagination.bind(this)}
          isMedium={true}
          onSearch={this.searchList.bind(this)}
        />
      </React.Fragment>
    );
  }

  searchList(keywords) {
    const filter = { ...this.state.filter, ...{ search: keywords } };
    this.setState({ filter: filter });
    this.props.fetch(filter, this.state.patch);
  }

  handlePagination(page) {
    const filter = { ...this.state.filter, ...{ page: page } };
    this.setState({ filter: filter });
    this.props.fetch(filter, this.state.patch);
  }

};

export default connect((store) => ({
  company: store.app.activeCompany,
  companyMeta: store.app.companyMeta,
  updateActiveCompany: store.app.helpers.updateActiveCompany,
  updateCompanyMeta: store.app.helpers.updateCompanyMeta,
  showNotification: store.app.helpers.showNotification,

  items: store.logs.items,
  listInfo: store.logs.listInfo,
  isWorking: store.commons.isWorking.logs
}), { ...actions })(Logs);


