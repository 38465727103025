import { camelCaseToDash } from 'utils/utils';

/**
 * Find bulma classes as react props like isSomething or hasSomething and transform into css classes
 * @param {Object} componentProps
 * @return {String} of classes
 */
export default function parsePropsAsBulmaClasses(componentProps) {
  const props = Object.keys(componentProps);
  const classNames = [];
  props.forEach((propsKey) => {
    if (propsKey.match(/(is|has)([A-Z|0-9]{1})/g) && componentProps[propsKey]) classNames.push(camelCaseToDash(propsKey));
  });
  return classNames.join(' ');
};
