import React, { useEffect, useState } from 'react';
import { baseURL } from 'core/constants';
import request from 'axios';

import Modal from 'components/lib/Modal';
import Button from 'components/lib/Button';
import Table from 'components/lib/Table';
import Header from 'components/lib/Header';
import Pagination from 'components/lib/Pagination';

import CreateSurvey from '../../../../services/integrations/components/RedeVistorias/CreateSurvey';

const redeVistoriasUrl = REDE_VISTORIAS_URL;

const Surveys = (props) => {
  const [modalState, setModalState] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalFeature, setModalFeature] = useState(null);
  const [requestState, setRequestState] = useState('');
  const [appData, setAppData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({});
  const [resultsInfo, setResultsInfo] = useState({});

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      let companyId = props.activeCompany.id;
      request
        .get(
          `${baseURL}company/${companyId}/rede-vistoria` +
            (filter && filter.page ? '?page=' + filter.page : '')
        )
        .then((response) => {
          setResultsInfo(response.resultsInfo);
          setAppData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
    fetchData();
  }, [refreshKey]);

  if (isLoading) {
    return (
      <div>
        <div style={{ height: '250px', marginTop: 100 }}>
          <div
            style={{ margin: '0 auto', padding: '45px 60px' }}
            className="empty-state-spinner"
          />
        </div>
      </div>
    );
  }

  const handleUpdateSuccess = (success, response) => {
    setRefreshKey((oldKey) => oldKey + 1);
    if (success) {
      props.notification({
        message: 'Vistoria criada com sucesso!',
      });
      setModalState(false);
    } else {
      props.notification({
        message: 'Erro ao criar Vistoria',
        status: 'danger',
      });
      setModalState(false);
    }
  };

  const createSurvey = () => {
    let company = props.activeCompany;
    setModalState(true);
    setModalTitle('Rede Vistorias');
    setModalFeature(
      <CreateSurvey company={company} onSuccess={handleUpdateSuccess.bind(this)} />
    );
  };

  const handleModalClose = () => {
    setModalState(false);
    setModalTitle('');
    setModalFeature(null);
  };

  const handlePagination = (page) => {
    setFilter({ page: page });
    setRefreshKey((oldKey) => oldKey + 1);
  };

  return (
    <div>
      <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
        <Button
          style={{ marginRight: '5px' }}
          isMedium
          disabled={requestState === 'processing'}
          onClick={createSurvey.bind(this)}
        >
          Nova Vistoria
        </Button>
      </Header>

      {appData.length > 0 ? (
        <div>
          <Table
            items={appData}
            cells={[
              { name: 'Código', path: 'code' },
              { name: 'Status', path: 'status' },
              {
                name: 'Id. do Imóvel',
                path: 'property',
                render: (content) => <span>{content.code}</span>,
              },
              { name: 'Créditos', path: 'price' },
              {
                name: 'Relatório',
                path: 'report',
                render: (content) =>
                  content ? (
                    <a className="link-icon" href={content} target="_blank">
                      <p className="si-item-icon text-center">
                        <i className="fas fa-clipboard-list"></i>
                      </p>
                    </a>
                  ) : null,
              },
              { name: 'Observações', path: 'comments' },
              {
                name: 'Painel RV',
                path: 'hotsite',
                render: (content) =>
                  content ? (
                    <a href={content} target="_blank">
                      <p className="si-item-icon text-center">
                        <img src="media/icone-rede-vistorias.png" />
                      </p>
                    </a>
                  ) : null,
              },
            ]}
          />

          <div className="text-center mt-5 mb-5">
            <Pagination
              current={resultsInfo.currentPage}
              total={resultsInfo.total}
              itemsPerPage={resultsInfo.perPage}
              onChange={handlePagination.bind(this)}
            />
          </div>
        </div>
      ) : null}

      {redeVistoriasUrl === '' ? (
        <Grid>
          <Grid.Col>
            <div className="text-center">Rede Vistorias em breve.</div>
          </Grid.Col>
        </Grid>
      ) : null}

      <Modal
        title={modalTitle}
        isOpen={modalState}
        onClose={handleModalClose.bind(this)}
      >
        {modalFeature}
      </Modal>
    </div>
  );
};

export default Surveys;
