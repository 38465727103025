import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  inset: 0;
  z-index: 9999999999999999999999;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #0000007a;

  padding: 0px;

  @media (min-width: 600px) {
    padding: 40px;
  }
`

export const Modal = styled.div`
  height: auto;
  max-height: 95%;
  max-width: min(85%, 600px);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  padding: 0px 40px 40px 40px;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: white;

  border-radius: 4px;
`

export const BackButton = styled.span`
  align-self: flex-end;

  margin: 13px -20px 14px 0px;

  font-size: 16px;
  color: #4e4e4e;
  font-weight: 600;

  cursor: pointer;
`

export const Button = styled.button`
  align-self: center;

  margin-top: 24px;
  padding: 9px 32px;

  font-size: 17px;
  color: white;

  background-color: #2091ea;

  border: none;
  border-radius: 4px;

  cursor: pointer;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.95;
  }
`
