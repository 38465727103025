import React from 'react';

// Components
import Space from 'components/lib/Space';
import Button from 'components/lib/Button';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';
import { FormFieldEmail } from 'components/lib/FormFields';
import Assistant from 'components/Assistant';

import actionCreator from '../authCreator';

export class Recovery extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.actionKey !== this.props.actionKey && this.props.isSuccess) {
    }
    return null;
  }

  render() {
    const { formIsWorking, handleFormField, goto, savedEmail, fieldErrors = {} } = this.props;

    console.log(this.props);

    return (
      <React.Fragment>
        <Assistant words={'Confirme o seu email que enviaremos uma nova senha para você.'} style={{ marginBottom: 30 }} />

        <FormFieldEmail
          label="Email de recuperação"
          isDisabled={formIsWorking}
          value={savedEmail}
          onChange={handleFormField.bind(this, 'email')}
          isValid={!fieldErrors.email}
          errorMessage={fieldErrors.email}
        />

        <Space isSmall />

        <Field>
          <Control>
            <Button isFullWidth isLarge isLoading={formIsWorking} isDisabled={formIsWorking} submit>
              Enviar
            </Button>
          </Control>
        </Field>

        <Button isLink isFullWidth specSelector="recovery-button" onClick={goto.bind(this, '/auth')}>
          Voltar para login
        </Button>
      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.recovery({
      email: fields.email.value,
    });
  }
}

export default actionCreator(Recovery);
