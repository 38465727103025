import React from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import withRedux from 'components/redux';
import * as actions from './actions';
import * as mainActions from '../../actions';
import * as commons from 'utils/UtilityStore/actions';

// Components
import withModal from 'components/HOCs/withModal';
import SectionWrapper from 'components/SectionWrapper';
import KeysTimeline from './components/KeysTimeline';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import { deepKey, copyToClipboard } from 'utils/utils';
import KeysDeliveryDate from './components/KeysDeliveryDate';
import ProcessTask from 'services/ProcessTask';

import CloseRequirement from 'features/CloseRequirement';

import HeadRight from 'features/AnalysisProposal/components/HeadRight';
import SectionGroup from 'features/AnalysisProposal/components/SectionGroup';
import Documents from 'components/Documents';
import { fb, ga } from '../../../../services/firebase';
import Superlogica from '../../../../services/integrations/components/Superlogica';

class Keys extends React.Component {
  componentDidMount() {
    const allButtons = document.querySelectorAll('#form-blocked button');

    for (let i = 0; i < allButtons.length; i++) {
      const currentButton = allButtons[i];

      const clone = currentButton.cloneNode(true);

      const parent = currentButton.parentNode;

      parent.appendChild(clone);

      currentButton.remove();
    }
  }

  render() {
    const { company: activeCompany, current, isAgent = false } = this.props;
    const { proposal } = current;

    const process_status_url = proposal ? proposal.process_status_url : '';

    const extraButtons = (
      <Button
        isBare
        isFullWidth
        isMedium
        onClick={() => {
          ga.logEvent('options_menu', { custom_value: 'public-link' });
          const path = `${process_status_url}`;
          copyToClipboard(path);
          this.props.notification({
            message: 'Link copiado com sucesso',
            status: 'success',
          });
        }}
      >
        <Icon name="fas fa-link" />
        Link de Acompanhamento
      </Button>
    );
    const reactToPrint = () => (
      <ReactToPrint
        trigger={() => (
          <Button isBare isFullWidth isMedium>
            <Icon name="fas fa-print" />
            Imprimir
          </Button>
        )}
        bodyClass="print-padding"
        content={() => this.componentRef}
        copyStyles={true}
      />
    );

    const processId = deepKey(this.props.current, 'id');

    const { requirements, keys_delivered_at } = this.props.current;
    const keysStatus = ProcessTask(requirements, 'REQUIREMENT_POSSESSION').status;

    let keysDeliveryDate = {};

    if (keys_delivered_at) {
      keysDeliveryDate.time = moment(
        keys_delivered_at,
        'YYYY-MM-DD hh:mm:ss'
      ).format('hh:mm');
      keysDeliveryDate.date = moment(
        keys_delivered_at,
        'YYYY-MM-DD hh:mm:ss'
      ).format('YYYY-MM-DD');
    }

    const items = [
      {
        contain: (
          <KeysTimeline
            requirements={requirements}
            onClickInfo={this.props.openModal}
            process={this.props.current}
          />
        ),
        icon: 'fa-tasks',
        open: true,
        title: 'Acompanhamento',
      },
      {
        contain: (
          <KeysDeliveryDate
            processId={processId}
            defaultValue={keysDeliveryDate}
            disabledAutoFocus
            isDisabled={keysStatus}
            isClosed={keysStatus}
            onSuccess={this.handleSetDateSuccess.bind(this)}
          />
        ),
        icon: 'fa-key',
        open: false,
        title: 'Data de entrega das chaves',
      },
      {
        contain: (
          <Documents
            entityId={current.id}
            entityName="processPossession"
            noRequest
          />
        ),
        icon: 'fa-clipboard-list',
        open: false,
        title: 'Documentos',
      },
    ];

    return isAgent ? (
      <form id="form-blocked">
        <fieldset disabled="disabled">
          <div ref={(element) => (this.componentRef = element)}>
            <div className="print-wrap">
              <SectionWrapper
                customHeader={true}
                header={<p>Posse</p>}
                headerRight={
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      width: '100%',
                      height: 32,
                    }}
                  >
                    <Superlogica
                      companyId={this.props.activeCompany.id}
                      payload={this.props.current}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {HeadRight({ reactToPrint, extraButtons })}
                  </div>
                }
              >
                <SectionGroup items={items} />

                {!keysStatus && (
                  <SectionWrapper header="Finalizar processo">
                    <CloseRequirement
                      processId={this.props.current.id}
                      // onSuccess={this.closeRequirementSuccess.bind(this)}
                      requirement="possession"
                      companyId={activeCompany ? activeCompany.id : ''}
                    />
                  </SectionWrapper>
                )}
              </SectionWrapper>
            </div>
          </div>
        </fieldset>
      </form>
    ) : (
      <div ref={(element) => (this.componentRef = element)}>
        <div className="print-wrap">
          <SectionWrapper
            customHeader={true}
            header={<p>Posse</p>}
            headerRight={
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  width: '100%',
                  height: 32,
                }}
              >
                <Superlogica
                  companyId={this.props.activeCompany.id}
                  payload={this.props.current}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {HeadRight({ reactToPrint, extraButtons })}
              </div>
            }
          >
            <SectionGroup items={items} />

            {!keysStatus && (
              <SectionWrapper header="Finalizar processo">
                <CloseRequirement
                  processId={this.props.current.id}
                  onSuccess={this.closeRequirementSuccess.bind(this)}
                  requirement="possession"
                  companyId={activeCompany ? activeCompany.id : ''}
                />
              </SectionWrapper>
            )}
          </SectionWrapper>
        </div>
      </div>
    );
  }

  openInfoModal(description) {
    this.props.openModal('Andamento', description);
  }

  closeRequirementSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({ message: 'Etapa encerrada com sucesso' });
      this.props.fetch(this.props.current.id);

      ga.logEvent('process_ended', {
        name: 'Processos Finalizados',
        value: this.props.current.proposal.rent_value,
        currency: 'BRL',
      });

      ga.logEvent('process_guarantee', {
        custom_value: this.props?.current?.proposal?.guarantee?.name || '',
      });

      fb.logEvent('process_ended', {
        processId: this.props.current.id,
        rentValue: this.props.current.proposal.rent_value,
        city: this.props.current.proposal.properties[0].address.city,
        state: this.props.current.proposal.properties[0].address.state,
        neighborhood: this.props.current.proposal.properties[0].address.neighborhood,
        zipCode: this.props.current.proposal.properties[0].address.zip_code,
        companyId: this.props.activeCompany.id,
        guarantee: this.props?.current?.proposal?.guarantee?.name || '',
        user: {
          email: this.props.activeUser.email,
          id: this.props.activeUser.id,
        },
      });
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar encerrar a etapa',
        status: 'danger',
      });
    }
  }

  handleSetDateSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Data de entrega das chaves atualizada com sucesso',
      });
      this.props.fetch(this.props.current.id);
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar atualizar a data de entrega das chaves',
        status: 'danger',
      });
    }
  }
}

export default withRedux(
  ({ processDetails: { current } }) => ({
    current,
  }),
  { ...actions, ...mainActions, ...commons }
)(withModal(Keys));
