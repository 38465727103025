import React from 'react';

import { connect } from 'react-redux';
import * as actions from './actions';
import * as commons from 'utils/UtilityStore/actions';

// Components
import BodyContainer from 'components/lib/BodyContainer';
import UpdatePropertyOwner from 'features/PropertyOwner/UpdatePropertyOwner';

class PropertyOwnerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyInfo: {
        companyName: '',
        themeColor: '',
      },
    };
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.propertyOwnerId);
    const companyInfo = {
      companyName: this.props.activeCompany.name,
      themeColor: this.props.companyMeta.themeColor,
    };
    this.setState({ companyInfo });
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    let current = this.props.current;
    if (current.address) {
      current = { ...current.address, ...current };
      current.address = current.address.address;
      current.number = parseInt(current.number);
    }

    let trading_address = {};

    if (current.trading_address) {
      trading_address = {
        trading_address_zip_code: current.trading_address.zip_code,
        trading_address_address: current.trading_address.address,
        trading_address_number: parseInt(current.trading_address.number),
        trading_address_additional_address:
          current.trading_address.additional_address,
        trading_address_state: current.trading_address.state,
        trading_address_city: current.trading_address.city,
        trading_address_neighborhood: current.trading_address.neighborhood,
      };
    }

    current = { ...trading_address, ...current };

    Object.keys(current).forEach((key) => {
      if (current[key] === null) {
        current[key] = '';
      }
    });

    const hasData = Object.keys(current).length > 0;

    const { companyInfo } = this.state;

    return (
      <BodyContainer isLoading={this.props.isWorking['propertyOwner']}>
        {hasData ? (
          <UpdatePropertyOwner
            {...this.props}
            {...this.props.history}
            isApp
            activeUser={this.props.activeUser.id}
            activeCompany={this.props.activeCompany.id}
            companyInfo={companyInfo}
            defaultValue={current}
            propertyOwnerId={this.props.match.params.propertyOwnerId}
            properties={current.properties}
            onUpdate={() =>
              this.props.fetch(this.props.match.params.propertyOwnerId)
            }
          />
        ) : null}
      </BodyContainer>
    );
  }
}

export default connect(
  (store) => ({
    current: store.propertyOwner.current,
    isWorking: store.commons.isWorking,
  }),
  { ...actions, ...commons }
)(PropertyOwnerDetails);
