import { request } from 'core/constants'
import { requestState } from 'utils/UtilityStore/actions'

export const fetch =
  (routeName, filter, order = null, orderDirection = null) =>
  async (dispatch, state) => {
    dispatch(requestState(true, 'process'))

    const { data: filters } = await request.get('proposal-status')

    const filtersParams = `${Object.entries(
      state().app?.userMeta?.proposalFilters || {},
    )
      .filter(([, value]) => value)
      .map(
        ([filteredFilter]) =>
          `filters[status_id][]=${
            filters.find((filter) => filter.code === filteredFilter).id
          }`,
      )
      .join('&')}`

    const orderBy = order && orderDirection ? order + ',' + orderDirection : ''

    let filterType = ''

    if (routeName === 'Rentals') {
      filterType = 'filters[type][]=TYPE_RENTAL'
    } else if (routeName === 'Sales') {
      filterType = 'filters[type][]=TYPE_SALE'
    } else if (routeName === 'PropertyCapture') {
      filterType = 'filters[type][]=TYPE_ACQUISITION'
    }

    const url = `{company}/proposalv2?page=${filter?.page || 1}&search=${
      filter?.search || ''
    }&orderBy=${orderBy}&${filterType}&${filtersParams}`

    const { data: payload, resultsInfo: listInfo } = await request.get(url)

    dispatch(requestState(false, 'process'))
    dispatch({
      type: 'FETCH_PROCESS',
      payload,
      listInfo,
    })
  }
