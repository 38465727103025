import React from 'react'

const ModalPlanRestriction = () => (
  <svg
    width='100%'
    height='212'
    viewBox='0 0 287 212'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M238.523 196.755H257.479V196.613H238.523V196.755Z' fill='#ECECEC' />
    <path d='M184.586 198.308H189.562V198.165H184.586V198.308Z' fill='#ECECEC' />
    <path d='M226.969 191.44H237.953V191.297H226.969V191.44Z' fill='#ECECEC' />
    <path d='M30.0234 192.403H54.7437V192.26H30.0234V192.403Z' fill='#ECECEC' />
    <path d='M59.8359 192.403H63.4604V192.26H59.8359V192.403Z' fill='#ECECEC' />
    <path d='M75.2422 194.819H128.854V194.676H75.2422V194.819Z' fill='#ECECEC' />
    <path
      d='M25.9308 0.14389C24.2081 0.14389 22.8071 1.54491 22.8071 3.26756V158.61C22.8071 160.332 24.2081 161.733 25.9308 161.733H136.443C138.164 161.733 139.566 160.332 139.566 158.61V3.26756C139.566 1.54491 138.164 0.14389 136.443 0.14389H25.9308ZM136.443 161.876H25.9308C24.1297 161.876 22.6641 160.411 22.6641 158.61V3.26756C22.6641 1.46651 24.1297 0.000823975 25.9308 0.000823975H136.443C138.243 0.000823975 139.709 1.46651 139.709 3.26756V158.61C139.709 160.411 138.243 161.876 136.443 161.876Z'
      fill='#ECECEC'
    />
    <path
      d='M149.719 0.14389C147.997 0.14389 146.596 1.54491 146.596 3.26756V158.61C146.596 160.332 147.997 161.733 149.719 161.733H260.231C261.953 161.733 263.355 160.332 263.355 158.61V3.26756C263.355 1.54491 261.953 0.14389 260.231 0.14389H149.719ZM260.231 161.876H149.719C147.919 161.876 146.453 160.411 146.453 158.61V3.26756C146.453 1.46651 147.919 0.000823975 149.719 0.000823975H260.231C262.032 0.000823975 263.498 1.46651 263.498 3.26756V158.61C263.498 160.411 262.032 161.876 260.231 161.876Z'
      fill='#ECECEC'
    />
    <path d='M286.154 187.543H0V187.4H286.154V187.543Z' fill='#ECECEC' />
    <path
      d='M52.0143 115.709C48.4299 114.601 46.0703 113.872 43.2677 113.006C42.6284 112.808 42.7984 111.86 43.4663 111.895C46.7353 112.068 49.4114 112.21 53.5464 112.43C51.1656 108.289 49.76 105.705 48.1564 102.464C47.8622 101.869 48.6497 101.338 49.0904 101.834C51.2423 104.253 53.0427 106.221 55.9283 109.317C56.6174 105.309 57.4146 101.621 58.4133 97.9123C58.5936 97.2444 59.5899 97.4184 59.5287 98.1074C59.262 101.116 59.1647 104.151 59.2374 107.084C59.3719 107.131 59.3776 107.133 59.5121 107.181C61.9341 104.419 64.4769 101.765 67.1267 99.2211C67.6366 98.7318 68.3989 99.4346 67.9611 99.9897C65.3897 103.25 63.0089 106.661 60.8444 110.205C64.304 108.901 67.7831 107.634 71.2753 106.408C71.9272 106.18 72.345 107.099 71.7441 107.439C68.1219 109.492 64.5393 111.594 60.9623 113.769C64.7361 114.971 67.1976 115.755 70.1794 116.704C70.8169 116.908 70.6412 117.853 69.9733 117.813C66.9172 117.629 64.3796 117.475 60.5016 117.243C62.3736 119.435 63.6751 120.939 65.1888 122.639C65.6307 123.137 65.004 123.858 64.45 123.49C62.5459 122.227 60.8708 121.106 58.4327 119.469C58.68 121.417 58.8946 122.923 59.1521 124.403C59.27 125.079 58.3131 125.346 58.0636 124.707C57.4306 123.087 56.8514 121.475 56.116 119.338C53.3632 132.509 54.0769 145.278 56.7387 158.372C55.4195 158.133 54.7597 158.014 53.4399 157.776C51.5118 144.352 52.0109 132.331 54.9869 119.133C53.3335 120.84 52.1185 122.128 50.9595 123.423C50.5005 123.937 49.6942 123.341 50.0496 122.752C50.8273 121.462 51.6732 120.148 52.8024 118.452C49.8573 119.098 47.8399 119.544 45.6165 120.045C44.9617 120.192 44.6372 119.281 45.2387 118.982C47.3105 117.951 49.2128 117.034 52.0143 115.709Z'
      fill='#ECECEC'
    />
    <path
      d='M47.3533 120.26C50.07 118.819 52.0256 117.782 54.0699 116.698C54.8619 116.278 54.4018 115.073 53.5308 115.287C50.9794 115.913 48.606 116.496 45.2363 117.323C46.5875 113.429 47.442 110.809 48.2747 107.926C48.5219 107.071 47.3504 106.561 46.8908 107.322C45.527 109.582 44.2285 111.675 42.3152 114.71C41.1552 111.61 39.9642 108.731 38.6588 105.903C38.2719 105.063 37.0077 105.52 37.2366 106.415C37.8083 108.658 38.2822 110.915 38.6473 113.134C38.5243 113.205 38.5185 113.208 38.3961 113.28C36.2242 111.585 33.9979 109.961 31.7218 108.41C30.9383 107.876 30.077 108.956 30.7764 109.596C33.1738 111.789 35.4693 114.092 37.6509 116.5C34.7121 115.987 31.7647 115.501 28.8128 115.042C27.8977 114.899 27.5772 116.209 28.4528 116.512C31.7109 117.639 34.9525 118.806 38.2003 120.023C35.3056 121.59 33.2476 122.704 31.0545 123.891C30.267 124.317 30.7346 125.518 31.6033 125.298C33.9601 124.701 36.1852 124.138 39.3015 123.349C38.186 125.303 37.3333 126.779 36.4571 128.261C36.0044 129.027 37.0099 129.813 37.6452 129.191C38.8854 127.976 40.1181 126.761 41.7492 125.151C41.8208 126.31 41.8746 127.315 41.9112 128.254C41.9467 129.168 43.271 129.25 43.4221 128.348C43.6109 127.219 43.7947 126.015 44.0013 124.589C49.1635 137.017 50.8461 149.694 50.6744 163.054C51.9266 162.574 52.5521 162.334 53.8038 161.853C53.1931 148.304 50.4586 136.588 45.0726 124.179C46.2584 125.014 47.2548 125.725 48.1654 126.395C48.9117 126.943 49.8073 125.936 49.1721 125.261C48.5534 124.604 47.8752 123.902 47.0912 123.101C49.3861 123.166 51.1316 123.217 52.8394 123.272C53.7402 123.301 53.9348 122.016 53.0655 121.777C51.3806 121.314 49.6476 120.855 47.3533 120.26Z'
      fill='#F0F0F0'
    />
    <path
      d='M59.221 127.478C57.1905 126.401 55.7425 125.633 54.211 124.821C53.6502 124.524 53.9747 123.671 54.5916 123.822C56.4997 124.29 58.2521 124.72 60.7645 125.337C59.7607 122.446 59.1335 120.519 58.5159 118.37C58.3414 117.765 59.1701 117.403 59.4952 117.943C60.5127 119.63 61.4696 121.173 62.8935 123.433C63.7606 121.116 64.6505 118.969 65.6303 116.857C65.9056 116.264 66.8018 116.588 66.6381 117.221C66.1992 118.921 65.8375 120.636 65.5616 122.318C65.6406 122.364 65.644 122.366 65.7224 122.412C67.3713 121.123 69.0642 119.891 70.796 118.717C71.3511 118.34 71.9555 119.112 71.4593 119.564C69.6559 121.204 67.931 122.93 66.2936 124.737C68.4941 124.352 70.701 123.989 72.9101 123.647C73.5573 123.547 73.7868 124.474 73.1676 124.688C70.7359 125.527 68.3167 126.397 65.893 127.305C68.0535 128.474 69.5741 129.298 71.2155 130.185C71.7735 130.488 71.4415 131.338 70.8269 131.182C69.0624 130.735 67.417 130.319 65.0906 129.73C65.9296 131.2 66.5649 132.298 67.2253 133.414C67.5464 133.957 66.8356 134.515 66.3852 134.074C65.4517 133.16 64.5332 132.255 63.3062 131.043C63.2501 131.952 63.2089 132.732 63.182 133.461C63.1585 134.108 62.2211 134.169 62.1129 133.531C61.9658 132.662 61.8245 131.739 61.6648 130.635C57.9013 139.693 56.6748 148.935 56.7996 158.674C55.8873 158.323 55.4306 158.149 54.5184 157.799C54.9642 147.922 56.957 139.381 60.8836 130.334C59.957 130.987 59.1895 131.536 58.4873 132.054C57.9602 132.443 57.3261 131.731 57.7742 131.253C58.2578 130.737 58.7895 130.185 59.4122 129.549C57.6787 129.598 56.3749 129.636 55.0867 129.678C54.4485 129.698 54.3112 128.79 54.927 128.62C56.1964 128.27 57.4892 127.927 59.221 127.478Z'
      fill='#F5F5F5'
    />
    <path
      d='M45.0943 187.306H62.1616C63.3549 187.306 64.3421 186.377 64.4154 185.186L66.2244 155.749C66.3046 154.45 40.9513 154.45 41.0314 155.749L42.8405 185.186C42.9138 186.377 43.901 187.306 45.0943 187.306Z'
      fill='#F0F0F0'
    />
    <path
      d='M44.0586 119.286C43.6076 118.913 43.3764 118.337 43.4445 117.755L43.8285 114.493L42.9529 116.01C42.4349 116.908 41.2474 117.15 40.4198 116.525L38.7081 115.233L40.2727 117.759C40.7203 118.482 40.5749 119.423 39.9288 119.977L37.9062 121.711L39.8893 121.258C40.7581 121.06 41.6348 121.557 41.9124 122.403L42.5946 124.487L43.0313 122.27C43.2276 121.274 44.2371 120.662 45.2106 120.948L46.5429 121.341L44.0586 119.286Z'
      fill='#E7E7E7'
    />
    <path
      d='M55.4392 113.666C55.7689 113.394 55.9377 112.973 55.8879 112.549L55.6075 110.166L56.2467 111.274C56.625 111.93 57.4927 112.106 58.097 111.65L59.3475 110.706L58.2046 112.551C57.8773 113.079 57.9837 113.767 58.4559 114.171L59.933 115.438L58.4845 115.107C57.8498 114.963 57.2094 115.324 57.0068 115.944L56.5083 117.465L56.1895 115.846C56.0464 115.119 55.3093 114.672 54.5979 114.881L53.625 115.167L55.4392 113.666Z'
      fill='#F5F5F5'
    />
    <path
      d='M62.1848 127.325C62.4217 127.129 62.5425 126.828 62.5064 126.522L62.3056 124.812L62.7646 125.608C63.0358 126.078 63.6585 126.205 64.0923 125.878L64.9891 125.2L64.169 126.525C63.9344 126.904 64.011 127.397 64.3493 127.687L65.4098 128.596L64.3699 128.358C63.9149 128.255 63.4553 128.515 63.31 128.958L62.9523 130.051L62.7234 128.888C62.6203 128.366 62.0915 128.046 61.581 128.196L60.8828 128.401L62.1848 127.325Z'
      fill='#E7E7E7'
    />
    <path
      d='M220.129 50.6118H205.153C204.736 50.6118 204.398 50.2742 204.398 49.8569V46.9428C204.398 46.5255 204.736 46.1879 205.153 46.1879H220.129C220.547 46.1879 220.884 46.5255 220.884 46.9428V49.8569C220.884 50.2742 220.547 50.6118 220.129 50.6118Z'
      fill='#F5F5F5'
    />
    <path
      d='M215.091 56.0957H200.115C199.698 56.0957 199.359 55.7575 199.359 55.3408V52.4261C199.359 52.0094 199.698 51.6718 200.115 51.6718H215.091C215.508 51.6718 215.846 52.0094 215.846 52.4261V55.3408C215.846 55.7575 215.508 56.0957 215.091 56.0957Z'
      fill='#F5F5F5'
    />
    <path
      d='M232.357 56.0957H217.38C216.963 56.0957 216.625 55.7575 216.625 55.3408V52.4261C216.625 52.0094 216.963 51.6718 217.38 51.6718H232.357C232.774 51.6718 233.111 52.0094 233.111 52.4261V55.3408C233.111 55.7575 232.774 56.0957 232.357 56.0957Z'
      fill='#F0F0F0'
    />
    <path
      d='M240.351 15.6675H224.372C223.955 15.6675 223.617 15.3298 223.617 14.9126V11.7294C223.617 11.3128 223.955 10.9746 224.372 10.9746H240.351C240.768 10.9746 241.106 11.3128 241.106 11.7294V14.9126C241.106 15.3298 240.768 15.6675 240.351 15.6675Z'
      fill='#F5F5F5'
    />
    <path
      d='M240.351 27.3115H224.372C223.955 27.3115 223.617 26.9739 223.617 26.5566V23.3735C223.617 22.9563 223.955 22.6186 224.372 22.6186H240.351C240.768 22.6186 241.106 22.9563 241.106 23.3735V26.5566C241.106 26.9739 240.768 27.3115 240.351 27.3115Z'
      fill='#F5F5F5'
    />
    <path
      d='M235.015 21.4844H219.036C218.619 21.4844 218.281 21.1461 218.281 20.7295V17.5463C218.281 17.1291 218.619 16.7914 219.036 16.7914H235.015C235.432 16.7914 235.77 17.1291 235.77 17.5463V20.7295C235.77 21.1461 235.432 21.4844 235.015 21.4844Z'
      fill='#F5F5F5'
    />
    <path
      d='M253.32 21.4844H237.341C236.924 21.4844 236.586 21.1461 236.586 20.7295V17.5463C236.586 17.1291 236.924 16.7914 237.341 16.7914H253.32C253.737 16.7914 254.075 17.1291 254.075 17.5463V20.7295C254.075 21.1461 253.737 21.4844 253.32 21.4844Z'
      fill='#F0F0F0'
    />
    <path d='M125.297 153.521H235.206V150.361H125.297V153.521Z' fill='#F5F5F5' />
    <path d='M234.383 153.521H259.002V150.361H234.383V153.521Z' fill='#ECECEC' />
    <path d='M136.722 161.202V153.521H131.602L136.722 161.202Z' fill='#E1E1E1' />
    <path
      d='M134.167 161.202L129.047 153.521H131.607L136.728 161.202H134.167Z'
      fill='#FAFAFA'
    />
    <path d='M254.995 161.202V153.521H249.875L254.995 161.202Z' fill='#E1E1E1' />
    <path
      d='M252.441 161.202L247.32 153.521H249.88L255.001 161.202H252.441Z'
      fill='#FAFAFA'
    />
    <path
      d='M241.421 150.792H244.315C245.618 150.792 246.661 149.713 246.617 148.411L246.249 137.503H239.487L239.119 148.411C239.074 149.713 240.118 150.792 241.421 150.792Z'
      fill='#F0F0F0'
    />
    <path
      d='M218.18 139.345C218.18 142.891 221.055 145.767 224.602 145.767C228.148 145.767 231.023 142.891 231.023 139.345C231.023 135.799 228.148 132.923 224.602 132.923C221.055 132.923 218.18 135.799 218.18 139.345Z'
      fill='#F0F0F0'
    />
    <path
      d='M224.144 150.694H225.041V146.439H224.593C220.681 146.439 217.498 143.257 217.498 139.345C217.498 135.433 220.681 132.251 224.593 132.251V131.354C220.186 131.354 216.602 134.939 216.602 139.345C216.602 143.601 219.946 147.091 224.144 147.323V150.694Z'
      fill='#ECECEC'
    />
    <path
      d='M220.82 150.792H228.457C228.457 149.847 227.69 149.081 226.744 149.081H222.533C221.587 149.081 220.82 149.847 220.82 150.792Z'
      fill='#ECECEC'
    />
    <path
      d='M241.847 150.792L235.814 144.76L229.781 150.792H241.847Z'
      fill='#F5F5F5'
    />
    <path d='M235.812 144.76L241.845 150.792H235.812V144.76Z' fill='#E7E7E7' />
    <path
      d='M193.76 142.899C193.791 143.47 193.809 143.927 193.809 144.23C193.809 146.864 192.625 149.212 190.764 150.792H180.161C178.301 149.212 177.117 146.864 177.117 144.23C177.117 143.927 177.134 143.47 177.165 142.899H193.76Z'
      fill='#F5F5F5'
    />
    <path
      d='M200.346 148.265C200.381 148.047 200.405 147.824 200.405 147.596C200.405 147.289 200.398 146.662 200.385 145.867C200.601 145.771 200.831 145.716 201.047 145.716C201.985 145.716 202.059 146.696 202.059 146.996C202.059 147.705 201.212 148.277 200.51 148.277C200.452 148.277 200.397 148.273 200.346 148.265ZM192.273 147.596C192.273 148.878 192.85 150.023 193.757 150.792H198.923C199.509 150.295 199.954 149.638 200.198 148.89C200.298 148.908 200.401 148.921 200.51 148.921C201.545 148.921 202.704 148.098 202.704 146.996C202.704 145.845 202.038 145.071 201.047 145.071C200.824 145.071 200.596 145.111 200.374 145.182C200.374 145.157 200.373 145.133 200.373 145.108C200.364 144.555 199.913 144.11 199.36 144.11H193.319C192.766 144.11 192.315 144.555 192.306 145.108C192.287 146.259 192.273 147.196 192.273 147.596Z'
      fill='#ECECEC'
    />
    <path
      d='M144.476 150.792L145.322 141.127H133.065L132.219 150.792H144.476Z'
      fill='#F0F0F0'
    />
    <path
      d='M144.477 150.792L145.322 141.127H145.982L145.136 150.792H144.477Z'
      fill='#E1E1E1'
    />
    <path
      d='M143.313 149.807L143.986 142.113H134.228L133.555 149.807H143.313Z'
      fill='#FAFAFA'
    />
    <path
      d='M142.558 149.174L143.12 142.747H134.969L134.406 149.174H142.558Z'
      fill='#ECECEC'
    />
    <path
      d='M158.29 150.792H145.977L147.343 135.177H159.656L158.29 150.792Z'
      fill='#F0F0F0'
    />
    <path
      d='M158.289 150.792H158.877L160.243 135.177H159.655L158.289 150.792Z'
      fill='#E1E1E1'
    />
    <path
      d='M157.169 149.2H147.367L148.455 136.769H158.256L157.169 149.2Z'
      fill='#E1E1E1'
    />
    <path
      d='M156.454 148.176H148.266L149.174 137.792H157.362L156.454 148.176Z'
      fill='#FAFAFA'
    />
    <path
      d='M150.556 143.424L151.363 144.231C152.072 144.941 153.224 144.941 153.933 144.231L154.74 143.424C155.45 142.714 155.45 141.563 154.74 140.853L153.933 140.046C153.224 139.336 152.072 139.336 151.363 140.046L150.556 140.853C149.846 141.563 149.846 142.714 150.556 143.424Z'
      fill='#ECECEC'
    />
    <path
      d='M150.15 146.411L150.549 146.81C151.258 147.52 152.41 147.52 153.119 146.81L153.518 146.411C154.228 145.701 154.228 144.55 153.518 143.84L153.119 143.441C152.409 142.731 151.258 142.731 150.549 143.441L150.15 143.84C149.439 144.55 149.44 145.701 150.15 146.411Z'
      fill='#E7E7E7'
    />
    <path
      d='M39.708 34.267H57.1989C57.6155 34.267 57.9538 34.6047 57.9538 35.0219V38.6108C57.9538 39.0275 57.6155 39.3657 57.1989 39.3657H39.708C39.2908 39.3657 38.9531 39.0275 38.9531 38.6108V35.0219C38.9531 34.6047 39.2908 34.267 39.708 34.267Z'
      fill='#F5F5F5'
    />
    <path
      d='M48.5908 27.9492H66.0817C66.4983 27.9492 66.836 28.2868 66.836 28.704V32.293C66.836 32.7096 66.4983 33.0479 66.0817 33.0479H48.5908C48.1736 33.0479 47.8359 32.7096 47.8359 32.293V28.704C47.8359 28.2868 48.1736 27.9492 48.5908 27.9492Z'
      fill='#F5F5F5'
    />
    <path
      d='M28.6924 27.9492H46.1833C46.5999 27.9492 46.9376 28.2868 46.9376 28.704V32.293C46.9376 32.7096 46.5999 33.0479 46.1833 33.0479H28.6924C28.2752 33.0479 27.9375 32.7096 27.9375 32.293V28.704C27.9375 28.2868 28.2752 27.9492 28.6924 27.9492Z'
      fill='#F0F0F0'
    />
    <path
      d='M36.7981 132.085C30.8043 119.05 27.1055 104.786 27.5428 90.3006C28.0607 73.1262 33.1376 55.9587 42.9006 42.2182C52.6642 28.477 67.2306 18.3781 83.2655 15.5337C99.301 12.6893 116.625 17.463 128.607 29.0785C140.081 40.2031 155.716 47.3992 171.212 45.7853C182.834 44.5749 193.811 39.3509 203.103 31.7873C214.37 22.6155 230.075 22.026 241.399 31.1177C244.609 33.6948 247.454 36.819 249.777 40.4125C258.126 53.3261 260.117 70.0169 257.571 85.424C255.026 100.832 248.302 115.141 240.418 128.377C206.323 189.135 77.4721 220.549 36.7981 132.085Z'
      fill='#2091EA'
    />
    <g opacity='0.899994'>
      <g opacity='0.899994'>
        <path
          opacity='0.899994'
          d='M36.7903 132.086C30.7965 119.051 27.0977 104.788 27.5349 90.3021C28.0529 73.1277 33.1298 55.9602 42.8928 42.2196C52.6564 28.4785 67.2228 18.3795 83.2577 15.5352C99.2932 12.6908 116.617 17.4644 128.599 29.08C140.074 40.2045 155.708 47.4007 171.204 45.7868C182.826 44.5764 193.803 39.3524 203.095 31.7887C214.362 22.6169 230.067 22.0274 241.392 31.1191C244.601 33.6962 247.446 36.8205 249.769 40.414C258.118 53.3276 260.109 70.0183 257.563 85.4255C255.018 100.833 248.294 115.142 240.411 128.378C206.315 189.136 77.4642 220.551 36.7903 132.086Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M254.046 204.565C254.046 208.144 204.365 211.045 143.081 211.045C81.7975 211.045 32.1172 208.144 32.1172 204.565C32.1172 200.986 81.7975 198.084 143.081 198.084C204.365 198.084 254.046 200.986 254.046 204.565Z'
      fill='#F5F5F5'
    />
    <path
      d='M224.769 127.382H191.287C185.398 127.382 180.625 122.609 180.625 116.721V25.8468C180.625 19.9589 185.398 15.1859 191.287 15.1859H224.769C230.658 15.1859 235.43 19.9589 235.43 25.8468V116.721C235.43 122.609 230.658 127.382 224.769 127.382Z'
      fill='#2091EA'
    />
    <g opacity='0.300003'>
      <g opacity='0.300003'>
        <path
          opacity='0.300003'
          d='M224.896 15.1854H191.175C185.352 15.1854 180.633 19.9052 180.633 25.7273V116.84C180.633 122.662 185.352 127.382 191.175 127.382H224.896C230.718 127.382 235.438 122.662 235.438 116.84V25.7273C235.438 19.9052 230.718 15.1854 224.896 15.1854Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M235.431 20.6893V42.9395H180.625V20.6893C180.625 17.6509 183.09 15.1859 186.131 15.1859H229.925C232.966 15.1859 235.431 17.6509 235.431 20.6893Z'
      fill='#2091EA'
    />
    <path
      d='M195.191 27.1665C195.191 26.5398 194.772 26.177 193.96 26.177H192.878V28.156H193.96C194.772 28.156 195.191 27.7926 195.191 27.1665ZM196.124 27.1665C196.124 28.2624 195.312 28.9383 194.003 28.9383H192.878V30.376H191.953V25.394H194.003C195.312 25.394 196.124 26.0631 196.124 27.1665Z'
      fill='white'
    />
    <path
      d='M199.129 26.5329V27.3799C199.051 27.3656 198.987 27.3582 198.923 27.3582C198.247 27.3582 197.82 27.7571 197.82 28.5331V30.376H196.93V26.5759H197.777V27.1304C198.033 26.7321 198.496 26.5329 199.129 26.5329Z'
      fill='white'
    />
    <path
      d='M200.429 28.1702H202.586C202.515 27.622 202.095 27.2448 201.511 27.2448C200.935 27.2448 200.515 27.6151 200.429 28.1702ZM203.419 28.7609H200.436C200.543 29.316 201.006 29.672 201.668 29.672C202.095 29.672 202.43 29.5369 202.7 29.2588L203.176 29.807C202.835 30.2128 202.301 30.4263 201.646 30.4263C200.372 30.4263 199.547 29.6079 199.547 28.4764C199.547 27.3444 200.38 26.5334 201.511 26.5334C202.621 26.5334 203.433 27.3089 203.433 28.4976C203.433 28.5686 203.425 28.6756 203.419 28.7609Z'
      fill='white'
    />
    <path
      d='M210.637 28.1983V30.376H209.747V28.3122C209.747 27.6363 209.434 27.3084 208.893 27.3084C208.31 27.3084 207.904 27.693 207.904 28.4473V30.376H207.014V28.3122C207.014 27.6363 206.701 27.3084 206.16 27.3084C205.57 27.3084 205.171 27.693 205.171 28.4473V30.376H204.281V26.5759H205.128V27.0595C205.413 26.7178 205.854 26.5329 206.374 26.5329C206.929 26.5329 207.391 26.739 207.655 27.1665C207.961 26.775 208.473 26.5329 209.078 26.5329C209.996 26.5329 210.637 27.0526 210.637 28.1983Z'
      fill='white'
    />
    <path
      d='M211.77 26.5772H212.66V30.3779H211.77V26.5772ZM211.648 25.4166C211.648 25.1178 211.891 24.8832 212.218 24.8832C212.546 24.8832 212.787 25.1035 212.787 25.3954C212.787 25.7084 212.553 25.9505 212.218 25.9505C211.891 25.9505 211.648 25.7159 211.648 25.4166Z'
      fill='white'
    />
    <path
      d='M217.513 26.5758V30.3765H216.666V29.8923C216.381 30.2408 215.933 30.4263 215.441 30.4263C214.466 30.4263 213.805 29.8923 213.805 28.7465V26.5758H214.694V28.6258C214.694 29.316 215.021 29.6434 215.584 29.6434C216.203 29.6434 216.623 29.2588 216.623 28.497V26.5758H217.513Z'
      fill='white'
    />
    <path
      d='M225.035 28.1983V30.376H224.145V28.3122C224.145 27.6363 223.832 27.3084 223.291 27.3084C222.708 27.3084 222.302 27.693 222.302 28.4473V30.376H221.412V28.3122C221.412 27.6363 221.099 27.3084 220.559 27.3084C219.968 27.3084 219.569 27.693 219.569 28.4473V30.376H218.68V26.5759H219.527V27.0595C219.811 26.7178 220.252 26.5329 220.772 26.5329C221.327 26.5329 221.79 26.739 222.053 27.1665C222.359 26.775 222.872 26.5329 223.476 26.5329C224.395 26.5329 225.035 27.0526 225.035 28.1983Z'
      fill='white'
    />
    <path
      d='M219.466 121.425H196.586C193.475 121.425 190.953 118.903 190.953 115.791V115.365C190.953 112.254 193.475 109.731 196.586 109.731H219.466C222.577 109.731 225.099 112.254 225.099 115.365V115.791C225.099 118.903 222.577 121.425 219.466 121.425Z'
      fill='#2091EA'
    />
    <path
      d='M197.869 116.679C197.869 116.448 197.686 116.304 197.406 116.2L197.19 117.295C197.622 117.207 197.869 116.983 197.869 116.679ZM196.878 114.825L197.11 113.674C196.654 113.761 196.399 114.009 196.391 114.313C196.391 114.561 196.59 114.705 196.878 114.825ZM199.165 116.472C199.165 117.535 198.309 118.246 196.974 118.359L196.798 119.238H195.998L196.175 118.359C195.415 118.287 194.743 118.047 194.336 117.743L194.871 116.752C195.295 117.064 195.83 117.263 196.383 117.319L196.654 115.968C195.911 115.744 195.095 115.449 195.095 114.481C195.095 113.418 195.966 112.699 197.326 112.602L197.501 111.723H198.301L198.125 112.61C198.693 112.666 199.213 112.827 199.597 113.066L199.109 114.057C198.765 113.826 198.349 113.689 197.917 113.641L197.638 115.048C198.373 115.264 199.165 115.552 199.165 116.472Z'
      fill='white'
    />
    <path d='M221.588 86.9536H193.297V85.809H221.588V86.9536Z' fill='#2091EA' />
    <path d='M221.588 68.1587H193.297V67.0141H221.588V68.1587Z' fill='#2091EA' />
    <path d='M221.588 50.0444H193.297V48.8998H221.588V50.0444Z' fill='#2091EA' />
    <path
      d='M212.129 58.4807C212.129 61.1981 209.926 63.4009 207.209 63.4009C204.491 63.4009 202.289 61.1981 202.289 58.4807C202.289 55.7634 204.491 53.5606 207.209 53.5606C209.926 53.5606 212.129 55.7634 212.129 58.4807Z'
      fill='#2091EA'
    />
    <path
      d='M210.731 57.4186L207.9 60.2493L206.58 61.5713L205.258 60.2493L206.578 58.9295L209.408 56.0966L210.731 57.4186Z'
      fill='white'
    />
    <path
      d='M207.905 60.2487L206.586 61.5713L203.781 58.767L205.102 57.4467L206.583 58.9289L207.905 60.2487Z'
      fill='white'
    />
    <path
      d='M212.129 76.6424C212.129 79.3597 209.926 81.5625 207.209 81.5625C204.491 81.5625 202.289 79.3597 202.289 76.6424C202.289 73.925 204.491 71.7222 207.209 71.7222C209.926 71.7222 212.129 73.925 212.129 76.6424Z'
      fill='#2091EA'
    />
    <path
      d='M210.731 75.5803L207.9 78.4103L206.58 79.7329L205.258 78.4103L206.578 77.0906L209.408 74.2577L210.731 75.5803Z'
      fill='white'
    />
    <path
      d='M207.905 78.4103L206.586 79.7329L203.781 76.9286L205.102 75.6089L206.583 77.0906L207.905 78.4103Z'
      fill='white'
    />
    <path
      d='M212.129 96.1175C212.129 98.8348 209.926 101.038 207.209 101.038C204.491 101.038 202.289 98.8348 202.289 96.1175C202.289 93.4001 204.491 91.1973 207.209 91.1973C209.926 91.1973 212.129 93.4001 212.129 96.1175Z'
      fill='#2091EA'
    />
    <path
      d='M210.731 95.0524L207.9 97.8831L206.58 99.2051L205.258 97.8831L206.578 96.5627L209.408 93.7298L210.731 95.0524Z'
      fill='white'
    />
    <path
      d='M207.905 97.883L206.586 99.2051L203.781 96.4013L205.102 95.081L206.583 96.5627L207.905 97.883Z'
      fill='white'
    />
    <path
      d='M200.754 35.0598L201.675 36.5324L203.36 36.953L202.244 38.2837L202.365 40.0166L200.754 39.3659L199.144 40.0166L199.264 38.2837L198.148 36.953L199.833 36.5324L200.754 35.0598Z'
      fill='white'
    />
    <path
      d='M207.207 35.0598L208.128 36.5324L209.813 36.953L208.697 38.2837L208.818 40.0166L207.207 39.3659L205.597 40.0166L205.718 38.2837L204.602 36.953L206.286 36.5324L207.207 35.0598Z'
      fill='white'
    />
    <path
      d='M213.66 35.0598L214.581 36.5324L216.266 36.953L215.15 38.2837L215.271 40.0166L213.66 39.3659L212.05 40.0166L212.171 38.2837L211.055 36.953L212.74 36.5324L213.66 35.0598Z'
      fill='white'
    />
    <path
      d='M160.371 120.997H126.888C121 120.997 116.227 116.224 116.227 110.336V19.4611C116.227 13.5732 121 8.80016 126.888 8.80016H160.371C166.259 8.80016 171.032 13.5732 171.032 19.4611V110.336C171.032 116.224 166.259 120.997 160.371 120.997Z'
      fill='#2091EA'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M160.007 8.79975H127.235C121.151 8.79975 116.219 13.7319 116.219 19.8155V109.977C116.219 116.062 121.152 120.996 127.237 120.996H160.007C166.092 120.996 171.025 116.062 171.025 109.977V19.8184C171.025 13.733 166.092 8.79975 160.007 8.79975Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M171.033 14.306V36.5557H116.227V14.306C116.227 11.2677 118.691 8.80273 121.733 8.80273H165.526C168.568 8.80273 171.033 11.2677 171.033 14.306Z'
      fill='#2091EA'
    />
    <path
      d='M127.539 23.4644L127.86 22.7456C128.258 23.0661 128.891 23.2939 129.504 23.2939C130.279 23.2939 130.607 23.0163 130.607 22.646C130.607 21.5712 127.646 22.2758 127.646 20.4255C127.646 19.6214 128.286 18.938 129.653 18.938C130.251 18.938 130.877 19.0949 131.311 19.3793L131.02 20.0981C130.571 19.8349 130.08 19.7067 129.646 19.7067C128.877 19.7067 128.564 20.006 128.564 20.3826C128.564 21.443 131.518 20.7528 131.518 22.5819C131.518 23.3792 130.87 24.0625 129.504 24.0625C128.728 24.0625 127.952 23.8204 127.539 23.4644Z'
      fill='white'
    />
    <path
      d='M134.634 23.7841C134.42 23.9553 134.1 24.0405 133.78 24.0405C132.947 24.0405 132.47 23.5993 132.47 22.7591V20.93H131.844V20.2186H132.47V19.3499H133.36V20.2186H134.377V20.93H133.36V22.738C133.36 23.1082 133.545 23.3074 133.886 23.3074C134.072 23.3074 134.25 23.2576 134.385 23.1512L134.634 23.7841Z'
      fill='white'
    />
    <path
      d='M137.656 22.8021V22.3608H136.738C136.133 22.3608 135.969 22.5886 135.969 22.8662C135.969 23.1861 136.24 23.3927 136.695 23.3927C137.129 23.3927 137.507 23.1935 137.656 22.8021ZM138.546 21.7627V23.9907H137.706V23.5283C137.492 23.8551 137.08 24.0405 136.51 24.0405C135.642 24.0405 135.094 23.5638 135.094 22.9016C135.094 22.2681 135.521 21.7702 136.674 21.7702H137.656V21.713C137.656 21.1933 137.343 20.8877 136.709 20.8877C136.282 20.8877 135.841 21.0296 135.557 21.2643L135.208 20.617C135.613 20.3039 136.204 20.1471 136.816 20.1471C137.912 20.1471 138.546 20.6668 138.546 21.7627Z'
      fill='white'
    />
    <path
      d='M143.424 21.8126V23.9902H142.534V21.9265C142.534 21.2506 142.2 20.9227 141.63 20.9227C140.997 20.9227 140.57 21.3073 140.57 22.0684V23.9902H139.68V20.1901H140.527V20.6806C140.819 20.332 141.282 20.1472 141.837 20.1472C142.747 20.1472 143.424 20.6668 143.424 21.8126Z'
      fill='white'
    />
    <path
      d='M147.441 22.0907C147.441 21.3644 146.95 20.902 146.309 20.902C145.669 20.902 145.177 21.3644 145.177 22.0907C145.177 22.8169 145.669 23.2794 146.309 23.2794C146.95 23.2794 147.441 22.8169 147.441 22.0907ZM148.317 18.71V23.9907H147.462V23.4997C147.163 23.8631 146.722 24.0405 146.202 24.0405C145.1 24.0405 144.281 23.2719 144.281 22.0907C144.281 20.9094 145.1 20.1477 146.202 20.1477C146.694 20.1477 147.128 20.3114 147.427 20.6599V18.71H148.317Z'
      fill='white'
    />
    <path
      d='M151.781 22.8021V22.3608H150.863C150.258 22.3608 150.094 22.5886 150.094 22.8662C150.094 23.1861 150.365 23.3927 150.82 23.3927C151.254 23.3927 151.632 23.1935 151.781 22.8021ZM152.671 21.7627V23.9907H151.831V23.5283C151.617 23.8551 151.205 24.0405 150.635 24.0405C149.767 24.0405 149.219 23.5638 149.219 22.9016C149.219 22.2681 149.646 21.7702 150.799 21.7702H151.781V21.713C151.781 21.1933 151.468 20.8877 150.834 20.8877C150.407 20.8877 149.966 21.0296 149.682 21.2643L149.333 20.617C149.738 20.3039 150.329 20.1471 150.941 20.1471C152.037 20.1471 152.671 20.6668 152.671 21.7627Z'
      fill='white'
    />
    <path
      d='M156.004 20.1472V20.9942C155.926 20.9799 155.862 20.9725 155.798 20.9725C155.122 20.9725 154.695 21.3714 154.695 22.1474V23.9902H153.805V20.1901H154.652V20.7447C154.908 20.3463 155.371 20.1472 156.004 20.1472Z'
      fill='white'
    />
    <path
      d='M159.566 22.0907C159.566 21.3644 159.075 20.902 158.434 20.902C157.794 20.902 157.302 21.3644 157.302 22.0907C157.302 22.8169 157.794 23.2794 158.434 23.2794C159.075 23.2794 159.566 22.8169 159.566 22.0907ZM160.442 18.71V23.9907H159.587V23.4997C159.288 23.8631 158.847 24.0405 158.327 24.0405C157.225 24.0405 156.406 23.2719 156.406 22.0907C156.406 20.9094 157.225 20.1477 158.327 20.1477C158.819 20.1477 159.253 20.3114 159.552 20.6599V18.71H160.442Z'
      fill='white'
    />
    <path
      d='M155.536 115.04H132.657C129.546 115.04 127.023 112.517 127.023 109.406V108.979C127.023 105.868 129.546 103.346 132.657 103.346H155.536C158.647 103.346 161.17 105.868 161.17 108.979V109.406C161.17 112.517 158.647 115.04 155.536 115.04Z'
      fill='#2091EA'
    />
    <path d='M157.659 80.5669H129.367V79.4223H157.659V80.5669Z' fill='#2091EA' />
    <path d='M157.659 61.7734H129.367V60.6288H157.659V61.7734Z' fill='#2091EA' />
    <path d='M157.659 43.6577H129.367V42.5131H157.659V43.6577Z' fill='#2091EA' />
    <path
      d='M147.996 70.2586C147.996 72.9759 145.793 75.1787 143.076 75.1787C140.359 75.1787 138.156 72.9759 138.156 70.2586C138.156 67.5413 140.359 65.3384 143.076 65.3384C145.793 65.3384 147.996 67.5413 147.996 70.2586Z'
      fill='#2091EA'
    />
    <path
      d='M146.597 69.1935L143.767 72.0242L142.447 73.3462L141.125 72.0242L142.445 70.7044L145.275 67.8715L146.597 69.1935Z'
      fill='white'
    />
    <path
      d='M143.772 72.0246L142.452 73.3472L139.648 70.5429L140.969 69.2225L142.45 70.7048L143.772 72.0246Z'
      fill='white'
    />
    <path
      d='M148.426 89.7317C148.426 92.449 146.223 94.6519 143.506 94.6519C140.788 94.6519 138.586 92.449 138.586 89.7317C138.586 87.0144 140.788 84.8116 143.506 84.8116C146.223 84.8116 148.426 87.0144 148.426 89.7317Z'
      fill='#2091EA'
    />
    <path
      d='M147.035 88.6667L144.204 91.4967L142.885 92.8193L141.562 91.4967L142.882 90.177L145.712 87.3441L147.035 88.6667Z'
      fill='white'
    />
    <path
      d='M144.194 91.4967L142.874 92.8193L140.07 90.015L141.391 88.6953L142.872 90.177L144.194 91.4967Z'
      fill='white'
    />
    <path
      d='M147.958 52.1444C147.958 54.8342 145.778 57.0142 143.089 57.0142C140.399 57.0142 138.219 54.8342 138.219 52.1444C138.219 49.4551 140.399 47.2746 143.089 47.2746C145.778 47.2746 147.958 49.4551 147.958 52.1444Z'
      fill='#1A2E35'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M147.966 52.1439C147.966 54.8338 145.786 57.0137 143.096 57.0137C140.407 57.0137 138.227 54.8338 138.227 52.1439C138.227 49.4546 140.407 47.2741 143.096 47.2741C145.786 47.2741 147.966 49.4546 147.966 52.1439Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M145.833 50.7007L141.636 54.8975L140.328 53.5897L144.525 49.393L145.833 50.7007Z'
      fill='white'
    />
    <path
      d='M141.636 49.393L145.833 53.5897L144.525 54.8975L140.328 50.7007L141.636 49.393Z'
      fill='white'
    />
    <path
      d='M165.562 14.9565V28.02L168.319 25.2632V14.9565H165.562Z'
      fill='#2091EA'
    />
    <path
      d='M171.077 14.9565V28.02L168.32 25.2632V14.9565H171.077Z'
      fill='#2091EA'
    />
    <g opacity='0.300003'>
      <g opacity='0.300003'>
        <path
          opacity='0.300003'
          d='M171.081 14.9579V28.0254L168.324 25.2634L165.562 28.0254V14.9579H171.081Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M169.67 1.90048C170.065 2.64219 170.977 2.93865 171.733 2.57065C172.858 2.0241 174.138 2.9541 173.966 4.19314C173.85 5.02585 174.413 5.80133 175.241 5.94841C176.473 6.16704 176.962 7.67163 176.094 8.57302C175.511 9.17852 175.511 10.1371 176.094 10.7426C176.962 11.6435 176.473 13.1486 175.241 13.3672C174.413 13.5143 173.85 14.2898 173.966 15.1225C174.138 16.3621 172.858 17.2921 171.733 16.7444C170.977 16.377 170.065 16.6729 169.67 17.4152C169.081 18.5192 167.499 18.5192 166.91 17.4152C166.514 16.6729 165.603 16.377 164.846 16.7444C163.721 17.2921 162.442 16.3621 162.614 15.1225C162.73 14.2898 162.167 13.5143 161.339 13.3672C160.107 13.1486 159.618 11.6435 160.486 10.7426C161.069 10.1371 161.069 9.17852 160.486 8.57302C159.618 7.67163 160.107 6.16704 161.339 5.94841C162.167 5.80133 162.73 5.02585 162.614 4.19314C162.442 2.9541 163.721 2.0241 164.846 2.57065C165.603 2.93865 166.514 2.64219 166.91 1.90048C167.499 0.796495 169.081 0.796495 169.67 1.90048Z'
      fill='#2091EA'
    />
    <path
      d='M169.67 1.90048C170.065 2.64219 170.977 2.93865 171.733 2.57065C172.858 2.0241 174.138 2.9541 173.966 4.19314C173.85 5.02585 174.413 5.80133 175.241 5.94841C176.473 6.16704 176.962 7.67163 176.094 8.57302C175.511 9.17852 175.511 10.1371 176.094 10.7426C176.962 11.6435 176.473 13.1486 175.241 13.3672C174.413 13.5143 173.85 14.2898 173.966 15.1225C174.138 16.3621 172.858 17.2921 171.733 16.7444C170.977 16.377 170.065 16.6729 169.67 17.4152C169.081 18.5192 167.499 18.5192 166.91 17.4152C166.514 16.6729 165.603 16.377 164.846 16.7444C163.721 17.2921 162.442 16.3621 162.614 15.1225C162.73 14.2898 162.167 13.5143 161.339 13.3672C160.107 13.1486 159.618 11.6435 160.486 10.7426C161.069 10.1371 161.069 9.17852 160.486 8.57302C159.618 7.67163 160.107 6.16704 161.339 5.94841C162.167 5.80133 162.73 5.02585 162.614 4.19314C162.442 2.9541 163.721 2.0241 164.846 2.57065C165.603 2.93865 166.514 2.64219 166.91 1.90048C167.499 0.796495 169.081 0.796495 169.67 1.90048Z'
      fill='#2091EA'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M169.678 1.90048C170.073 2.64219 170.985 2.93865 171.741 2.57065C172.866 2.0241 174.146 2.9541 173.973 4.19314C173.858 5.02585 174.421 5.80133 175.249 5.94841C176.481 6.16704 176.97 7.67163 176.102 8.57302C175.519 9.17852 175.519 10.1371 176.102 10.7426C176.97 11.6435 176.481 13.1486 175.249 13.3672C174.421 13.5143 173.858 14.2898 173.973 15.1225C174.146 16.3621 172.866 17.2921 171.741 16.7444C170.985 16.377 170.073 16.6729 169.678 17.4152C169.089 18.5192 167.507 18.5192 166.918 17.4152C166.522 16.6729 165.611 16.377 164.854 16.7444C163.729 17.2921 162.449 16.3621 162.622 15.1225C162.738 14.2898 162.175 13.5143 161.347 13.3672C160.115 13.1486 159.626 11.6435 160.494 10.7426C161.077 10.1371 161.077 9.17852 160.494 8.57302C159.626 7.67163 160.115 6.16704 161.347 5.94841C162.175 5.80133 162.738 5.02585 162.622 4.19314C162.449 2.9541 163.729 2.0241 164.854 2.57065C165.611 2.93865 166.522 2.64219 166.918 1.90048C167.507 0.796495 169.089 0.796495 169.678 1.90048Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M165.808 8.01907H165.1C164.765 8.01907 164.479 8.29091 164.461 8.62571L164.29 11.8953C164.272 12.2301 164.53 12.502 164.865 12.502H165.573C165.907 12.502 166.193 12.2301 166.211 11.8953L166.382 8.62571C166.4 8.29091 166.143 8.01907 165.808 8.01907Z'
      fill='white'
    />
    <path
      d='M171.475 8.01906H169.813L170.276 6.93053C170.326 6.81207 170.345 6.68387 170.331 6.55968L170.233 5.70122C170.193 5.35783 169.902 5.098 169.556 5.098H168.728C168.683 5.96276 168.369 6.79203 167.831 7.47079L167.323 8.11292C167.189 8.27946 167.11 8.48664 167.099 8.6961L166.938 11.7545C166.917 12.1654 167.236 12.502 167.648 12.502H170.549C170.803 12.502 171.051 12.3898 171.226 12.1946L171.497 11.8942C171.604 11.7751 171.678 11.6309 171.713 11.4752L172.267 9.00801C172.318 8.77909 172.268 8.53929 172.129 8.35043C171.976 8.14211 171.733 8.01906 171.475 8.01906Z'
      fill='white'
    />
    <path
      d='M143.074 28.0208L143.995 29.4933L145.68 29.914L144.564 31.2446L144.685 32.9775L143.074 32.3268L141.464 32.9775L141.585 31.2446L140.469 29.914L142.154 29.4933L143.074 28.0208Z'
      fill='white'
    />
    <path
      d='M134.12 110.312C134.12 110.081 133.936 109.936 133.656 109.832L133.441 110.928C133.872 110.839 134.12 110.616 134.12 110.312ZM133.129 108.458L133.36 107.306C132.904 107.394 132.649 107.641 132.641 107.945C132.641 108.194 132.841 108.337 133.129 108.458ZM135.415 110.105C135.415 111.167 134.56 111.879 133.224 111.991L133.049 112.871H132.249L132.425 111.991C131.665 111.919 130.994 111.68 130.586 111.375L131.122 110.384C131.545 110.696 132.081 110.896 132.633 110.952L132.904 109.601C132.161 109.377 131.345 109.081 131.345 108.113C131.345 107.05 132.217 106.331 133.576 106.234L133.752 105.355H134.551L134.376 106.243C134.943 106.298 135.463 106.459 135.847 106.699L135.36 107.689C135.015 107.458 134.6 107.322 134.167 107.274L133.888 108.681C134.624 108.897 135.415 109.185 135.415 110.105Z'
      fill='white'
    />
    <path
      d='M94.8632 127.382H61.3803C55.4924 127.382 50.7188 122.609 50.7188 116.721V25.8468C50.7188 19.9589 55.4924 15.1859 61.3803 15.1859H94.8632C100.752 15.1859 105.525 19.9589 105.525 25.8468V116.721C105.525 122.609 100.752 127.382 94.8632 127.382Z'
      fill='#1A2E35'
    />
    <g opacity='0.699997'>
      <g opacity='0.699997'>
        <path
          opacity='0.699997'
          d='M94.6978 15.1854H61.5451C55.5662 15.1854 50.7188 20.0329 50.7188 26.0118V116.555C50.7188 122.535 55.5662 127.382 61.5451 127.382H94.6978C100.677 127.382 105.524 122.535 105.524 116.555V26.0118C105.524 20.0329 100.677 15.1854 94.6978 15.1854Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M105.525 20.6893V42.9395H50.7188V20.6893C50.7188 17.6509 53.1837 15.1859 56.2249 15.1859H100.019C103.06 15.1859 105.525 17.6509 105.525 20.6893Z'
      fill='#2091EA'
    />
    <path
      d='M105.525 20.6893V42.9395H50.7188V20.6893C50.7188 17.6509 53.1837 15.1859 56.2249 15.1859H100.019C103.06 15.1859 105.525 17.6509 105.525 20.6893Z'
      fill='#2091EA'
    />
    <path
      d='M72.6898 28.9315C72.6898 28.433 72.3127 28.2126 71.615 28.2126H70.1207V29.6503H71.615C72.3127 29.6503 72.6898 29.43 72.6898 28.9315ZM70.1207 26.1203V27.4938H71.4302C72.0706 27.4938 72.4334 27.2661 72.4334 26.8105C72.4334 26.3481 72.0706 26.1203 71.4302 26.1203H70.1207ZM73.6221 29.0242C73.6221 29.8781 72.9531 30.376 71.6723 30.376H69.1953V25.394H71.5298C72.7253 25.394 73.3663 25.8994 73.3663 26.6897C73.3663 27.202 73.1099 27.5791 72.7253 27.7857C73.2593 27.9563 73.6221 28.3763 73.6221 29.0242Z'
      fill='white'
    />
    <path
      d='M76.8278 29.1878V28.7465H75.9099C75.3049 28.7465 75.1413 28.9743 75.1413 29.2519C75.1413 29.5718 75.4114 29.7784 75.8669 29.7784C76.3013 29.7784 76.6785 29.5793 76.8278 29.1878ZM77.7178 28.1485V30.3765H76.8776V29.9141C76.6642 30.2408 76.2515 30.4263 75.6821 30.4263C74.8139 30.4263 74.2656 29.9495 74.2656 29.2874C74.2656 28.6538 74.6931 28.1559 75.8458 28.1559H76.8278V28.0987C76.8278 27.579 76.5148 27.2734 75.8812 27.2734C75.4543 27.2734 75.0131 27.4154 74.7286 27.65L74.3795 27.0027C74.7853 26.6897 75.3759 26.5329 75.9883 26.5329C77.0842 26.5329 77.7178 27.0525 77.7178 28.1485Z'
      fill='white'
    />
    <path
      d='M78.4141 29.9993L78.7557 29.3234C79.09 29.5438 79.6027 29.7006 80.0795 29.7006C80.6421 29.7006 80.8767 29.5438 80.8767 29.2805C80.8767 28.5548 78.528 29.2376 78.528 27.7221C78.528 27.0033 79.1758 26.5334 80.2002 26.5334C80.7056 26.5334 81.2825 26.6542 81.6236 26.8608L81.2825 27.5367C80.9191 27.3232 80.5562 27.2522 80.1934 27.2522C79.6525 27.2522 79.3961 27.4302 79.3961 27.6792C79.3961 28.4478 81.7449 27.7645 81.7449 29.2519C81.7449 29.9638 81.0902 30.4263 80.0228 30.4263C79.3893 30.4263 78.7557 30.2414 78.4141 29.9993Z'
      fill='white'
    />
    <path
      d='M82.5114 26.5772H83.4013V30.3779H82.5114V26.5772ZM82.3906 25.4166C82.3906 25.1178 82.6321 24.8832 82.9601 24.8832C83.2874 24.8832 83.5289 25.1035 83.5289 25.3954C83.5289 25.7084 83.2943 25.9505 82.9601 25.9505C82.6321 25.9505 82.3906 25.7159 82.3906 25.4166Z'
      fill='white'
    />
    <path
      d='M84.2578 28.4764C84.2578 27.3375 85.1117 26.5334 86.3147 26.5334C87.0547 26.5334 87.6453 26.8396 87.9372 27.4159L87.2538 27.8143C87.0261 27.4514 86.685 27.2877 86.3078 27.2877C85.6531 27.2877 85.1546 27.7433 85.1546 28.4764C85.1546 29.2164 85.6531 29.6651 86.3078 29.6651C86.685 29.6651 87.0261 29.5014 87.2538 29.138L87.9372 29.5369C87.6453 30.1063 87.0547 30.4263 86.3147 30.4263C85.1117 30.4263 84.2578 29.6153 84.2578 28.4764Z'
      fill='white'
    />
    <path
      d='M82.1849 77.6703C82.1849 80.3601 80.0044 82.54 77.3151 82.54C74.6258 82.54 72.4453 80.3601 72.4453 77.6703C72.4453 74.981 74.6258 72.8005 77.3151 72.8005C80.0044 72.8005 82.1849 74.981 82.1849 77.6703Z'
      fill='#1A2E35'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M82.1927 77.6693C82.1927 80.3591 80.0122 82.5391 77.3229 82.5391C74.6336 82.5391 72.4531 80.3591 72.4531 77.6693C72.4531 74.98 74.6336 72.7995 77.3229 72.7995C80.0122 72.7995 82.1927 74.98 82.1927 77.6693Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M80.0591 76.2256L75.8624 80.4224L74.5547 79.1146L78.7514 74.9179L80.0591 76.2256Z'
      fill='white'
    />
    <path
      d='M75.8624 74.9179L80.0591 79.1146L78.7514 80.4224L74.5547 76.2256L75.8624 74.9179Z'
      fill='white'
    />
    <path
      d='M82.4569 95.6678C82.4569 98.3852 80.2541 100.587 77.5367 100.587C74.8194 100.587 72.6172 98.3852 72.6172 95.6678C72.6172 92.9505 74.8194 90.7477 77.5367 90.7477C80.2541 90.7477 82.4569 92.9505 82.4569 95.6678Z'
      fill='#2091EA'
    />
    <path
      d='M81.0667 94.6022L78.2361 97.4329L76.9164 98.7549L75.5938 97.4329L76.9141 96.1125L79.7441 93.2802L81.0667 94.6022Z'
      fill='white'
    />
    <path
      d='M78.2256 97.4329L76.9053 98.7549L74.1016 95.9511L75.4219 94.6308L76.9036 96.1125L78.2256 97.4329Z'
      fill='white'
    />
    <path
      d='M89.5676 121.425H66.6879C63.5768 121.425 61.0547 118.903 61.0547 115.791V115.365C61.0547 112.254 63.5768 109.731 66.6879 109.731H89.5676C92.6793 109.731 95.2009 112.254 95.2009 115.365V115.791C95.2009 118.903 92.6793 121.425 89.5676 121.425Z'
      fill='#2091EA'
    />
    <path
      d='M67.159 116.893C67.159 116.662 66.9747 116.517 66.6949 116.413L66.4797 117.509C66.9106 117.421 67.159 117.197 67.159 116.893ZM66.1678 115.039L66.399 113.887C65.9434 113.975 65.6882 114.222 65.6802 114.526C65.6802 114.775 65.8799 114.918 66.1678 115.039ZM68.4541 116.686C68.4541 117.748 67.5991 118.46 66.2633 118.572L66.0876 119.452H65.2881L65.4644 118.572C64.7044 118.5 64.0331 118.261 63.625 117.956L64.1607 116.965C64.5842 117.277 65.1199 117.477 65.6716 117.533L65.9434 116.181C65.2006 115.958 64.3845 115.662 64.3845 114.694C64.3845 113.631 65.2561 112.912 66.6153 112.815L66.791 111.936H67.5905L67.4148 112.824C67.9826 112.879 68.5022 113.04 68.8857 113.279L68.3986 114.271C68.0541 114.039 67.6386 113.903 67.2065 113.854L66.9272 115.262C67.6626 115.478 68.4541 115.765 68.4541 116.686Z'
      fill='white'
    />
    <path d='M91.6821 86.9536H63.3906V85.809H91.6821V86.9536Z' fill='#2091EA' />
    <path d='M91.6821 68.1587H63.3906V67.0141H91.6821V68.1587Z' fill='#2091EA' />
    <path d='M91.6821 50.0444H63.3906V48.8998H91.6821V50.0444Z' fill='#2091EA' />
    <path
      d='M82.1849 58.5301C82.1849 61.2194 80.0044 63.3999 77.3151 63.3999C74.6258 63.3999 72.4453 61.2194 72.4453 58.5301C72.4453 55.8403 74.6258 53.6604 77.3151 53.6604C80.0044 53.6604 82.1849 55.8403 82.1849 58.5301Z'
      fill='#1A2E35'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M82.1927 58.5301C82.1927 61.2194 80.0122 63.3999 77.3229 63.3999C74.6336 63.3999 72.4531 61.2194 72.4531 58.5301C72.4531 55.8403 74.6336 53.6604 77.3229 53.6604C80.0122 53.6604 82.1927 55.8403 82.1927 58.5301Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M80.0591 57.0865L75.8624 61.2832L74.5547 59.9755L78.7514 55.7788L80.0591 57.0865Z'
      fill='white'
    />
    <path
      d='M75.8624 55.7788L80.0591 59.9755L78.7514 61.2832L74.5547 57.0865L75.8624 55.7788Z'
      fill='white'
    />
    <path
      d='M148.852 202.689L153.24 203.492L156.561 195.393L151.766 193.477L148.852 202.689Z'
      fill='#FFC3BD'
    />
    <g opacity='0.100006'>
      <g opacity='0.100006'>
        <path
          opacity='0.100006'
          d='M150.438 197.556L151.69 193.79L153.901 194.329L156.572 195.396L155.14 198.925L150.438 197.556Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M147.083 201.054C146.259 200.914 145.39 200.657 145.269 200.368C145.249 200.326 145.236 200.251 145.362 200.14C145.43 200.076 145.524 200.04 145.625 200.046C145.706 200.045 145.782 200.057 145.857 200.073C146.392 200.279 146.885 200.567 147.327 200.93C147.409 200.988 147.491 201.057 147.573 201.127C147.416 201.11 147.253 201.082 147.083 201.054ZM148.001 201.17C147.982 201.153 147.83 201.015 147.603 200.836C147.439 200.709 147.243 200.559 147.028 200.415C146.567 200.104 146.031 199.823 145.623 199.826C145.46 199.826 145.303 199.885 145.191 199.985C145.041 200.096 144.986 200.282 145.05 200.45C145.184 200.734 145.775 200.986 146.379 201.133C146.518 201.161 146.65 201.189 146.788 201.218C147.159 201.286 147.531 201.335 147.908 201.362H147.914C147.958 201.362 148.002 201.339 148.021 201.292C148.045 201.251 148.032 201.205 148.001 201.17Z'
      fill='#2091EA'
    />
    <path
      d='M147.829 201.01C147.772 200.952 147.709 200.895 147.652 200.825C147.196 200.329 146.777 199.559 146.881 199.297C146.894 199.274 146.931 199.198 147.145 199.231L147.208 199.254C147.353 199.288 147.479 199.369 147.567 199.478C147.79 199.889 147.888 200.342 147.853 200.795C147.847 200.865 147.841 200.934 147.829 201.01ZM147.774 199.367C147.635 199.187 147.426 199.066 147.187 199.033C146.86 198.983 146.723 199.112 146.674 199.228C146.543 199.484 146.742 199.966 147.027 200.417C147.141 200.591 147.268 200.758 147.395 200.908C147.553 201.094 147.711 201.243 147.838 201.341C147.85 201.353 147.869 201.358 147.888 201.358C147.894 201.358 147.901 201.364 147.907 201.364H147.913C147.932 201.369 147.951 201.376 147.97 201.369C148.008 201.357 148.039 201.323 148.044 201.288C148.051 201.264 148.08 201.061 148.091 200.782C148.114 200.352 148.072 199.742 147.774 199.367Z'
      fill='#2091EA'
    />
    <path
      d='M148.579 200.85L153.884 202.119C154.084 202.167 154.221 202.336 154.214 202.527L154.057 206.747C154.046 207.121 153.709 207.416 153.304 207.406C153.252 207.404 153.201 207.398 153.15 207.387C151.247 206.899 149.573 206.395 147.191 205.829C144.382 205.159 145.007 205.64 141.692 204.893C139.695 204.442 139.916 202.263 140.788 202.362C144.776 202.836 145.479 201.853 147.326 200.931C147.719 200.764 148.164 200.735 148.579 200.85Z'
      fill='#1A2E35'
    />
    <path
      d='M180.445 203.487H184.647L185.887 196.526L180.75 195.566L180.445 203.487Z'
      fill='#FFC3BD'
    />
    <g opacity='0.100006'>
      <g opacity='0.100006'>
        <path
          opacity='0.100006'
          d='M180.758 195.563L185.895 196.523L185.06 201.212L180.586 201.029L180.758 195.563Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M186.798 199.933L179.251 199.503L179.023 196.529L187.465 197.106L186.798 199.933Z'
      fill='#2091EA'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M186.813 199.933L179.266 199.503L179.039 196.529L187.481 197.106L186.813 199.933Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M178.975 203.702C178.196 203.761 177.351 203.714 177.17 203.462C177.134 203.427 177.105 203.362 177.187 203.222C177.234 203.14 177.311 203.081 177.404 203.063C177.474 203.046 177.545 203.034 177.616 203.034C178.154 203.11 178.676 203.274 179.163 203.52C179.257 203.555 179.35 203.602 179.439 203.655C179.292 203.673 179.134 203.69 178.975 203.702ZM179.837 203.596C179.813 203.585 179.643 203.485 179.391 203.362C179.222 203.28 179.011 203.187 178.782 203.098C178.284 202.899 177.715 202.747 177.34 202.852C177.193 202.887 177.07 202.981 176.994 203.11C176.888 203.257 176.888 203.451 176.988 203.596C177.181 203.843 177.785 203.948 178.366 203.942C178.495 203.942 178.624 203.936 178.752 203.931C179.098 203.907 179.444 203.866 179.784 203.802H179.79H179.801C179.843 203.79 179.878 203.755 179.89 203.708C179.895 203.667 179.872 203.62 179.837 203.596Z'
      fill='#2091EA'
    />
    <path
      d='M178.344 202.031C178.35 202.001 178.368 201.925 178.567 201.901L178.625 201.907C178.766 201.907 178.907 201.954 179.012 202.048C179.317 202.394 179.516 202.816 179.604 203.261C179.621 203.337 179.633 203.408 179.639 203.478C179.575 203.437 179.505 203.391 179.434 203.344C178.895 202.962 178.32 202.312 178.344 202.031ZM179.909 203.695C179.909 203.672 179.885 203.467 179.827 203.191C179.727 202.763 179.534 202.183 179.17 201.884C179 201.743 178.778 201.673 178.555 201.702C178.244 201.732 178.151 201.884 178.139 202.013C178.086 202.3 178.397 202.728 178.784 203.097C178.924 203.238 179.077 203.367 179.223 203.473C179.411 203.619 179.592 203.73 179.739 203.795C179.751 203.801 179.768 203.807 179.786 203.801H179.791H179.803C179.804 203.801 179.805 203.8 179.805 203.8C179.824 203.803 179.843 203.804 179.862 203.795C179.892 203.771 179.915 203.736 179.909 203.695Z'
      fill='#2091EA'
    />
    <path
      d='M180.271 203.143L185.384 203.114C185.577 203.113 185.744 203.245 185.787 203.433L186.735 207.587C186.822 207.955 186.594 208.324 186.226 208.41C186.179 208.422 186.131 208.428 186.082 208.429C184.239 208.407 182.6 208.315 180.305 208.331C177.597 208.348 178.285 208.668 175.102 208.731C173.184 208.768 172.82 206.589 173.633 206.477C177.353 205.988 177.734 204.862 179.162 203.521C179.473 203.263 179.867 203.129 180.271 203.143Z'
      fill='#1A2E35'
    />
    <path
      d='M170.979 100.669L151.164 193.407L158.468 195.351C163.931 183.397 168.506 171.169 171.92 161.604C173.54 157.066 174.19 152.797 175.428 148.798C176.9 144.042 178.886 139.668 179.897 135.674C185.192 114.764 184.764 104.286 184.764 104.286L185.892 101.529L170.979 100.669Z'
      fill='#1A2E35'
    />
    <g opacity='0.300003'>
      <g opacity='0.300003'>
        <path
          opacity='0.300003'
          d='M183.141 120.571C182.421 124.788 181.388 129.822 179.906 135.673C179.424 137.573 176.199 146.724 175.437 148.796C174.543 135.403 177.603 112.761 177.603 112.761L183.141 120.571Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M177.062 101.148L178.556 152.093L179.864 196.718L186.751 197.194C188.668 186.269 190.073 176.358 191.084 167.433C191.745 161.602 190.622 157.337 190.98 152.341C191.04 151.511 191.107 150.658 191.18 149.791C192.381 135.484 192.487 121.101 191.426 106.784C191.224 104.055 191.089 102.232 191.089 102.232L177.062 100.438V101.148Z'
      fill='#1A2E35'
    />
    <path
      d='M157.497 197.935L150.273 195.707L150.766 192.765L158.823 195.35L157.497 197.935Z'
      fill='#2091EA'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M157.489 197.933L150.266 195.705L150.758 192.763L158.815 195.348L157.489 197.933Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M176.083 68.8412C175.887 69.1153 174.254 71.3742 173.11 75.3587C172.831 76.3213 172.58 77.38 172.391 78.543L171.889 79.9131L167.472 78.1578L166.773 77.8843C167.848 73.0334 172.015 68.6489 175.106 68.7153C175.517 68.7227 175.838 68.7525 176.083 68.8412Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M176.09 68.8388C175.895 69.1129 174.262 71.3718 173.118 75.3562C172.839 76.3188 172.587 77.3776 172.399 78.5405L171.897 79.9106L167.479 78.1554L166.781 77.8818C167.856 73.0309 172.022 68.6465 175.113 68.7129C175.525 68.7203 175.846 68.7501 176.09 68.8388Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M170.57 100.237C171.104 99.0502 171.037 96.7272 172.794 96.2911C175.129 95.7113 177.049 95.5431 186.241 96.3243C190.577 96.6928 191.539 100.158 191.414 101.936C191.398 102.174 191.317 102.401 191.182 102.597L190.998 102.861C190.566 103.027 170.898 100.845 170.703 100.966C170.703 100.966 170.394 100.627 170.57 100.237Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M170.578 100.239C171.112 99.0521 171.045 96.7291 172.802 96.293C175.137 95.7133 177.057 95.545 186.249 96.3262C190.584 96.6948 191.547 100.16 191.422 101.938C191.406 102.176 191.325 102.403 191.189 102.599L191.006 102.863C190.574 103.029 170.906 100.847 170.711 100.968C170.711 100.968 170.401 100.629 170.578 100.239Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M175.344 68.8067C175.218 69.0396 171.309 69.4465 171.618 86.5768C171.658 88.7779 171.098 93.4548 171.098 93.4548C171.099 93.4594 171.098 93.464 171.097 93.468L170.531 94.8913C170.065 96.0623 170.137 97.3792 170.728 98.4929L171.01 99.0257C171.014 99.0331 171.021 99.0383 171.029 99.0389L190.851 101.055C190.856 101.056 190.861 101.055 190.866 101.052L191.436 101.056C191.436 101.056 192.715 90.7072 194.421 83.7914C194.977 81.5342 195.582 78.7665 195.582 78.7665C194.902 73.8378 194.982 70.8492 194.999 70.8819C195.005 70.8944 194.996 70.8532 194.982 70.8492C192.58 70.139 190.129 69.6039 187.65 69.2485C185.018 68.8433 182.359 68.627 179.696 68.6012C177.185 68.6642 175.348 68.7992 175.344 68.8067Z'
      fill='#2091EA'
    />
    <path
      d='M187.597 60.2329C186.884 62.7568 185.997 67.4068 187.652 69.2651L186.398 70.5739C184.192 72.8769 180.307 71.742 179.688 68.6144C182.586 68.1594 181.972 65.2469 182.503 62.4323L187.597 60.2329Z'
      fill='#FFC3BD'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M180.977 59.5274C181.578 61.5425 182.394 64.7835 181.789 67.0189C184.394 67.2496 186.123 64.8836 186.227 64.1316C186.227 64.1316 185.584 62.2979 185.477 61.4011L180.977 59.5274Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M188.358 56.2876C188.046 60.5793 188.008 62.4004 185.817 64.5786C182.52 67.8557 177.32 66.5353 176.341 62.243C175.46 58.3754 176.115 52.0136 180.337 50.3487C183.349 49.1498 186.763 50.62 187.962 53.6327C188.298 54.4751 188.433 55.3839 188.358 56.2876Z'
      fill='#FFC3BD'
    />
    <path
      d='M190.841 54.299C190.382 55.9244 190.164 57.4095 189.974 59.2529C189.447 59.2461 188.867 59.2449 188.34 59.2375C188.04 59.5362 187.916 60.0027 188.069 60.397C187.809 60.2653 187.545 60.108 187.396 59.8567C187.256 59.6198 187.239 59.3331 187.22 59.0583C187.15 58.0242 187.101 56.9322 186.886 55.9181C186.045 55.5759 185.072 55.6634 184.285 55.209C182.528 54.1954 180.975 52.8465 179.784 51.2046C178.483 51.7626 177.476 53.0176 177.063 54.3711C176.691 54.4764 176.285 54.2687 176.058 53.9562C175.832 53.6432 175.755 53.2483 175.722 52.8637C175.636 51.857 175.839 50.7954 176.451 49.9924C176.877 49.4338 177.524 49.0189 178.222 48.9062C178.524 48.857 178.834 48.9308 179.108 48.8009C179.404 48.6595 179.67 48.303 179.966 48.125C181.267 47.3421 182.922 47.2396 184.368 47.6311C186.199 48.1267 187.763 49.3159 189.145 50.6145C189.898 51.3213 191.121 53.3055 190.841 54.299Z'
      fill='#1A2E35'
    />
    <path
      d='M189.851 60.4129C189.431 61.1329 188.743 61.6566 187.938 61.87C186.872 62.1407 186.362 61.1827 186.679 60.1886C186.962 59.2901 187.868 58.0865 188.922 58.2319C189.692 58.3687 190.205 59.1029 190.069 59.8727C190.034 60.0661 189.96 60.2498 189.851 60.4129Z'
      fill='#FFC3BD'
    />
    <path
      d='M181.717 57.3668C181.712 57.7405 181.508 58.0375 181.267 58.041C181.026 58.045 180.824 57.7325 180.828 57.3588C180.833 56.9851 181.038 56.688 181.279 56.6846C181.52 56.6806 181.722 56.9931 181.717 57.3668Z'
      fill='#1A2E35'
    />
    <path
      d='M177.462 57.3205C177.452 57.6965 177.252 57.9913 177.006 57.997C176.76 58.0022 176.563 57.688 176.571 57.3074C176.579 56.9268 176.775 56.6389 177.021 56.6332C177.267 56.6275 177.479 56.936 177.462 57.3205Z'
      fill='#1A2E35'
    />
    <path
      d='M179.14 58.0987C178.794 58.9509 178.335 59.7527 177.773 60.4818C178.157 60.7617 178.646 60.8578 179.107 60.7445L179.14 58.0987Z'
      fill='#FF847F'
    />
    <path
      d='M180.436 62.0138C180.224 62.1037 180.004 62.1712 179.778 62.2164C179.727 62.2278 179.676 62.1964 179.665 62.146C179.665 62.1454 179.665 62.1449 179.665 62.1449C179.659 62.0956 179.691 62.0493 179.739 62.0373C180.678 61.8656 181.479 61.2532 181.891 60.3913C181.903 60.3444 181.948 60.3141 181.996 60.3215C182.042 60.3272 182.074 60.3684 182.069 60.4136C182.069 60.4182 182.067 60.4228 182.066 60.4279C181.75 61.1485 181.165 61.7173 180.436 62.0138Z'
      fill='#1A2E35'
    />
    <path
      d='M183.202 55.2429C183.154 55.2584 183.097 55.2441 183.054 55.2046C182.673 54.84 182.177 54.6758 181.723 54.7639C181.645 54.7828 181.558 54.729 181.526 54.6414C181.496 54.5544 181.533 54.4663 181.61 54.4446C181.611 54.444 181.612 54.444 181.613 54.444C182.161 54.3295 182.763 54.5235 183.226 54.9631C183.293 55.0254 183.31 55.1273 183.264 55.1948C183.249 55.2172 183.227 55.2332 183.202 55.2429Z'
      fill='#1A2E35'
    />
    <path
      d='M176.344 55.4409C176.283 55.4638 176.215 55.4512 176.167 55.4083C176.089 55.3442 176.08 55.2291 176.144 55.1525C176.144 55.1519 176.144 55.1519 176.145 55.1513C176.583 54.6076 177.26 54.3135 177.957 54.3632C178.054 54.3707 178.127 54.4554 178.119 54.5527C178.119 54.5578 178.118 54.5624 178.118 54.5676C178.104 54.6666 178.012 54.737 177.913 54.7232C177.912 54.7232 177.912 54.7232 177.912 54.7232C177.338 54.6923 176.784 54.9396 176.424 55.3865C176.403 55.4117 176.375 55.43 176.344 55.4409Z'
      fill='#1A2E35'
    />
    <path
      d='M186.951 67.897L188.535 69.3872C188.535 69.3872 182.809 73.394 182.717 73.1382C182.624 72.8812 181.438 71.5431 181.438 71.5431L186.951 67.897Z'
      fill='#2091EA'
    />
    <path
      d='M181.161 67.7573L179.141 68.5614C179.141 68.5614 179.482 72.8858 179.661 72.6815C179.841 72.4766 181.437 71.6679 181.437 71.6679L181.161 67.7573Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M186.959 67.8974L188.543 69.3877C188.543 69.3877 182.817 73.3945 182.724 73.1386C182.632 72.8817 181.445 71.5436 181.445 71.5436L186.959 67.8974Z'
          fill='white'
        />
      </g>
    </g>
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M181.154 67.7578L179.133 68.5619C179.133 68.5619 179.474 72.8863 179.654 72.6819C179.833 72.4771 181.429 71.6684 181.429 71.6684L181.154 67.7578Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M180.086 85.6207C180.453 86.1621 183.436 85.6207 183.436 85.6207L185.991 85.7953C186.27 85.7993 186.512 85.5881 186.552 85.3088L187.265 82.0787C187.285 81.9179 187.179 81.7794 187.025 81.753L185.003 81.3879C184.189 81.2414 183.35 81.4194 182.666 81.8835L180.527 83.3349L180.086 85.6207Z'
      fill='#FFC3BD'
    />
    <path
      d='M180.652 83.1758L180.52 85.667C180.52 85.667 169.02 89.9862 165.719 88.0953C163.556 86.8597 165.719 81.6197 167.471 78.1441L171.888 79.7986L170.429 83.6016L180.652 83.1758Z'
      fill='#FFC3BD'
    />
    <g opacity='0.100006'>
      <g opacity='0.100006'>
        <path
          opacity='0.100006'
          d='M180.094 85.6197C180.461 86.1611 183.443 85.6197 183.443 85.6197L185.999 85.7943C186.278 85.7983 186.52 85.5871 186.56 85.3078L187.273 82.0777C187.293 81.9169 187.187 81.7784 187.033 81.7521L185.01 81.3869C184.197 81.2404 183.358 81.4184 182.674 81.8825L180.535 83.3339L180.094 85.6197Z'
          fill='black'
        />
      </g>
      <g opacity='0.100006'>
        <path
          opacity='0.100006'
          d='M180.652 83.1748L180.52 85.6661C180.52 85.6661 169.02 89.9853 165.719 88.0944C163.556 86.8588 165.719 81.6187 167.471 78.1431L171.888 79.7976L170.429 83.6006L180.652 83.1748Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M194.269 72.6675L185.785 84.3403L173.364 82.0505L172.805 84.4982C172.805 84.4982 184.839 91.0454 188.125 89.1276C191.41 87.2098 196.938 75.6006 196.938 75.6006L194.269 72.6675Z'
      fill='#FFC3BD'
    />
    <path
      d='M172.8 84.5002C172.426 85.0364 169.451 84.4578 169.451 84.4578L166.893 84.5998C166.614 84.6015 166.374 84.3869 166.338 84.107L165.666 80.8684C165.649 80.7075 165.756 80.5702 165.91 80.5456L167.937 80.2062C168.752 80.0694 169.589 80.2577 170.267 80.731L173.367 82.1331L172.8 84.5002Z'
      fill='#FFC3BD'
    />
    <path
      d='M195.878 71.5723C197.876 73.9331 197.288 79.7683 194.411 83.4814L187.398 79.3603C187.398 79.3603 191.513 72.4119 193.785 71.0446C194.754 70.4609 195.147 70.7087 195.878 71.5723Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M195.886 71.5698C197.884 73.9306 197.296 79.7659 194.419 83.479L187.406 79.3578C187.406 79.3578 191.521 72.4094 193.793 71.0422C194.762 70.4584 195.155 70.7062 195.886 71.5698Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M132.742 204.244L137.191 204.855L141.734 195.986L136.816 195.169L132.742 204.244Z'
      fill='#FFC3BD'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M136.799 195.171L141.717 195.989L139.24 200.815L134.578 200.114L136.799 195.171Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M138.476 203.171L132.977 202.871C132.789 202.857 132.62 202.98 132.574 203.161L131.462 207.146C131.364 207.498 131.57 207.862 131.922 207.96C131.925 207.961 131.929 207.962 131.932 207.962L132.079 207.971C133.859 208.037 135.142 207.997 137.397 208.12C138.772 208.195 142.92 208.569 144.82 208.672C146.721 208.776 147.068 206.907 146.302 206.694C142.853 205.745 140.29 204.527 139.22 203.481C139.021 203.287 138.755 203.176 138.476 203.171Z'
      fill='#1A2E35'
    />
    <path
      d='M140.654 203.148C140.101 203.217 139.565 203.389 139.076 203.657C139.591 203.868 140.169 203.871 140.687 203.665C140.802 203.575 140.865 203.433 140.855 203.287C140.853 203.202 140.795 203.156 140.795 203.156C140.749 203.141 140.701 203.138 140.654 203.148ZM138.756 203.786C138.713 203.774 138.683 203.734 138.681 203.69C138.673 203.648 138.696 203.606 138.736 203.589C138.9 203.494 140.416 202.676 140.904 202.96C141.015 203.021 141.08 203.142 141.07 203.269C141.106 203.502 141.005 203.734 140.811 203.868C140.546 204.024 140.236 204.088 139.931 204.046C139.531 204.003 139.137 203.916 138.756 203.786Z'
      fill='#2091EA'
    />
    <path
      d='M139.79 202.035C139.701 202.035 139.615 202.064 139.546 202.119C139.206 202.51 138.992 202.994 138.931 203.508C139.415 203.185 140.037 202.459 139.991 202.163C139.987 202.132 139.979 202.051 139.845 202.044L139.79 202.041V202.035ZM138.747 203.841C138.716 203.818 138.7 203.78 138.704 203.741C138.707 203.674 138.864 202.377 139.413 201.984C139.544 201.879 139.714 201.837 139.879 201.868C140.045 201.877 140.183 202.003 140.206 202.168C140.271 202.65 139.313 203.597 138.834 203.828L138.779 203.825C138.77 203.833 138.759 203.838 138.747 203.841Z'
      fill='#2091EA'
    />
    <path
      d='M109.018 178.478L112.279 178.601C117.701 178.348 123.084 177.547 128.346 176.211C133.055 175.071 140.861 171.461 140.861 171.461C138.35 182.21 133.826 198.591 133.826 198.591L140.819 199.399C140.819 199.399 159.028 164.705 150.991 160.731C143.811 156.9 116.022 159.415 116.022 159.415L113.156 156.411L93.7474 157.972C93.7474 157.972 90.0303 178.453 109.018 178.478Z'
      fill='#1A2E35'
    />
    <path
      d='M141.259 200.811L133.258 198.385L134.218 195.05L142.889 198.347L141.259 200.811Z'
      fill='#2091EA'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M141.244 200.809L133.242 198.383L134.203 195.048L142.874 198.345L141.244 200.809Z'
          fill='white'
        />
      </g>
    </g>
    <g opacity='0.399994'>
      <g opacity='0.399994'>
        <path
          opacity='0.399994'
          d='M111.433 170.891C113.38 176.597 128.213 176.044 130.278 175.767C118.071 178.119 115.766 178.437 112.28 178.6L109.02 178.477C107.932 178.475 106.928 178.392 105.984 178.253C107.493 168.415 109.075 163.982 111.433 170.891Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M71.449 194.362L70.875 198.857L81.2487 200.552L81.8233 196.045L71.449 194.362Z'
      fill='#FFC3BD'
    />
    <path
      d='M71.7992 199.482L75.0242 194.987C75.1318 194.838 75.1215 194.634 74.999 194.497L72.2445 191.411C71.9944 191.14 71.5726 191.121 71.2985 191.368C71.2687 191.408 71.2441 191.45 71.2246 191.495C70.2065 192.956 69.5472 194.059 68.2332 195.893C67.4302 197.012 64.8812 200.314 63.7761 201.852C62.6709 203.389 64.0473 204.697 64.642 204.166C67.298 201.77 69.6886 200.255 71.1554 199.928C71.4187 199.866 71.6487 199.707 71.7992 199.482Z'
      fill='#1A2E35'
    />
    <path
      d='M71.0518 199.711C70.5951 200.033 70.2803 200.519 70.1727 201.068C70.1853 201.215 70.2717 201.346 70.4022 201.416C70.476 201.459 70.5287 201.454 70.5441 201.428C70.5888 201.409 70.626 201.376 70.6489 201.333C70.8841 200.822 71.0203 200.272 71.0518 199.711ZM71.1101 199.369C71.1445 199.343 71.1908 199.34 71.2286 199.361C71.2704 199.375 71.2933 199.42 71.2818 199.462C71.275 199.656 71.1513 201.371 70.6489 201.623C70.5361 201.684 70.3982 201.676 70.294 201.601C70.0971 201.5 69.9695 201.3 69.9609 201.079C69.9661 200.77 70.0794 200.473 70.282 200.239C70.5258 199.92 70.8028 199.628 71.1101 199.369Z'
      fill='#2091EA'
    />
    <path
      d='M72.1165 200.96C71.9706 200.464 71.677 200.022 71.2752 199.696C71.2861 200.283 71.5797 201.164 71.8384 201.315C71.8676 201.326 71.95 201.367 72.0181 201.251L72.0455 201.203C72.0965 201.133 72.1211 201.047 72.1165 200.96ZM71.1098 199.336C71.1453 199.324 71.1842 199.328 71.2157 199.349C71.2678 199.393 72.2596 200.243 72.2974 200.917C72.3151 201.083 72.2602 201.247 72.1474 201.368C72.0496 201.504 71.8704 201.553 71.717 201.486C71.2701 201.281 70.9919 199.962 71.056 199.44L71.0841 199.392C71.0841 199.371 71.0932 199.351 71.1098 199.336Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M81.8369 196.043L81.2623 200.55L75.9141 199.672L76.4887 195.172L81.8369 196.043Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M111.839 166.635C113.418 169.135 118.882 199.305 108.688 206.047C101.179 210.997 77.5391 201.627 77.5391 201.627L78.2968 194.138C78.2968 194.138 97.5 197.73 99.8717 195.798C102.243 193.867 94.3226 172.923 93.9311 167.999C93.5396 163.075 111.839 166.635 111.839 166.635Z'
      fill='#1A2E35'
    />
    <path
      d='M77.0078 202.17L77.2676 193.813L80.7375 193.872L79.8104 203.103L77.0078 202.17Z'
      fill='#2091EA'
    />
    <g opacity='0.5'>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M77.0234 202.171L77.2833 193.814L80.7532 193.873L79.826 203.104L77.0234 202.171Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M113.975 157.202C113.36 156.054 111.495 153.807 109.698 153.493C107.309 153.075 106.802 152.984 97.5934 154.396C93.2513 155.063 92.9365 158.474 93.1757 160.241C93.2078 160.477 93.3039 160.697 93.4533 160.885L93.6542 161.135C94.1 161.27 113.683 157.832 113.886 157.938C113.886 157.938 114.177 157.58 113.975 157.202Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M113.967 157.203C113.352 156.055 111.488 153.809 109.69 153.495C107.302 153.076 106.795 152.985 97.5856 154.398C93.2435 155.064 92.9287 158.476 93.1679 160.242C93.2 160.479 93.2961 160.699 93.4455 160.886L93.6464 161.137C94.0922 161.272 113.675 157.833 113.879 157.94C113.879 157.94 114.169 157.581 113.967 157.203Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M135.229 124.217C136.494 124.123 137.736 123.724 138.819 123.064C139.57 122.606 140.25 121.758 140.703 121.095C140.861 120.862 140.924 120.58 140.888 120.301C140.801 119.649 140.551 119.037 140.145 118.526C139.923 118.245 139.509 118.204 139.218 118.414C138.557 118.891 137.801 119.386 137.204 119.852C135.525 120.168 133.936 121.711 133.797 123.414C134.252 123.652 134.774 123.979 135.229 124.217Z'
      fill='#FFC3BD'
    />
    <path
      d='M135.967 121.968L136.424 124.187C136.424 124.187 125.409 131.986 123.308 132.102C121.866 132.177 120.195 132.255 118.466 132.309C116.483 132.378 114.418 132.421 112.542 132.409C109.149 132.378 106.374 132.159 105.807 131.563C104.092 129.746 105.925 126.888 108.612 126.913C112.108 126.949 118.217 127.778 124.024 127.558C126.009 127.482 135.967 121.968 135.967 121.968Z'
      fill='#FFC3BD'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M118.463 132.308C116.479 132.377 114.414 132.419 112.538 132.408L110.461 129.422C113.503 130.117 116.527 131.397 118.463 132.308Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M111.602 126.273C111.602 126.273 106.747 125.355 105.924 127.7C105.101 130.043 107.262 134.226 114.013 133.492L114.702 126.35L111.602 126.273Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M111.61 126.275C111.61 126.275 106.755 125.357 105.932 127.702C105.109 130.045 107.27 134.228 114.021 133.494L114.71 126.352L111.61 126.275Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M107.878 126.33C108.012 126.558 111.705 131.42 112.22 135.207C112.592 137.939 112.219 140.913 112.168 143.973C112.131 146.173 112.907 150.831 112.907 150.831C112.906 150.835 112.907 150.84 112.908 150.844L113.519 152.248C114.021 153.404 113.992 154.722 113.437 155.854L113.172 156.395C113.169 156.403 113.161 156.409 113.153 156.409L93.4057 159.056C93.4005 159.057 93.3948 159.056 93.3902 159.053L93.2735 158.99C93.2735 158.99 91.5131 157.521 92.0962 155.651L92.106 154.201C92.8917 151.619 92.6474 148.293 90.7216 141.435C90.0937 139.197 89.4007 136.45 89.4007 136.45C89.9232 131.502 88.315 128.996 88.299 129.03C88.2927 129.043 88.3013 129.001 88.315 128.996C90.6929 128.21 93.1252 127.598 95.5919 127.163C98.2102 126.675 100.861 126.373 103.522 126.263C106.033 126.246 107.874 126.323 107.878 126.33Z'
      fill='#2091EA'
    />
    <path
      d='M105.921 108.93C107.162 110.367 107.432 112.385 107.958 114.21C108.386 115.696 109.317 118.717 108.738 120.151C108.079 121.78 104.842 120.401 103.767 121.79C99.4839 127.322 96.9229 124.939 95.2574 123.574C94.4339 122.899 91.3028 122.227 90.4941 121.536C89.6786 120.838 88.3085 120.493 88.9031 117.802C91.2862 107.028 95.2414 106.395 100.514 106.683C102.538 106.794 104.555 107.347 105.921 108.93Z'
      fill='#1A2E35'
    />
    <path
      d='M95.3516 118.154C96.1448 120.653 97.1795 125.273 95.5839 127.183L96.8796 128.451C99.1574 130.683 103.004 129.425 103.524 126.279C100.613 125.916 101.134 122.986 100.514 120.19L95.3516 118.154Z'
      fill='#FFC3BD'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M101.93 117.239C101.393 119.273 100.681 122.538 101.356 124.753C98.7597 125.066 96.9563 122.756 96.8281 122.008C96.8281 122.008 97.4125 120.155 97.4914 119.255L101.93 117.239Z'
          fill='black'
        />
      </g>
    </g>
    <path
      d='M94.4759 114.235C94.9241 118.514 95.0208 120.332 97.2802 122.44C100.68 125.611 105.835 124.125 106.676 119.804C107.435 115.91 106.577 109.572 102.304 108.043C99.2553 106.94 95.8895 108.519 94.7873 111.568C94.4788 112.421 94.3723 113.334 94.4759 114.235Z'
      fill='#FFC3BD'
    />
    <path
      d='M95.5 116.482C95.8331 117.052 96.0139 117.71 96.0185 118.37C96.4369 118.151 96.4781 117.558 96.3424 117.107C96.2062 116.654 95.9693 116.434 95.5 116.482Z'
      fill='#1A2E35'
    />
    <path
      d='M93.1012 118.404C93.5436 119.109 94.2481 119.611 95.0596 119.799C96.1333 120.036 96.6123 119.062 96.2638 118.079C95.9536 117.189 95.0092 116.015 93.9596 116.195C93.195 116.355 92.7057 117.106 92.8665 117.87C92.9072 118.063 92.9867 118.245 93.1012 118.404Z'
      fill='#FFC3BD'
    />
    <path
      d='M100.587 115.237C100.604 115.61 100.818 115.9 101.059 115.896C101.299 115.893 101.492 115.574 101.475 115.201C101.459 114.827 101.244 114.537 101.004 114.541C100.763 114.545 100.57 114.864 100.587 115.237Z'
      fill='#1A2E35'
    />
    <path
      d='M104.831 115.056C104.853 115.431 105.062 115.72 105.308 115.717C105.554 115.715 105.741 115.395 105.721 115.014C105.702 114.634 105.496 114.353 105.249 114.355C105.003 114.357 104.801 114.672 104.831 115.056Z'
      fill='#1A2E35'
    />
    <path
      d='M105.141 114.38L106.024 114.103C106.024 114.103 105.606 114.787 105.141 114.38Z'
      fill='#1A2E35'
    />
    <path
      d='M103.742 115.749C104.115 116.59 104.6 117.377 105.184 118.088C104.809 118.38 104.324 118.492 103.859 118.392L103.742 115.749Z'
      fill='#FF847F'
    />
    <path
      d='M102.566 119.704C102.781 119.786 103.004 119.847 103.231 119.884C103.282 119.895 103.332 119.861 103.341 119.811C103.341 119.811 103.341 119.811 103.342 119.811C103.346 119.761 103.313 119.716 103.264 119.705C102.32 119.563 101.5 118.977 101.061 118.129C101.048 118.082 101.001 118.052 100.953 118.062C100.908 118.069 100.877 118.111 100.884 118.156C100.884 118.161 100.885 118.165 100.887 118.17C101.225 118.881 101.828 119.431 102.566 119.704Z'
      fill='#1A2E35'
    />
    <path
      d='M99.5615 112.455C99.6102 112.468 99.6663 112.452 99.708 112.411C100.077 112.035 100.568 111.855 101.024 111.929C101.103 111.945 101.188 111.888 101.217 111.799C101.245 111.712 101.205 111.625 101.127 111.606C101.126 111.606 101.124 111.605 101.123 111.605C100.573 111.509 99.977 111.722 99.5283 112.176C99.4631 112.24 99.4494 112.342 99.4974 112.408C99.514 112.43 99.5363 112.446 99.5615 112.455Z'
      fill='#1A2E35'
    />
    <path
      d='M106.428 112.435C106.489 112.456 106.558 112.441 106.605 112.397C106.68 112.33 106.686 112.215 106.619 112.14C106.619 112.14 106.619 112.139 106.619 112.139C106.163 111.61 105.477 111.337 104.782 111.41C104.686 111.42 104.616 111.507 104.626 111.604C104.627 111.609 104.627 111.613 104.628 111.618C104.645 111.717 104.739 111.784 104.838 111.768H104.839C105.412 111.719 105.973 111.947 106.347 112.384C106.369 112.408 106.397 112.425 106.428 112.435Z'
      fill='#1A2E35'
    />
    <path
      d='M100.898 114.562L101.782 114.285C101.782 114.285 101.357 114.989 100.898 114.562Z'
      fill='#1A2E35'
    />
    <path
      d='M98.5572 110.05C99.8192 111.595 95.2493 115.636 95.4931 116.48C94.2518 116.56 93.3321 113.958 93.2645 112.608C93.1976 111.258 93.6331 109.889 94.4675 108.826C95.0021 108.144 95.9887 108.015 96.8483 107.902C97.1013 107.869 97.3703 107.85 97.6021 107.958C97.8974 108.095 98.0708 108.418 98.116 108.741C98.1612 109.063 98.3512 109.798 98.5572 110.05Z'
      fill='#1A2E35'
    />
    <path
      d='M96.8516 107.902C98.8672 109.533 99.0544 111.707 98.5982 113.536C99.1459 113.032 99.6295 112.56 99.9506 111.889C100.271 111.217 100.3 110.351 99.8224 109.78C101.036 110.612 101.85 112.072 102.074 113.526C102.608 113.179 102.897 112.528 102.961 111.894C103.025 111.259 102.788 110.601 102.333 110.154C103.177 110.907 103.688 112.194 103.453 113.3C104.036 112.774 104.393 112.157 104.338 111.373C104.284 110.589 103.853 109.84 103.202 109.399C104.387 110.085 105.254 111.377 105.224 112.746C106.532 111.476 106.09 109.664 104.488 108.667C102.887 107.671 99.5008 106.997 96.8516 107.902Z'
      fill='#1A2E35'
    />
    <path
      d='M96.2236 125.792L94.6875 127.332C94.6875 127.332 100.539 131.155 100.623 130.896C100.706 130.636 101.851 129.26 101.851 129.26L96.2236 125.792Z'
      fill='#2091EA'
    />
    <path
      d='M102.003 125.468L104.048 126.207C104.048 126.207 103.845 130.541 103.659 130.342C103.472 130.143 101.852 129.386 101.852 129.386L102.003 125.468Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M96.2236 125.792L94.6875 127.332C94.6875 127.332 100.539 131.155 100.623 130.896C100.706 130.637 101.851 129.261 101.851 129.261L96.2236 125.792Z'
          fill='white'
        />
      </g>
    </g>
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M101.995 125.468L104.04 126.207C104.04 126.207 103.837 130.541 103.651 130.342C103.465 130.143 101.844 129.386 101.844 129.386L101.995 125.468Z'
          fill='white'
        />
      </g>
    </g>
    <path
      d='M64.7696 143.843L59.8438 144.311L61.5635 148.182C61.5635 148.182 64.2294 146.996 65.3837 146.059L64.7696 143.843Z'
      fill='#FFC3BD'
    />
    <path
      d='M58.0277 147.274L58.1089 147.414C58.4151 147.946 58.9903 148.266 59.6038 148.246L61.5662 148.182L59.8465 144.31L58.6429 144.998C57.8434 145.456 57.567 146.476 58.0277 147.274Z'
      fill='#FFC3BD'
    />
    <path
      d='M65.8851 145.914C65.8851 145.914 74.4984 146.708 82.5588 142.899C82.5588 142.899 87.9808 138.384 90.9082 134.588C95.2571 128.947 91.2121 127.244 88.0031 130.887C85.6223 133.59 80.103 138.807 80.103 138.807C73.1718 142.831 64.1711 144.062 64.1711 144.062C62.4272 146.577 65.8851 145.914 65.8851 145.914Z'
      fill='#FFC3BD'
    />
    <path
      d='M93.4044 128.741C95.4521 130.383 92.3971 134.675 88.4162 139.835L83.4766 133.427C83.4766 133.427 89.7399 125.802 93.4044 128.741Z'
      fill='#2091EA'
    />
    <g opacity='0.199997'>
      <g opacity='0.199997'>
        <path
          opacity='0.199997'
          d='M93.3888 128.741C95.4365 130.383 92.3815 134.675 88.4005 139.835L83.4609 133.427C83.4609 133.427 89.7243 125.802 93.3888 128.741Z'
          fill='white'
        />
      </g>
    </g>
  </svg>
)

export default ModalPlanRestriction
