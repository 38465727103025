import React from 'react';
import axios from 'axios';

// Components
import SubmitButton from 'components/SubmitButton';

import formCreator from '../components/formCreator';
import UserProfile from '../components/fieldsGroup/UserProfile';

export class UpdateUserProfile extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>

        <UserProfile {...this.props} />

        <SubmitButton
          label="Salvar"
          isFeatured
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {

    // const bodyFormData = new FormData();

    // bodyFormData.append('file', fields.avatar[0].raw);

    // const request = axios.create({
    //   baseURL: 'http://dev.workandcode.com/uploads/',
    //   onUploadProgress: (progressEvent) => {
    //     console.log(progressEvent);
    //   }
    // });

    // request.post('upload.php', bodyFormData)
    //   .then((response) => {
    //     fields.avatar = 'http://dev.workandcode.com/uploads/' + response.data;
    //   });

    this.props.submit('me', fields, 'put');
  }

};

export default formCreator(UpdateUserProfile);
