import React from "react";

import Header from "components/lib/Header";
import Container from "components/lib/Container";

const SectionWrapper = props => (
  <section
    className={`section ${props.className && props.className}`}
    style={props.style}
  >
    <Container isMedium={!props.isMedium}>
      {props.header ? (
        <React.Fragment>
          {props.customHeader && props.pageWidth < 768 ? (
            <Header
              isUppercase
              isMedium
              subtitle={<hr />}>
              <p>{props.header}</p>
              {props.headerRight}
            </Header>
          ) : (
            <Header
              {...props}
              isUppercase
              subtitle={<hr style={{ marginTop: "1.5rem" }} />}
            >
              <React.Fragment>
                {props.header}
                <div>{props.headerRight}</div>
              </React.Fragment>
            </Header>
          )}
        </React.Fragment>
      ) : null}
      {props.children}
    </Container>
  </section>
);

export default SectionWrapper;
