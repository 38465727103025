import React, { Component, Fragment } from 'react';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Modal from 'components/lib/Modal';
import { FormFieldTextArea } from 'components/lib/FormFields';
import Success from '../components/Success';
import Failed from '../components/Failed';

import { baseURL, request } from 'core/constants';
import * as types from '../types';

class FileViewerActions extends Component {
  constructor(props) {
    super(props);
    this.handleActions = this.handleActions.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
    this.state = {
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
      reason: '',
    };
  }

  handleTextArea(reason) {
    this.setState({ reason });
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    });
  }

  handleEntityType(entityName) {
    switch (entityName) {
      case 'user':
        return 'user';
      case 'proposal':
        return 'proposal';
      case 'property':
        return 'property';
    }
  }

  handleNoUserRoute(name, id, type, docId) {
    const { publicURL } = this.props;
    const prefixURL = publicURL ? `${baseURL}public/` : `${baseURL}`;
    let dbEntityId = null;
    switch (name) {
      case 'proposal':
        dbEntityId = 1;
        break;
      case 'property':
        dbEntityId = 2;
    }

    switch (type) {
      case 'show':
        return `${prefixURL}entity/${dbEntityId}/show/${id}/owner`;
      case 'upload':
        return `${prefixURL}entity/${dbEntityId}/upload/file`;
      case 'delete':
        return `${prefixURL}entity/${dbEntityId}/delete/${docId}/file`;
      case 'update':
        return `${prefixURL}entity/${dbEntityId}/update/file`;
    }
  }

  handleUrl(type, docId = null) {
    const { entityName, entityId, publicURL, bearerToken } = this.props;

    const prefixURL = publicURL ? `${baseURL}public/` : `${baseURL}`;

    let dbEntityId = null;

    switch (entityName) {
      case 'proposal':
        dbEntityId = 1;
        break;
      case 'property':
        dbEntityId = 2;
        break;
      case 'propertyOwner':
        dbEntityId = 3;
        break;
      case 'client':
        dbEntityId = 4;
        break;
      case 'guarantor':
        dbEntityId = 5;
        break;
      case 'processPossession':
        dbEntityId = 6;
        break;
      case 'processInsurance':
        dbEntityId = 7;
        break;
    }

    switch (type) {
      case 'show':
        if (bearerToken)
          return `${baseURL}register/entity/${dbEntityId}/show/${entityId}/owner`;
        return `${prefixURL}entity/${dbEntityId}/show/${entityId}/owner`;
      case 'upload':
        if (bearerToken)
          return `${prefixURL}/register/entity/${dbEntityId}/upload/file`;
        return `${prefixURL}entity/${dbEntityId}/upload/file`;
      case 'update':
        if (bearerToken)
          return `${prefixURL}register/entity/${dbEntityId}/upload/file`;
        return `${prefixURL}entity/${dbEntityId}/update/file`;
      case 'delete':
        if (bearerToken)
          return `${baseURL}register/entity/${dbEntityId}/delete/${docId}/file`;
        return `${prefixURL}entity/${dbEntityId}/delete/${docId}/file`;
    }
  }

  handleDbEntityId() {
    const { entityName } = this.props;
    switch (entityName) {
      case 'proposal':
        return 1;
      case 'property':
        return 2;
      case 'propertyOwner':
        return 3;
      case 'client':
        return 4;
      case 'guarantor':
        return 5;
      case 'processPossession':
        return 6;
      case 'processInsurance':
        return 7;
    }
  }

  handleConfirm(action) {
    const spinner = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Icon style={{ fontSize: '2rem' }} name="fas fa-spinner fa-pulse" />
      </div>
    );
    this.setState({ feature: spinner });

    const { documents, index, entityId } = this.props;
    const docId = documents[index].id;
    const headers = { 'Access-Control-Allow-Origin': true };
    let url = '',
      status = '',
      body = new FormData(),
      msg = '';

    switch (action) {
      case 'approve':
        msg = 'aprovado(s)';
        url = this.handleUrl('update');
        status = types.STATUS_APPROVED;
        body.append('id', docId);
        body.append('status', status);
        body.append('entity_id', this.handleDbEntityId());
        body.append('owner_document', entityId);

        return request.post(url, body, headers).then((response) => {
          let callback = () => {
            this.props.handleView();
          };
          this.props.loadDocs(callback);

          if (response.success) {
            return this.handleUpdate(true, msg);
          } else {
            return this.handleUpdate(false, msg);
          }
        });
      case 'reject':
        msg = 'rejeitado(s)';
        const { reason } = this.state;
        url = this.handleUrl('update');
        status = types.STATUS_REJECTED;
        body.append('id', docId);
        body.append('status', status);
        body.append('comments', reason);
        body.append('entity_id', this.handleDbEntityId());
        body.append('owner_document', entityId);

        return request.post(url, body, headers).then((response) => {
          let callback = () => {
            this.props.handleView();
          };
          this.props.loadDocs(callback);

          if (response.success) {
            return this.handleUpdate(true, msg);
          } else {
            return this.handleUpdate(false, msg);
          }
        });
      case 'delete':
        const { bearerToken } = this.props;
        const config = bearerToken
          ? {
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                'Access-Control-Allow-Origin': true,
              },
            }
          : {
              'Access-Control-Allow-Origin': true,
            };

        msg = 'deletado(s)';
        url = this.handleUrl('delete', docId);

        return request.delete(url, config).then((response) => {
          this.props.loadDocs();
          if (response.sucesso) {
            return this.handleUpdate(true, msg, true);
          } else {
            return this.handleUpdate(false, msg, true);
          }
        });
    }
  }

  handleUpdate(success, msg, isDelete = null) {
    if (isDelete) this.props.clearSelected();

    console.log(this.state);

    if (success) {
      isDelete && this.props.handleHideModal();

      return this.setState({
        feature: <Success msg={msg} />,
      });
    }
    return this.setState({ feature: <Failed msg={msg} /> });
  }

  handleFeature(
    title,
    documentName,
    hasReason,
    approveButton,
    cancelButton,
    isSuccess,
    action
  ) {
    return (
      <Fragment>
        <div className="title" style={{ fontSize: '1.5rem' }}>
          {title}
        </div>
        <div className="document_name" style={{ marginBottom: '1.5rem' }}>
          - {documentName}
        </div>
        {hasReason ? (
          <FormFieldTextArea
            label="Qual o motivo?"
            onChange={(reason) => this.handleTextArea(reason)}
          />
        ) : null}
        <div className="buttons" style={{ justifyContent: 'space-around' }}>
          <Button isLight normal onClick={this.handleModalClose.bind(this)}>
            {cancelButton}
          </Button>

          {isSuccess ? (
            <Button normal isSuccess onClick={() => this.handleConfirm(action)}>
              {approveButton}
            </Button>
          ) : (
            <Button normal isDanger onClick={() => this.handleConfirm(action)}>
              {approveButton}
            </Button>
          )}
        </div>
      </Fragment>
    );
  }

  handleActions(name) {
    const { documents, index } = this.props;
    const current = documents[index];
    let title = '',
      documentName = '',
      approveButton = '',
      cancelButton = '',
      action = '',
      hasReason = null,
      isSuccess = null,
      feature = null;

    switch (name) {
      case 'Aprovar':
        title = 'Você deseja aprovar o documento:';
        documentName = current.name;
        hasReason = false;
        approveButton = 'Aprovar documento';
        cancelButton = 'Cancelar';
        isSuccess = true;
        action = 'approve';
        feature = this.handleFeature(
          title,
          documentName,
          hasReason,
          approveButton,
          cancelButton,
          isSuccess,
          action
        );

        return this.setState({
          modalIsOpen: true,
          feature,
        });

      case 'Rejeitar':
        title = 'Você deseja rejeitar o documento:';
        documentName = current.name;
        hasReason = true;
        approveButton = 'Rejeitar documento';
        cancelButton = 'Cancelar';
        isSuccess = false;
        action = 'reject';
        feature = this.handleFeature(
          title,
          documentName,
          hasReason,
          approveButton,
          cancelButton,
          isSuccess,
          action
        );

        return this.setState({
          modalIsOpen: true,
          feature,
        });

      case 'Deletar':
        title = 'Você deseja deletar o documento:';
        documentName = current.name;
        hasReason = false;
        approveButton = 'Deletar documento';
        cancelButton = 'Cancelar';
        isSuccess = false;
        action = 'delete';
        feature = this.handleFeature(
          title,
          documentName,
          hasReason,
          approveButton,
          cancelButton,
          isSuccess,
          action
        );

        return this.setState({
          modalIsOpen: true,
          feature,
        });
    }
  }

  renderActions(actions) {
    return actions.map((action, i) => {
      const { icon, name } = action;
      return (
        <div key={i} className="Item">
          <Button
            isWhite
            isSmall
            isTransparent
            normal
            onClick={() => this.handleActions(name)}
          >
            <Icon name={icon} />
            {name}
          </Button>
        </div>
      );
    });
  }

  render() {
    const { modalIsOpen, feature, modalTitle } = this.state;

    const isApp = location.href.includes('/app/');

    const actions = [
      ...(!isApp
        ? []
        : [
            {
              icon: 'fas fa-thumbs-up',
              name: 'Aprovar',
            },
            {
              icon: 'fas fa-ban',
              name: 'Rejeitar',
            },
          ]),
      {
        icon: 'fas fa-times-circle',
        name: 'Deletar',
      },
    ];

    const renderActions = this.renderActions(actions);

    return (
      <Fragment>
        {renderActions}
        <Modal
          title={modalTitle}
          isOpen={modalIsOpen}
          onClose={this.handleModalClose.bind(this)}
        >
          {feature}
        </Modal>
      </Fragment>
    );
  }
}
export default FileViewerActions;
