import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999999999999;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #00000014;

  @media (min-width: 510px) {
    padding: 10px 5px;
  }

  @media (min-width: 1000px) {
    padding: 40px 100px;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 100%;
  max-width: 800px;

  background-color: white;

  box-shadow: 0px 6px 20px #00000014;

  @media (min-width: 756px) {
    border-radius: 4px;
  }

  & .columns {
    margin-top: 0 !important;
    margin-left: 0 !important;

    & > .column {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }

    @media (max-width: 768px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @media (min-width: 768px) {
      & > div:first-child {
        padding-left: 0;
      }
      & > div:last-child {
        padding-right: 0;
      }
    }
  }
`;

export const ModalHeader = styled.div`
  padding: 16px 24px 16px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 16px;

  background-color: #f5f5f5;
`;

export const ModalBody = styled.div`
  padding-top: 14px;

  overflow-y: auto;
`;

export const FormSection = styled.div`
  padding: 0px 20px 25px 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  padding: 0px 20px 20px 0px;
`;
