const formatData = (data) => {
  if (data === 'true') {
    return true
  }
  if (data === 'false') {
    return false
  }

  return data
}

const recursiveFillPayload = (data, parts, value) => {
  const index = parts.splice(0, 1)[0]

  if (parts.length > 0) {
    if (!data[index]) {
      if (isNaN(parts[0])) {
        data[index] = {}
      } else {
        data[index] = []
      }
    }
    recursiveFillPayload(data[index], parts, value)
  } else {
    data[index] = formatData(value)
  }
}

const returnFormattedFields = (fields, extractValue = true) => {
  const formattedFields = {}

  for (let field in fields) {
    let parts = field.split('.')

    recursiveFillPayload(
      formattedFields,
      parts,
      extractValue ? fields[field].value : fields[field],
    )
  }

  return formattedFields
}

export default returnFormattedFields
