import React from 'react';

import SectionIdentifier from 'components/lib/SectionIdentifier';
import ProgressBar from 'components/lib/ProgressBar';
import Space from 'components/lib/Space';
import withRedux from 'components/redux';

class Body extends React.Component {
  render() {
    const {
      sections,
      selected,
      selectedSection,
      callBack,
      isInvalid,
      canCustomizeApp,
      companyInfo: { themeColor },
    } = this.props;

    const container = selectedSection.customBody
      ? 'custom-container'
      : 'container-register-form';

    const progressActive = selectedSection.progressBar;

    const isApp = location.href.includes('/app/');

    return (
      <div className="container-rfmodal">
        {progressActive && (
          <ProgressBar
            sections={sections}
            selected={selected}
            selectedSection={selectedSection}
            callBack={callBack}
            isInvalid={isInvalid}
            themeColor={(!isApp || canCustomizeApp) && themeColor}
          />
        )}
        {progressActive == false ? <Space isLarge /> : null}
        <div className={container}>
          {!selectedSection.customBody &&
          selectedSection.sectionTitle !== '' &&
          !this.props.customBody ? (
            <SectionIdentifier modal selectedSection={selectedSection} />
          ) : null}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withRedux((state) => {
  const subscriptionPlan = state.app.activeCompany?.subscription_plan;
  const canCustomizeApp = subscriptionPlan ? subscriptionPlan.can_customize_app : true;
  
  return {canCustomizeApp}
})(Body);
