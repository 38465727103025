import React from 'react'

import PersonalDataSpouse from 'components/Proposal/components/PersonalDataSpouse'
import PersonalData from 'components/Proposal/components/PersonalData'
import UserName from 'components/Proposal/components/UserName'
import Address from 'components/Proposal/components/Address'

import Section from './Section'

const SectionView = (props) => {
  const { owner, property, proposal, guarantor, client, other } = props

  if (!owner || !property || !proposal || !guarantor || !client) return null

  let info = null

  let title = ''

  let iconName = ''

  if (Object.keys(owner.current).length > 0) {
    const spouse = (({
      spouse_birth_date,
      spouse_cpf,
      spouse_email,
      spouse_identity,
      spouse_identity_expedition_date,
      spouse_identity_issuing_body,
      spouse_identity_type,
      spouse_is_student,
      spouse_name,
      spouse_nationality,
      spouse_occupation,
      spouse_phone,
      spouse_income,
      spouse_income_source_id,
    }) => ({
      spouse_birth_date,
      spouse_cpf,
      spouse_email,
      spouse_identity,
      spouse_identity_expedition_date,
      spouse_identity_issuing_body,
      spouse_identity_type,
      spouse_is_student,
      spouse_name,
      spouse_nationality,
      spouse_occupation,
      spouse_phone,
      spouse_income,
      spouse_income_source_id,
    }))(owner.current)

    title = 'Proprietário'
    iconName = 'user'
    info = (
      <>
        <UserName {...owner.current} />
        <PersonalData {...owner.current} />
        <PersonalDataSpouse {...spouse} />
        <Address {...owner.current} />
      </>
    )
  }
  if (Object.keys(property.current).length > 0) {
    title = 'Imóvel'
    iconName = 'home'
    info = (
      <>
        <UserName {...property.current} />
        <PersonalData {...property.current} />
      </>
    )
  }
  if (Object.keys(proposal.current).length > 0) {
    title = 'Proposta'
    iconName = 'scroll'
    info = (
      <>
        <UserName {...proposal.current} />
        <PersonalData {...proposal.current} />
      </>
    )
  }
  if (Object.keys(guarantor.current).length > 0) {
    const spouse = (({
      spouse_birth_date,
      spouse_cpf,
      spouse_email,
      spouse_identity,
      spouse_identity_expedition_date,
      spouse_identity_issuing_body,
      spouse_identity_type,
      spouse_is_student,
      spouse_name,
      spouse_nationality,
      spouse_occupation,
      spouse_phone,
      spouse_income,
      spouse_income_source_id,
    }) => ({
      spouse_birth_date,
      spouse_cpf,
      spouse_email,
      spouse_identity,
      spouse_identity_expedition_date,
      spouse_identity_issuing_body,
      spouse_identity_type,
      spouse_is_student,
      spouse_name,
      spouse_nationality,
      spouse_occupation,
      spouse_phone,
      spouse_income,
      spouse_income_source_id,
    }))(guarantor.current)

    title = 'Fiador'
    iconName = 'user'
    info = (
      <>
        <UserName {...guarantor.current} />
        <PersonalData {...guarantor.current} />
        <PersonalDataSpouse {...spouse} />
        <Address {...guarantor.current} />
      </>
    )
  }
  if (Object.keys(client.current).length > 0) {
    const spouse = (({
      spouse_birth_date,
      spouse_cpf,
      spouse_email,
      spouse_identity,
      spouse_identity_expedition_date,
      spouse_identity_issuing_body,
      spouse_identity_type,
      spouse_is_student,
      spouse_name,
      spouse_nationality,
      spouse_occupation,
      spouse_phone,
      spouse_income,
      spouse_income_source_id,
    }) => ({
      spouse_birth_date,
      spouse_cpf,
      spouse_email,
      spouse_identity,
      spouse_identity_expedition_date,
      spouse_identity_issuing_body,
      spouse_identity_type,
      spouse_is_student,
      spouse_name,
      spouse_nationality,
      spouse_occupation,
      spouse_phone,
      spouse_income,
      spouse_income_source_id,
    }))(client.current)

    title = 'Cliente'
    iconName = 'user'
    info = (
      <>
        <PersonalData documentView={true} {...client.current} />
        <PersonalDataSpouse documentView={true} {...spouse} />
        <Address documentView={true} {...client.current} />
      </>
    )
  }

  if (other) {
    if (Object.keys(other).length > 0) {
      const spouse = (({
        spouse_birth_date,
        spouse_cpf,
        spouse_email,
        spouse_identity,
        spouse_identity_expedition_date,
        spouse_identity_issuing_body,
        spouse_identity_type,
        spouse_is_student,
        spouse_name,
        spouse_nationality,
        spouse_occupation,
        spouse_phone,
        spouse_income,
        spouse_income_source_id,
      }) => ({
        spouse_birth_date,
        spouse_cpf,
        spouse_email,
        spouse_identity,
        spouse_identity_expedition_date,
        spouse_identity_issuing_body,
        spouse_identity_type,
        spouse_is_student,
        spouse_name,
        spouse_nationality,
        spouse_occupation,
        spouse_phone,
        spouse_income,
        spouse_income_source_id,
      }))(other)

      title = other.sectionTitle
      iconName = other.sectionIconName
      info = (
        <>
          <UserName {...other} />
          <PersonalData {...other} />
          <PersonalDataSpouse {...spouse} />
          <Address {...other} />
        </>
      )
    }
  }

  return <Section title={title} iconName={iconName} children={info} />
}

export default SectionView
