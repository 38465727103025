import React from 'react'
import PropTypes from 'prop-types'

// Components
import { FormFieldText } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import SubmitButton from 'components/SubmitButton'
import formCreator, { fieldProps } from '../components/formCreator'

export class RejectGuarantee extends React.Component {
  componentDidMount() {
    this.props.createRequest(this.createRequest)
  }

  render() {
    const { formIsWorking, formIsReady } = this.props

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <FormFieldText
              label='Motivo'
              {...fieldProps(this.props, 'guarantee_denial_comment')}
            />
          </Grid.Col>
        </Grid>

        <SubmitButton
          label='Rejeitar'
          isDanger
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />
      </React.Fragment>
    )
  }

  createRequest(fields) {
    fields['guarantee_evaluation'] = 0
    this.props.submit(this.props.path, fields)
  }
}

RejectGuarantee.defaultProps = {}

RejectGuarantee.propTypes = {
  activeCompany: PropTypes.object.isRequired
}

export default formCreator(RejectGuarantee)
