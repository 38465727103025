import React from 'react';

// Components
import { FormFieldText, FormFieldTextArea } from 'components/lib/FormFields';
import { fieldProps } from 'features/components/formCreator';

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { showGuaranteeValue, maxLength, allowMaxLengthField, currentFieldLength } = this.props;

    return (
      <>
        {showGuaranteeValue && (
          <FormFieldText {...fieldProps(this.props, 'guarantee_value')}
            onChange={this.props.changeGuaranteeValue}
            label="Valor da garantia"
            mask={['number']}
            style={{ width: 200 }} />
        )}
        <FormFieldTextArea
          {...fieldProps(this.props, 'keys_location')}
          label="Observações"
          placeholder="Coloque as observações aqui"
          allowMaxLengthField={allowMaxLengthField}
          maxLength={maxLength}
          currentFieldLength={currentFieldLength}
          onChange={(e) => this.props.changeKeysLocation(e)}
        />
      </>
    );
  }
}

export default Settings;
