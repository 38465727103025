import { combineReducers } from 'redux';

import Auth from 'containers/Auth/reducer';
import AppWrapper from 'containers/AppWrapper/reducer';
import Process from 'containers/Process/reducer';
import ProcessDetails from 'containers/ProcessDetails/reducer';

import Settings from 'containers/Settings/reducer';

import Members from 'containers/Members/reducer';
import Logs from 'components/Logs/reducer';
import PropertyOwners from 'containers/PropertyOwners/reducer';
import PropertyOwner from 'containers/PropertyOwnerDetails/reducer';
import Properties from 'containers/Properties/reducer';
import Property from 'containers/PropertyDetails/reducer';
import GuestUsers from 'containers/GuestUsers/reducer';
import ClientDetails from 'containers/ClientDetails/reducer';
import Guarantors from 'containers/Guarantors/reducer';
import GuarantorDetails from 'containers/GuarantorDetails/reducer';
import Proposal from 'containers/Proposal/reducer';
import Commons from 'utils/UtilityStore/reducer';
import Features from 'features/components/formCreator/reducer';

import PublicApp from 'containers/PublicApp/reducer';

const reducers = combineReducers({
  auth: Auth,
  app: AppWrapper,
  commons: Commons,
  features: Features,
  process: Process,
  processDetails: ProcessDetails,
  settings: Settings,

  logs: Logs,
  members: Members,
  propertyOwners: PropertyOwners,
  propertyOwner: PropertyOwner,
  properties: Properties,
  property: Property,
  clients: GuestUsers,
  client: ClientDetails,
  guarantors: Guarantors,
  guarantor: GuarantorDetails,
  proposal: Proposal,

  public: PublicApp,
});

export default (state, action) => {
  // Reset all states when users logging out or token is missing
  if (action.type === 'APP_DESTROY_SESSION') {
    state = undefined;
  }
  return reducers(state, action);
};
