import React from 'react';

import { components } from 'react-select';

import Icon from 'components/lib/Icon';

const MultiValueRemove = (props) => {
  if (props.data.forbiddenRemove) {
    return <span style={{ width: 4 }} />;
  }

  return (
    <div>
      <components.MultiValueRemove {...props}>
        <Icon name="far fa-times" />
      </components.MultiValueRemove>
    </div>
  );
};

export default MultiValueRemove;
