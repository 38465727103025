import React from 'react'

import { connect } from 'react-redux'

import { request } from 'core/constants'

import * as commons from 'utils/UtilityStore/actions'
import { copyToClipboard } from 'utils/utils'

import CreateProperty from 'features/CreateProperty'

import Button from 'components/lib/Button'
import Modal from 'components/lib/Modal'

import ConfirmExcludeModal from 'components/ConfirmExcludeModal'

import List from './components/List'

import * as actions from './actions'

class Properties extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastOrder: '',
      orderDirection: '',
      modalTitle: '',
      modalIsOpen: false,
      showExcludeModal: false,
      excludeRowId: null,
      feature: null,
      filter: {},
    }
  }

  componentDidMount() {
    this.props.fetch(this.state.filter)
  }

  render() {
    return (
      <>
        <List
          listName='Imóveis'
          items={this.props.items}
          pagination={this.props.listInfo}
          isWorking={this.props.isWorking}
          handleEditRow={this.openRow.bind(this)}
          handleExcludeRow={this.openExcludeModal.bind(this)}
          onChangePage={this.handlePagination.bind(this)}
          onSearch={this.searchList.bind(this)}
          orderBy={this.orderBy.bind(this)}
          lastOrder={this.state.lastOrder}
          orderDirection={this.state.orderDirection}
          isMedium={true}
          actionButton={
            <div className='buttons'>
              <Button onClick={this.handleFeature.bind(this)} isFlat hasThemeColor>
                Adicionar imóvel
              </Button>
              <Button onClick={this.goToImport.bind(this)} isFlat hasThemeColor>
                Importar CSV
              </Button>
            </div>
          }
        />

        <Modal
        isScrollable
          title={this.state.modalTitle}
          isOpen={this.state.modalIsOpen}
          onClose={this.closeModal.bind(this)}
        >
          {this.state.feature}
        </Modal>

        {this.state.showExcludeModal && (
          <ConfirmExcludeModal
            title='Excluir Propriedade?'
            text='Confirmar exclusão desta Propriedade?'
            onClose={() =>
              this.setState({
                showExcludeModal: false,
                excludeRowId: null,
              })
            }
            onExclude={this.excludeRow.bind(this, this.state.excludeRowId)}
          />
        )}
      </>
    )
  }

  searchList(keywords) {
    const filter = { ...this.state.filter, ...{ search: keywords } }

    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  orderBy(order) {
    const filter = { ...this.state.filter, page: 1 }

    let orderDirection = ''

    if (this.state.lastOrder == order) {
      if (this.state.orderDirection == 'desc') {
        this.setState({ orderDirection: 'asc' })
        orderDirection = 'asc'
      } else if (this.state.orderDirection == 'asc') {
        this.setState({ orderDirection: 'desc' })
        orderDirection = 'desc'
      }
    } else {
      this.setState({ orderDirection: 'asc' })
      orderDirection = 'asc'
    }

    this.props.fetch(filter, order, orderDirection)
    this.setState({ lastOrder: order })
    this.props.fetch(filter)
  }

  goToImport() {
    const path = this.props.location.pathname + '/import'

    this.props.history.push(path)
  }

  handlePagination(page) {
    const filter = { ...this.state.filter, ...{ page: page } }

    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  closeModal() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    })
  }

  handleCreateSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Imóvel cadastrado com sucesso',
      })
      this.closeModal()
      this.props.fetch(this.state.filter)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao cadastrar o imóvel',
        status: 'danger',
      })
    }
  }

  handleFeature() {
    this.setState({
      modalTitle: 'Adicionar imóvel',
      modalIsOpen: true,
      feature: <CreateProperty onSuccess={this.handleCreateSuccess.bind(this)} />,
    })
  }

  handleExternalFormUrl(url) {
    copyToClipboard(url)
    this.props.notification({
      message: 'Url copiada para área de transferência',
    })
  }

  openRow(row) {
    this.props.history.push(this.props.location.pathname + '/' + row.id)
  }

  openExcludeModal(row) {
    this.setState({
      showExcludeModal: true,
      excludeRowId: row.id,
    })
  }

  async excludeRow(rowId) {
    try {
      const deleteUrl = `/company/${this.props.activeCompany.id}/property/${rowId}`

      await request.delete(deleteUrl)
      this.props.notification({
        message: 'Registro excluído com sucesso',
        status: 'success',
      })
      this.props.fetch(this.state.filter)
    } catch (erro) {
      this.props.notification({
        message: erro.message || 'Erro ao tentar excluir o Imóvel',
        status: 'danger',
      })
    }
  }
}

export default connect(
  (store) => ({
    items: store.properties.items,
    listInfo: store.properties.listInfo,
    isWorking: store.commons.isWorking.properties,
  }),
  { ...actions, ...commons },
)(Properties)
