import React from 'react'

import ImportCSV from '../../components/ImportCSV'

const propertyProperties = [
  { label: 'Imóvel: Código', name: 'property.code', required: false },
  { label: 'Imóvel: Apelido de registro', name: 'property.name', required: false },
  { label: 'Imóvel: Tipo de imóvel', name: 'property.type_id', required: false },
  {
    label: 'Imóvel: Condomínio (Mensal aproximado)',
    name: 'property.condo_fee',
    required: false,
  },
  { label: 'Imóvel: IPTU (Aproximado)', name: 'property.iptu', required: false },
  { label: 'Imóvel: Área privativa (m²)', name: 'property.area', required: false },
  {
    label: 'Imóvel: Valor sugerido de aluguel',
    name: 'property.suggested_rental_value',
    required: false,
  },
  {
    label: 'Imóvel: Valor sugerido de venda',
    name: 'property.suggested_sale_value',
    required: false,
  },
  { label: 'Imóvel: CEP', name: 'property.address.zip_code', required: false },
  { label: 'Imóvel: Estado', name: 'property.address.state', required: false },
  { label: 'Imóvel: Logradouro', name: 'property.address.address', required: false },
  { label: 'Imóvel: Número', name: 'property.address.number', required: false },
  { label: 'Imóvel: Cidade', name: 'property.address.city', required: false },
  {
    label: 'Imóvel: Bairro',
    name: 'property.address.neighborhood',
    required: false,
  },
  { label: 'Imóvel: País', name: 'property.address.country', required: false },
  {
    label: 'Imóvel: Complemento',
    name: 'property.address.additional_address',
    required: false,
  },

  // Property Owner
  { label: 'Proprietário: Nome', name: 'property_owner.name', required: false },
  { label: 'Proprietário: Email', name: 'property_owner.email', required: true },
  { label: 'Proprietário: Telefone', name: 'property_owner.phone', required: false },
  {
    label: 'Proprietário: Data de nascimento',
    name: 'property_owner.birth_date',
    required: false,
  },
  {
    label: 'Proprietário: Ocupação',
    name: 'property_owner.occupation',
    required: false,
  },
  {
    label: 'Proprietário: Estado civil',
    name: 'property_owner.marital_status_id',
    required: false,
  },
  {
    label: 'Proprietário: Número do documento',
    name: 'property_owner.identity',
    required: false,
  },
  {
    label: 'Proprietário: Data de expedição do documento',
    name: 'property_owner.identity_expedition_date',
    required: false,
  },
  { label: 'Proprietário: CPF', name: 'property_owner.cpf', required: false },
  { label: 'Proprietário: CNPJ', name: 'property_owner.cnpj', required: false },
  {
    label: 'Proprietário: Órgão emissor / UF',
    name: 'property_owner.identity_issuing_body',
    required: false,
  },
  {
    label: 'Proprietário: CEP',
    name: 'property_owner.address.zip_code',
    required: false,
  },
  {
    label: 'Proprietário: Estado',
    name: 'property_owner.address.state',
    required: false,
  },
  {
    label: 'Proprietário: Logradouro',
    name: 'property_owner.address.address',
    required: false,
  },
  {
    label: 'Proprietário: Número',
    name: 'property_owner.address.number',
    required: false,
  },
  {
    label: 'Proprietário: Cidade',
    name: 'property_owner.address.city',
    required: false,
  },
  {
    label: 'Proprietário: Bairro',
    name: 'property_owner.address.neighborhood',
    required: false,
  },
  {
    label: 'Proprietário: País',
    name: 'property_owner.address.country',
    required: false,
  },
  {
    label: 'Proprietário: Complemento',
    name: 'property_owner.address.additional_address',
    required: false,
  },
]

const ImportPropertiesWithCSV = () => (
  <ImportCSV
    payloadProperties={propertyProperties}
    validateUrl='/property/validate-many'
    importUrl='/property/create-many'
    entityName='Imóveis'
  />
)

export default ImportPropertiesWithCSV
