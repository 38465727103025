import React from 'react';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Box = (props) => {

  let boxCSSClass = `box ${
    parsePropsAsBulmaClasses(props)
  }`;

  if (props.className) boxCSSClass = props.className;

  return (
    <div className={boxCSSClass} data-spec-selector={props.specSelector} style={{ maxWidth: props.maxWidth }}>
      {props.children}
    </div>
  );

};

export default Box;
