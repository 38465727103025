import React from 'react';
import ReactTimeout from 'react-timeout';

import ValueParser from 'services/ValueParser';

const formFieldCreator = (Component, options = {}) => {
  class FormFieldWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: '',
        isEmpty: true,
        isValid: null,
        forceInvalid: false,
        wasFocused: false,
      };
      this.isTyping = false;
    }

    static getDerivedStateFromProps(props, state) {
      if (state.forceInvalid !== props.forceInvalid) {
        return {
          forceInvalid: props.forceInvalid,
        };
      }
      return null;
    }

    componentDidMount() {
      let defaultValue = this.props.valueDefault || '';
      if (options.valueType === 'boolean') {
        defaultValue = this.props.checkedDefault || false;
      }
      this.updateField(defaultValue);
      this.timer = null;
    }

    getSnapshotBeforeUpdate(prevProps) {
      // Set default values and clear inputs when receive empty values
      if (options.valueType === 'boolean') {
        if (prevProps.checked !== this.props.checked && !this.props.checked) {
          this.updateField(this.props.checked || false);
        }
        if (
          prevProps.checkedDefault !== this.props.checkedDefault &&
          !this.props.checkedDefault
        ) {
          this.updateField(this.props.checkedDefault || false);
        }
        return null;
      }

      if (prevProps.value !== this.props.value && !this.props.value) {
        this.updateField('');
        return;
      }
      if (prevProps.valueDefault !== this.props.valueDefault) {
        let defaultValue = this.props.valueDefault || '';
        this.updateField(defaultValue);
        return;
      }

      return null;
    }

    componentDidUpdate() {}

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          onChange={this.handleFieldChanges.bind(this)}
          onFocus={this.handleFieldFocus.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
          onKeyUp={this.handleKeyUp.bind(this)}
        />
      );
    }

    handleFieldChanges({ target }) {
      let value = target.value;
      if (target.type === 'checkbox') value = target.checked;
      this.updateField(value);
    }

    handleFieldFocus() {
      this.setState({ wasFocused: true });
    }

    handleKeyDown() {}

    handleKeyUp() {
      this.props.clearTimeout(this.timer);
    }

    updateField(value) {
      this.props.clearTimeout(this.timer);

      let parsedValue = ValueParser.parse(value, {
        mask: this.props.mask || options.masks,
        validator: this.props.validator || options.validators,
      });

      let isValid = (value || '').length > 0 ? parsedValue.isValid : null;

      if (this.props.noValidate) {
        parsedValue.isValid = null;
        isValid = null;
      }

      this.setState({
        value: parsedValue.value,
        isEmpty: parsedValue.isEmpty,
        isValid: null,
        wasFocused: this.state.wasFocused,
      });

      parsedValue.wasFocused = this.state.wasFocused;

      this.timer = this.props.setTimeout(() => {
        this.setState({
          isValid: isValid,
        });
      }, 1000);

      // if (!this.state.wasFocused && !value) return;

      if (typeof this.props.onChange === 'function')
        this.props.onChange(parsedValue.value, parsedValue);
    }
  }

  FormFieldWrapper.displayName = `FormFieldWrapper(${getDisplayName(Component)})`;

  return ReactTimeout(FormFieldWrapper);
};

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export default formFieldCreator;
