import React from 'react';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

const ListRecordActionCell = ({ handleEditRow, handleExcludeRow }) => (
  <div className="buttons">
    <Button
      isNeutral
      isFlat
      title="Editar"
      onClick={handleEditRow}
      style={{ marginRight: 4 }}
    >
      <Icon name="fa fa-pen" />
    </Button>
    <Button isNeutral isFlat title="Excluir" onClick={handleExcludeRow}>
      <Icon style={{color: '#EB144C'}} name="fa fa-trash" />
    </Button>
  </div>
);

export default ListRecordActionCell;
