import React from 'react';
import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Container = (props) => {
  let CSSClass = `container ${
    parsePropsAsBulmaClasses(props)
  }`;
  return (
    <div className={CSSClass} style={props.style}>
      {props.children}
    </div>
  );
};

export default Container;
