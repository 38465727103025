import React from 'react';

// Components
import { FormFieldText } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import { fieldProps } from '../formCreator';

const ConfirmPassword = (props) => (
  <React.Fragment>
    <Grid>
      <Grid.Col>
        <FormFieldText
          label="Senha atual"
          {...fieldProps(props, 'password')}
        />
      </Grid.Col>
      <Grid.Col>
        <FormFieldText
          label="Nova senha"
          {...fieldProps(props, 'new_password')}
        />
      </Grid.Col>
    </Grid>
  </React.Fragment>
);

export default ConfirmPassword;
