import React from 'react';
import ReactTimeout from 'react-timeout';

const defaultOptions = {
  time: 300,
  children: null,
  showSpinner: false,
  top: 0,
  left: 0
};

const SplashScreen = (props) => (
  <div className={`splash-screen${
    props.isVisible ? ' is-visible' : ''
  }${
    props.showSpinner ? ' has-spinner' : ''
  }`}
  style={props.style}>
    {props.children}
  </div>
);

export function withSplashScreen(Component, options) {

  class SplashScreenWrapper extends React.Component {

    constructor(props) {
      super(props);
      this.state = { isVisible: true };
      this.options = { ...defaultOptions, ...options };
    }

    componentDidMount() {
      this.props.setTimeout(() => {
        this.setState({ isVisible: false });
      }, this.options.time);
    }

    componentWillUnmount() {
      this.setState({ isVisible: true });
    }

    render() {
      return (
        <React.Fragment>
          <SplashScreen
            isVisible={this.state.isVisible}
            showSpinner={this.options.showSpinner}
            style={{ marginTop: this.options.top, marginLeft: this.options.left }}>
            {this.options.children}
          </SplashScreen>
          <Component {...this.props} />
        </React.Fragment>
      );
    }

  };

  SplashScreenWrapper.displayName = `SplashScreenWrapper(${getDisplayName(Component)})`;

  return ReactTimeout(SplashScreenWrapper);

};

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
};

export default SplashScreen;
