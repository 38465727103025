
// Components
import { RouteWrapper } from 'utils/RouterUtils';
import PublicApp from 'containers/PublicApp';

// Routes
import authRoutes from 'containers/Auth/routes';
import appRoutes from 'containers/AppWrapper/routes';

const routes = [
  {
    component: RouteWrapper,
    beforeReturn: (props) => {
      const pathname = props.location.pathname;
      const token = localStorage.getItem('izee-token');
      let homeUrl = '/auth';
      if (pathname === '/' && token) {
        homeUrl = `/app/0`;
        props.history.replace(homeUrl);
      } else if (pathname === '/' && !token) {
        props.history.replace(homeUrl);
      }
    },
    routes: [
      {
        name: 'App',
        path: '/app/:userId',
        component: RouteWrapper,
        routes: appRoutes,
      },
      {
        name: 'Auth',
        path: '/auth',
        component: RouteWrapper,
        routes: authRoutes,
      },
      {
        name: 'Public App',
        path: '/public/:hash?',
        component: PublicApp,
      },
    ],
  },
];

export default routes;
