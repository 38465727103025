import React, { Component } from 'react'

import request from 'axios'
import { baseURL } from 'core/constants'
import Space from 'components/lib/Space'
import Button from 'components/lib/Button'

import OwnershipDate from './OwnershipDate'
import Settings from './Settings'
import Payday from './Payday'
import ValidityMonths from './ValidityMonths'

class Options extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: null,
      keysLocation: '',
      guaranteeValue: '',
      payday: props.defaultValue.payday,
      validityMonths: props.defaultValue.validity_months,
      validate: {
        payday: true,
        validity_months: true,
      },
    }
  }

  render() {
    const {
      proposalId,
      processId,
      dataValue,
      onSuccess,
      defaultValue,
      showGuaranteeValue,
      maxLength,
      allowMaxLengthField,
    } = this.props

    const { keysLocation } = this.state

    return (
      <>
        <div className='flex-form'>
          <Payday
            defaultValue={this.props.defaultValue.payday}
            disabledAutoFocus
            onChange={(e) => {
              this.setState({ payday: e })
            }}
            isValid={this.state.validate.payday}
            errorMessage='O Dia de Pagamento deve ser um número entre 1 e 31'
          />
          {this.props.isRental && (
            <ValidityMonths
              defaultValue={this.props.defaultValue.validityMonths}
              disabledAutoFocus
              onChange={(e) => {
                this.setState({ validityMonths: e })
              }}
              isValid={this.state.validate.validity_months}
              errorMessage='A Vigência deve ser um número maior que 0'
            />
          )}
        </div>

        <Space isSmall />

        <OwnershipDate
          processId={processId}
          dataValue={dataValue}
          disabledAutoFocus
          onSuccess={onSuccess}
          changeDate={(e) => this.changeDate(e)}
        />

        <Space isSmall />

        <Settings
          defaultValue={defaultValue}
          showGuaranteeValue={showGuaranteeValue}
          disabledAutoFocus
          maxLength={maxLength}
          currentFieldLength={this.state.keysLocation.length}
          allowMaxLengthField={allowMaxLengthField}
          changeKeysLocation={(e) => this.changeKeysLocation(e)}
          changeGuaranteeValue={(e) => this.changeGuaranteeValue(e)}
        />

        <Space isSmall />

        <div className='text-right'>
          <Button
            isLoading={this.state.isWorking}
            hasThemeColor
            onClick={() => this.submitOptions()}
            isDisabled={keysLocation.length > maxLength}
          >
            Salvar
          </Button>
        </div>
      </>
    )
  }

  changeDate(date) {
    this.setState({ date })
  }

  changeKeysLocation(keysLocation) {
    this.setState({ keysLocation })
  }

  changeGuaranteeValue(guaranteeValue) {
    this.setState({ guaranteeValue })
  }

  saveDateOwnership(date) {
    if (this.state.date)
      return request.post(
        `${baseURL}company/${this.props.company.id}/process/${this.props.processId}/overview/define-ownership-date`,
        { ownership_date: date },
      )
  }

  saveGuaranteeValue(guaranteeValue) {
    const guarantee_value = {
      guarantee_value: parseFloat(
        guaranteeValue.replace(/\./g, '').replace(',', '.'),
      ),
    }

    return request.post(
      `${baseURL}company/${this.props.company.id}/process/${this.props.processId}/overview/set-guarantee-value`,
      guarantee_value,
    )
  }

  savekeysLocation(keysLocation) {
    return request.post(
      `${baseURL}company/${this.props.company.id}/process/${this.props.processId}/overview/set-keys-location`,
      { keys_location: keysLocation },
    )
  }

  savePaydayValidityMonths() {
    return request.put(
      `${baseURL}company/${this.props.company.id}/proposal/${this.props.proposalId}`,
      { payday: this.state.payday, validity_months: this.state.validityMonths },
    )
  }

  validateForm() {
    const validate = {
      payday: true,
      validity_months: true,
    }

    if (
      (this.state.payday !== '' && this.state.payday < 1) ||
      this.state.payday > 31
    ) {
      validate.payday = false
    }

    if (this.state.validityMonths !== '' && this.state.validityMonths < 1) {
      validate.validity_months = false
    }
    this.setState({ validate, validate })

    if (!validate.payday || !validate.validity_months) {
      return false
    } else return true
  }

  submitOptions() {
    if (!this.validateForm()) return

    this.setState({ isWorking: true })

    const { showGuaranteeValue } = this.props
    const { date, keysLocation, guaranteeValue } = this.state

    const promises = []

    promises.push(this.saveDateOwnership(date))
    promises.push(this.savekeysLocation(keysLocation))
    promises.push(
      this.savePaydayValidityMonths({
        payday: this.state.payday,
        validity_months: this.state.validityMonths,
      }),
    )

    if (showGuaranteeValue) promises.push(this.saveGuaranteeValue(guaranteeValue))

    Promise.all(promises)
      .then(() => this.props.onSuccess({ isSuccess: true }))
      .catch(() => this.props.onSuccess({ isSuccess: false }))
      .finally(() => this.setState({ isWorking: false }))
  }
}

export default Options
