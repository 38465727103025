import React from 'react';
import PropTypes from 'prop-types';

// Components
import SubmitButton from 'components/SubmitButton';

import formCreator from '../components/formCreator';
import UserInvite from '../components/fieldsGroup/UserInvite';
import ProfileSelection from '../components/fieldsGroup/ProfileSelection';

export class UpdateUser extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>

        <UserInvite {...this.props} />

        <ProfileSelection {...this.props} />

        <SubmitButton
          label="Salvar"
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.submit(`{company}/user/${this.props.defaultValue.id}`, fields, 'put');
  }

};

UpdateUser.defaultProps = {
  fields: {},
  activeCompany: {}
};

UpdateUser.propTypes = {
  activeCompany: PropTypes.object.isRequired
};

export default formCreator(UpdateUser);
