import React from 'react';
import PropTypes from 'prop-types';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';
import { getTypeOf } from 'utils/utils';

class FilePreview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      source: null
    };
  }

  componentDidMount() {
    if (getTypeOf(this.props.source) === 'Promise') {
      this.handlePromise(this.props.source);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    const filenameChanges = this.props.filename !== prevProps.filename;
    const sourceChanges = this.props.source !== prevProps.source;
    const isPromise = getTypeOf(this.props.source) === 'Promise';

    if (!isPromise && sourceChanges) {
      this.setState({ source: this.props.source });
      return null;
    }

    if (isPromise && filenameChanges) {
      this.handlePromise(this.props.source);
    }

    return null;
  }

  render() {

    let filePreviewCSSClass = `file-preview ${
      parsePropsAsBulmaClasses(this.props)
    }`;

    const isImage = this.props.ext.match(/(jpg|jpeg|png|gif|svg)/);

    let preview = (
      <img
        src={this.state.source || this.props.source}
        alt={this.props.filename}
      />
    );

    if (!isImage) {
      preview = this.props.fallback || this.props.filename;
    }

    return (
      <figure
        data-spec-selector={this.props.specSelector}
        className={filePreviewCSSClass}>
        {preview}
      </figure>
    );

  }

  handlePromise(promise) {
    promise.then((source) => {
      this.setState({ source });
    });
  }

};

export default FilePreview;
