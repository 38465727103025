import React from 'react';
import PropTypes from 'prop-types';

import _propsObject from './propsObject';

const Select = (props) => {
  const options = props.options.map((item, index) => {
    return (
      <option
        value={item[props.valueKey] || item.value}
        key={index}>
        {item[props.labelKey] || item.name}
      </option>
    );
  });

  return (
    <div className="select">
      <select
        {..._propsObject(props)}
        value={(props.value && props.value[props.valueKey] || props.value || props.defaultValue && props.defaultValue[props.valueKey] || props.defaultValue || '')}>
        {props.placeholder && <option value="">{props.placeholder}</option>}
        {!props.hideBlankOption && <option value="" />}
        {options}
      </select>
    </div>
  );
};

Select.defaultProps = {
  valueKey: 'name'
};

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};

export const propsObject = _propsObject;

export default Select;
