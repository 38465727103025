import React from 'react';

import Button from 'components/lib/Button';
import Space from 'components/lib/Space';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

const SubmitButton = (props) => (
  <React.Fragment>
    <Space />
    <Field isRight>
      <Control>
        <Button
          {...props}
          hasThemeColor={!props.isDanger}
          isLoading={props.isLoading}
          isDisabled={props.isDisabled}
          submit>
          {props.label}
        </Button>
      </Control>
    </Field>
  </React.Fragment>
);

export default SubmitButton;
