import General from './components/General';
import Advanced from './components/Advanced';
import Members from 'containers/Members';
import Integrations from './components/Integrations';
import Billing from './components/Billing';
import Logs from 'components/Logs';


const routes = [
  {
    name: 'General',
    path: '/app/:userId/:companyId/settings/general',
    exact: true,
    component: General,
  },
  {
    name: 'Advanced',
    path: '/app/:userId/:companyId/settings/advanced',
    exact: true,
    component: Advanced,
  },
  {
    name: 'Members',
    path: '/app/:userId/:companyId/settings/members',
    exact: true,
    component: Members,
  },
  {
    name: 'Integrations',
    path: '/app/:userId/:companyId/settings/integrations',
    exact: true,
    component: Integrations,
  },
  {
    name: 'Billing',
    path: '/app/:userId/:companyId/settings/billing',
    exact: true,
    component: Billing,
  },
  {
    name: 'Logs',
    path: '/app/:userId/:companyId/settings/logs',
    exact: true,
    component: Logs
  }
];

export default routes;
