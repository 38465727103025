import React from "react";

import Input, {
  propsObject as defaultProps
} from "components/lib/FormFields/components/Input";
import Field from "components/lib/FormFields/components/Field";
import Control from "components/lib/FormFields/components/Control";

import fieldCreator from "./fieldCreator";

const FormFieldText = props => {
  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Field {...props}>
      <Control {...props}>
        <Input
          {...props}
          {...defaultProps(props)}
          isDanger={isValid || props.forceInvalid}
        />
      </Control>
      {props.forceInvalid && props.errorMessage ? (
        <p className="help is-danger">{props.errorMessage}</p>
      ) : null}
    </Field>
  );
};

export default fieldCreator(FormFieldText, {
  masks: [],
  validators: [{ minLength: 1 }]
});
