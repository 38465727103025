import React from 'react';

import _propsObject from './propsObject';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const TextArea = (props) => {
  const textareaCSSClass = `textarea ${
    parsePropsAsBulmaClasses(props)
  }`;
  return (
    <textarea
      className={textareaCSSClass}
      {..._propsObject(props)}
    />
  );
};

export const propsObject = _propsObject;

export default TextArea;
