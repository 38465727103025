import React from 'react'

import returnFormattedFields from 'utils/returnFormattedFields'
import { rawNumber } from 'utils/utils'

import formCreator from 'features/components/formCreator'

import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'

import JuridicalPerson from './components/JuridicalPerson'
import PhysicalPerson from './components/PhysicalPerson'
import Contract from './components/Contract'
import Property from './components/Property'

import { ButtonContainer, Container, Modal, ModalHeader, ModalBody } from './styles'

import integrationsConfiax from '../../../../'
class NeedInformationModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fieldErrors: [],
    }
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this))
  }

  render() {
    const { proposal, personData, closeModal } = this.props

    const clients = proposal.clients
    const mainClient = clients[0]

    const isJuridical = !!mainClient.cnpj
    const [, ...otherClients] = clients
    const goal = proposal.goal.code

    const formProps = {
      ...this.props,
      isDeep: true,
      ...{
        fieldErrors: this.state.fieldErrors,
      },
    }

    return (
      <Container onClick={closeModal}>
        <Modal onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <span>Solicitar análise</span>

            <Button isBare onClick={closeModal}>
              <Icon name='fas fa-times' />
            </Button>
          </ModalHeader>

          <ModalBody>
            <Contract isJuridical={isJuridical} formProps={formProps} goal={goal} />

            <Property formProps={formProps} />

            {isJuridical ? (
              <JuridicalPerson formProps={formProps} />
            ) : (
              <PhysicalPerson
                formProps={formProps}
                personData={personData}
                otherClients={otherClients}
                goal={goal}
              />
            )}

            <ButtonContainer>
              <Button hasThemeColor submit>
                Concluir
              </Button>
            </ButtonContainer>
          </ModalBody>
        </Modal>
      </Container>
    )
  }

  async createRequest() {
    try {
      this.setState({
        fieldErrors: [],
      })

      const {
        company,
        proposal,
        personData,
        propertyData,
        notification,
        closeModal,
        onSuccess = () => {},
      } = this.props

      const clients = proposal.clients
      const mainClient = clients[0]

      const isJuridical = !!mainClient.cnpj
      const [, ...otherClients] = clients
      const goal = proposal.goal.code

      const formattedFields = returnFormattedFields(this.props.fields)

      const gas_bill = rawNumber(formattedFields.property.gas_bill)
      const water_bill = rawNumber(formattedFields.property.water_bill)
      const energy_bill = rawNumber(formattedFields.property.energy_bill)

      const total_amount =
        gas_bill +
        water_bill +
        energy_bill +
        propertyData.taxes_value +
        propertyData.condominium_fee +
        propertyData.rent_value

      const payload = {
        ...formattedFields,

        analysis_assurance: 'SIMPLE',
        purpose: goal === 'GOAL_COMMERCIAL' ? 'COMMERCIAL' : 'RESIDENTIAL',
        tenant_type: isJuridical ? 'LEGAL' : 'NATURAL',

        property: {
          ...formattedFields.property,
          ...propertyData,
          ...{
            total_amount,
            gas_bill,
            water_bill,
            energy_bill,
          },
        },

        [isJuridical ? 'legal_tenant' : 'natural_tenant']: {
          ...personData,
          ...formattedFields[isJuridical ? 'legal_tenant' : 'natural_tenant'],

          // Natural person payload adicional fields
          ...(!isJuridical && {
            resident: true,

            ...(goal === 'GOAL_RESIDENTIAL' && {
              include_sympathise_tenants: otherClients > 0,

              sympathise_tenants: otherClients.map((otherClient, i) => ({
                ...otherClient,
                ...formattedFields.natural_tenant.sympathise_tenants[i],
              })),
            }),
          }),
        },
      }

      const { data } = await integrationsConfiax.confiax({
        companyId: company.id,
        payload: {
          action: 'CREATE_QUOTE',
          insuranceData: payload,
          company,
          proposal,
        },
      })

      if (data.app.response.success) {
        closeModal()

        onSuccess()
      } else {
        this.setState({
          fieldErrors: data.app.response.data?.errors || [],
        })
      }

      if (data.app.response.message) {
        notification({
          message: data.app.response.message,
          status: data.app.response.success ? 'success' : 'danger',
        })
      }
    } catch (err) {
      const { notification } = this.props

      notification({
        message: 'Erro ao tentar criar uma cotação',
        status: 'danger',
      })
    } finally {
      this.props.cancelProgress()
    }
  }
}

export default formCreator(NeedInformationModal)
