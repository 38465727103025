import React from 'react'
import ReactTimeout from 'react-timeout'

import AsyncSelect from 'react-select/async'
import { components } from 'react-select'

import Field from 'components/lib/FormFields/components/Field'
import Control from 'components/lib/FormFields/components/Control'
import Icon from 'components/lib/Icon'
import Text from 'components/lib/Text'

import { request } from 'core/constants'
import CreateProperty from 'features/CreateProperty'
import Modal from 'components/lib/Modal'

import AwesomeDebouncePromise from 'awesome-debounce-promise'

import AddButton from './components/AddButton'

class PropertySelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      options: (this.props.defaultValue || {}).properties || [],
      isWorking: this.props.isDisabled || false,
      modalIsOpen: false,
    }

    this.asyncRef = React.createRef()
    this.debouncePromiseOptions = AwesomeDebouncePromise(
      this.promiseOptions.bind(this),
      400,
    )
  }

  componentDidMount() {
    if ((this.props.defaultValue || {}).properties) {
      const defaultOptions = (this.props.defaultValue || {}).properties[0]

      this.props.handleFieldChanges('property_ids', [defaultOptions.id], {
        isValid: [defaultOptions.id].length > 0,
        value: [defaultOptions.id],
      })
    }
  }

  onAdded(result) {
    if (result.isSuccess) {
      this.setState({
        modalIsOpen: false,
        options: result.data,
      })

      this.onChangeAsyncSelect(this.state.options)
    } else {
      this.props.notification({
        message: 'Não foi possível adicionar esse imóvel',
        status: 'danger',
      })
    }
  }

  onChangeAsyncSelect(options) {
    options.source === 'izee'
      ? this.setOptions(options)
      : this.importProperty(options)
  }

  promiseOptions(inputValue) {
    return new Promise((resolve, reject) => {
      request
        .get('{company}/property/search?q=' + inputValue)
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => reject())
    })
  }

  setOptions(options) {
    this.setState({ options, inputValue: '' })
    if (this.props.onSelect) this.props.onSelect(options)
    this.props.handleFieldChanges('property_ids', [options.id], {
      isValid: [options.id].length > 0,
      value: [options.id],
    })
  }

  importProperty(options) {
    this.setOptions(options)

    if (options.source && options.id)
      request
        .get('{company}/property/import/' + options.source + '?id=' + options.id)
        .then((response) => this.setOptions(response.data))
  }

  render() {
    const { showAddition } = this.props

    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <Icon name='fas fa-home' />
      </components.DropdownIndicator>
    )

    const Option = (props) => {
      const address = props.data.address || {}
      const data = props.data || {}

      return (
        <components.Option {...props}>
          <span style={{ fontSize: 14, fontWeight: 700, color: '#4a4a4a' }}>
            {data.name || data.code}
            {data.name && data.code ? ` (${data.code})` : ''}
          </span>
          <br />
          {address.address ? (
            <span style={{ fontSize: 12 }}>
              {address.address}, {address.neighborhood} - {address.city} /{' '}
              {address.state}
            </span>
          ) : (
            'Sem endereço'
          )}
        </components.Option>
      )
    }

    const SingleValue = (props) => {
      if (props.data.source && props.data.source !== 'izee') {
        return <Text>Importando do {props.data.source}...</Text>
      }

      const address = props.data.address || {}

      return (
        <components.SingleValue {...props}>
          {address.address ? (
            <span style={{ fontSize: 12 }}>
              {address.address}, {address.neighborhood} - {address.city} /{' '}
              {address.state}
            </span>
          ) : (
            'Sem endereço'
          )}
        </components.SingleValue>
      )
    }
    const LoadingIndicator = (props) => null

    const isValid = this.props.isValid == true

    return (
      <React.Fragment>
        <Field label='Imóvel'>
          <Control>
            <div className='react-select-wrapper' style={{ display: 'flex' }}>
              <AsyncSelect
                styles={
                  !isValid && {
                    control: (provided) => ({
                      ...provided,
                      borderColor: '#ff0000',
                    }),
                  }
                }
                defaultOptions
                ref={(ref) => (this.asyncRef = ref)}
                classNamePrefix='react-select'
                id='property-selection'
                defaultValue={[]}
                name='property'
                isDisabled={this.state.isWorking}
                isLoading={this.state.isWorking}
                value={this.state.options}
                loadingMessage={() => 'Pesquisando por imóveis...'}
                noOptionsMessage={() => <span>Nenhum imóvel encontrado</span>}
                placeholder='Selecionar imóvel'
                menuPosition='fixed'
                maxMenuHeight={168}
                minMenuHeight={100}
                onInputChange={this.handleInputChange.bind(this)}
                inputValue={this.state.inputValue}
                onChange={this.onChangeAsyncSelect.bind(this)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{
                  SingleValue,
                  DropdownIndicator,
                  LoadingIndicator,
                  Option,
                }}
                loadOptions={this.debouncePromiseOptions.bind(this)}
              />
              {showAddition ? (
                <AddButton onClick={() => this.setState({ modalIsOpen: true })} />
              ) : null}
            </div>
          </Control>
          {!isValid && <p className='help is-danger'>{this.props.errorMessage}</p>}
        </Field>
        <Modal
          title='Criar novo Imóvel'
          isOpen={this.state.modalIsOpen}
          onClose={() => {
            this.setState({ modalIsOpen: false })
          }}
          backgroundContentStyle={{
            display: 'block',
          }}
        >
          <CreateProperty
            onSuccess={this.onAdded.bind(this)}
            hidePropertyOwnerSelectionControl={true}
          />
        </Modal>
      </React.Fragment>
    )
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue })

    return inputValue
  }
}

export default ReactTimeout(PropertySelection)
