export const ROLES = [
  { id: 1, code: 'PROFILE_DEFAULT', title: 'Usuário padrão' },
  { id: 2, code: 'PROFILE_COMPANY_OWNER_PARTNER', title: 'Proprietário da empresa' },
  { id: 3, code: 'PROFILE_ADMIN', title: 'Administrador' },
  { id: 4, code: 'PROFILE_MANAGER', title: 'Gestor' },
  { id: 5, code: 'PROFILE_AGENT', title: 'Consultor' },
  { id: 6, code: 'PROFILE_INSPECTOR', title: 'Vistoriador' },
  { id: 7, code: 'PROFILE_JURIDICAL', title: 'Jurídico' },
  { id: 8, code: 'PROFILE_INSURANCE_BROKER', title: 'Corretor de seguros' },
  { id: 9, code: 'PROFILE_IZEE_ADMIN', title: 'Administrador Izee' },
];

export const ROLE = {
  PROFILE_DEFAULT: 'PROFILE_DEFAULT',
  PROFILE_COMPANY_OWNER_PARTNER: 'PROFILE_COMPANY_OWNER_PARTNER',
  PROFILE_ADMIN: 'PROFILE_ADMIN',
  PROFILE_MANAGER: 'PROFILE_MANAGER',
  PROFILE_AGENT: 'PROFILE_AGENT',
  PROFILE_INSPECTOR: 'PROFILE_INSPECTOR',
  PROFILE_JURIDICAL: 'PROFILE_JURIDICAL',
  PROFILE_INSURANCE_BROKER: 'PROFILE_INSURANCE_BROKER',
  PROFILE_IZEE_ADMIN: 'PROFILE_IZEE_ADMIN',
};

export const MARITAL_STATUS = [
  { id: 1, code: "MARITAL_STATUS_SINGLE", name: "Solteiro(a)" },
  { id: 2, code: "MARITAL_STATUS_MARRIED", name: "Casado(a)" },
  { id: 3, code: "MARITAL_STATUS_DIVORCED", name: "Divorciado(a)" },
  { id: 4, code: "MARITAL_STATUS_STABLE_UNION", name: "União Estável" },
  { id: 5, code: "MARITAL_STATUS_WIDOWED", name: "Viúvo(a)" }
];

export const ENTITY_TYPE = {
  PROPERTY_OWNER: 3,
  CLIENT: 4,
  GUARANTOR: 5,
}
