import React from 'react';
import moment from 'moment';

// Components
import SubmitButton from 'components/SubmitButton';
import Grid from 'components/lib/Grid';

import formCreator from 'features/components/formCreator';
import { fieldProps } from 'features/components/formCreator';

import { FormFieldSwitch } from 'components/lib/FormFields';
import Space from 'components/lib/Space';
import Text from 'components/lib/Text';
import Icon from 'components/lib/Icon';
import Header from 'components/lib/Header';
import PropertySelection from 'features/components/fieldsGroup/PropertySelection';
import ClientSelection from 'features/components/fieldsGroup/ClientSelection';
import GuarantorSelection from 'features/components/fieldsGroup/GuarantorSelection';
import Negotiation from 'features/components/fieldsGroup/Negotiation';

import DocumentRepeater from './DocumentsRepeater';

// Utils
import { goodObject, rawNumber } from 'utils/utils';
import { baseURL } from 'core/constants';
import request from 'axios';

export class CreateProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: {},
      clients: [],
      guarantee: {},
      guarantors: [],
      documents: [],
    };
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this), true);
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;

    let companyGuarantees = [...this.props.activeCompany.guarantees];

    const guarantees = companyGuarantees.map(
      (item) =>
        item['is_active'] && (
          <Grid.Col isNarrow key={item.id}>
            <FormFieldSwitch description={item.name} id={item.code} name="guarantee" type="radio" onChange={this.selectGuarantee.bind(this, item)} />
          </Grid.Col>
        )
    );

    const choosesGuarantee = true;

    const address = this.state.property.address;

    const clients = this.state.clients.map((item, index) => {
      const guarantees = item.guarantees ? item.guarantees.filter((guarantee) => guarantee.is_active) : '';

      return (
        <div key={item.id}>
          <div
            style={{
              borderTop: index > 0 && '1px solid #CCC',
              margin: index > 0 && '16px 0',
            }}
          />
          <div>
            <Text>
              {item.name}
              {item.is_verified && <Icon name="fas fa-sm fa-shield-check" isSuccess />}
            </Text>
          </div>
          <div>
            <Text isSmall>{item.email}</Text>
          </div>
          <div>{item.cnpj && item.cnpj !== '' ? <Text>CNPJ: {item.cnpj}</Text> : <Text>CPF: {item.cpf}</Text>}</div>
          {guarantees.length > 0 && (
            <div>
              <Text>
                Garantias pré-aprovadas pela <strong>Izee</strong>: {guarantees.map((guarantee) => guarantee.name).join(', ')}
              </Text>
            </div>
          )}
        </div>
      );
    });

    const { fields } = this.props;

    const { guarantee } = this.state;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col isTwoThirds>
            <Grid>
              <Grid.Col>
                <PropertySelection onSelect={this.selectedPropertyHandler.bind(this)} {...this.props} />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <ClientSelection onSelect={this.selectedClientsHandler.bind(this)} {...this.props} />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <Negotiation {...this.props} />
              </Grid.Col>
            </Grid>

            {choosesGuarantee && (
              <React.Fragment>
                <Grid>
                  <Grid.Col isFull>
                    <Space />
                    <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
                      Garantia
                      <FormFieldSwitch name="guarantee" type="radio" defaultChecked={true} onChange={this.selectGuarantee.bind(this, { id: 0 })} description="Todas" />
                    </Header>
                  </Grid.Col>
                </Grid>
                <Grid>{guarantees}</Grid>

                {guarantee.id === 1 ? (
                  <Grid>
                    <Grid.Col isFull>
                      <GuarantorSelection onSelect={this.selectedGuarantorsHandler.bind(this)} {...this.props} />
                    </Grid.Col>
                  </Grid>
                ) : null}
              </React.Fragment>
            )}

            <DocumentRepeater onChange={this.updateDocumentList.bind(this)} />

            <Grid>
              <Grid.Col isFull>
                <Space />
                <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
                  Configurações
                </Header>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col isFull>
                <FormFieldSwitch {...fieldProps(this.props, 'send_to_owner')} description="Enviar proposta para o proprietário?" name="send_to_owner" />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <SubmitButton label="Salvar" isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col>
            <div style={{ paddingLeft: 20 }}>
              <div>
                <div>
                  <Text>
                    <Icon name="fas fa-sm fa-home" style={{ marginLeft: -6 }} />
                    <strong>Imóvel</strong>
                  </Text>
                </div>
                <div style={{ padding: '0', marginTop: 10 }}>
                  {address ? (
                    <Text>
                      {address.address}, {address.neighborhood} - {address.city} / {address.state}
                    </Text>
                  ) : (
                    '---'
                  )}
                </div>
              </div>
              <hr />
              <div>
                <div>
                  <Text>
                    <Icon name="fas fa-sm fa-user" style={{ marginLeft: -6 }} />
                    <strong>Cliente</strong>
                  </Text>
                </div>
                <div style={{ padding: '0', marginTop: 10 }}>{clients.length ? clients : '---'}</div>
              </div>
              <hr />
              <div>
                <div>
                  <Text>
                    <Icon name="fas fa-sm fa-usd-circle" style={{ marginLeft: -6 }} />
                    <strong>Valor do aluguel:</strong>
                    &nbsp;
                    {fields['rent_value'] && 'R$ ' + (fields['rent_value'].value || '-.---,--')}
                  </Text>
                </div>
              </div>
              <div>
                <div>
                  <Text>
                    <Icon name="fas fa-sm fa-calendar-alt" style={{ marginLeft: -6 }} />
                    <strong>Data da posse:</strong>
                    &nbsp;
                    {fields['ownership_date'] && (fields['ownership_date'].value || '--/--/----')}
                  </Text>
                </div>
              </div>
              <div>
                <div>
                  <Text>
                    <Icon name="fas fa-sm fa-asterisk" style={{ marginLeft: -6 }} />
                    <strong>Negociação:</strong>
                    <br />
                    <span style={{ whiteSpace: 'pre-line' }}>{fields.negotiation && (fields.negotiation.value || '------- ---- --- ---')}</span>
                  </Text>
                </div>
              </div>
            </div>
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  updateDocumentList(value, field) {
    if (!field.wasFocused) return;
    let list = value.split(',');
    list = list.map((item) => item.trim());
    this.setState({ documents: list });
  }

  selectedPropertyHandler(property) {
    this.setState({ property });
  }

  selectedClientsHandler(clients) {
    this.setState({ clients });
  }

  selectedGuarantorsHandler(guarantors) {
    this.setState({ guarantors });
    this.props.onSelectGuarantor(guarantors.map((guarantor) => guarantor.id));
  }

  selectedNegotiationHandler() {}

  selectGuarantee(guarantee, value, field) {
    if (!field.wasFocused) return;
    this.setState({ guarantee: guarantee });
  }

  createRequest(fields) {
    let goodFields = goodObject(fields, {
      ownership_date: {
        path: 'ownership_date',
        format: (value) => moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      },
      rent_value: {
        path: 'rent_value',
        format: (value) => rawNumber(value),
      },
    });

    goodFields['guarantee_id'] = this.state.guarantee.id || null;
    goodFields['return_to'] = 'http://process.izee.com.br';
    goodFields['name'] = 'Nova proposta!';

    goodFields['additional_documents'] = this.state.documents;

    if (!goodFields.clients[0]) return;

    goodFields.clients[0].main = true;

    goodFields['property_owner_ids'] = this.state.property && this.state.property['property_owners'].map((item) => item.id);

    const { documents, clients, guarantors } = this.state;
    const clientEntity = 4;
    const guarantorEntity = 5;
    const clientUrl = `${baseURL}entity/${clientEntity}/upload/file`;
    const guarantorUrl = `${baseURL}entity/${guarantorEntity}/upload/file`;
    const headers = { 'Access-Control-Allow-Origin': true };

    clients.map((client) => {
      documents.map((document) => {
        const formData = new FormData();
        formData.append('name', document);
        formData.append('owner_document', client.id);
        formData.append('entity_id', clientEntity);
        formData.append('status', 'STATUS_WAITING');
        request
          .post(clientUrl, formData, headers)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => console.log(err));
      });
    });

    guarantors.map((guarantor) => {
      documents.map((document) => {
        const formData = new FormData();
        formData.append('name', document);
        formData.append('owner_document', guarantor.id);
        formData.append('entity_id', guarantorEntity);
        formData.append('status', 'STATUS_WAITING');
        request
          .post(guarantorUrl, formData, headers)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => console.log(err));
      });
    });

    this.props.submit('{company}/proposal', goodFields);
  }
}

export default formCreator(CreateProcess);
