import styled from 'styled-components';

export const Container = styled.div`
  height: 75%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 24px;
`;

export const Text = styled.span`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;

  color: #b5b5b5;
`;
