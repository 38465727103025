const initialState = {
  isWorking: {},
  notification: {
    isOpen: false,
    message: '',
    status: null
  },
  helpers: {}
};

export default (state = initialState, action = {}) => {

  switch (action.type) {

  case 'IS_WORKING':
    let isWorking = { ...state.isWorking };
    if (action.valueKey === 'all') {
      Object.keys(isWorking).forEach((item) => isWorking[item] = action.payload);
    } else {
      isWorking = { ...isWorking, ...{ [action.valueKey]: action.payload } };
    }
    state = { ...state, ...{ isWorking: isWorking } };
    break;

  case 'SHOW_NOTIFICATION':
    const { isOpen, message, status } = action.payload;
    state = {
      ...state,
      ...{
        notification: {
          isOpen: isOpen,
          message: isOpen ? message : '',
          status: status
        }
      }
    };
    break;

  case 'HIDE_NOTIFICATION':
    state = {
      ...state,
      ...{
        notification: {
          isOpen: false,
          message: '',
          status: null
        }
      }
    };
    break;

  case 'CREATE_HELPER':
    let helpers = state.helpers;
    helpers[action.payload.name] = action.payload.helper;
    state = {
      ...state,
      ...{ helpers: helpers }
    };
    break;

  default:
    break;
  }

  return state;
};
