import React, { useEffect } from 'react'

import { ga } from '../../services/firebase'
import * as commons from 'utils/UtilityStore/actions'
import ModalPlanRestriction from 'components/ModalPlanRestriction'
import ScreenHeaderPrimary from 'components/ScreenHeaderPrimary'
import withRedux from 'components/redux'

import { DashboardIframe } from './styles'

const Dashboard = (props) => {
  const token = localStorage.getItem('izee-token')

  const companyId = props.activeCompany.id

  const subscriptionPlan = props.company?.subscription_plan

  const canUseDashboard = subscriptionPlan && subscriptionPlan?.can_access_dashboard

  useEffect(() => {
    if (canUseDashboard) {
      const gaPayload = {
        user_email: props.activeUser.email,
        company_name: props.activeCompany.name,
      }

      ga.logEvent('use_dashboard', gaPayload)
    }
  }, [])

  return (
    <>
      <ScreenHeaderPrimary {...props} />

      <div
        style={{
          position: 'relative',
          flex: 1,
          overflowY: 'hidden',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!canUseDashboard && <ModalPlanRestriction />}

        <DashboardIframe
          // eslint-disable-next-line no-undef
          src={`${DASHBOARD_URL}?token=${token}&companyId=${companyId}`}
        />
      </div>
    </>
  )
}

export default withRedux({ ...commons })(Dashboard)
