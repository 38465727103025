import React from 'react';

import { request } from 'core/constants';

import Grid from 'components/lib/Grid';
import Text from 'components/lib/Text';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import { FormFieldFile } from 'components/lib/FormFields';

import uploadFile from '../../uploadFile';

export default class AddDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [{ mockId: 0 }],
      isUpload: {},
      progress: {},
      message: {},
    };
  }

  componentDidMount() {
    // if (this.props.onInit) this.props.onInit(this.state.documents);
  }

  render() {
    const { name, hideRepeater, description } = this.props;

    const { message } = this.state;
    const documents = this.state.documents.map((item, index) => {
      const documentName = `${name}${index === 0 ? '' : ' ' + (index + 1)}`;

      const progress = (
        <strong
          style={{
            color: this.state.isUpload[documentName] ? null : '#39c357',
          }}>
          {this.state.progress[documentName]
            ? ` (${this.state.progress[documentName] === 100 ? (this.state.isUpload[documentName] ? 'Aguarde...' : 'Arquivo enviado com sucesso!') : this.state.progress[documentName] + '%'})`
            : ''}
        </strong>
      );

      return (
        <Grid key={item.mockId}>
          <Grid.Col>
            <FormFieldFile
              onChange={this.sendFile.bind(this, documentName, item.mockId)}
              isLoading={this.state.isUpload[documentName]}
              isDisabled={this.state.isUpload[documentName]}
              label={
                <span>
                  {documentName} {progress}
                </span>
              }
              accept="image/*, .pdf"
              isFlat
            />
            {description && (
              <Text isSmall style={{ marginTop: -5 }}>
                <strong>Motivo da recusa:</strong> {description}
              </Text>
            )}
          </Grid.Col>
          {!hideRepeater && (
            <Grid.Col isNarrow isFlexBottom>
              <Button isFlat isDark isDisabled={this.state.documents.length === 1} onClick={this.update.bind(this, 'remove', item.mockId)}>
                <Icon name="fas fa-sm fa-times" />
              </Button>
            </Grid.Col>
          )}
        </Grid>
      );
    });

    return (
      <React.Fragment>
        {documents}

        {!hideRepeater && (
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button isFlat isDark onClick={this.update.bind(this, 'add')}>
              <Icon name="fas fa-sm fa-file-plus" /> Outro
            </Button>
          </div>
        )}

        <div style={{ borderBottom: '1px solid #CCC', marginTop: 10, marginBottom: 15 }} />
      </React.Fragment>
    );
  }

  update(action, mockId) {
    const documents = this.state.documents;
    if (action === 'add') {
      documents.push({ mockId: Math.random(999999999) });
    }
    if (action === 'remove') {
      let findIndex = 0;
      documents.forEach((item, index) => {
        if (item.mockId === mockId) {
          findIndex = index;
          this.setState({
            isUpload: { ...this.state.isUpload, ...{ [item.name]: false } },
            message: { ...this.state.message, ...{ [item.name]: '' } },
          });
        }
      });
      documents.splice(findIndex, 1);
    }
    this.setState({ documents });
    this.props.onAddFile(documents);
  }

  uploadProgress(fileName, event) {
    let progress = (event.loaded * 100) / event.total;
    this.setState({
      progress: { ...this.state.progress, ...{ [fileName]: Math.round(progress) } },
    });
  }

  sendFile(fileName, mockId, file, field) {
    if (!field.value) return;

    const { keyId, typeRegister } = this.props;
    const hash = localStorage.getItem('izee-token');

    if (!keyId || !hash) return;

    const path = `/public/${typeRegister}/${keyId}/file`;

    request.defaults.headers.common['Authorization'] = 'Bearer ' + hash;

    this.setState({
      isUpload: { ...this.state.isUpload, ...{ [fileName]: true } },
      message: { ...this.state.message, ...{ [fileName]: 'Enviando arquivo...' } },
      progress: { ...this.state.progress, ...{ [fileName]: null } },
    });

    uploadFile(file[0], this.uploadProgress.bind(this, fileName)).then((source) => {
      request
        .post(path, {
          name: fileName,
          type: file[0].ext,
          source: source,
        })
        .then(
          (response) => {
            // Preciso add os dados que retornam da requisição ao array já existente de aqruivos.
            let files = this.state.documents;

            files = this.state.documents.map((item) => {
              if (item.mockId === mockId) item['file_id'] = response.data.id;
              return item;
            });

            this.setState({
              documents: files,
              isUpload: { ...this.state.isUpload, ...{ [fileName]: false } },
              message: { ...this.state.message, ...{ [fileName]: 'Arquivo enviado com sucesso' } },
            });

            this.props.onUpload(files);
          },
          () => {
            this.setState({
              isUpload: { ...this.state.isUpload, ...{ [fileName]: false } },
              message: { ...this.state.message, ...{ [fileName]: 'Ocorreu um erro ao tentar anexar o documento' } },
            });
          }
        );
    });
  }
}
