import React from "react";
import Icon from "components/lib/Icon";

const Section = ({ title, iconName, children }) => {
  const icon = iconName && (
    <Icon name={`fas fa-${iconName}`} style={{ marginLeft: -4 }} />
  );

  return (
    <div className="proposal-section" style={{ pageBreakAfter: "auto" }}>
      <header
        className="proposal-section-title"
        style={{ border: !children && "none" }}
      >
        {icon} <strong>{title}</strong>
      </header>
      <div className="proposal-section-body">{children}</div>
    </div>
  );
};

export default Section;
