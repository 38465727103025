import React, { Fragment, Component } from 'react'

import request from 'axios'
import { baseURL } from 'core/constants'
import Space from 'components/lib/Space'
import Button from 'components/lib/Button'

import OwnershipDate from './OwnershipDate'
import Settings from './Settings'
import Payday from './Payday'
import ValidityMonths from './ValidityMonths'

class Options extends Component {
  proposalId
  companyId

  constructor(props) {
    super(props)

    this.proposalId = props.proposalId
    this.companyId = props.companyId

    this.state = {
      isWorking: false,
      date: props.defaultValue.ownershipDate,
      keysLocation: props.defaultValue.keysLocation,
      payday: props.defaultValue.payday,
      validityMonths: props.defaultValue.validity_months,
      validate: {
        payday: true,
        validity_months: true,
      },
    }
  }

  saveOwnershipDateInProposal() {
    if (this.state.date)
      return request.put(
        `${baseURL}company/${this.companyId}/proposal/${this.proposalId}/define-ownership-date`,
        { ownership_date: this.state.date },
      )
  }

  saveKeyLocationInProposal() {
    return request.put(
      `${baseURL}company/${this.companyId}/proposal/${this.proposalId}/set-keys-location`,
      { keys_location: this.state.keysLocation },
    )
  }

  savePaydayValidityMonths() {
    return request.put(
      `${baseURL}company/${this.companyId}/proposal/${this.proposalId}`,
      { payday: this.state.payday, validity_months: this.state.validityMonths },
    )
  }

  submitOptions() {
    if (!this.validateForm()) return

    this.setState({ isWorking: true })

    Promise.all([
      this.saveOwnershipDateInProposal(),
      this.saveKeyLocationInProposal(),
      this.savePaydayValidityMonths(),
    ])
      .then((values) => {
        this.props.onSuccess({ isSuccess: true })
      })
      .catch((err) => {
        this.props.onSuccess({ isSuccess: false, errors: err.errors })
      })
      .finally(() => {
        this.setState({ isWorking: false })
      })
  }

  validateForm() {
    const validate = {
      payday: true,
      validity_months: true,
    }

    if (
      (this.state.payday !== '' && this.state.payday < 1) ||
      this.state.payday > 31
    ) {
      validate.payday = false
    }

    if (this.state.validityMonths !== '' && this.state.validityMonths < 1) {
      validate.validity_months = false
    }
    this.setState({ validate, validate })

    if (!validate.payday || !validate.validity_months) {
      return false
    } else return true
  }

  render() {
    return (
      <Fragment>
        <div className='flex-form'>
          <Payday
            defaultValue={this.props.defaultValue.payday}
            disabledAutoFocus
            onChange={(e) => {
              this.setState({ payday: e })
            }}
            isValid={this.state.validate.payday}
            errorMessage='O Dia de Pagamento deve ser um número entre 1 e 31'
          />
          {this.props.isRental && (
            <ValidityMonths
              defaultValue={this.props.defaultValue.validityMonths}
              disabledAutoFocus
              onChange={(e) => {
                this.setState({ validityMonths: e })
              }}
              isValid={this.state.validate.validity_months}
              errorMessage='A Vigência deve ser um número maior que 0'
            />
          )}
        </div>

        <Space isSmall />

        <OwnershipDate
          defaultValue={this.props.defaultValue.ownershipDate}
          disabledAutoFocus
          onChange={(e) => {
            this.setState({ date: e })
          }}
        />

        <Space isSmall />

        <Settings
          defaultValue={this.props.defaultValue.keysLocation}
          disabledAutoFocus
          onChange={(e) => {
            this.setState({ keysLocation: e })
          }}
        />

        <Space isSmall />

        <div className='text-right'>
          <Button
            isLoading={this.state.isWorking}
            hasThemeColor
            onClick={() => this.submitOptions()}
          >
            Salvar
          </Button>
        </div>
      </Fragment>
    )
  }
}

export default Options
