import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

// compose
import thunk from 'redux-thunk';
import reducers from './reducers';

const middleware = applyMiddleware(thunk);

const { NODE_ENV } = process.env;
const inDev = NODE_ENV === 'DEVELOPMENT';
const finalMiddleware = inDev ? composeWithDevTools(middleware) : middleware;

export default createStore(reducers, finalMiddleware);
