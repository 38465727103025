import React from 'react';
import ReactTimeout from 'react-timeout';
import { debounce } from 'throttle-debounce';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Form from 'components/lib/Form';
import Grid from 'components/lib/Grid';
import Space from 'components/lib/Space';
import { FormFieldText } from 'components/lib/FormFields';

export default function withFilter(Component) {

  class WithFilterWrapper extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        value: ''
      };
    }
    render() {
      let pageWidth = document.body.offsetWidth;
      if(pageWidth < 768) {
        return (
          <React.Fragment>
            <Form
              getRef={(ref) => this.form = ref}
              onSubmit={this.submitForm.bind(this)}>
              <div className="container-mobile">
                <div className="search-label-mobile">
                  <FormFieldText
                    style={{width: '100%'}}
                    placeholder="Pesquisar"
                    value={this.state.value}
                    onChange={debounce(200, this.handleFieldChanges.bind(this))}
                    noValidate
                  />
                </div>
                <div className="search-button-mobile">
                  <Button hasThemeColor submit >
                    <Icon name="fas fa-search" />
                  </Button>
                </div>
              </div>
            </Form>
            <Space isSmall />
            <Component
              {...this.props}
              createFilterRequest={this.requestSearch.bind(this)}
            />
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Form
              getRef={(ref) => this.form = ref}
              onSubmit={this.submitForm.bind(this)}
              autoFocus>
              <Grid>
                <Grid.Col>
                  <FormFieldText
                    placeholder="Pesquisar"
                    value={this.state.value}
                    onChange={debounce(200, this.handleFieldChanges.bind(this))}
                    noValidate
                  />
                </Grid.Col>
                <Grid.Col isNarrow>
                  <Button hasThemeColor submit>
                    <Icon name="fas fa-search" />
                  </Button>
                </Grid.Col>
              </Grid>
            </Form>
            <Space isSmall />
            <Component
              {...this.props}
              createFilterRequest={this.requestSearch.bind(this)}
            />
          </React.Fragment>
        );
      }
    }

    requestSearch(callback) {
      this.request = callback;
    }

    handleFieldChanges(value, field) {
      if (!field.wasFocused) return;
      this.request(value);
      this.setState({ value });
    }

    submitForm() {
      this.request(this.state.value);
    }

  };

  WithFilterWrapper.displayName = `WithFilterWrapper(${getDisplayName(Component)})`;

  return ReactTimeout(WithFilterWrapper);

};

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
};
