import React from 'react'

const Loading = () => (
  <div style={{ height: '250px' }}>
    <div
      style={{ position: 'absolute', margin: '0 auto', padding: '45px 60px' }}
      className='empty-state-spinner'
    />
  </div>
)

export default Loading
