import React from 'react';
import PropTypes from 'prop-types';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Control = (props) => {
  const controlCSSClass = `control ${
    parsePropsAsBulmaClasses(props)
  }`;
  return (
    <span className={controlCSSClass} style={props.style}>
      {props.children}
    </span>
  );
};

Control.propTypes = {
  children: PropTypes.any.isRequired
};

export default Control;
