import React from 'react'
import ReactTimeout from 'react-timeout'
// import PropTypes from 'prop-types';

import AsyncSelect from 'react-select/async'
import { components } from 'react-select'

// import Input, { propsObject as defaultProps } from 'components/lib/FormFields/components/Input';
import Field from 'components/lib/FormFields/components/Field'
// import Form from 'components/lib/Form';
// import Button from 'components/lib/Button';
import Control from 'components/lib/FormFields/components/Control'
import Icon from 'components/lib/Icon'
import Text from 'components/lib/Text'

import { request } from 'core/constants'

class UserSelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      options: this.props.defaultValue || [],
      isWorking: false,
      noOptions: false,
    }
    this.asyncRef = React.createRef()
  }

  componentDidMount() {
    const { defaultValue = {} } = this.props

    if ((defaultValue || []).length > 0) {
      const defaultOptions = this.props.defaultValue || []

      this.props.handleFieldChanges(
        'user',
        [...defaultOptions].map((item) => ({
          id: item.id,
        })),
        {
          isValid: defaultOptions.length > 0,
          value: [...defaultOptions].map((item) => ({
            id: item.id,
          })),
        },
      )
    }
  }

  render() {
    const promiseOptions = (inputValue) =>
      new Promise((resolve, reject) => {
        request
          .get('{company}/user?search=' + inputValue)
          .then((response) => {
            this.setState({ noOptions: false })
            resolve(response.data)
          })
          .catch(() => reject())
      })

    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <Icon name='fas fa-user' />
      </components.DropdownIndicator>
    )

    const Option = (props) => {
      const data = props.data || {}

      return (
        <components.Option {...props}>
          <Text>
            <strong>{data.name || 'Sem nome'}</strong> -{' '}
            {(
              (data.profiles && data.profiles.concat().map((item) => item.name)) ||
              []
            ).join(', ')}
          </Text>
          <Text isSmall>{data.email}</Text>
        </components.Option>
      )
    }

    const MultiValueLabel = (props) => {
      const data = props.data || {}

      return (
        <components.MultiValueLabel {...props}>
          <Text>
            <strong>{data.name || data.email}</strong>
          </Text>
        </components.MultiValueLabel>
      )
    }

    const MultiValueRemove = (props) => (
      <div>
        <components.MultiValueRemove {...props}>
          <Icon name='far fa-times' />
        </components.MultiValueRemove>
      </div>
    )

    const LoadingIndicator = (props) => null

    return (
      <React.Fragment>
        <Field
          label={
            this.props.label || 'Envie o link da proposta para um ou mais usuários'
          }
        >
          <Control>
            <div className='react-select-wrapper'>
              <AsyncSelect
                defaultOptions
                ref={(ref) => (this.asyncRef = ref)}
                classNamePrefix='react-select'
                defaultValue={[]}
                name='user'
                isMulti={this.props.isMult == false ? false : true}
                isDisabled={this.state.isWorking}
                isLoading={this.state.isWorking}
                value={this.state.options}
                loadingMessage={() => 'Pesquisando por usuários...'}
                noOptionsMessage={() => <span>Nenhum cliente encontrado</span>}
                placeholder=''
                menuPosition='fixed'
                maxMenuHeight={162}
                minMenuHeight={100}
                onInputChange={this.handleInputChange.bind(this)}
                inputValue={this.state.inputValue}
                onChange={(options) => {
                  this.setState({
                    options,
                    inputValue: '',
                  })
                  if (this.props.onSelect) this.props.onSelect(options)
                  else {
                    this.props.handleFieldChanges(
                      'user_ids',
                      [...options].map((item) => item.id),
                      {
                        isValid: options.length > 0,
                        value: [...options].map((item) => item.id),
                      },
                    )
                  }
                }}
                isClearable={false}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{
                  MultiValueLabel,
                  MultiValueRemove,
                  DropdownIndicator,
                  LoadingIndicator,
                  Option,
                }}
                loadOptions={promiseOptions}
              />
            </div>
          </Control>
        </Field>
      </React.Fragment>
    )
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue })

    return inputValue
  }
}

UserSelection.propTypes = {}

export default ReactTimeout(UserSelection)
