import JsonParse from 'services/JsonParse';

const initialState = {
  company: null,
  isSuccess: null,
  isUnauthorized: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'PUBLIC_FETCH_COMPANY':
      let object = action.payload;
      object.meta = JsonParse.create(action.payload.meta);

      state = {
        ...state,
        ...{
          company: object,
        },
      };
      break;

    default:
      break;
  }

  return state;
};
