import React from "react";
import Text from "components/lib/Text";
import { currency, date } from "./utils";

const PersonalData = props => {
  const income = currency(props["income"]);
  const estimatedRentValue = currency(props["estimated_rent_value"]);

  const birthDate = date(props["birth_date"]);

  const { brief, identity_issuing_body, documentView } = props;

  if (!documentView) {
    return (
      <React.Fragment>
        {props.is_student && !brief && (
          <div style={{ marginBottom: 5 }}>
            <span className="tag is-warning is-small">Estudante</span>
          </div>
        )}
        <Text>
          Email: {props.email} / Telefone: {props.phone}
        </Text>
        <Text>
          Documento: {props.identity_type} / Número: {props.identity}{" "}
          {identity_issuing_body
            ? ` / Órgão Emissor / UF: ${identity_issuing_body}`
            : null}
        </Text>
        <Text>CPF: {props["cpf"]}</Text>
        {!brief && <Text>Data de nascimento: {birthDate}</Text>}
        <Text>Fonte de Renda: {props.income_source?.name}</Text>
        {!brief && income && <Text>Renda: R$ {income}</Text>}
        {!brief && estimatedRentValue && (
          <Text>Valor estimado do aluguel: R$ {estimatedRentValue}</Text>
        )}
        {!brief && (
          <Text>Estado civil: {(props["marital_status"] || {}).name}</Text>
        )}
        {!brief && <Text>Ocupação: {props["occupation"]}</Text>}
        {!brief && props["nationality"] && (
          <Text>Nacionalidade: {props["nationality"]}</Text>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <div className="documentView-container-info">
        <strong className="documentView-title">{props.name}</strong>
        {props.is_student && !brief && (
          <div style={{ marginBottom: 5 }}>
            <span className="tag is-warning is-small">Estudante</span>
          </div>
        )}
        <Text style={{ fontSize: 14, color: "#5F6776" }}>
          Email: {props.email}
        </Text>
        <Text style={{ fontSize: 14, color: "#5F6776" }}>
          Telefone: {props.phone}
        </Text>
        <Text style={{ fontSize: 14, color: "#5F6776" }}>
          CPF: {props["cpf"]}
        </Text>
        <Text style={{ fontSize: 14, color: "#5F6776" }}>
          Documento: {props.identity_type} / Número: {props.identity}{" "}
          {identity_issuing_body
            ? ` / Órgão Emissor / UF: ${identity_issuing_body}`
            : null}
        </Text>
        {!brief && (
          <Text style={{ fontSize: 14, color: "#5F6776" }}>
            Data de nascimento: {birthDate}
          </Text>
        )}
        {!brief && props["nationality"] && (
          <Text style={{ fontSize: 14, color: "#5F6776" }}>
            Nacionalidade: {props["nationality"]}
          </Text>
        )}
        {!brief && (
          <Text style={{ fontSize: 14, color: "#5F6776" }}>
            Estado civil: {(props["marital_status"] || {}).name}
          </Text>
        )}
        {!brief && (
          <Text style={{ fontSize: 14, color: "#5F6776" }}>
            Ocupação: {props["occupation"]}
          </Text>
        )}
        {!brief && income && (
          <Text style={{ fontSize: 14, color: "#5F6776" }}>
            Renda: R$ {income}
          </Text>
        )}
      </div>
    );
  }
};

export default PersonalData;
