import React from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import Field from 'components/lib/FormFields/components/Field'

import fieldCreator from './fieldCreator'

class FormFieldDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.valueDefault ? new Date(moment().format('YYYY-MM-DD') + ', ' + props.valueDefault+ ':00') : null,
    }
    this.inputContainer = React.createRef()
  }

  render() {
    return (
      <Field {...this.props} hasAddons style={{ width: 105 }}>
        <DatePicker
          className='input-time'
          disabled={this.props.isDisabled}
          selected={this.state.selected}
          onChange={this.handleDateChanges.bind(this)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption='Hora'
          dateFormat='HH:mm'
          timeFormat='HH:mm'
          placeholderText='Hora'
        />

        {this.props.forceInvalid && this.props.errorMessage ? (
          <p className='help is-danger'>{this.props.errorMessage}</p>
        ) : null}
      </Field>
    )
  }

  handleDateChanges(date) {
    this.setState({
      selected: date,
    })
    this.props.onChange({
      target: {
        value: moment(date).format('HH:mm'),
      },
    })
  }
}

export default fieldCreator(FormFieldDate, {
  masks: ['time'],
  validators: [{ minLength: 1 }],
})
