import { UserMeta, CompanyMeta } from 'core/constants'
import Constants from 'services/Constants'
import { request } from 'core/constants'
import { buildQuery } from 'utils/utils'

const initialState = {
  isSessionActive: null,
  activeUser: null,
  hasCompanies: null,
  companies: [],
  activeCompany: null,

  userMeta: {},
  companyMeta: {},

  helpers: {},
  notification: {
    isOpen: false,
    message: '',
    status: null,
  },
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'APP_SET_USER':
      let companies = []
      if (action.payload.companies.length > 0) {
        companies = action.payload.companies
      }
      state = {
        ...state,
        ...{
          isSessionActive: true,
          activeUser: action.payload,
          companies: companies,
          hasCompanies: action.payload.companies.length > 0,
          appUser: action.payload,
          userMeta: UserMeta.create(action.payload.meta || {}),
        },
      }
      break

    case 'UPDATE_USER':
      return {
        ...state,
        activeUser: { ...state.activeUser, ...action.payload },
      }

    case 'APP_SET_USER_ERROR':
      state = {
        ...state,
        ...{
          isSessionActive: false,
          activeUser: null,
        },
      }
      break

    case 'APP_SET_HELPER':
      let helpers = state.helpers
      helpers[action.payload.name] = action.payload.helper
      state = {
        ...state,
        ...{ helpers: helpers },
      }
      break

    case 'APP_SET_COMPANY_BY_ID':
      let company = action.payload

      Constants.set('companyId', company.id)
      Constants.set('profiles', company.profiles)

      request.interceptors.request.use(
        (config) => {
          if (!config.companyId) config.companyId = company.id
          if (config.url.match('{company}')) {
            config.url = config.url.replace(
              '{company}',
              `company/${config.companyId}`,
            )
          }
          if (config.filter && config.url) {
            const url = config.url.split('?')
            config.url = url[0] + buildQuery(config.filter)
          }
          return config
        },
        (error) => error,
      )

      state = {
        ...state,
        ...{
          profiles: company.profiles,
          activeCompany: company,
          companyMeta: CompanyMeta.create(company.meta || {}),
        },
      }
      break

    case 'APP_UPDATE_ACTIVE_COMPANY':
      state = {
        ...state,
        ...{
          activeCompany: action.payload,
          companyMeta: CompanyMeta.create(action.payload.meta || {}),
        },
      }
      break

    case 'APP_DESTROY_SESSION':
      state = {
        ...state,
        ...initialState,
        ...{
          isSessionActive: false,
        },
      }
      break

    case 'APP_TOGGLE_NOTIFICATION':
      const { isOpen, message, status } = action.payload
      state = {
        ...state,
        ...{
          notification: {
            isOpen: isOpen,
            message: isOpen ? message : '',
            status: status,
          },
        },
      }
      break

    default:
      break
  }

  return state
}
