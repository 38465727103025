import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { DOCUMENT_LIST_ID } from '../../../../izee-app/src/constants/documentList';

import FormGrid from '../../components/lib/FormGrid';
import { FormFieldText } from 'components/lib/FormFields';
import { fieldProps } from '../components/formCreator';

import DocumentsRequest from 'components/DocumentsRequest/DocumentsRequest';
import Space from 'components/lib/Space';
import SectionIdentifier from 'components/lib/SectionIdentifier';

const RequestRegistration = (props) => {
  const defaultDocumentList =
    props.documentLists.find((documentList) => {
      if (props.sendClient) {
        return documentList.id === DOCUMENT_LIST_ID.SOLICITATION_CLIENT;
      } else {
        return documentList.id === DOCUMENT_LIST_ID.SOLICITATION_OWNER;
      }
    })?.list || [];

  useEffect(() => {
    const mappedDocs = defaultDocumentList.map((doc) => ({ name: doc }));

    if (props.sendClient) {
      props.sendClient('clientDocs', mappedDocs);
    } else {
      props.sendPropertyOwner('propertyOwnerDocs', mappedDocs);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="container-register-form">
        <FormGrid>
          <FormGrid column>
            <FormFieldText
              {...fieldProps(props, 'name')}
              label="Nome"
              onChange={(e) =>
                props.sendClient
                  ? props.sendClient('clientName', e)
                  : props.sendPropertyOwner('propertyOwnerName', e)
              }
            />
          </FormGrid>
          <Space />
          <FormGrid column>
            <FormFieldText
              {...fieldProps(props, 'email')}
              label="Email"
              onChange={(e) =>
                props.sendClient
                  ? props.sendClient('clientEmail', e)
                  : props.sendPropertyOwner('propertyOwnerEmail', e)
              }
            />
          </FormGrid>
        </FormGrid>
      </div>
      <Space isLarge />
      <div className="container-register-form">
        <SectionIdentifier title="Documentos" />
        <FormGrid>
          <DocumentsRequest
            title={`Solicitar documentos`}
            defaultMultiOption={defaultDocumentList}
            onChange={(e) =>
              props.sendClient
                ? props.sendClient('clientDocs', e)
                : props.sendPropertyOwner('propertyOwnerDocs', e)
            }
          />
        </FormGrid>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    documentLists: state.app.companyMeta?.documentLists || [],
  }),
  {}
)(RequestRegistration);
