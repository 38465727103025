// Components
import Auth from './';
import Recovery from './containers/Recovery';
import Invite from './containers/Invite';
import NotFound from './containers/NotFound';

import { RouteWrapper } from 'utils/RouterUtils';

const routes = [
  {
    name: 'Authentication',
    path: '/auth',
    component: RouteWrapper,
    routes: [
      {
        name: 'Login',
        path: '/auth',
        exact: true,
        component: Auth,
      },
      {
        name: 'Password Recovery',
        path: '/auth/recovery',
        exact: true,
        component: Recovery,
      },
      {
        name: 'Invite',
        path: '/auth/invite',
        exact: true,
        component: Invite,
      },
      {
        name: 'Auth 404',
        path: '/auth/**',
        component: NotFound,
      },
    ],
  },
];

export default routes;
