import React from 'react'

import { connect } from 'react-redux'

import { request } from 'core/constants'

import * as commons from 'utils/UtilityStore/actions'
import { copyToClipboard } from 'utils/utils'

import CreateOrUpdateGuarantor from 'features/CreateOrUpdateGuarantor'

import Button from 'components/lib/Button'

import ConfirmExcludeModal from 'components/ConfirmExcludeModal'

import * as actions from './actions'

import List from './components/List'

class Guarantors extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastOrder: '',
      orderDirection: '',
      isCreatingGuarantor: false,
      showExcludeModal: false,
      excludeRowId: null,
      filter: {},
      companyInfo: {
        companyName: '',
        themeColor: '',
      },
    }
  }

  componentDidMount() {
    this.props.fetch(this.state.filter)

    const companyInfo = {
      companyName: this.props.company.name,
      themeColor: this.props.companyMeta.themeColor,
    }

    this.setState({ companyInfo })
  }

  render() {
    const { isCreatingGuarantor, companyInfo } = this.state

    return (
      <>
        {!isCreatingGuarantor ? (
          <List
            listName='Fiadores'
            items={this.props.items}
            pagination={this.props.listInfo}
            isWorking={this.props.isWorking}
            handleEditRow={this.openRow.bind(this)}
            handleExcludeRow={this.openExcludeModal.bind(this)}
            onChangePage={this.handlePagination.bind(this)}
            onSearch={this.searchList.bind(this)}
            orderBy={this.orderBy.bind(this)}
            lastOrder={this.state.lastOrder}
            orderDirection={this.state.orderDirection}
            isMedium={true}
            actionButton={
              <div className='buttons'>
                <Button onClick={this.createGuarantor} isFlat hasThemeColor>
                  Adicionar fiador
                </Button>
              </div>
            }
          />
        ) : (
          <CreateOrUpdateGuarantor
            {...this.props}
            companyInfo={companyInfo}
            companyId={this.props.company.id}
            isUpdate={false}
            push={this.props.history.push}
            activeUser={this.props.activeUser.id}
            activeCompany={this.props.activeCompany.id}
            closeCreateGuarantor={this.closeCreateGuarantor}
          />
        )}

        {this.state.showExcludeModal && (
          <ConfirmExcludeModal
            title='Excluir Fiador?'
            text='Confirmar exclusão deste fiador?'
            onClose={() =>
              this.setState({
                showExcludeModal: false,
                excludeRowId: null,
              })
            }
            onExclude={this.excludeRow.bind(this, this.state.excludeRowId)}
          />
        )}
      </>
    )
  }

  createGuarantor = () => {
    this.setState({
      isCreatingGuarantor: true,
    })
  }

  closeCreateGuarantor = () => {
    this.setState(
      {
        isCreatingGuarantor: false,
      },
      () => {
        this.props.fetch(this.state.filter)
      },
    )
  }

  searchList(keywords) {
    const filter = { ...this.state.filter, ...{ search: keywords } }

    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  orderBy(order) {
    const filter = { ...this.state.filter, page: 1 }

    let orderDirection = ''

    if (this.state.lastOrder == order) {
      if (this.state.orderDirection == 'desc') {
        this.setState({ orderDirection: 'asc' })
        orderDirection = 'asc'
      } else if (this.state.orderDirection == 'asc') {
        this.setState({ orderDirection: 'desc' })
        orderDirection = 'desc'
      }
    } else {
      this.setState({ orderDirection: 'asc' })
      orderDirection = 'asc'
    }

    this.props.fetch(filter, order, orderDirection)
    this.setState({ lastOrder: order })
  }

  handlePagination(page) {
    const filter = { ...this.state.filter, ...{ page: page } }

    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  handleCreateSuccess(response) {
    if (response.isSuccess) {
      this.props.notification({
        message: 'Cliente cadastrado com sucesso',
      })
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar cadastrar o cliente',
        status: 'danger',
      })
    }
    this.props.fetch()
  }

  handlePublicAppUrl(url) {
    copyToClipboard(url)
    this.props.notification({
      message: 'Url copiada para área de transferência',
    })
  }

  openRow(row) {
    this.props.history.push(this.props.location.pathname + '/' + row.id)
  }

  openExcludeModal(row) {
    this.setState({
      showExcludeModal: true,
      excludeRowId: row.id,
    })
  }

  async excludeRow(rowId) {
    try {
      const deleteUrl = `/company/${this.props.activeCompany.id}/guarantor/${rowId}`

      await request.delete(deleteUrl)
      this.props.notification({
        message: 'Registro excluído com sucesso',
        status: 'success',
      })
      this.props.fetch(this.state.filter)
    } catch (erro) {
      this.props.notification({
        message: erro.message || 'Erro ao tentar excluir o Fiador',
        status: 'danger',
      })
    }
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    items: store.guarantors.items,
    listInfo: store.guarantors.listInfo,
    isWorking: store.commons.isWorking.guarantors,
  }),
  { ...actions, ...commons },
)(Guarantors)
