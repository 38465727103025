import React from 'react';
import { FormFieldText, FormFieldEmail, FormFieldSelect } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Text from 'components/lib/Text';
import moment from 'moment';

const AddButton = ({ hidden = false, disabled, onAddNewSigner, index, lasPos }) => {
  if (index === lasPos) {
    return (
      <span style={{ display: hidden ? 'none' : 'inherited' }}>
        <Button style={{ marginLeft: 10 }} hasThemeColor isDisabled={disabled} onClick={() => onAddNewSigner()}>
          <Icon name="fa fa-plus" />
        </Button>
      </span>
    );
  }

  return null;
};

const SignersList = ({ signers = [], disabled = false, errors = [], ...props }) => {
  if (signers.length === 0) {
    return null;
  }
  const lasPos = signers.length - 1;

  const indexErrors = errors
    .map((error) => {
      const fieldIndex = error.field.replace('signers.', '');
      const message = error.message.replace(error.field, '');

      return {
        ...error,
        message,
        fieldIndex,
      };
    })
    .filter((item) => !!item);

  const getErrorByFieldIndex = (field, index) => {
    const error = indexErrors.find((x) => x.fieldIndex === `${index}.${field}`);
    return !error
      ? {}
      : {
          forceInvalid: true,
          errorMessage: error.message,
        };
  };
  return (
    <>
      {signers.map((signer, index) => (
        <React.Fragment key={index}>
          <Grid>
            <Grid.Col>
              <FormFieldText disabled={disabled} label="Nome" valueDefault={signer.name} onChange={(name) => props.updateSigners('name', name, index)} {...getErrorByFieldIndex('name', index)} />
            </Grid.Col>
            <Grid.Col>
              <FormFieldEmail
                disabled={disabled}
                label="Email"
                valueDefault={signer.email}
                onChange={(email) => props.updateSigners('email', email, index)}
                {...getErrorByFieldIndex('email', index)}
              />
            </Grid.Col>
            <Grid.Col>
              <FormFieldText
                disabled={disabled}
                label="CPF"
                mask={['cpf']}
                valueDefault={signer.cpf}
                onChange={(cpf) => props.updateSigners('cpf', cpf, index)}
                {...getErrorByFieldIndex('cpf', index)}
              />
            </Grid.Col>
            <Grid.Col>
              <FormFieldSelect
                label="Assinar / Testemunhar"
                disabled={disabled}
                valueKey="id"
                labelKey="name"
                hideBlankOption
                valueDefault={signer.is_witness}
                onChange={(is_witness) => props.updateSigners('is_witness', is_witness == 1 ? 1 : 0, index)}
                options={[
                  {
                    id: 0,
                    name: 'Assinar',
                  },
                  {
                    id: 1,
                    name: 'Testemunhar',
                  },
                ]}
              />
            </Grid.Col>
            <Grid.Col isOneFifth>
              <div
                className="remove_signer"
                style={{
                  display: 'flex',
                  paddingTop: 28,
                  justifyContent: 'flex-start',
                }}>

                <Button isBare style={{ marginRight: 10 }} disabled={disabled} onClick={() => props.removeSigner(index)}>
                  <Icon name="fa fa-times" />
                </Button>

                <AddButton disabled={disabled} lasPos={lasPos} index={index} onAddNewSigner={props.addNewSigner} />
              </div>
            </Grid.Col>
          </Grid>
          <Grid>
            <Text isSmall style={{ marginTop: 0, marginBottom: 20 }}>
              {signer.visualized_at ? (
                <React.Fragment>
                  <Icon name="fas fa-xs fa-eye" style={{ marginLeft: 5 }} />
                  {moment(signer.visualized_at).format('DD/MM/YYYY HH:mm')}
                </React.Fragment>
              ) : null}

              {signer.signed_at ? (
                <React.Fragment>
                  <Icon name="fas fa-xs fa-file-signature" style={{ marginLeft: 5 }} />
                  {moment(signer.signed_at).format('DD/MM/YYYY HH:mm')}
                </React.Fragment>
              ) : null}

              {signer.rejected_at ? (
                <React.Fragment>
                  <Icon name="fas fa-xs fa-file-times" style={{ marginLeft: 5 }} />
                  {moment(signer.rejected_at).format('DD/MM/YYYY HH:mm')}
                  &nbsp;
                  {signer.rejection_motive && `(Motivo: ${signer.rejection_motive})`}
                </React.Fragment>
              ) : null}
            </Text>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

export default SignersList;
