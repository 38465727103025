import React from 'react';

// Components
import Space from 'components/lib/Space';
import Button from 'components/lib/Button';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';
import { FormFieldText } from 'components/lib/FormFields';
import Assistant from 'components/Assistant';

export class CheckUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { formIsWorking, handleFormField } = this.props;

    return (
      <React.Fragment>
        <Assistant words={'Olá! Digite seu usuário ou email para continuar.'} style={{ marginBottom: 30 }} />

        <FormFieldText autoComplete="on" name="username" label="Usuário/Email" isDisabled={formIsWorking} onChange={handleFormField.bind(this, 'username')} />

        <Space isSmall />

        <Field>
          <Control>
            <Button isFullWidth isLarge isLoading={formIsWorking} isDisabled={formIsWorking} submit>
              Prosseguir
            </Button>

            <Space isSmall />

            <Button isFullWidth isLarge isLoading={formIsWorking} isDisabled={formIsWorking} onClick={this.props.redirectToCreate.bind(this)}>
              Ainda não é cliente?
            </Button>
          </Control>
        </Field>
      </React.Fragment>
    );
  }
}

export default CheckUser;
