import React from 'react';

const GrupoRVLogo = () => (
  <svg width="115" height="25" viewBox="0 0 115 25" fill="none">
    <path
      d="M86.7266 4.73981C86.7266 1.6936 88.2707 0.1705 91.3589 0.1705H94.447V4.73981H92.4568C91.679 4.85318 91.2906 5.17071 91.2906 5.69188V18.073H86.7266V4.73981Z"
      fill="white"
    />
    <path
      d="M95.8047 0.169554H100.419L104.879 12.2589L109.375 0.169554H114.026L107.952 16.3668C107.448 17.6858 106.43 18.345 104.898 18.345C103.388 18.345 102.381 17.6858 101.878 16.3668L95.8047 0.169554Z"
      fill="white"
    />
    <path
      d="M5.73027 17.0162H13.8286V5.4313C13.8286 3.88248 13.4796 2.77191 12.7821 2.09958C12.0841 1.42778 10.9577 1.09188 9.40209 1.09188H5.25003C3.78571 1.09188 2.72185 1.39944 2.05845 2.01455C1.39504 2.62915 1.06334 3.65417 1.06334 5.08963V12.9156C1.06334 14.5106 1.45225 15.6039 2.23007 16.1964C2.91657 16.7428 4.0833 17.0162 5.73027 17.0162ZM0 24.0408V22.9837H9.66609C12.4409 22.9837 13.8286 21.6879 13.8286 19.0962V18.0733H5.81372C4.75825 18.0733 4.02504 18.0392 3.61199 17.9709C2.85464 17.857 2.18966 17.5726 1.61653 17.117C0.538492 16.2741 0 14.8848 0 12.9487V5.19302C0 3.59906 0.325929 2.39822 0.977788 1.58943C1.62965 0.78064 2.73287 0.27364 4.28904 0.0684242C4.63229 0.0232887 4.91781 0.00019455 5.14716 0.00019455H8.99009C9.379 0.00019455 9.76791 0.0232887 10.1568 0.0684242C11.8725 0.27364 13.1023 0.76857 13.846 1.55426C14.5891 2.33943 14.9607 3.5744 14.9607 5.25915V18.9524C14.9607 20.9788 14.7088 22.2652 14.206 22.8116C13.4508 23.6309 11.6668 24.0408 8.8531 24.0408H0Z"
      fill="white"
    />
    <path
      d="M19.7656 18.073V5.12452C19.7656 2.0274 21.2147 0.330574 24.1135 0.0340366C24.1822 0.0340366 24.2971 0.0230141 24.4583 -8.01086e-05H26.0108V1.09108H24.6131C24.4084 1.09108 24.2494 1.10315 24.136 1.12519C21.9773 1.33041 20.8977 2.66299 20.8977 5.12294V18.073H19.7656Z"
      fill="white"
    />
    <path
      d="M28.1719 13.2218V-7.43866e-05H29.2357V13.2234C29.2357 15.7521 30.741 17.016 33.7531 17.016H37.6889C40.6548 17.016 42.138 15.7862 42.138 13.3258V-7.43866e-05H43.2701V13.2218C43.2701 15.2262 42.4923 16.6612 40.9372 17.5266C40.2733 17.8909 39.1984 18.073 37.7115 18.073H33.7652C32.2552 18.073 31.1687 17.8909 30.5053 17.5266C28.9492 16.6612 28.1719 15.2262 28.1719 13.2218Z"
      fill="white"
    />
    <path
      d="M58.117 17.0162C60.6331 17.0162 61.8917 15.6836 61.8917 13.018V4.85082C61.8917 2.34468 60.4273 1.09188 57.4992 1.09188H53.4159C50.556 1.09188 49.1264 2.3788 49.1264 4.95369V17.0162H58.117ZM48.0625 24.0408V4.84925C48.0625 1.61725 49.8008 0.00019455 53.2784 0.00019455H57.4651C61.1711 0.00019455 63.0238 1.60622 63.0238 4.81776V12.9487C63.0238 14.2702 62.7949 15.2837 62.3378 15.9896C61.4225 17.3789 60.0153 18.0733 58.117 18.0733H49.1264V24.0408H48.0625Z"
      fill="white"
    />
    <path
      d="M71.4819 16.9136C71.801 16.9818 72.3715 17.016 73.1929 17.016H77.4363C78.3941 17.016 79.1016 16.9136 79.5583 16.7084C80.8132 16.1389 81.4404 15.0457 81.4404 13.4281V4.98701C81.4404 2.39007 80.1051 1.09108 77.4363 1.09108H73.1929C72.0981 1.09108 71.3334 1.19395 70.9004 1.39864C69.5773 2.01376 68.9154 3.2104 68.9154 4.98701V13.3252C68.9154 15.3758 69.7704 16.5719 71.4819 16.9136ZM67.8516 13.0166V5.32973C67.8516 4.28214 67.9429 3.48438 68.1261 2.93801C68.3775 2.20952 68.8467 1.57184 69.5332 1.02547C70.3792 0.342121 71.5921 -8.01086e-05 73.1704 -8.01086e-05H77.5281C80.8914 -8.01086e-05 82.5724 1.77705 82.5724 5.32973V13.0166C82.5724 16.3877 80.895 18.073 77.5402 18.073H73.1924C71.9595 18.073 71.0699 17.9476 70.5215 17.6972C68.7417 16.9005 67.8516 15.5448 67.8516 13.6312V13.0166Z"
      fill="white"
    />
  </svg>
);

export default GrupoRVLogo;
