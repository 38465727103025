import React from 'react';

import Input, { propsObject as defaultProps } from 'components/lib/FormFields/components/Input';
import Field from 'components/lib/FormFields/components/Field';
import Form from 'components/lib/Form';
import Button from 'components/lib/Button';
import Control from 'components/lib/FormFields/components/Control';
import Icon from 'components/lib/Icon';

import fieldCreator from './fieldCreator';

const FormFieldSearch = (props) => {

  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Form id="search" onSubmit={props.onSubmit} autoFocus>
      <Field {...props} hasAddons>
        <Control {...props}>
          <Input
            {...defaultProps(props)}
            isDanger={isValid || props.forceInvalid}
          />
        </Control>
        <Control {...props} style={null}>
          <Button type="submit" isFeatured><Icon name="fas fa-search" /></Button>
        </Control>
        {props.forceInvalid && props.errorMessage ? <p className="help is-danger">{props.errorMessage}</p> : null}
      </Field>
    </Form>
  );

};

export default fieldCreator(FormFieldSearch, {
  masks: [],
  validators: []
});
