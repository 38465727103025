import React from 'react';

import { fieldProps } from 'features/components/formCreator';

import Grid from 'components/lib/Grid';
import { FormFieldText } from 'components/lib/FormFields';

const OtherPerson = ({ formProps, i }) => (
  <Grid>
    <Grid.Col>
      <FormFieldText
        label="Nome do sócio *"
        {...fieldProps(formProps, `natural_tenant.partners.${i}.name`)}
      />
    </Grid.Col>
    <Grid.Col>
      <FormFieldText
        mask={['cpf']}
        label="CPF do sócios *"
        {...fieldProps(formProps, `natural_tenant.partners.${i}.document`)}
      />
    </Grid.Col>
  </Grid>
);

export default OtherPerson;
