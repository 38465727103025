import React from 'react'

import _propsObject from './propsObject'

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses'

const File = (props) => {
  const inputCSSClass = `file ${parsePropsAsBulmaClasses(props)}`

  if (React.Children.toArray(props.children).length > 0) {
    return (
      <label htmlFor={props.id} className='file-input-wrapper'>
        <input {..._propsObject(props)} />
        <span className='file-input-wrapper-content'>{props.children}</span>
        <span className='file-input-wrapper-label'>{props.label}</span>
      </label>
    )
  }

  return (
    <div className={inputCSSClass}>
      <label htmlFor={props.id} className='file-label'>
        <input className='file-input' {..._propsObject(props)} />
        <span className='file-cta'>
          <span className='file-icon'>
            <i className='fas fa-upload' />
          </span>
          <span
            className='file-label'
            style={{ marginLeft: props.description ? 0 : '-0.5em' }}
          >
            {props.description}
          </span>
        </span>

        {props.placeholder && !props.filename ? (
          <span
            className='file-name'
            title={props.placeholder}
            style={{ color: 'lightgray' }}
          >
            {props.placeholder}
          </span>
        ) : (
          <span className='file-name' title={props.filename}>
            {props.filename}
          </span>
        )}
      </label>
    </div>
  )
}

export const propsObject = _propsObject

export default File
