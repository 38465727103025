import React from "react";

import TextArea, {
  propsObject as defaultProps
} from "components/lib/FormFields/components/TextArea";
import Field from "components/lib/FormFields/components/Field";
import Control from "components/lib/FormFields/components/Control";

import fieldCreator from "./fieldCreator";

const FormFieldTextArea = props => {
  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;
  const { allowMaxLengthField, maxLength, currentFieldLength, value } = props;
  const isFieldReachedMax = (currentFieldLength > maxLength) ? "is-danger" : "";

  return (
    <Field {...props}>
      <Control {...props}>
        <TextArea
          {...defaultProps(props)}
          isDanger={isValid || props.forceInvalid}
        />
      </Control>
      {(props.forceInvalid && props.errorMessage) && (
        <p className="help is-danger">{props.errorMessage}</p>
      )}
      {allowMaxLengthField && <small className={`small-label ${isFieldReachedMax}`}>{currentFieldLength}/{maxLength}</small>}
      {(value.length > maxLength) && <small className="small-label is-danger">{`O campo deve ter até ${maxLength} caracteres.`}</small>}
    </Field>
  );
};

export default fieldCreator(FormFieldTextArea, {
  masks: []
});
