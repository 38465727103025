import React from 'react';
import PropTypes from 'prop-types';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Icon = (props) => {
  const iconCSSClass = `icon ${
    parsePropsAsBulmaClasses(props)
  }`;
  return (
    <span className={iconCSSClass} style={props.style}>
      <i className={props.name} />
    </span>
  );
};

export default Icon;
