import React from 'react'

import listWrapper from 'containers/Records/listWrapper'

import UserName from 'components/TableComponents/UserName'
import withFilter from 'components/HOCs/withFilter'
import EmptyState from 'components/EmptyState'

import Table from 'components/lib/Table'
import Icon from 'components/lib/Icon'

import ListRecordActionCell from '../../../components/ListRecordActionCell'

const List = (props) => {
  props.createFilterRequest(props.onSearch)

  return (
    <Table
      lastOrder={props.lastOrder}
      orderDirection={props.orderDirection}
      onSort={props.orderBy}
      items={props.items}
      isUpdatingRows={props.isWorking}
      onClickRow={props.handleRowClick}
      cells={[
        {
          name: 'Responsável',
          path: 'creator.name',
          render: (_, content) => {
            if (content && content.creator) {
              return content.creator.name || content.creator.email
            }

            return 'Sistema'
          },
        },
        {
          name: 'Nome',
          render: (_, row) => <UserName content={row} />,
        },
        {
          name: 'Email',
          path: 'email',
        },
        {
          name: 'Telefone',
          path: 'phone',
        },
        {
          name: 'Imóveis',
          shrink: true,
          align: 'center',
          render: (_, row) => (
            <span>
              <Icon name='fas fa-home' /> <strong>{row.properties.length}</strong>
            </span>
          ),
        },
      ]}
      actionCell={(row) => (
        <ListRecordActionCell
          handleEditRow={props.handleEditRow.bind(null, row)}
          handleExcludeRow={props.handleExcludeRow.bind(null, row)}
        />
      )}
      emptyState={<EmptyState isLoading={props.isWorking} />}
    />
  )
}

List.defaultProps = {
  items: [],
  isWorking: false,
  handleEditRow: () => {},
  handleExcludeRow: () => {},
}

export default listWrapper(withFilter(List))
