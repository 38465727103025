import React from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

import withRedux from 'components/redux';
import * as actions from './actions';
import * as commons from 'utils/UtilityStore/actions';
import * as mainActions from '../../actions';

// Components
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Space from 'components/lib/Space';
import SectionWrapper from 'components/SectionWrapper';
import Text from 'components/lib/Text';
import Grid from 'components/lib/Grid';
import { FormFieldTextArea } from 'components/lib/FormFields';

import Schedule from './components/Schedule';
import CreateSurveyProcess from '../../../../services/integrations/components/RedeVistorias/CreateSurveyProcess';
import UploadSurvey from './components/UploadSurvey';
import SendSurvey from './components/SendSurvey';

import ToggleRequirement from 'features/ToggleRequirement';

import HeadRight from 'features/AnalysisProposal/components/HeadRight';
import SectionGroup from 'features/AnalysisProposal/components/SectionGroup';

import ProcessTask from 'services/ProcessTask';
import { isObject } from 'lodash';

class Survey extends React.Component {
  componentDidMount() {

    const allButtons = document.querySelectorAll('#form-blocked button');

    for (let i = 0; i < allButtons.length; i++) {
      const currentButton = allButtons[i];

      const clone = currentButton.cloneNode(true);

      const parent = currentButton.parentNode;

      parent.appendChild(clone);

      currentButton.remove();
    }
  }

  render() {
    const {
      current,
      activeCompany: { id: companyId },
      isAgent,
    } = this.props;
    const { proposal } = current;

    const reactToPrint = () => {
      return (
        <ReactToPrint
          trigger={() => (
            <Button
              isBare
              isFullWidth
              isMedium>
              <Icon name="fas fa-print" />
              Imprimir
            </Button>
          )}
          bodyClass="print-padding"
          content={() => this.componentRef}
          copyStyles={true}
        />
      );
    };

    const { requirements, inspection = {} } = current;

    const status = ProcessTask(requirements, 'REQUIREMENT_INSPECTION').status;

    const scheduleStatus = ProcessTask(requirements, 'REQUIREMENT_INSPECTION', 'TASK_SCHEDULE_INSPECTION').status;
    const uploadStatus = ProcessTask(requirements, 'REQUIREMENT_INSPECTION', 'TASK_UPLOAD_INSPECTION').status;

    const ownershipDate = current['ownership_date'];

    if (inspection && !inspection.time && inspection.date) {
      inspection.time = moment(inspection.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
      inspection.date = moment(inspection.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
    }

    const items = [
      {
        contain: (
          <SectionWrapper>
            <Schedule
              defaultValue={inspection}
              processId={current.id}
              isClosed={false}
              maxDate={ownershipDate}
              ownershipDate={ownershipDate}
              onSuccess={this.submitScheduleSuccess.bind(this)}
              disabledAutoFocus
            />
          </SectionWrapper>
        ),
        icon: 'fa-calendar-alt',
        open: isObject(inspection),
        title: 'Agendamento da vistoria',
      },
      {
        contain: (
          <SectionWrapper>
            {!scheduleStatus ? (
              <Text>*Você não possui nenhuma vistoria agendada.</Text>
            ) : (
              <UploadSurvey
                // isClosed={status}
                companyId={companyId}
                defaultValue={inspection}
                processId={current.id}
                onSuccess={this.uploadSurveySuccess.bind(this)}
                disabledAutoFocus
              />
            )}
            {scheduleStatus && uploadStatus && <SendSurvey processId={current.id} onSuccess={this.sendSurveySuccess.bind(this)} disabledAutoFocus />}
          </SectionWrapper>
        ),
        icon: 'fa-clipboard-list',
        open: false,
        title: 'Documento da vistoria',
      },
      {
        contain: (
          <SectionWrapper>
            <CreateSurveyProcess process={this.props.current} company={this.props.activeCompany} onSuccess={this.submitScheduleSuccess.bind(this)} />
          </SectionWrapper>
        ),
        icon: 'fa-eye',
        open: false,
        title: 'Rede Vistorias',
        info: 'Caso já tenha o painel na RV, adicione a URL de call back fornecida pelo painel RV. Caso não, faça seu cadastro inicial e solicite uma vistoria, esse cadastro será apenas na primeira vez, depois os dados serão automaticamente preenchidos.',
      },
    ];

    if (isAgent) {
      return (
        <form id="form-blocked">
          <fieldset disabled="disabled">
            <div ref={(element) => (this.componentRef = element)}>
              <div className="print-wrap">
                <SectionWrapper
                  customHeader={true}
                  header={<p>Vistoria</p>}
                // headerRight={(
                //   <HeadRight
                //     reactToPrint={reactToPrint}
                //   />
                // )}
                >
                  <SectionGroup items={items} />
                  {companyId ? (
                    <SectionWrapper header="Gerenciar etapa">
                      <ToggleRequirement processId={current.id} onSuccess={this.closeRequirementSuccess.bind(this)} requirement="inspection" requirementStatus={status} companyId={companyId} />
                    </SectionWrapper>
                  ) : null}
                </SectionWrapper>
              </div>
            </div>
          </fieldset>
        </form>
      );
    }

    return (
      <div ref={(element) => (this.componentRef = element)}>
        <div className="print-wrap">
          <SectionWrapper customHeader={true} header={<p>Vistoria</p>} headerRight={HeadRight({ reactToPrint })}>
            <SectionGroup items={items} />
            {companyId ? (
              <SectionWrapper header="Gerenciar etapa">
                <ToggleRequirement processId={current.id} onSuccess={this.closeRequirementSuccess.bind(this)} requirement="inspection" requirementStatus={status} companyId={companyId} />
              </SectionWrapper>
            ) : null}
          </SectionWrapper>
        </div>
      </div>
    );
  }

  submitScheduleSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Agendamento da vistoria concluído com sucesso',
      });
      this.props.fetch(this.props.current.id);
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar agendar a vistoria',
        status: 'danger',
      });
    }
  }

  uploadSurveySuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Documento da vistoria enviado com sucesso',
      });
      this.props.fetch(this.props.current.id);
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar enviar o documento da vistoria',
        status: 'danger',
      });
    }
  }

  sendSurveySuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Documento da vistoria enviado ao proprietário',
      });
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar enviar o documento da vistoria para o proprietário',
        status: 'danger',
      });
    }
  }

  closeRequirementSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({ message: 'Etapa alterada com sucesso' });
      this.props.fetch(this.props.current.id);
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar alterar a etapa',
        status: 'danger',
      });
    }
  }
}

export default withRedux(
  ({ processDetails: { current } }) => ({
    current,
  }),
  { ...actions, ...mainActions, ...commons }
)(Survey);
