import React from 'react'

import Grid from 'components/lib/Grid'
import Text from 'components/lib/Text'
import Space from 'components/lib/Space'

import Address from './components/Address'
import PropertyInfo from './components/PropertyInfo'
import PersonalData from './components/PersonalData'
import PersonalDataSpouse from './components/PersonalDataSpouse'
import Evaluation from './components/Evaluation'
import Negotiation from './components/Negotiation'
import UserName from './components/UserName'
import Section from './components/Section'

import { currency, date } from './components/utils'

import withFilePreview from 'components/HOCs/withFilePreview'

class Proposal extends React.Component {
  getExpirationDateTitle(item) {
    if (!item || !item.operation_tokens[0]) return null

    return `Data de Expiração: ${item.operation_tokens[0].expiration_date}`
  }
  renderAddress(item) {
    return (
      <React.Fragment>
        {item.trading_address && (
          <div>
            <Address address={item.trading_address} label='Endereço comercial' />
            <Space isSmall />
          </div>
        )}
        {item.address && (
          <Address address={item.address} label='Endereço residencial' />
        )}
      </React.Fragment>
    )
  }

  renderSpouse(item) {
    return (
      <React.Fragment>
        {item.spouse_name && item.spouse_name !== '' && (
          <div>
            <PersonalDataSpouse {...item} />
          </div>
        )}
      </React.Fragment>
    )
  }
  render() {
    let current = this.props.data

    if (!current) return null
    if (!current.properties) return null

    const requestPath = this.props.requestPath

    const hasClick =
      typeof this.props.onClickResend === 'function' &&
      typeof this.props.onClickCopy === 'function'

    const isRental = current.type === 'TYPE_RENTAL'

    const rentValue = currency(current['rent_value'])
    const saleValue = currency(current['sale_value'])
    const ownershipDate = date(current['ownership_date'])
    const negotiation = current['negotiation']
    const guarantee = current['guarantee']
    const guaranteeName = guarantee
      ? guarantee.name
      : 'A garantia ainda não foi escolhida'
    const property = current.properties[0]

    const owners = current['property_owners'].map((item, index) => {
      const shoResend = hasClick && item.evaluation != 2

      return (
        <React.Fragment key={item.id}>
          <div
            style={{
              borderTop: index > 0 && '1px solid #f2f2f2',
              margin: index > 0 && '2px 0 15px',
            }}
          />
          <Grid>
            <Grid.Col>
              {/* {this.renderCompany(item)} */}
              {/* <Space isSmall /> */}
              <UserName
                {...item}
                onClickResend={
                  shoResend &&
                  this.props.onClickResend.bind(null, 'property_owner_ids', item)
                }
                onClickCopy={shoResend && this.props.onClickCopy.bind(this, item)}
                title={this.getExpirationDateTitle(item)}
              />
              <PersonalData {...item} />
              {this.renderSpouse(item)}
            </Grid.Col>
            <Grid.Col>{this.renderAddress(item)}</Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              <Evaluation status={item.evaluation} comment={item.denial_comment} />
            </Grid.Col>
          </Grid>
        </React.Fragment>
      )
    })

    const clients = current.clients.map((item, index) => (
      <React.Fragment key={item.id}>
        <div
          style={{
            borderTop: index > 0 && '1px solid #f2f2f2',
            margin: index > 0 && '2px 0 15px',
          }}
        />
        <Grid>
          <Grid.Col>
            {/* {this.renderCompany(item)} */}
            {/* <Space isSmall /> */}
            <UserName
              {...item}
              onClickResend={
                hasClick && this.props.onClickResend.bind(null, 'client_ids', item)
              }
              onClickCopy={hasClick && this.props.onClickCopy.bind(this, item)}
              title={this.getExpirationDateTitle(item)}
            />
            <PersonalData {...item} />
            {this.renderSpouse(item)}
          </Grid.Col>
          <Grid.Col>{this.renderAddress(item)}</Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col>
            <Space isSmall />
            {/* <Documents
              requestPath={requestPath}
              list={item.documents}
              onClickFile={this.props.openFile}
            /> */}
            {item.main && (
              <Evaluation status={item.evaluation} comment={item.denial_comment} />
            )}
          </Grid.Col>
        </Grid>
      </React.Fragment>
    ))

    const guarantors = (current.guarantors || []).map((item, index) => {
      const showClick = item.operation_tokens.length > 0 ? true : false

      return (
        <React.Fragment key={item.id}>
          <div
            style={{
              borderTop: index > 0 && '1px solid #f2f2f2',
              margin: index > 0 && '2px 0 15px',
            }}
          />
          <Grid>
            <Grid.Col>
              {/* {this.renderCompany(item)}
              <Space isSmall /> */}
              <UserName
                {...item}
                onClickResend={
                  hasClick &&
                  showClick &&
                  this.props.onClickResend.bind(null, 'client_ids', item)
                }
                onClickCopy={
                  hasClick && showClick && this.props.onClickCopy.bind(this, item)
                }
                title={this.getExpirationDateTitle(item)}
              />
              <PersonalData {...item} />
              {this.renderSpouse(item)}
            </Grid.Col>
            <Grid.Col>{this.renderAddress(item)}</Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              <Space isSmall />
              {/* <Documents
              requestPath={requestPath}
              list={item.documents}
              onClickFile={this.props.openFile}
            /> */}
              <Evaluation status={item.evaluation} comment={item.denial_comment} />
            </Grid.Col>
          </Grid>
        </React.Fragment>
      )
    })

    return (
      <div ref={this.props.ref}>
        <Section
          title={`Imóvel ${property?.type ? `(${property?.type?.name})` : ''}${
            property?.name ? ` // Apelido: ${property?.name}` : ''
          }`}
          iconName='home'
        >
          <Address address={property?.address} />
        </Section>

        <Space isSmall />

        <Section title='Proprietários' iconName='user'>
          {owners}
        </Section>

        <Space isSmall />

        <Section title='Clientes' iconName='user'>
          {clients}
        </Section>

        <Space isSmall />

        {isRental && (
          <>
            <Section
              title={'Garantia ' + (guaranteeName && `(${guaranteeName})`)}
              iconName='shield-check'
            >
              {guarantors.length ? guarantors : null}
            </Section>
            <Space isSmall />
          </>
        )}

        <Section
          title={
            isRental
              ? `Valor do aluguel: R$ ${rentValue}`
              : `Valor de venda: R$ ${saleValue}`
          }
          iconName='file-invoice-dollar'
        >
          <PropertyInfo property={property} goal={current?.goal} />
        </Section>

        <Space isSmall />

        {ownershipDate && (
          <>
            <Section
              title={`Data da posse: ${ownershipDate}`}
              iconName='calendar-alt'
            />
            <Space isSmall />
          </>
        )}

        <Section title='Negociação' iconName='asterisk'>
          <Negotiation>{negotiation}</Negotiation>
        </Section>
      </div>
    )
  }
}

export default withFilePreview(Proposal)
