import React from 'react'
import { request } from 'core/constants'
import { isAfter, parseISO } from 'date-fns'

import withRedux from 'components/redux'
import { ROLE } from 'components/constants'
import * as actions from './actions'
import * as mainActions from '../../actions'
import * as commons from 'utils/UtilityStore/actions'

import SectionWrapper from 'components/SectionWrapper'

import RejectDocument from 'features/RejectDocument'

import Person from './components/Person'
import CreditAnalysis from './components/CreditAnalysis'

import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import Administration from 'components/Proposal/components/Administration'
import Negotiation from 'components/Proposal/components/Negotiation'
import PropertyInfo from 'components/Proposal/components/PropertyInfo'
import HeadWrap from 'features/AnalysisProposal/components/HeadWrap'
import Modal from 'components/lib/Modal'
import EditProposal from 'containers/Proposal/components/New'
import EditResponsible from 'containers/Proposal/components/EditResponsible'

import ProcessTask from 'services/ProcessTask'

import ShareProposal from '../Proposal/components/ShareProposal'
import ArchiveProposal from 'features/ArchiveProposal'
import ToggleRequirement from 'features/ToggleRequirement'

import withModal from 'components/HOCs/withModal'
import withFilePreview from 'components/HOCs/withFilePreview'

import Documents from 'components/Documents'

import ProposalPrint from 'components/Proposal'

import ReactToPrint from 'react-to-print'

import formatAddress from './components/formatAddress'
import Options from './components/Options'

import { copyToClipboard, timeDelay } from 'utils/utils'
import { ga } from '../../../../services/firebase'
import Fichacerta from '../../../../services/integrations/components/Fichacerta'

class ProcessOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refusedFiles: {},
      shareHistory: [],
    }
  }

  render() {
    const { company, current, activeCompany, isAgent, forbiddenAccess } = this.props

    const { proposal = {}, keys_location, guarantee_value } = current
    const { validity_months, payday } = proposal
    const { clients = [], guarantors = [], guarantee = null } = proposal
    const propertyOwners = proposal.property_owners || []

    const { requirements } = current
    const { status } = ProcessTask(requirements, 'REQUIREMENT_OVERVIEW')

    const isRental = current.proposal.type === 'TYPE_RENTAL'

    const forbiddenForJuridicalAndInspector = forbiddenAccess([
      ROLE.PROFILE_INSPECTOR,
      ROLE.PROFILE_JURIDICAL,
    ])

    const reactToPrint = () => {
      if (isAgent) {
        return (
          <Button isBare isFullWidth isMedium disabled>
            <Icon name='fas fa-print' />
            Imprimir
          </Button>
        )
      }

      return (
        <ReactToPrint
          trigger={() => (
            <Button isBare isFullWidth isMedium>
              <Icon name='fas fa-print' />
              Imprimir
            </Button>
          )}
          content={() => this.componentRef}
          copyStyles={true}
          bodyClass='print-padding'
        />
      )
    }

    let isClientGuarantee = guarantee?.code === 'GUARANTEE_GUARANTOR'

    let isGuarantorValid = null
    const guarantorList = guarantors.map((item) => {
      const refusedFiles = this.state.refusedFiles[item.email] || []
      const other = {
        ...item,
        sectionIconName: 'user',
        sectionTitle: 'Fiador',
      }

      isGuarantorValid =
        isGuarantorValid !== 2 && isGuarantorValid !== 0
          ? item.evaluation
          : isGuarantorValid

      const tokens = item.operation_tokens

      return (
        <SectionWrapper className='no-py' key={item.id}>
          <Person
            {...item}
            onClickResend={
              !forbiddenForJuridicalAndInspector &&
              (() =>
                this.resendProposal({
                  proposalId: proposal.id,
                  email: item.email,
                  type: 'guarantor_ids',
                  id: [item.id],
                }))
            }
            isValidLink={!!this.getValidToken(tokens)}
            onClickCopyI={
              !forbiddenForJuridicalAndInspector &&
              (() => this.copyProposalLink(tokens))
            }
            onClickWhatsapp={
              !forbiddenForJuridicalAndInspector &&
              (() => this.sendLinkWhatsapp(tokens, item, 'guarantor'))
            }
            onClick={
              !forbiddenForJuridicalAndInspector && this.editGuarantor.bind(this)
            }
            refusedFiles={refusedFiles}
            fetchFile={this.props.openFile}
            removeFileFromRejection={this.removeFileFromRejection.bind(this)}
            rejectFile={!status && this.rejectFile.bind(this)}
            requestNewDocuments={this.requestNewDocuments.bind(this, 'guarantor')}
          />
          <SectionWrapper className='group-white' header='Documentos'>
            <Documents entityId={item.id} entityName='guarantor' other={other} />
          </SectionWrapper>
        </SectionWrapper>
      )
    })

    let isClientValid = null
    const clientList = clients.map((item) => {
      const refusedFiles = this.state.refusedFiles[item.email] || []
      const other = {
        ...item,
        sectionIconName: 'user',
        sectionTitle: 'Cliente',
      }

      isClientValid =
        isClientValid !== 2 && isClientValid !== 0 ? item.evaluation : isClientValid

      const tokens = item.operation_tokens

      return (
        <SectionWrapper className='no-py' key={item.id}>
          <Person
            {...item}
            onClickResend={
              !forbiddenForJuridicalAndInspector &&
              (() =>
                this.resendProposal({
                  proposalId: proposal.id,
                  email: item.email,
                  type: 'client_ids',
                  id: [item.id],
                }))
            }
            isValidLink={!!this.getValidToken(tokens)}
            onClickCopyI={
              !forbiddenForJuridicalAndInspector &&
              (() => this.copyProposalLink(tokens))
            }
            onClickWhatsapp={
              !forbiddenForJuridicalAndInspector &&
              (() => this.sendLinkWhatsapp(tokens, item, 'client'))
            }
            onClick={
              !forbiddenForJuridicalAndInspector && this.editClient.bind(this)
            }
            refusedFiles={refusedFiles}
            fetchFile={this.props.openFile}
            removeFileFromRejection={this.removeFileFromRejection.bind(this)}
            rejectFile={!status && this.rejectFile.bind(this)}
            requestNewDocuments={this.requestNewDocuments.bind(this, 'client')}
            key={item.id}
          />
          <SectionWrapper className={'group-white'} header='Documentos'>
            <Documents entityId={item.id} entityName='client' other={other} />
          </SectionWrapper>
        </SectionWrapper>
      )
    })

    const optionsInfo = (
      <SectionWrapper className='no-py'>
        <Options
          proposalId={proposal.id}
          processId={current.id}
          dataValue={current.ownership_date}
          isRental={isRental}
          onSuccess={this.changeOptionSuccess.bind(this)}
          allowMaxLengthField={true}
          maxLength={1200}
          defaultValue={{
            keys_location: keys_location,
            guarantee_value: guarantee_value,
            payday: payday || '',
            validityMonths: validity_months || '',
          }}
          canEdit={!forbiddenForJuridicalAndInspector}
          showGuaranteeValue={
            guarantee &&
            (guarantee.code === 'GUARANTEE_CAPITALIZATION' ||
              guarantee.code === 'GUARANTEE_SURETY_BOND' ||
              guarantee.code === 'GUARANTEE_DEPOSIT')
          }
          {...this.props}
        />
      </SectionWrapper>
    )

    let isPropValid = null
    const ownerList = propertyOwners.map((item) => {
      const tokens = item.operation_tokens

      item.type = 'propertyOwners'
      isPropValid =
        isPropValid !== 2 && isPropValid !== 0 ? item.evaluation : isPropValid

      const other = {
        ...item,
        sectionIconName: 'user',
        sectionTitle: 'Proprietário',
      }

      return (
        <SectionWrapper className='no-py' key={item.id}>
          <Person
            {...item}
            onClickResend={
              !forbiddenForJuridicalAndInspector &&
              (() =>
                this.resendProposal({
                  proposalId: proposal.id,
                  email: item.email,
                  type: 'property_owner_ids',
                  id: [item.id],
                }))
            }
            isValidLink={!!this.getValidToken(tokens)}
            onClickCopyI={
              !forbiddenForJuridicalAndInspector &&
              (() => this.copyProposalLink(tokens))
            }
            onClickWhatsapp={
              !forbiddenForJuridicalAndInspector &&
              (() => this.sendLinkWhatsapp(tokens, item, 'owner'))
            }
            onClick={!forbiddenForJuridicalAndInspector && this.editOwner.bind(this)}
            key={item.id}
          />
          <SectionWrapper className='group-white' header='Documentos'>
            <Documents entityId={item.id} entityName='propertyOwner' other={other} />
          </SectionWrapper>
        </SectionWrapper>
      )
    })

    const property = proposal.properties ? proposal.properties[0] : {}
    const propertyInfo = <PropertyInfo property={property} goal={proposal?.goal} />

    const negotiation = proposal.negotiation ? proposal.negotiation : ''
    const negotiationInfo = <Negotiation>{negotiation}</Negotiation>

    const acquisitionFees = proposal.type === 'TYPE_ACQUISITION' && (
      <Administration>
        <p>Taxa de administração: {proposal?.administration_fee || 0} %</p>

        <p>Taxa de intermediação: {proposal?.intermediation_fee || 0} %</p>
      </Administration>
    )

    const sharePrClick = () => {
      const { current } = this.props
      const { proposal } = current

      this.props.openModal(
        'Compartilhar proposta',
        <ShareProposal
          history={this.state.shareHistory}
          proposalId={proposal.id}
          onSuccess={this.updateHistory.bind(this)}
        />,
      )
    }

    const editClick = () => {
      this.props.openModal(
        'Editar proposta',
        <EditProposal
          {...this.props}
          proposalId={proposal.id}
          activeCompanyModel={company}
          activeCompany={company.id}
          defaultValue={proposal}
          onSuccess={this.onEditProposalSuccess.bind(this)}
        />,
      )
    }

    const editResponsibleClick = () => {
      this.props.openModal(
        'Alterar Responsável',
        <EditResponsible
          proposal={proposal}
          activeCompanyId={company.id}
          onSuccess={this.onEditResponsibleClick.bind(this)}
        />,
      )
    }

    const { process_status_url } = current.proposal

    const copyToClipboardClick = () => {
      const path = `${process_status_url}`

      copyToClipboard(path)
      this.props.notification({
        message: 'Link copiado com sucesso',
        status: 'success',
      })
    }

    const extraButtons = (
      <Button
        isBare
        isFullWidth
        isMedium
        onClick={() => {
          ga.logEvent('options_menu', { custom_value: 'public-link' })
          copyToClipboardClick()
        }}
      >
        <Icon name='fas fa-link' />
        Link de Acompanhamento
      </Button>
    )

    const fichacerta = (
      <Fichacerta
        props={this.props}
        companyId={company.id}
        payload={current.proposal}
      />
    )

    const isAcquisition = proposal?.type === 'TYPE_ACQUISITION'

    return (
      <div>
        <div className='visibleToPrint'>
          <ProposalPrint
            data={proposal}
            company={company}
            ref={(element) => (this.componentRef = element)}
          />
        </div>
        <div className='print-wrap'>
          <HeadWrap
            {...this.props}
            fichacerta={fichacerta}
            archiveClick={this.archiveProposal.bind(this, proposal.id, company)}
            fetchCreditAnalysis={this.props.fetchCreditAnalysis}
            editClick={editClick}
            editResponsibleClick={editResponsibleClick}
            extraButtons={!isAcquisition && extraButtons}
            sharePrClick={sharePrClick}
            guarantorList={guarantorList}
            isGuarantorValid={isGuarantorValid}
            isPropValid={isPropValid}
            isClientValid={isClientValid}
            isClientGuarantee={isClientGuarantee}
            negotiationInfo={negotiationInfo}
            optionsInfo={optionsInfo}
            ownerList={ownerList}
            propertyInfo={propertyInfo}
            acquisitionFees={acquisitionFees || null}
            clientList={clientList}
            reactToPrint={reactToPrint}
            clients={clients}
            guarantors={guarantors}
            onEditProperty={this.editProperty.bind(this, property)}
          />

          {activeCompany && !forbiddenForJuridicalAndInspector && (
            <SectionWrapper header='Gerenciar etapa'>
              <ToggleRequirement
                processId={current.id}
                onSuccess={this.closeRequirementSuccess.bind(this)}
                requirement='overview'
                requirementStatus={status}
                companyId={activeCompany.id}
              />
            </SectionWrapper>
          )}

          <Modal
            title={this.state.modalTitle}
            isOpen={this.state.modalIsOpen}
            onClose={this.handleModalClose.bind(this)}
          >
            {this.state.feature}
          </Modal>
        </div>
      </div>
    )
  }

  getValidToken = (tokens) =>
    (tokens || []).find(
      (tk) =>
        !!tk.expiration_date && isAfter(parseISO(tk.expiration_date), new Date()),
    )

  copyProposalLink(tokens) {
    const token = this.getValidToken(tokens)

    if (!token) {
      return
    }

    const path = `${IZEE_PROCESS_URL}${token.token}`

    copyToClipboard(path)

    this.props.notification({
      message: 'Link copiado com sucesso',
      status: 'success',
    })

    return true
  }

  sendLinkWhatsapp(tokens, item, type) {
    const token = this.getValidToken(tokens)

    if (!token) {
      return
    }

    let company = this.props.activeCompany

    let id = this.props.current?.proposal?.id

    let body = ''

    if (type == 'owner') {
      body = `Olá ${item.name}, você tem uma proposta (código: ${id}) para o seu imóvel aqui na ${company?.name}. Clique no link abaixo para continuar.`
    }
    if (type == 'client') {
      body = `Olá ${item.name}, você tem uma proposta (código: ${id}) em seu nome aqui na ${company?.name}. Clique no link abaixo para confirmar a proposta e enviar seus dados, bem simples e rápido.`
    }
    if (type == 'guarantor') {
      const client = this.props.current?.proposal?.clients[0]

      body = `Olá ${item?.name}, sou da ${company?.name}, você foi escolhido por ${client?.name} como um fiador na proposta (código: ${id}). Clique no link abaixo para continuar.`
    }

    let url = `${IZEE_PROCESS_URL}${token.token}`

    let link = `https://api.whatsapp.com/send?text=${body}%0a${url}`

    let left =
      window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 520 / 2

    let top =
      window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 570 / 2

    window.open(
      link,
      '',
      `height=570,width=520,left=${left},top=${top}location=no,scrollbars=yes,status=no,toolbar=no,directories=no,menubar=no,resizable=no,centerscreen=yes,chrome=yes`,
    )

    return true
  }

  archiveProposal(proposalId, companyId) {
    this.props.openModal(
      'Arquivar proposta',
      <ArchiveProposal
        proposalId={proposalId}
        activeCompany={companyId}
        onSuccess={this.onArchiveProposalSuccess.bind(this)}
      />,
    )
  }

  resendProposal(data) {
    const { proposalId, type, email, id } = data
    const body = { [type]: id }

    request
      .post(`{company}/proposal/${proposalId}/send-to-parties`, body)
      .then(() => {
        this.props.fetch(this.props.match.params.processId)
        this.props.notification({
          message: 'Proposta reenviada com sucesso para ' + email,
        })
      })
      .catch(() => {
        this.props.notification({
          message: 'Ocorreu um erro ao tentar reenviar a proposta',
          status: 'danger',
        })
      })
  }

  onArchiveProposalSuccess(response) {
    if (response.isSuccess) {
      this.props.closeModal()
      let delayRedirect = timeDelay(400)

      delayRedirect(() => {
        this.props.history.push('/')
      })
    } else {
      this.props.notification({
        message: response.actionMessage,
        status: 'danger',
      })
    }
  }

  shareProposal() {
    const { current } = this.props
    const { proposal } = current

    this.props.openModal(
      'Compartilhar proposta',
      <ShareProposal
        history={this.state.shareHistory}
        proposalId={proposal.id}
        onSuccess={this.updateHistory.bind(this)}
      />,
    )
  }

  updateHistory(response) {
    if (response.isSuccess) {
      this.props.notification({ message: 'Proposta enviada com sucesso' })
      this.setState({ shareHistory: response.data })
      this.props.closeModal()
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar enviar a proposta por email',
        status: 'danger',
      })
    }
  }

  onEditProposalSuccess() {
    window.location.reload()
  }

  onEditResponsibleClick(response) {
    if (response.isSuccess) {
      this.props.notification({ message: 'Responsável alterado com sucesso' })
      this.props.fetch(this.props.current.id)
      this.props.closeModal()
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar alterar o responsável',
        status: 'danger',
      })
    }
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    })
  }

  creditAnalysis(type, data) {
    this.props.openModal(
      'Análise de crédito',
      <CreditAnalysis userData={{ ...data, ...{ type: type } }} />,
    )
  }

  closeRequirementSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({ message: 'Etapa alterada com sucesso' })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: 'Informe a data de posse aproximada e observações.',
        status: 'danger',
      })
    }
  }

  requestNewDocuments(type, object) {
    const body = { documents: this.state.refusedFiles[object.email] }

    request
      .post(
        `{company}/process/${this.props.match.params.processId}/overview/validate-${type}/${object.id}`,
        body,
      )
      .then(() => {
        this.props.notification({
          message: 'Novos documentos solicitados com sucesso',
        })
      })
      .catch(() => {
        this.props.notification({
          message: 'Ocorreu um erro ao tentar solicitar novos documentos',
          status: 'danger',
        })
      })
  }

  rejectFile(email, doc) {
    doc.email = email
    this.props.openModal(
      'Rejeitar documento',
      <RejectDocument
        defaultValue={doc}
        onSuccess={this.onRejectionSuccess.bind(this)}
      />,
    )
  }

  removeFileFromRejection(email, file) {
    let refusedFiles = this.state.refusedFiles

    refusedFiles[email] = refusedFiles[email].filter((item) => file.id !== item.id)
    this.setState({ refusedFiles: refusedFiles })
  }

  onRejectionSuccess({ data }) {
    const payload = data.payload.raw

    let refusedFiles = this.state.refusedFiles

    if (!refusedFiles[payload.email]) refusedFiles[payload.email] = []
    refusedFiles[payload.email].push({
      id: payload.id,
      reject_comment: data.payload.justification,
      is_rejected: true,
    })
    this.setState({ refusedFiles: refusedFiles })
    this.props.closeModal()
  }

  editProperty(property) {
    let current = property
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/properties/${current.id}`,
      { from: 'proposal' },
    )
  }

  editClient(client) {
    let current = client

    current = formatAddress(current)
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/guests/${current.id}`,
      { from: 'proposal' },
    )
  }

  editGuarantor(guarantor) {
    let current = guarantor

    current = formatAddress(current)
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/guarantors/${current.id}`,
      { from: 'proposal' },
    )
  }

  editOwner(owner) {
    let current = owner

    current = formatAddress(current)
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/property-owners/${current.id}`,
      { from: 'proposal' },
    )
  }

  updateUserSuccess() {
    this.props.notification({ message: 'Dados atualizados com sucesso' })
    this.props.closeModal()
    this.props.fetch(this.props.match.params.processId)
  }

  changeOptionSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Configuração atualizada com sucesso',
      })
      this.props.fetch(this.props.match.params.processId)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar atualizar a configuração',
        status: 'danger',
      })
    }
  }
}

export default withRedux(
  ({ processDetails: { current, creditAnalysis } }) => ({
    current,
    creditAnalysis,
  }),
  { ...actions, ...mainActions, ...commons },
)(withFilePreview(withModal(ProcessOverview)))
