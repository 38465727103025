import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from 'components/lib/Tooltip'
import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses'
import { getTypeOf } from 'utils/utils'

const disabledStyle = {
  color: '#999',
}

const Button = (props) => {
  let buttonCSSClass = `button ${
    props.custom ? props.custom : ''
  } ${parsePropsAsBulmaClasses(props)}`

  if (props.className) buttonCSSClass = props.className

  const children = React.Children.toArray(props.children).map((element, index) => {
    if (getTypeOf(element) === 'String') {
      return (
        <span key={index} style={props?.childrenExtraStyles || {}}>
          {element}
        </span>
      )
    }

    return element
  })

  const disabled = props.isDisabled || props.disabled

  return (
    <button
      id={props.id}
      title={props.alt}
      type={props.submit ? 'submit' : 'button'}
      data-spec-selector={props.specSelector}
      className={buttonCSSClass}
      onClick={props.onClick || null}
      style={props?.extraStyles || props.style || (disabled ? disabledStyle : {})}
      disabled={disabled}
      aria-hidden={props.isStatic}
      tabIndex={props.isStatic ? -1 : props.tabIndex}
      role={props.role ? props.role : null}
    >
      {props.title ? (
        <Tooltip description={props.title}>{children}</Tooltip>
      ) : (
        children
      )}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  childrenExtraStyles: PropTypes.object,
  disabled: PropTypes.bool,
  extraStyles: PropTypes.object,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  childrenExtraStyles: null,
  extraStyles: null,
}

export default Button
