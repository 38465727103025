export default (props) => ({
  id: props.id,
  'data-spec-selector': props.id || props.name,
  name: props.name,
  autoComplete: props.autoComplete || 'off',
  value: props.value,
  defaultValue: props.defaultValue,
  onClick: props.onClick,
  onChange: props.onChange,
  onKeyDown: props.onKeyDown,
  onKeyUp: props.onKeyUp,
  onInput: props.onInput,
  onFocus: props.onFocus,
  onBlur: props.onBlur,
  placeholder: props.placeholder,
  readOnly: props.readOnly,
  disabled: props.disabled || props.isDisabled,
  style: props.style,
  rows: props.rows
});
