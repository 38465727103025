import React, { Fragment } from 'react';
import _ from 'lodash';

// Components
import Grid from 'components/lib/Grid';

import formCreator from 'features/components/formCreator';
import { FormFieldEmail } from 'components/lib/FormFields';

import Header from 'components/lib/Header';
import Space from 'components/lib/Space';
import Button from 'components/lib/Button';
import Dropdown from 'components/lib/Dropdown';
import Icon from 'components/lib/Icon';

import PropertySelection from 'features/components/fieldsGroup/PropertySelection';

import { copyToClipboard } from 'utils/utils';
import * as commons from 'utils/UtilityStore/actions';

// Utils
import { baseURL } from 'core/constants';
import request from 'axios';

import { EmailShareButton, WhatsappShareButton } from 'react-share';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ga } from '../../../services/firebase';

export class LinkProposal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      property: {},
      email: {},
      sendToEmail: false,
      ownershipDate: null,
      modalIsOpen: false,
      modalContent: null,
      modalTitle: null,
      linkUrl: null,
      linkUrlIsCopied: false,
      validate: {
        property: true,
        email: true,
      },
      fieldErrors: {
        property: '',
        email: '',
      },
    };
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
    ga.logEvent('screen_view', { screen_name: 'proposal-link-modal' });
  }

  whatsAppShare() {
    // let subject = "Rede Izee: Link da proposta"
    let body = `${this.props.activeCompany.name}: Basta clicar no link abaixo e seguir os 3 passos para dar andamento à sua proposta. É rápido e fácil =)`;
    let url = this.state.linkUrl;
    let link = `https://api.whatsapp.com/send?text=${body}%0a${url}`;

    ga.logEvent('proposal_link_shared', { custom_value: 'whatsapp' });

    let left =
      window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 520 / 2;
    let top =
      window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 570 / 2;

    window.open(
      link,
      '',
      `height=570,width=520,left=${left},top=${top}location=no,scrollbars=yes,status=no,toolbar=no,directories=no,menubar=no,resizable=no,centerscreen=yes,chrome=yes`
    );

    // Object.assign(document.createElement('a'), {
    //   target: '_blank',
    //   href: link,
    // }).click();
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Grid>
            <Grid.Col>
              <div style={{ position: 'relative', zIndex: 999 }}>
                <PropertySelection
                  {...this.props}
                  onSelect={this.selectedPropertyHandler.bind(this)}
                  isValid={this.state.validate.property}
                  errorMessage="Informe pelo menos um imóvel"
                  showAddition={true}
                />
              </div>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col>
              <div className="mt-12 mb-12 text-right">
                {/* <div style={{ fontSize: 12 }}>Enviar por:</div> */}
                <Dropdown
                  isRight
                  trigger={
                    <Button hasThemeColor isFlat>
                      Enviar por
                      <Icon name="fas fa-arrow-down" />
                    </Button>
                  }
                >
                  <React.Fragment>
                    <Dropdown.Item>
                      <Button
                        {...this.props}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled || !this.state.linkUrl}
                        onClick={() => this.setState({ sendToEmail: true })}
                        isBare
                        isFullWidth
                        isMedium
                      >
                        <Icon name="far fa-envelope" />
                        Email
                      </Button>
                      {/* <EmailShareButton
                      style={{ display: 'block' }}
                      subject="Rede Izee: Link da proposta"
                      body={`${this.props.activeCompany.name
                        }: Basta clicar no link abaixo e seguir os 3 passos para dar andamento à sua proposta. É rápido e fácil =)`}
                      url={this.state.linkUrl}>
                      <Button
                        {...this.props}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled || !this.state.linkUrl}
                        isBare
                        isFullWidth
                        isMedium>
                        <Icon name="far fa-envelope" />
                        Email
                      </Button>
                    </EmailShareButton> */}
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Button
                        {...this.props}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled || !this.state.linkUrl}
                        onClick={() => this.whatsAppShare()}
                        isBare
                        isFullWidth
                        isMedium
                      >
                        <svg
                          style={{ marginLeft: '4', marginRight: '4' }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                        </svg>
                        {/* <Icon name="fab fa-whatsapp" /> */}
                        WhatsApp
                      </Button>
                      {/* <WhatsappShareButton
                      style={{ display: 'block' }}
                      title={`${this.props.activeCompany.name
                        }: Basta clicar no link abaixo e seguir os 3 passos para dar andamento à sua proposta. É rápido e fácil =)`}
                      url={this.state.linkUrl}>
                      <Button
                        {...this.props}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled || !this.state.linkUrl}
                        onclick={() => { this.whatsAppShare() }}
                        isBare
                        isFullWidth
                        isMedium>
                        <Icon name="fab fa-whatsapp" />
                        WhatsApp
                      </Button>
                    </WhatsappShareButton> */}
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <CopyToClipboard
                        text={this.state.linkUrl}
                        onCopy={
                          this.state.linkUrl
                            ? () => this.setState({ linkUrlIsCopied: true })
                            : null
                        }
                      >
                        <Button
                          {...this.props}
                          isLoading={this.props.isLoading}
                          isDisabled={this.props.isDisabled || !this.state.linkUrl}
                          onClick={() => {
                            this.props.onSuccess({
                              isSuccess: true,
                              message: 'Link da proposta copiado',
                            });
                            ga.logEvent('proposal_link_shared', {
                              custom_value: 'clipboard',
                            });
                          }}
                          isSuccess={this.state.linkUrlIsCopied}
                          isBare
                          isFullWidth
                          isMedium
                        >
                          <Icon name="fas fa-link" />
                          Copiar URL
                        </Button>
                      </CopyToClipboard>
                    </Dropdown.Item>
                  </React.Fragment>
                </Dropdown>
              </div>
            </Grid.Col>
          </Grid>

          <Space isSmall />

          {this.state.sendToEmail && (
            <div>
              <Header
                isTiny
                isUppercase
                subtitle={<hr style={{ marginTop: '1rem' }} />}
              >
                Deseja enviar por email?
              </Header>
              <Grid>
                <Grid.Col>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        width: '100%',
                      }}
                    >
                      <FormFieldEmail
                        onChange={this.emailHandle.bind(this)}
                        // errorMessage={this.state.fieldErrors.email}
                      />
                    </div>
                    &nbsp;
                    <div
                      style={{
                        paddingTop: 4,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      <Button
                        {...this.props}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled || !this.state.linkUrl}
                        onClick={() => {
                          this.createRequest('email');
                        }}
                        isSuccess={this.state.linkUrlIsCopied}
                      >
                        Enviar
                      </Button>
                    </div>
                  </div>
                </Grid.Col>
              </Grid>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  emailHandle(value) {
    this.setState({ email: value });
  }

  selectedPropertyHandler(property) {
    this.setState({ property }, () => {
      this.createRequest('link');
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, modalContent: null });
  }

  validateForm(fields, type) {
    const validate = {
      property: true,
      email: true,
    };
    const fieldErrors = {
      property: [],
      email: [],
    };
    if (!this.state.property.id) validate.property = false;

    if (!this.state.email && type == 'email') {
      validate.email = false;
      fieldErrors.email = 'Informe o email';
    }

    this.setState({ validate, fieldErrors });

    if (!validate.property || !validate.email) {
      document.querySelector('.custom-modal.is-active .custom-modal-body').scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      this.props.cancelProgress();
      return false;
    } else return true;
  }

  createRequest(type, fields) {
    // event.preventDefault();

    if (!this.validateForm(fields, type)) return;

    if (type == 'link') {
      request
        .get(
          `${baseURL}company/${this.props.activeCompany.id}/property/${this.state.property.id}/proposal-token`
        )
        .then((response) => {
          const url = IZEE_PROCESS_URL + response.data.token;
          this.setState({ linkUrl: url });
          ga.logEvent('proposal_link_created');
          return this.props.onSuccess({
            isSuccess: true,
            message: 'Link da proposta gerado com sucesso',
          });
        })
        .catch((error) => {
          return this.props.onSuccess({ isSuccess: false });
        });
    }

    if (type == 'email') {
      const goodFields = {};
      goodFields['email'] = this.state.email;

      request
        .post(
          `${baseURL}company/${this.props.activeCompany.id}/property/${this.state.property.id}/send-proposal-token`,
          goodFields
        )
        .then((response) => {
          ga.logEvent('proposal_link_shared', { custom_value: 'email' });
          return this.props.onSuccess({
            isSuccess: true,
            message: 'Link da proposta enviado por email',
          });
        })
        .catch((error) => {
          return this.props.onSuccess({ isSuccess: false });
        });
    }
  }
}

export default formCreator(LinkProposal);
