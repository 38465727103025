import React, { Fragment } from 'react';
import _ from 'lodash';

// Components
import SubmitButton from 'components/SubmitButton';
import Grid from 'components/lib/Grid';

import formCreator from 'features/components/formCreator';
import UserSelection from 'features/components/fieldsGroup/UserSelection';


export class EditResponsible extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      responsible_user_id: (this.props.proposal.responsible_user ? this.props.proposal.responsible_user.id : null),
    };
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }


  render() {
    const { formIsWorking, formIsReady } = this.props;
    return (
      <React.Fragment>

        <UserSelection
          {...this.props}
          isMult={false}
          onSelect={this.selectedResponsibleHandler.bind(this)}
          defaultValue={this.props.proposal.responsible_user}
        />

        <Grid>
          <Grid.Col>
            <SubmitButton
              {...this.props}
              label="Salvar"
              isLoading={formIsWorking}
              isDisabled={!formIsReady || formIsWorking}
            />
          </Grid.Col>
        </Grid>

      </React.Fragment>
    );
  }

  selectedResponsibleHandler(user) {
    this.setState({ responsible_user_id: user.id });
  }


  createRequest(fields) {
    this.props.submit(`{company}/proposal/${this.props.proposal.id}`, {responsible_user_id: this.state.responsible_user_id}, 'put');
  }
}

export default formCreator(EditResponsible);
