import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import ModalHeader from "./header";
import Body from "./body";

import Button from "components/lib/Button";
import SubmitButton from 'components/SubmitButton'
import { Footer } from "./styles";

class ModalBody extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      formConfig: {},
      selected: 0,
      sections: []
    };
    this.callBack = this.callBack.bind(this);
  }

  componentDidMount() {
    if (this.state.isOpen != this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
      if (this.props.onOpen && this.props.isOpen == true) this.props.onOpen();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen != nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
      if (this.props.onOpen && nextProps.isOpen === true) this.props.onOpen();
    }
  }

  render() {
    const {
      formConfig,
      close,
      forward,
      back,
      requestRegistrationClick,
      isLoading,
      isDisabled,
      customBody,
      callBack,
      isInvalid
    } = this.props;
    let { selected, sections } = formConfig;
    /*this.setState({ selected: selected, sections: sections });*/
    const selectedSection = sections[selected];
    const modalCSSClass = `fullscreen-modal${
      this.state.isOpen ? " is-active" : ""
    }${this.props.responsive === false ? "" : " is-responsive"}`;
    return (
      <div className={modalCSSClass}>
        <div className="fullscreen-modal-content">
          <div className="fullscreen-modal-body">
            <ModalHeader
              {...this.props}
              closeClick={close}
              forwardClick={forward}
              title={selectedSection.formTitle}
              buttonTitle={selectedSection.buttonTitle}
              secondButtonTitle={selectedSection.secondButtonTitle}
              requestRegistrationClick={requestRegistrationClick}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
            <Body
              {...this.props}
              formConfig={formConfig}
              sections={sections}
              selected={selected}
              selectedSection={selectedSection}
              callBack={callBack}
              isInvalid={isInvalid}
            />
          </div>
        </div>
        <div
          className="fullscreen-modal-background"
          role="button"
          tabIndex="0"
          onKeyDown={
            !this.props.disableClose && !this.props.disableBackground
              ? this.close.bind(this)
              : null
          }
          onClick={
            !this.props.disableClose && !this.props.disableBackground
              ? this.close.bind(this)
              : null
          }
        />
        <Footer>
          {!this.props.hideBackButton ? 
            <Button hasThemeColor style={{backgroundColor:this.props.companyInfo.themeColor, marginRight: 15}} onClick={() => back()}>
              Voltar
            </Button>
          : null}
          {!this.props.hideForwardButton ? 
            <Button hasThemeColor style={{backgroundColor:this.props.companyInfo.themeColor}} onClick={() => forward()}>
              Avançar
            </Button>
          : null}
          {this.props.hideForwardButton && !this.props.hideConfirmButton ? (
              <SubmitButton
                style={{fontSize:16, fontWeight:'bold', backgroundColor:this.props.companyInfo.themeColor}}
                onClick={() => forward()}
                label='Concluir'
                isLoading={isLoading}
                isDisabled={isDisabled}
              />
            ) : null}
        </Footer>
      </div>
    );
  }

  close() {
    this.setState({ isOpen: false });
    if (this.props.onClose) this.props.onClose();
  }

  callBack(data) {
    this.setState({ selected: data });
  }
}

const body = document.body;

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement("div");
  }

  componentDidMount() {
    body.appendChild(this.element);
  }

  componentWillUnmount() {
    body.removeChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}

const FullscreenModal = props => (
  <Portal>
    <ModalBody {...props} />
  </Portal>
);

export default ModalBody;
