import styled from 'styled-components'

export const ShowPropertyFormButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #0485be;
  border: none;
  border-radius: 4px;
  padding: 10px 40px;
  margin-top: 30px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  > span {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
`

export const PropertyFormSectionWrapper = styled.div`
  margin-top: 30px;

  > form {
    display: block;
    width: 100%;
  }
`

export const PropertyFormSectionTitle = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d5dadf;
  margin-bottom: 30px;

  > h3 {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
`

export const PropertyFormButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 12px;
`

export const CancelPropertyFormButton = styled.button`
  background: #aaadb1;
  border: none;
  border-radius: 4px;
  padding: 10px 26px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const SubmitPropertyButton = styled.button`
  background: #25dc27;
  border: none;
  border-radius: 4px;
  padding: 10px 26px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`
