export const requestState = (is, type) => (dispatch) => {
  dispatch({
    type: 'IS_WORKING',
    payload: is,
    valueKey: type
  });
};

export const notification = ({ isOpen, message, status }) => (dispatch) => {
  if (isOpen === false) {
    dispatch({ type: 'HIDE_NOTIFICATION' });
    return;
  }
  dispatch({
    type: 'SHOW_NOTIFICATION',
    payload: {
      isOpen: isOpen === undefined ? true : isOpen,
      message: message,
      status: status || 'success'
    }
  });
};

export const createHelper = (name, helper) => (dispatch) => {
  dispatch({
    type: 'CREATE_HELPER',
    payload: {
      helper: helper,
      name: name
    }
  });
};
