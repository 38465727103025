import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  FormFieldText
} from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Text from 'components/lib/Text';
import SubmitButton from 'components/SubmitButton';
import formCreator, { fieldProps } from '../components/formCreator';

export class RejectDocument extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>

        <Grid>
          <Grid.Col>
            <Text>
              Tem certeza que deseja rejeitar este documento: <strong>{this.props.defaultValue.name}</strong>
            </Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <FormFieldText
              label="Motivo"
              {...fieldProps(this.props, 'justification')}
            />
          </Grid.Col>
        </Grid>

        <SubmitButton
          label="Salvar recusa"
          isDanger
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {
    fields.raw = this.props.defaultValue;
    this.props.fakeSubmit(fields);
  }

};

RejectDocument.defaultProps = {};

RejectDocument.propTypes = {};

export default formCreator(RejectDocument);
