import Proposal from './containers/Proposal';
import Overview from './containers/Overview';
import Guarantee from './containers/Guarantee';
import Juridical from './containers/Juridical';
import Survey from './containers/Survey';
import Contract from './containers/Contract';
import Keys from './containers/Keys';

const routes = [
  {
    name: 'Proposal Details',
    path: '/app/:userId/:companyId/process/:processId/proposal',
    component: Proposal,
  },
  {
    name: 'Overview',
    path: '/app/:userId/:companyId/process/:processId/overview',
    component: Overview,
  },
  {
    name: 'Guarantee',
    path: '/app/:userId/:companyId/process/:processId/guarantee',
    component: Guarantee,
  },
  {
    name: 'Juridical',
    path: '/app/:userId/:companyId/process/:processId/juridical',
    component: Juridical,
  },
  {
    name: 'Survey',
    path: '/app/:userId/:companyId/process/:processId/survey',
    component: Survey,
  },
  {
    name: 'Contract',
    path: '/app/:userId/:companyId/process/:processId/contract',
    component: Contract,
  },
  {
    name: 'Keys',
    path: '/app/:userId/:companyId/process/:processId/keys',
    component: Keys,
  },
];

export default routes;
