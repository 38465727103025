import React from 'react';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

import { Container, Modal, ModalHeader, ModalBody } from './styles';

const MissingFieldsModal = ({ closeModal, missingFields }) => (
  <Container onClick={closeModal}>
    <Modal onClick={(e) => e.stopPropagation()}>
      <ModalHeader>
        <span style={{ marginRight: 40 }}>
          Preencha esses campos nos cadastros para fazer uma analise
        </span>
        <Button isBare onClick={closeModal}>
          <Icon name="fas fa-times" />
        </Button>
      </ModalHeader>

      <ModalBody>
        {missingFields.map((field) => (
          <p key={field.field}>{field.message}</p>
        ))}
      </ModalBody>
    </Modal>
  </Container>
);

export default MissingFieldsModal;
