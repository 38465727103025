import React, { useState, useEffect, useCallback } from 'react'

import { request } from 'core/constants'

import { FormFieldSelect, FormFieldText } from 'components/lib/FormFields'
import SectionWrapper from 'components/SectionWrapper'
import SubmitButton from 'components/SubmitButton'
import Grid from 'components/lib/Grid'

import { FieldContainer } from '../components/fieldsGroup/FullAddress/styles'
import formCreator from '../components/formCreator'
import FullAddress from '../components/fieldsGroup/FullAddress'
import PropertyMeta from '../components/fieldsGroup/PropertyMeta'
import { fieldProps } from '../components/formCreator'
import DocumentComponent from '../../components/Documents'
import UserSelection from 'features/components/fieldsGroup/UserSelection'

import { goodObject, rawNumber } from 'utils/utils'

const UpdateProperty = (props) => {
  const [selectedType, setSelectedType] = useState()
  const [optionsLoaded, setOptionsLoaded] = useState(false)
  const [optionsTypesSelect, setOptionsTypesSelect] = useState([])

  const createRequest = (fields) => {
    if (props.erros) {
      return
    }
    let goodFields = goodObject(fields, {
      condo_fee: {
        path: 'condo_fee',
        format: (value) => rawNumber(value),
      },
      iptu: {
        path: 'iptu',
        format: (value) => rawNumber(value),
      },
      suggested_rental_value: {
        path: 'suggested_rental_value',
        format: (value) => rawNumber(value),
      },
      suggested_sale_value: {
        path: 'suggested_sale_value',
        format: (value) => rawNumber(value),
      },
    })

    props.submit(`{company}/property/${props.defaultValue.id}`, goodFields, 'put')
  }

  const loadTypes = useCallback(async () => {
    setOptionsLoaded(true)

    const response = await request.get('properties-type')
    const data = response
      .map((res) => ({
        ...res,
        name: res.description,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))

    setOptionsTypesSelect(data)
  }, [])

  useEffect(() => {
    props.createRequest(createRequest)

    !optionsLoaded && loadTypes()
  })

  const handleTypeChange = (value, field) => {
    if (value) {
      setSelectedType(value)
      props.handleFieldChanges('type_id', value, field)
    }
  }

  const selectedResponsibleHandler = (user) => {
    if (user) {
      props.handleFieldChanges('creator.id', user.id, {
        isValid: true,
        value: user.id,
      })
    }
  }

  const defaultType =
    props.defaultValue && props.defaultValue.type ? props.defaultValue.type.id : null

  const type = selectedType || defaultType

  const {
    formIsWorking,
    defaultValue: { id: propertyId },
  } = props

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col isNarrow>
          <FormFieldText
            {...fieldProps(props, 'code')}
            label='Código'
            noValidate
            style={{ width: 100 }}
            // required={true}
          />
        </Grid.Col>
        <Grid.Col>
          <FormFieldText {...fieldProps(props, 'name')} label='Apelido' noValidate />
        </Grid.Col>
      </Grid>

      <FullAddress
        {...props}
        isDeep
        isRequired
        adicionalFields={
          <FieldContainer style={{ marginTop: 5 }}>
            <FormFieldSelect
              style={{ marginTop: 9 }}
              {...fieldProps(props, 'type')}
              label='Tipo de imóvel'
              onChange={(value, field) => handleTypeChange(value, field)}
              value={type}
              valueKey='id'
              options={optionsTypesSelect}
            />
            <FormFieldText
              {...fieldProps(props, 'area')}
              label='Área privativa (m²)'
              mask={['onlyNumbers']}
            />
          </FieldContainer>
        }
      />

      <PropertyMeta {...props} noValidate />

      <SectionWrapper header='Documentação'>
        <DocumentComponent entityName='property' entityId={propertyId} />
      </SectionWrapper>

      {props.profileUpdateCreator && (
        <SectionWrapper header='Responsável'>
          <UserSelection
            {...props}
            label='Alterar Responsável'
            isMult={false}
            onSelect={selectedResponsibleHandler.bind(this)}
            defaultValue={props.defaultValue.creator}
          />
        </SectionWrapper>
      )}

      <SubmitButton
        label='Salvar'
        isLoading={formIsWorking}
        isDisabled={formIsWorking}
      />
    </React.Fragment>
  )
}

export default formCreator(UpdateProperty)
