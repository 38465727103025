import React from 'react'

import { FormFieldText } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'

import { fieldProps } from '../formCreator'

const PropertyMeta = ({ noValidate = false, ...props }) => (
  <>
    <Grid>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'registration')}
          label='Matrícula'
          noValidate={noValidate}
        />
      </Grid.Col>

      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'real_state_enrollment')}
          label='Inscrição Imobiliária'
          noValidate={noValidate}
        />
      </Grid.Col>
    </Grid>

    <Grid>
      <Grid.Col isOneThird>
        <FormFieldText
          {...fieldProps(props, 'light_consumption_unit_number')}
          label='Unidade consumidora - Energia'
          labelStyle={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          noValidate={noValidate}
          style={{ width: '100%' }}
          numbersOnly
        />
      </Grid.Col>

      <Grid.Col isOneThird>
        <FormFieldText
          {...fieldProps(props, 'water_consumption_unit_number')}
          label='Unidade consumidora - Água'
          labelStyle={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          noValidate={noValidate}
          style={{ width: '100%' }}
          numbersOnly
        />
      </Grid.Col>

      <Grid.Col isOneThird>
        <FormFieldText
          {...fieldProps(props, 'gas_consumption_unit_number')}
          label='Unidade consumidora - Gás'
          labelStyle={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          noValidate={noValidate}
          style={{ width: '100%' }}
          numbersOnly
        />
      </Grid.Col>
    </Grid>

    <Grid>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'suggested_rental_value')}
          label='Preço sugerido de locação'
          mask={['number']}
          noValidate={noValidate}
        />
      </Grid.Col>

      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'suggested_sale_value')}
          label='Preço sugerido de venda'
          mask={['number']}
          noValidate={noValidate}
        />
      </Grid.Col>
    </Grid>

    <Grid>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'iptu')}
          label='IPTU (Aproximado)'
          mask={['number']}
          noValidate={noValidate}
        />
      </Grid.Col>

      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'condo_fee')}
          label='Condomínio (Mensal aproximado)'
          mask={['number']}
          noValidate={noValidate}
        />
      </Grid.Col>
    </Grid>

    <Grid>
      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'parking_spaces')}
          label='Número da(s) vaga(s)'
          noValidate={noValidate}
        />
      </Grid.Col>

      <Grid.Col>
        <FormFieldText
          {...fieldProps(props, 'hobby_boxes')}
          label='Depósito(s)'
          noValidate={noValidate}
        />
      </Grid.Col>
    </Grid>
  </>
)

export default PropertyMeta
