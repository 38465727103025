import React from 'react'
import _ from 'lodash'
import { baseURL } from 'core/constants'
import { request } from 'core/constants'
import { connect } from 'react-redux'
import * as actions from '../actions'
import * as commons from 'utils/UtilityStore/actions'
import Pagination from 'components/lib/Pagination'
import Table from 'components/lib/Table'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import { FormFieldSwitch } from 'components/lib/FormFields'
import { FormFieldText } from 'components/lib/FormFields'
import SectionWrapper from 'components/SectionWrapper'
import Modal from 'components/lib/Modal'
import EmptyState from 'components/EmptyState'
import FlexBar from 'components/lib/FlexBar'
import BodyContainer from 'components/lib/BodyContainer'
import CreateContract from 'features/CreateContract'
import UpdateContract from 'features/UpdateContract'
import Remove from 'features/Remove'
import { deepKey, timeDelay } from 'utils/utils'
import HeaderFooterModels from './HeaderFooterModels'
import { ga, fb } from '../../../services/firebase'
import UpdateHeaderFooterTemplate from 'features/UpdateHeaderFooterTemplate'

import DocumentListModal from './components/DocumentListModal'

import ModalPlanRestriction from 'components/ModalPlanRestriction'

import { GuaranteesContainer } from './style'

class Advanced extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      modalIsWide: true,
      openModalHeaderFooter: false,
      customGuaranteeName: '',
      isGuaranteeNameActive: false,
      contractNamesByType: {
        TYPE_RENTAL: 'Locação',
        TYPE_SALE: 'Venda',
        TYPE_ACQUISITION: 'Captação',
      },
    }

    this.handleContractUpdates = this.handleContractUpdates.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  componentDidMount() {
    this.props.fetchContracts()
    this.props.fetchHeaderFooterTemplates()
    this.props.fetchContractVariables()
  }

  render() {
    const subscriptionPlan = this.props.company?.subscription_plan
    const { isGuaranteeNameActive, contractNamesByType } = this.state
    const {
      listContractModelVariables,
      contractListInfo,
      headerFooterListInfo,
      company,
      contractItems,
      headerFooterTemplateItems,
    } = this.props

    company.guarantees.sort((array1, array2) => {
      if (deepKey(array1, 'code') > deepKey(array2, 'code')) return 1
      if (deepKey(array1, 'code') < deepKey(array2, 'code')) return -1

      return 0
    })

    const hasCustomGuarantee = _.filter(company.guarantees, {
      code: 'GUARANTEE_CUSTOM',
      is_active: true,
    })

    const customGuaranteeName = _.find(company.guarantees, {
      code: 'GUARANTEE_CUSTOM',
      is_active: true,
    })

    const guarantees = company.guarantees.map((item) => (
      <div key={item.id} style={{ marginRight: 18, marginBottom: 12 }}>
        <FormFieldSwitch
          info={
            this.props.contractItems.filter(
              (e) => e.type === 'TYPE_RENTAL' && e.guarantee?.code === item.code,
            ).length < 1
              ? 'Adicione um contrato para esta garantia'
              : null
          }
          description={item.name}
          name={item.code}
          onChange={this.handleGuaranteeUpdate.bind(this, item)}
          defaultChecked={item.is_active}
          isLoading={this.props.isWorking[item.code]}
          isDisabled={
            this.props.contractItems.filter(
              (e) => e.type === 'TYPE_RENTAL' && e.guarantee?.code === item.code,
            ).length < 1
          }
        />
      </div>
    ))

    const saveCustomGuaranteeName = () => {
      this.setState({ isGuaranteeNameActive: false })
      request
        .put(`/company/${company.id}/guarantee/5`, {
          name: this.state.customGuaranteeName,
        })
        .then(() => {
          let delayRedirect = timeDelay(400)

          delayRedirect(() => {
            window.location.reload()
          })
        })
        .catch(() => {})
    }

    const DocumentActionCell = (row) => (
      <React.Fragment>
        <Button
          onClick={() =>
            this.handleFeature(
              'Editar lista de documentos',
              <DocumentListModal
                handleCloseModal={this.handleModalClose}
                defaultListOption={row}
                defaultDocuments={row.list}
                isEditing
              />,
              false,
            )
          }
          isNeutral
          isFlat
        >
          <Icon name='fas fa-pen-square' />
        </Button>
        <Button
          onClick={() =>
            this.handleFeature(
              'Remover lista de documentos',
              <DocumentListModal
                handleCloseModal={this.handleModalClose}
                defaultListOption={row}
                defaultDocuments={row.list}
                isEditing
                isRemoving
              />,
              false,
            )
          }
          isNeutral
          isFlat
        >
          <Icon name='fas fa-ban' />
        </Button>
      </React.Fragment>
    )

    const ContractModelsActionCell = (row) => (
      <React.Fragment>
        <Button
          onClick={this.handleUpdateContract.bind(this, row.id)}
          isNeutral
          isFlat
        >
          <Icon name='fas fa-pen-square' />
        </Button>
        <Button
          onClick={this.handleFeature.bind(
            this,
            'Remover modelo de contrato',
            <Remove
              path={`company/${this.props.company.id}/contract-model/${row.id}`}
              display={row.name}
              onSuccess={this.handleContractRemoval.bind(this, row)}
            />,
          )}
          isNeutral
          isFlat
        >
          <Icon name='fas fa-ban' />
        </Button>
      </React.Fragment>
    )

    return (
      <BodyContainer getRef={(ref) => (this.body = ref)}>
        {/* <SectionWrapper header="Configurações">
          <FormFieldSwitch
            name={'signs_contract'}
            onChange={this.handleSettingUpdate.bind(this, 'signs_contract')}
            description="A imobiliária assina o contrato no lugar do proprietário"
            defaultChecked={company.settings['signs_contract']}
            isLoading={this.props.isWorking['signs_contract']}
            isDisabled={this.props.isWorking['signs_contract']}
          />
        </SectionWrapper> */}

        {subscriptionPlan && !subscriptionPlan?.can_access_advanced && (
          <ModalPlanRestriction />
        )}

        <SectionWrapper
          header='Documentos'
          headerRight={
            <Button
              isFlat
              hasThemeColor
              onClick={() =>
                this.handleFeature(
                  'Criar nova pré-configuração de documentos',
                  <DocumentListModal handleCloseModal={this.handleModalClose} />,
                  false,
                )
              }
            >
              Criar novo grupo
            </Button>
          }
        >
          <Table
            items={this.props.companyMeta.documentLists || []}
            cells={[
              {
                name: 'Lista aparece em',
                path: 'label',
              },
              {
                name: 'Documentos',
                path: 'value',
                render: (_, b) => b.list.join(', '),
              },
            ]}
            actionCell={DocumentActionCell}
          />
        </SectionWrapper>

        <SectionWrapper
          header='Modelos de contrato'
          headerRight={
            <Button
              onClick={this.handleFeature.bind(
                this,
                'Criar novo modelo de contrato',
                <CreateContract
                  listContractModelVariables={listContractModelVariables}
                  headerFooterTemplateItems={headerFooterTemplateItems}
                  activeCompany={company}
                  onSuccess={(response) => {
                    ga.logEvent('contract_created')
                    fb.logEvent('contract_created', {
                      contractId: response.data.id,
                      companyId: this.props.activeCompany.id,
                      guarantee: response.data?.guarantee?.name,
                      user: {
                        email: this.props.activeUser.email,
                        id: this.props.activeUser.id,
                      },
                    })
                    this.handleContractUpdates()
                    this.handleModalClose()
                  }}
                />,
              )}
              isFlat
              hasThemeColor
            >
              Criar novo modelo
            </Button>
          }
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {guarantees}
          </div>

          {hasCustomGuarantee.length > 0 && (
            <GuaranteesContainer>
              <FormFieldText
                name={'customGuaranteeName'}
                valueDefault={customGuaranteeName.name}
                onChange={this.handleCustomGuaranteeName.bind(this)}
                label='Nome da garantia personalizada'
                isDisabled={!isGuaranteeNameActive}
                style={{ flex: 1, marginBottom: 0 }}
              />
              {!isGuaranteeNameActive ? (
                <Button
                  hasThemeColor
                  isFlat
                  style={{ height: 36 }}
                  onClick={() => this.setState({ isGuaranteeNameActive: true })}
                >
                  Editar
                </Button>
              ) : (
                <Button
                  hasThemeColor
                  isFlat
                  style={{ height: 36 }}
                  onClick={() => saveCustomGuaranteeName()}
                >
                  Salvar
                </Button>
              )}
            </GuaranteesContainer>
          )}

          <Table
            items={contractItems}
            isUpdatingRows={this.props.isWorking['contracts']}
            cells={[
              {
                name: 'Modelo',
                path: 'name',
              },
              {
                name: 'Tipo',
                width: 200,
                shrink: true,
                render: (_, row) =>
                  row.type in contractNamesByType && contractNamesByType[row.type],
              },
              {
                name: 'Garantia',
                width: 200,
                shrink: true,
                render: (_, row) =>
                  row.type === 'TYPE_SALE' || row.type === 'TYPE_ACQUISITION'
                    ? ''
                    : row.guarantee?.name,
              },
            ]}
            actionCell={ContractModelsActionCell}
            emptyState={<EmptyState isLoading={this.props.isWorking['contracts']} />}
            footer={
              <FlexBar>
                <FlexBar.Child isGrow>
                  <Pagination
                    current={contractListInfo.currentPage}
                    total={contractListInfo.total}
                    itemsPerPage={contractListInfo.perPage}
                    onChange={this.handlePagination.bind(this)}
                  />
                </FlexBar.Child>
              </FlexBar>
            }
          />

          <Modal
            isOpen={this.state.openModal}
            onClose={this.handleModalClose.bind(this)}
            title={this.state.modalTitle}
            isWide={this.state.modalIsWide}
            responsive={false}
            bodyStyle={
              this.state.modalIsWide
                ? {}
                : {
                    overflowY: 'inherit',
                  }
            }
          >
            {this.state.feature}
          </Modal>
        </SectionWrapper>

        <HeaderFooterModels
          company={company}
          moduleRef={this}
          headerFooterListInfo={headerFooterListInfo}
          headerFooterTemplateItems={headerFooterTemplateItems}
        />
      </BodyContainer>
    )
  }

  handlePagination(page) {
    this.body.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    })
    const filter = {
      ...this.state.filter,
      ...{ page: page },
    }

    this.setState({ filter })
    this.props.fetchContracts(filter)
  }

  handlePaginationHeaderFooter(page) {
    this.body.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 1100,
    })

    const filterHeaderFooter = {
      ...this.state.filterHeaderFooter,
      ...{ pageHeaderFooter: page },
    }

    this.setState({ filterHeaderFooter })
    this.props.fetchHeaderFooterTemplates(filterHeaderFooter)
    this.props.fetchContractVariables()
  }

  handleUpdateContract(id) {
    const { listContractModelVariables, company, headerFooterTemplateItems } =
      this.props

    this.setState({
      openModal: true,
      modalTitle: 'Editar modelo de contrato',
      feature: (
        <div>
          <div style={{ height: '350px', marginTop: 200 }}>
            <div
              style={{ margin: '0 auto', padding: '45px 60px' }}
              className='empty-state-spinner'
            />
          </div>
        </div>
      ),
    })
    request
      .get(`${baseURL}company/${company.id}/contract-model/${id}`)
      .then((response) => {
        this.setState({
          openModal: true,
          modalTitle: 'Editar modelo de contrato',
          feature: (
            <UpdateContract
              listContractModelVariables={listContractModelVariables}
              headerFooterTemplateItems={headerFooterTemplateItems}
              activeCompany={company}
              defaultValue={{
                ...response.data,
                ...{
                  guarantee_id: response.data?.guarantee?.id || null,
                  header_footer_template_id:
                    response.data?.header_footer_template?.id || null,
                },
              }}
              onSuccess={this.handleContractUpdates.bind(this, 'edit')}
            />
          ),
        })
      })
  }

  handleFeature(modalTitle, feature, modalIsWide = true) {
    this.setState({
      openModal: true,
      modalTitle,
      feature,
      modalIsWide,
    })
  }

  handleFeatureHeaderFooter(featureHeaderFooter, operation) {
    let title =
      operation === 'edit'
        ? 'Editar modelo de cabeçalho/rodapé'
        : 'Criar novo modelo de cabeçalho/rodapé'

    title = operation === 'delete' ? 'Remover modelo de cabeçalho/rodapé' : title
    this.setState({
      openModalHeaderFooter: true,
      modalTitleHeaderFooter: title,
      featureHeaderFooter,
    })
  }

  handleUpdateHeaderFooter(id) {
    const { company } = this.props

    this.setState({
      openModalHeaderFooter: true,
      modalTitleHeaderFooter: 'Editar modelo de cabeçalho/rodapé',
      featureHeaderFooter: (
        <div>
          <div style={{ height: '350px', marginTop: 200 }}>
            <div
              style={{ margin: '0 auto', padding: '45px 60px' }}
              className='empty-state-spinner'
            />
          </div>
        </div>
      ),
    })
    request
      .get(`${baseURL}company/${company.id}/header-footer-template/${id}`)
      .then((response) => {
        this.setState({
          openModalHeaderFooter: true,
          modalTitleHeaderFooter: 'Editar modelo de cabeçalho/rodapé',
          featureHeaderFooter: (
            <UpdateHeaderFooterTemplate
              activeCompany={company}
              defaultValue={response.data}
              onSuccess={this.handleHeaderFooterUpdates.bind(this)}
            />
          ),
        })
      })
  }

  handleModalClose() {
    this.setState({
      openModal: false,
      feature: null,
    })
  }

  handleModalHeaderFooterClose() {
    this.setState({
      openModalHeaderFooter: false,
      featureHeaderFooter: null,
    })
  }

  handleSettingUpdate(name, value, field) {
    if (!field.wasFocused) return
    this.props.updateSettings(name, value)
  }

  handleGuaranteeUpdate(item, value, field) {
    if (!field.wasFocused) return
    this.props.updateGuarantees(item, value)
    let delayRedirect = timeDelay(500)

    delayRedirect(() => {
      window.location.reload()
    })
    // let checkContract = this.props.contractItems.filter((e) => {
    //   return e.guarantee.code === item.code;
    // })

    // if (value && checkContract.length > 0){
    //   this.props.updateGuarantees(item, value)
    // }
    // let delayRedirect = timeDelay(500)
    // delayRedirect(() => {
    //   window.location.reload()
    // })
  }

  handleCustomGuaranteeName(value) {
    this.setState({ customGuaranteeName: value })
  }

  handleContractUpdates(action, response) {
    if (action === 'edit') {
      ga.logEvent('contract_updated')
      fb.logEvent('contract_updated', {
        contractId: response.data.id,
        companyId: this.props.activeCompany.id,
        guarantee: response.data?.guarantee?.name,
        user: {
          email: this.props.activeUser.email,
          id: this.props.activeUser.id,
        },
      })
    }

    this.props.fetchContracts()
    this.props.fetchContractVariables()
    this.props.notification({
      message: 'Contrato atualizado com sucesso',
    })
  }

  handleHeaderFooterUpdates() {
    this.props.fetchHeaderFooterTemplates()
    this.props.fetchContractVariables()
    this.props.notification({
      message: 'Modelo de cabeçalho e rodapé atualizado com sucesso',
    })
    this.setState({ openModalHeaderFooter: false })
  }

  handleContractRemoval(row) {
    if (row.guarantee) {
      const isLastContractWithThisGuarantee =
        this.props.contractItems.filter(
          (e) => e?.guarantee?.code === row.guarantee.code,
        ).length === 1

      if (isLastContractWithThisGuarantee) {
        this.props.updateGuarantees(row.guarantee, false)
      }

      window.location.reload()

      return
    }

    this.props.fetchContracts()
    this.handleModalClose()
    this.props.notification({
      message: 'Contrato removido com sucesso',
    })
  }

  handleHeaderFooterRemoval() {
    this.props.fetchHeaderFooterTemplates()
    this.props.notification({
      message: 'Modelo de cabeçalho/rodapé removido com sucesso',
    })
    this.handleModalHeaderFooterClose()
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    companyMeta: store.app.companyMeta,
    contractItems: store.settings.contractItems,
    contractListInfo: store.settings.contractListInfo,
    listContractModelVariables: store.settings.listContractModelVariables,
    headerFooterTemplateItems: store.settings.headerFooterTemplateItems,
    headerFooterListInfo: store.settings.headerFooterListInfo,
    listInfo: store.settings.listInfo,
    isWorking: store.commons.isWorking,
  }),
  { ...actions, ...commons },
)(Advanced)
