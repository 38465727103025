import React from 'react';

import OtherPerson from './OtherPerson';

import { FormSection } from '../../../../styles';

const Residencial = ({ formProps, otherClients }) => (
  <FormSection>
    {otherClients.map((client, i) => (
      <OtherPerson key={client.id} index={i} client={client} formProps={formProps} />
    ))}
  </FormSection>
);

export default Residencial;
