import React from 'react'
import moment from 'moment'

// Components
import {
  FormFieldText,
  FormFieldDate,
  FormFieldTime,
  FormFieldTextArea,
} from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import Space from 'components/lib/Space'
import Text from 'components/lib/Text'

import formCreator from 'features/components/formCreator'
import { fieldProps } from 'features/components/formCreator'

// Utils
import { goodObject, rawNumber } from 'utils/utils'

export class Schedule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this))
  }

  changeDate(date) {
    this.setState({ date: date })
  }

  createRequest(fields) {
    const { processId } = this.props

    let goodFields = goodObject(fields, {
      date: {
        path: 'date',
        format: (value) => {
          value = `${moment(value).format('YYYY-MM-DD')} ${
            fields.time
          }:00`
          return value
        },
      },
    })

    this.props.submit(
      `{company}/process/${processId}/inspection/schedule`,
      goodFields,
      'post',
    )
  }

  render() {
    const { formIsWorking, formIsReady, maxDate, ownershipDate } = this.props

    const ownershipDateText =
      ownershipDate && moment(ownershipDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    const ownershipDatePlaceholder = 'A data da posse ainda não foi definida'

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col isNarrow>
            <FormFieldText
              {...fieldProps(this.props, 'inspector_name')}
              style={{ width: 300 }}
              isDisabled={this.props.isClosed}
              placeholder='Nome do vistoriador(a)'
              noValidate
            />
          </Grid.Col>
          <Grid.Col isNarrow>
            <FormFieldDate
              changeDate={this.changeDate.bind(this)}
              {...fieldProps(this.props, 'date')}
              isDisabled={this.props.isClosed}
              maxDate={moment(maxDate)}
              noValidate
            />
          </Grid.Col>
          <Grid.Col isNarrow>
            <FormFieldTime
              {...fieldProps(this.props, 'time')}
              isDisabled={this.props.isClosed}
              noValidate
            />
          </Grid.Col>
        </Grid>

        <Space />

        <Grid>
          <Grid.Col>
            <Text>
              <Icon name='fas fa-sm fa-key' />
              <strong>Local das chaves: </strong>
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Text>
              <Icon name='fas fa-sm fa-calendar-alt' />
              <strong>Data da posse: </strong>
              {ownershipDateText || ownershipDatePlaceholder}
            </Text>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <FormFieldTextArea
              {...fieldProps(this.props, 'keys_location')}
              placeholder='Coloque o local das chaves aqui'
            ></FormFieldTextArea>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col isRight>
            <Button
              hasThemeColor
              isLoading={formIsWorking}
              isDisabled={!formIsReady || formIsWorking}
              submit
            >
              Atualizar
            </Button>
          </Grid.Col>
        </Grid>
      </React.Fragment>
    )
  }
}

export default formCreator(Schedule)
