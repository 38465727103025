import styled from 'styled-components';

export const CompanyHeader = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#2091EA'};
  display: flex;
  padding: 0.5rem 2rem 0.5rem 2rem;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  box-sizing: content-box;
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 30px;
  height: 35px;
  margin: 0 1rem;
  cursor: pointer;
`;

export const Footer = styled.div`
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
