import React from 'react';

// Components
import { FormFieldText, FormFieldTextArea } from 'components/lib/FormFields';
import { fieldProps } from 'features/components/formCreator';

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <FormFieldTextArea label="Observações" placeholder="Coloque as observações aqui" onChange={(e) => this.props.onChange(e)} valueDefault={this.props.defaultValue} />
      </React.Fragment>
    );
  }
}

// export default formCreator(Settings)
export default Settings;
