import React from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';

// Redux
import { connect } from 'react-redux';
import * as actions from './actions';
import * as commons from 'utils/UtilityStore/actions';

// Components
import { withSplashScreen } from 'components/lib/SplashScreen';
import Table from 'components/lib/Table';
import BodyContainer from 'components/lib/BodyContainer';

import SectionWrapper from 'components/SectionWrapper';
import ScreenHeader from 'components/ScreenHeader';

import UpdateUserProfile from 'features/UpdateUserProfile';
import UpdatePassword from 'features/UpdatePassword';
import FormProfile from './form_profile';

export class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const user = this.props.user;

    return (
      <React.Fragment>
        <ScreenHeader header="Meu perfil na Izee" onClick={this.props.historyPush.bind(this)} />

        <BodyContainer isPadded>
          <SectionWrapper header="Dados cadastrais">
            <FormProfile onSuccess={this.onSuccess} />
            {/* <UpdateUserProfile
              disabledAutoFocus
              defaultValue={user}
              onSuccess={this.handleSuccessOnSuccess.bind(this)}
            /> */}
          </SectionWrapper>

          {/* <SectionWrapper header="Alterar senha">
            <UpdatePassword
              disabledAutoFocus
            />
          </SectionWrapper> */}

          <SectionWrapper header="Empresas">
            <Table
              dontHover
              items={user.companies}
              cells={[
                {
                  name: 'Empresa',
                  path: 'name',
                },
                {
                  name: 'Meu perfil',
                  path: 'profiles',
                  shrink: true,
                  width: '20%',
                  render: (content, rowItem) => {
                    const profileListSimplified = rowItem.profiles.map((item) => item.name);
                    return profileListSimplified.join(', ');
                  },
                },
              ]}
            />
          </SectionWrapper>
        </BodyContainer>
      </React.Fragment>
    );
  }

  handleSuccessOnSuccess(response) {
    this.props.updateActiveUser(response.data);
    this.props.notification({ message: 'Usuário atualizado com sucesso!' });
  }

  onSuccess = (message) => {
    this.props.notification({ message });
  };
}

Profile.defaultProps = {};

Profile.propTypes = {};

export default connect(
  (store) => ({
    user: store.app.activeUser,
    historyPush: store.app.helpers.historyPush,
    updateActiveUser: store.app.helpers.updateActiveUser,
  }),
  { ...actions, ...commons }
)(ReactTimeout(Profile));
