import React, { useState, useEffect } from 'react';

import SectionGroup from 'features/AnalysisProposal/components/SectionGroup';
import ContentEditable from 'react-contenteditable';

import request from 'axios';
import { baseURL } from 'core/constants';

const ShowTerms = () => {
  const [privacyTerms, setPrivacyTerms] = useState(null);
  const [usageTerms, setUsageTerms] = useState(null);

  useEffect(() => {
    async function fetchTerms() {
      try {
        const url = `${baseURL}user-term`;
        const response = await request.get(url);
        const { data } = response;
        const privacyTerms = data[0].source;
        const usageTerms = data[1].source;

        handleTermsLoaded(privacyTerms, usageTerms);
      } catch (error) {
        console.log(error);
      }
    }
    fetchTerms();
  }, []);

  const handleTermsLoaded = (privacyTerms, usageTerms) => {
    setPrivacyTerms(privacyTerms);
    setUsageTerms(usageTerms);
  };

  const items = [
    {
      contain: (
        <ContentEditable html={usageTerms} disabled={true} onChange={() => {}} />
      ),
      icon: 'fa-handshake',
      open: false,
      title: 'Termos de Uso',
    },
    {
      contain: (
        <ContentEditable html={privacyTerms} disabled={true} onChange={() => {}} />
      ),
      icon: 'fa-handshake',
      open: false,
      title: 'Política de Privacidade',
    },
  ];

  return <SectionGroup items={items} />;
};

export default ShowTerms;
