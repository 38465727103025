import React from 'react';
import { TwitterPicker } from 'react-color';
import Button from 'components/lib/Button';

// Components
import Icon from 'components/lib/Icon';

const ColorSelector = (props) => (
  <div className="theme-color">
    <TwitterPicker
      color={props.defaultColor}
      colors={[
        '#FF6900',
        '#FCB900',
        '#7BDCB5',
        '#00D084',
        '#2670FF',
        '#0693E3',
        '#ABB8C3',
        '#EB144C',
        '#F78DA7',
        '#9900EF'
      ]}
      triangle="hide"
      onChange={props.onChange}
    />
    <div>
          <div className="theme-color-selected" style={{ backgroundColor: props.defaultColor }}>
      {props.isLoading ? <Icon name="far fa-fill-drip" /> : <Icon name="far fa-check" />}
    </div>
    <div className="text-right mt-2">
      <Button hasThemeColor isFlat onClick={props.onClick}>
        Salvar
      </Button>
    </div>
    </div>
  </div>
);

ColorSelector.propTypes = {};

export default ColorSelector;
