import React from 'react';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Tooltip from 'components/lib/Tooltip';

const UserName = (props) => (
  <div>
    {props.content.cnpj ? (
      <React.Fragment>
        <strong>{props.content.corporate_name}</strong>
        <br />
        <span className="text has-text-grey">{props.content.cnpj}</span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <strong>{props.content.name}</strong>{props.content.is_verified ? (
          <Tooltip description={<span>Cliente<br />pré-aprovado<br />pela Izee</span>}>
            <Icon name="fas fa-sm fa-shield-check" isSuccess />
          </Tooltip>
        ) : null}<br />
        <span className="text has-text-grey">{props.content.email}</span>
      </React.Fragment>
    )}
    
  </div>
);

UserName.defaultProps = {
  content: {}
};

export default UserName;
