import React, { Component } from "react";
import { createFeature, createTitle } from "./helpers.js";

import Modal from "components/lib/Modal";

class ReleaseNotes extends Component {
  constructor(props) {
    super(props);

    /**
     * Controle das release notes
     * @type {string}
     */
    this.currentReleaseDate = ''; // Colocar nova data aqui
    this.previousReleaseDate = '05/05/2021'; // Colocar data anterior aqui

    this.currentReleaseNotes = 'releaseNotes'+this.currentReleaseDate.split('/').join('');
    this.previousReleaseNotes = 'releaseNotes'+this.previousReleaseDate.split('/').join('');
    this.state = {
      isModalOpen: true
    };
  }

  handleModalClose() {
    this.setState({ isModalOpen: false, feature: null });
    localStorage.setItem(this.currentReleaseNotes, "true");
  }

  render() {
    localStorage.removeItem(this.previousReleaseNotes);
    return null;
    // const hasRead = localStorage.getItem(this.currentReleaseNotes);
    //
    // if (hasRead) return null;
    //
    // const { isModalOpen } = this.state;
    //
    // const feature = createFeature();
    // const title = createTitle(this.currentReleaseDate);
    //
    // return (
    //   <Modal
    //     isOpen={isModalOpen}
    //     onClose={this.handleModalClose.bind(this)}
    //     responsive={true}
    //     title={title}
    //   >
    //     {feature}
    //   </Modal>
    // );
  }
}

export default ReleaseNotes;
