import React from 'react';
import ReactTimeout from 'react-timeout';
// import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as commons from 'utils/UtilityStore/actions';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

// import Input, { propsObject as defaultProps } from 'components/lib/FormFields/components/Input';
import Field from 'components/lib/FormFields/components/Field';
// import Form from 'components/lib/Form';
// import Button from 'components/lib/Button';
import Control from 'components/lib/FormFields/components/Control';
import Icon from 'components/lib/Icon';
import Text from 'components/lib/Text';

import { request } from 'core/constants';
import Modal from 'components/lib/Modal';
import CreatePropertyOwner from 'features/CreatePropertyOwner';

import AddButton from './components/AddButton';
import MultiValueLabel from './components/MultiValueLabel';
import MultiValueRemove from './components/MultiValueRemove';

class PropertySelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      options: [],
      isWorking: false,
      noOptions: false,
      modalIsOpen: false,
    };
    this.asyncRef = React.createRef();

    this.props.handleFieldChanges('property_owner_ids', [], {
      isValid: false,
      value: [],
    });
  }

  componentDidMount() {
    const { propertyOwners } = this.props;
    if (!propertyOwners) return;
    if (propertyOwners.length > 0) {
      this.setState({ options: propertyOwners });
    }
  }

  onAdded(result) {
    if (result.isSuccess) {
      this.setState({
        modalIsOpen: false,
        options: [
          ...this.state.options,
          { id: result.data.id, name: result.data.name, email: result.data.email },
        ],
      });

      this.onChangeAsyncSelect(this.state.options);
    } else {
      this.props.notification({
        message: 'Não foi possível adicionar esse proprietário',
        status: 'danger',
      });
    }
  }

  onChangeAsyncSelect(options) {
    this.setState({
      options,
      inputValue: '',
    });
    if (this.props.onSelect) this.props.onSelect(options);
    this.props.handleFieldChanges(
      'property_owner_ids',
      [...options].map((item) => item.id),
      {
        isValid: options.length > 0,
        value: [...options].map((item) => item.id),
      }
    );
  }

  render() {
    const { showAddition } = this.props;

    const promiseOptions = (inputValue) =>
      new Promise((resolve, reject) => {
        request
          .get('{company}/property-owner/search?q=' + inputValue)
          .then((response) => {
            this.setState({ noOptions: false });
            resolve(response.data);
          })
          .catch(() => reject());
      });

    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <Icon name="fas fa-user" />
      </components.DropdownIndicator>
    );

    const Option = (props) => {
      const data = props.data || {};
      return (
        <components.Option {...props}>
          {data.cnpj ? (
            <div>
              <span style={{ fontSize: 14, fontWeight: 700, color: '#4a4a4a' }}>
                {data.corporate_name || 'Sem nome'}
              </span>
              <br />
              <span style={{ fontSize: 12 }}>{data.cnpj}</span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: 14, fontWeight: 700, color: '#4a4a4a' }}>
                {data.name || 'Sem nome'}
              </span>
              <br />
              {data.withSpouse && (
                <>
                  <br />
                  <span style={{ fontSize: 12 }}>Cônjuge: {data.spouse_name}</span>
                </>
              )}
              <span style={{ fontSize: 12 }}>{data.email}</span>
            </div>
          )}
        </components.Option>
      );
    };

    const LoadingIndicator = (props) => null;

    return (
      <React.Fragment>
        <Field label="Proprietários">
          <Control>
            <div className="react-select-wrapper" style={{ display: 'flex' }}>
              <AsyncSelect
                defaultOptions
                ref={(ref) => (this.asyncRef = ref)}
                classNamePrefix="react-select"
                defaultValue={[]}
                name="propertyOwner"
                isMulti
                id="property-owner-selection"
                isDisabled={this.state.isWorking}
                isLoading={this.state.isWorking}
                value={this.state.options}
                loadingMessage={() => 'Pesquisando por proprietários...'}
                noOptionsMessage={() => <span>Nenhum proprietário encontrado</span>}
                placeholder="Selecionar proprietários"
                menuPosition="fixed"
                maxMenuHeight={162}
                minMenuHeight={100}
                onInputChange={this.handleInputChange.bind(this)}
                inputValue={this.state.inputValue}
                onChange={this.onChangeAsyncSelect.bind(this)}
                isClearable={false}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{
                  MultiValueLabel,
                  MultiValueRemove,
                  DropdownIndicator,
                  LoadingIndicator,
                  Option,
                }}
                loadOptions={promiseOptions}
              />
              {showAddition ? (
                <AddButton onClick={() => this.setState({ modalIsOpen: true })} />
              ) : null}
            </div>
          </Control>
        </Field>
        <Modal
          title="Criar novo proprietário"
          isOpen={this.state.modalIsOpen}
          onClose={() => {
            this.setState({ modalIsOpen: false });
          }}
          backgroundContentStyle={{
            display: 'block',
          }}
        >
          <CreatePropertyOwner onSuccess={this.onAdded.bind(this)} />
        </Modal>
      </React.Fragment>
    );
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  }
}

PropertySelection.propTypes = {};

export default connect((store) => ({}), { ...commons })(
  ReactTimeout(PropertySelection)
);
