import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import jsonp from 'jsonp'
import PropTypes from 'prop-types'

import { baseURL, request } from 'core/constants'

import Button from 'components/lib/Button'
import Grid from 'components/lib/Grid'

import {
  FormFieldCEP,
  FormFieldSelect,
  FormFieldText,
} from 'components/lib/FormFields'

import { goodObject, rawNumber } from 'utils/utils'
import { FieldContainer } from '../components/fieldsGroup/FullAddress/styles'

import ConfirmationModal from 'components/lib/Modal'
import ConfirmationMessage from 'components/ConfirmationMessage'
import Table from 'components/lib/Table'
import Icon from 'components/lib/Icon'
import EmptyState from 'components/EmptyState'

import {
  CancelPropertyFormButton,
  PropertyFormButtonWrapper,
  PropertyFormSectionTitle,
  PropertyFormSectionWrapper,
  ShowPropertyFormButton,
  SubmitPropertyButton,
} from './styles'

const CreateProperties = ({
  companyId,
  ownerId,
  publicHash,
  publicLink,
  registeredProperties,
}) => {
  const [addressByZipcode, setAddressBZipcode] = useState(null)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [isSearchingForAddress, setIsSearchingForAddress] = useState(false)
  const [properties, setProperties] = useState(registeredProperties || [])
  const [propertyId, setPropertyId] = useState(null)
  const [propertyTypesOptions, setPropertyTypesOptions] = useState([])
  const [modal, setModal] = useState({ isOpen: false, message: '', success: true })

  const formWrapperRef = useRef(null)

  const handleScrollToForm = () => {
    setTimeout(() => {
      formWrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 100)
  }

  const handleSendProperty = async (payload) => {
    if (publicLink && publicHash) {
      const config = { headers: { Authorization: `Bearer ${publicHash}` } }
      const url = `${baseURL}public/company/${companyId}/property-owner/${ownerId}/property`

      const { data } = await axios.post(url, payload, config)

      return data?.data
    }

    if (propertyId) {
      const response = await request.put(
        `/company/${companyId}/property/${propertyId}`,
        payload,
      )

      return response?.data
    }

    const response = await request.post(`/company/${companyId}/property`, payload)

    return response?.data
  }

  const handleValidatePropertyData = (formValues) => {
    const requiredFields = {
      code: 'Campo Código é obrigatório',
      'address.zip_code': 'Campo CEP é obrigatório',
      'address.address': 'Campo Logradouro é obrigatório',
      'address.state': 'Campo Estado é obrigatório',
      'address.city': 'Campo Cidade é obrigatório',
      'address.neighborhood': 'Campo Bairro é obrigatório',
      'address.number': 'Campo Número é obrigatório',
    }

    let isValid = true

    for (const [field, errorMessage] of Object.entries(requiredFields)) {
      const value = field.split('.').reduce((acc, key) => acc?.[key], formValues)

      if (!value) {
        setFieldError(field, errorMessage)

        isValid = false
      }
    }

    return isValid
  }

  const {
    values: formValues,
    errors: formErrors,
    isValid: isFormValid,
    isSubmitting,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldError,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: {
        additional_address: '',
        address: '',
        city: '',
        code: '',
        neighborhood: '',
        number: '',
        state: '',
        zip_code: '',
      },
      area: '',
      code: '',
      condo_fee: null,
      gas_consumption_unit_number: '',
      hobby_boxes: '',
      iptu: null,
      light_consumption_unit_number: '',
      name: '',
      parking_spaces: '',
      real_state_enrollment: '',
      registration: '',
      suggested_rental_value: null,
      suggested_sale_value: null,
      type_id: '',
      water_consumption_unit_number: '',
    },
    onSubmit: async (submitValues, { resetForm }) => {
      const isValid = handleValidatePropertyData(submitValues)

      if (!isValid) return

      const payload = { ...submitValues }

      payload['property_owner_ids'] = []

      if (ownerId) payload['property_owner_ids'].push(ownerId)

      let goodFields = goodObject(payload, {
        condo_fee: {
          path: 'condo_fee',
          format: (value) => rawNumber(value),
        },
        iptu: {
          path: 'iptu',
          format: (value) => rawNumber(value),
        },
        suggested_rental_value: {
          path: 'suggested_rental_value',
          format: (value) => rawNumber(value),
        },
        suggested_sale_value: {
          path: 'suggested_sale_value',
          format: (value) => rawNumber(value),
        },
      })

      if (propertyId) {
        try {
          const data = await handleSendProperty(goodFields)

          if (data?.id) {
            setProperties((state) => {
              const propertiesWithUpdatedProperty = state.filter(
                (property) => property.id !== propertyId,
              )

              propertiesWithUpdatedProperty.push({ ...payload, id: data.id })

              return propertiesWithUpdatedProperty
            })
            setFormIsOpen(false)
            setPropertyId(null)
            setModal({
              isOpen: true,
              message: 'Imóvel atualizado com sucesso',
              success: true,
            })
            resetForm()
          }

          return
        } catch (error) {
          setModal({
            isOpen: true,
            message: 'Houve um erro ao atualizar o imóvel, tente novamente em breve',
            success: false,
          })

          return
        }
      }

      try {
        const data = await handleSendProperty(goodFields)

        if (data?.id) {
          setProperties((state) => [...state, { ...payload, id: data.id }])
          setFormIsOpen(false)
          setModal({
            isOpen: true,
            message: 'Imóvel cadastrado com sucesso',
            success: true,
          })
          resetForm()
        }
      } catch (error) {
        setModal({
          isOpen: true,
          message: 'Houve um erro ao cadastrar o imóvel, tente novamente em breve',
          success: false,
        })
      }
    },
  })

  const handleZipcodeChange = (value) => {
    setFieldValue('address.zip_code', value)

    if (!value || value?.length < 9) return

    setIsSearchingForAddress(true)

    const url = `https://viacep.com.br/ws/${value.replace('-', '')}/json`

    try {
      jsonp(url, null, (_, data) => {
        if (data?.erro) {
          setFieldError('address.zip_code', 'CEP inválido ou inexistente')

          return
        }

        handleAddressChange('fullAddress', data)

        setAddressBZipcode(data)
        setIsSearchingForAddress(false)
      })
    } catch (error) {
      setAddressBZipcode(null)
      setIsSearchingForAddress(false)
    }
  }

  const handleAddressChange = (name, value) => {
    if (name === 'fullAddress') {
      if (value?.['cep']) setFieldValue('address.zip_code', value['cep'])
      if (value?.['bairro']) setFieldValue('address.neighborhood', value['bairro'])
      if (value?.['localidade']) setFieldValue('address.city', value['localidade'])
      if (value?.['uf']) setFieldValue('address.state', value['uf'])
      if (value?.['logradouro'])
        setFieldValue('address.address', value['logradouro'])

      return
    }

    setFieldValue(name, value)
  }

  const handleSelectPropertyToUpdate = (propertyToUpdate) => {
    setFormIsOpen(true)
    setPropertyId(propertyToUpdate.id)
    setValues({
      address: {
        additional_address: propertyToUpdate?.address?.additional_address || '',
        address: propertyToUpdate?.address?.address || '',
        city: propertyToUpdate?.address?.city || '',
        code: propertyToUpdate?.address?.code || '',
        neighborhood: propertyToUpdate?.address?.neighborhood || '',
        number: propertyToUpdate?.address?.number || '',
        state: propertyToUpdate?.address?.state || '',
        zip_code: propertyToUpdate?.address?.zip_code || '',
      },
      area: propertyToUpdate?.area || '',
      code: propertyToUpdate?.code || '',
      condo_fee: propertyToUpdate?.condo_fee || null,
      gas_consumption_unit_number:
        propertyToUpdate?.gas_consumption_unit_number || '',
      hobby_boxes: propertyToUpdate?.hobby_boxes || '',
      iptu: propertyToUpdate?.iptu || null,
      light_consumption_unit_number:
        propertyToUpdate?.light_consumption_unit_number || '',
      name: propertyToUpdate?.name || '',
      parking_spaces: propertyToUpdate?.parking_spaces || '',
      real_state_enrollment: propertyToUpdate?.real_state_enrollment || '',
      registration: propertyToUpdate?.registration || '',
      suggested_rental_value: propertyToUpdate?.suggested_rental_value || null,
      suggested_sale_value: propertyToUpdate?.suggested_sale_value || null,
      type_id: propertyToUpdate?.type_id || '',
      water_consumption_unit_number:
        propertyToUpdate?.water_consumption_unit_number || '',
    })

    handleScrollToForm()
  }

  useEffect(() => {
    if (modal.isOpen) {
      setTimeout(() => {
        setModal({ isOpen: false, message: '', success: false })
      }, 3000)
    }
  }, [modal])

  useEffect(() => {
    const loadPropertyTypes = async () => {
      const response = await request.get('properties-type')

      const data = response
        .map((item) => ({ ...item, name: item.description }))
        .sort((a, b) => a.name.localeCompare(b.name))

      setPropertyTypesOptions(data)
    }

    loadPropertyTypes()
  }, [])

  return (
    <>
      <Table
        items={properties}
        isUpdatingRows={isSubmitting}
        cells={[
          {
            name: 'Endereço',
            path: 'address',
            render: (content) => {
              if (!content) return '-'

              return (
                <span>
                  {content?.neighborhood || '-'}
                  <br />
                  {content?.city || '-'} - {content?.state || '-'}
                </span>
              )
            },
          },
          {
            name: 'Área',
            path: 'area',
            width: '30%',
            render: (content) => {
              if (!content) return '-'

              return (
                <>
                  <span>{content}</span> m<sup>2</sup>
                </>
              )
            },
          },
          {
            name: 'Tipo',
            path: 'type_id',
            width: '30%',
            render: (content) => {
              if (!content) return '-'

              const propertyType = propertyTypesOptions.find(
                (option) => option?.id === Number(content),
              )

              return propertyType?.name || '-'
            },
          },
        ]}
        actionCell={(row) => (
          <div className='buttons'>
            <Button
              title='Editar'
              onClick={() => handleSelectPropertyToUpdate(row)}
              isNeutral
              isFlat
            >
              <Icon name='fas fa-pen-square' />
            </Button>
          </div>
        )}
        emptyState={<EmptyState isLoading={false} />}
      />

      {!formIsOpen && (
        <ShowPropertyFormButton
          type='button'
          disabled={formIsOpen}
          onClick={() => {
            setFormIsOpen(true)
            handleScrollToForm()
          }}
        >
          <Icon name='fa fa-plus' />

          <span>Adicionar imóvel</span>
        </ShowPropertyFormButton>
      )}

      {formIsOpen && (
        <PropertyFormSectionWrapper ref={formWrapperRef}>
          <PropertyFormSectionTitle>
            <h3>Dados do Imóvel</h3>
          </PropertyFormSectionTitle>

          <form>
            <Grid>
              <Grid.Col isNarrow>
                <FormFieldText
                  name='code'
                  label='Código'
                  onChange={(value) => setFieldValue('code', value)}
                  value={formValues?.code}
                  valueDefault={formValues?.code}
                  errorMessage={formErrors?.code}
                  forceInvalid={formErrors?.code}
                  isRequired
                />
              </Grid.Col>

              <Grid.Col>
                <FormFieldText
                  name='name'
                  label='Apelido'
                  onChange={(value) => setFieldValue('name', value)}
                  value={formValues?.name}
                  valueDefault={formValues?.name}
                  errorMessage={formErrors.name}
                  forceInvalid={formErrors.name}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <FormFieldCEP
                  name='address.zip_code'
                  info='Se não souber o seu CEP preencha os campos manualmente'
                  onChange={handleZipcodeChange}
                  value={formValues?.address?.zip_code}
                  valueDefault={formValues?.address?.zip_code}
                  errorMessage={formErrors?.address?.zip_code}
                  forceInvalid={formErrors?.address?.zip_code}
                  isLoading={isSearchingForAddress}
                  numbersOnly
                  isRequired
                />
              </Grid.Col>

              <Grid.Col>
                <FormFieldText
                  name='address.address'
                  label='Logradouro'
                  onChange={(value) => handleAddressChange('address.address', value)}
                  value={formValues?.address?.address}
                  valueDefault={
                    formValues?.address?.address || addressByZipcode?.logradouro
                  }
                  errorMessage={formErrors?.address?.address}
                  forceInvalid={formErrors?.address?.address}
                  isDisabled={isSearchingForAddress}
                  isRequired
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <FormFieldText
                  name='address.number'
                  label='Número'
                  onChange={(value) => handleAddressChange('address.number', value)}
                  mask={['onlyNumbers']}
                  value={formValues?.address?.number}
                  valueDefault={formValues?.address?.number}
                  errorMessage={formErrors?.address?.number}
                  forceInvalid={formErrors?.address?.number}
                  isRequired
                />
              </Grid.Col>

              <Grid.Col field>
                <FormFieldText
                  name='address.state'
                  label='Estado'
                  onChange={(value) => handleAddressChange('address.state', value)}
                  value={formValues?.address?.state}
                  valueDefault={formValues?.address?.state || addressByZipcode?.uf}
                  errorMessage={formErrors?.address?.state}
                  forceInvalid={formErrors?.address?.state}
                  isDisabled={isSearchingForAddress}
                  isRequired
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col field>
                <FormFieldText
                  name='address.city'
                  label='Cidade'
                  onChange={(value) => handleAddressChange('address.city', value)}
                  value={formValues?.address?.city}
                  valueDefault={
                    formValues?.address?.city || addressByZipcode?.localidade
                  }
                  errorMessage={formErrors?.address?.city}
                  forceInvalid={formErrors?.address?.city}
                  isDisabled={isSearchingForAddress}
                  isRequired
                />
              </Grid.Col>

              <Grid.Col field>
                <FormFieldText
                  name='address.neighborhood'
                  label='Bairro'
                  onChange={(value) =>
                    handleAddressChange('address.neighborhood', value)
                  }
                  value={formValues?.address?.neighborhood}
                  valueDefault={
                    formValues?.address?.neighborhood || addressByZipcode?.bairro
                  }
                  errorMessage={formErrors?.address?.neighborhood}
                  forceInvalid={formErrors?.address?.neighborhood}
                  isDisabled={isSearchingForAddress}
                  isRequired
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col field>
                <FormFieldText
                  name='address.additional_address'
                  label='Complemento (Opcional)'
                  onChange={(value) =>
                    handleAddressChange('address.additional_address', value)
                  }
                  value={formValues?.address?.additional_address}
                  valueDefault={formValues?.address?.additional_address}
                  errorMessage={formErrors?.address?.additional_address}
                  forceInvalid={formErrors?.address?.additional_address}
                />
              </Grid.Col>
            </Grid>

            <FieldContainer>
              <FormFieldSelect
                name='type_id'
                label='Tipo de imóvel'
                onChange={(value) => setFieldValue('type_id', value)}
                options={propertyTypesOptions}
                valueKey='id'
                value={formValues?.type_id}
                valueDefault={formValues?.type_id}
                errorMessage={formErrors?.type_id}
                forceInvalid={formErrors?.type_id}
              />

              <FormFieldText
                name='area'
                label='Área privativa (m²)'
                onChange={(value) => setFieldValue('area', value)}
                mask={['onlyNumbers']}
                value={formValues?.area}
                valueDefault={formValues?.area}
                errorMessage={formErrors?.area}
                forceInvalid={formErrors?.area}
              />
            </FieldContainer>

            <Grid>
              <Grid.Col>
                <FormFieldText
                  name='registration'
                  label='Matrícula'
                  onChange={(value) => setFieldValue('registration', value)}
                  value={formValues?.registration}
                  valueDefault={formValues?.registration}
                  errorMessage={formErrors?.registration}
                  forceInvalid={formErrors?.registration}
                />
              </Grid.Col>

              <Grid.Col>
                <FormFieldText
                  name='real_state_enrollment'
                  label='Inscrição Imobiliária'
                  onChange={(value) => setFieldValue('real_state_enrollment', value)}
                  value={formValues?.real_state_enrollment}
                  valueDefault={formValues?.real_state_enrollment}
                  errorMessage={formErrors?.real_state_enrollment}
                  forceInvalid={formErrors?.real_state_enrollment}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col isOneThird>
                <FormFieldText
                  name='light_consumption_unit_number'
                  label='Unidade consumidora - Energia'
                  labelStyle={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    setFieldValue('light_consumption_unit_number', value)
                  }
                  value={formValues?.light_consumption_unit_number}
                  valueDefault={formValues?.light_consumption_unit_number}
                  errorMessage={formErrors?.light_consumption_unit_number}
                  forceInvalid={formErrors?.light_consumption_unit_number}
                  numbersOnly
                />
              </Grid.Col>

              <Grid.Col isOneThird>
                <FormFieldText
                  name='water_consumption_unit_number'
                  label='Unidade consumidora - Água'
                  labelStyle={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    setFieldValue('water_consumption_unit_number', value)
                  }
                  value={formValues?.water_consumption_unit_number}
                  valueDefault={formValues?.water_consumption_unit_number}
                  errorMessage={formErrors?.water_consumption_unit_number}
                  forceInvalid={formErrors?.water_consumption_unit_number}
                  numbersOnly
                />
              </Grid.Col>

              <Grid.Col isOneThird>
                <FormFieldText
                  name='gas_consumption_unit_number'
                  label='Unidade consumidora - Gás'
                  labelStyle={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    setFieldValue('gas_consumption_unit_number', value)
                  }
                  value={formValues?.gas_consumption_unit_number}
                  valueDefault={formValues?.gas_consumption_unit_number}
                  errorMessage={formErrors?.gas_consumption_unit_number}
                  forceInvalid={formErrors?.gas_consumption_unit_number}
                  numbersOnly
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <FormFieldText
                  name='suggested_rental_value'
                  label='Preço sugerido de locação'
                  mask={['number']}
                  onChange={(value) =>
                    setFieldValue('suggested_rental_value', value)
                  }
                  value={formValues?.suggested_rental_value}
                  valueDefault={formValues?.suggested_rental_value}
                  errorMessage={formErrors?.suggested_rental_value}
                  forceInvalid={formErrors?.suggested_rental_value}
                />
              </Grid.Col>

              <Grid.Col>
                <FormFieldText
                  name='suggested_sale_value'
                  label='Preço sugerido de venda'
                  mask={['number']}
                  onChange={(value) => setFieldValue('suggested_sale_value', value)}
                  value={formValues?.suggested_sale_value}
                  valueDefault={formValues?.suggested_sale_value}
                  errorMessage={formErrors?.suggested_sale_value}
                  forceInvalid={formErrors?.suggested_sale_value}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <FormFieldText
                  name='iptu'
                  label='IPTU (Aproximado)'
                  mask={['number']}
                  onChange={(value) => setFieldValue('iptu', value)}
                  value={formValues?.iptu}
                  valueDefault={formValues?.iptu}
                  errorMessage={formErrors?.iptu}
                  forceInvalid={formErrors?.iptu}
                />
              </Grid.Col>

              <Grid.Col>
                <FormFieldText
                  name='condo_fee'
                  label='Condomínio (Mensal aproximado)'
                  mask={['number']}
                  onChange={(value) => setFieldValue('condo_fee', value)}
                  value={formValues?.condo_fee}
                  valueDefault={formValues?.condo_fee}
                  errorMessage={formErrors?.condo_fee}
                  forceInvalid={formErrors?.condo_fee}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <FormFieldText
                  name='parking_spaces'
                  label='Número da(s) vaga(s)'
                  onChange={(value) => setFieldValue('parking_spaces', value)}
                  value={formValues?.parking_spaces}
                  valueDefault={formValues?.parking_spaces}
                  errorMessage={formErrors?.parking_spaces}
                  forceInvalid={formErrors?.parking_spaces}
                />
              </Grid.Col>

              <Grid.Col>
                <FormFieldText
                  name='hobby_boxes'
                  label='Depósito(s)'
                  onChange={(value) => setFieldValue('hobby_boxes', value)}
                  value={formValues?.hobby_boxes}
                  valueDefault={formValues?.hobby_boxes}
                  errorMessage={formErrors?.hobby_boxes}
                  forceInvalid={formErrors?.hobby_boxes}
                />
              </Grid.Col>
            </Grid>

            <PropertyFormButtonWrapper>
              <CancelPropertyFormButton
                type='button'
                disabled={isSubmitting}
                onClick={() => {
                  setFormIsOpen(false)
                  setPropertyId(null)
                  resetForm()
                }}
              >
                Cancelar
              </CancelPropertyFormButton>

              <SubmitPropertyButton
                type='button'
                disabled={isSubmitting || !isFormValid}
                onClick={handleSubmit}
              >
                {propertyId ? 'Atualizar' : 'Cadastrar'}
              </SubmitPropertyButton>
            </PropertyFormButtonWrapper>
          </form>
        </PropertyFormSectionWrapper>
      )}

      <ConfirmationModal
        containerStyle={{ maxWidth: 500 }}
        headerStyle={{ backgroundColor: 'transparent' }}
        bodyStyle={{
          paddingTop: 0,
          paddingBottom: 35,
          paddingLeft: 35,
          paddingRight: 35,
        }}
        backgroundContentStyle={{ display: 'block' }}
        isOpen={modal.isOpen}
        onClose={() => setModal({ isOpen: false, message: '', success: false })}
        disableClose
      >
        <ConfirmationMessage message={modal.message} success={modal.success} />
      </ConfirmationModal>
    </>
  )
}

CreateProperties.propTypes = {
  companyId: PropTypes.number,
  ownerId: PropTypes.number,
  publicHash: PropTypes.string,
  publicLink: PropTypes.bool,
  registeredProperties: PropTypes.array,
}

CreateProperties.defaultProps = {
  companyId: null,
  ownerId: null,
  publicHash: null,
  publicLink: false,
  registeredProperties: [],
}

export default CreateProperties
