import React, { Component } from "react";
import Icon from "components/lib/Icon";
import * as types from "../types";

class StatusFilter extends Component {
  render() {
    const { content, row } = this.props;
    switch (content) {
      case types.STATUS_WAITING:
        return (
          <React.Fragment>
            <Icon
              name="fas fa-circle"
              style={{
                fontSize: "10px",
                color: "#2196f3"
              }}
            />
            Aguardando envio
          </React.Fragment>
        );
      case types.STATUS_ANALYSIS:
        return (
          <React.Fragment>
            <Icon
              name="fas fa-circle"
              style={{
                fontSize: "10px",
                color: "#efc63b"
              }}
            />
            Aguardando análise
          </React.Fragment>
        );
      case types.STATUS_APPROVED:
        return (
          <React.Fragment>
            <Icon
              name="fas fa-circle"
              style={{
                fontSize: "10px",
                color: "#39c357"
              }}
            />
            Aprovado
          </React.Fragment>
        );
      case types.STATUS_REJECTED:
        let printText = "";
        let text = "Rejeitado";
        let title = "Rejeitado";

        if (row) {
          if (row.comments) {
            const newText = row.comments.substr(0, 55);
            const length = newText.length;
            text = `Rejeitado : ${newText} ${length > 55 ? "..." : ""}`;
            title = row.comments;
          }
        }

        return (
          <React.Fragment>
            <Icon
              name="fas fa-circle"
              style={{
                fontSize: "10px",
                color: "#eb144c"
              }}
            />
            <span
              style={{
                color: "#eb144c"
              }}
              title={title}
            >
              {text}
            </span>
          </React.Fragment>
        );
      default:
        return null;
    }
  }
}
export default StatusFilter;
