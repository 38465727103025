import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from 'components/SubmitButton';
import formCreator from '../components/formCreator';
import ContractHeaderFooterTemplate from '../components/fieldsGroup/ContractHeaderFooterTemplate';

export class CreateHeaderFooterTemplate extends React.Component {
  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>
        <ContractHeaderFooterTemplate {...this.props} />
        <SubmitButton label="Salvar" isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} />
      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.submit('{company}/header-footer-template', fields);
  }
}

CreateHeaderFooterTemplate.defaultProps = {
  fields: {},
  activeCompany: {},
};

CreateHeaderFooterTemplate.propTypes = {
  activeCompany: PropTypes.object.isRequired,
};

export default formCreator(CreateHeaderFooterTemplate);
