import React from 'react'

import { connect } from 'react-redux'
import moment from 'moment'

import { DOCUMENT_LIST_ID } from '../../constants/documentList'

import { request } from 'core/constants'

import * as commons from 'utils/UtilityStore/actions'
import { cnpjValidation } from 'utils/cnpjValidation'
import { cpfValidation } from 'utils/cpfValidation'
import { goodObject, rawNumber } from 'utils/utils'
import { copyToClipboard } from 'utils/utils'

import { ga } from '../../services/firebase'

import PersonalDataClient from 'features/components/fieldsGroup/PersonalDataClient'
import FullTradingAddress from 'features/components/fieldsGroup/FullTradingAddress'
import PersonalDataSpouse from 'features/components/fieldsGroup/PersonalDataSpouse'
import FullAddress from 'features/components/fieldsGroup/FullAddress'
import RequestRegistration from 'features/RequestRegistration'

import DocumentsRequest from 'components/DocumentsRequest/DocumentsRequest'
import RequestByEmail from 'components/Documents/components/Email'
import ConfirmationMessage from 'components/ConfirmationMessage'

import Modal from 'components/lib/RegisterFormModal'
import ConfirmationModal from 'components/lib/Modal'
import SmallModal from 'components/lib/Modal'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'

import ConfirmExcludeModal from 'components/ConfirmExcludeModal'

import List from './components/List'

import * as actions from './actions'

const styles = {
  requestRegistration: {
    borderWidth: 1,
    borderColor: '#D5DADF',
    borderStyle: 'solid',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    color: '#7F8996',
    fontWeight: 'bold',
    marginRight: '0.5rem',
  },
}

class Clients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastOrder: '',
      orderDirection: '',
      addDocs: props.documentList.map((doc) => ({ name: doc })),
      showExcludeModal: false,
      excludeRowId: null,
      companyInfo: {
        companyName: '',
        themeColor: '',
      },
      success: '',
      message: '',
      modalIsOpen: false,
      isAddition: true,
      modalTitle: '',
      requestRegistration: false,
      smallModal: false,
      confirmationModal: false,
      formIsWorking: false,
      formIsInvalid: false,
      filter: {},
      activeId: '',
      fieldErrors: {},
      clientDocuments: [],
      clientFields: {
        clientName: '',
        clientEmail: '',
        clientDocs: '',
      },
      selected: 0,
      defaultValue: {
        name: '',
        email: '',
        phone: '',
        cpf: '',
        identity_type: '',
        identity: '',
        identity_issuing_body: '',
        identity_expedition_date: '',
        birth_date: '',
        nationality: '',
        marital_status: '',
        marital_status_id: '',
        occupation: '',
        income: '0,00',
        income_source_id: '',
        is_student: false,
        zip_code: '',
        address: '',
        number: '',
        additional_address: '',
        state: '',
        city: '',
        neighborhood: '',
        spouse_name: '',
        spouse_email: '',
        spouse_phone: '',
        spouse_cpf: '',
        spouse_identity_type: '',
        spouse_identity: '',
        spouse_identity_issuing_body: '',
        spouse_identity_expedition_date: '',
        spouse_birth_date: '',
        spouse_nationality: '',
        spouse_occupation: '',
        spouse_is_student: false,
        spouse_status: '',
        estimated_rent_value: 1,
        spouse_income: '0,00',
        spouse_income_source_id: '',
        cnpj: '',
        corporate_name: '',
        trading_name: '',
        municipal_enrollment: '',
        state_enrollment: '',
        typeJuridical: false,
        trading_address_zip_code: '',
        trading_address_address: '',
        trading_address_number: '',
        trading_address_additional_address: '',
        trading_address_state: '',
        trading_address_city: '',
        trading_address_neighborhood: '',
      },
    }

    this.callBack = this.callBack.bind(this)
  }

  componentDidMount() {
    const shouldRefresh = localStorage.getItem('should-refresh')

    if (shouldRefresh === 'true') {
      localStorage.setItem('should-refresh', 'false')
      location.reload()
    }

    this.props.fetch(this.state.filter)
    const companyInfo = {
      companyName: this.props.company.name,
      themeColor: this.props.companyMeta.themeColor,
    }

    this.setState({ companyInfo })
  }

  forward() {
    const isValid = this.validatePersonalData()

    if (!isValid) return

    let { selected } = this.state

    if (selected == 3) return this.registerClient()
    this.setState({ selected: ++selected })
  }

  back() {
    let { selected } = this.state

    if (selected == 0) return null
    this.setState({ selected: --selected })
  }

  close() {
    this.handleModalClose()
  }

  callBack(data) {
    let section = --data

    let selected = this.state.selected
    const isValid = this.validatePersonalData()

    if (!isValid) {
      this.setState({ formIsInvalid: selected })
    } else {
      this.setState({
        selected: section,
        formIsInvalid: false,
      })
    }
  }

  validatePersonalData = () => {
    const {
      name,
      email,
      phone,
      birth_date,
      nationality,
      cpf,
      identity,
      identity_type,
      identity_issuing_body,
      occupation,
      zip_code,
      address,
      number,
      state,
      city,
      neighborhood,
      trading_address_zip_code,
      trading_address_address,
      trading_address_neighborhood,
      trading_address_number,
      trading_address_city,
      trading_address_state,
      spouse_status,
      spouse_name,
      spouse_email,
      spouse_phone,
      spouse_cpf,
      spouse_birth_date,
      spouse_nationality,
      spouse_identity_issuing_body,
      spouse_occupation,
      spouse_identity,
      spouse_identity_type,
      spouse_income,
      spouse_income_source_id,
      cnpj,
      corporate_name,
      typeJuridical,
      marital_status_id,
      income,
      income_source_id,
    } = this.state.defaultValue

    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

    const fieldErrors = {}

    let isValid = true

    if (typeJuridical) {
      if (cnpj && cnpj !== '') {
        const cnpjIsValid = cnpjValidation(cnpj)

        if (!cnpjIsValid) {
          fieldErrors['cnpj'] = ['CNPJ inválido', true]
          isValid = false
        } else {
          delete fieldErrors['cnpj']
        }
      } else {
        fieldErrors['cnpj'] = ['Campo CNPJ é obrigatório', true]
        isValid = false
      }

      if (!corporate_name || corporate_name === '') {
        fieldErrors['corporate_name'] = ['Campo Razão Social é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['corporate_name']
      }
    }

    if (!name || name === '') {
      fieldErrors['name'] = ['Campo Nome é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['name']
    }

    if (!birth_date || birth_date === '') {
      fieldErrors['birth_date'] = ['Campo Data de nascimento é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['birth_date']
    }

    if (!nationality || nationality === '') {
      fieldErrors['nationality'] = ['Campo Nacionalidade é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['nationality']
    }

    if (!identity_type || identity_type === '') {
      fieldErrors['identity_type'] = ['Campo Documento é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['identity_type']
    }

    if (!identity || identity === '') {
      fieldErrors['identity'] = ['Campo Número do documento é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['identity']
    }

    if (!identity_issuing_body || identity_issuing_body === '') {
      fieldErrors['identity_issuing_body'] = [
        'Campo Órgão emissor / UF é obrigatório',
        true,
      ]
      isValid = false
    } else {
      delete fieldErrors['identity_issuing_body']
    }

    if (!occupation || occupation === '') {
      fieldErrors['occupation'] = ['Campo Ocupação é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['occupation']
    }

    if (!emailRegex.test(email)) {
      fieldErrors['email'] = ['Email inválido', true]
      isValid = false
    } else {
      delete fieldErrors['email']
    }

    if (phone && phone !== '') {
      const plainPhone = phone.replace(/\D/g, '')
      const phoneLength = plainPhone.length

      if (phoneLength < 10) {
        fieldErrors['phone'] = ['Telefone inválido', true]
        isValid = false
      } else {
        delete fieldErrors['phone']
      }
    } else {
      fieldErrors['phone'] = ['Campo Telefone é obrigatório', true]
      isValid = false
    }

    if (!marital_status_id || marital_status_id === '') {
      fieldErrors['marital_status_id'] = ['Campo Estado civil é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['marital_status_id']
    }

    if (
      (!income || income === '' || income == '0,00') &&
      income_source_id != '1' &&
      income_source_id != ''
    ) {
      fieldErrors['income'] = ['Campo Renda é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['income']
    }

    if (!income_source_id || income_source_id === '') {
      fieldErrors['income_source_id'] = ['Campo Fonte de renda é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['income_source_id']
    }

    if (cpf && cpf !== '') {
      const plainCPF = cpf.replace(/\D/g, '')
      const cpfIsValid = cpfValidation(plainCPF)

      if (!cpfIsValid) {
        fieldErrors['cpf'] = ['CPF inválido', true]
        isValid = false
      } else {
        delete fieldErrors['cpf']
      }
    } else {
      fieldErrors['cpf'] = ['Campo CPF é obrigatório', true]
      isValid = false
    }

    // valida apenas no passo 1
    if (this.state.selected == 1) {
      if (!zip_code || zip_code === '') {
        fieldErrors['zip_code'] = ['Campo CEP é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['zip_code']
      }

      if (!address || address === '') {
        fieldErrors['address'] = ['Campo Logradouro é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['address']
      }

      if (!number || number === '') {
        fieldErrors['number'] = ['Campo Número é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['number']
      }

      if (!state || state === '') {
        fieldErrors['state'] = ['Campo Estado é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['state']
      }

      if (!city || city === '') {
        fieldErrors['city'] = ['Campo Cidade é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['city']
      }

      if (!neighborhood || neighborhood === '') {
        fieldErrors['neighborhood'] = ['Campo Bairro é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['neighborhood']
      }

      if (typeJuridical) {
        if (!trading_address_zip_code || trading_address_zip_code === '') {
          fieldErrors['trading_address_zip_code'] = ['Campo CEP é obrigatório', true]
          isValid = false
        } else {
          delete fieldErrors['trading_address_zip_code']
        }

        if (!trading_address_address || trading_address_address === '') {
          fieldErrors['trading_address_address'] = [
            'Campo Logradouro é obrigatório',
            true,
          ]
          isValid = false
        } else {
          delete fieldErrors['trading_address_address']
        }

        if (!trading_address_number || trading_address_number === '') {
          fieldErrors['trading_address_number'] = [
            'Campo Número é obrigatório',
            true,
          ]
          isValid = false
        } else {
          delete fieldErrors['trading_address_number']
        }

        if (!trading_address_state || trading_address_state === '') {
          fieldErrors['trading_address_state'] = ['Campo Estado é obrigatório', true]
          isValid = false
        } else {
          delete fieldErrors['trading_address_state']
        }

        if (!trading_address_city || trading_address_city === '') {
          fieldErrors['trading_address_city'] = ['Campo Cidade é obrigatório', true]
          isValid = false
        } else {
          delete fieldErrors['trading_address_city']
        }

        if (!trading_address_neighborhood || trading_address_neighborhood === '') {
          fieldErrors['trading_address_neighborhood'] = [
            'Campo Bairro é obrigatório',
            true,
          ]
          isValid = false
        } else {
          delete fieldErrors['trading_address_neighborhood']
        }
      }
    }

    // Verifica cônjuge
    if (spouse_status && this.state.selected === 2) {
      if (!spouse_name || spouse_name === '') {
        fieldErrors['spouse_name'] = ['Campo Nome é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_name']
      }

      if (!spouse_birth_date || spouse_birth_date === '') {
        fieldErrors['spouse_birth_date'] = [
          'Campo Data de nascimento é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_birth_date']
      }

      if (!spouse_nationality || spouse_nationality === '') {
        fieldErrors['spouse_nationality'] = [
          'Campo Nacionalidade é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_nationality']
      }

      if (!spouse_identity_type || spouse_identity_type === '') {
        fieldErrors['spouse_identity_type'] = ['Campo Documento é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_identity_type']
      }

      if (!spouse_identity || spouse_identity === '') {
        fieldErrors['spouse_identity'] = [
          'Campo Número do documento é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_identity']
      }

      if (!spouse_identity_issuing_body || spouse_identity_issuing_body === '') {
        fieldErrors['spouse_identity_issuing_body'] = [
          'Campo Órgão emissor / UF é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_identity_issuing_body']
      }

      if (!spouse_occupation || spouse_occupation === '') {
        fieldErrors['spouse_occupation'] = ['Campo Ocupação é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_occupation']
      }

      if (!emailRegex.test(spouse_email)) {
        fieldErrors['spouse_email'] = ['Email inválido', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_email']
      }

      if (spouse_phone && spouse_phone !== '') {
        const plainPhone = spouse_phone.replace(/\D/g, '')
        const phoneLength = plainPhone.length

        if (phoneLength < 10) {
          fieldErrors['spouse_phone'] = ['Telefone inválido', true]
          isValid = false
        } else {
          delete fieldErrors['spouse_phone']
        }
      } else {
        fieldErrors['spouse_phone'] = ['Campo Telefone é obrigatório', true]
        isValid = false
      }

      if (
        (!spouse_income || spouse_income === '' || spouse_income == '0,00') &&
        spouse_income_source_id != '1' &&
        spouse_income_source_id != ''
      ) {
        fieldErrors['spouse_income'] = ['Campo Renda é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_income']
      }

      if (!spouse_income_source_id || spouse_income_source_id === '') {
        fieldErrors['spouse_income_source_id'] = [
          'Campo Fonte de renda é obrigatório',
          true,
        ]
        isValid = false
      } else {
        delete fieldErrors['spouse_income_source_id']
      }

      if (spouse_cpf && spouse_cpf !== '') {
        const plainCPF = spouse_cpf.replace(/\D/g, '')
        const cpfIsValid = cpfValidation(plainCPF)

        if (!cpfIsValid) {
          fieldErrors['spouse_cpf'] = ['CPF inválido', true]
          isValid = false
        } else {
          delete fieldErrors['spouse_cpf']
        }
      } else {
        fieldErrors['spouse_cpf'] = ['Campo CPF é obrigatório', true]
        isValid = false
      }
    }

    this.setState({ fieldErrors })

    return isValid
  }

  validateNewClient = () => {
    const { clientName, clientEmail } = this.state.clientFields

    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

    const fieldErrors = {
      name: [],
      email: [],
    }

    let isValid = true

    if (clientName === '') {
      fieldErrors['name'] = ['Nome inválido', true]
      isValid = false
    } else {
      delete fieldErrors['name']
    }

    if (!emailRegex.test(clientEmail)) {
      fieldErrors['email'] = ['Email inválido', true]
      isValid = false
    } else {
      delete fieldErrors['email']
    }

    this.setState({ fieldErrors })

    return isValid
  }

  handleChange(name, value) {
    const { defaultValue } = this.state
    const updatedFields = { ...defaultValue }

    if (name === 'typeJuridical') {
      updatedFields['typeJuridical'] = value
      if (value == false) {
        updatedFields['cnpj'] = ''
        updatedFields['corporate_name'] = ''
        updatedFields['trading_name'] = ''
        updatedFields['municipal_enrollment'] = ''
        updatedFields['state_enrollment'] = ''
        updatedFields['trading_address_zip_code'] = ''
        updatedFields['trading_address_neighborhood'] = ''
        updatedFields['trading_address_city'] = ''
        updatedFields['trading_address_address'] = ''
        updatedFields['trading_address_state'] = ''
        updatedFields['trading_address_number'] = ''
        updatedFields['trading_address_additional_address'] = ''
      }

      return this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
    }

    if (name === 'spouse_status') {
      updatedFields['spouse_status'] = value
      if (value == false) {
        updatedFields['spouse_name'] = ''
        updatedFields['spouse_cpf'] = ''
        updatedFields['spouse_birth_date'] = ''
        updatedFields['spouse_email'] = ''
        updatedFields['spouse_identity'] = ''
        updatedFields['spouse_identity_issuing_body'] = ''
        updatedFields['spouse_identity_type'] = ''
        updatedFields['spouse_identity_expedition_date'] = ''
        updatedFields['spouse_nationality'] = ''
        updatedFields['spouse_occupation'] = ''
        updatedFields['spouse_phone'] = ''
        updatedFields['spouse_income_source_id'] = ''
        updatedFields['spouse_income'] = ''
      }

      return this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
    }

    if (name === 'fullAddress') {
      updatedFields['zip_code'] = value['cep'] || ''
      updatedFields['neighborhood'] = value['bairro'] || ''
      updatedFields['city'] = value['localidade'] || ''
      updatedFields['address'] = value['logradouro'] || ''
      updatedFields['state'] = value['uf'] || ''

      return this.setState({
        defaultValue: updatedFields,
      })
    }

    if (name === 'trading_address') {
      updatedFields['trading_address_zip_code'] = value['cep'] || ''
      updatedFields['trading_address_neighborhood'] = value['bairro'] || ''
      updatedFields['trading_address_city'] = value['localidade'] || ''
      updatedFields['trading_address_address'] = value['logradouro'] || ''
      updatedFields['trading_address_state'] = value['uf'] || ''

      return this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
    }

    updatedFields[name] = value
    this.setState({
      fields: updatedFields,
      defaultValue: updatedFields,
    })
  }

  registerClient = () => {
    const { defaultValue } = this.state

    const requestData = { ...defaultValue }

    const address = {
      additional_address: requestData['additional_address'],
      address: requestData['address'],
      city: requestData['city'],
      neighborhood: requestData['neighborhood'],
      number: requestData['number'],
      state: requestData['state'],
      zip_code: requestData['zip_code'],
    }

    requestData['address'] = address

    const trading_address = {
      additional_address: requestData['trading_address_additional_address'],
      address: requestData['trading_address_address'],
      city: requestData['trading_address_city'],
      neighborhood: requestData['trading_address_neighborhood'],
      number: requestData['trading_address_number'],
      state: requestData['trading_address_state'],
      zip_code: requestData['trading_address_zip_code'],
    }

    delete requestData.trading_address_additional_address
    delete requestData.trading_address_address
    delete requestData.trading_address_city
    delete requestData.trading_address_neighborhood
    delete requestData.trading_address_number
    delete requestData.trading_address_state
    delete requestData.trading_address_zip_code

    requestData['trading_address'] = trading_address

    let goodFields = goodObject(requestData, {
      income: {
        path: 'income',
        format: (value) => rawNumber(value),
      },
      spouse_income: {
        path: 'spouse_income',
        format: (value) => rawNumber(value),
      },
      identity_expedition_date: {
        path: 'identity_expedition_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      spouse_identity_expedition_date: {
        path: 'spouse_identity_expedition_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      birth_date: {
        path: 'birth_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      spouse_birth_date: {
        path: 'spouse_birth_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      marital_status: {
        path: 'marital_status',
        format: (value) => +value,
      },
      estimated_rent_value: {
        path: 'estimated_rent_value',
        format: (value) => rawNumber(value),
      },
    })

    return new Promise((resolve, reject) => {
      this.setState({ formIsWorking: true })
      const companyId = this.props.company.id
      const url = `/company/${companyId}/client`

      request
        .post(url, goodFields)
        .then((response) => {
          const getClientsUrl = `/company/${companyId}/client?`

          request.get(getClientsUrl)
          resolve([
            this.setState(
              {
                formIsWorking: false,
                confirmationModal: true,
                success: true,
                message: 'Cliente cadastrado com sucesso!',
                createdClientId: response.data.id,
              },
              () => {
                const gaPayload = {
                  company_id: companyId,
                  company_name: this.props.company.name,
                }

                ga.logEvent('create_client', gaPayload)

                this.handleNewDocs()
              },
            ),
            setTimeout(() => {
              this.setState(
                {
                  confirmationModal: false,
                  modalTitle: '',
                  modalIsOpen: false,
                },
                () => {
                  this.updateClientList()
                },
              )
            }, 2500),
          ])
        })
        .catch((err) => {
          const { errors } = err
          const message = errors[0].message

          reject([
            this.setState({
              formIsWorking: false,
              confirmationModal: true,
              success: false,
              message: message,
            }),
            setTimeout(() => {
              this.setState({
                confirmationModal: false,
              })
            }, 2500),
          ])
        })
    })
  }

  async handleNewDocs() {
    const { addDocs } = this.state

    if (addDocs === []) return

    const docsUrl = '/entity/4/upload/file'

    for (let i in addDocs) {
      const data = new FormData()

      data.append('name', addDocs[i].name)
      data.append('owner_document', this.state.createdClientId)
      data.append('entity_id', '4')
      data.append('status', 'STATUS_WAITING')

      await request.post(docsUrl, data).catch((error) => {
        console.log('error', error)
      })
    }
  }

  updateClientList() {
    setTimeout(() => {
      this.props.fetch(this.state.filter)
    }, 500)
  }

  render() {
    const formConfig = {
      selected: this.state.selected,
      sections: [
        {
          formTitle: 'Cadastrar cliente',
          buttonTitle: 'Avançar',
          secondButtonTitle: 'Solicitar Edição',
          progressBar: true,
          sectionNumber: '1',
          sectionTitle: 'Dados do(a) cliente',
          progressTitle: 'Dados',
        },
        {
          formTitle: 'Cadastrar cliente',
          buttonTitle: 'Avançar',
          secondButtonTitle: 'Solicitar Edição',
          progressBar: true,
          sectionNumber: '2',
          sectionTitle: 'Endereço do(a) cliente',
          progressTitle: 'Endereço',
        },
        {
          formTitle: 'Cadastrar cliente',
          buttonTitle: 'Avançar',
          secondButtonTitle: 'Solicitar Edição',
          progressBar: true,
          sectionNumber: '3',
          sectionTitle: 'Dados do(a) Cônjuge',
          progressTitle: 'Cônjuge',
        },
        {
          formTitle: 'Cadastrar cliente',
          buttonTitle: 'Concluir',
          progressBar: true,
          sectionNumber: '4',
          sectionTitle: 'Documentação',
          progressTitle: 'Documentos',
        },
      ],
    }

    const formConfigRequestReg = {
      selected: this.state.selected,
      sections: [
        {
          formTitle: 'Solicitar cadastro de cliente',
          buttonTitle: 'Solicitar cadastro',
          customBody: true,
          progressBar: false,
          sectionNumber: '',
          sectionTitle: '',
          progressTitle: '',
        },
      ],
    }
    const url = location.origin + '/public/' + this.props.company.public_token
    const { selected } = formConfig

    let pageWidth = document.body.offsetWidth

    const { companyInfo } = this.state

    const hideForwardButton = this.state.selected == 3
    const hideBackButton = this.state.selected == 0

    const { typeJuridical } = this.state.defaultValue

    return (
      <>
        {this.state.showExcludeModal && (
          <ConfirmExcludeModal
            title='Excluir Cliente?'
            text='Confirmar exclusão deste Cliente?'
            onClose={() =>
              this.setState({
                showExcludeModal: false,
                excludeRowId: null,
              })
            }
            onExclude={this.excludeRow.bind(this, this.state.excludeRowId)}
          />
        )}

        <List
          customHeader
          pageWidth={pageWidth}
          listName='Clientes'
          items={this.props.items}
          pagination={this.props.listInfo}
          isWorking={this.props.isWorking}
          handleEditRow={this.openRow.bind(this)}
          handleExcludeRow={this.openExcludeModal.bind(this)}
          onChangePage={this.handlePagination.bind(this)}
          onSearch={this.searchList.bind(this)}
          orderBy={this.orderBy.bind(this)}
          lastOrder={this.state.lastOrder}
          orderDirection={this.state.orderDirection}
          isMedium={true}
          actionButton={
            <div className='buttons'>
              <Button
                onClick={this.requestRegistration.bind(this)}
                isFlat
                style={styles.requestRegistration}
              >
                Solicitar Novo
              </Button>
              <Button onClick={this.handleModal.bind(this)} isFlat hasThemeColor>
                Adicionar cliente
              </Button>
              <Button
                onClick={this.handlePublicAppUrl.bind(this, url)}
                isFlat
                title='Pré-cadastro'
                isTab
              >
                <Icon name='fas fa-address-card' />
              </Button>
            </div>
          }
        />

        {this.state.isAddition ? (
          <Modal
            isApp
            isOpen={this.state.modalIsOpen}
            hideForwardButton={hideForwardButton}
            hideBackButton={hideBackButton}
            forward={this.forward.bind(this)}
            back={this.back.bind(this)}
            close={this.close.bind(this)}
            onClose={this.handleModalClose.bind(this)}
            formConfig={formConfig}
            isLoading={this.state.formIsWorking}
            isInvalid={this.state.formIsInvalid}
            callBack={this.callBack}
            companyInfo={companyInfo}
            push={this.props.history.push}
            activeUser={this.props.activeUser.id}
            activeCompany={this.props.activeCompany.id}
          >
            {selected === 0 && (
              <PersonalDataClient
                {...this.state}
                handleChange={this.handleChange.bind(this)}
                showEstimatedRentValue={true}
                isMultiStep={true}
              />
            )}
            {selected === 1 && (
              <div>
                {typeJuridical && (
                  <FullTradingAddress
                    {...this.state}
                    handleChange={this.handleChange.bind(this)}
                    isRequired
                  />
                )}
                <FullAddress
                  {...this.state}
                  handleChange={this.handleChange.bind(this)}
                  isMultiStep
                  isRequired
                />
              </div>
            )}
            {selected === 2 && (
              <PersonalDataSpouse
                {...this.state}
                handleChange={this.handleChange.bind(this)}
                isMultiStep={true}
                isRequired
              />
            )}
            {selected === 3 && (
              <div className='docsInput'>
                <DocumentsRequest
                  title={'Solicitar documentos'}
                  defaultMultiOption={this.props.documentList}
                  onChange={(docs) => this.setState({ addDocs: docs })}
                />
              </div>
            )}
          </Modal>
        ) : (
          <Modal
            noTitleMobile
            isOpen={this.state.requestRegistration}
            hideForwardButton={true}
            hideBackButton={true}
            forward={this.handleRegisterClient.bind(this)}
            close={this.requestRegistrationClose.bind(this)}
            onClose={this.requestRegistrationClose.bind(this)}
            formConfig={formConfigRequestReg}
            isLoading={this.state.formIsWorking}
            companyInfo={companyInfo}
          >
            <RequestRegistration
              {...this.props}
              {...this.state}
              sendClient={this.selectClient.bind(this)}
            />
          </Modal>
        )}

        <SmallModal
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          backgroundContentStyle={{ display: 'block' }}
          isOpen={this.state.smallModal}
          onClose={this.smallModalClose.bind(this)}
        >
          <RequestByEmail
            fullForm
            noMessagebox
            requestForm
            confirmationTrue
            title='Como você deseja solicitar o cadastro?'
            userId={this.state.activeId}
            companyId={this.props.company.id}
            entityName={'client'}
            documents={this.state.clientDocuments}
          />
        </SmallModal>

        <ConfirmationModal
          disableClose={!!this.state.success}
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          backgroundContentStyle={{ display: 'block' }}
          isOpen={this.state.confirmationModal}
        >
          <ConfirmationMessage
            success={this.state.success}
            message={this.state.message}
          />
        </ConfirmationModal>
      </>
    )
  }

  searchList(keywords) {
    const filter = { ...this.state.filter, ...{ search: keywords } }

    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  orderBy(order) {
    const filter = { ...this.state.filter, page: 1 }

    let orderDirection = ''

    if (this.state.lastOrder == order) {
      if (this.state.orderDirection == 'desc') {
        this.setState({ orderDirection: 'asc' })
        orderDirection = 'asc'
      } else if (this.state.orderDirection == 'asc') {
        this.setState({ orderDirection: 'desc' })
        orderDirection = 'desc'
      }
    } else {
      this.setState({ orderDirection: 'asc' })
      orderDirection = 'asc'
    }

    this.props.fetch(filter, order, orderDirection)
    this.setState({ lastOrder: order })
  }

  handlePagination(page) {
    const filter = { ...this.state.filter, ...{ page: page } }

    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  selectClient(name, e) {
    const updatedFields = { ...this.state.clientFields }

    updatedFields[name] = e
    this.setState({ clientFields: updatedFields })
  }

  handleRegisterClient() {
    const isValid = this.validateNewClient()

    this.setState({ formIsWorking: true })

    if (!isValid) return

    const companyId = this.props.company.id
    const url = `/company/${companyId}/client`
    const { clientFields } = this.state
    const data = {
      name: clientFields.clientName,
      email: clientFields.clientEmail,
    }

    request
      .post(url, data)
      .then((res) => {
        this.setState(
          {
            activeId: res.data.id,
          },
          async () => {
            this.updateClientList()

            const docsUrl = '/entity/4/upload/file'

            const {
              clientFields: { clientDocs },
            } = this.state

            let requests = clientDocs.map(
              (document) =>
                new Promise((resolve) => {
                  const data = new FormData()

                  data.append('name', document.name)
                  data.append('owner_document', this.state.activeId)
                  data.append('entity_id', '4')
                  data.append('status', 'STATUS_WAITING')

                  resolve(
                    request.post(docsUrl, data).catch((error) => {
                      console.log('error', error)
                    }),
                  )
                }),
            )

            await Promise.all(requests).then(() => {
              this.setState({
                formIsWorking: false,
                smallModal: true,
                success: true,
                message: 'Cliente cadastrado com sucesso!',
              })

              setTimeout(() => {
                this.setState({
                  confirmationModal: false,
                  modalIsOpen: false,
                  modalTitle: '',
                })
              }, 2500)
            })
          },
        )
      })
      .catch((error) => {
        const { errors } = error
        const message = errors[0].message

        this.setState({
          formIsWorking: false,
          confirmationModal: true,
          success: false,
          message: message,
        })
      })
  }

  requestRegistration() {
    this.setState({
      isAddition: false,
      modalTitle: 'Solicitar Cadastro de cliente',
      requestRegistration: true,
    })
  }

  handleModal() {
    this.setState({
      isAddition: true,
      modalIsOpen: true,
      modalTitle: 'Adicionar cliente',
    })
  }

  requestRegistrationClose() {
    this.setState(
      {
        modalTitle: 'Solicitar Cadastro de proprietário',
        requestRegistration: false,
      },
      () => {
        this.updateClientList()
      },
    )
  }

  handleModalClose() {
    this.setState(
      {
        modalTitle: '',
        modalIsOpen: false,
      },
      () => {
        this.updateClientList()
      },
    )
  }

  smallModalClose() {
    this.setState(
      {
        smallModal: false,
      },
      () => {
        this.updateClientList()
      },
    )
  }

  handlePublicAppUrl(url) {
    copyToClipboard(url)
    setTimeout(() => {
      this.props.notification({
        message: 'Url copiada para área de transferência',
      })
    }, 100)
  }

  openRow(row) {
    this.props.history.push(this.props.location.pathname + '/' + row.id)
  }

  openExcludeModal(row) {
    this.setState({
      showExcludeModal: true,
      excludeRowId: row.id,
    })
  }

  async excludeRow(rowId) {
    try {
      const deleteUrl = `/company/${this.props.activeCompany.id}/client/${rowId}`

      await request.delete(deleteUrl)
      this.props.notification({
        message: 'Registro excluído com sucesso',
        status: 'success',
      })
      this.props.fetch(this.state.filter)
    } catch (erro) {
      this.props.notification({
        message: erro.message || 'Erro ao tentar excluir o Cliente',
        status: 'danger',
      })
    }
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    items: store.clients.items,
    listInfo: store.clients.listInfo,
    isWorking: store.commons.isWorking.clients,
    documentList:
      store.app.companyMeta?.documentLists?.find(
        (documentList) => documentList.id === DOCUMENT_LIST_ID.CREATE_CLIENT,
      )?.list || [],
  }),
  { ...actions, ...commons },
)(Clients)
