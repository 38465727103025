import React from 'react';
import PropTypes from 'prop-types';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';
import Title from 'components/lib/Title';

const Header = (props) => {
  const headerCSSClass = `header ${parsePropsAsBulmaClasses(props)}`;

  return (
    <header className={headerCSSClass}>
      <div className={props?.isBetween && 'flex-between'}>
        <Title {...props} isH1>
          {props.children}
        </Title>
        {props.rightComponent && <props.rightComponent />}
      </div>
      {props.subtitle ? <Title isH2>{props.subtitle}</Title> : null}
    </header>
  );
};

Header.defaultProps = {
  children: null,
};

Header.propTypes = {
  children: PropTypes.any,
};

export default Header;
