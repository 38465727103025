import React from 'react';
import { Callout } from '@blueprintjs/core';
import { format } from 'date-fns';
import Button from 'components/lib/Button';

const readFile = (file) =>
  new Promise((then) => {
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      const fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + ' kB',
        base64: reader.result,
        file,
        date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      };
      then(fileInfo);
    };
  });

export default ({
  disabled = false,
  instantUpload = false,
  folder = 'files',
  title = 'Upload',
  titleUploading = 'Enviando...',
  uploadMethod,
  onError,
  afterUpload,
  hideUploadList = false,
  style,
  buttonStyle,
  ...rest
}) => {
  const [uploadRef, setUploadRef] = React.useState();
  const [uploading, setUploading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [uploadingFile, setUploadingFile] = React.useState(null);

  const uploadFile = async ({ target }) => {
    const files = target.files;
    const items = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      items.push(await readFile(file));
      if (instantUpload) {
        setFiles(items);
        await startUpload();
        setFiles([]);
      }
    }
    setFiles(items);
  };

  const startUpload = async () => {
    const [file] = files;
    if (!file) {
      return;
    }
    try {
      let content = null;
      setUploading(true);
      if (uploadMethod) {
        content = await uploadMethod(file);
      } else {
        // content = await service.upload({ file: file.file, folder })
      }
      afterUpload && afterUpload(content);
    } catch (error) {
      if (!!onError) {
        onError(error);
      } else {
        setError({ filename: file.name, message: error.message });
        setTimeout(() => setError(null), 6000);
      }
    }
    setUploading(false);
    setFiles(files.slice(1));
  };

  const [currentFile] = files;

  if (!!currentFile && (!uploadingFile || currentFile.date !== uploadingFile.date)) {
    startUpload();
    setUploadingFile(currentFile);
  }

  return (
    <div style={style}>
      <Button isLoading={uploading} style={buttonStyle} intent="primary" isDisabled={uploading || disabled} onClick={() => uploadRef.click()} {...rest}>
        {uploading ? titleUploading : title}
      </Button>
      <input multiple ref={setUploadRef} type="file" accept="image/*" style={{ display: 'none' }} onChange={uploadFile} />
      {error && (
        <Callout title={error.filename} intent="danger">
          {error.message}
        </Callout>
      )}
    </div>
  );
};
