import { connect } from 'react-redux';
import { ROLE } from '../constants';

const RoleType = {
  none: 0,
  admin: 1,
  other: 2
}

const hasRoles = (company, roleItems, allRolesTogether = false) => {
  const profiles = company?.profiles || [];
  if (profiles.find((profile) => [ROLE.PROFILE_IZEE_ADMIN, ROLE.PROFILE_COMPANY_OWNER_PARTNER, ROLE.PROFILE_ADMIN].includes(profile.code))) {
    return RoleType.admin;
  }
  
  const roles = Array.isArray(roleItems) ? roleItems : [roleItems];
  const validProfiles = profiles.filter((profile) => roles.includes(profile.code));

  return ((validProfiles.length > 0) && (!allRolesTogether || (profiles.length === roles.length))) ? RoleType.other : RoleType.none
};

export default (...params) => {
  const [callback, actions] = params;

  const isFunction = typeof callback === 'function';
  const data = isFunction ? actions : callback;

  return connect(
    (store) => ({
      historyPush: store.app.helpers?.historyPush,
      app: store.app,
      company: store.app.activeCompany,
      isWorking: store.commons.isWorking,
      isAgent: hasRoles(store.app.activeCompany, ROLE.PROFILE_AGENT, true) === RoleType.other,
      onlyAdmin: hasRoles(store.app.activeCompany, []) === RoleType.admin,
      forbiddenAccess: (roles, allRolesTogether = false) => hasRoles(store.app.activeCompany, roles, allRolesTogether) === RoleType.other,
      grantedAccess: (roles, allRolesTogether = false) => hasRoles(store.app.activeCompany, roles, allRolesTogether) !== RoleType.none,
      ...(isFunction ? callback(store) : {}),
    }),
    { ...(data || {}) }
  );
};
