import React from 'react';
import Text from 'components/lib/Text';

const Negotiation = ({ children }) => (
  <Text>
    <span style={{ whiteSpace: 'pre-line' }}>
      {children}
    </span>
  </Text>
);

export default Negotiation;
