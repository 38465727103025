import React from 'react';
import PropTypes from 'prop-types';
// import Typed from 'react-typed';

import logoIzee from 'static/media/logo-izee.png';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Assistant = (props) => {

  let assistantCSSClass = `assistant ${
    parsePropsAsBulmaClasses(props)
  }`;

  let bubbleCSSClass = 'assistant-speech-bubble is-bottom';

  return (
    <div className={assistantCSSClass} style={props.style}>
      <div className="assistant-icon"><img src={logoIzee} alt={props.words} /></div>
      <div className={bubbleCSSClass}>
        {props.words}
        {/* <Typed
          strings={[props.words]}
          typeSpeed={40}
        /> */}
      </div>
    </div>
  );

};

Assistant.propTypes = {
  words: PropTypes.string.isRequired
};

export default Assistant;
