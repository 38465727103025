import React from 'react';

import { connect } from 'react-redux';
import * as actions from './actions';
import * as commons from 'utils/UtilityStore/actions';
import AddDocument from '../Documents/AddDocument.js';
import documentList from './documentList.js';

// Components
import BodyContainer from 'components/lib/BodyContainer';
import withModal from 'components/HOCs/withModal';
import CreateOrUpdateGuarantor from 'features/CreateOrUpdateGuarantor';

class GuarantorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: {},
      documentsLength: 0,
      companyInfo: {
        companyName: '',
        themeColor: '',
      },
    };
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.guarantorId);
    const companyInfo = {
      companyName: this.props.activeCompany.name,
      themeColor: this.props.companyMeta.themeColor,
    };
    this.setState({ companyInfo });
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    let current = this.props.current;
    if (current.address) {
      current = { ...current.address, ...current };
      current.address = current.address.address;
      current.number = parseInt(current.number);
    }

    let trading_address = {};

    if (current.trading_address) {
      trading_address = {
        trading_address_zip_code: current.trading_address.zip_code,
        trading_address_address: current.trading_address.address,
        trading_address_number: parseInt(current.trading_address.number),
        trading_address_additional_address: current.trading_address.additional_address,
        trading_address_state: current.trading_address.state,
        trading_address_city: current.trading_address.city,
        trading_address_neighborhood: current.trading_address.neighborhood,
      }
    }

    current = { ...trading_address, ...current };

    Object.keys(current).forEach((key) => {
      if (current[key] === null) {
        current[key] = '';
      }
    });

    const hasData = Object.keys(current).length > 0;

    const { companyInfo } = this.state;

    return (
      <BodyContainer isLoading={this.props.isWorking['guarantor']}>
        {hasData ? (
          <CreateOrUpdateGuarantor
            {...this.props }
            {...this.props.history}
            defaultValue={current}
            activeUser={this.props.activeUser.id}
            activeCompany={this.props.activeCompany.id}
            guarantorId={this.props.match.params.guarantorId}
            isUpdate
            companyInfo={companyInfo}
          />
        ) : null}
      </BodyContainer>
    );
  }

  renderDocuments() {
    const { guarantorId } = this.props.match.params;
    return documentList.map((item) => (
      <AddDocument
        name={item.name}
        onUpload={this.updateFileList.bind(this, item.name)}
        onAddFile={this.onAddFile.bind(this)}
        onInit={this.onAddFile.bind(this)}
        keyId={guarantorId}
        typeRegister={'guarantor'}
        key={item.id}
      />
    ));
  }

  onAddFile(documents) {
    let length = documents.length + this.state.documentsLength;
    this.setState({ documentsLength: length });
  }

  updateFileList(listName, fileList) {
    const documents = this.state.documents;
    documents[listName] = fileList;
    this.setState({ documents });
  }

  handleUpdateSuccess() {
    this.props.notification({
      message: 'Dados do fiador atualizados com sucesso',
    });
  }
}

export default connect(
  (store) => ({
    current: store.guarantor.current,
    isWorking: store.commons.isWorking,
  }),
  { ...actions, ...commons }
)(withModal(GuarantorDetails));
