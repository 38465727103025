import React from "react";
import { FormFieldTextArea } from "components/lib/FormFields";

const MessageBox = props => (
  <FormFieldTextArea
  		style={props.style}
  		labelStyle={props.labelStyle}
   	label={props.title}
   	onChange={message => props.message(message)}
  />
);

export default MessageBox;
