import { uuid } from 'utils/utils'

const initialState = {
  isSuccess: null,
  actionMessage: '',
  actionKey: uuid(),
  isProcessing: false,
  errors: [],
  data: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SUBMIT_FORM_IS_PROCESSING':
      return {
        ...state,
        ...{ isProcessing: true },
      }

    case 'SUBMIT_FORM_SUCCESS':
      return {
        ...state,
        ...{
          isSuccess: true,
          actionMessage: action?.payload?.message,
          actionKey: uuid(),
          isProcessing: false,
          errors: [],
          data: action?.payload,
        },
      }

    case 'SUBMIT_FORM_ERROR':
      return {
        ...state,
        ...{
          isSuccess: false,
          actionMessage: action?.payload?.message,
          actionKey: uuid(),
          isProcessing: false,
          errors: action?.payload?.errors,
          data: action?.payload,
        },
      }

    default:
      return state
  }
}
