import React from 'react'

import Table from 'components/lib/Table'
import StatusField from './StatusField'

import FileButton from './FileButton'
import FileName from './FileName'
import { FormFieldCheckbox } from 'components/lib/FormFields'

import {
  STATUS_WAITING,
  STATUS_ANALYSIS,
  STATUS_APPROVED,
  STATUS_REJECTED,
} from '../types'

const statusIndexMap = {
  [STATUS_ANALYSIS]: 0,
  [STATUS_APPROVED]: 1,
  [STATUS_WAITING]: 2,
  [STATUS_REJECTED]: 3,
}

class Component extends React.Component {
  renderButtons(row) {
    return <FileButton {...this.props} row={row} />
  }

  renderCheckBox(content) {
    const { selected } = this.props
    const checked = selected.indexOf(content) === -1 ? false : true

    return (
      <FormFieldCheckbox
        checked={checked}
        onClick={() => this.props.handleSelectRow(content)}
      />
    )
  }

  handleRowClick(row) {
    window.open(row.new_source, '_blank')
  }

  renderStatus(content, row) {
    return <StatusField content={content} row={row} {...this.props} />
  }

  renderFileName(content, row) {
    return <FileName content={content} row={row} {...this.props} />
  }

  render() {
    const { items = [], allChecked, hideUpload = false } = this.props

    items.forEach((item) => {
      if (!item.index) {
        item.index =
          statusIndexMap?.[item?.status] !== undefined
            ? statusIndexMap[item?.status]
            : item.index
      }
    })

    items.sort((a, b) => a.index - b.index)

    const cells = [
      {
        name:
          document.body.offsetWidth < 1025 ? (
            'Selecionar'
          ) : (
            <FormFieldCheckbox
              checked={allChecked}
              onClick={() => {
                this.props.handleSelectAll()
              }}
            />
          ),
        render: (content) => this.renderCheckBox(content),
      },
      {
        name: 'Documentos',
        width: '20%',
        render: (content) => content,
      },
      {
        name: 'Status',
        width: '25%',
        render: (content, row) => this.renderStatus(content, row),
      },
      {
        name: 'Arquivo',
        width: '25%',
        render: (content, row) => this.renderFileName(content, row),
      },
      {
        name: 'Envio de documentos',
        width: '30%',
        render: (content, row) => this.renderButtons(row),
        remove: hideUpload,
      },
    ].filter((x) => !x.remove)

    return (
      <Table
        items={items}
        cells={cells}
        emptyState={
          items.length > 0 ? (
            true
          ) : (
            <div>
              <p>Não há documentos solicitados.</p> <br></br>{' '}
              <p>
                Você pode escrever o nome do documento no campo acima e clicar no
                sinal de +
              </p>
            </div>
          )
        }
      />
    )
  }
}

export default Component
