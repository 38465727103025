import React from 'react';
import ReactQuill from 'react-quill';

let Embed = ReactQuill.Quill.import('blots/embed');

export default class MergeBlot extends Embed {
  static create(propriedades) {
    let node = super.create();
    const id = String(propriedades['data-id']);
    const name = String(propriedades['data-name']);
    const object = String(propriedades['data-object']);
    const attribute = String(propriedades['data-attribute']);

    node.dataset.id = id;
    node.dataset.name = name;
    node.dataset.object = object;
    node.dataset.attribute = attribute;

    node.innerHTML = `${name}`;
    node.contentEditable = 'false';
    return node;
  }

  static value(domNode) {
    return [].slice.apply(domNode.attributes).reduce((all, { name, value }) => ({ ...all, [name]: value }), {});
  }
}

MergeBlot.blotName = 'merge-blot';
MergeBlot.tagName = 'span';
MergeBlot.className = 'merge-blot';
