import React from 'react';
import SectionWrapper from 'components/SectionWrapper';
import { SectionStatus } from './styles';
import Tooltip from 'components/lib/Tooltip';
import Button from 'components/lib/Button';

// import Button from 'components/lib/Button';
// import Profile from 'components/Profile';

const SectionItem = (props) => {
  const { item } = props;
  const { contain, evaluation, icon, open, title, type, info } = item;

  const [isOpen, setOpen] = React.useState(open);

  return (
    <div className={`section-item ${type} open-${isOpen} ${evaluation && `evaluation-status`}`}>
      <div className={`si-header`} onClick={() => setOpen(!isOpen)}>
        <p className={`si-item-icon`}>
          <i className={`fas ${icon}`}></i>
        </p>
        <p className={`si-item-name`}>{title} 
        {info ? 
        <Tooltip description={info} placement="right-start">
          <button style={{marginLeft: '5px'}} type="button" className="button  is-bare ml-2"><span class="icon is-info is-small"><i class="fas fa-question-circle fa-xs"></i></span></button>
        </Tooltip> : null}
        </p>
        <SectionStatus>
			{evaluation}
          	<i style={{ marginBottom: 5 * (isOpen ? -1 : 1) }} className={`fas ${isOpen ? 'fa-sort-up' : 'fa-sort-down'}`}></i>
        </SectionStatus>
      </div>
      <div className={`si-contain`}>
        <SectionWrapper className="no-py">{contain}</SectionWrapper>
      </div>
    </div>
  );
};

const SectionGroup = ({ items, ...props }) => {
  return (
    <div className={`section-group`}>
      <div className={`sg-items`}>
        {_.map(items, (item, k) => (
          <SectionItem {...props} item={item} key={k} />
        ))}
      </div>
    </div>
  );
};

export default SectionGroup;
