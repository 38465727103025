import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
try {
  firebase.initializeApp(firebaseConfig)
} catch (error) {}

const analytics = firebase.analytics()

const logEvent = (name, payload) =>
  analytics.logEvent(name, { ...payload, recorded_at: Date.now() })
const setUserProperties = analytics.setUserProperties
const setUserId = analytics.setUserId

const logCurrentPage = () => {
  analytics.setCurrentScreen(window.location.pathname)
  logEvent('screen_view')
}

export const ga = { logEvent, setUserProperties, setUserId, logCurrentPage }

//

const db = firebase.firestore

export const fb = {
  process: (processId, callback) => {
    db()
      .collection('processes')
      .doc(processId.toString())
      .onSnapshot((response) => {
        if (!response.exists) {
          callback({
            success: false,
            data: null,
          })
        }
        callback({
          success: true,
          data: response.data(),
        })
      })
  },

  fichacertaSolicitations: (companyId, filtros, callback) => {
    if (filtros['proposalId']) {
      db()
        .collection('fichacertaSolicitations')
        .where('companyId', '==', companyId)
        .where('proposalId', '==', filtros['proposalId'])
        .orderBy('id', 'desc')
        .onSnapshot((response) => {
          if (!response.exists) {
            callback({
              success: false,
              data: null,
            })
          }
          let data = []

          response.docs.forEach((doc) => {
            data.push(doc.data())
          })
          callback({
            success: true,
            data: data,
          })
        })
    } else {
      if (filtros['first']) {
        db()
          .collection('fichacertaSolicitations')
          .where('companyId', '==', companyId)
          .orderBy('id', 'desc')
          .endBefore(filtros['first']['id'])
          .limit(10)
          .onSnapshot((response) => {
            if (!response.exists) {
              callback({
                success: false,
                data: null,
              })
            }
            let data = []

            response.docs.forEach((doc) => {
              data.push(doc.data())
            })
            callback({
              success: true,
              data: data,
            })
          })
      } else if (filtros['last']) {
        db()
          .collection('fichacertaSolicitations')
          .where('companyId', '==', companyId)
          .orderBy('id', 'desc')
          .startAfter(filtros['last']['id'])
          .limit(10)
          .onSnapshot((response) => {
            if (!response.exists) {
              callback({
                success: false,
                data: null,
              })
            }
            let data = []

            response.docs.forEach((doc) => {
              data.push(doc.data())
            })
            callback({
              success: true,
              data: data,
            })
          })
      } else {
        db()
          .collection('fichacertaSolicitations')
          .where('companyId', '==', companyId)
          .orderBy('id', 'desc')
          .limit(10)
          .onSnapshot((response) => {
            if (!response.exists) {
              callback({
                success: false,
                data: null,
              })
            }
            let data = []

            response.docs.forEach((doc) => {
              data.push(doc.data())
            })
            callback({
              success: true,
              data: data,
            })
          })
      }
    }
  },

  fichacertaSolicitationById: (solicitationId, callback) => {
    db()
      .collection('fichacertaSolicitations')
      .doc(solicitationId.toString())
      .onSnapshot((response) => {
        if (!response.exists) {
          callback({
            success: false,
            data: null,
          })
        }
        callback({
          success: true,
          data: response.data(),
        })
      })
  },

  logEvent: async (name, payload) => {
    try {
      await db()
        .collection('events')
        .doc()
        .set({
          name,
          ...payload,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })

      return { success: true }
    } catch (error) {
      throw new Error({ success: false, error })
    }
  },
}
