import React from 'react';
import moment from 'moment';

// Components
import { FormFieldText } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Space from 'components/lib/Space';
import Text from 'components/lib/Text';
import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';

import formCreator from 'features/components/formCreator';
import { fieldProps } from 'features/components/formCreator';

export class SaveGuaranteeProtocol extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormActive: false,
    };
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  render() {
    const { isFormActive } = this.state;
    const { guaranteeValue, formIsWorking, formIsReady, hasReceipt, showReceipt, guaranteeCode } = this.props;
    return (
      <React.Fragment>
        {(hasReceipt ? (
          <div className="buttons-flat mb-3">
            <Icon name="fas fa-sm fa-receipt" style={{ marginLeft: -4, fontSize: 16, color: '#23d160' }} />
            <Button isLink isDark onClick={this.props.fetchFile} style={{ fontSize: 16, color: '#23d160' }}>
              {`Comprovante enviado (Valor do título: R$ ${guaranteeValue})`}
            </Button>
          </div>
        ) : (
          <div className="buttons-flat mb-3">
            <Icon name="fas fa-sm fa-unlink" style={{ marginLeft: -4, fontSize: 16 }} />
            <Button isLink isDark onClick={null} style={{ fontSize: 16 }}>
              Aguardando comprovante
              </Button>
          </div>
        ))}

        {guaranteeCode === 'GUARANTEE_CAPITALIZATION' && (
          <div>
            <FormFieldText {...fieldProps(this.props, 'guarantee_protocol')} isDisabled={!isFormActive} label="Protocolo de capitalização / Número do contrato" />

            <Space />
            {isFormActive ? (
              <Button hasThemeColor isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} onClick={() => this.setState({ isFormActive: false })}>
                Salvar
              </Button>
            ) : (
              <Button hasThemeColor onClick={() => this.setState({ isFormActive: true })} onSuccess={() => false} submit>
                Editar
              </Button>
            )}
          </div>
        )}

      </React.Fragment>
    );
  }

  createRequest(fields) {
    const { processId } = this.props;
    this.props.submit(`{company}/process/${processId}/guarantee/protocol`, fields);
    this.props.submit(`{company}/process/${processId}/guarantee/validate-guarantee`, { guarantee_evaluation: true });
  }
}

export default formCreator(SaveGuaranteeProtocol);
