import React from 'react';
import ReactDOM from 'react-dom';

import parsePropsAsBulmaClasses from '../lib/_utils/parsePropsAsBulmaClasses';

class NotificationBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    const notificationCSSClass = `notification is-fixed ${
      parsePropsAsBulmaClasses(this.props)
    }`;

    return (
      <div className={notificationCSSClass}>
        <button className="delete" onClick={this.props.onClose} />
        {this.props.children}
      </div>
    );

  }

};

NotificationBody.propTypes = {};

const body = document.body;

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement('div');
  }

  componentDidMount() {
    body.appendChild(this.element);
  }

  componentWillUnmount() {
    body.removeChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.element
    );
  }
}

const Notification = (props) => <Portal><NotificationBody {...props} /></Portal>;

export default Notification;
