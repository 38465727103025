import React from 'react';

// Components
import { FormFieldSelect } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';

export default class ContractEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    const { contracts, isClosed, currentModelId } = this.props;
    const source = this.props.source;

    return (
      <Grid>
        <Grid.Col>
          {contracts.length > 0 && !isClosed ? (
            <FormFieldSelect
              valueKey="id"
              hideBlankOption
              valueDefault={ currentModelId }
              onChange={this.selectContract.bind(this)}
              options={contracts}
            />
          ) : null}
          <iframe title="Document" width="100%" style={{ height: 600 }} src={source} />
        </Grid.Col>
      </Grid>
    );
  }

  selectContract(value, field) {
    const { currentModelId } = this.props;
    if (value == currentModelId) return
    if (!field.wasFocused) return;
    this.requestSource(value);
  }

  requestSource(modelId) {
    this.props.onSelect(modelId);
  }
}
