import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Pagination extends React.Component {

  constructor() {
    super();
    this.state = {
      currentPage: 1,
      lastPage: 10,
      firstPage: 1,
      total: 0,
      itemsPerPage: 0,
      pages: 0
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.total !== prevProps.total || this.props.itemsPerPage !== prevProps.itemsPerPage || this.props.current !== prevProps.current) {
      const totalPages = this.props.total / this.props.itemsPerPage;
      if (isNaN(totalPages)) {
        if (this.props.current) {
          if (this.props.onChange) this.props.onChange(this.props.current);
        }
        return;
      }
      this.setState({
        currentPage: this.props.current || 1,
        pages: Math.ceil(totalPages)
      });
    }
    return null;
  }

  componentDidMount() {
    const totalPages = this.props.total / this.props.itemsPerPage;
    if (isNaN(totalPages)) {
      if (this.props.current) {
        if (this.props.onChange) this.props.onChange(this.props.current);
      }
      return;
    }
    this.setState({
      currentPage: this.props.current || 1,
      pages: Math.ceil(totalPages)
    });
  }

  updatePage(page) {
    let currentPage = page > 0 ? (page < this.state.pages ? page : this.state.pages) : 1;
    console.log('CURRENT PAGE: ', currentPage)
    let lastPage = 10;
    let firstPage = 1;
    let previousPage = this.state.currentPage;

    if (page < this.state.lastPage) {
      firstPage = currentPage < this.state.lastPage && currentPage > this.state.firstPage ? this.state.firstPage : (currentPage - 1 > 0) ? currentPage - 1 : 1;
      lastPage = firstPage + 9;
      this.setState({
        currentPage: currentPage,
        firstPage: firstPage,
        lastPage: lastPage
      });
    } else {
      lastPage = currentPage < this.state.lastPage ? this.state.lastPage : (currentPage + 1 < this.state.pages) ? currentPage + 1 : this.state.pages;
      firstPage = currentPage > this.state.firstPage ? lastPage - 9 : currentPage;
      this.setState({
        currentPage: currentPage,
        firstPage: firstPage,
        lastPage: lastPage
      });
    }

    if (this.props.onChange) this.props.onChange(currentPage, previousPage);
  }

  render() {

    const { firstPage, lastPage, currentPage } = this.state;
    const { pages } = this.state;

    const pageNumbers = [];

    for (let i = firstPage; i <= (pages < 11 ? pages : lastPage); i++) {
      pageNumbers.push(i);
    }

    const pageNumbersSelect = [];

    for (let i = firstPage; i <= (pages < 11 ? pages : lastPage); i++) {
      pageNumbersSelect.push(i);
    }

    const pagesNumbers = pageNumbers.map((item, index) => {
      const pageClasses = classnames([
        'pagination-link',
        { 'is-current': this.state.currentPage == item }
      ]);
      return (
        <li
          className={pageClasses}
          tabIndex="0"
          role="button"
          key={index}
          onKeyDown={this.updatePage.bind(this, item)}
          onClick={this.updatePage.bind(this, item)}>
          {item}
        </li>
      );
    });

    // const pagesNumbersSelect = pageNumbersSelect.map((item, index) => (
    //   <option
    //     key={index}
    //     onKeyDown={this.updatePage.bind(this, item)}
    //     onClick={this.updatePage.bind(this, item)}>
    //     {item}
    //   </option>
    // ));

    if (pages < 2) return null;

    return (
      <div className="pagination-container">
        <ul className="pagination">
          {pages > 3 ?
            <li
              className="pagination-previous"
              tabIndex="0"
              role="button"
              onKeyDown={this.updatePage.bind(this, 1)}
              onClick={this.updatePage.bind(this, 1)}>
              <i className="fal fa-lg fa-angle-double-left" />
            </li>
            : null
          }
          {pages > 10 ?
            <li
              className="pagination-link is-arrow"
              tabIndex="0"
              role="button"
              onKeyDown={this.updatePage.bind(this, this.state.currentPage - 1)}
              onClick={this.updatePage.bind(this, this.state.currentPage - 1)}>
              <i className="fal fa-lg fa-angle-left" />
            </li>
            : null
          }
          {pagesNumbers}
          {pages > 10 ?
            <li
              className="pagination-link is-arrow"
              tabIndex="0"
              role="button"
              onKeyDown={this.updatePage.bind(this, this.state.currentPage + 1)}
              onClick={this.updatePage.bind(this, this.state.currentPage + 1)}>
              <i className="fal fa-lg fa-angle-right" />
            </li>
            : null
          }
          {pages > 3 ?
            <li
              className="pagination-next"
              tabIndex="0"
              role="button"
              onKeyDown={this.updatePage.bind(this, this.state.pages)}
              onClick={this.updatePage.bind(this, this.state.pages)}>
              <i className="fal fa-lg fa-angle-double-right" />
            </li>
            : null
          }
        </ul>
      </div>
    );
  }
};

Pagination.propTypes = {};
