import React from 'react';
import PropTypes from 'prop-types';
import { request } from 'core/constants';
import { baseURL } from 'core/constants';

// Components
import SubmitButton from 'components/SubmitButton';

import formCreator from '../components/formCreator';
import UserInvite from '../components/fieldsGroup/UserInvite';
import ProfileSelection from '../components/fieldsGroup/ProfileSelection';

import Dropdown from 'components/lib/Dropdown';
import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';
import { goodObject } from 'utils/utils';

export class CreateUser extends React.Component {
  componentDidMount() {
    this.props.createRequest(this.createRequest, true);
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;

    return (
      <>
        <UserInvite {...this.props} />

        <ProfileSelection {...this.props} />

        {/* <SubmitButton
          label="Salvar e continuar"
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        /> */}

        <div style={{ textAlign: 'right' }}>
          <Button isDisabled={!formIsReady || formIsWorking} hasThemeColor onClick={() => this.createRequest(this.props.fields, 'email')}>
            Enviar
          </Button>
        </div>
      </>
    );
  }

  createRequest(fields, type) {
    let { companyId } = this.props;
    let goodFields = goodObject(fields, {
      name: 'name.value',
      email: 'email.value',
      profile_ids: 'profile_ids.value',
    });
    if (type == 'email') {
      goodFields['return_to'] = location.origin + '/auth';
    }
    request
      .post(`${baseURL}company/${companyId}/grant-profile`, goodFields)
      .then((response) => {
        return this.props.onSuccess({ isSuccess: true, type: type, response: response.data });
      })
      .catch((error) => {
        return this.props.onSuccess({ isSuccess: false, response: error });
      });

    // fields['return_to'] = location.origin + '/auth';
    // this.props.submit('{company}/grant-profile', fields);
  }
}

CreateUser.defaultProps = {
  fields: {},
  activeCompany: {},
};

CreateUser.propTypes = {
  activeCompany: PropTypes.object.isRequired,
};

export default formCreator(CreateUser);
