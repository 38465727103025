import React from 'react';

import moment from 'moment';

import Step from './KeysTimelineStep';
import Text from 'components/lib/Text';
import ProcessTask from 'services/ProcessTask';

const KeysTimeline = (props) => {
  const overview = ProcessTask(props.requirements, 'REQUIREMENT_OVERVIEW');
  const guarantee = ProcessTask(props.requirements, 'REQUIREMENT_GUARANTEE');
  const inspection = ProcessTask(props.requirements, 'REQUIREMENT_INSPECTION');
  const contract = ProcessTask(props.requirements, 'REQUIREMENT_CONTRACT');
  const keys = ProcessTask(props.requirements, 'REQUIREMENT_POSSESSION');

  // Recebeu e Contratou Jurídico -> Data e Hora

  if (!props.process) return null;

  let showGuarantee = props.process.proposal?.type === 'TYPE_RENTAL' && props.process.proposal?.guarantee?.code === 'GUARANTEE_GUARANTOR';

  const [task] = overview?.tasks ?? [];

  return (
    <div className="keys-timeline">
      <Step icon="file-alt" label="Proposta" isAchieved />
      <Step
        icon="id-card"
        label="Análise"
        isAchieved={overview.status}
        onClickInfo={props.onClickInfo}
        description={
          overview.status && (
            <Text>
              A análise foi aprovada por {task?.executor?.name ?? '-'} em&nbsp;
              <strong>{moment(overview.updated_at).format('DD/MM/YYYY HH:mm')}</strong>
            </Text>
          )
        }
      />
      {showGuarantee && (
        <Step
          icon="shield-check"
          label="Garantia"
          isAchieved={guarantee.status}
          onClickInfo={props.onClickInfo}
          description={
            guarantee.status && (
              <Text>
                A garantia foi aprovada em <strong>{moment(guarantee.updated_at).format('DD/MM/YYYY HH:mm')}</strong>
              </Text>
            )
          }
        />
      )}
      <Step
        icon="clipboard-list"
        label="Vistoria"
        isAchieved={inspection.status}
        onClickInfo={props.onClickInfo}
        description={
          inspection.status && (
            <>
              <Text>
                A vistoria ocorreu em <strong>{moment(inspection.tasks[0].updated_at).format('DD/MM/YYYY HH:mm')}</strong>
              </Text>
              <Text>
                O documento foi enviado em <strong>{moment(inspection.tasks[1].updated_at).format('DD/MM/YYYY HH:mm')}</strong>
              </Text>
            </>
          )
        }
      />
      <Step
        icon="file-contract"
        label="Contrato"
        isAchieved={contract.status}
        onClickInfo={props.onClickInfo}
        description={
          contract.status &&
          props.process.proposal.contract.signers.length > 0 && (
            <Text>
              O contrato foi enviado para:&nbsp;
              <strong>{props.process.proposal.contract.signers.map((item) => (item.user ? item.user.email : item.email)).join(', ')}</strong>
            </Text>
          )
        }
      />
      <Step
        icon="key"
        label="Posse"
        isAchieved={keys.status}
        onClickInfo={props.onClickInfo}
        description={
          keys.status && (
            <Text>
              As chaves foram entregues em <strong>{moment(keys.updated_at).format('DD/MM/YYYY HH:mm')}</strong>
            </Text>
          )
        }
      />
    </div>
  );
};

export default KeysTimeline;
