import React from 'react';

// Components
import { FormFieldText } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Space from 'components/lib/Space';
import Text from 'components/lib/Text';
import Header from 'components/lib/Header';

export default class DocumentsRepeater extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Col isFull>
            <Space />
            <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
              Solicitação de documentos
            </Header>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col isFull>
            <Text>
              Documentos solicitados atualmente: Documento com foto, Comprovante de residência, Comprovante de renda, Comprovante de matrícula, Frente do cartão de crédito, Última fatura completa.
            </Text>
            <Space isSmall />
            <FormFieldText isFlat placeholder="Digite o nome de um ou mais documentos separados por vírgula" label="Solicitar mais documentos" onChange={this.props.onChange} />
          </Grid.Col>
        </Grid>
        <Space />
      </React.Fragment>
    );
  }
}
