import { request } from 'core/constants';
import { requestState } from 'utils/UtilityStore/actions';

export const fetch = (guarantorId) => (dispatch) => {
  dispatch(requestState(true, 'guarantor'));
  request
    .get(`{company}/guarantor/${guarantorId}`)
    .then((response) => {
      dispatch(requestState(false, 'guarantor'));
      dispatch({
        type: 'FETCH_GUARANTOR',
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const clear = () => (dispatch) => {
  dispatch(requestState(null, 'guarantor'));
  dispatch({
    type: 'CLEAR_GUARANTOR',
  });
};
