import React from 'react';
import NumberFormat from 'react-number-format';
import Switch from 'components/lib/FormFields/Switch';
import { Field as FkField, ErrorMessage, connect } from 'formik';
import { formatValue } from '../../shared';

const ErrorComponent = (props) => <p {...props} style={style.error} />;

const Field = ({
  name,
  label,
  mask,
  horizontal = false,
  type = 'text',
  decimalScale = 0,
  marginBottom = 12,
  labelStyle = style.label,
  hideError = false,
  containerStyle = {},
  formik: { values, setFieldValue, handleChange, handleBlur },
  ...props
}) => {
  let component = null;

  switch (type) {
    case 'switch':
      component = <Switch id={name} checked={values[name] ?? false} onChange={(newValue) => setFieldValue(name, newValue)} />;
      break;
    case 'numeric':
      let vl = `${values[name] || '0,00'}`;
      if (decimalScale > 0 && typeof values[name] === 'number' && values[name] > 0) {
        // Sanitize
        vl = formatValue(values[name], decimalScale);
      }

      const onBlur = (evt) => {
        const { value } = evt.target;
        if (!value || decimalScale === 0) {
          handleBlur(evt);
          return;
        }

        const [, decimalValue] = value.split(',');
        if ((decimalValue || '').length === decimalScale) {
          handleBlur(evt);
          return;
        }

        setFieldValue(name, formatValue(value, decimalScale));
      };

      component = <NumberFormat decimalSeparator="," thousandSeparator="." decimalScale={decimalScale} name={name} value={vl} className="input" onChange={handleChange} onBlur={onBlur} {...props} />;
      break;
    default:
      component = <FkField type={type} className="input" name={name} id={name} {...props} />;
      break;
  }

  return (
    <div style={{ marginBottom, ...containerStyle }}>
      {label && (
        <label style={{ ...style.label, ...labelStyle }} htmlFor={name}>
          {label}
        </label>
      )}
      {component}
      {!hideError && <ErrorMessage name={name} component={ErrorComponent} />}
    </div>
  );
};

const style = {
  label: {
    fontSize: 14,
    marginBottom: '0.5em',
    display: 'block',
    color: '#7F8996',
    fontWeight: '400',
  },
  error: {
    color: '#990000',
    fontSize: 12,
    marginTop: 4,
  },
};

export default connect(Field);
