import React, { useState } from 'react';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

import { Container, Modal, ModalBody, ModalHeader, ButtonContainer } from './styles';

const ConfirmExcludeModal = ({
  title = 'Excluir',
  text = 'Confirmar exclusão',
  onExclude = () => {},
  onClose = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const handleExcludeButtonClick = async () => {
    setLoading(true);

    await onExclude();

    onClose();

    setLoading(false);
  };

  const handleClose = () => (!loading ? onClose() : () => {});
  return (
    <Container onClick={handleClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <span>{title}</span>

          <div
            aria-hidden="true"
            onClick={handleClose}
            style={{ cursor: 'pointer' }}
          >
            <Icon name="fal fa-times" />
          </div>
        </ModalHeader>
        <ModalBody>
          <p>{text}</p>

          <ButtonContainer>
            <Button isNeutral disabled={loading} onClick={handleClose}>
              cancelar
            </Button>
            <Button isDanger disabled={loading} onClick={handleExcludeButtonClick}>
              Excluir
            </Button>
          </ButtonContainer>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default ConfirmExcludeModal;
