import { request } from 'core/constants';
import { requestState } from 'utils/UtilityStore/actions';

export const fetch = (processId) => (dispatch) => {
  dispatch(requestState(true, 'processDetails'));
  request
    .get(`{company}/process/${processId}`)
    .then((response) => {
      dispatch(requestState(false, 'processDetails'));
      dispatch({
        type: 'FETCH_PROCESS_DETAILS',
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const fetchCreditAnalysis = (proposalId) => (dispatch) => {
  // // eslint-disable-next-line no-console
  // console.log('aqui', proposalId);
  dispatch(requestState(true, 'processCreditAnalysis'));
  request
    .get(`{company}/proposal/${proposalId}/credit-analysis`)
    .then((response) => {
      dispatch(requestState(false, 'processCreditAnalysis'));
      dispatch({
        type: 'FETCH_CREDIT_ANALYSIS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'FETCH_CREDIT_ANALYSIS',
        payload: null,
      });
    });
};

export const fetchContract = (processId, contractId) => (dispatch) => {
  dispatch(requestState(true, 'processContract'));
  dispatch(requestState(true, 'processDetails'));
  return request
    .post(`{company}/process/${processId}/generate-contract`, {
      contract_id: contractId,
    })
    .then((response) => {
      dispatch(requestState(false, 'processContract'));
      dispatch({
        type: 'FETCH_PROCESS_CONTRACT',
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const fetchReadyContract = (fileId) => (dispatch) => {
  dispatch(requestState(true, 'readyContract'));
  return new Promise((resolve) => {
    request
      .get(`{company}/file/${fileId}`)
      .then((response) => {
        dispatch(requestState(false, 'readyContract'));
        dispatch({
          type: 'FETCH_READY_CONTRACT',
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch((error) => {});
  });
};

export const fetchReadyContractVariables = (proposalId) => (dispatch) => {
  dispatch(requestState(true, 'readyContractVariables'));
  return new Promise((resolve) => {
    request
      .get(`{company}/proposal/${proposalId}/contract-variables`)
      .then((response) => {
        dispatch(requestState(false, 'readyContractVariables'));
        dispatch({
          type: 'FETCH_READY_CONTRACT_VARIABLES',
          payload: response.data,
        });
        resolve(response.data);
      })
      .catch(() => {});
  });
};

export const fetchSignedContract = (fileId) => (dispatch) =>
  new Promise((resolve) => {
    request
      .get(`{company}/file/${fileId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {});
  });

export const fetchContracts = (filter) => (dispatch) => {
  dispatch(requestState(true, 'contracts'));
  request
    .get('{company}/contract-model', { filter })
    .then((response) => {
      dispatch(requestState(false, 'contracts'));
      dispatch({
        type: 'LIST_CONTRACTS',
        payload: response.data,
        listInfo: response.resultsInfo,
      });
    })
    .catch((error) => {});
};

export const clear = () => (dispatch) => {
  dispatch(requestState(null, 'processDetails'));
  dispatch({
    type: 'CLEAR_PROCESS_DETAILS',
  });
};
