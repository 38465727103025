import React from 'react';

// Components
import Button from 'components/lib/Button';
import Assistant from 'components/Assistant';

import actionCreator from '../authCreator';

export class NotFound extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Assistant words={'404 :('} style={{ marginBottom: 30 }} />

        <Button isLink isFullWidth specSelector="auth-notfound-button" onClick={this.props.goto.bind(this, '/auth')}>
          Ir para a tela de login
        </Button>
      </React.Fragment>
    );
  }
}

export default actionCreator(NotFound);
