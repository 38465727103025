import React from 'react';
import PropTypes from 'prop-types';

const Profiles = (props) => {
  const profileListSimplified = props.content.map((item) => item.name);
  return profileListSimplified.join(', ');
};

Profiles.defaultProps = {
  content: [],
};

Profiles.propTypes = {
  content: PropTypes.array.isRequired,
};

export default Profiles;
