import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #00000012; ;
`;

export const Modal = styled.div`
  min-width: 320px;

  border-radius: 4px;

  background-color: white;

  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 8px 10px 16px;

  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 18px;

  border-radius: 4px 4px 0px 0px;

  background-color: #5f6775;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 20px;

  font-size: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
