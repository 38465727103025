import React from 'react'

import ReactTimeout from 'react-timeout'
import request from 'axios'

import { baseURL } from 'core/constants'

import { fb, ga } from '../../services/firebase'

import * as commons from 'utils/UtilityStore/actions'

import BodyContainer from 'components/lib/BodyContainer'
import Modal from 'components/lib/Modal'

import ScreenHeaderPrimary from 'components/ScreenHeaderPrimary'
import ReleaseNotes from 'components/ReleaseNotes'
import withModal from 'components/HOCs/withModal'
import withRedux from 'components/redux'
import List from './components/List'

import ProposalNew from 'containers/Proposal/components/New'

import * as actions from './actions'

export class Process extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastOrder: '',
      orderDirection: '',
      guarantorsIds: (this.props.defaultValue || {}).guarantorsIds,
    }
    this.body = React.createRef()
  }
  componentDidMount() {
    this.props.fetch(this.props?.name)
  }
  render() {
    const pagination = this.props.listInfo

    return (
      <>
        <ScreenHeaderPrimary {...this.props} />

        <BodyContainer getRef={(ref) => (this.body = ref)} isPadded>
          <List
            items={this.props.items}
            hideAgent={this.props.isAgent}
            openProcess={this.openProcess.bind(this)}
            onSearch={this.searchList.bind(this)}
            isWorking={this.props.isWorking}
            pagination={pagination}
            createNewProposal={this.createNewProposal.bind(this)}
            handlePagination={this.handlePagination.bind(this)}
            orderBy={this.orderBy.bind(this)}
            lastOrder={this.state.lastOrder}
            orderDirection={this.state.orderDirection}
          />
          <Modal
            title={this.state.modalTitle}
            isOpen={this.state.modalIsOpen}
            onClose={this.handleModalClose.bind(this)}
          >
            {this.state.feature}
          </Modal>
        </BodyContainer>
        <ReleaseNotes />
      </>
    )
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    })
  }

  searchList(keywords) {
    const filter = { ...this.state.filter, ...{ search: keywords }, page: 1 }

    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(
        this.props?.name,
        filter,
        this.state.lastOrder,
        this.state.orderDirection,
      )
    } else {
      this.props.fetch(this.props?.name, filter)
    }
  }

  orderBy(order) {
    const filter = { ...this.state.filter, page: 1 }

    let orderDirection = ''

    if (this.state.lastOrder == order) {
      if (this.state.orderDirection == 'desc') {
        this.setState({ orderDirection: 'asc' })
        orderDirection = 'asc'
      } else if (this.state.orderDirection == 'asc') {
        this.setState({ orderDirection: 'desc' })
        orderDirection = 'desc'
      }
    } else {
      this.setState({ orderDirection: 'asc' })
      orderDirection = 'asc'
    }

    this.props.fetch(this.props?.name, filter, order, orderDirection)
    this.setState({ lastOrder: order })
  }

  handlePagination(page) {
    this.body.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    })
    const filter = {
      ...this.state.filter,
      ...{ page: page },
    }

    this.setState({ filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(
        this.props?.name,
        filter,
        this.state.lastOrder,
        this.state.orderDirection,
      )
    } else {
      this.props.fetch(this.props?.name, filter)
    }
  }

  createNewProposal() {
    const { activeCompany, proposal, companyId } = this.props

    this.props.openModal(
      'Nova proposta',
      <ProposalNew
        {...this.props}
        activeCompanyModel={activeCompany}
        activeCompany={companyId && companyId}
        defaultValue={proposal}
        onSelectGuarantor={this.onSelectGuarantor.bind(this)}
        onSuccess={this.onCreateProposalSuccess.bind(this)}
        proposalId={proposal && proposal.id}
      />,
    )
  }

  onSelectGuarantor(guarantorsIds) {
    this.setState({ guarantorsIds })
  }

  onCreateProposalSuccess(response) {
    const { activeCompany } = this.props

    this.props.closeModal()

    if (!response.isSuccess) {
      this.props.notification({
        message: 'Ocorreu um erro ao criar a proposta',
        status: 'danger',
      })

      ga.logEvent('proposal_error')

      return
    }

    this.props.notification({
      message: 'Proposta criada com sucesso',
    })

    const proposalType =
      response.data.type === 'TYPE_RENTAL'
        ? 'Locação'
        : response.data.type === 'TYPE_SALE'
        ? 'Venda'
        : 'Captação de imóveis'

    ga.logEvent('proposal_ended', {
      type: proposalType,
    })
    fb.logEvent('proposal_ended', {
      type: proposalType,
      proposalId: response.data.id,
      guarantee: response.data?.guarantee?.name || '',
      companyId: this.props.activeCompany.id,
      user: {
        email: this.props.activeUser.email,
        id: this.props.activeUser.id,
      },
    })

    const homePath = this.props.location.pathname.replace('process/new', '')

    new Promise((resolve, reject) => {
      const token = localStorage.getItem('izee-token')
      const companyId = activeCompany.id
      const url = `${baseURL}company/${companyId}/guarantor/associate-proposal`
      const formData = {}
      const data = []
      const proposalId = response.data.id
      const { guarantorsIds } = this.state
      const headers = `Authorization: Bearer ${token}`

      if (response.data.type === 'TYPE_ACQUISITION') {
        return resolve(this.props.history.push(`${homePath}/process/${proposalId}`))
      }

      if (!guarantorsIds) {
        return resolve(this.props.history.push(`${homePath}/proposal/${proposalId}`))
      }

      guarantorsIds.map((id) => {
        data.push({
          proposal_id: proposalId,
          guarantor_id: id,
        })
      })

      formData['data'] = data

      request
        .post(url, formData, headers)
        .then(() => {
          resolve(this.props.history.push(`${homePath}/proposal/${proposalId}`))
        })
        .catch(() => {
          reject(this.props.history.push(`${homePath}/proposal/${proposalId}`))
        })
    })
  }

  openProcess(process) {
    if (process['process_id'] !== null) {
      // const url_path =
      //   this.props.location.pathname +
      //   '/process/' +
      //   process['process_id'] +
      //   '/overview'

      // this.props.history.push(url_path)
      this.props.history.push(
        `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/process/${process['process_id']}/overview`,
      )

      return
    }

    this.props.history.push(
      `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/proposal/${process.id}`,
    )
    // this.props.history.push(this.props.location.pathname + '/proposal/' + process.id)
  }
}

export default withRedux(
  ({ process: { items, listInfo }, commons }) => ({
    items,
    listInfo,
    isWorking: commons.isWorking.process,
  }),
  { ...actions, ...commons },
)(ReactTimeout(withModal(Process)))
