import React from 'react';

const Onboarding = () => (
  <svg width="188" height="168" viewBox="0 0 188 168" fill="none">
    <path
      d="M27.6871 152.913C64.2803 172.029 123.609 172.03 160.202 152.914C196.795 133.797 196.795 102.803 160.202 83.6865C123.609 64.5696 64.2795 64.5696 27.6867 83.6857C-8.90606 102.802 -8.90606 133.797 27.6871 152.913Z"
      fill="#FAFAFA"
    />
    <path
      d="M58.0759 152.987C62.5207 155.106 69.7272 155.106 74.1721 152.987C78.617 150.869 78.617 147.434 74.1721 145.316C69.7272 143.197 62.5207 143.197 58.0759 145.316C53.631 147.434 53.631 150.869 58.0759 152.987Z"
      fill="#ECECEC"
    />
    <path
      d="M28.4313 90.6639L30.3038 90.1885C30.3038 90.1885 31.1117 87.1825 31.4702 82.6454C31.9526 76.5429 29.5872 71.9575 26.64 70.0587C23.1411 67.8037 21.3056 69.8646 21.5163 71.4841C21.7384 73.1946 23.5869 75.6083 24.4675 77.2769C25.7432 79.6934 27.6729 83.3527 28.4313 90.6639Z"
      fill="#2091EA"
    />
    <path
      d="M29.3301 90.5732C29.3208 90.5732 29.3114 90.5728 29.3021 90.5703C29.2274 90.5549 29.1791 90.4818 29.1945 90.4071C31.434 79.569 26.8292 71.8456 23.9859 70.2971C23.9194 70.2606 23.8942 70.1765 23.9311 70.1096C23.9673 70.043 24.0513 70.0178 24.1187 70.0543C27.1158 71.6869 31.7617 79.3493 29.4653 90.4632C29.4519 90.5289 29.3943 90.5732 29.3301 90.5732Z"
      fill="white"
    />
    <path
      d="M28.4319 90.664C28.4319 90.664 28.7198 87.6437 27.656 84.6617C26.5923 81.6796 24.922 78.9115 21.5831 77.8908C18.2438 76.8697 15.6108 79.2927 17.2669 81.52C18.923 83.7465 25.0694 85.129 26.2671 92.1061L28.4319 90.664Z"
      fill="#2091EA"
    />
    <path
      d="M27.4785 91.4387C27.4744 91.4387 27.4703 91.4383 27.4659 91.4379C27.39 91.431 27.3339 91.364 27.3408 91.2885C27.8414 85.6694 24.0518 80.0947 19.0649 79.1146C18.9898 79.1 18.9411 79.0273 18.9557 78.9522C18.9703 78.8775 19.043 78.8283 19.1181 78.8434C24.2374 79.8494 28.1289 85.5598 27.6161 91.3124C27.6096 91.3847 27.5495 91.4387 27.4785 91.4387Z"
      fill="white"
    />
    <path
      d="M150.016 136.008C150.016 136.008 150.653 129.982 154.41 125.935C158.168 121.888 162.178 121.926 163.74 123.378C165.301 124.829 163.695 127.095 160.207 129.299C157.218 131.186 153.269 135.468 152.054 138.361L150.016 136.008Z"
      fill="#2091EA"
    />
    <path
      d="M150.882 137.147C150.871 137.147 150.859 137.146 150.847 137.143C150.772 137.123 150.727 137.047 150.747 136.972C152.344 130.826 157.704 124.634 162.218 123.722C162.293 123.706 162.368 123.755 162.383 123.831C162.398 123.907 162.349 123.98 162.273 123.996C157.851 124.89 152.592 130.987 151.018 137.042C151.002 137.105 150.944 137.147 150.882 137.147Z"
      fill="white"
    />
    <path
      d="M150.662 150.128C150.662 150.128 153.437 150.916 155.437 149.54C157.146 148.363 156.721 147.108 156.919 146.141C157.118 145.175 157.955 144.654 159.353 144.471C161.077 144.245 162.684 143.975 162.986 142.157C163.252 140.552 160.604 139.685 161.393 138.122C161.989 136.939 163.33 136.15 164.435 135.263C165.535 134.38 164.882 132.482 162.241 132.478C158.97 132.474 158.289 133.68 156.828 133.887C155.368 134.093 154.555 133.237 152.958 133.447C151.194 133.678 150.331 135.366 150.289 137.477L150.662 150.128Z"
      fill="#2091EA"
    />
    <path
      d="M150.484 144.115C150.468 144.115 150.453 144.113 150.437 144.107C150.364 144.081 150.326 144.001 150.352 143.928C151.809 139.824 159.657 134.102 162.965 133.517C163.039 133.502 163.113 133.554 163.127 133.631C163.14 133.706 163.09 133.779 163.013 133.792C159.762 134.368 152.047 139.989 150.616 144.022C150.595 144.079 150.542 144.115 150.484 144.115Z"
      fill="white"
    />
    <path
      d="M161.224 141.705C161.202 141.705 161.181 141.699 161.161 141.689C159.115 140.636 155.652 139.869 152.99 140.441C152.916 140.456 152.84 140.409 152.823 140.333C152.807 140.258 152.856 140.184 152.931 140.167C155.658 139.583 159.199 140.364 161.288 141.44C161.357 141.476 161.384 141.56 161.349 141.629C161.324 141.677 161.275 141.705 161.224 141.705Z"
      fill="white"
    />
    <path
      d="M154.406 139.019C154.329 139.018 154.266 138.956 154.266 138.878C154.271 136.423 153.441 135.229 153.432 135.217C153.387 135.154 153.402 135.067 153.465 135.022C153.528 134.977 153.615 134.991 153.66 135.054C153.696 135.105 154.551 136.324 154.546 138.879C154.546 138.956 154.483 139.019 154.406 139.019Z"
      fill="white"
    />
    <path
      d="M51.7657 1.04632L52.0085 0.90625C52.397 0.682137 53.0263 0.682137 53.4149 0.90625L93.2386 23.8984C93.6267 24.1225 93.9414 24.6678 93.9414 25.1164L93.9418 80.6219C93.9418 81.0705 93.6271 81.6154 93.2386 81.8399L92.9958 81.98C92.6073 82.2041 91.9775 82.2041 91.589 81.98L51.7661 58.9878C51.3776 58.7637 51.0629 58.2184 51.0629 57.7698L51.0625 2.26432C51.0625 1.8161 51.3771 1.27043 51.7657 1.04632Z"
      fill="#FAFAFA"
    />
    <path
      d="M93.9445 52.8691L93.9441 25.1166C93.9441 24.892 93.8658 24.6436 93.7383 24.4231L91.4688 25.7337V81.9091L91.5918 81.9801C91.9803 82.2042 92.6096 82.2042 92.9982 81.9801L93.2413 81.8396C93.6299 81.6155 93.9445 81.0703 93.9445 80.622V52.8691Z"
      fill="#E1E1E1"
    />
    <path
      d="M51.7657 1.8584L91.589 24.8506C91.9776 25.0747 92.2922 25.62 92.2922 26.0686V81.5741C92.2922 82.0227 91.9776 82.2042 91.589 81.9801L51.7661 58.9879C51.3776 58.7638 51.0629 58.2185 51.0629 57.7699L51.0625 2.2644C51.0625 1.81617 51.3772 1.63428 51.7657 1.8584Z"
      fill="#F5F5F5"
    />
    <path
      d="M56.711 8.5235L86.6393 25.8024C87.0274 26.027 87.3425 26.5722 87.3425 27.0204V29.2051C87.3425 29.6538 87.0274 29.8357 86.6393 29.6111L56.711 12.3322C56.3225 12.1081 56.0078 11.5624 56.0078 11.1142V8.9295C56.0078 8.48128 56.3225 8.29939 56.711 8.5235Z"
      fill="#E1E1E1"
    />
    <path
      d="M60.1307 18.1153C62.4076 19.4299 64.2537 22.6271 64.2537 25.2564C64.2537 27.8857 62.4076 28.9514 60.1307 27.6368C57.8539 26.3221 56.0078 23.1249 56.0078 20.4956C56.0078 17.8664 57.8539 16.8006 60.1307 18.1153Z"
      fill="#E1E1E1"
    />
    <path
      d="M60.1653 19.6439C59.3667 19.1827 58.7188 19.5566 58.7188 20.479C58.7188 21.4015 59.3667 22.5232 60.1653 22.9845C60.9643 23.4457 61.6119 23.0718 61.6119 22.1493C61.6119 21.2265 60.9643 20.1051 60.1653 19.6439Z"
      fill="#2091EA"
    />
    <path
      d="M60.541 23.6643L59.7205 23.1905C58.4083 22.4325 57.3438 23.0472 57.3438 24.5623V24.7686C58.0782 25.972 59.0546 27.0158 60.1305 27.637C61.2068 28.2582 62.1833 28.3422 62.9173 27.9865V27.7803C62.9173 26.2647 61.8532 24.4219 60.541 23.6643Z"
      fill="#2091EA"
    />
    <path
      d="M56.8868 29.5778L63.3747 33.3236C63.8602 33.6041 64.2537 34.2574 64.2537 34.7831C64.2537 35.3093 63.8602 35.5082 63.3747 35.2281L56.8868 31.4819C56.4012 31.2018 56.0078 30.5485 56.0078 30.0224C56.0078 29.4966 56.4012 29.2977 56.8868 29.5778Z"
      fill="#E1E1E1"
    />
    <path
      d="M56.8868 33.3841L63.3747 37.1298C63.8602 37.4104 64.2537 38.0636 64.2537 38.5894C64.2537 39.1156 63.8602 39.3145 63.3747 39.0344L56.8868 35.2882C56.4012 35.0081 56.0078 34.3548 56.0078 33.8286C56.0078 33.3029 56.4012 33.1039 56.8868 33.3841Z"
      fill="#E1E1E1"
    />
    <path
      d="M56.8868 37.1903L63.3747 40.9361C63.8602 41.2166 64.2537 41.8699 64.2537 42.3956C64.2537 42.9218 63.8602 43.1208 63.3747 42.8406L56.8868 39.0944C56.4012 38.8143 56.0078 38.1611 56.0078 37.6349C56.0078 37.1091 56.4012 36.9102 56.8868 37.1903Z"
      fill="#E1E1E1"
    />
    <path
      d="M56.8868 40.9966L63.3747 44.7423C63.8602 45.0229 64.2537 45.6761 64.2537 46.2019C64.2537 46.7281 63.8602 46.927 63.3747 46.6469L56.8868 42.9007C56.4012 42.6206 56.0078 41.9673 56.0078 41.4411C56.0078 40.9154 56.4012 40.7164 56.8868 40.9966Z"
      fill="#E1E1E1"
    />
    <path
      d="M56.8868 46.7113L63.3747 50.4575C63.8603 50.7376 64.2541 51.4193 64.2541 51.98V55.6628C64.2541 56.2231 63.8603 56.4504 63.3747 56.1703L56.8868 52.4245C56.4016 52.144 56.0078 51.4623 56.0078 50.902V47.2188C56.0078 46.6585 56.4016 46.4312 56.8868 46.7113Z"
      fill="#2091EA"
    />
    <path
      d="M70.0821 21.9552L86.4654 31.4142C86.9506 31.6943 87.3444 32.348 87.3444 32.8738C87.3444 33.3995 86.9506 33.5989 86.4654 33.3183L70.0821 23.8593C69.5965 23.5792 69.2031 22.9259 69.2031 22.3998C69.2031 21.874 69.5965 21.6751 70.0821 21.9552Z"
      fill="#2091EA"
    />
    <path
      d="M70.0821 25.764L86.4654 35.2226C86.9506 35.5031 87.3444 36.1568 87.3444 36.6826C87.3444 37.2083 86.9506 37.4073 86.4654 37.1271L70.0821 27.6681C69.5965 27.388 69.2031 26.7343 69.2031 26.2086C69.2031 25.6828 69.5965 25.4839 70.0821 25.764Z"
      fill="#2091EA"
    />
    <path
      d="M70.0821 29.5727L86.4654 39.0316C86.9506 39.3118 87.3444 39.9655 87.3444 40.4912C87.3444 41.017 86.9506 41.2163 86.4654 40.9358L70.0821 31.4768C69.5965 31.1967 69.2031 30.5434 69.2031 30.0172C69.2031 29.4915 69.5965 29.2925 70.0821 29.5727Z"
      fill="#2091EA"
    />
    <path
      d="M126.157 36.1749L127.698 35.2858C128.183 35.0052 128.97 35.0052 129.456 35.2858L163.98 55.2184C164.465 55.4985 164.859 56.1802 164.859 56.7409V100.414C164.859 100.975 164.465 101.657 163.98 101.937L162.439 102.826C161.954 103.106 161.167 103.106 160.681 102.826L126.152 82.8934C125.667 82.6132 125.273 81.9315 125.273 81.3713L125.278 37.6974C125.278 37.1367 125.672 36.4551 126.157 36.1749Z"
      fill="#375A64"
    />
    <path
      d="M164.862 78.5775V56.7408C164.862 56.4603 164.764 56.1497 164.604 55.8744L159.914 58.582V102.382L160.685 102.826C161.17 103.106 161.957 103.106 162.443 102.826L163.983 101.937C164.469 101.657 164.862 100.975 164.862 100.415V78.5775Z"
      fill="#1A2E35"
    />
    <path
      d="M126.157 37.1899L160.682 57.1225C161.167 57.403 161.561 58.0843 161.561 58.645V102.319C161.561 102.879 161.167 103.106 160.681 102.826L126.152 82.8933C125.667 82.6132 125.273 81.9315 125.273 81.3712L125.278 37.6974C125.278 37.1367 125.672 36.9098 126.157 37.1899Z"
      fill="#2B454E"
    />
    <path
      d="M158.206 58.5329L128.629 41.4569C128.143 41.1764 127.75 41.4038 127.75 41.9644V75.1643C127.75 75.725 128.144 76.4066 128.629 76.6868L158.206 93.7627C158.691 94.0433 159.085 93.8159 159.085 93.2552V60.0554C159.085 59.4951 158.691 58.813 158.206 58.5329Z"
      fill="white"
    />
    <path
      d="M134.598 48.8743C136.171 49.7825 137.446 51.9912 137.446 53.8072C137.446 55.6237 136.171 56.3597 134.598 55.4515C133.025 54.5433 131.75 52.3351 131.75 50.5186C131.75 48.7022 133.025 47.9661 134.598 48.8743Z"
      fill="#2091EA"
    />
    <path
      d="M141.991 69.0821L136.695 61.1006C135.838 59.8099 134.357 59.7425 133.387 60.9499L127.75 67.9656V75.1823C127.75 75.743 128.144 76.4246 128.629 76.7048L158.203 93.7706C158.688 94.0507 159.082 93.8234 159.082 93.2631V74.5428L151.735 58.9906C151.073 57.5899 149.832 57.4941 148.964 58.7774L141.991 69.0821Z"
      fill="#2091EA"
    />
    <path
      d="M133.634 134.692L143.311 129.105C144.282 128.544 145.07 127.181 145.07 126.06L145.069 71.086C145.069 69.9646 144.282 68.6012 143.311 68.041L77.5607 30.08C76.59 29.5197 75.0159 29.5197 74.0448 30.08L64.3674 35.6673C63.3962 36.228 62.6094 37.591 62.6094 38.7123V93.6864C62.6094 94.8073 63.3966 96.1707 64.3678 96.7314L130.117 134.692C131.088 135.253 132.663 135.253 133.634 134.692Z"
      fill="#375A64"
    />
    <path
      d="M145.07 71.0857C145.069 70.5251 144.873 69.9043 144.554 69.3533L130.227 77.6252V134.743C131.203 135.247 132.695 135.233 133.632 134.693L143.313 129.104C144.283 128.543 145.069 127.182 145.07 126.061V71.0857Z"
      fill="#2B454E"
    />
    <path
      d="M130.117 75.6582L64.3674 37.6972C63.3966 37.1369 62.6094 37.5912 62.6094 38.7122L62.6098 93.6862C62.6098 94.8072 63.3966 96.1705 64.3678 96.7312L130.117 134.692C131.089 135.253 131.875 134.798 131.875 133.677V78.7032C131.875 77.5818 131.089 76.2185 130.117 75.6582Z"
      fill="#2B454E"
    />
    <path
      d="M135.891 72.3271C136.862 72.8878 137.649 74.2512 137.649 75.3721V132.376L139.299 131.424L139.298 74.4201C139.298 73.2991 138.511 71.9357 137.54 71.3751L70.0316 32.3991L68.3828 33.3515L135.891 72.3271Z"
      fill="#1A2E35"
    />
    <path
      d="M137.54 71.375L70.1395 32.4615L68.4375 33.3831L135.891 72.327C136.376 72.6076 136.816 73.0883 137.134 73.6392L138.783 72.6872C138.465 72.1362 138.025 71.6551 137.54 71.375Z"
      fill="#2B454E"
    />
    <path
      d="M127.317 62.6165L126.156 61.9462C125.671 61.666 124.883 61.666 124.398 61.9462L119.939 64.5206L119.195 64.2949V65.2469C119.195 65.4309 119.317 65.6148 119.559 65.7544L121.1 66.644C121.585 66.9241 122.372 66.9241 122.858 66.644L127.697 63.8503C127.939 63.7102 128.06 63.5267 128.06 63.3432V62.3907L127.317 62.6165Z"
      fill="#1A2E35"
    />
    <path
      d="M127.697 61.8832L126.156 60.994C125.671 60.7139 124.883 60.7139 124.398 60.994L119.559 63.7873C119.074 64.0678 119.074 64.5222 119.559 64.8023L121.1 65.6918C121.585 65.972 122.373 65.972 122.858 65.6918L127.697 62.8982C128.182 62.618 128.182 62.1633 127.697 61.8832Z"
      fill="#375A64"
    />
    <path
      d="M87.7388 39.7671L86.5781 39.0968C86.0925 38.8166 85.3048 38.8166 84.8197 39.0968L80.3602 41.6712L79.6172 41.4455V42.3975C79.6172 42.5815 79.7382 42.7654 79.981 42.905L81.5217 43.7946C82.0069 44.0747 82.7941 44.0747 83.2797 43.7946L88.118 41.0009C88.3604 40.8608 88.4818 40.6773 88.4818 40.4938H88.4822V39.5413L87.7388 39.7671Z"
      fill="#1A2E35"
    />
    <path
      d="M88.1184 39.0338L86.5781 38.1446C86.0925 37.8645 85.3057 37.8645 84.8201 38.1446L79.9814 40.9379C79.4958 41.2184 79.4958 41.6728 79.9814 41.9529L81.5217 42.8424C82.0073 43.1226 82.7945 43.1226 83.2801 42.8424L88.1184 40.0488C88.6036 39.7686 88.6036 39.3139 88.1184 39.0338Z"
      fill="#375A64"
    />
    <path
      d="M100.539 39.5397V45.2521L97.2422 47.1562V38.4475C97.2422 37.9969 97.5548 37.8182 97.9446 38.0415L100.539 39.5397Z"
      fill="#1A2E35"
    />
    <path
      d="M117.834 45.5971V56.214L115.386 57.631L113.737 56.6769V47.9682C113.737 47.5216 113.421 46.9735 113.036 46.7502L100.542 39.5396L97.9485 38.0415C97.5588 37.8182 97.2458 37.9968 97.2458 38.4475V47.1562L95.5938 46.2021V35.5893C95.5938 35.5446 95.5974 35.4959 95.6055 35.4512C95.6583 35.033 95.951 34.5702 96.2998 34.3713L97.3432 33.7663C97.7289 33.543 98.3582 33.543 98.748 33.7663L117.132 44.3791C117.521 44.6065 117.834 45.1505 117.834 45.5971Z"
      fill="#375A64"
    />
    <path
      d="M115.386 47.0142V57.6311L113.737 56.677V47.9683C113.737 47.5217 113.421 46.9736 113.036 46.7503L100.542 39.5398L97.9485 38.0416C97.5588 37.8183 97.2458 37.997 97.2458 38.4476V47.1563L95.5938 46.2022V35.5894C95.5938 35.5447 95.5978 35.496 95.6055 35.4513C95.6668 35.1062 95.9551 34.9844 96.2998 35.1834L114.684 45.7962C115.07 46.0236 115.386 46.5676 115.386 47.0142Z"
      fill="#2B454E"
    />
    <path
      d="M140.118 153.791L141.767 152.839L140.118 105.294C140.118 104.736 139.722 104.051 139.239 103.772L108.012 85.7434C107.529 85.464 106.984 84.8043 106.801 84.2765L105.817 81.4341C105.634 80.9063 105.086 80.2518 104.598 79.9794L89.8781 71.7461C89.3909 71.4737 88.9922 71.7075 88.9922 72.2658V123.259C88.9922 123.818 89.3876 124.503 89.8712 124.782L140.118 153.791Z"
      fill="#2091EA"
    />
    <path
      d="M116.086 73.2666L150.961 93.4022C151.35 93.6263 151.665 94.1716 151.665 94.6202V152.03C151.665 152.479 151.35 152.66 150.961 152.436L116.086 132.3C115.698 132.077 115.383 131.531 115.383 131.082L115.383 73.6726C115.383 73.2244 115.697 73.0425 116.086 73.2666Z"
      fill="white"
    />
    <g opacity="0.5">
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M115.772 73.3735C115.741 73.3735 115.713 73.38 115.69 73.3934C115.624 73.4315 115.586 73.5334 115.586 73.6727L115.586 131.082C115.586 131.459 115.862 131.937 116.188 132.125L151.063 152.261C151.183 152.329 151.291 152.349 151.358 152.309C151.424 152.271 151.462 152.17 151.462 152.03V94.6203C151.462 94.2435 151.187 93.7664 150.86 93.5777L115.984 73.4425C115.906 73.397 115.832 73.3735 115.772 73.3735ZM151.277 152.734C151.144 152.734 151.002 152.694 150.86 152.612L115.985 132.476C115.534 132.216 115.18 131.604 115.18 131.082L115.18 73.6727C115.18 73.3844 115.292 73.1542 115.487 73.0418C115.683 72.9289 115.938 72.9468 116.187 73.0909L151.063 93.2265C151.514 93.4871 151.868 94.099 151.868 94.6203V152.03C151.868 152.323 151.759 152.546 151.561 152.661C151.476 152.71 151.379 152.734 151.277 152.734Z"
          fill="#E1E1E1"
        />
      </g>
    </g>
    <path
      d="M119.559 80.9856L135.942 90.4441C136.427 90.7247 136.821 91.3779 136.821 91.9041C136.821 92.4299 136.428 92.6288 135.942 92.3487L119.559 82.8901C119.074 82.6096 118.68 81.9563 118.68 81.4305C118.68 80.9044 119.074 80.7054 119.559 80.9856Z"
      fill="#2B454E"
    />
    <path
      d="M119.559 86.6985L147.486 102.821C147.972 103.101 148.366 103.755 148.366 104.281C148.366 104.807 147.972 105.006 147.487 104.726L119.559 88.6027C119.074 88.3225 118.68 87.6693 118.68 87.1431C118.68 86.6173 119.074 86.4184 119.559 86.6985Z"
      fill="#E1E1E1"
    />
    <path
      d="M119.559 92.4117L147.486 108.534C147.972 108.814 148.366 109.468 148.366 109.994C148.366 110.52 147.972 110.719 147.487 110.438L119.559 94.3158C119.074 94.0357 118.68 93.3824 118.68 92.8566C118.68 92.3309 119.074 92.1315 119.559 92.4117Z"
      fill="#E1E1E1"
    />
    <path
      d="M119.559 98.125L147.486 114.248C147.972 114.528 148.366 115.181 148.366 115.707C148.366 116.233 147.972 116.432 147.487 116.152L119.559 100.029C119.074 99.749 118.68 99.0957 118.68 98.5699C118.68 98.0438 119.074 97.8448 119.559 98.125Z"
      fill="#E1E1E1"
    />
    <path
      d="M119.559 103.838L147.486 119.961C147.972 120.241 148.366 120.895 148.366 121.421C148.366 121.946 147.972 122.145 147.487 121.865L119.559 105.742C119.074 105.462 118.68 104.809 118.68 104.283C118.68 103.757 119.074 103.558 119.559 103.838Z"
      fill="#E1E1E1"
    />
    <path
      d="M119.559 109.551L147.486 125.674C147.972 125.954 148.366 126.608 148.366 127.134C148.366 127.66 147.972 127.859 147.487 127.578L119.559 111.455C119.074 111.176 118.68 110.522 118.68 109.996C118.68 109.47 119.074 109.271 119.559 109.551Z"
      fill="#E1E1E1"
    />
    <path
      d="M119.559 115.265L147.486 131.387C147.972 131.667 148.366 132.321 148.366 132.847C148.366 133.373 147.972 133.572 147.487 133.291L119.559 117.169C119.074 116.889 118.68 116.235 118.68 115.71C118.68 115.183 119.074 114.985 119.559 115.265Z"
      fill="#E1E1E1"
    />
    <path
      d="M119.559 120.978L147.486 137.101C147.972 137.38 148.366 138.034 148.366 138.56C148.366 139.086 147.972 139.285 147.487 139.005L119.559 122.882C119.074 122.602 118.68 121.949 118.68 121.423C118.68 120.897 119.074 120.698 119.559 120.978Z"
      fill="#E1E1E1"
    />
    <path
      d="M111.141 76.1199L146.016 96.2555C146.405 96.48 146.72 97.0253 146.72 97.4735V154.883C146.72 155.332 146.405 155.514 146.016 155.289L111.141 135.154C110.753 134.929 110.438 134.384 110.438 133.936L110.438 76.5259C110.438 76.0777 110.752 75.8958 111.141 76.1199Z"
      fill="white"
    />
    <path
      d="M110.827 76.2268C110.795 76.2268 110.768 76.2333 110.745 76.2463C110.679 76.2848 110.64 76.3867 110.64 76.526L110.641 133.936C110.641 134.312 110.917 134.789 111.242 134.978L146.118 155.114C146.239 155.183 146.345 155.202 146.412 155.163C146.479 155.125 146.517 155.023 146.517 154.883V97.4735C146.517 97.0972 146.241 96.6197 145.915 96.4313L111.039 76.2958C110.96 76.2503 110.887 76.2268 110.827 76.2268ZM146.332 155.588C146.199 155.588 146.056 155.547 145.915 155.465L111.039 135.329C110.588 135.069 110.235 134.456 110.235 133.936L110.234 76.526C110.234 76.2377 110.346 76.0075 110.542 75.8951C110.737 75.7826 110.992 75.7997 111.242 75.9442L146.118 96.0798C146.569 96.3404 146.923 96.9531 146.923 97.4735V154.883C146.923 155.172 146.811 155.402 146.615 155.514C146.53 155.564 146.434 155.588 146.332 155.588Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 83.8391L130.996 93.2976C131.482 93.5782 131.875 94.2314 131.875 94.7572C131.876 95.2834 131.482 95.4823 130.997 95.2022L114.613 85.7432C114.128 85.4631 113.734 84.8094 113.734 84.2836C113.734 83.7579 114.128 83.5589 114.613 83.8391Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 89.5518L142.541 105.674C143.026 105.955 143.42 106.608 143.42 107.134C143.42 107.66 143.026 107.859 142.541 107.579L114.613 91.4559C114.128 91.1758 113.734 90.5226 113.734 89.9968C113.734 89.4706 114.128 89.2717 114.613 89.5518Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 95.2651L142.541 111.388C143.026 111.668 143.42 112.322 143.42 112.847C143.42 113.374 143.026 113.573 142.541 113.292L114.613 97.1693C114.128 96.8891 113.734 96.2359 113.734 95.7097C113.734 95.1839 114.128 94.985 114.613 95.2651Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 100.979L142.541 117.101C143.026 117.381 143.42 118.035 143.42 118.561C143.42 119.087 143.026 119.285 142.541 119.005L114.613 102.883C114.128 102.603 113.734 101.949 113.734 101.423C113.734 100.897 114.128 100.698 114.613 100.979Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 106.691L142.541 122.814C143.026 123.094 143.42 123.748 143.42 124.274C143.42 124.8 143.026 124.999 142.541 124.718L114.613 108.596C114.128 108.315 113.734 107.662 113.734 107.136C113.734 106.611 114.128 106.411 114.613 106.691Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 112.405L142.541 128.527C143.026 128.808 143.42 129.461 143.42 129.987C143.42 130.513 143.026 130.712 142.541 130.432L114.613 114.309C114.128 114.029 113.734 113.376 113.734 112.85C113.734 112.324 114.128 112.125 114.613 112.405Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 118.118L142.541 134.241C143.026 134.521 143.42 135.175 143.42 135.7C143.42 136.226 143.026 136.425 142.541 136.145L114.613 120.022C114.128 119.742 113.734 119.089 113.734 118.563C113.734 118.037 114.128 117.838 114.613 118.118Z"
      fill="#E1E1E1"
    />
    <path
      d="M114.613 123.831L142.541 139.954C143.026 140.234 143.42 140.888 143.42 141.414C143.42 141.94 143.026 142.138 142.541 141.858L114.613 125.735C114.128 125.455 113.734 124.802 113.734 124.276C113.734 123.75 114.128 123.551 114.613 123.831Z"
      fill="#E1E1E1"
    />
    <path
      d="M140.121 153.791L132.059 110.038C131.958 109.489 131.479 108.811 130.996 108.532L81.6281 80.0301C81.145 79.7512 80.8319 79.9721 80.933 80.521L88.8111 123.276C88.9126 123.825 89.3904 124.503 89.874 124.782L140.121 153.791Z"
      fill="#2091EA"
    />
    <path
      d="M79.1016 100.533V128.972C79.1016 129.533 79.4954 130.214 79.981 130.495L99.6622 141.858C100.147 142.139 100.91 142.152 101.366 141.889C101.821 141.627 102.19 140.959 102.19 140.398V111.959C102.19 111.399 101.797 110.717 101.311 110.437L81.6297 99.0733C81.1441 98.7931 80.3813 98.7793 79.9261 99.042C79.4706 99.3051 79.1016 99.9726 79.1016 100.533Z"
      fill="#375A64"
    />
    <path
      d="M102.192 126.179V111.959C102.192 111.679 102.094 111.368 101.935 111.093L99.4609 112.521V141.741L99.6643 141.858C100.15 142.138 100.912 142.152 101.368 141.889L101.369 141.889C101.824 141.626 102.192 140.959 102.193 140.398L102.192 126.179Z"
      fill="#1A2E35"
    />
    <path
      d="M79.9806 100.026L99.6622 111.388C100.147 111.669 100.541 112.351 100.541 112.911V141.35C100.541 141.911 100.147 142.138 99.6622 141.858L79.9806 130.495C79.4954 130.214 79.1016 129.533 79.1016 128.972V100.533C79.1016 99.9726 79.495 99.7456 79.9806 100.026Z"
      fill="#2B454E"
    />
    <path
      d="M81.629 102.882L98.0123 112.341C98.4975 112.621 98.8913 113.303 98.8913 113.863V134.685C98.8913 135.246 98.4979 135.473 98.0123 135.193L81.629 125.734C81.1438 125.454 80.75 124.772 80.75 124.211V103.39C80.75 102.829 81.1434 102.602 81.629 102.882Z"
      fill="white"
    />
    <path
      d="M84.714 107.742C85.7903 108.363 86.6624 109.874 86.6624 111.117C86.6624 112.359 85.7903 112.863 84.714 112.242C83.6381 111.621 82.7656 110.109 82.7656 108.867C82.7656 107.624 83.6381 107.121 84.714 107.742Z"
      fill="#2091EA"
    />
    <path
      d="M93.0331 115.146L88.9955 121.112L85.9289 116.491C85.4328 115.743 84.5753 115.704 84.0138 116.403L80.75 120.466V124.212C80.75 124.772 81.1434 125.454 81.629 125.734L98.0123 135.193C98.4975 135.473 98.8909 135.246 98.8909 134.685V124.273L94.6372 115.269C94.254 114.458 93.5362 114.403 93.0331 115.146Z"
      fill="#2091EA"
    />
    <path
      d="M17.1305 95.3032C29.984 87.5551 37.8702 73.6301 37.8702 58.5821V44.1594C37.8702 43.7128 38.1865 43.5301 38.573 43.7534L75.0983 64.841C75.4848 65.0643 75.8015 65.6124 75.8015 66.059V80.4822C75.8015 95.468 67.9804 109.341 55.2202 117.107C54.6944 117.427 54.0322 117.425 53.4991 117.117L17.1337 96.1221C16.7472 95.8988 16.748 95.5338 17.1305 95.3032Z"
      fill="white"
    />
    <path
      d="M17.235 95.4769C17.1145 95.55 17.0458 95.6373 17.0467 95.716C17.0475 95.7936 17.1161 95.8772 17.235 95.9462L53.6005 116.942C54.0751 117.216 54.6553 117.213 55.1144 116.934C67.7492 109.244 75.5984 95.2759 75.5984 80.4821V66.0589C75.5984 65.6826 75.3227 65.2051 74.9967 65.0171L38.4713 43.9291C38.3511 43.8597 38.2439 43.8426 38.1777 43.8808C38.1108 43.9194 38.0726 44.0209 38.0726 44.1593V58.5821C38.0726 73.5919 30.0882 87.7292 17.235 95.4769ZM54.356 117.552C54.0268 117.552 53.6955 117.465 53.3975 117.293L17.032 96.2974C16.7815 96.1533 16.6427 95.9478 16.6407 95.7201C16.6382 95.4903 16.775 95.2808 17.0251 95.1298C29.7573 87.4547 37.6666 73.4506 37.6666 58.5821V44.1593C37.6666 43.8719 37.779 43.6425 37.9747 43.5292C38.1708 43.4159 38.4254 43.4338 38.6743 43.5775L75.1997 64.6651C75.6507 64.9258 76.0044 65.538 76.0044 66.0589V80.4821C76.0044 95.4168 68.0805 109.517 55.3252 117.281C55.0292 117.461 54.6934 117.552 54.356 117.552Z"
      fill="#E1E1E1"
    />
    <path
      d="M60.1915 61.9458L69.9777 67.5961C70.4633 67.8767 70.8571 68.5299 70.8571 69.0561C70.8571 69.5819 70.4633 69.7808 69.9777 69.5007L60.1915 63.8499C59.7059 63.5698 59.3125 62.9166 59.3125 62.3904C59.3125 61.8646 59.7059 61.6657 60.1915 61.9458Z"
      fill="#E1E1E1"
    />
    <path
      d="M54.3629 67.1496C54.3629 67.6754 53.9695 67.8747 53.4839 67.5946L43.6993 61.9468C43.2137 61.6666 42.8203 61.013 42.8203 60.4872C42.8203 59.9614 43.2137 59.7625 43.6993 60.0426L53.4839 65.6901C53.9695 65.9706 54.3629 66.6239 54.3629 67.1496Z"
      fill="#E1E1E1"
    />
    <path
      d="M70.372 84.0084C70.2981 84.4915 69.8353 84.6547 69.3497 84.3742L43.0779 69.2064C42.5923 68.9259 42.2659 68.3071 42.3381 67.8232C42.4104 67.3388 42.8521 67.172 43.3377 67.4521L69.6181 82.6243C70.1032 82.9049 70.4463 83.5252 70.372 84.0084Z"
      fill="#E1E1E1"
    />
    <path
      d="M69.2891 89.0982C69.1628 89.5513 68.657 89.689 68.1718 89.4088L41.8991 74.2366C41.4135 73.9565 41.1306 73.3641 41.2564 72.911C41.3827 72.4583 41.8683 72.3162 42.3538 72.5964L68.6302 87.7686C69.1157 88.0487 69.4154 88.6451 69.2891 89.0982Z"
      fill="#E1E1E1"
    />
    <path
      d="M67.6585 93.8676C67.4843 94.2926 66.9419 94.4067 66.4567 94.1266L40.1804 78.958C39.6948 78.6779 39.4525 78.1099 39.6266 77.684C39.8004 77.2577 40.3241 77.1351 40.8097 77.4156L67.0856 92.5878C67.5712 92.868 67.8327 93.4429 67.6585 93.8676Z"
      fill="#E1E1E1"
    />
    <path
      d="M65.5113 98.3439C65.2908 98.7422 64.709 98.8323 64.2235 98.5522L37.9516 83.3876C37.466 83.1075 37.2586 82.5614 37.4778 82.1623C37.6975 81.7628 38.2618 81.6621 38.747 81.9423L65.0233 97.1105C65.5089 97.3906 65.7318 97.946 65.5113 98.3439Z"
      fill="#E1E1E1"
    />
    <path
      d="M50.3398 97.204C50.0588 97.5673 49.4315 97.6303 48.946 97.3501L34.2122 88.8444C33.7271 88.5639 33.5683 88.0462 33.8501 87.6825C34.1319 87.3183 34.7461 87.2448 35.2317 87.5249L49.9654 96.0343C50.4506 96.3148 50.6207 96.8402 50.3398 97.204Z"
      fill="#E1E1E1"
    />
    <path
      d="M44.9616 105.074C44.9616 105.074 37.2362 110.168 27.9766 110.522C29.8636 111.612 34.1222 112.064 37.4128 111.612C37.4128 111.612 36.9524 113.525 33.6382 115.97C33.6382 115.97 39.0352 117.426 48.7361 107.253L44.9616 105.074Z"
      fill="#2091EA"
    />
    <path
      d="M53.1178 106.989C51.465 108.669 48.1309 108.81 45.8529 107.495C43.5748 106.18 43.2159 103.909 44.8687 102.229C46.5211 100.55 49.5507 100.109 51.8292 101.423C54.1073 102.737 54.7707 105.308 53.1178 106.989Z"
      fill="#2091EA"
    />
    <path
      d="M53.1178 106.48C51.465 108.161 48.1309 108.301 45.8529 106.986C43.5748 105.671 43.2159 103.4 44.8687 101.72C46.5211 100.041 49.5507 99.6004 51.8292 100.914C54.1073 102.228 54.7707 104.8 53.1178 106.48Z"
      fill="#2091EA"
    />
    <path
      d="M49.289 106.651C48.2711 106.651 47.2675 106.396 46.4628 105.931C45.6565 105.465 45.164 104.855 45.0767 104.212C44.9813 103.511 45.3837 102.934 45.7377 102.575C46.4831 101.817 47.6613 101.365 48.8895 101.365C49.7295 101.365 50.5358 101.574 51.2215 101.969C52.1038 102.478 52.6945 103.255 52.8021 104.047C52.8821 104.632 52.6913 105.178 52.2503 105.626C51.6093 106.278 50.5301 106.651 49.289 106.651Z"
      fill="#2091EA"
    />
    <path
      d="M51.221 102.481C50.5357 102.086 49.729 101.877 48.8889 101.877C47.6608 101.877 46.4826 102.329 45.7372 103.087C45.4655 103.363 45.1675 103.767 45.0859 104.256C45.1895 104.883 45.6746 105.476 46.4623 105.931C47.267 106.396 48.2706 106.651 49.2884 106.651C50.5296 106.651 51.6087 106.278 52.2498 105.626C52.5612 105.309 52.7451 104.943 52.7999 104.55C52.6887 103.76 52.0996 102.988 51.221 102.481Z"
      fill="#2091EA"
    />
    <path
      d="M68.3936 93.2832C68.3936 94.9709 67.0254 96.3391 65.3376 96.3391C63.6495 96.3391 62.2812 94.9709 62.2812 93.2832C62.2812 91.5954 63.6495 90.2272 65.3376 90.2272C67.0254 90.2272 68.3936 91.5954 68.3936 93.2832Z"
      fill="#1A2E35"
    />
    <path d="M72.6526 148.679H70.5V144.994H72.6526V148.679Z" fill="#FFAAA8" />
    <path
      d="M65.1887 144.797L63.0373 144.871L62.9297 140.971L65.1887 140.897V144.797Z"
      fill="#FFAAA8"
    />
    <path
      d="M72.5115 113.409C72.5115 115.465 72.7775 130.295 72.7775 130.295C72.8274 130.886 73.382 131.976 73.2622 134.248C73.099 137.335 72.745 146.836 72.745 146.836C72.745 146.836 71.6999 147.447 70.348 146.883C70.348 146.883 68.4487 134.259 68.063 131.2C67.7264 128.531 67.1552 121.281 67.1552 121.281L65.9883 129.994C65.9883 129.994 66.385 131.586 66.2551 133.415C66.1544 134.83 65.3253 143.589 65.3253 143.589C65.3253 143.589 64.3124 144.276 62.8402 143.779C62.8402 143.779 61.7521 131.292 61.7274 129.994C61.6989 128.514 62.4431 113.409 62.4431 113.409H72.5115Z"
      fill="#2B454E"
    />
    <path
      d="M71.591 101.731C71.591 101.731 72.3904 102.217 72.3904 104.17V108.388L72.4521 116.352C69.362 117.98 65.4604 118.075 62.1641 115.668C62.1641 115.668 62.322 105.431 62.322 103.754C62.322 102.078 63.2562 99.8979 65.8286 99.7485L69.1497 100.52L71.591 101.731Z"
      fill="white"
    />
    <path
      d="M62.3427 110.532C63.3419 106.339 63.6691 102.146 63.84 101.064L61.0768 105.482C61.0768 105.482 60.4637 108.913 60.1503 110.73C59.875 112.326 58.955 115.2 58.4268 116.456C58.3334 116.679 57.8714 116.98 57.6538 117.084C57.0696 117.363 56.8097 117.688 56.3501 118.071C55.99 118.371 55.7066 118.424 55.7367 118.536C55.7752 118.679 55.9795 118.929 56.3875 118.853C56.5921 118.815 56.8662 118.69 57.2088 118.522C57.2088 118.522 56.9039 119.166 56.2612 119.793C55.3895 120.643 55.2901 121.215 56.8325 121.405C57.7987 121.524 58.6388 121.015 59.6099 118.966C59.8896 118.377 60.3285 117.444 60.5628 116.651C61.1734 114.584 62.0402 111.8 62.3427 110.532Z"
      fill="#FFAAA8"
    />
    <path
      d="M64.5596 99.7509C63.6279 99.6019 62.9868 99.6827 62.3498 100.37C61.9495 100.802 61.5325 101.514 60.837 105.812C60.2321 109.547 58.5078 115.715 58.5078 115.715C58.5078 115.715 59.4152 116.819 60.8577 116.563L63.8942 105.392L64.5596 99.7509Z"
      fill="#2B454E"
    />
    <path
      d="M65.9498 99.9318C65.9498 99.9318 64.7854 100.856 64.4703 102.222C64.1553 103.588 64.1045 118.843 64.1045 118.843C64.1045 118.843 62.485 118.544 61.7266 117.948V104.008C61.7266 102.011 62.1756 98.9724 65.9498 99.9318Z"
      fill="#2B454E"
    />
    <path
      d="M69.2764 100.705C69.2764 100.703 67.4718 102.574 67.4348 107.142C67.3975 111.71 67.3203 119.72 67.3203 119.72C67.3203 119.72 70.4944 120.366 72.7583 119.013C72.7583 119.013 72.5017 107.571 72.4587 105.855C72.4095 103.892 72.9836 102.223 71.8789 101.669C70.7742 101.115 69.2764 100.705 69.2764 100.705Z"
      fill="#2B454E"
    />
    <path
      d="M70.615 95.4562C70.615 95.4562 70.12 97.7826 69.9215 98.1651C69.7226 98.5471 69.3125 98.6559 69.3125 98.6559L69.347 97.0193L70.615 95.4562Z"
      fill="#1A2E35"
    />
    <path
      d="M69.9989 92.123C69.9989 92.123 70.5324 92.0317 70.9481 92.4567C71.3558 92.8737 71.128 94.1782 70.6205 95.4563L69.6875 95.5634L69.9989 92.123Z"
      fill="#1A2E35"
    />
    <path
      d="M69.4078 95.5641C69.5946 95.6753 69.8617 95.3164 70.0923 95.0749C70.3229 94.8337 71.0761 94.504 71.4516 95.2953C71.8345 96.102 71.1199 97.1678 70.5271 97.3537C69.5057 97.6745 69.3522 97.0192 69.3522 97.0192L69.2742 100.704L65.7335 103.039C65.7335 103.039 65.5045 101.757 65.9503 100.607L65.9532 99.4852C65.9532 99.4852 65.0275 99.6261 64.576 99.5563C63.8245 99.4402 63.3515 98.8474 63.1237 98.0338C62.7579 96.7277 62.6187 95.6729 62.9293 93.1037C63.2699 90.2877 66.5451 90.2589 68.3136 91.3717C70.0822 92.485 69.4078 95.5641 69.4078 95.5641Z"
      fill="#FFAAA8"
    />
    <path
      d="M65.7344 103.039L69.2869 100.147L69.2966 99.6826C69.2966 99.6826 70.122 99.8474 70.2284 101.001L68.0721 104.285L67.1598 102.574L65.7344 103.039Z"
      fill="#F0F0F0"
    />
    <path
      d="M65.9544 99.4853C65.9544 99.4853 65.1867 99.5876 64.8493 100.147C64.5123 100.706 63.8984 103.039 63.8984 103.039L65.0466 102.153L65.7348 103.039C65.6625 102.328 65.7291 101.521 65.9516 100.607L65.9544 99.4853Z"
      fill="#F0F0F0"
    />
    <path
      d="M69.4044 95.8785C69.4982 95.8805 69.8563 95.287 70.089 95.075C70.4117 94.7815 69.9956 92.123 69.9956 92.123C69.9956 92.123 70.3053 91.4624 69.6842 90.7783C69.2424 90.2919 68.5035 90.2379 67.2425 90.0398C66.5807 89.9359 65.5779 89.7503 64.9169 89.6277C64.1281 89.4812 63.4752 89.3257 62.9178 89.81C62.4842 90.1864 62.2978 90.8526 62.5309 91.3772C62.5857 91.5006 62.6648 91.6114 62.755 91.7117C62.9149 91.8896 63.0956 92.0292 63.323 92.1027C63.323 92.1027 63.6668 92.408 64.8637 92.5542C66.0606 92.6995 67.9246 92.5258 68.1795 92.4908C68.5267 92.4433 68.6006 92.7799 68.7877 93.6987C68.9522 94.5054 69.0882 95.8724 69.4044 95.8785Z"
      fill="#1A2E35"
    />
    <path
      d="M69.9924 92.33L71.1236 91.7226C70.9632 91.3986 70.5795 91.2719 70.2673 91.4396C69.9551 91.6073 69.8316 92.0056 69.9924 92.33Z"
      fill="#1A2E35"
    />
    <path
      d="M65.9531 99.4853C65.9531 99.4853 67.6303 99.1475 68.217 98.8357C68.6092 98.6275 68.9324 98.2657 69.0274 98.0339C69.0274 98.0339 68.9173 98.5312 68.5666 98.9799C68.1378 99.528 65.9531 99.9319 65.9531 99.9319V99.4853Z"
      fill="#FF9090"
    />
    <path
      d="M66.3516 95.1446C66.3516 95.3379 66.5022 95.4934 66.6877 95.493C66.8733 95.4922 67.0235 95.3359 67.0235 95.1426C67.0235 94.9501 66.8733 94.7942 66.6877 94.7946C66.5022 94.795 66.3516 94.9518 66.3516 95.1446Z"
      fill="#1A2E35"
    />
    <path
      d="M66.7109 93.8759L67.4299 94.2729C67.5359 94.0663 67.4608 93.8097 67.2623 93.7001C67.0637 93.5904 66.8169 93.6692 66.7109 93.8759Z"
      fill="#1A2E35"
    />
    <path
      d="M66.1033 97.4665L65.3359 97.7543C65.4119 97.9743 65.6453 98.088 65.8572 98.0085C66.0692 97.9293 66.1792 97.6865 66.1033 97.4665Z"
      fill="#FF9090"
    />
    <path
      d="M63.3611 94.1554L64.0168 93.6507C63.8832 93.4628 63.6279 93.4234 63.4468 93.563C63.2657 93.7027 63.2271 93.9678 63.3611 94.1554Z"
      fill="#1A2E35"
    />
    <path
      d="M63.5625 95.0491C63.5625 95.2419 63.7131 95.3974 63.8987 95.397C64.0842 95.3966 64.2348 95.2403 64.2348 95.0471C64.2348 94.8546 64.0842 94.6983 63.8987 94.6991C63.7131 94.6996 63.5625 94.8559 63.5625 95.0491Z"
      fill="#1A2E35"
    />
    <path
      d="M65.275 94.7303L65.1646 96.7518L64.1094 96.3916L65.275 94.7303Z"
      fill="#FF9090"
    />
    <path
      d="M70.4995 147.616V147.308C70.2937 147.398 69.815 149.224 68.9608 150.003C68.2819 150.622 67.3554 151.338 67.12 152.028C66.8751 152.746 68.4102 153.142 69.0444 153.03C69.7813 152.9 71.059 152.381 71.3797 151.899C71.7004 151.418 71.8507 150.709 72.1113 150.356C72.3716 150.003 72.9724 149.61 73.1133 149.202C73.1909 148.978 73.0987 148.487 72.9899 148.072C72.8904 147.691 72.782 147.272 72.6521 147.308C72.6517 147.354 72.6521 147.543 72.6521 147.543C72.4958 147.716 72.0638 147.888 71.5758 147.907C71.2608 147.919 70.4971 147.857 70.4995 147.616Z"
      fill="#1A2E35"
    />
    <path
      d="M63.017 144.207C62.9054 144.211 62.814 144.368 62.5676 144.636C62.2696 144.958 61.7824 145.306 61.3013 145.547C60.4953 145.95 58.9282 146.571 58.2189 146.89C57.7756 147.09 57.7967 147.625 58.2546 147.93C58.7126 148.234 59.8185 148.454 61.1462 148.157C61.8693 147.995 62.8112 147.327 63.4068 147.363C64.0028 147.399 65.1181 147.391 65.5582 147.087C65.9979 146.784 65.8221 146.137 65.6978 145.58C65.5622 144.973 65.3921 144.026 65.1911 144.067V144.277C64.9325 144.668 63.5968 144.828 63.0284 144.539L63.017 144.207Z"
      fill="#1A2E35"
    />
    <path
      d="M67.1538 121.28C67.1538 121.28 65.4331 120.796 64.1562 119.562C64.1562 119.562 64.7344 120.645 66.4546 121.653L66.0868 129.248L67.1538 121.28Z"
      fill="#1A2E35"
    />
    <path
      d="M66.7685 102.7C66.6487 102.408 66.2261 102.273 65.8286 102.267C65.4831 102.261 65.2013 102.546 65.2196 102.891C65.2358 103.2 65.2862 103.586 65.4254 103.862C65.4254 103.862 65.9268 103.741 66.3227 103.942C66.3227 103.942 66.6775 103.463 66.7685 102.7Z"
      fill="#1A2E35"
    />
    <path
      d="M65.4245 103.862C65.4245 103.862 64.6754 110.004 64.6957 110.366C64.7156 110.728 65.4789 112.855 65.8699 112.855C66.2998 112.855 67.0241 110.944 67.0444 110.643C67.0643 110.341 66.3218 103.942 66.3218 103.942C66.0579 103.707 65.6884 103.707 65.4245 103.862Z"
      fill="#1A2E35"
    />
    <path
      d="M70.4417 137.091L68.8644 136.181C68.7064 136.089 68.5781 135.867 68.5781 135.685V126.726C68.5781 126.544 68.7064 126.322 68.8648 126.23L79.5791 120.044C79.7374 119.952 79.994 119.952 80.152 120.044L81.7293 120.954C81.8872 121.046 82.0159 121.268 82.0159 121.451L82.0155 130.409C82.0155 130.592 81.8872 130.814 81.7293 130.905L71.0145 137.091C70.8562 137.183 70.6 137.183 70.4417 137.091Z"
      fill="#2091EA"
    />
    <path
      d="M68.5781 126.726C68.5781 126.634 68.6102 126.533 68.6622 126.444L70.9971 127.792V137.1C70.8375 137.182 70.5943 137.179 70.4417 137.091L68.8644 136.181C68.706 136.089 68.5781 135.868 68.5781 135.685V126.726Z"
      fill="#FF9090"
    />
    <path
      d="M68.5781 126.726C68.5781 126.634 68.6102 126.533 68.6622 126.444L70.9971 127.792V137.1C70.8375 137.182 70.5943 137.179 70.4417 137.091L68.8644 136.181C68.706 136.089 68.5781 135.868 68.5781 135.685V126.726Z"
      fill="#2091EA"
    />
    <path
      d="M71.0132 127.471L81.7279 121.285C81.8858 121.194 82.0145 121.268 82.0145 121.451L82.0141 130.409C82.0141 130.592 81.8858 130.814 81.7279 130.905L71.0132 137.091C70.8549 137.183 70.7266 137.108 70.7266 136.926V127.968C70.7266 127.785 70.8549 127.563 71.0132 127.471Z"
      fill="#2091EA"
    />
    <path
      d="M70.071 126.928C69.9127 127.02 69.7844 127.242 69.7844 127.425V136.714L69.5156 136.559V127.269C69.5156 127.087 69.6439 126.865 69.8023 126.773L80.8036 120.422L81.072 120.577L70.071 126.928Z"
      fill="#2091EA"
    />
    <path
      d="M69.8042 126.773L80.7877 120.432L81.065 120.582L70.0729 126.928C69.9938 126.974 69.9223 127.052 69.8703 127.142L69.6016 126.987C69.6535 126.897 69.725 126.819 69.8042 126.773Z"
      fill="#2091EA"
    />
    <path
      d="M71.4726 125.346L71.6617 125.236C71.7409 125.191 71.8692 125.191 71.948 125.236L72.6747 125.656L72.7961 125.62V125.775C72.7961 125.804 72.7762 125.834 72.7368 125.858L72.4855 126.002C72.4068 126.048 72.2785 126.048 72.1993 126.002L71.4108 125.547C71.3711 125.524 71.3516 125.494 71.3516 125.464V125.309L71.4726 125.346Z"
      fill="#2091EA"
    />
    <path
      d="M71.4109 125.226L71.6618 125.082C71.741 125.036 71.8693 125.036 71.9485 125.082L72.7369 125.537C72.8161 125.582 72.8161 125.657 72.7369 125.702L72.4856 125.847C72.4069 125.893 72.2786 125.893 72.1994 125.847L71.4109 125.392C71.3318 125.346 71.3318 125.272 71.4109 125.226Z"
      fill="#2091EA"
    />
    <path
      d="M77.5585 122.098L77.7477 121.988C77.8269 121.943 77.9552 121.943 78.0339 121.988L78.7607 122.408L78.8821 122.372V122.527C78.8821 122.556 78.8622 122.586 78.8228 122.61L78.5715 122.754C78.4927 122.8 78.3644 122.8 78.2852 122.754L77.4968 122.299C77.457 122.276 77.4375 122.246 77.4375 122.216V122.061L77.5585 122.098Z"
      fill="#2091EA"
    />
    <path
      d="M77.4969 121.978L77.7478 121.834C77.827 121.788 77.9553 121.788 78.0344 121.834L78.8229 122.289C78.902 122.334 78.902 122.409 78.8229 122.454L78.5716 122.599C78.4928 122.645 78.3645 122.645 78.2853 122.599L77.4969 122.144C77.4177 122.098 77.4177 122.024 77.4969 121.978Z"
      fill="#2091EA"
    />
    <path
      d="M75.8359 121.585V122.516L76.3731 122.827V121.408C76.3731 121.334 76.3223 121.305 76.259 121.341L75.8359 121.585Z"
      fill="#2091EA"
    />
    <path
      d="M73.0156 122.573V124.303L73.4143 124.534L73.6835 124.378V122.959C73.6835 122.886 73.7347 122.797 73.7976 122.761L75.8333 121.585L76.2563 121.341C76.3197 121.305 76.3708 121.334 76.3708 121.407V122.826L76.64 122.671V120.941C76.64 120.934 76.6392 120.926 76.638 120.919C76.6294 120.851 76.5819 120.775 76.5251 120.743L76.355 120.645C76.292 120.608 76.1893 120.608 76.126 120.645L73.1301 122.374C73.0664 122.411 73.0156 122.5 73.0156 122.573Z"
      fill="#2091EA"
    />
    <path
      d="M73.4141 122.803V124.534L73.6828 124.378V122.959C73.6828 122.886 73.7344 122.796 73.7973 122.761L75.833 121.585L76.2561 121.341C76.3194 121.305 76.3702 121.334 76.3702 121.408V122.826L76.6393 122.671V120.941C76.6393 120.934 76.6389 120.926 76.6377 120.919C76.6276 120.863 76.5805 120.843 76.5244 120.875L73.5286 122.605C73.4656 122.642 73.4141 122.73 73.4141 122.803Z"
      fill="#2091EA"
    />
    <path
      d="M75.7336 118.511C75.7336 118.511 75.7202 119.045 75.7336 119.276C75.747 119.506 76.0506 120.714 76.0454 121.409C76.0994 121.66 75.7514 122.162 75.1737 122.433C74.9272 122.548 74.4981 122.947 74.302 122.968C74.1696 122.981 73.7973 122.761 73.7973 122.761L74.0426 122.619C74.0426 122.619 74.3783 122.129 74.0101 121.866L73.2805 122.287C73.2805 122.287 73.2119 121.986 73.2805 121.62C73.3487 121.254 73.356 120.785 73.4145 120.403C73.4729 120.021 73.7977 119.56 73.7977 119.56L73.6759 118.354L75.7336 118.511Z"
      fill="#FFAAA8"
    />
    <path
      d="M71.1971 101.365C71.1971 101.365 72.4525 101.818 73.0132 102.267C73.4654 102.628 76.0935 110.163 76.1954 110.842C76.2969 111.52 75.9087 118.657 75.9087 118.657C75.9087 118.657 74.9258 119.411 73.5219 118.811L73.1005 111.655L70.837 105.764C70.837 105.764 69.9868 103.301 71.1971 101.365Z"
      fill="#2B454E"
    />
  </svg>
);

export default Onboarding;
