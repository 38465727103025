import { deepKey } from 'utils/utils';

const getValues = (fieldsObject) => {
  let newObject = {};
  const fieldsArray = Object.keys(fieldsObject);
  fieldsArray.forEach((field) => {
    deepKey(newObject, field, fieldsObject[field].value);
  });
  return newObject;
};

export default getValues;
