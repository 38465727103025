import React from 'react'

import { connect } from 'react-redux'
import * as mainActions from '../../actions'
import * as commons from 'utils/UtilityStore/actions'

import SectionWrapper from 'components/SectionWrapper'

import ToggleRequirement from 'features/ToggleRequirement'

import ProcessTask from 'services/ProcessTask'
import ArchiveProposal from 'features/ArchiveProposal'

import withModal from 'components/HOCs/withModal'
import GuaranteeFlow from './components/GuaranteeFlow'

class Guarantee extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldBlock: true,
    }
  }

  componentDidMount() {
    const { company } = this.props

    const profiles = company.profiles

    let isAgent = false

    profiles.map((profile) => {
      if (profile.code === 'PROFILE_AGENT') isAgent = true
    })

    if (!isAgent) return this.setState({ shouldBlock: false })

    const allButtons = document.querySelectorAll('#form-blocked button')

    for (let i = 0; i < allButtons.length; i++) {
      const currentButton = allButtons[i]

      const clone = currentButton.cloneNode(true)

      const parent = currentButton.parentNode

      parent.appendChild(clone)

      currentButton.remove()
    }
  }

  render() {
    const { current, activeCompany } = this.props

    const { proposal = {}, company } = current
    const { guarantee = {} } = proposal

    const { guarantee_evaluation, requirements } = this.props.current

    const { status } = ProcessTask(requirements, 'REQUIREMENT_GUARANTEE')
    const isApproved = ProcessTask(
      requirements,
      'REQUIREMENT_GUARANTEE',
      'TASK_SET_GUARANTEE_APPROVED',
    )
    const isRejected = guarantee_evaluation === 0 ? true : false

    const guaranteeCode = guarantee && guarantee.code

    const { shouldBlock } = this.state

    return shouldBlock ? (
      <>
        <form id='form-blocked'>
          <fieldset disabled='disabled'>
            <GuaranteeFlow
              {...this.props}
              current={current}
              isRejected={isRejected}
              isApproved={isApproved.status}
            />

            {activeCompany ? (
              <SectionWrapper header='Gerenciar etapa'>
                <ToggleRequirement
                  processId={current.id}
                  onSuccess={this.closeRequirementSuccess.bind(this)}
                  requirement='guarantee'
                  requirementStatus={status}
                  companyId={activeCompany.id}
                />
              </SectionWrapper>
            ) : null}
          </fieldset>
        </form>
      </>
    ) : (
      <>
        <GuaranteeFlow
          {...this.props}
          current={current}
          isRejected={isRejected}
          isApproved={isApproved.status}
        />

        {activeCompany ? (
          <SectionWrapper header='Gerenciar etapa'>
            <ToggleRequirement
              processId={current.id}
              onSuccess={this.closeRequirementSuccess.bind(this)}
              requirement='guarantee'
              requirementStatus={status}
              companyId={activeCompany.id}
            />
          </SectionWrapper>
        ) : null}
      </>
    )
  }

  archiveProposal(proposalId, companyId) {
    this.props.openModal(
      'Arquivar proposta',
      <ArchiveProposal
        proposalId={proposalId}
        activeCompany={companyId}
        defaultValue={{ status_comment: 'Garantia reprovada' }}
        onSuccess={this.onArchiveProposalSuccess.bind(this)}
      />,
    )
  }

  onArchiveProposalSuccess(response) {
    if (response.isSuccess) {
      this.props.closeModal()
      let delayRedirect = timeDelay(400)
      delayRedirect(() => {
        this.props.history.push('/')
      })
    } else {
      this.props.notification({
        message: response.actionMessage,
        status: 'danger',
      })
    }
  }

  closeRequirementSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({ message: 'Etapa alterada com sucesso' })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar alterar a etapa',
        status: 'danger',
      })
    }
  }
}

export default connect(
  (store) => ({
    current: store.processDetails.current,
    isWorking: store.commons.isWorking,
    company: store.app.activeCompany,
    activeUser: store.app.activeUser,
  }),
  { ...mainActions, ...commons },
)(withModal(Guarantee))
