import { request } from 'core/constants';
import { requestState } from 'utils/UtilityStore/actions';

export const fetch = (propertyId) => (dispatch) => {
  dispatch(requestState(true, 'property'));
  request
    .get(`{company}/property/${propertyId}`)
    .then((response) => {
      dispatch(requestState(false, 'property'));
      dispatch({
        type: 'FETCH_PROPERTY',
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const clear = () => (dispatch) => {
  dispatch(requestState(null, 'property'));
  dispatch({
    type: 'CLEAR_PROPERTY',
  });
};
