export function getTaskListStatus(taskList = [], taskCode) {

  let filter = taskList.filter((item) => item.code === taskCode);
  let statusArray = [null];

  filter = filter[0];

  if (filter && filter.tasks) {
    filter = filter.tasks.filter((item) => !item.status);
    statusArray = filter;
  }

  return statusArray.length === 0;
};

export default function getTask(taskList = [], taskCode, subTaskCode) {

  let filter = taskList.find(item => item.code === taskCode);

  if (subTaskCode && filter && filter.tasks) {
    filter = filter.tasks.find(item => item.code === subTaskCode);
  }

  return filter || {};
};
