import request from 'axios';

import { baseURL } from 'core/constants';

export default function (file, uploadProgress, companyId) {
  const upload = new FormData();
  upload.append('file', file);
  return new Promise((resolve, reject) => {
    request
      .post(`${baseURL}company/${companyId}/upload-file`, upload, {
        onUploadProgress: (progressEvent) => {
          if (uploadProgress) uploadProgress(progressEvent);
        },
      })
      .then((response) => {
        if (response.success) {
          resolve(response.data.id);
        } else {
          reject(console.log('rejeitado'));
        }
      });
  });
}
