import React from 'react';

// Components
import Space from 'components/lib/Space';
import Button from 'components/lib/Button';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';
import { FormFieldText, FormFieldPassword } from 'components/lib/FormFields';
import Assistant from 'components/Assistant';

export class CheckUserPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { formIsWorking, handleFormField } = this.props;

    return (
      <React.Fragment>
        <Assistant words={'Digite sua senha ou escolha outro email.'} style={{ marginBottom: 30 }} />

        <FormFieldText
          autoComplete="on"
          name="username"
          label="Usuário/Email"
          placeholder={this.props.savedEmail}
          value={this.props.savedEmail}
          isDisabled={true}
          onChange={handleFormField.bind(this, 'username')}
        />

        <FormFieldPassword isDisabled={formIsWorking} onChange={handleFormField.bind(this, 'password')} />

        <Space isSmall />

        <Field>
          <Control>
            <Button isFullWidth isLarge isLoading={formIsWorking} isDisabled={formIsWorking} submit>
              Entrar
            </Button>

            <Space isSmall />

            <Button isFullWidth isLarge isLoading={formIsWorking} isDisabled={formIsWorking} onClick={this.props.resetCheck.bind(this)}>
              Usar outro email
            </Button>
          </Control>
        </Field>
      </React.Fragment>
    );
  }
}

export default CheckUserPassword;
