import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from 'components/SubmitButton';
import formCreator from '../components/formCreator';
import ContractHeaderFooterTemplate from '../components/fieldsGroup/ContractHeaderFooterTemplate';

export class UpdateHeaderFooterTemplate extends React.Component {
  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>
        <ContractHeaderFooterTemplate {...this.props} />
        <SubmitButton label="Salvar" isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} />
      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.submit(`company/${this.props.activeCompany.id}/header-footer-template/${this.props.defaultValue.id}`, fields, 'put');
  }
}

UpdateHeaderFooterTemplate.defaultProps = {
  fields: {},
  defaultValue: {},
  activeCompany: {},
};

UpdateHeaderFooterTemplate.propTypes = {
  activeCompany: PropTypes.object.isRequired,
};

export default formCreator(UpdateHeaderFooterTemplate);
