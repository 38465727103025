import React from 'react'

import Button from 'components/lib/Button'
import moment from 'moment'

const LastActivity = (props) => (
  <React.Fragment>
    {!props?.content?.last_activity && !props?.content?.invite?.account_registered ? (
      <Button isFlat isLink onClick={() => props.onClick()}>
        Reenviar convite
      </Button>
    ) : props?.content?.last_activity ? (
      moment(props.content.last_activity).format('DD/MM/YYYY hh:mm')
    ) : (
      ''
    )}
  </React.Fragment>
)

LastActivity.propTypes = {}

export default LastActivity
