import React from 'react';
import PropTypes from 'prop-types';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

export default class Dropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
    };
    this.container = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }

  render() {
    const dropdownCSSClass = `dropdown ${this.state.isActive ? ' is-active' : ''}${' ' + parsePropsAsBulmaClasses(this.props)}`;
    return (
      <div className={dropdownCSSClass} ref={this.container}>
        <div className="dropdown-trigger is-featured" onClick={this.props.isDisabled ? null : this.toggleDropdown.bind(this)} tabIndex="0" role="button" onKeyPress={null}>
          {this.props.trigger}
        </div>
        <div className="dropdown-menu" role="menu">
          <div className="dropdown-content" onClick={!this.props.keepOpen && this.toggleDropdown.bind(this)} tabIndex="0" role="button" onKeyPress={null}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  handleClickOutside(event) {
    const container = this.container.current;
    if (container && !container.contains(event.target)) this.closeDropdown();
  }

  toggleDropdown() {
    this.setState({ isActive: !this.state.isActive });
  }

  closeDropdown() {
    if (this.state.isActive) this.setState({ isActive: false });
  }
}

Dropdown.Item = (props) => {
  const dropdownItemCSSClass = `dropdown-item ${parsePropsAsBulmaClasses(props)}`;
  return (
    <React.Fragment>
      {props.children ? (
        <span onClick={props.onClick} onKeyDown={props.onClick} tabIndex={props.onClick ? '0' : '-1'} role="button" className={dropdownItemCSSClass} {...props}>
          {props.children}
        </span>
      ) : null}
      {props.hasDivider ? <hr className="dropdown-divider" /> : null}
    </React.Fragment>
  );
};

Dropdown.propTypes = {
  children: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
};
