/**
 * @class Constants
 * @author @cspilhere
 */

class Constants {

  constructor() {
    this.constants = {};
  }

  get(key) {
    if (!this) return undefined;
    if (typeof this.constants[key] === 'function') return this.constants[key](this.params);
    else return this.constants[key];
  }

  set(newConstantKey, value) {
    this.constants[newConstantKey] = value;
  }

  setup() {
    this.constant = (key) => this.get(key);
  }

};

// Export Constants
let Instance = null;
if (!(Instance instanceof Constants)) Instance = new Constants();
export default Instance;
