import React from 'react';

import ListEmptySVG from '../../../../assets/svgs/ListEmpty';

import Button from 'components/lib/Button';

import { Container, Text } from './styles';

const ListEmpty = ({ createNewProposal }) => (
  <Container>
    <ListEmptySVG />

    <Text>Crie uma nova proposta e agilize o processo de locação!</Text>

    <Button onClick={createNewProposal} isFlat hasThemeColor>
      Nova proposta
    </Button>
  </Container>
);

export default ListEmpty;
