import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import Grid from '../../components/lib/Grid';
import 'react-quill/dist/quill.snow.css';

export default class EditorField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.props?.defaultValue?.[props.templateName] || '',
    };
    this.editorRef = null;

    const AlignStyle = Quill.import('attributors/style/align');
    const BackgroundStyle = Quill.import('attributors/style/background');
    const ColorStyle = Quill.import('attributors/style/color');
    const DirectionStyle = Quill.import('attributors/style/direction');
    const FontStyle = Quill.import('attributors/style/font');
    const SizeStyle = Quill.import('attributors/style/size');

    SizeStyle.whitelist = ['8px', '9px', '10px', '11px', '12px', '14px', '18px', '24px', '30px', '36px'];

    Quill.register(AlignStyle, true);
    Quill.register(BackgroundStyle, true);
    Quill.register(ColorStyle, true);
    Quill.register(DirectionStyle, true);
    Quill.register(FontStyle, true);
    Quill.register(SizeStyle, true);

    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }],
        [{ header: 1 }, { header: 2 }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        [{ size: ['8px', '9px', '10px', '11px', '12px', '14px', '18px', '24px', '30px', '36px'] }],
        [{ font: [] }],
        ['image'],
      ],
    };

    this.formats = ['header', 'font', 'background', 'color', 'size', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'script', 'align', 'image'];
  }

  componentDidMount() {
    if (this.props.handleFieldChanges) {
      this.props.handleFieldChanges(this.props.templateName, this.state.editorState, {
        isValid: true,
        value: this.state.editorState,
      });
    }
  }

  render() {
    return (
      <div className="contract-editor editor-field">
        <Grid>
          <Grid.Col>
            <div className="editor-wrapper">
              <ReactQuill
                theme="snow"
                tabIndex={0}
                ref={(editor) => (this.editorRef = editor)}
                defaultValue={this.state.editorState}
                placeholder={''}
                onChange={this.handleEditorChanges.bind(this)}
                modules={this.modules}
                formats={this.formats}
              />
            </div>
          </Grid.Col>
        </Grid>
      </div>
    );
  }

  handleEditorChanges(editorState) {
    this.setState({ editorState });

    if (this.props.handleFieldChanges) {
      this.props.handleFieldChanges(this.props.templateName, editorState, {
        isValid: editorState.length > 0,
        value: editorState,
      });
    }
  }
}
