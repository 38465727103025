import moment from 'moment';
import { isFloat, isInvalidNumber, fixNumber } from 'utils/utils';

export const currency = (number) => {
  if (!isFloat(number) && !isInvalidNumber(number) && number != parseFloat(number)) {
    number = fixNumber(number, 2) + ',00';
  } else {
    number = fixNumber(number, 2, 'pt-BR');
  }
  return number;
};

export const area = (number) => {
  number = fixNumber(number, 2, 'pt-BR');
  return number;
};

export const date = (value) => {
  if (!moment(value, 'YYYY-MM-DD')._isValid) return null;
  return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

