import React from 'react'

import * as actions from './actions'
import withRedux from 'components/redux'
import { ROLE } from 'components/constants'

import * as commons from 'utils/UtilityStore/actions'

// Components
import BodyContainer from 'components/lib/BodyContainer'
import ScreenHeader from 'components/ScreenHeader'
import ViewSelector from 'components/ViewSelector'
import Modal from 'components/lib/Modal'

// Utils
import { ChildRoutes } from 'utils/RouterUtils'
import formatTabsIcons from './formatTabsIcons'

class ProcessDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { historyPush } = this.props
    const current = this.getCurrentView()

    if (!current) {
      return historyPush('/')
    }

    const { processId } = this.props.match.params
    const filter = {
      ...this.state.filter,
      ...{ limit: 1000 },
    }

    this.props.fetch(processId)
    this.props.fetchContracts(filter)

    if (!current.isSame) {
      historyPush(`process/${processId}/${current.view}`)
    }
  }

  getSnapshotBeforeUpdate() {
    const { view, processId } = this.props.match.params

    if (!view) this.props.historyPush(`process/${processId}/proposal`)

    return null
  }

  componentWillUnmount() {
    this.props.clear()
  }

  render() {
    const current = this.props.current
    const { requirements } = current

    if (!current || !current.proposal) {
      return null
    }

    const currentView = this.getCurrentView(current)

    if (!currentView) {
      return null
    }

    const views = this.getViews(current)

    const isRental = current?.proposal?.type === 'TYPE_RENTAL'
    const isSale = current?.proposal?.type === 'TYPE_SALE'
    // const isAcquisition = current.proposal.type === 'TYPE_ACQUISITION'

    return (
      <>
        <ScreenHeader
          header={`Processo de ${isRental ? 'locação' : isSale ? 'venda' : 'captação de imóvel'} ${
            (current.proposal && current.proposal.code) || ''
          }`}
          onClick={this.props.historyPush.bind(this)}
        >
          <ViewSelector
            views={formatTabsIcons(views, requirements)}
            current={currentView.view}
            onClick={this.handleChangeView.bind(this)}
          />
        </ScreenHeader>

        <BodyContainer isLoading={this.props.isWorking['processDetails']}>
          <ChildRoutes {...this.props} />

          <Modal
            title={this.state.modalTitle}
            isOpen={this.state.modalIsOpen}
            onClose={this.handleModalClose.bind(this)}
          >
            {this.state.feature}
          </Modal>
        </BodyContainer>
      </>
    )
  }

  getCurrentView() {
    const currentView = this.props.match.params.view
    const views = this.getViews()

    if (!views.length) {
      return null
    }

    const current = views.find((view) => view.code === currentView)
      ? currentView
      : views[0].code

    return {
      view: current,
      isSame: current === currentView,
    }
  }

  getViews = (current) => {
    const { forbiddenAccess } = this.props

    const forbiddenInspectorAndJuridical = forbiddenAccess([
      ROLE.PROFILE_INSPECTOR,
      ROLE.PROFILE_JURIDICAL,
    ])

    const isAcquisition = current?.proposal?.type === 'TYPE_ACQUISITION'

    return [
      {
        name: 'Proposta',
        code: 'proposal',
        id: 'proposal',
        isHidden: true,
      },
      {
        name: 'Análise',
        code: 'overview',
        id: 'overview',
        isHidden: false,
      },
      {
        name: 'Garantia',
        code: 'guarantee',
        id: 'guarantee',
        isActive: true,
        remove: forbiddenInspectorAndJuridical || isAcquisition,
        isHidden:
          current?.proposal?.type === 'TYPE_SALE' ||
          current?.proposal?.guarantee?.code === 'GUARANTEE_GUARANTOR',
      },
      {
        name: 'Jurídico',
        code: 'juridical',
        id: 'juridical',
        isActive: true,
        isHidden: false,
        remove: forbiddenAccess(ROLE.PROFILE_INSPECTOR, true),
      },
      {
        name: 'Vistoria',
        code: 'survey',
        id: 'survey',
        isActive: true,
        isHidden: false,
        remove: forbiddenAccess(ROLE.PROFILE_JURIDICAL, true) || isAcquisition,
      },
      {
        name: 'Contrato',
        code: 'contract',
        id: 'contract',
        isActive: true,
        isHidden: false,
        remove: forbiddenInspectorAndJuridical,
      },
      {
        name: 'Posse',
        code: 'keys',
        id: 'keys',
        isActive: true,
        isHidden: false,
        remove: forbiddenInspectorAndJuridical || isAcquisition,
      },
    ].filter((view) => !view.remove && !view.isHidden)
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    })
  }

  handleChangeView(view) {
    this.props.historyPush(`process/${this.props.current.id}/${view}`)
  }
}

ProcessDetails.defaultProps = {
  current: {
    properties: [{}],
  },
}

export default withRedux(
  ({ processDetails: { current } }) => ({
    current,
  }),
  { ...actions, ...commons },
)(ProcessDetails)
