import React from 'react'

import PersonalDataSpouse from 'components/Proposal/components/PersonalDataSpouse'
import PersonalData from 'components/Proposal/components/PersonalData'
import Evaluation from 'components/Proposal/components/Evaluation'
import UserName from 'components/Proposal/components/UserName'
import Address from 'components/Proposal/components/Address'
import Section from 'components/Proposal/components/Section'

import Dropdown from 'components/lib/Dropdown'
import Button from 'components/lib/Button'
import Space from 'components/lib/Space'
import Grid from 'components/lib/Grid'
import Icon from 'components/lib/Icon'

import Whatsapp from '../../../../../assets/svgs/Whatsapp'

const Person = ({ isValidLink = true, ...props }) => (
  <Grid>
    <Grid.Col>
      <Grid>
        <Grid.Col>
          <UserName {...props}>
            &nbsp;
            {document.body.offsetWidth < 768 && <div />}
            {props.deleted_at ? (
              <span style={{ fontSize: 14, color: '#EB144C' }}>(excluído)</span>
            ) : (
              <>
                {props.onClick && (
                  <>
                    <Button
                      isBare
                      alt='Editar o cadastro'
                      onClick={props.onClick && props.onClick.bind(null, props)}
                    >
                      <Icon name='fas fa-xs fa-user-edit' />
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                  </>
                )}

                {props.onClickAnalysis && (
                  <Button
                    isBare
                    onClick={
                      props.onClickAnalysis &&
                      props.onClickAnalysis.bind(null, props)
                    }
                  >
                    <Icon name='fas fa-xs fa-check-double' />
                  </Button>
                )}

                {props.onClickResend && (
                  <>
                    <Dropdown
                      trigger={
                        <Button alt='Enviar link por:' isBare>
                          <Icon name='fas fa-xs fa-paper-plane' />
                        </Button>
                      }
                    >
                      <>
                        <Dropdown.Item>
                          <Button
                            onClick={
                              props.onClickResend &&
                              props.onClickResend.bind(null, props)
                            }
                            isBare
                            isFullWidth
                            isMedium
                          >
                            <Icon name='far fa-envelope' />
                            {isValidLink ? 'Email' : 'Gerar Link'}
                          </Button>
                        </Dropdown.Item>

                        {isValidLink && (
                          <>
                            <Dropdown.Item>
                              <Button
                                alt='Copiar o link'
                                onClick={
                                  props.onClickWhatsapp &&
                                  props.onClickWhatsapp.bind(null, props)
                                }
                                isBare
                                isFullWidth
                                isMedium
                              >
                                <Whatsapp
                                  style={{ marginLeft: '4px', marginRight: '4px' }}
                                />
                                WhatsApp
                              </Button>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <Button
                                onClick={
                                  props.onClickCopyI &&
                                  props.onClickCopyI.bind(null, props)
                                }
                                isBare
                                isFullWidth
                                isMedium
                              >
                                <Icon name='fas fa-link' />
                                Copiar URL
                              </Button>
                            </Dropdown.Item>
                          </>
                        )}
                      </>
                    </Dropdown>
                    &nbsp;&nbsp;&nbsp;
                  </>
                )}
              </>
            )}
          </UserName>

          <PersonalData {...props} />

          {props.spouse_name && props.spouse_name !== '' && (
            <div>
              <Space isSmall />

              <PersonalDataSpouse {...props} />
            </div>
          )}

          <Space isSmall />

          <Evaluation status={props.evaluation} comment={props.denial_comment} />
        </Grid.Col>

        <Grid.Col>
          {props.trading_address && (
            <div>
              <Address address={props.trading_address} label='Endereço comercial' />

              <Space isSmall />
            </div>
          )}

          {props.address && (
            <Address address={props.address} label='Endereço residencial' />
          )}
        </Grid.Col>
      </Grid>

      {props.documents.length > 0 && (
        <Section title='Documentos' iconName='search'>
          {props.documents.map((doc) => {
            const isRejected = props.refusedFiles.filter(
              (rejectedFile) => rejectedFile.id === doc.id,
            ).length

            return (
              <div className='buttons-flat' key={doc.id}>
                <Icon name='fas fa-sm fa-id-card' style={{ marginLeft: -4 }} />
                <Button isLink isDark onClick={props.fetchFile.bind(null, doc)}>
                  <span
                    style={{
                      textDecoration:
                        isRejected || doc.is_rejected ? 'line-through' : null,
                    }}
                  >
                    {doc.name}
                  </span>
                </Button>
                {props.rejectFile && (
                  <>
                    {isRejected ? (
                      <Button
                        isLink
                        isDanger
                        isThin
                        onClick={props.removeFileFromRejection.bind(
                          null,
                          props.email,
                          doc,
                        )}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        isLink
                        isDanger={!doc.is_rejected}
                        isDark={doc.is_rejected}
                        isThin
                        onClick={
                          !doc.is_rejected &&
                          props.rejectFile.bind(null, props.email, doc)
                        }
                      >
                        {doc.is_rejected ? 'Rejeitado' : 'Rejeitar'}
                      </Button>
                    )}
                  </>
                )}
              </div>
            )
          })}
        </Section>
      )}

      {(props.refusedFiles || []).length > 0 && (
        <>
          <div style={{ borderTop: '1px solid #CCC', margin: '6px 0 0' }} />

          <Button isLink onClick={props.requestNewDocuments.bind(null, props)}>
            <Icon name='fal fa-xs fa-inbox-out' style={{ marginRight: -2 }} />
            Solicitar novos documentos para {props.name}
          </Button>
        </>
      )}
    </Grid.Col>
  </Grid>
)

export default Person
