import React from 'react'

class ProgressCounter extends React.Component {
  callBack(sectionNumber) {
    this.props.callBack(sectionNumber)
  }

  render() {
    let { selected, sectionNumber, title, isInvalid, themeColor } = this.props
    let active = null
    let done = null
    let invalid = null
    {
      selected == --sectionNumber ? (active = ' active') : (active = '')
    }
    //{sectionNumber < selected ? ( done = " done" ) : ( done = "" )}
    {
      isInvalid === sectionNumber ? (invalid = ' invalid') : (invalid = '')
    }

    const styles = {
      backgroundColor: active ? themeColor : 'inherit',
      borderColor: active ? themeColor : 'inherit'
    }

    const titleStyle = {
      color: active ? themeColor : 'inherit'
    }
    return (
      <div className='progress-item-container'>
        <div
          style={styles}
          className={'progress-item' + active + invalid}
          onClick={() => this.callBack(sectionNumber)}
        >
          <p className={'progress-number' + active + invalid}>
            {++sectionNumber}
          </p>
        </div>
        <p style={titleStyle} className={'progress-title' + active + invalid}>
          {title}
        </p>
      </div>
    )
  }
}

export default ProgressCounter
