import React from 'react';

import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import gravatar from 'gravatar';
import * as Yup from 'yup';

import profileService from '../../services/ProfileService';

import SubmitButton from 'components/SubmitButton';
import { Field, UploadModal } from '../../components';

const validation = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  email: Yup.string().required('Informe o email').email('Informe o email corretamente'),
});

const FormProfile = ({ activeUser, updateUser, onSuccess, size = 120 }) => {
  const save = async ({ name, email }, { setValues }) => {
    try {
      const data = {
        name,
        email,
      };

      await profileService.updateProfile(data);
      setValues({
        name,
        email,
      });

      updateUser({ name, email });
      onSuccess('Dados atualizados!');
    } catch (error) {
      alert(error?.error ?? 'Erro');
    }
  };

  const uploadImage = async (file) => {
    try {
      const avatar = await profileService.uploadAvatar({ file });
      updateUser({ avatar });
      return null;
    } catch (error) {
      return error.message;
    }
  };

  return (
    <Formik initialValues={{ ...activeUser, isChangePassword: false, password: undefined }} onSubmit={save} validationSchema={validation}>
      {({ values, isSubmitting, isValid }) => (
        <Form style={style.form}>
          <div style={style.container}>
            <UploadModal onConfirm={uploadImage}>
              {(openModal) => (
                <button type="button" style={style.buttonUpload} onClick={openModal}>
                  <figure className="image is-128px has-border-rounded">
                    <img alt={values.name} src={activeUser.avatar ?? gravatar.url(values.email, { size, d: 'mp' })} />
                  </figure>
                  <div className="file-input-wrapper-label" style={style.buttonUploadText}>
                    Imagem do perfil
                  </div>
                </button>
              )}
            </UploadModal>
            <div style={style.fields}>
              <Field name="name" placeholder="Informe seu nome" />
              <Field name="email" type="email" placeholder="Informe seu email" disabled />
            </div>
          </div>
          <SubmitButton label="Salvar" isLoading={isSubmitting} isDisabled={!isValid || isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

const style = {
  form: {},
  container: {
    display: 'flex',
    flex: 1,
  },
  buttonUpload: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    height: 90,
  },
  buttonUploadText: {
    textAlign: 'center',
    marginTop: 6,
  },
  fields: {
    flex: 1,
    paddingLeft: '1em',
  },
};

const mapStateToProps = (store) => ({
  activeUser: store.app.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (payload) => dispatch({ type: 'UPDATE_USER', payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormProfile);
