export default function cpf(cpf) {

  if (typeof cpf !== 'string') return;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length > 3) cpf = cpf.substring(0, 3) + '.' + cpf.substring(3);
  if (cpf.length > 7) cpf = cpf.substring(0, 7) + '.' + cpf.substring(7);
  if (cpf.length > 11) cpf = cpf.substring(0, 11) + '-' + cpf.substring(11,13);
  return cpf;

} // cpf
