import React from 'react';
import request from 'axios';
import { baseURL } from 'core/constants';
import Button from 'components/lib/Button';
import Grid from 'components/lib/Grid';
import { connect } from 'react-redux';
import * as commons from 'utils/UtilityStore/actions';

// Components

import { FormFieldSelect } from 'components/lib/FormFields';

class SelectPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      planSelected: {},
    };
  }

  componentDidMount() {
    this.fetchPlans();
  }

  fetchPlans() {
    request
      .get(baseURL + 'subscription-plan')
      .then((response) => {
        this.setState({ plans: response.data });
        if (this.props.defaultValue) {
          let selected = response.data.find(
            (p) => p.id == this.props.defaultValue.id
          );
          this.setState({ planSelected: selected });
        }
      })
      .catch(() => {});
  }

  updatePlan() {
    let companyId = this.props?.companyId;
    if (!companyId) return;
    if (!this.state.planSelected?.id) {
      this.props.notification({
        message: 'Selecione um plano',
        status: 'danger',
      });
      return;
    }
    this.setState({ isWorking: true });
    request
      .post(baseURL + `company/${companyId}/update-subscription-plan`, {
        subscription_plan_id: this.state.planSelected.id,
      })
      .then((response) => {
        this.props.notification({
          message: 'Plano atualizado com sucesso',
        });
        this.setState({ isWorking: false });
        location.reload();
      })
      .catch((error) => {
        this.props.notification({
          message: error.message,
          status: 'danger',
        });
      });
  }

  handleChange(value, field) {
    let selected = this.state.plans.find((p) => p.id == value);
    this.setState({ planSelected: selected });
    this.props.onChange(value, field);
  }

  render() {
    const planSelected = this.state.planSelected;

    return (
      <>
        <label className="label">Plano de assinatura</label>
        <Grid>
          <Grid.Col>
            <FormFieldSelect
              forceInvalid={this.props?.forceInvalid}
              errorMessage={this.props?.errorMessage}
              valueKey="id"
              labelKey="label"
              defaultValue={this.state.planSelected}
              placeholder="Selecione um plano de assinatura"
              hideBlankOption
              onChange={this.handleChange.bind(this)}
              options={this.state.plans}
              disabled={this.props.disabled}
            />
          </Grid.Col>

          {this.props.activeUser && this.props.activeUser.role == 'ROLE_IZEE' && (
            <Grid.Col>
              <Button
                isLoading={this.state.isWorking}
                isDisabled={this.state.isWorking}
                hasThemeColor
                onClick={() => this.updatePlan()}
              >
                Atualizar Plano
              </Button>
            </Grid.Col>
          )}
        </Grid>

        {planSelected && (
          <>
            <h2 className="mb-2">O plano de uso selecionado dá direito a:</h2>
            {planSelected.can_customize_app && (
              <p className="mb-1">- Customização de cor e logo do App;</p>
            )}
            {planSelected.maximum_users > 0 && (
              <p className="mb-1">
                - No máximo {planSelected.maximum_users} Usuário(s);
              </p>
            )}
            {planSelected.maximum_users == null && (
              <p className="mb-1">- Quantidade ilimitada de Usuários;</p>
            )}
            {planSelected.maximum_parallel_processes > 0 && (
              <p className="mb-1">
                - No máximo {planSelected.maximum_parallel_processes} Processo(s) em
                andamento;
              </p>
            )}
            {planSelected.maximum_parallel_processes == null && (
              <p className="mb-1">- Quantidade ilimitada de Processos;</p>
            )}
            {planSelected.can_access_integrations && (
              <p className="mb-1">- Acesso à personalização de Integrações;</p>
            )}
            {planSelected.can_access_advanced && (
              <p className="mb-1">
                - Acesso à personalização de Garantias, Modelos de Contrato;
              </p>
            )}
            <p className="mb-1">
              - Cadastro de Clientes, Proprietários, Fiadores, Imóveis e Propostas.
            </p>
          </>
        )}
      </>
    );
  }
}

export default connect((store) => ({}), { ...commons })(SelectPlan);
