import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Icon from 'components/lib/Icon'
import StatusField from './StatusField'
import FileViewerActions from './FileViewerActions'
import SectionView from './SectionView'

import FilePreview from 'components/FilePreview'

class ShowFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0
    }
  }

  renderImage() {
    const { documents } = this.props
    const { index } = this.state
    if (documents.length > 0) {
      
      const src = documents[index]?.new_source
      const array = documents[index]?.new_source?.split('.')
      const lastPosition = array?.length - 1
      const fileExtension = array ? array[lastPosition] : ''

      return (
        <FilePreview
          style={{ height: '600px' }}
          ext={fileExtension}
          source={src}
        ></FilePreview>
      )
    }
    return null
  }

  handleArrowLeft() {
    const { index } = this.state
    const { documents } = this.props
    const docsSize = documents.length

    if (index === 0) return this.setState({ index: docsSize - 1 })

    return this.setState({ index: index - 1 })
  }

  handleArrowRight() {
    const { index } = this.state
    const { documents } = this.props
    const docsSize = documents.length

    if (index === docsSize - 1) return this.setState({ index: 0 })

    return this.setState({ index: index + 1 })
  }

  handleDocName() {
    const { documents } = this.props
    const { index } = this.state
    const docsSize = documents.length

    if (docsSize > 0) {
      if (documents[index]?.name) {
        return documents[index].name
      }
      return null
    }
  }

  render() {
    const { documents, noChangeStatus } = this.props
    const { index } = this.state
    const pageWidth = document.body.offsetWidth;
    return (
      <div className='FullDocsModule'>
        <div className='LeftPanel'>
          <div className='header has-theme-color'>
            <div className='document_name'>{this.handleDocName()}</div>
            <div className='status'>
              {documents.length > 0 ? (
                <StatusField content={documents[index]?.status} />
              ) : null}
            </div>
          </div>

          {!noChangeStatus ? (
            <div className='buttons'>
              <FileViewerActions {...this.props} index={index} />
            </div>
          ) : null}

          {pageWidth < 1025 && (
            <div className='main' style={{overflow: 'scroll'}}>
              <div className='arrow_left' onClick={() => this.handleArrowLeft()}>
                <Icon name='fas fa-chevron-left' />
              </div>

              <div ref={el => (this.container = el)} className='image'>
                {this.renderImage()}
              </div>

              <div
                className='arrow_right'
                onClick={() => this.handleArrowRight()}
              >
                <Icon name='fas fa-chevron-right' />
              </div>
            </div>
          )}

          <SectionView {...this.props} />

        </div>
        {pageWidth >= 1025 && (
          <div className='RightPanel'>
            <div className='main' style={{overflow: 'scroll'}}>
              <div className='arrow_left' onClick={() => this.handleArrowLeft()}>
                <Icon name='fas fa-chevron-left' />
              </div>

              <div ref={el => (this.container = el)} className='image'>
                {this.renderImage()}
              </div>

              <div
                className='arrow_right'
                onClick={() => this.handleArrowRight()}
              >
                <Icon name='fas fa-chevron-right' />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  owner: state.propertyOwner,
  property: state.property,
  proposal: state.proposal,
  guarantor: state.guarantor,
  client: state.client
})

export default connect(mapStateToProps)(ShowFiles)
