import React from 'react'

const Faq = () => {
  const handleRedirectFaq = () => {
    window.open('https://gruporv.movidesk.com/kb', '_blank')
  }

  return (
    <div aria-hidden onClick={handleRedirectFaq} className='header-button-container'>
      <svg
        width='34'
        height='34'
        viewBox='0 0 34 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.0778 9.82835C20.1508 9.11591 18.8995 8.75922 17.321 8.75922C15.4515 8.75922 13.7009 9.17974 12.0667 10.0173L13.0814 11.9773C13.7006 11.6963 14.3383 11.4541 14.9897 11.2535C15.6411 11.053 16.3511 10.9529 17.1151 10.9529C17.9615 10.9529 18.6085 11.1199 19.0597 11.4541C19.5106 11.788 19.7362 12.267 19.7362 12.8906C19.7362 13.425 19.5985 13.8859 19.32 14.2713C19.0416 14.6581 18.4236 15.1997 17.4659 15.8971C16.6599 16.4762 16.0976 17.0369 15.7747 17.5785C15.4518 18.1214 15.292 18.8007 15.292 19.6163V20.3291H17.6592V19.8279C17.6592 19.2265 17.786 18.7478 18.0394 18.3914C18.2928 18.0347 18.795 17.587 19.5441 17.0438C20.4211 16.4133 21.039 15.9011 21.3982 15.5072C21.7562 15.1149 22.0231 14.7 22.2011 14.2657C22.3779 13.8314 22.4658 13.3358 22.4658 12.7792C22.4658 11.5251 22.0033 10.5411 21.0778 9.82835Z'
          fill='white'
        />
        <path
          d='M16.5726 22.1995C15.388 22.1995 14.7963 22.7605 14.7963 23.881C14.7963 24.4223 14.9493 24.844 15.2557 25.1447C15.5607 25.4454 16.0003 25.5956 16.5726 25.5956C17.1446 25.5956 17.5842 25.441 17.8949 25.1335C18.2052 24.8259 18.3598 24.4082 18.3598 23.8807C18.3598 23.3463 18.2065 22.9329 17.9002 22.6388C17.5939 22.3472 17.1527 22.1995 16.5726 22.1995Z'
          fill='white'
        />
      </svg>
    </div>
  )
}

export default Faq
