import React, { useState } from 'react';

import Onboarding from '../../../../assets/svgs/Onboarding';

import CompanyCreated from './components/CompanyCreated';
import Terms from './components/Terms';
import Form from './components/Form';

import { Container, Card, Text } from './styles';

const AppOnboarding = () => {
  const [useTerms, setUseTerms] = useState(false);
  const [privacyPolitics, setPrivacyPolitics] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [createdCompanyId, setCreatedCompanyId] = useState();

  return (
    <Container>
      <Card>
        {!onboardingCompleted ? (
          <>
            <Onboarding />

            <Text>
              Para concluirmos seu registro precisamos que você aceite os termos e as
              políticas de privacidade e nos forneça o nome da empresa e o cnpj.
            </Text>

            <Terms
              useTerms={useTerms}
              setUseTerms={setUseTerms}
              privacyPolitics={privacyPolitics}
              setPrivacyPolitics={setPrivacyPolitics}
            />

            <Form
              useTerms={useTerms}
              privacyPolitics={privacyPolitics}
              setCreatedCompanyId={setCreatedCompanyId}
              onSubmit={() => setOnboardingCompleted(true)}
            />
          </>
        ) : (
          <CompanyCreated createdCompanyId={createdCompanyId} />
        )}
      </Card>
    </Container>
  );
};

export default AppOnboarding;
