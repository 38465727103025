import React from 'react';
import Grid from 'components/lib/Grid';
import { FormFieldText, FormFieldCheckbox } from 'components/lib/FormFields';
import { fieldProps } from '../formCreator';
import 'react-quill/dist/quill.snow.css';
import EditorField from '../../../components/EditorField';

export default class ContractHeaderFooterTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerEditorState: this.props.defaultValue['header_template'] || '',
      footerEditorState: this.props.defaultValue['footer_template'] || '',
      showPages: false,
    };
  }

  componentDidMount() {
    const { headerEditorState, footerEditorState } = this.state;

    this.props.handleFieldChanges('header_template', headerEditorState, {
      isValid: headerEditorState.length > 0,
      value: headerEditorState,
    });
    this.props.handleFieldChanges('footer_template', footerEditorState, {
      isValid: footerEditorState.length > 0,
      value: footerEditorState,
    });
  }

  render() {
    const { headerEditorState, footerEditorState } = this.state;

    return (
      <div className="contract-editor">
        <Grid>
          <Grid.Col>
            <FormFieldText {...fieldProps(this.props, 'name')} label="Nome" info="Dê um nome ao conjunto de cabeçalho e rodapé" />
          </Grid.Col>
          <Grid.Col>
            <div className="has-pagination">
              <FormFieldCheckbox description="Exibir páginas" onChange={this.acceptTerms.bind(this)} />
            </div>
          </Grid.Col>
        </Grid>
        <EditorField templateName="header_template" defaultValue={{ header_template: headerEditorState }} handleFieldChanges={this.props.handleFieldChanges} />
        <EditorField templateName="footer_template" defaultValue={{ footer_template: footerEditorState }} handleFieldChanges={this.props.handleFieldChanges} />
      </div>
    );
  }

  acceptTerms(value) {
    this.setState({ showPages: value });
    this.props.handleFieldChanges('show_pages', value, {
      isValid: true,
      value,
    });
  }
}
