import React from 'react';

import { fieldProps } from 'features/components/formCreator';

import Header from 'components/lib/Header';

import { FormFieldSelect, FormFieldText } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';

import {
  employmentRelationshipOptions,
  exposedPersonOptions,
  genderOptions,
} from '../../..';

import { FormSection } from '../../../../../styles';

const OtherPerson = ({ client, formProps, index }) => {
  const fieldPath = `natural_tenant.sympathise_tenants.${index}`;
  const closeToFieldPath = `natural_tenant.sympathise_tenants.${index}.close_to_politically_exposed_person`;

  const isPoliticallyExposedCloseTo =
    formProps.fields[`${fieldPath}.politically_exposed_person`]?.value ===
    'CLOSE_TO';

  return (
    <FormSection>
      <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
        Dados complementares de {client.name}
      </Header>

      <Grid>
        <Grid.Col>
          <FormFieldSelect
            label="Gênero *"
            valueKey="value"
            options={genderOptions}
            {...fieldProps(formProps, `${fieldPath}.gender`)}
          />
        </Grid.Col>
        <Grid.Col>
          <FormFieldText
            label="Nome da mãe *"
            {...fieldProps(formProps, `${fieldPath}.mother_name`)}
          />
        </Grid.Col>
        <Grid.Col>
          <FormFieldSelect
            label="Vínculo empregatício *"
            options={employmentRelationshipOptions}
            valueKey="value"
            {...fieldProps(formProps, `${fieldPath}.employment_relationship`)}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <FormFieldSelect
            label="Pessoa publicamente exposta? *"
            valueKey="value"
            options={exposedPersonOptions}
            {...fieldProps(formProps, `${fieldPath}.politically_exposed_person`)}
          />
        </Grid.Col>
        {isPoliticallyExposedCloseTo && (
          <>
            <Grid.Col>
              <FormFieldText
                label="Nome da pessoa *"
                {...fieldProps(
                  formProps,
                  `${closeToFieldPath}.politically_exposed_person_name`
                )}
              />
            </Grid.Col>
            <Grid.Col>
              <FormFieldText
                label="CPF da pessoa *"
                {...fieldProps(
                  formProps,
                  `${closeToFieldPath}.politically_exposed_person_cpf`
                )}
              />
            </Grid.Col>
            <Grid.Col>
              <FormFieldText
                label="Grau de parentesco *"
                {...fieldProps(
                  formProps,
                  `${closeToFieldPath}.degree_of_kinship_to_politically_exposed_person`
                )}
              />
            </Grid.Col>
          </>
        )}
      </Grid>
    </FormSection>
  );
};

export default OtherPerson;
