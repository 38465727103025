import styled from 'styled-components'

export const Container = styled.div`
  padding: 50px 120px;

  overflow-y: auto;

  @media (max-width: 1000px) {
    padding: 30px 80px;
  }

  @media (max-width: 550px) {
    padding: 30px 40px;
  }

  @media (max-width: 400px) {
    padding: 30px 20px;
  }
`
