import React from 'react'
import request from 'axios'
import { baseURL } from 'core/constants'

import CreatableSelect from 'react-select/creatable'

export default class DocumentsRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      documents: [],
      value: props.defaultMultiOption
        ? props.defaultMultiOption.map((doc) => ({
            name: doc,
            code: doc,
          }))
        : [],
    }
  }

  componentDidMount() {
    this.fetchDocuments()
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.clear === true && prevProps.clear !== this.props.clear) {
      this.setState({ value: [] }, () => {
        this.props.onClear()
      })
    }

    return null
  }

  fetchDocuments() {
    request.get(`${baseURL}documents?orderBy=name,asc&search=`).then((response) => {
      const newDocuments = response.data?.data || response.data || []

      const filteredDocuments = newDocuments.filter(
        (doc) => !this.state.value.find((valueDoc) => doc.name === valueDoc.name),
      )

      this.setState({ documents: filteredDocuments })
    })
  }

  render() {
    return (
      <div style={{ width: '100%', marginRight: 20 }}>
        {this.props.title && <label className='label'>{this.props.title}</label>}
        <CreatableSelect
          placeholder='Selecione, ou digite o nome de um ou mais documentos'
          isMulti
          id='documents-selection'
          classNamePrefix='react-select'
          formatCreateLabel={(inputValue) => inputValue}
          onChange={(value) => {
            this.setState({ value })
            this.props.onChange(value)
          }}
          options={this.state.documents}
          value={this.state.value}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.code}
          getNewOptionData={(inputValue, optionLabel) => ({
            ['code']: optionLabel,
            ['name']: inputValue,
            isNew: true,
          })}
          isDisabled={this.props.isDisabled}
        />
      </div>
    )
  }
}
