const initialState = {
  current: {},
  contract: null,
  contractReadyVariables: null,
  creditAnalysis: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_PROCESS_DETAILS':
      state = {
        ...state,
        ...{
          current: action.payload,
        },
      };
      break;

    case 'FETCH_CREDIT_ANALYSIS':
      state = {
        ...state,
        ...{
          creditAnalysis: action.payload,
        },
      };
      break;

    case 'FETCH_READY_CONTRACT':
      state = {
        ...state,
        ...{
          contract: action.payload,
        },
      };
      break;

    case 'FETCH_READY_CONTRACT_VARIABLES':
      state = {
        ...state,
        ...{
          contractReadyVariables: action.payload,
        },
      };
      break;

    case 'FETCH_PROCESS_CONTRACT':
      state = {
        ...state,
        ...{
          contract: action.payload,
        },
      };
      break;

    case 'CLEAR_PROCESS_DETAILS':
      state = {
        ...state,
        ...{
          current: {},
          creditAnalysis: null,
        },
      };
      break;

    default:
      break;
  }

  return state;
};
