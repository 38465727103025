import React, { Component } from "react";

// Components
import Icon from "components/lib/Icon";

export class Failed extends Component {
  handleMessage() {
    const { msg } = this.props;
    if (msg !== "") {
      return <span>Documento(s) {msg} com falha</span>;
    }

    return <span>Ocorreu um erro, tente novamente</span>;
  }

  render() {
    return (
      <div
        className="feedback-container is-danger"
        style={{ position: "inherit", margin: "auto" }}
      >
        <div className="feedback-container-danger">
          <Icon name="fas fa-exclamation-triangle" />
          <br />
          {this.handleMessage()}
        </div>
      </div>
    );
  }
}

export default Failed;
