import React from 'react'

const FormGrid = (props) => {
  if (props.field) {
    if (props.fullWidth) {
      return <div className='form-grid-fcontainer-full-width'>{props.children}</div>
    }

    return <div className='form-grid-fcontainer'>{props.children}</div>
  }

  if (props.column) {
    return <div className='form-grid-column'>{props.children}</div>
  }

  if (props.row) {
    return <div className='form-grid-row'>{props.children}</div>
  }

  return <div className='form-grid'>{props.children}</div>
}

export default FormGrid
