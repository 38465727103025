import React from 'react';
import PropTypes from 'prop-types';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Grid = (props) => {

  const columnsCSSClass = `columns ${
    parsePropsAsBulmaClasses(props)
  }`;

  return (
    <div className={columnsCSSClass}>
      {props.children}
    </div>
  );

};

Grid.Col = (props) => {

  const columnCSSClass = `column ${
    parsePropsAsBulmaClasses(props)
  }`;

  return (
    <div className={columnCSSClass}>
      {props.children}
    </div>
  );

};

Grid.propTypes = {
  children: PropTypes.any.isRequired
};

export default Grid;
