import React, { Fragment } from "react";
import Button from "components/lib/Button";

const emailStyle = {
  width: 190,
  borderRadius: 3,
  paddingTop: 10,
  paddingBottom: 10
};

const linkStyle = {
  width: 190,
  backgroundColor: "#FFFFFF",
  borderRadius: 3,
  paddingTop: 10,
  paddingBottom: 10
};

const EmailButtons = props => {
  let emailTitle = "";
  let copyTitle = "";
  if (!props.confirmation) {
    props.sentEmail
      ? (emailTitle = "Documentos solicitados")
      : (emailTitle = "Solicitar por email");
    props.copied ? (copyTitle = "Link copiado") : (copyTitle = "Copiar link");
  } else {
    props.sentEmail
      ? (emailTitle = "Cadastro solicitado")
      : (emailTitle = "Solicitar por email");
    props.copied ? (copyTitle = "Link copiado") : (copyTitle = "Copiar link");
  }

  return (
    <Fragment>
      <Button
        isLoading={props.emailIsWorking}
        isNormal
        style={emailStyle}
        onClick={() => props.click("request")}
      >
        {emailTitle}
      </Button>

      <Button
        isLoading={props.copyIsWorking}
        isNormal
        style={linkStyle}
        isLight
        onClick={() => props.click("link")}
      >
        {copyTitle}
      </Button>
    </Fragment>
  );
};

export default EmailButtons;
