import React, { useState } from 'react';

import { fieldProps } from 'features/components/formCreator';

import Header from 'components/lib/Header';

import { FormFieldSelect, FormFieldText } from 'components/lib/FormFields';
import Button from 'components/lib/Button';
import Grid from 'components/lib/Grid';

import OtherPerson from './OtherPerson';

import { FormSection } from '../../../../styles';

const activityTreeOptions = [
  { name: 'Comércio', value: 'COMERCIO' },
  { name: 'Serviços', value: 'SERVIÇOS' },
  { name: 'Indústria', value: 'INDUSTRIA' },
];

const Comercial = ({ formProps }) => {
  const [partners, setPartners] = useState([1]);

  const handleAddPartner = () =>
    setPartners((oldState) => [...oldState, oldState[oldState.length - 1] + 1]);

  const isCompanyCreated =
    formProps.fields['natural_tenant.quiz_company_created']?.value === 'true';

  return (
    <FormSection>
      <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
        Questionário
      </Header>

      <Grid>
        <Grid.Col>
          <FormFieldSelect
            label="Empresa já constituída"
            valueKey="value"
            options={[
              { name: 'Não', value: false },
              { name: 'Sim', value: true },
            ]}
            {...fieldProps(formProps, 'natural_tenant.quiz_company_created')}
          />
        </Grid.Col>
        <Grid.Col>
          <FormFieldSelect
            label="Ramo de atividade *"
            valueKey="value"
            options={activityTreeOptions}
            {...fieldProps(
              formProps,
              'natural_tenant.quiz_company_field_of_activity'
            )}
          />
        </Grid.Col>

        {isCompanyCreated && (
          <>
            <Grid.Col>
              <FormFieldText
                mask={['cnpj']}
                label="CNPJ *"
                {...fieldProps(formProps, 'natural_tenant.quiz_company_cnpj')}
              />
            </Grid.Col>
            <Grid.Col>
              <FormFieldText
                mask={['number']}
                label="Renda mensal *"
                {...fieldProps(formProps, 'natural_tenant.quiz_company_rent')}
              />
            </Grid.Col>
          </>
        )}
      </Grid>

      <Grid>
        <Grid.Col>
          <FormFieldSelect
            label="Possui sócios?"
            valueKey="value"
            options={[
              { name: 'Não', value: false },
              { name: 'Sim', value: true },
            ]}
            {...fieldProps(formProps, 'natural_tenant.quiz_partners')}
          />
        </Grid.Col>
      </Grid>

      {formProps.fields['natural_tenant.quiz_partners']?.value === 'true' && (
        <>
          {partners.map((partner, i) => (
            <OtherPerson
              key={`other-person-${partner}`}
              formProps={formProps}
              i={i}
            />
          ))}

          <Button hasThemeColor onClick={handleAddPartner} style={{ marginTop: 8 }}>
            Adicionar sócio
          </Button>
        </>
      )}
    </FormSection>
  );
};

export default Comercial;
