import styled from 'styled-components';

export const Horizontal = styled.div`
  display: flex;
`;

export const Col = styled(Horizontal)`
  width: 150px;
  flex-direction: column;
  margin-right: 20px;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const GuaranteesContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  align-items: flex-end;
`;
