import React from 'react';
import { request } from 'core/constants';
import ReactTimeout from 'react-timeout';
import withModal from 'components/HOCs/withModal';
import Text from 'components/lib/Text';
import FilePreview from 'components/FilePreview';

export default function withFilePreview(Component, path) {

  class WithFilePreviewWrapper extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
      this.modalTitle = 'Visualizar arquivo';
    }

    render() {
      return (
        <Component
          {...this.props}
          openFile={this.fetchFile.bind(this)}
        />
      );
    }

    fetchFile(file, requestPath) {

      this.props.openModal(this.modalTitle, <Text>Carregando...</Text>);

      const path = typeof requestPath === 'string' ? requestPath : '{company}/file';

      request.get(`${path}/${file.id}`)
        .then((response) => {
          this.props.openModal(this.modalTitle, (
            <FilePreview source={`${response.data.source}`} ext={response.data.type} />
          ));
        })
        .catch(() => this.props.openModal(this.modalTitle, <Text>Ocorreu um erro :(</Text>));

    }

  };

  WithFilePreviewWrapper.displayName = `WithFilePreviewWrapper(${getDisplayName(Component)})`;

  return ReactTimeout(withModal(WithFilePreviewWrapper));

};

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
};
