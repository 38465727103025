import React from 'react';
import request from 'axios';

// Components
import {
  FormFieldCEP,
  FormFieldCheckbox
} from 'components/lib/FormFields';
import { fieldProps } from '../formCreator';
import Grid from 'components/lib/Grid';
import Space from 'components/lib/Space';
import Header from 'components/lib/Header';

// Utils
import { timeDelay } from 'utils/utils';

export default class ProfileSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentProfiles: this.props.defaultValue.profiles || []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.fields.profile_ids && state.currentProfiles.length > 0 && !props.defaultValue.profiles) {
      return {
        currentProfiles: []
      };
    }
    return null;
  }

  render() {

    const availableProfiles = [{
      id: 3,
      name: 'Administrador(a)',
      info: 'Tem os mesmo poderes que o proprietário da conta'
    }, {
      id: 4,
      name: 'Gestor(a)',
      info: 'Pode apenas visualizar tudo do sistema'
    }, {
      id: 5,
      name: 'Consultor(a)',
      info: 'Cria propostas'
    }, {
      id: 6,
      name: 'Vistoriador(a)',
      info: 'Agenda e anexa o documento de vistoria da proposta'
    }, {
      id: 7,
      name: 'Jurídico',
      info: 'Edita o contrato de locação'
    }];
    
    if(this.props.userProfile?.code === 'PROFILE_COMPANY_OWNER_PARTNER' || this.props.activeUser?.role == 'ROLE_IZEE'){
      let profileOwner = {
        id: 2,
        name: 'Dono/Sócio da imobiliária',
        info: 'Tem os mesmo poderes que o proprietário da conta'
      }
      availableProfiles.unshift(profileOwner)
    }

    return (
      <React.Fragment>
        <Space />
        <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
          Papéis na empresa
        </Header>
        <Grid>
          <Grid.Col>
            {availableProfiles.map((item, index) => {
              const isChecked = this.state.currentProfiles.filter((profile) => profile.id === item.id).length;
              return (
                <FormFieldCheckbox
                  key={item.id}
                  name={item.name}
                  isDisabled={this.props.formIsWorking}
                  onChange={this.handleCheckboxChanges.bind(this, item, index)}
                  description={item.name}
                  info={item.info}
                  checked={isChecked}
                />
              );
            })}
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  handleCheckboxChanges(item, index, value, field) {
    // if (!field.wasFocused) return;
    if (value) {
      this.push(item, index);
    } else {
      this.remove(item, index);
    }
    // console.log(this.state.currentProfiles.length);
    
    setTimeout(() => 
      this.props.handleFieldChanges(
        'profile_ids',
        [...this.state.currentProfiles].map((item) => item.id),
        {
          isValid: this.state.currentProfiles.length > 0,
          value: [...this.state.currentProfiles].map((item) => item.id)
        }
      ), 100);
  }

  push(item, index) {
    // const list = this.state.currentProfiles;
    // if (list.filter((listItem) => listItem.id === item.id).length) return;
    const list = [];
    list.push(item);
    this.setState({ currentProfiles: list });
    return
  }

  remove(item, index) {
    let list = this.state.currentProfiles;
    if (list.length === 1) {
      this.setState({ currentProfiles: list });
      return;
    }
    list = list.filter((listItem) => listItem.id !== item.id);
    this.setState({
      currentProfiles: list
    });
  }

};
