import React from 'react'
import Text from 'components/lib/Text'
import { currency, date } from './utils'

const PersonalDataSpouse = (props) => {
  const spouseIncome = currency(props['spouse_income'])
  const birthDate = date(props['spouse_birth_date'])

  const { brief, spouse_identity_issuing_body, documentView } = props

  if (!documentView) {
    return (
      <React.Fragment>
        <Text>
          <strong>
            <span style={{ fontSize: 14 }}>{props.spouse_name} (Cônjuge)</span>
          </strong>
        </Text>
        <Text>
          Email: {props.spouse_email} / Telefone: {props.spouse_phone}
        </Text>
        <Text>
          Documento: {props.spouse_identity_type} / Número: {props.spouse_identity}
          {spouse_identity_issuing_body
            ? ` / Órgão Emissor / UF : ${spouse_identity_issuing_body}`
            : null}
        </Text>
        <Text>CPF: {props['spouse_cpf']}</Text>
        {!brief && <Text>Data de nascimento: {birthDate}</Text>}
        <Text>Fonte de Renda: {props.spouse_income_source?.name}</Text>
        {!brief && spouseIncome && <Text>Renda: R$ {spouseIncome}</Text>}
        {!brief && <Text>Ocupação: {props['spouse_occupation']}</Text>}
        {!brief && props['spouse_nationality'] && (
          <Text>Nacionalidade: {props['nationality']}</Text>
        )}
      </React.Fragment>
    )
  } else {
    return (
      <div className='documentView-container-info'>
        <strong className='documentView-title'>{props.spouse_name} (Cônjuge)</strong>
        <Text style={{ fontSize: 14, color: '#5F6776' }}>
          Email: {props.spouse_email}
        </Text>
        <Text style={{ fontSize: 14, color: '#5F6776' }}>
          Telefone: {props.spouse_phone}
        </Text>
        <Text style={{ fontSize: 14, color: '#5F6776' }}>
          CPF: {props['spouse_cpf']}
        </Text>
        <Text style={{ fontSize: 14, color: '#5F6776' }}>
          Documento: {props.spouse_identity_type} / Número: {props.spouse_identity}
          {spouse_identity_issuing_body
            ? ` / Órgão Emissor / UF : ${spouse_identity_issuing_body}`
            : null}
        </Text>
        {!brief && (
          <Text style={{ fontSize: 14, color: '#5F6776' }}>
            Data de nascimento: {birthDate}
          </Text>
        )}
        {!brief && (
          <Text style={{ fontSize: 14, color: '#5F6776' }}>
            Ocupação: {props['spouse_occupation']}
          </Text>
        )}
        {!brief && props['spouse_nationality'] && (
          <Text style={{ fontSize: 14, color: '#5F6776' }}>
            Nacionalidade: {props['spouse_nationality']}
          </Text>
        )}
      </div>
    )
  }
}

export default PersonalDataSpouse
