import React from 'react'

import { baseURL } from 'core/constants'
import request from 'axios'

// Components
import { FormFieldCheckbox } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import formCreator from '../components/formCreator'
import Button from 'components/lib/Button'
import Modal from 'components/lib/Modal'
import Space from 'components/lib/Space'

import { ModalContentContainer } from './styles'

export class CloseRequirement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requirementIsClosed: false,
      modalIsOpen: false,
      modalContent: null
    }
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest, true)
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  closeKeys() {
    this.setState({ modalIsOpen: false }, () => {
      this.createRequest()
    })
  }

  handleFeature() {
    const modalContent = (
      <ModalContentContainer>
        <strong>
          Esta etapa concluirá o processo e não poderá ser reaberta.
        </strong>
        <Space />
        <div className='buttons'>
          <Button isDanger onClick={this.closeModal.bind(this)}>
            Cancelar
          </Button>
          <Button isSuccess onClick={this.closeKeys.bind(this)} specSelector="button-accept-close-requirement">
            Aceitar
          </Button>
        </div>
      </ModalContentContainer>
    )

    this.setState({ modalIsOpen: true, modalContent })
  }

  render() {
    const { formIsWorking, formIsReady, buttonLabel, description } = this.props
    const submitIsDisabled =
      !formIsReady || formIsWorking || !this.state.requirementIsClosed

    const { modalContent, modalIsOpen } = this.state

    return (
      <Grid>
        <Grid.Col>
          <FormFieldCheckbox
            description={
              description ||
              'Declaro que esta etapa está concluída e desejo encerrá-la.'
            }
            onChange={this.closeRequirement.bind(this)}
          />
        </Grid.Col>
        <Grid.Col isRight>
          <Button
            hasThemeColor
            isLoading={formIsWorking}
            isDisabled={submitIsDisabled}
            specSelector="button-confirm-close-requirement"
            onClick={this.handleFeature.bind(this)}
          >
            {buttonLabel || 'Concluir'}
          </Button>
        </Grid.Col>

        <Modal
          backgroundContentStyle={{ display: 'block' }}
          isOpen={modalIsOpen}
          onClose={this.closeModal.bind(this)}
        >
          {modalContent}
        </Modal>
      </Grid>
    )
  }

  closeRequirement(value, field) {
    this.setState({
      requirementIsClosed: value
    })
  }

  async createRequest() {
    const { processId, requirement, companyId } = this.props

    const url = `${baseURL}company/${companyId}/process/${processId}/${requirement}/close-requirement`

    try {
      const response = await request.post(url)

      if (response.success) {
        const success = {
          isSuccess: true
        }
        return this.props.onSuccess(success)
      } else {
        const success = {
          isSuccess: false
        }
        return this.props.onSuccess(success)
      }
    } catch (error) {
      const success = {
        isSuccess: false
      }
      return this.props.onSuccess(success)
    }
  }
}

export default formCreator(CloseRequirement)
