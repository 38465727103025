import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  top: 0;
  position: sticky;
`;

export const ReadyContractWrapper = styled.div`
  .editor-wrapper,
  .token-list-wrapper {
    height: calc(100vh - 200px) !important;
    margin-bottom: 10px;
  }
`;
