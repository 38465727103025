import React from 'react';

import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

const EmptyState = (props) => (
  <div className="empty-state">
    {props.isLoading ?
      <div className="empty-state-spinner" /> :
      <div className="empty-state-icon">
        <Icon name="fas fa-inbox fa-4x" />
      </div>
    }
    {/* <div className="empty-state-action">
      {props.isLoading ?
        null :
        <Button hasThemeColor onClick={props.onClick}>
          {props.buttonLabel}
        </Button>
      }
    </div> */}
  </div>
);

EmptyState.propTypes = {};

export default EmptyState;
