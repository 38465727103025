import React from 'react';
import PropTypes from 'prop-types';

import logo from './superlogica-logo.png';

const Trigger = ({ onClick }) => (
  <div style={{ cursor: 'pointer' }} onClick={onClick}>
    <img
      title="Atualizar contrato na Superlógica"
      alt="Logo da Superlógica"
      src={logo}
      style={{ height: 32 }}
    />
  </div>
);

Trigger.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Trigger;
