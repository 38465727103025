import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';
import * as commons from 'utils/UtilityStore/actions';

// Components
import Button from 'components/lib/Button';
import Modal from 'components/lib/Modal';

import CreateUser from 'features/CreateUser';
import UpdateUser from 'features/UpdateUser';

import List from './components/List';
import Remove from './../../../../izee-components/src/features/Remove';
import { copyToClipboard } from 'utils/utils';

class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
      filter: {},
    };
    this.body = React.createRef();
  }

  load() {
    if (this.props.items.length < 1) this.props.fetch(this.state.filter);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.filter != this.state.filter) {
      this.setState({
        items: this.props.fetch(this.state.filter),
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <List
          listName="Usuários da empresa"
          actionButton={
            <Button onClick={this.inviteUser.bind(this)} isFlat hasThemeColor>
              Convidar usuário
            </Button>
          }
          items={this.props.items}
          pagination={this.props.listInfo}
          isWorking={this.props.isWorking}
          handleRowClick={this.editRow.bind(this)}
          handleRowEdit={this.editRow.bind(this)}
          handleRowDelete={this.deleteRow.bind(this)}
          handleStatusUpdate={this.updateStatus.bind(this)}
          handleInviteResend={this.resendInvite.bind(this)}
          handleInviteWhatsapp={this.whatsAppShare.bind(this)}
          handleInviteLink={this.copyToClipboard.bind(this)}
          handleInativeList={this.handleInativeList.bind(this)}
          handleAtivoList={this.handleAtivoList.bind(this)}
          handleAllList={this.handleAllList.bind(this)}
          handleFieldChanges={this.handleFieldChanges.bind(this)}
          onChangePage={this.handlePagination.bind(this)}
          isMedium={false}
        />

        <Modal title={this.state.modalTitle} isOpen={this.state.modalIsOpen} onClose={this.handleModalClose.bind(this)}>
          {this.state.feature}
        </Modal>
      </React.Fragment>
    );
  }

  handlePagination(page) {
    const filter = { ...this.state.filter, ...{ page: page } };
    this.setState({ filter: filter });
    this.props.fetch(filter);
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    });
  }

  handleCreateSuccess({ isSuccess, type, response }) {
    const filter = { ...this.state.filter, ...{ page: 1 } };
    this.setState({ filter: filter });
    if (isSuccess) {
      
      if (type == 'email') {
        this.props.notification({
          message: 'Usuário convidado com sucesso!',
        });
        this.props.fetch(filter);
      }
      if (type == 'whatsapp') {
        this.whatsAppShare(response);
      }
      if (type == 'link') {
        this.copyToClipboard(response)
        this.props.notification({
          message: 'Link de convite copiado!',
        });
        this.props.fetch(filter);
      }
    } else {
      this.props.notification({
        message: response?.message || 'Ocorreu um erro ao convidar o usuario.',
        status: 'danger',
      });
    }
  }

  copyToClipboard(response) {
    const path = location.origin + '/auth/register?hash=' + response.invite.hash;
    copyToClipboard(path);
    this.props.notification({
      message: 'Link copiado com sucesso',
      status: 'success',
    });
  }

  whatsAppShare(response) {
    let body = `Olá ${response.name}, você foi adicionado na ${this.props.activeCompany.name}. Clique no link abaixo para você ter acesso ao Izee.`;
    let url = location.origin + '/auth/register?hash=' + response.invite.hash;
    let link = `https://api.whatsapp.com/send?text=${body}%0a${url}`;

    let left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 520 / 2;
    let top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 570 / 2;

    window.open(link, '', `height=570,width=520,left=${left},top=${top}location=no,scrollbars=yes,status=no,toolbar=no,directories=no,menubar=no,resizable=no,centerscreen=yes,chrome=yes`);

  }

  handleUpdateSuccess(response) {
    this.props.updateItems(response.data);
  }

  inviteUser() {
    let companyId = this.props.activeCompany.id;
    let userProfile = this.props.activeUser.companies.find(e => e.id === companyId).profiles[0];
    this.setState({
      modalTitle: 'Convidar usuário da empresa',
      modalIsOpen: true,
      feature: (
        <CreateUser
          activeUser= {this.props.activeUser}
          companyId = {companyId}
          userProfile={userProfile}
          onSuccess={this.handleCreateSuccess.bind(this)}
        />
      )
    });
  }

  openRow(row) {
    this.setState({
      modalTitle: 'Editar usuário da empresa',
      modalIsOpen: true,
      feature: <UpdateUser activeUser= {this.props.activeUser} defaultValue={{ ...row }} onSuccess={this.handleUpdateSuccess.bind(this)} />,
    });
  }

  updateStatus(row, value, field) {
    if (!field.wasFocused) return;
    this.props.updateStatus(row.id, value);
  }

  resendInvite(user) {
    this.props.resendInvite(user.id);
  }

  editRow(row) {
    let userProfile = this.props.activeUser.companies.find(e => e.id === this.props.activeCompany.id).profiles[0];
    this.setState({
      modalTitle: 'Editar usuário da empresa',
      modalIsOpen: true,
      feature: (
        <UpdateUser
          activeUser= {this.props.activeUser}
          userProfile={userProfile}
          defaultValue={{ ...row }}
          onSuccess={this.handleUpdateSuccess.bind(this)}
        />
      )
    });
  }

  deleteRow(row) {
    this.setState({
      modalTitle: 'Excluir usuário da empresa',
      modalIsOpen: true,
      feature: <Remove path={`company/${this.props.company.id}/user/${row.id}/destroy`} display={row.name} onSuccess={this.handleUserDeleteSuccess.bind(this)} />,
    });
  }

  handleUserDeleteSuccess() {
    this.handleModalClose();
    window.location.reload();
  }

  handleInativeList() {
    this.setState({
      filter: {
        inativo: 1,
      },
    });
  }

  handleAtivoList() {
    this.setState({
      filter: {
        ativo: 1,
      },
    });
  }

  handleAllList() {
    this.setState({ filter: {} });
  }

  handleFieldChanges(text) {
    this.setState({
      filter: {
        search: text,
      },
    });
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    companyMeta: store.app.companyMeta,
    updateActiveCompany: store.app.helpers.updateActiveCompany,
    updateCompanyMeta: store.app.helpers.updateCompanyMeta,
    showNotification: store.app.helpers.showNotification,

    items: store.members.items,
    listInfo: store.members.listInfo,
    isWorking: store.commons.isWorking.members,
  }),
  { ...actions, ...commons }
)(Members);
