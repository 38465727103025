const initialState = {
  contractItems: [],
  contractListInfo: [],
  headerFooterTemplateItems: [],
  headerFooterListInfo: [],
  listInfo: [],
  listContractModelVariables: [],
  isFetching: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'IS_FETCHING':
      let isFetching = { ...state.isFetching };
      if (action.valueKey === 'all') {
        Object.keys(isFetching).forEach((item) => (isFetching[item] = action.payload));
      } else {
        isFetching = { ...isFetching, ...{ [action.valueKey]: action.payload } };
      }
      state = { ...state, ...{ isFetching: isFetching } };
      break;

    case 'LIST_CONTRACTS':
      state = {
        ...state,
        ...{
          contractItems: action.payload,
          contractListInfo: action.listInfo,
        },
      };
      break;

    case 'LIST_HEADER_FOOTER_TEMPLATE':
      state = {
        ...state,
        ...{
          headerFooterTemplateItems: action.payload,
          headerFooterListInfo: action.listInfo,
          listInfo: action.listInfo,
        },
      };
      break;

    case 'LIST_CONTRACT_MODEL_VARIABLES':
      state = {
        ...state,
        ...{
          listContractModelVariables: action.payload,
        },
      };
      break;

    default:
      break;
  }

  return state;
};
