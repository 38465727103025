import React from 'react';
import Text from 'components/lib/Text';
import { area } from './utils';

const Address = ({ address, inline, label, documentView }) => {

  if (!address) return null;

  if (inline) {
    return (
      <div>
        <Text>
          <strong>{label ? label : "Endereço"}:</strong>&nbsp;
          {address.address}, {address.number}&nbsp;
          {address.neighborhood} - {address.zip_code}&nbsp;
          {address.city}/{address.state}&nbsp;
          - {address.additional_address}
        </Text>
      </div>
    );
  }

  if (documentView) {
    return (
      <div className="documentView-container-info">
        <strong className="documentView-title">{label ? label : "Endereço"}</strong>
        <Text style={{fontSize: 14, color: "#5F6776"}}>{address.address}, {address.number}</Text>
        <Text style={{fontSize: 14, color: "#5F6776"}}>{address.neighborhood} - {address.zip_code}</Text>
        <Text style={{fontSize: 14, color: "#5F6776"}}>{address.city} / {address.state}</Text>
        <Text style={{fontSize: 14, color: "#5F6776"}}>{address.additional_address}</Text>
        {address.area && <Text style={{fontSize: 14, color: "#5F6776"}}>Área: {area(address.area)} m²</Text>}
      </div>
  );
  }

  return (
    <div>
      <Text><strong><span style={{ fontSize: 14 }}>{label ? label : "Endereço"}</span></strong></Text>
      <Text>{address.address}, {address.number}</Text>
      <Text>{address.neighborhood} - {address.zip_code}</Text>
      <Text>{address.city} / {address.state}</Text>
      <Text>{address.additional_address}</Text>
      {address.area && <Text>Área: {area(address.area)} m²</Text>}
    </div>
  );
};

export default Address;
