import React, { useState } from 'react'
import jsonp from 'jsonp'

import { FormFieldCEP, FormFieldText } from 'components/lib/FormFields'
import { fieldProps } from '../../formCreator'
import FormGrid from 'components/lib/FormGrid'
import Grid from 'components/lib/Grid'

const FullAddress = (props) => {
  const { company, defaultValue, isMultiStep } = props

  const [addressByCEP, setAddressByCEP] = useState({})
  const [isSearchingForAddress, setIsSearchingForAddress] = useState(false)

  const defaultPrefix = company ? defaultValue.trading_address : defaultValue
  const prefix = company ? 'trading_address' : 'address'

  const cepValidation = {
    isInvalid: addressByCEP.erro,
    message: 'CEP inválido ou inexistente',
  }

  const handleCEP = (value, field) => {
    props.handleChange
      ? props.handleChange('zip_code', value)
      : props.handleFieldChanges(`${prefix}.zip_code`, value, field)
    if (!field.wasFocused || !value || value.length < 9) return

    setAddressByCEP({})

    setIsSearchingForAddress(true)

    jsonp(
      `https://viacep.com.br/ws/${value.replace('-', '')}/json`,
      null,
      (_, response) => {
        if (response.erro) {
          props.fieldErrors.zip_code = ['CEP inválido ou inexistente', true]
        }
        props.handleChange
          ? props.handleChange('fullAddress', response)
          : props.handleFieldChanges(`${prefix}.zip_code`, value, field)

        setAddressByCEP(
          response || {
            erro: true,
          },
        )
        setIsSearchingForAddress(false)
      },
    )
  }

  return !isMultiStep ? (
    <FormGrid>
      <FormGrid column>
        <FormGrid field>
          <FormFieldCEP
            {...fieldProps(props, company ? `${prefix}.zip_code` : 'zip_code')}
            forceInvalid={cepValidation.isInvalid}
            errorMessage={cepValidation.isInvalid && cepValidation.message}
            isLoading={isSearchingForAddress}
            isRequired={props.isRequired}
            info='Se não souber o seu CEP preencha os campos manualmente'
            onChange={handleCEP}
            noValidate={props.noValidate}
            style={{ width: '100%' }}
          />
        </FormGrid>
        <FormGrid field>
          <FormFieldText
            {...fieldProps(props, `${prefix}.address`)}
            label='Logradouro'
            noValidate={props.noValidate}
            isRequired={props.isRequired}
            valueDefault={
              addressByCEP.logradouro || (defaultPrefix || {})['address']
            }
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </FormGrid>
        <FormGrid field>
          <FormFieldText
            {...fieldProps(props, `${prefix}.number`)}
            valueDefault={(defaultPrefix || {})['number'] || ''}
            label='Número'
            noValidate={props.noValidate}
            isRequired={props.isRequired}
            mask={['onlyNumbers']}
          />
        </FormGrid>
        <FormGrid field>
          <FormFieldText
            {...fieldProps(props, `${prefix}.additional_address`)}
            valueDefault={(defaultPrefix || {})['additional_address']}
            label='Complemento (Opcional)'
            noValidate
          />
        </FormGrid>
      </FormGrid>
      <FormGrid column>
        <FormGrid field>
          <FormFieldText
            {...fieldProps(props, `${prefix}.state`)}
            label='Estado'
            isRequired={props.isRequired}
            noValidate={props.noValidate}
            valueDefault={addressByCEP.uf || (defaultPrefix || {})['state']}
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </FormGrid>
        <FormGrid field>
          <FormFieldText
            {...fieldProps(props, `${prefix}.city`)}
            label='Cidade'
            isRequired={props.isRequired}
            noValidate={props.noValidate}
            valueDefault={addressByCEP.localidade || (defaultPrefix || {})['city']}
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </FormGrid>
        <FormGrid field>
          <FormFieldText
            {...fieldProps(props, `${prefix}.neighborhood`)}
            label='Bairro'
            isRequired={props.isRequired}
            noValidate={props.noValidate}
            valueDefault={
              addressByCEP.bairro || (defaultPrefix || {})['neighborhood']
            }
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </FormGrid>
        {props.adicionalFields}
      </FormGrid>
    </FormGrid>
  ) : (
    <>
      <Grid>
        <Grid.Col>
          <FormFieldCEP
            {...fieldProps(props, company ? `${prefix}.zip_code` : 'zip_code')}
            isLoading={isSearchingForAddress}
            info='Se não souber o seu CEP preencha os campos manualmente'
            onChange={handleCEP}
            isRequired={props.isRequired}
            noValidate={props.noValidate}
            style={{ width: '100%' }}
            numbersOnly
          />
        </Grid.Col>
        <Grid.Col>
          <FormFieldText
            {...fieldProps(props, 'address')}
            onChange={(e) => props.handleChange('address', e)}
            label='Logradouro'
            isRequired={props.isRequired}
            required={props.isRequired}
            noValidate={props.noValidate}
            valueDefault={
              addressByCEP.logradouro || (defaultPrefix || {})['address']
            }
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col>
          <FormFieldText
            {...fieldProps(props, 'number')}
            onChange={(e) => props.handleChange('number', e)}
            valueDefault={(defaultPrefix || {})['number'] || ''}
            label='Número'
            noValidate={props.noValidate}
            isRequired={props.isRequired}
            mask={['onlyNumbers']}
          />
        </Grid.Col>
        <Grid.Col field>
          <FormFieldText
            {...fieldProps(props, 'state')}
            onChange={(e) => props.handleChange('state', e)}
            label='Estado'
            isRequired={props.isRequired}
            noValidate={props.noValidate}
            valueDefault={addressByCEP.uf || (defaultPrefix || {})['state']}
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col field>
          <FormFieldText
            {...fieldProps(props, 'city')}
            onChange={(e) => props.handleChange('city', e)}
            label='Cidade'
            isRequired={props.isRequired}
            noValidate={props.noValidate}
            valueDefault={addressByCEP.localidade || (defaultPrefix || {})['city']}
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </Grid.Col>
        <Grid.Col field>
          <FormFieldText
            {...fieldProps(props, 'neighborhood')}
            onChange={(e) => props.handleChange('neighborhood', e)}
            label='Bairro'
            isRequired={props.isRequired}
            noValidate={props.noValidate}
            valueDefault={
              addressByCEP.bairro || (defaultPrefix || {})['neighborhood']
            }
            isDisabled={
              props.formIsWorking || isSearchingForAddress || props.isDisabled
            }
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col field>
          <FormFieldText
            {...fieldProps(props, 'additional_address')}
            onChange={(e) => props.handleChange('additional_address', e)}
            valueDefault={(defaultPrefix || {})['additional_address']}
            label='Complemento (Opcional)'
            noValidate
          />
        </Grid.Col>
      </Grid>

      {props.adicionalFields}
    </>
  )
}

export default FullAddress
