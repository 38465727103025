import React, { useEffect, useState } from 'react';

import { FormFieldSelect } from 'components/lib/FormFields';

import { request } from 'core/constants';

import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

const GoalSelection = (props) => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  const [options, setOptions] = useState([]);

  const getOptions = async () => {
    try {
      const { data } = await request.get('proposal-goal?orderBy=name,asc');

      if (props.defaultValue?.goal) {
        handleOnSelect(props.defaultValue.goal.id);
      }

      setOptions(data);
    } catch (err) {
      console.log('ERROR LOADING PROPOSAL GOAL', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  const handleOnSelect = (e) => {
    props.onGoalSelect(e);

    setValue(e);
  };

  return (
    <Field label="Finalidade">
      <Control>
        <FormFieldSelect
          valueKey="id"
          valueDefault={value}
          hideBlankOption
          options={options}
          isLoading={loading}
          isDisabled={loading}
          onChange={(e) => handleOnSelect(e)}
          placeholder="Selecionar finalidade"
        />
      </Control>
    </Field>
  );
};

export default GoalSelection;
