import React from 'react';

import { components } from 'react-select';

import { Text } from './styles';

const MultiValueLabel = (props) => {
  const data = props.data || {};

  return (
    <components.MultiValueLabel {...props}>
      {data.cnpj ? (
        <Text>{data.corporate_name}</Text>
      ) : (
        <Text>{data.name || data.email}</Text>
      )}
    </components.MultiValueLabel>
  );
};

export default MultiValueLabel;
