import ReactQuill from 'react-quill';

const startConditionString = 'Início Condição';
const endConditionString = 'Fim Condição';

const startListString = 'Início Lista';
const endListString = 'Fim Lista';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const addList = (editorRef, attribute, name, object) => {
  const quill = editorRef.getEditor();

  const addFunction = ({ type, attribute, name, object }) => {
    const uniqueId = uuidv4();
    let range = quill.getSelection(true);
    quill.deleteText(range.index, range.length);

    const startObject = {
      ['data-start']: false,
      ['data-id']: uniqueId,
      ['data-type']: type,
      ['data-name']: name,
      ['data-attribute']: attribute,
      ['data-plural']: object?.list_name,
      ['data-object']: object?.object,
    };

    const endObject = { ...startObject, ['data-start']: true };

    const description = '\n Conteúdo da lista aqui! \n';

    if (range) {
      quill.insertText(range.index, '\n', ReactQuill.Quill.sources.USER);
      quill.insertText((range?.index || 0) + 1, '\n', ReactQuill.Quill.sources.USER);
      quill.insertEmbed((range?.index || 0) + 1, 'operation-blot', startObject, ReactQuill.Quill.sources.USER);
      quill.insertText((range?.index || 0) + 1, description, ReactQuill.Quill.sources.USER);
      quill.insertEmbed((range?.index || 0) + 1, 'operation-blot', endObject, ReactQuill.Quill.sources.USER);
    }
  };

  addFunction({ type: 'list', attribute, name, object });
};

export const addCondition = (editorRef, attribute, name, object) => {
  const quill = editorRef.getEditor();

  const addFunction = ({ type, attribute, name, object }) => {
    const uniqueId = uuidv4();
    let range = quill.getSelection(true);
    quill.deleteText(range.index, range.length);

    const startObject = {
      ['data-start']: true,
      ['data-id']: uniqueId,
      ['data-type']: type,
      ['data-name']: name,
      ['data-attribute']: attribute,
      ['data-object']: object?.object,
    };
    const endObject = { ...startObject, ['data-start']: false };

    const description = '\n Conteúdo condicional aqui! \n';

    if (range) {
      quill.insertText(range.index, '\n', ReactQuill.Quill.sources.USER);
      quill.insertText((range?.index || 0) + 1, '\n', ReactQuill.Quill.sources.USER);
      quill.insertEmbed((range?.index || 0) + 1, 'operation-blot', endObject, ReactQuill.Quill.sources.USER);
      quill.insertText((range?.index || 0) + 1, description, ReactQuill.Quill.sources.USER);
      quill.insertEmbed((range?.index || 0) + 1, 'operation-blot', startObject, ReactQuill.Quill.sources.USER);
    }
  };

  addFunction({ type: 'conditional', attribute, name, object });
};

export const addToken = (editorRef, attribute, name, object) => {
  const quill = editorRef.getEditor();
  const uniqueId = uuidv4();
  let range = quill.getSelection(true);
  quill.deleteText(range.index, range.length);

  const properties = {
    ['data-id']: uniqueId,
    ['data-name']: name,
    ['data-attribute']: attribute,
    ['data-object']: object?.object,
  };

  if (range) {
    quill.insertEmbed(range?.index || 0, 'merge-blot', properties, ReactQuill.Quill.sources.USER);
    quill.setSelection((range?.index || 0) + 1, ReactQuill.Quill.sources.SILENT);
  }
};

export const insertBasicText = (editorRef, opt = '') => {
  const quill = editorRef.getEditor();
  quill.focus();
  let cursorPosition = 0;
  const range = quill.getSelection();

  if (range) {
    if (range.length == 0) {
      cursorPosition = range.index;
    } else {
      quill.deleteText(range.index, range.length);
      cursorPosition = range.index;
    }
  }

  const formatted = `${opt}`;
  quill.insertText(cursorPosition, formatted);
  quill.setSelection(cursorPosition + formatted.length);
};
