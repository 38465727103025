import React from 'react';
import Text from 'components/lib/Text';
import { currency } from './utils';

const PropertyInfo = ({ property, isProposal, hideCosts, showCostsOnly, goal }) => {
  if (!property) return null;

  const { parking_spaces, hobby_boxes, area } = property;

  let condoFee = currency(property['condo_fee']);
  let iptu = currency(property.iptu);

  const isIptuFree = iptu === currency('0,00');
  const showCurrency = !isIptuFree;

  isProposal ? (isIptuFree ? (iptu = <strong>isento</strong>) : null) : null;

  return (
    <div>
      {goal && <Text>Finalidade: {goal.name}</Text>}

      {!hideCosts && (
        <>
          {condoFee && <Text>Condomínio (Mensal aproximado): R$ {condoFee}</Text>}
          {iptu && (
            <Text>
              IPTU (Aproximado): {showCurrency ? 'R$' : null} {iptu}
            </Text>
          )}
        </>
      )}
      {!showCostsOnly && (
        <>
          {property.parking_spaces && property.parking_spaces.length ? (
            <Text>
              Vagas de garagem:{' '}
              {parking_spaces == 0
                ? 'sem vaga(s)'
                : property.parking_spaces.join(', ')}
            </Text>
          ) : null}
          {property.hobby_boxes &&
          property.hobby_boxes.length &&
          hobby_boxes != 0 ? (
            <Text>Depósito(s): {property.hobby_boxes.join(', ')}</Text>
          ) : null}
          {area ? (
            <Text>Área privativa: {!area ? 'Não informado' : area + ' m²'}</Text>
          ) : null}
        </>
      )}
    </div>
  );
};

export default PropertyInfo;
