import React from 'react';

// Components
import {
  FormFieldText,
  FormFieldDate,
  FormFieldSelect
} from 'components/lib/FormFields';
import { fieldProps } from '../formCreator';
import Grid from 'components/lib/Grid';

const PersonalData = (props) => (
  <React.Fragment>
      <FormFieldText
        {...fieldProps(props, 'name')}
        label="Nome"
      />
      <FormFieldText
        {...fieldProps(props, 'email')}
        label="Email"
      />
      <FormFieldText
        {...fieldProps(props, 'phone')}
        label="Telefone"
        noValidate
        mask={['phone']}
      />
      <FormFieldText
        {...fieldProps(props, 'cpf')}
        label="CPF"
        mask={['cpf']}
      />
      <FormFieldText
        {...fieldProps(props, 'income')}
        mask={['number']}
        label="Renda"
      />
      <FormFieldText
        {...fieldProps(props, 'estimated_rent_value')}
        mask={['number']}
        label="Valor estimado do aluguel"
      />
  </React.Fragment>
);

export default PersonalData;
