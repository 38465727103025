import React, { useState } from 'react';
import UploadButton from './UploadButton';
import { Classes, Dialog, Callout } from '@blueprintjs/core';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from 'components/lib/Button';

const cropSettings = {
  width: 200,
  height: 200,
  aspect: 1,
};

export default ({ title = 'Upload de Imagem', minWidth = 200, minHeight = 200, onConfirm, children }) => {
  const [imageRef, setImageRef] = useState(null);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ ...cropSettings, minWidth, minHeight });

  const getCroppedImg = () => {
    const canvas = document.createElement('canvas');
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(imageRef, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/png');
    });
  };

  const closeModal = () => {
    setVisible(false);
    setSrc(null);
    setError(null);
  };

  const tapOnConfirm = async () => {
    setUploading(true);
    setError(null);

    if (onConfirm) {
      try {
        const blob = await getCroppedImg();
        const fail = await onConfirm(blob);
        if (fail) {
          throw fail;
        }
      } catch (error) {
        setUploading(false);
        setError(error.message || error);
        return;
      }
    }

    setUploading(false);
    setVisible(false);
    setSrc(null);
  };

  const component = !children ? (
    <Button intent="primary" onClick={() => setVisible(true)}>
      {title}
    </Button>
  ) : typeof children === 'function' ? (
    children(() => setVisible(true))
  ) : (
    children
  );

  return (
    <>
      {component}

      <Dialog isOpen={visible} title={title} onClose={uploading ? null : closeModal}>
        <div className={Classes.DIALOG_BODY}>
          <UploadButton instantUpload disabled={uploading} title="Escolher Imagem" uploadMethod={({ base64 }) => setSrc(base64)} />
          <br />
          {src && <ReactCrop src={src} crop={crop} onChange={setCrop} onImageLoaded={setImageRef} />}

          {error && <Callout intent="danger">{error}</Callout>}
        </div>

        {src && (
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button isLoading={uploading} intent="success" icon="confirm" onClick={tapOnConfirm}>
                Confirmar
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};
