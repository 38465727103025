import React from 'react';

// Components
import { FormFieldText, FormFieldFile } from 'components/lib/FormFields';
import FilePreview from 'components/lib/FilePreview';
import Gravatar from 'components/lib/Gravatar';
import Grid from 'components/lib/Grid';
import Space from 'components/lib/Space';
import Icon from 'components/lib/Icon';
import { fieldProps } from '../formCreator';

const UserProfile = (props) => (
  <React.Fragment>
    <Grid isCentered>
      <Grid.Col isNarrow>
        <FormFieldFile
          label="Imagem do perfil"
          {...fieldProps(props, 'avatar')}
          accept="image/*"
          render={(fileProps) => {

            if (fileProps.files[0]) {
              const file = fileProps.files[0];
              return (
                <FilePreview
                  isRounded
                  source={file.preview()}
                  filename={file.name}
                  fallback={<Icon name="fas fa-file" />}
                  ext={file.ext}
                />
              );
            }

            if (!props.defaultValue['avatar']) {
              return (
                <figure className="image is-128px has-border-rounded">
                  <Gravatar
                    email={props.defaultValue['email']}
                    alt={props.defaultValue['name'] || props.defaultValue['email']}
                  />
                </figure>
              );
            };

            if (props.defaultValue['avatar']) {
              return (
                <figure className="image is-128px has-border-rounded">
                  <img src={props.defaultValue['avatar']} alt="" />
                </figure>
              );
            };

          }}
        />
      </Grid.Col>
      <Grid.Col>
        <Grid>
          <Grid.Col>
            <FormFieldText
              {...fieldProps(props, 'name')}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col>
            <FormFieldText
              {...fieldProps(props, 'email')}
              isDisabled
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
    <Space />
  </React.Fragment>
);

export default UserProfile;
