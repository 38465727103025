import React from 'react'
import ReactTimeout from 'react-timeout'
import { debounce } from 'throttle-debounce'

import { FormFieldText } from 'components/lib/FormFields'
import Form from 'components/lib/Form'

import ListFilter from './components/ListFilter'

export default function withFilter(Component) {
  class WithFilterWrapper extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        value: '',
      }
    }

    render() {
      return (
        <>
          <Form
            getRef={(ref) => (this.form = ref)}
            onSubmit={this.submitForm.bind(this)}
            autoFocus
          >
            <div style={{ display: 'flex' }}>
              <FormFieldText
                placeholder='Pesquisar'
                value={this.state.value}
                onChange={debounce(200, this.handleFieldChanges.bind(this))}
                noValidate
                style={{ flex: 1, marginRight: 7 }}
              />

              <ListFilter submitForm={this.submitForm.bind(this)} />
            </div>
          </Form>

          <Component
            {...this.props}
            createFilterRequest={this.requestSearch.bind(this)}
          />
        </>
      )
    }

    requestSearch(callback) {
      this.request = callback
    }

    handleFieldChanges(value, field) {
      if (!field.wasFocused) return
      this.setState({ value })
      this.request(value)
    }

    submitForm() {
      this.request(this.state.value)
    }
  }

  WithFilterWrapper.displayName = `WithFilterWrapper(${getDisplayName(Component)})`

  return ReactTimeout(WithFilterWrapper)
}

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component'
}
