import React from 'react';

const ListEmpty = () => (
  <svg width="236" height="258" viewBox="0 0 236 258">
    <g opacity="0.5">
      <circle cx="118" cy="129" r="118" fill="#D8D8D8" />
      <path
        d="M72.0397 3.47531L78.4397 7.16531C78.4002 6.8837 78.3801 6.59968 78.3797 6.3153V4.46531C78.3797 1.16531 80.6997 -0.164687 83.5497 1.46531C84.7854 2.22552 85.8372 3.25007 86.6297 4.46531C87.0997 0.315313 90.3497 -1.22469 94.2997 1.05531C96.5498 2.52263 98.4224 4.49972 99.7655 6.82618C101.109 9.15265 101.884 11.7629 102.03 14.4453V17.2153C102.06 18.324 101.838 19.4251 101.38 20.4353L106.69 23.4953C107.528 24.0438 108.225 24.7826 108.724 25.6514C109.222 26.5203 109.509 27.4947 109.56 28.4953C109.56 30.3253 108.27 31.0653 106.69 30.1453L72.0397 10.1453C71.2026 9.59631 70.5072 8.85721 70.0101 7.98826C69.5131 7.11932 69.2285 6.14516 69.1797 5.14531C69.1797 3.29531 70.4597 2.55531 72.0397 3.47531Z"
        fill="#A3CDE1"
      />
      <path
        d="M44.6778 22.1053L49.5078 24.8853C49.4763 24.6734 49.4596 24.4595 49.4578 24.2453V22.8553C49.4578 20.3653 51.2078 19.3553 53.3678 20.5953C54.2906 21.1698 55.0813 21.9332 55.6878 22.8353C56.0378 19.7053 58.4978 18.5453 61.4778 20.2653C63.1763 21.3731 64.5895 22.866 65.6025 24.6227C66.6156 26.3794 67.1998 28.3503 67.3078 30.3753V32.4653C67.3314 33.2988 67.1638 34.1266 66.8178 34.8853L70.8178 37.1953C71.4463 37.6076 71.9692 38.1617 72.3444 38.8131C72.7196 39.4644 72.9365 40.1948 72.9778 40.9453C72.9778 42.3253 71.9778 42.8853 70.8178 42.1953L44.6678 27.0953C44.0378 26.687 43.5136 26.1351 43.1382 25.485C42.7627 24.8349 42.5467 24.105 42.5078 23.3553C42.5178 21.9753 43.4778 21.4153 44.6778 22.1053Z"
        fill="#A3CDE1"
      />
      <path
        d="M158.052 198.095L154.952 196.635C154.952 196.635 153.952 185.445 154.952 177.975C156.952 164.065 166.662 152.075 175.312 150.665C186.452 148.855 184.472 159.105 178.102 163.535C167.952 170.635 160.052 181.365 158.052 198.095Z"
        fill="#2091EA"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M158.052 198.095L154.952 196.635C154.952 196.635 153.952 185.445 154.952 177.975C156.952 164.065 166.662 152.075 175.312 150.665C186.452 148.855 184.472 159.105 178.102 163.535C167.952 170.635 160.052 181.365 158.052 198.095Z"
          fill="black"
        />
      </g>
      <path
        d="M156.871 193.635H156.951C157.091 193.608 157.215 193.527 157.296 193.409C157.377 193.291 157.407 193.146 157.381 193.005C155.221 181.575 164.631 160.385 177.221 154.365C177.353 154.303 177.456 154.19 177.505 154.052C177.555 153.915 177.548 153.763 177.486 153.63C177.423 153.497 177.311 153.395 177.173 153.345C177.035 153.296 176.883 153.303 176.751 153.365C163.751 159.565 154.091 181.365 156.311 193.185C156.333 193.316 156.402 193.434 156.505 193.517C156.608 193.6 156.738 193.642 156.871 193.635Z"
        fill="white"
      />
      <path
        d="M154.951 196.635C154.721 194.975 154.621 192.555 154.591 191.955C154.462 189.848 154.772 187.736 155.501 185.755C156.459 183.137 158.284 180.925 160.671 179.485C161.971 178.735 163.491 178.195 164.331 176.955C164.941 175.812 165.266 174.54 165.281 173.245C165.541 171.155 166.191 168.945 167.871 167.665C169.551 166.385 171.871 166.355 173.871 166.575C176.871 166.895 179.681 167.295 182.481 165.925C184.718 164.737 187.03 163.695 189.401 162.805C192.401 161.805 199.111 160.305 200.521 164.515C200.681 165.096 200.709 165.706 200.604 166.299C200.498 166.892 200.262 167.455 199.911 167.945C199.202 168.921 198.353 169.787 197.391 170.515L192.701 174.415C192.142 174.825 191.658 175.33 191.271 175.905C190.361 177.465 191.011 179.435 190.811 181.225C190.684 182.136 190.361 183.008 189.864 183.782C189.366 184.555 188.707 185.211 187.931 185.705C186.368 186.684 184.529 187.129 182.691 186.975C180.241 186.805 176.241 184.925 174.741 187.815C174.326 188.8 174.082 189.848 174.021 190.915C173.962 191.987 173.617 193.023 173.021 193.915C172.021 195.135 170.351 195.485 168.791 195.645C166.606 195.684 164.436 196.021 162.341 196.645C161.501 196.975 160.971 198.045 160.481 198.755C158.951 198.245 157.271 197.635 154.951 196.635Z"
        fill="#2091EA"
      />
      <path
        d="M156.319 195.795C156.244 195.814 156.165 195.814 156.089 195.795C155.965 195.744 155.866 195.646 155.814 195.523C155.762 195.399 155.76 195.26 155.809 195.135C162.109 179.485 182.209 168.275 194.189 164.555C194.253 164.535 194.32 164.528 194.387 164.535C194.453 164.541 194.517 164.561 194.576 164.592C194.635 164.624 194.687 164.667 194.728 164.719C194.77 164.771 194.801 164.831 194.819 164.895C194.857 165.022 194.844 165.159 194.782 165.277C194.721 165.395 194.616 165.484 194.489 165.525C182.699 169.185 162.919 180.185 156.749 195.525C156.707 195.603 156.645 195.669 156.57 195.716C156.494 195.763 156.408 195.791 156.319 195.795Z"
        fill="white"
      />
      <path
        d="M52.951 97.0149C49.331 96.1549 45.341 98.7349 43.011 104.415C40.681 110.095 36.011 124.615 35.011 128.415C34.571 130.115 47.491 145.175 47.011 144.955C46.871 144.885 51.221 137.605 51.221 137.605L44.951 127.955L48.951 117.485L52.951 97.0149Z"
        fill="#B16668"
      />
      <path
        d="M44.5701 231.815C44.0801 232.005 43.9501 232.935 43.8801 234.815C43.8201 236.225 43.5201 238.675 45.1701 239.165C46.8201 239.655 49.2301 239.405 50.2901 238.085C51.5662 236.307 52.9312 234.595 54.3801 232.955C56.0101 231.195 57.7701 229.565 58.4301 227.105C59.0301 224.885 58.5301 223.925 57.0201 223.275C54.9301 222.395 51.6601 225.275 50.0201 225.745C48.6301 226.125 45.0601 231.635 44.5701 231.815Z"
        fill="#263238"
      />
      <path
        d="M44.5703 230.095V232.375C45.4269 232.923 46.4166 233.227 47.4331 233.255C48.4496 233.284 49.4547 233.034 50.3403 232.535C50.4403 232.315 50.9403 229.265 50.9403 229.265L44.5703 230.095Z"
        fill="#B16668"
      />
      <path
        d="M61.5476 243.945C61.1522 245.167 60.8185 246.409 60.5476 247.665C60.2576 249.045 59.8576 251.355 60.3476 252.385C60.8376 253.415 62.7076 254.095 65.6376 254.075C67.5276 254.075 70.4776 255.345 72.4976 256.475C74.5276 257.47 76.8232 257.789 79.0476 257.385C81.6476 257.075 84.3477 255.565 84.7677 254.495C85.1877 253.425 84.6477 252.895 78.5177 250.285C78.4577 250.285 72.0577 247.365 69.0877 244.095L61.5476 243.945Z"
        fill="#263238"
      />
      <path
        d="M61.5547 241.475C61.5547 241.475 61.5547 243.165 61.5547 244.135C61.5547 245.105 62.6947 246.545 65.6547 246.445C67.7347 246.365 69.1847 245.805 69.0947 244.095V241.635L61.5547 241.475Z"
        fill="#B16668"
      />
      <path
        d="M42.3204 198.455C42.8107 194.45 43.7465 190.512 45.1104 186.715C45.1104 186.715 43.8204 176.615 43.1104 166.915C41.7804 149.525 42.8504 143.845 46.2704 136.045C46.2704 136.045 75.4404 133.045 76.3304 145.215C77.4404 160.315 75.9704 189.495 75.5704 194.825C75.1704 200.155 74.6704 202.565 72.7904 213.705C71.3504 222.185 69.1304 241.635 69.1304 241.635C67.9573 242.23 66.6556 242.527 65.3404 242.499C64.0252 242.471 62.7372 242.119 61.5904 241.475C61.5904 241.475 59.2104 217.195 59.4604 211.045C59.6364 206.494 60.4063 201.986 61.7504 197.635C61.7504 197.635 59.9404 173.025 59.4204 169.105C59.4204 169.105 58.5004 176.915 58.1004 182.245C57.7004 187.575 56.1004 192.675 54.5804 202.585C53.2404 211.175 50.7504 230.385 50.7504 230.385C46.8804 232.485 44.5704 230.115 44.5704 230.115C44.5704 230.115 41.2804 205.905 42.3204 198.455Z"
        fill="#2091EA"
      />
      <g opacity="0.35">
        <path
          opacity="0.35"
          d="M42.3204 198.455C42.8107 194.45 43.7465 190.512 45.1104 186.715C45.1104 186.715 43.8204 176.615 43.1104 166.915C41.7804 149.525 42.8504 143.845 46.2704 136.045C46.2704 136.045 75.4404 133.045 76.3304 145.215C77.4404 160.315 75.9704 189.495 75.5704 194.825C75.1704 200.155 74.6704 202.565 72.7904 213.705C71.3504 222.185 69.1304 241.635 69.1304 241.635C67.9573 242.23 66.6556 242.527 65.3404 242.499C64.0252 242.471 62.7372 242.119 61.5904 241.475C61.5904 241.475 59.2104 217.195 59.4604 211.045C59.6364 206.494 60.4063 201.986 61.7504 197.635C61.7504 197.635 59.9404 173.025 59.4204 169.105C59.4204 169.105 58.5004 176.915 58.1004 182.245C57.7004 187.575 56.1004 192.675 54.5804 202.585C53.2404 211.175 50.7504 230.385 50.7504 230.385C46.8804 232.485 44.5704 230.115 44.5704 230.115C44.5704 230.115 41.2804 205.905 42.3204 198.455Z"
          fill="black"
        />
      </g>
      <path
        d="M67.8581 93.0848L66.4881 104.355L56.5781 103.455L57.7081 90.9448L67.8581 93.0848Z"
        fill="#B16668"
      />
      <path
        d="M45.7031 102.635C46.7931 99.6348 50.5031 96.3648 54.3131 97.0748L57.1131 97.5848C57.1931 100.185 62.1131 101.525 66.9731 99.9548C68.4331 100.375 69.7831 100.805 70.9731 101.235C74.4631 102.485 75.8631 108.235 75.9731 117.975C76.0831 127.715 77.0831 151.845 77.0831 151.845L72.9531 153.155L71.0531 146.085L70.2831 154.555C65.6459 155.636 60.8193 155.612 56.1931 154.485C45.7131 152.195 42.4531 146.545 42.4531 146.545C43.7351 140.005 46.2828 133.778 49.9531 128.215C44.7131 114.245 44.0931 107.065 45.7031 102.635Z"
        fill="#EBEBEB"
      />
      <path
        d="M75.2315 78.7648C75.8244 77.4183 76.0666 75.9436 75.9355 74.4782C75.8043 73.0128 75.3041 71.6046 74.4815 70.3848C71.1515 65.1648 64.7415 64.2748 57.1415 66.3148C54.4888 67.1273 52.1517 68.739 50.4495 70.9298C48.7474 73.1206 47.7632 75.7836 47.6315 78.5548C47.2615 84.1248 62.8115 88.2348 62.4515 92.0548L75.2315 78.7648Z"
        fill="#263238"
      />
      <path
        d="M75.5625 80.8848C75.5625 80.8848 77.6725 84.2648 77.3725 84.6148C76.8499 84.9389 76.2745 85.169 75.6725 85.2948L75.5625 80.8848Z"
        fill="#9A4A4D"
      />
      <path
        d="M69.132 70.1747C71.232 71.3347 74.872 73.4747 75.612 81.3747C76.372 89.4747 74.492 92.2347 73.362 93.2647C72.602 93.9647 68.752 94.1147 66.772 93.4447C64.292 92.5947 58.932 89.7747 56.502 85.5347C53.642 80.5347 53.202 73.9847 56.722 71.5347C58.506 70.2753 60.5807 69.4899 62.7513 69.252C64.922 69.0141 67.1176 69.3316 69.132 70.1747Z"
        fill="#B16668"
      />
      <path
        d="M69.7476 70.5248C69.7476 70.5248 71.4077 77.9448 69.0477 82.5248C69.0477 82.5248 67.8277 79.6148 65.3277 80.3748C62.8277 81.1348 62.9577 84.4648 64.8677 86.2148C65.3555 86.7152 65.9751 87.0671 66.6547 87.2299C67.3342 87.3927 68.046 87.3598 68.7077 87.1348C67.8653 89.2347 66.6028 91.1403 64.9976 92.7348C64.9976 92.7348 57.6076 96.0248 51.2176 88.8048C45.4076 82.2548 46.4876 70.8048 55.3776 68.1348C64.2676 65.4648 69.7476 70.5248 69.7476 70.5248Z"
        fill="#263238"
      />
      <path
        d="M48.6844 71.2946C49.0339 71.5947 49.2502 72.0211 49.2858 72.4804C49.3214 72.9396 49.1735 73.3943 48.8744 73.7446L46.2344 71.4746C46.3829 71.3011 46.5643 71.1588 46.7681 71.0557C46.9719 70.9527 47.1941 70.891 47.4219 70.8743C47.6496 70.8575 47.8785 70.8861 48.0952 70.9582C48.3118 71.0304 48.5121 71.1447 48.6844 71.2946Z"
        fill="#263238"
      />
      <path
        d="M96.0781 119.125C97.5181 117.485 98.0781 113.825 99.9781 111.325C101.878 108.825 102.598 106.995 103.358 108.085C104.358 109.535 102.098 112.425 102.598 112.715C103.098 113.005 104.978 116.815 105.168 118.935C105.358 121.055 99.7481 126.375 99.7481 126.375L96.0781 119.125Z"
        fill="#B16668"
      />
      <path
        d="M102.438 112.385C102.438 112.385 109.218 111.805 110.208 112.385C111.198 112.965 110.898 115.905 110.208 117.705C109.518 119.505 106.058 120.705 102.848 122.845C102.848 122.525 102.438 112.385 102.438 112.385Z"
        fill="#B16668"
      />
      <path
        d="M70.1293 100.965C73.0393 101.965 75.5893 103.155 78.3093 108.265C81.7993 114.835 88.1593 127.695 88.1593 127.695L96.0794 119.125C96.0794 119.125 97.2294 117.505 99.3694 121.045C101.509 124.585 100.569 125.565 100.569 125.565C100.569 125.565 91.3594 137.905 89.6693 139.475C87.9793 141.045 85.1993 139.825 83.3793 137.355C81.5593 134.885 70.1293 120.575 70.1293 120.575C70.1293 120.575 63.8793 112.355 70.1293 100.965Z"
        fill="#B16668"
      />
      <path
        opacity="0.2"
        d="M59.4209 169.085L58.8609 163.195C55.687 162.722 52.6517 161.573 49.9609 159.825C49.9609 159.825 51.8609 162.825 57.0609 164.245L57.6009 171.745L57.9209 184.045L59.4209 169.085Z"
        fill="black"
      />
      <path
        d="M38.6484 99.4047C39.9988 99.4087 41.3367 99.1463 42.5855 98.6325C43.8343 98.1188 44.9696 97.3638 45.9263 96.4109C46.883 95.4579 47.6424 94.3256 48.1609 93.0787C48.6795 91.8319 48.9471 90.4951 48.9484 89.1447V73.5547C47.7716 73.1728 46.5211 73.0749 45.2992 73.2688C44.0773 73.4628 42.9186 73.9433 41.9179 74.6709C40.9172 75.3984 40.1029 76.3525 39.5417 77.4551C38.9804 78.5577 38.688 79.7775 38.6884 81.0147L38.6484 99.4047Z"
        fill="#263238"
      />
      <path
        d="M76.1897 128.185C76.1897 128.185 70.4997 120.845 68.6797 117.895L76.3897 134.175L76.1897 128.185Z"
        fill="#E0E0E0"
      />
      <path
        d="M126.084 67.905C123.924 74.275 119.314 93.275 119.314 93.275C119.314 93.275 110.524 104.965 108.104 107.195C106.764 108.435 103.734 110.665 103.734 110.665L106.254 112.525C106.254 112.525 110.454 111.245 110.804 113.525C115.184 110.002 119.326 106.193 123.204 102.125C124.183 101.103 124.873 99.8403 125.204 98.465L128.954 82.335C128.954 82.335 128.954 69.335 130.954 67.635C132.954 65.935 132.164 62.085 132.164 62.085C130.794 62.125 127.784 62.915 126.084 67.905Z"
        fill="#FFA8A7"
      />
      <path
        d="M132.23 61.4448C132.842 63.1652 133.584 64.8366 134.45 66.4448C135.33 67.9548 128.6 95.1048 128.15 97.1248C127.7 99.1448 114.65 110.915 114.65 110.915C112.292 109.917 110.418 108.036 109.43 105.675L118.85 93.4148C118.85 93.4148 121.78 80.5548 123.32 74.6548C124.86 68.7548 126.5 62.0848 132.23 61.4448Z"
        fill="#2091EA"
      />
      <path
        opacity="0.1"
        d="M132.23 61.4448C132.842 63.1652 133.584 64.8366 134.45 66.4448C135.33 67.9548 128.6 95.1048 128.15 97.1248C127.7 99.1448 114.65 110.915 114.65 110.915C112.292 109.917 110.418 108.036 109.43 105.675L118.85 93.4148C118.85 93.4148 121.78 80.5548 123.32 74.6548C124.86 68.7548 126.5 62.0848 132.23 61.4448Z"
        fill="black"
      />
      <path d="M159.805 189.035H153.305V200.175H159.805V189.035Z" fill="#FFA8A7" />
      <path
        d="M133.187 191.735L126.687 191.955L126.367 180.175L133.187 179.945V191.735Z"
        fill="#FFA8A7"
      />
      <path
        d="M158.879 109.545C158.879 115.755 159.309 151.875 159.309 151.875C159.459 153.655 161.179 156.875 161.309 163.275C161.499 172.615 160.059 194.605 160.059 194.605C158.936 195.156 157.705 195.454 156.454 195.478C155.204 195.502 153.963 195.252 152.819 194.745C152.819 194.745 147.709 163.645 146.549 154.395C145.549 146.335 142.019 124.805 142.019 124.805L137.019 152.065C137.773 155.456 138.046 158.937 137.829 162.405C137.519 166.675 133.559 188.085 133.559 188.085C132.426 188.726 131.166 189.11 129.869 189.211C128.571 189.311 127.267 189.124 126.049 188.665C126.049 188.665 125.659 155.055 125.589 151.135C125.499 146.665 127.449 108.195 127.449 108.195L158.879 109.545Z"
        fill="#37474F"
      />
      <path
        d="M153.291 196.965V196.035C152.941 196.035 152.731 196.375 152.591 197.205C152.498 197.824 152.371 198.439 152.211 199.045C151.924 200.183 151.546 201.297 151.081 202.375C150.161 204.645 149.391 205.485 147.281 208.135C146.566 208.869 145.898 209.647 145.281 210.465C144.891 211.165 144.751 213.105 145.421 213.555C147.871 215.195 151.601 215.235 154.241 214.465C155.223 214.189 156.106 213.637 156.785 212.875C157.464 212.113 157.91 211.172 158.071 210.165C158.171 209.565 158.131 208.945 158.201 208.345C158.433 206.972 158.992 205.676 159.831 204.565C160.317 203.923 160.736 203.233 161.081 202.505C161.621 201.175 161.191 199.665 160.841 198.335C160.541 197.185 160.211 195.925 159.841 196.035V196.735C158.914 197.468 157.762 197.857 156.581 197.835C155.591 197.875 153.281 197.685 153.291 196.965Z"
        fill="#263238"
      />
      <path
        d="M126.622 189.955C126.282 189.955 126.012 190.445 125.262 191.255C124.149 192.375 122.858 193.304 121.442 194.005C119.002 195.225 114.272 197.095 112.122 198.065C110.782 198.665 110.852 200.285 112.232 201.205C113.612 202.125 116.962 202.785 120.972 201.895C123.152 201.405 125.972 199.385 127.802 199.495C129.632 199.605 132.972 199.575 134.302 198.655C135.632 197.735 135.102 195.785 134.722 194.105C134.312 192.275 133.802 189.405 133.192 189.535V190.165C132.412 191.355 128.372 191.835 126.662 190.965L126.622 189.955Z"
        fill="#263238"
      />
      <path
        d="M142.033 124.805C138.665 123.765 135.566 122 132.953 119.635C132.953 119.635 134.703 122.915 139.953 125.955L137.433 150.205L142.033 124.805Z"
        fill="#263238"
      />
      <path
        d="M147.602 61.8847C149.822 62.4247 154.282 63.1547 155.282 67.5647C156.482 72.8547 156.872 84.3847 156.872 84.3847L157.282 110.045C152.802 113.305 136.352 115.045 126.922 108.195C126.922 108.195 128.082 77.3947 128.082 72.5947C128.082 67.7947 130.742 62.0347 138.082 61.5947L147.602 61.8847Z"
        fill="#F5F5F5"
      />
      <path
        d="M138.2 61.1146C136.177 62.8089 134.713 65.0744 134 67.6146C133.1 71.4946 133 114.825 133 114.825C133 114.825 128.4 113.975 126.25 112.285C126.25 112.285 126.52 78.0846 126.96 72.4246C127.63 63.8546 127.56 61.4246 138.23 60.4746L138.2 61.1146Z"
        fill="#2091EA"
      />
      <path
        d="M149.542 60.4746C149.542 60.4746 143.412 64.2446 143.342 79.2546C143.282 92.2546 142.852 114.825 142.852 114.825C142.852 114.825 153.002 116.315 159.432 112.475C159.432 112.475 160.202 81.0146 160.072 76.1446C159.932 70.5646 158.632 63.3546 156.842 62.6346C154.465 61.734 152.025 61.012 149.542 60.4746Z"
        fill="#2091EA"
      />
      <path
        d="M172.342 94.9348C166.242 69.9948 161.732 61.6748 154.342 61.7748C152.172 64.3548 152.772 72.1748 156.192 76.9148C156.192 76.9148 163.822 92.9948 165.032 98.7148C166.102 103.715 167.312 110.065 167.242 114.385C167.217 115.149 166.915 115.877 166.392 116.435C165.002 117.945 164.992 119.165 164.092 120.835C163.382 122.135 162.612 122.625 162.832 122.925C163.124 123.234 163.52 123.423 163.944 123.455C164.368 123.486 164.788 123.358 165.122 123.095C165.773 122.621 166.355 122.059 166.852 121.425C166.613 123.024 166.152 124.583 165.482 126.055C163.912 129.585 164.292 131.385 169.062 130.125C172.062 129.335 173.922 126.855 174.362 119.675C174.582 117.034 174.609 114.38 174.442 111.735C173.792 104.935 173.332 98.9548 172.342 94.9348Z"
        fill="#FFA8A7"
      />
      <path
        d="M153.048 61.3647C153.048 61.3647 157.958 61.1447 160.618 63.8347C163.278 66.5247 166.868 72.1847 170.348 84.5547C173.828 96.9247 174.898 105.965 175.068 112.925C175.068 112.925 170.708 114.925 166.688 112.645C166.688 112.645 164.908 101.875 163.328 96.1447C161.748 90.4147 155.858 78.3447 155.858 78.3447C155.858 78.3447 150.228 69.9747 153.048 61.3647Z"
        fill="#2091EA"
      />
      <path
        opacity="0.1"
        d="M153.048 61.3647C153.048 61.3647 157.958 61.1447 160.618 63.8347C163.278 66.5247 166.868 72.1847 170.348 84.5547C173.828 96.9247 174.898 105.965 175.068 112.925C175.068 112.925 170.708 114.925 166.688 112.645C166.688 112.645 164.908 101.875 163.328 96.1447C161.748 90.4147 155.858 78.3447 155.858 78.3447C155.858 78.3447 150.228 69.9747 153.048 61.3647Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M150.231 68.6748L147.431 72.1548L148.831 76.1048L145.141 85.5648L149.591 76.1048L148.031 72.3748L150.231 68.6748Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M130.429 67.5547L131.039 71.1547L128.609 74.3147L131.989 84.9647L129.369 74.4347L131.619 71.4747L130.429 67.5547Z"
        fill="black"
      />
      <path
        d="M145.925 41.9432C146.716 37.0695 143.407 32.4774 138.533 31.6863C133.659 30.8951 129.067 34.2047 128.276 39.0783C127.485 43.952 130.795 48.5441 135.668 49.3353C140.542 50.1264 145.134 46.8168 145.925 41.9432Z"
        fill="#263238"
      />
      <path
        d="M152.53 46.8647C152.53 46.8647 151.08 53.6748 150.53 54.7948C150.141 55.4812 149.504 55.9928 148.75 56.2247L148.85 51.4348L152.53 46.8647Z"
        fill="#263238"
      />
      <path
        d="M150.708 37.1145C151.217 37.0576 151.732 37.1181 152.214 37.2915C152.696 37.4648 153.132 37.7464 153.488 38.1145C154.678 39.3345 154.018 43.1145 152.488 46.8845L149.758 47.1945L150.708 37.1145Z"
        fill="#263238"
      />
      <path
        d="M148.951 47.1849C149.501 47.5049 150.281 46.4549 150.951 45.7549C151.215 45.4448 151.554 45.2079 151.935 45.0669C152.317 44.9259 152.729 44.8856 153.131 44.9499C153.533 45.0142 153.911 45.181 154.23 45.434C154.549 45.6871 154.797 46.0181 154.951 46.3949C156.071 48.7549 153.951 51.8749 152.251 52.3949C149.251 53.3349 148.811 51.3949 148.811 51.3949L148.591 62.5049C146.521 65.1549 141.881 68.2749 139.381 67.8249C137.381 67.4749 136.951 64.1049 138.861 61.8949V58.6349C137.539 58.8453 136.198 58.9124 134.861 58.8349C132.661 58.4949 131.281 56.7649 130.611 54.3849C129.541 50.5649 129.131 47.4849 130.041 39.9649C131.041 31.7249 140.621 31.6449 145.791 34.8949C150.961 38.1449 148.951 47.1849 148.951 47.1849Z"
        fill="#FFA8A7"
      />
      <path
        d="M148.953 48.1049C149.233 48.1049 150.283 46.3749 150.953 45.7549C151.893 44.8949 150.673 37.1149 150.673 37.1149C150.999 36.4306 151.112 35.6638 150.996 34.9145C150.88 34.1653 150.541 33.4684 150.023 32.9149C148.733 31.4949 146.933 29.7549 141.023 30.4549C136.843 30.9449 132.293 32.2449 128.613 30.4549C128.272 31.6544 128.298 32.9282 128.686 34.1129C129.075 35.2977 129.808 36.3396 130.793 37.1049C132.3 37.853 133.943 38.2884 135.623 38.3849C138.854 38.6292 142.102 38.569 145.323 38.2049C146.323 38.0649 146.553 39.0449 147.103 41.7349C147.663 44.0849 148.063 48.0849 148.953 48.1049Z"
        fill="#263238"
      />
      <path
        d="M150.71 37.7248L154.02 35.9448C153.914 35.7218 153.764 35.5225 153.579 35.3591C153.393 35.1957 153.177 35.0714 152.942 34.9939C152.708 34.9163 152.46 34.8871 152.214 34.9079C151.968 34.9286 151.728 34.999 151.51 35.1148C151.071 35.3676 150.744 35.7776 150.596 36.2621C150.447 36.7466 150.488 37.2693 150.71 37.7248Z"
        fill="#263238"
      />
      <path
        d="M138.883 58.6348C141.143 58.2037 143.358 57.5678 145.503 56.7348C146.512 56.2085 147.338 55.3893 147.873 54.3848C147.615 55.3899 147.156 56.3323 146.523 57.1548C145.273 58.7548 138.883 59.9348 138.883 59.9348V58.6348Z"
        fill="#F28F8F"
      />
      <path
        d="M140.062 45.9546C140.062 46.2198 140.168 46.4741 140.355 46.6617C140.543 46.8492 140.797 46.9546 141.062 46.9546C141.328 46.9546 141.582 46.8492 141.77 46.6617C141.957 46.4741 142.062 46.2198 142.062 45.9546C142.062 45.6894 141.957 45.435 141.77 45.2475C141.582 45.0599 141.328 44.9546 141.062 44.9546C140.797 44.9546 140.543 45.0599 140.355 45.2475C140.168 45.435 140.062 45.6894 140.062 45.9546Z"
        fill="#263238"
      />
      <path
        d="M141.102 42.2449L143.202 43.4049C143.351 43.1154 143.383 42.7797 143.292 42.467C143.201 42.1543 142.993 41.8886 142.712 41.7249C142.572 41.6496 142.42 41.6034 142.262 41.5889C142.105 41.5744 141.946 41.592 141.795 41.6406C141.645 41.6892 141.506 41.7678 141.387 41.8717C141.267 41.9755 141.17 42.1025 141.102 42.2449Z"
        fill="#263238"
      />
      <path
        d="M139.318 52.7446L137.078 53.5846C137.125 53.7359 137.203 53.8761 137.306 53.9966C137.409 54.117 137.535 54.2153 137.677 54.2855C137.819 54.3556 137.974 54.3961 138.133 54.4046C138.291 54.413 138.449 54.3892 138.598 54.3346C138.9 54.2138 139.144 53.9809 139.278 53.6845C139.412 53.3881 139.427 53.0513 139.318 52.7446Z"
        fill="#F28F8F"
      />
      <path
        d="M131.289 43.0649L133.209 41.5849C133.118 41.4554 133.003 41.3456 132.869 41.2619C132.735 41.1783 132.585 41.1227 132.429 41.0984C132.273 41.0741 132.114 41.0817 131.961 41.1206C131.808 41.1595 131.664 41.229 131.539 41.3249C131.283 41.5282 131.114 41.8217 131.068 42.1452C131.021 42.4688 131.1 42.7978 131.289 43.0649Z"
        fill="#263238"
      />
      <path
        d="M131.883 45.6348C131.883 45.9 131.988 46.1543 132.176 46.3419C132.363 46.5294 132.618 46.6348 132.883 46.6348C133.148 46.6348 133.402 46.5294 133.59 46.3419C133.777 46.1543 133.883 45.9 133.883 45.6348C133.883 45.3695 133.777 45.1152 133.59 44.9277C133.402 44.7401 133.148 44.6348 132.883 44.6348C132.618 44.6348 132.363 44.7401 132.176 44.9277C131.988 45.1152 131.883 45.3695 131.883 45.6348Z"
        fill="#263238"
      />
      <path
        d="M136.894 44.7349L136.574 50.6549L133.484 49.5949L136.894 44.7349Z"
        fill="#F28F8F"
      />
      <path
        d="M135.82 67.1846C136.42 68.6246 137.54 69.1846 139.55 69.4346C140.567 69.4972 141.587 69.3522 142.546 69.0084C143.505 68.6645 144.385 68.1291 145.13 67.4346L142.63 64.9445L139.44 67.3345L139.17 64.6646L135.82 67.1846Z"
        fill="#2091EA"
      />
      <path
        opacity="0.1"
        d="M135.82 67.1846C136.42 68.6246 137.54 69.1846 139.55 69.4346C140.567 69.4972 141.587 69.3522 142.546 69.0084C143.505 68.6645 144.385 68.1291 145.13 67.4346L142.63 64.9445L139.44 67.3345L139.17 64.6646L135.82 67.1846Z"
        fill="black"
      />
      <path
        d="M137.401 66.4048C137.106 67.269 136.993 68.1849 137.071 69.0948C137.166 69.9051 137.477 70.6751 137.971 71.3248C138.288 71.529 138.648 71.6569 139.022 71.6985C139.397 71.7402 139.776 71.6944 140.131 71.5648C140.778 71.0089 141.298 70.3202 141.655 69.5454C142.012 68.7706 142.198 67.9279 142.201 67.0748L139.441 67.3848L137.401 66.4048Z"
        fill="#2091EA"
      />
      <path
        d="M137.953 71.3247C137.953 71.3247 134.683 89.3247 134.553 90.8047C134.423 92.2847 136.423 97.4247 137.673 97.4647C138.923 97.5047 141.363 92.8347 141.393 91.4647C141.423 90.0947 140.113 71.5147 140.113 71.5147L137.953 71.3247Z"
        fill="#2091EA"
      />
      <path
        opacity="0.1"
        d="M140.129 71.5647C139.78 71.7191 139.396 71.7783 139.016 71.7361C138.637 71.694 138.275 71.552 137.969 71.3247V71.6147C138.281 71.8637 138.648 72.0337 139.04 72.1103C139.432 72.1869 139.836 72.1678 140.219 72.0547L140.129 71.5647Z"
        fill="black"
      />
      <path
        d="M139.438 67.3847L148.638 60.3847V58.3047C148.638 58.3047 150.778 59.1147 151.258 62.8847C149.782 66.1541 147.259 68.8388 144.088 70.5147L142.158 67.0747L139.438 67.3847Z"
        fill="#E0E0E0"
      />
      <path
        d="M138.879 59.2549C137.677 59.4449 136.598 60.1027 135.879 61.0849C134.959 62.5949 135.169 68.4049 135.169 68.4049L137.369 66.4049L139.369 67.4049C139.179 65.4749 138.799 61.9549 138.799 61.9549L138.879 59.2549Z"
        fill="#E0E0E0"
      />
      <path
        d="M102.953 122.905C103.812 121.698 104.315 120.273 104.403 118.795C104.663 116.905 109.843 114.875 110.013 116.285C110.183 117.695 110.653 118.865 109.013 120.575C107.373 122.285 105.363 124.175 102.953 122.905Z"
        fill="#FFA8A7"
      />
      <path
        d="M103.223 110.695C101.983 112.295 101.223 115.405 100.563 116.455C99.903 117.505 99.123 118.455 100.563 118.605C102.003 118.755 104.093 115.995 104.873 114.295C105.175 113.623 105.635 113.033 106.213 112.575C106.44 112.393 106.693 112.244 106.963 112.135C106.293 111.905 106.643 109.205 106.173 108.835C106.173 108.835 103.733 109.835 103.223 110.695Z"
        fill="#FFA8A7"
      />
    </g>
  </svg>
);

export default ListEmpty;
