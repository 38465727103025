import React from 'react';

import request from 'axios';
import { baseURL } from 'core/constants';
import Grid from 'components/lib/Grid';
import Button from 'components/lib/Button';
import Table from 'components/lib/Table';

const redeVistoriasUrl = REDE_VISTORIAS_URL;
const redeVistoriasWebhook = WEBHOOK_VISTORIAS_URL;

export class CreateSurveyProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.process.redeVistoria.length == 0) {
      this.redeVistorias();
    }
  }

  redeVistorias() {
    const { company, process, onSuccess } = this.props;
    const property = this.props.process.proposal.properties[0];
    // const  clients  = this.props.process.proposal.clients;
    const property_owners = this.props.process.proposal.property_owners;
    const clients = this.props.process.proposal.clients;
    const guarantors = this.props.process.proposal.guarantors;

    !(function (r, v, s, d, k, i, n) {
      if (r.rv) return;
      k = r.rv = function () {
        k.queue.push(arguments);
      };
      k.queue = [];
      i = v.createElement(s);
      i.async = !0;
      i.src = d;
      n = v.getElementsByTagName(s)[0];
      n.parentNode.insertBefore(i, n);
    })(window, document, 'script', redeVistoriasUrl);

    // Identificação do parceiro
    rv('partner', 'Izee');

    // CPF/CNPJ utilizado para identificar clientes que já possuem integração
    if(company.cnpj){
      rv('document', company.cnpj);
    }
    if(company.cpf){
      rv('document', company.cpf);
    }

    // Tipo de vistoria solicitada
    rv('orderType', 'entrada');

    // Dados do Imovel
    rv('property', {
      building_id: property.code, //identificacao
      furnished: 'unfurnished', //mobiliada
      building_type: property.type ? property.type.name : '', //tipo
      purpose: 'Residencial', //proposito
      area: property.area,
      address: {
        neighborhood: property.address.neighborhood,
        state: property.address.state,
        city: property.address.city,
        zipcode: property.address.zip_code,
        street: property.address.address,
        number: property.address.number,
        complement: property.address.additional_address,
      },
    });

    // assinaturas
    const administradora = [
      {
        name: company.name,
        signature: true,
      },
    ];

    const peopleOwners = [];
    if (property_owners.length > 0) {
      property_owners.forEach(function (valor, chave) {
        peopleOwners.push({ name: valor.name, signature: true });
      });
    } else {
      peopleOwners.push({ name: '', signature: false });
    }

    const peopleClients = [];
    if (clients.length > 0) {
      clients.forEach(function (valor, chave) {
        peopleClients.push({ name: valor.name, signature: true });
      });
    } else {
      peopleClients.push({ name: '', signature: false });
    }

    const peopleGuarantors = [];
    if (guarantors.length > 0) {
      guarantors.forEach(function (valor, chave) {
        peopleGuarantors.push({ name: valor.name, signature: true });
      });
    } else {
      peopleGuarantors.push({ name: '', signature: false });
    }

    rv('people', [
      {
        id: 'propertyManager',
        title: 'Administradora',
        content: administradora,
      },
      {
        id: 'owner',
        title: 'Locador',
        content: peopleOwners,
      },
      {
        id: 'tenant',
        title: 'Cliente',
        content: peopleClients,
      },
      {
        id: 'guarantor',
        title: 'Fiador',
        content: peopleGuarantors,
      },
    ]);

    // webhook
    rv('webhook', redeVistoriasWebhook);

    // Evento com o retorno da solicitação (ver documentação).
    rv('on', 'order.created', function (code, price) {
      const data = {
        code: code,
        price: price,
        status: 'Aguardando',
        property_id: property.id,
        process_id: process.id,
      };
      request
        .post(`${baseURL}company/${company.id}/rede-vistoria`, data)
        .then((response) => {
          return onSuccess({ isSuccess: true });
        })
        .catch((error) => {
          return onSuccess({ isSuccess: false });
        });
    });

    // ID elemento html onde o formulario deve ser montado.
    rv('init', 'sdk-rv');
  }

  render() {
    return (
      <React.Fragment>
        {this.props.process.redeVistoria.length > 0 ? (
          <Grid>
            <Grid.Col>
              <Table
                items={this.props.process.redeVistoria}
                cells={[
                  { name: 'Código', path: 'code' },
                  { name: 'Status', path: 'status' },
                  {
                    name: 'Id. do Imóvel',
                    path: 'property',
                    render: (content) => <span>{content.code}</span>,
                  },
                  { name: 'Créditos', path: 'price' },
                  {
                    name: 'Relatório',
                    path: 'report',
                    render: (content) =>
                      content ? (
                        <a className="link-icon" href={content} target="_blank">
                          <p className="si-item-icon text-center">
                            <i className="fas fa-clipboard-list"></i>
                          </p>
                        </a>
                      ) : null,
                  },
                  { name: 'Observações', path: 'comments' },
                  {
                    name: 'Painel RV',
                    path: 'hotsite',
                    render: (content) =>
                      content ? (
                        <a href={content} target="_blank">
                          <p className="si-item-icon text-center">
                            <img src="media/icone-rede-vistorias.png" />
                          </p>
                        </a>
                      ) : null,
                  },
                ]}
              />
              <div className="mt-4 text-right">
                <Button onClick={this.redeVistorias.bind(this)} isFlat hasThemeColor>
                  Nova Vistoria
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        ) : null}
        <Grid>
          <Grid.Col>
            <div id="sdk-rv"></div>
          </Grid.Col>
        </Grid>
        {redeVistoriasUrl === '' ? (
          <Grid>
            <Grid.Col>
              <div className="text-center">Rede Vistorias em breve.</div>
            </Grid.Col>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

export default CreateSurveyProcess;
