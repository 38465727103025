import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'
import moment from 'moment'
import request from 'axios'

import { DOCUMENT_LIST_ID } from '../../../../../izee-app/src/constants/documentList'
import { baseURL } from 'core/constants'

import { ga } from '../../../../../izee-app/src/services/firebase'

import { cnpjValidation } from 'utils/cnpjValidation'
import { goodObject, rawNumber } from 'utils/utils'
import { cpfValidation } from 'utils/cpfValidation'

import CreateProperties from 'features/CreateProperties'
import FullTradingAddress from 'features/components/fieldsGroup/FullTradingAddress'
import PersonalDataClient from 'features/components/fieldsGroup/PersonalDataClient'
import PersonalDataSpouse from 'features/components/fieldsGroup/PersonalDataSpouse'
import FullAddress from 'features/components/fieldsGroup/FullAddress'

import ConfirmationModal from 'components/lib/Modal'
import Modal from 'components/lib/RegisterFormModal'

import ConfirmationMessage from 'components/ConfirmationMessage'
import DocumentsRequest from 'components/DocumentsRequest/DocumentsRequest'

class CreatePropertyOwner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createdPropertyOwnerId: '',
      entityName: 'propertyOwner',
      addDocs: props.documentList.map((doc) => ({ name: doc })),
      success: '',
      message: '',
      formIsWorking: false,
      smallModalIsOpen: false,
      confirmationModal: false,
      selected: 0,
      formIsInvalid: false,
      fieldErrors: {},
      fields: {
        name: '',
        email: '',
        phone: '',
        cpf: '',
        identity_type: '',
        identity: '',
        identity_issuing_body: '',
        identity_expedition_date: '',
        birth_date: '',
        nationality: '',
        marital_status: '',
        marital_status_id: '',
        occupation: '',
        income: '0,00',
        income_source_id: '',
        is_student: '',
        zip_code: '',
        address: '',
        number: '',
        additional_address: '',
        state: '',
        city: '',
        neighborhood: '',
        spouse_name: '',
        spouse_email: '',
        spouse_phone: '',
        spouse_cpf: '',
        spouse_identity_type: '',
        spouse_identity: '',
        spouse_identity_issuing_body: '',
        spouse_identity_expedition_date: '',
        spouse_birth_date: '',
        spouse_nationality: '',
        spouse_occupation: '',
        spouse_status: '',
        estimated_rent_value: 1,
        spouse_income: '0,00',
        spouse_income_source_id: '',
        payee: '',
        bank: '',
        agency: '',
        account: '',
        cnpj: '',
        corporate_name: '',
        trading_name: '',
        municipal_enrollment: '',
        state_enrollment: '',
        typeJuridical: false,
        trading_address_zip_code: '',
        trading_address_address: '',
        trading_address_number: '',
        trading_address_additional_address: '',
        trading_address_state: '',
        trading_address_city: '',
        trading_address_neighborhood: '',
      },
      defaultValue: {
        name: '',
        email: '',
        phone: '',
        cpf: '',
        identity_type: '',
        identity: '',
        identity_issuing_body: '',
        identity_expedition_date: '',
        birth_date: '',
        nationality: '',
        marital_status: '',
        marital_status_id: '',
        occupation: '',
        income: '0,00',
        income_source_id: '',
        is_student: '',
        zip_code: '',
        address: '',
        number: '',
        additional_address: '',
        state: '',
        city: '',
        neighborhood: '',
        spouse_name: '',
        spouse_email: '',
        spouse_phone: '',
        spouse_cpf: '',
        spouse_identity_type: '',
        spouse_identity: '',
        spouse_identity_issuing_body: '',
        spouse_identity_expedition_date: '',
        spouse_birth_date: '',
        spouse_nationality: '',
        spouse_occupation: '',
        spouse_status: '',
        estimated_rent_value: 1,
        spouse_income: '0,00',
        spouse_income_source_id: '',
        payee: '',
        bank: '',
        agency: '',
        account: '',
        cnpj: '',
        corporate_name: '',
        trading_name: '',
        municipal_enrollment: '',
        state_enrollment: '',
        typeJuridical: false,
        trading_address_zip_code: '',
        trading_address_address: '',
        trading_address_number: '',
        trading_address_additional_address: '',
        trading_address_state: '',
        trading_address_city: '',
        trading_address_neighborhood: '',
      },
    }
  }

  handleChange(name, value) {
    const { defaultValue } = this.state
    const updatedFields = { ...defaultValue }

    if (name === 'typeJuridical') {
      updatedFields['typeJuridical'] = value
      if (value == false) {
        updatedFields['cnpj'] = ''
        updatedFields['corporate_name'] = ''
        updatedFields['trading_name'] = ''
        updatedFields['municipal_enrollment'] = ''
        updatedFields['state_enrollment'] = ''
        updatedFields['trading_address_zip_code'] = ''
        updatedFields['trading_address_neighborhood'] = ''
        updatedFields['trading_address_city'] = ''
        updatedFields['trading_address_address'] = ''
        updatedFields['trading_address_state'] = ''
        updatedFields['trading_address_number'] = ''
        updatedFields['trading_address_additional_address'] = ''
      }

      return this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
    }

    if (name === 'fullAddress') {
      updatedFields['zip_code'] = value['cep'] || ''
      updatedFields['neighborhood'] = value['bairro'] || ''
      updatedFields['city'] = value['localidade'] || ''
      updatedFields['address'] = value['logradouro'] || ''
      updatedFields['state'] = value['uf'] || ''

      return this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
    }

    if (name === 'trading_address') {
      updatedFields['trading_address_zip_code'] = value['cep'] || ''
      updatedFields['trading_address_neighborhood'] = value['bairro'] || ''
      updatedFields['trading_address_city'] = value['localidade'] || ''
      updatedFields['trading_address_address'] = value['logradouro'] || ''
      updatedFields['trading_address_state'] = value['uf'] || ''

      return this.setState({
        defaultValue: updatedFields,
        fields: updatedFields,
      })
    }

    updatedFields[name] = value

    return this.setState({
      fields: updatedFields,
      defaultValue: updatedFields,
    })
  }

  async forward() {
    const isValid = this.validatePersonalData()

    let { selected } = this.state

    if (!isValid) return

    if (selected === 3) {
      await this.createPropertyOwner()
      this.setState({ selected: ++selected })

      return
    }

    if (selected === 4) {
      this.close()

      return
    }

    this.setState({ selected: ++selected })
  }

  back() {
    let { selected } = this.state

    if (selected === 0) return null

    this.setState({ selected: --selected })
  }

  close() {
    this.props.closeCreatePropertyOwner()
  }

  callBack(data) {
    let section = --data
    const { selected } = this.state
    const isValid = this.validatePersonalData()

    if (!isValid) {
      this.setState({ formIsInvalid: selected })
    } else {
      this.setState({
        selected: section,
        formIsInvalid: false,
      })
    }
  }

  validatePersonalData() {
    const {
      name,
      email,
      phone,
      cpf,
      identity,
      identity_type,
      spouse_email,
      spouse_name,
      spouse_cpf,
      spouse_status,
      cnpj,
      corporate_name,
      typeJuridical,
      marital_status_id,
    } = this.state.fields

    const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/)

    const fieldErrors = {}

    let isValid = true

    if (typeJuridical) {
      if (cnpj && cnpj !== '') {
        const cnpjIsValid = cnpjValidation(cnpj)

        if (!cnpjIsValid) {
          fieldErrors['cnpj'] = ['CNPJ inválido', true]
          isValid = false
        } else {
          delete fieldErrors['cnpj']
        }
      } else {
        fieldErrors['cnpj'] = ['Campo CNPJ é obrigatório', true]
        isValid = false
      }
      if (!corporate_name || corporate_name === '') {
        fieldErrors['corporate_name'] = ['Campo Razão Social é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['corporate_name']
      }
    }

    if (!name || name === '') {
      fieldErrors['name'] = ['Campo Nome é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['name']
    }

    if (!emailRegex.test(email)) {
      fieldErrors['email'] = ['Email inválido', true]
      isValid = false
    } else {
      delete fieldErrors['email']
    }

    if (phone && phone !== '') {
      const plainPhone = phone.replace(/\D/g, '')
      const phoneLength = plainPhone.length

      if (phoneLength < 10) {
        fieldErrors['phone'] = ['Telefone inválido', true]
        isValid = false
      } else {
        delete fieldErrors['phone']
      }
    } else {
      fieldErrors['phone'] = ['Campo Telefone é obrigatório', true]
      isValid = false
    }

    if (!marital_status_id || marital_status_id === '') {
      fieldErrors['marital_status_id'] = ['Campo Estado civil é obrigatório', true]
      isValid = false
    } else {
      delete fieldErrors['marital_status_id']
    }

    if (cpf && cpf !== '') {
      const plainCPF = cpf.replace(/\D/g, '')
      const cpfIsValid = cpfValidation(plainCPF)

      if (!cpfIsValid) {
        fieldErrors['cpf'] = ['CPF inválido', true]
        isValid = false
      } else {
        delete fieldErrors['cpf']
      }
    } else {
      fieldErrors['cpf'] = ['Campo CPF é obrigatório', true]
      isValid = false
    }

    if (identity_type) {
      if (!identity) {
        fieldErrors['identity'] = ['Número não pode estar vazio', true]
        isValid = false
      } else {
        delete fieldErrors['identity']
      }
    } else {
      delete fieldErrors['identity']
    }

    // Verifica cônjuge
    if (spouse_status && this.state.selected === 2) {
      if (spouse_name === '' || !spouse_name) {
        fieldErrors['spouse_name'] = ['Nome não pode estar vazio', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_name']
      }

      if (!emailRegex.test(spouse_email) || !spouse_email) {
        fieldErrors['spouse_email'] = ['Email inválido', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_email']
      }

      if (spouse_cpf === '' || !spouse_cpf) {
        fieldErrors['spouse_cpf'] = ['Campo CPF é obrigatório', true]
        isValid = false
      } else {
        delete fieldErrors['spouse_cpf']
      }
    } else {
      delete fieldErrors['spouse_name']
      delete fieldErrors['spouse_email']
      delete fieldErrors['spouse_cpf']
    }

    this.setState({ fieldErrors })

    return isValid
  }

  requestRegistration() {
    this.setState({ smallModalIsOpen: true })
  }

  requestRegistrationClose() {
    this.setState({ confirmationModal: false, smallModalIsOpen: false })
  }

  async createPropertyOwner() {
    const requestFields = { ...this.state.fields }
    const { companyId } = this.props

    const address = {
      additional_address: requestFields['additional_address'],
      address: requestFields['address'],
      city: requestFields['city'],
      neighborhood: requestFields['neighborhood'],
      number: requestFields['number'],
      state: requestFields['state'],
      zip_code: requestFields['zip_code'],
    }

    delete requestFields.additional_address
    delete requestFields.city
    delete requestFields.neighborhood
    delete requestFields.number
    delete requestFields.state
    delete requestFields.zip_code

    requestFields['address'] = address

    const trading_address = {
      additional_address: requestFields['trading_address_additional_address'],
      address: requestFields['trading_address_address'],
      city: requestFields['trading_address_city'],
      neighborhood: requestFields['trading_address_neighborhood'],
      number: requestFields['trading_address_number'],
      state: requestFields['trading_address_state'],
      zip_code: requestFields['trading_address_zip_code'],
    }

    delete requestFields.trading_address_additional_address
    delete requestFields.trading_address_address
    delete requestFields.trading_address_city
    delete requestFields.trading_address_neighborhood
    delete requestFields.trading_address_number
    delete requestFields.trading_address_state
    delete requestFields.trading_address_zip_code

    requestFields['trading_address'] = trading_address

    let goodFields = goodObject(requestFields, {
      birth_date: {
        path: 'birth_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      spouse_birth_date: {
        path: 'spouse_birth_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      identity_expedition_date: {
        path: 'identity_expedition_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      spouse_identity_expedition_date: {
        path: 'spouse_identity_expedition_date',
        format: (value) => {
          const isFormatted = moment(value, 'YYYY-MM-DD', true).isValid()

          if (isFormatted) return value

          return moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
      },
      income: {
        path: 'income',
        format: (value) => rawNumber(value),
      },
      spouse_income: {
        path: 'spouse_income',
        format: (value) => rawNumber(value),
      },
      estimated_rent_value: {
        path: 'estimated_rent_value',
        format: (value) => rawNumber(value),
      },
      marital_status_id: {
        path: 'marital_status_id',
        format: (value) => parseInt(value),
      },
    })

    const url = `${baseURL}company/${companyId}/property-owner`

    try {
      const response = await request.post(url, goodFields)

      this.setState(
        {
          formIsWorking: false,
          confirmationModal: true,
          success: true,
          message: 'Proprietário(a) adicionado com sucesso!',
          createdPropertyOwnerId: response.data.id,
        },
        () => {
          const gaPayload = {
            company_id: companyId,
            company_name: this.props.companyInfo.companyName,
          }

          ga.logEvent('create_property_owner', gaPayload)

          this.handleNewDocs()
        },
      )

      setTimeout(() => {
        this.setState({ confirmationModal: false })
      }, 3000)
    } catch (error) {
      const { errors } = error

      const message = errors[0].message
        ? errors[0].message
        : 'Ocorreu um erro inesperado'

      this.setState({
        formIsWorking: false,
        confirmationModal: true,
        success: false,
        message,
      })
    }
  }

  async handleNewDocs() {
    const { addDocs } = this.state

    if (!addDocs?.length) return

    const docsUrl = `${baseURL}entity/3/upload/file`

    for (let i in addDocs) {
      const data = new FormData()

      data.append('name', addDocs[i].name)
      data.append('owner_document', this.state.createdPropertyOwnerId)
      data.append('entity_id', '3')
      data.append('status', 'STATUS_WAITING')

      await request.post(docsUrl, data)
    }
  }

  render() {
    const formConfig = {
      selected: this.state.selected,
      sections: [
        {
          formTitle: 'Adicionar proprietário(a)',
          buttonTitle: 'Avançar',
          progressBar: true,
          sectionNumber: '1',
          sectionTitle: 'Dados do(a) proprietário(a)',
          progressTitle: 'Dados',
        },
        {
          formTitle: 'Adicionar proprietário(a)',
          buttonTitle: 'Avançar',
          progressBar: true,
          sectionNumber: '2',
          sectionTitle: 'Endereço do(a) proprietário(a)',
          progressTitle: 'Endereço',
        },
        {
          formTitle: 'Adicionar proprietário(a)',
          buttonTitle: 'Avançar',
          progressBar: true,
          sectionNumber: '3',
          sectionTitle: 'Dados do(a) Cônjuge',
          progressTitle: 'Cônjuge',
        },
        {
          formTitle: 'Adicionar proprietário(a)',
          buttonTitle: 'Avançar',
          progressBar: true,
          sectionNumber: '4',
          sectionTitle: 'Documentos',
          progressTitle: 'Documentação',
        },
        {
          formTitle: 'Adicionar proprietário(a)',
          buttonTitle: 'Concluir',
          progressBar: true,
          sectionNumber: '5',
          sectionTitle: 'Carteira de Imóveis',
          progressTitle: 'Imóveis',
        },
      ],
    }

    const { selected } = formConfig

    const { typeJuridical } = this.state.fields

    const hideForwardButton = this.state.selected === 3 || this.state.selected === 4
    const hideBackButton = this.state.selected === 0 || this.state.selected === 4

    return (
      <Fragment>
        <Modal
          disableClose
          noTitleMobile
          isOpen
          isApp
          hideForwardButton={hideForwardButton}
          hideBackButton={hideBackButton}
          forward={this.forward.bind(this)}
          back={this.back.bind(this)}
          close={this.close.bind(this)}
          push={this.props.push}
          activeUser={this.props.activeUser}
          activeCompany={this.props.activeCompany}
          formConfig={formConfig}
          isInvalid={this.state.formIsInvalid}
          callBack={this.callBack.bind(this)}
          isLoading={this.state.formIsWorking}
          companyInfo={this.props.companyInfo}
        >
          {selected === 0 && (
            <PersonalDataClient
              {...this.state}
              handleChange={this.handleChange.bind(this)}
              showEstimatedRentValue={true}
              multiStepIndex={selected}
              isMultiStep
              isPropertyOwnerForm
            />
          )}

          {selected === 1 && (
            <div>
              {typeJuridical && (
                <FullTradingAddress
                  {...this.state}
                  handleChange={this.handleChange.bind(this)}
                />
              )}

              <FullAddress
                {...this.state}
                handleChange={this.handleChange.bind(this)}
                multiStepIndex={selected}
                isMultiStep
              />
            </div>
          )}

          {selected === 2 && (
            <PersonalDataSpouse
              {...this.state}
              handleChange={this.handleChange.bind(this)}
              multiStepIndex={selected}
              isMultiStep
            />
          )}

          {selected === 3 && (
            <div className='docsInput'>
              <DocumentsRequest
                title='Solicitar documentos'
                defaultMultiOption={this.props.documentList}
                onChange={(docs) => this.setState({ addDocs: docs })}
              />
            </div>
          )}

          {selected === 4 && (
            <CreateProperties
              ownerId={this.state.createdPropertyOwnerId}
              companyId={this.props.activeCompany}
              registeredProperties={[]}
            />
          )}
        </Modal>

        <ConfirmationModal
          disableClose
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          backgroundContentStyle={{ display: 'block' }}
          isOpen={this.state.confirmationModal}
          onClose={this.requestRegistrationClose.bind(this)}
        >
          <ConfirmationMessage
            success={this.state.success}
            message={this.state.message}
          />
        </ConfirmationModal>
      </Fragment>
    )
  }
}

export default connect((state) => {
  const documentList = state.app.companyMeta?.documentLists?.find(
    (documentList) => documentList.id === DOCUMENT_LIST_ID.CREATE_OWNER,
  )?.list

  return { documentList: documentList || [] }
}, {})(CreatePropertyOwner)
