import React from 'react';

import * as commons from 'utils/UtilityStore/actions';

import UpdateCompany from 'features/UpdateCompany';

import ImageUpload from 'components/ImageUpload/ImageUpload';
import BodyContainer from 'components/lib/BodyContainer';
import SectionWrapper from 'components/SectionWrapper';
import ColorSelector from 'components/ColorSelector';
import { ROLE } from 'components/constants';
import withRedux from 'components/redux';
import Grid from 'components/lib/Grid';

import * as actions from '../../actions';

import ShowTerms from './components/ShowTerms';

export class General extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      themeColor: this.props.companyMeta.themeColor || '#2670FF',
      logo: this.props.companyMeta.logo || [],
    };
  }

  render() {
    // Format company address to set as default value on the forms
    let { company, forbiddenAccess, activeUser, canCustomizeApp } = this.props;

    if (forbiddenAccess([ROLE.PROFILE_MANAGER])) {
      return null;
    } else if (company.address) {
      company = { ...company.address, ...company };
      company.address = company.address.address;
      company.number = parseInt(company.number);
    }

    return (
      <BodyContainer getRef={(ref) => (this.body = ref)}>
        <SectionWrapper header="Dados cadastrais">
          <UpdateCompany
            activeUser={activeUser}
            defaultValue={company}
            disabledAutoFocus
            onSuccess={this.handleUpdateData.bind(this)}
          />
        </SectionWrapper>

        <SectionWrapper
          header={`Customização${canCustomizeApp ? '' : ' (APENAS PARA LINKS)'}`}
        >
          <Grid>
            <Grid.Col>
              <ColorSelector
                isLoading
                defaultColor={this.state.themeColor}
                onClick={() => this.handleColorSave()}
                onChange={this.handleColorChange.bind(this)}
              />
            </Grid.Col>
            <Grid.Col>
              <ImageUpload
                uploadingLogo={this.props.isWorking['uploadingLogo']}
                defaultImages={this.state.logo}
                onChange={this.handleLogoChange.bind(this)}
              />
            </Grid.Col>
          </Grid>
        </SectionWrapper>

        <SectionWrapper header="Termos de uso e política de privacidade">
          <ShowTerms />
        </SectionWrapper>
      </BodyContainer>
    );
  }

  handleColorChange(color) {
    this.setState({ themeColor: color.hex });
  }

  handleColorSave() {
    this.props.updateCompanyMeta('themeColor', this.state.themeColor);
  }

  handleLogoChange(files, pictures) {
    if (files.length > 0) {
      this.props.updateCompanyMeta('logo', files[0]);
    }
    this.setState({ logo: pictures[0] });
  }

  handleUpdateData(response) {
    if (response.isSuccess) {
      this.props.updateActiveCompany(response.data);
      this.props.notification({
        message: 'Dados da empresa atualizados com sucesso',
      });
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao atualizar os dados.',
        status: 'danger',
      });
    }
  }
}

export default withRedux(
  (state) => {
    const subscriptionPlan = state.app.activeCompany?.subscription_plan;
    const canCustomizeApp = subscriptionPlan ? subscriptionPlan.can_customize_app : true;

    return {
      companyMeta: state.app.companyMeta,
      updateActiveCompany: state.app.helpers.updateActiveCompany,
      updateCompanyMeta: state.app.helpers.updateCompanyMeta,
      isWorking: state.commons.isWorking,
      canCustomizeApp,
    }
  },
  { ...actions, ...commons }
)(General);
