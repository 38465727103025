import React from "react";

import CreateGuarantor from "./CreateGuarantor";
import UpdateGuarantor from "./UpdateGuarantor";

const CreateOrUpdateGuarantor = props =>
  props.isUpdate ? (
    <UpdateGuarantor {...props}></UpdateGuarantor>
  ) : (
    <CreateGuarantor {...props}></CreateGuarantor>
  );

export default CreateOrUpdateGuarantor;
