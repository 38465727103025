import { request } from 'core/constants';
import JsonParse from 'services/JsonParse';
import Constants from 'services/Constants';
import { promiseWrapper } from 'utils/utils';

export const fetchAll = (query) => (dispatch) => {
  const companyId = Constants.get('companyId');

  if (!companyId) {
    dispatch({
      type: 'FETCH_PROCESS_ERROR',
      payload: error.response,
    });
  }

  dispatch({ type: 'IS_FETCHING_PROCESS' });

  promiseWrapper((resolve, reject, delay) => {
    request
      .get(`/company/${companyId}/list-process`)
      .then((response) => {
        delay(() => {
          dispatch({
            type: 'FETCH_PROCESS',
            payload: response.data.data,
          });
          resolve();
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_PROCESS_ERROR',
          payload: error.response,
        });
        reject();
      });
  });
};
