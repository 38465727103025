import React from 'react';

const UserName = (props) => (
  <div>
    {props.content.name ? (
      <React.Fragment>
        <strong>{props.content.name}</strong>
        <br />
      </React.Fragment>
    ) : null}
    <span className="text has-text-grey">{props.content.email}</span>
  </div>
);

UserName.defaultProps = {
  content: {},
};

export default UserName;
