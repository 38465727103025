import React from 'react';

import { connect } from 'react-redux';
import * as actions from '../actions';
import * as commons from 'utils/UtilityStore/actions';
import { baseURL } from 'core/constants';
import { request } from 'core/constants';

// Components
import { FormFieldSwitch, FormFieldText } from 'components/lib/FormFields';
import SectionWrapper from 'components/SectionWrapper';
import Table from 'components/lib/Table';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import FlexBar from 'components/lib/FlexBar';
import Grid from 'components/lib/Grid';
import BodyContainer from 'components/lib/BodyContainer';
import { copyToClipboard } from 'utils/utils';

// Images
import logoClicksign from 'static/media/logo-clicksign-small.svg';
import logoVista from 'static/media/logo-vista-small.svg';
import AutentiqueIntegration from './AutentiqueIntegration';
import VistaIntegration from './VistaIntegration';
import SuperlogicaIntegration from './SuperlogicaIntegration';
import FichacertaIntegration from './FichacertaIntegration';
import integrations from '../../../services/integrations';

import ModalPlanRestriction from 'components/ModalPlanRestriction';

class Integrations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      urlApiDocs: '',
      integrationsIsLoaded: false,
      superlogicaParams: {},
      fichacertaParams: {},
    };
  }

  componentDidMount() {
    integrations.list({ companyId: this.props.company.id }).then((response) => {
      if (response.success) {
        this.setState({
          superlogicaParams: response.data.filter(
            (e) => e.name == 'superlogica'
          )?.[0]?.params,
          fichacertaParams: {
            enabled: response.data.filter((e) => e.name == 'fichacerta')?.[0]
              ?.enabled,
            creditAnalysisCost: response.data.filter(
              (e) => e.name == 'fichacerta'
            )?.[0]?.creditAnalysis?.creditAnalysisCost,
            creditAnalysisTickets: response.data.filter(
              (e) => e.name == 'fichacerta'
            )?.[0]?.creditAnalysis?.creditAnalysisTickets,
          },
        });
      }
      this.setState({ integrationsIsLoaded: true });
    });
    this.getUrlApiDocs();
  }

  getUrlApiDocs() {
    request
      .get(`${baseURL}izee-setting?filters[code]=SETTING_IZEE_PUBLIC_API_URL`)
      .then((response) => {
        this.setState({ urlApiDocs: response.data[0]?.value });
      })
      .catch((error) => {});
  }

  render() {
    // Format company address to set as default value on the forms
    let { activeUser } = this.props;
    let company = this.props.company;
    if (company.address) {
      company = { ...company.address, ...company };
      company.address = company.address.address;
      company.number = parseInt(company.number);
    }

    const token = company.public_token;

    const handleSettingUpdate = (name) => {
      const value = !company.settings[name];
      this.props.updateSettings(name, value);
    };

    const subscriptionPlan = this.props.company?.subscription_plan

    return (
      <BodyContainer getRef={(ref) => (this.body = ref)}>

        {(subscriptionPlan && !subscriptionPlan?.can_access_integrations) && (
          <ModalPlanRestriction></ModalPlanRestriction>
        )}

        <SectionWrapper header="Integrações">
          {/* <Grid>
            <Grid.Col>
              <div className="card-select">
                <header className="card-select-header">
                  <div className="media">
                    <div className="media-content">
                      <p className="text has-text-weight-bold">Painel CONFIAX</p>
                      <p className="text is-small">Habilitar o Painel CONFIAX para a imobiliária.</p>
                    </div>
                  </div>
                </header>
                <footer className="card-select-footer" style={{ textAlign: 'left', zIndex: 9999 }}>
                  <FormFieldSwitch
                    name={'rede_confiax_enabled'}
                    description=""

                    defaultChecked={company.settings['rede_confiax_enabled']}
                    onClick={() => handleSettingUpdate('rede_confiax_enabled')}

                    // isLoading={this.props.isWorking['enables_fichacerta']}
                    // isDisabled={this.props.isWorking['enables_fichacerta']}
                  />
                </footer>
              </div>
            </Grid.Col>
          </Grid> */}

          <Grid>
            <Grid.Col>
              <div className="card-select">
                <header className="card-select-header">
                  <div className="media">
                    <div className="media-content">
                      <p className="text has-text-weight-bold">Autentique</p>
                      <p className="text is-small">
                        Assinatura eletrônica de documentos
                      </p>
                    </div>
                  </div>
                </header>
                <footer
                  className="card-select-footer"
                  style={{ textAlign: 'left', zIndex: 9999 }}
                >
                  <AutentiqueIntegration
                    onSuccess={this.autentiqueIntegrationSuccess.bind(this)}
                    defaultValue={company.settings}
                  />
                </footer>
              </div>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              <div className="card-select">
                <header className="card-select-header">
                  <div className="media">
                    <div className="media-content">
                      <p className="text has-text-weight-bold">Vista</p>
                      <p className="text is-small">
                        Solicite os dados abaixo através de um ticket no Vista com
                        permissões para imóveis, clientes e proprietários.
                      </p>
                    </div>
                  </div>
                </header>
                <footer
                  className="card-select-footer"
                  style={{ textAlign: 'left', zIndex: 9999 }}
                >
                  <VistaIntegration
                    onSuccess={this.autentiqueIntegrationSuccess.bind(this)}
                    defaultValue={company.settings}
                  />
                </footer>
              </div>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              <div className="card-select">
                <header className="card-select-header">
                  <div className="media">
                    <div className="media-content">
                      <p className="text has-text-weight-bold">Superlogica</p>
                      <p className="text is-small">
                        Para integrar com a Superlógica, por favor visite:{' '}
                        <a
                          href="https://assinaturas.superlogica.com/hc/pt-br/articles/360004013613-Criar-app-e-access-token"
                          target="_blank"
                        >
                          Como gerar suas chaves.
                        </a>
                      </p>
                    </div>
                  </div>
                </header>
                <footer
                  className="card-select-footer"
                  style={{ textAlign: 'left', zIndex: 9999 }}
                >
                  {this.state.integrationsIsLoaded && (
                    <SuperlogicaIntegration
                      onSuccess={this.autentiqueIntegrationSuccess.bind(this)}
                      activeCompany={company}
                      defaultValue={this.state.superlogicaParams}
                    />
                  )}
                </footer>
              </div>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              <div className="card-select">
                <header className="card-select-header">
                  <div className="media">
                    <div className="media-content">
                      <p className="text has-text-weight-bold">Ficha Certa</p>
                      <p className="text is-small">Integração Ficha Certa</p>
                    </div>
                  </div>
                </header>
                <footer
                  className="card-select-footer"
                  style={{ textAlign: 'left', zIndex: 9999 }}
                >
                  {this.state.integrationsIsLoaded && (
                    <FichacertaIntegration
                      onSuccess={this.autentiqueIntegrationSuccess.bind(this)}
                      activeCompany={company}
                      activeUser={activeUser}
                      defaultValue={this.state.fichacertaParams}
                    />
                  )}
                </footer>
              </div>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col>
              <div className="card-select">
                <header className="card-select-header">
                  <div className="media">
                    <div className="media-content">
                      <p className="text has-text-weight-bold">Rede Vistorias</p>
                      <p className="text is-small">
                        Url de Webhook para integração com a Rede Vistorias
                      </p>
                    </div>
                  </div>
                </header>
                <footer
                  className="card-select-footer"
                  style={{ textAlign: 'left', zIndex: 9999 }}
                >
                  <div style={{ width: '100%' }}>
                    <FlexBar>
                      <FlexBar.Child isGrow>
                        <FormFieldText
                          label="Webhook"
                          valueDefault={WEBHOOK_VISTORIAS_URL}
                          isHorizontal
                          isDisabled
                          isFullWidth
                          isFlat
                        />
                      </FlexBar.Child>
                      <FlexBar.Child>
                        <Button
                          onClick={this.handleWebhookCopy.bind(
                            this,
                            WEBHOOK_VISTORIAS_URL
                          )}
                          isFlat
                          title="Copiar Url"
                          isTab
                        >
                          <Icon name="fas fa-clipboard" />
                        </Button>
                      </FlexBar.Child>
                    </FlexBar>
                  </div>
                </footer>
              </div>
            </Grid.Col>
          </Grid>
        </SectionWrapper>

        <SectionWrapper header="API pública">
          <FlexBar>
            <FlexBar.Child isGrow>
              <FormFieldText
                label="URL"
                info="Documentação da API pública"
                valueDefault={this.state.urlApiDocs}
                isHorizontal
                isDisabled
                isFullWidth
                isFlat
              />
            </FlexBar.Child>
            <FlexBar.Child>
              <Button
                onClick={this.handleLinkCopy.bind(this, this.state.urlApiDocs)}
                isFlat
                title="Copiar URL"
                isTab
              >
                <Icon name="fas fa-clipboard" />
              </Button>
            </FlexBar.Child>
          </FlexBar>
          <FlexBar>
            <FlexBar.Child isGrow>
              <FormFieldText
                label="API Key"
                info="Este token dá acesso aos endpoints públicos da API"
                valueDefault={token}
                isHorizontal
                isDisabled
                isFullWidth
                isFlat
              />
            </FlexBar.Child>
            <FlexBar.Child>
              <Button
                onClick={this.handleTokenCopy.bind(this, token)}
                isFlat
                title="Copiar chave"
                isTab
              >
                <Icon name="fas fa-clipboard" />
              </Button>
            </FlexBar.Child>
          </FlexBar>
        </SectionWrapper>
      </BodyContainer>
    );
  }

  handleFeature() {
    this.setState({
      openModal: true,
    });
  }

  handleModalClose() {
    this.setState({
      openModal: false,
    });
  }

  autentiqueIntegrationSuccess() {
    this.props.notification({
      message: 'Integração configurada com sucesso',
    });
  }

  handleTokenCopy(token) {
    copyToClipboard(token);
    this.props.notification({
      message: 'Token copiado para área de transferência',
    });
  }

  handleLinkCopy(token) {
    copyToClipboard(token);
    this.props.notification({
      message: 'Link copiado para área de transferência',
    });
  }

  handleWebhookCopy(token) {
    copyToClipboard(token);
    this.props.notification({
      message: 'Webhook copiado para área de transferência',
    });
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    companyMeta: store.app.companyMeta,
    updateActiveCompany: store.app.helpers.updateActiveCompany,
    updateCompanyMeta: store.app.helpers.updateCompanyMeta,
    showNotification: store.app.helpers.showNotification,
  }),
  { ...actions, ...commons }
)(Integrations);
