import React from 'react';

import actionCreator from './authCreator';
import CheckUser from './CheckUser';
import CheckUserPassword from './CheckUserPassword';

import { fb } from '../../services/firebase';

export class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSnapshotBeforeUpdate(prevProps) {
    this.props.createRequest(this.createRequest);
    if (prevProps.actionKey !== this.props.actionKey && this.props.isSuccess && this.props.loginData) {
      this.props.showSplashScreen(true);
      this.props.setTimeout(() => {
        if (!this.props.loginData) {
          // If login data is undefined try again
          this.props.setTimeout(() => {
            const { id, email, meta, companies } = this.props.loginData;
            const parsedMeta = JSON.parse(meta);
            fb.logEvent('login', {
              companyId: (parsedMeta || {}).lastCompanyId ? (parsedMeta || {}).lastCompanyId : (companies || []).length > 0 ? companies[0].id : null,
              user: {
                id,
                email,
              },
            });
            this.props.history.push(`/app/${id}`);
          }, 800);
          return;
        }
        const { id, email, meta, companies } = this.props.loginData;
        const parsedMeta = JSON.parse(meta);
        fb.logEvent('login', {
          companyId: (parsedMeta || {}).lastCompanyId ? (parsedMeta || {}).lastCompanyId : (companies || []).length > 0 ? companies[0].id : null,
          user: {
            id,
            email,
          },
        });
        this.props.history.push(`/app/${id}`);
      }, 600);
    }
    return null;
  }

  render() {
    if (this.props.userExists) {
      return <CheckUserPassword {...this.props} resetCheck={this.resetCheck.bind(this)} redirectToCreate={this.redirectToCreate.bind(this)} />;
    } else {
      return <CheckUser {...this.props} redirectToCreate={this.redirectToCreate.bind(this)} />;
    }
  }

  createRequest(fields) {
    const { userExists } = this.props;

    if (!userExists) {
      this.props.checkUserEmail({ username: fields.username.value });
    } else {
      this.props.login({
        email: fields.username.value,
        password: fields.password.value,
      });
    }
  }

  redirectToCreate() {
    window.location.href = `${OAUTH2_REGISTER_URL}?client_id=redeizee&redirect_uri=${IZEE_APP_URL}auth&response_type=token&origin=IZEE&scope=${OAUTH2_SCOPES}`
  }

  resetCheck() {
    // window.location.reload();
    this.props.resetUserEmailCheck();
  }
}

export default actionCreator(Auth);
