import React from 'react'

import request from 'axios'

import { baseURL } from 'core/constants'

import { goodObject } from 'utils/utils'

import { fieldProps } from 'features/components/formCreator'
import formCreator from 'features/components/formCreator'

import { FormFieldText } from 'components/lib/FormFields'
import Dropdown from 'components/lib/Dropdown'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import Grid from 'components/lib/Grid'

export class SendReceiptAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this))
  }

  render() {
    const { formIsWorking, formIsReady, defaultValue } = this.props

    const alreadySended =
      defaultValue.account_document &&
      defaultValue.bank_account &&
      defaultValue.bank_agency &&
      defaultValue.bank_name &&
      defaultValue.beneficiary_name

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <FormFieldText
              {...fieldProps(this.props, 'beneficiary_name')}
              style={{ width: 300 }}
              label='Enviar dados bancários para transferência'
              placeholder='Beneficiário'
              isDisabled={this.props.isClosed}
            />
          </Grid.Col>
          <Grid.Col isNarrow isFlexBottom>
            <FormFieldText
              {...fieldProps(this.props, 'bank_agency')}
              style={{ width: 120 }}
              placeholder='Agência'
              isDisabled={this.props.isClosed}
            />
          </Grid.Col>
          <Grid.Col isNarrow isFlexBottom>
            <FormFieldText
              {...fieldProps(this.props, 'bank_account')}
              style={{ width: 120 }}
              placeholder='Conta'
              isDisabled={this.props.isClosed}
            />
          </Grid.Col>
          <Grid.Col isFlexBottom>
            <FormFieldText
              {...fieldProps(this.props, 'bank_name')}
              placeholder='Banco'
              isDisabled={this.props.isClosed}
            />
          </Grid.Col>
          <Grid.Col isFlexBottom>
            <FormFieldText
              {...fieldProps(this.props, 'account_document')}
              placeholder='CPF/CNPJ'
              mask={['cpf_cnpj']}
              isDisabled={this.props.isClosed}
            />
          </Grid.Col>
          {!this.props.isClosed && (
            <Grid.Col isNarrow isFlexBottom>
              <Dropdown
                isRight
                isDisabled={!formIsReady || formIsWorking}
                trigger={
                  <Button
                    isLoading={formIsWorking}
                    isDisabled={!formIsReady || formIsWorking}
                    hasThemeColor
                  >
                    Enviar por:
                    <Icon name='fas fa-arrow-down' />
                  </Button>
                }
              >
                <React.Fragment>
                  <Dropdown.Item>
                    <Button
                      onClick={() => this.createRequest(this.props.fields, 'email')}
                      isBare
                      isFullWidth
                      isMedium
                    >
                      <Icon name='far fa-envelope' />
                      Email
                    </Button>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Button
                      onClick={() =>
                        this.createRequest(this.props.fields, 'whatsapp')
                      }
                      isBare
                      isFullWidth
                      isMedium
                    >
                      <svg
                        style={{ marginLeft: '4', marginRight: '4' }}
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                      >
                        <path d='M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z' />
                      </svg>
                      WhatsApp
                    </Button>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Button
                      onClick={() => this.createRequest(this.props.fields, 'link')}
                      isBare
                      isFullWidth
                      isMedium
                    >
                      <Icon name='fas fa-link' />
                      Copiar URL
                    </Button>
                  </Dropdown.Item>
                </React.Fragment>
              </Dropdown>
              {/* <Button hasThemeColor isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} submit>
              {defaultValue.account_document && defaultValue.bank_account && defaultValue.bank_agency && defaultValue.bank_name && defaultValue.beneficiary_name ?
                'Reenviar' :
                'Enviar'}
              </Button> */}
            </Grid.Col>
          )}
        </Grid>
        <Grid>
          <Grid.Col>
            {alreadySended && (
              <p className='button is-link is-dark'>
                Dados bancários para transferência já enviados
              </p>
            )}
          </Grid.Col>
        </Grid>
      </React.Fragment>
    )
  }

  createRequest(fields, type) {
    const { processId } = this.props
    const { companyId } = this.props

    const goodFields = goodObject(fields, {
      account_document: 'account_document.value',
      bank_account: 'bank_account.value',
      bank_agency: 'bank_agency.value',
      bank_name: 'bank_name.value',
      beneficiary_name: 'beneficiary_name.value',
    })

    if (type == 'email') {
      goodFields['send_email'] = true
    }
    request
      .post(
        `${baseURL}company/${companyId}/process/${processId}/guarantee/request-payment`,
        goodFields,
      )
      .then((response) =>
        this.props.onSuccess({
          isSuccess: true,
          type: type,
          response: response.data,
        }),
      )
      .catch(() => this.props.onSuccess({ isSuccess: false }))

    // this.props.submit(`{company}/process/${processId}/guarantee/request-payment`, goodFields);
  }
}

export default formCreator(SendReceiptAccount)
