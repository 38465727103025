const initialState = {
  items: [],
  listInfo: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_CLIENTS':
      state = {
        ...state,
        ...{
          items: action.payload || [],
          listInfo: action.listInfo,
        },
      };
      break;

    default:
      break;
  }

  return state;
};
