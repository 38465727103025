import { request, CompanyMeta } from 'core/constants';
import { requestState, notification } from 'utils/UtilityStore/actions';

const dispatchAllActions = ({ dispatch, response, setting, message = 'Configuração atualizada com sucesso' }) => {
  setting && dispatch(requestState(false, setting));
  dispatch(
    notification({
      isOpen: true,
      message,
    })
  );
  response &&
    dispatch({
      type: 'APP_UPDATE_ACTIVE_COMPANY',
      payload: response.data,
    });
};

export const updateSettings = (setting, newValue) => (dispatch) => {
  dispatch(requestState(true, setting));
  request
    .put('{company}/settings', {
      [setting]: newValue,
    })
    .then((response) => {
      dispatchAllActions({
        dispatch,
        response,
        setting,
      });
    })
    .catch((error) => { });
};

export const updateAllSettings = (values) => async (dispatch) => {
  try {
    const response = await request.put('{company}/settings', values);
    dispatchAllActions({
      dispatch,
      response,
    });
  } catch (error) { }
};

export const updateGuarantees = (guaranteeItem, isActive) => (dispatch, store) => {
  dispatch(requestState(true, guaranteeItem.code));
  request
    .put(`{company}/guarantee/${guaranteeItem.id}`, {
      is_active: isActive,
    })
    .then(() => {
      dispatch(requestState(false, guaranteeItem.code));
      dispatch(
        notification({
          message: 'Garantia atualizada com sucesso',
        })
      );
      let updatedCompany = [...store().app.activeCompany.guarantess];
      updatedCompany.map((item) => {
        if (item.id === guaranteeItem.id) item['is_active'] = isActive;
        return item;
      });
      dispatch({
        type: 'APP_UPDATE_ACTIVE_COMPANY',
        payload: updatedCompany,
      });
    })
    .catch((error) => { });
};

export const updateCompanyMeta = (path, value) => (dispatch) => {
  // CompanyMeta.set(path, value);

  if (path === 'themeColor') {
    request
      .put('{company}', {themeColor: value})
      .then((response) => {
        dispatch(
          notification({
            message: 'Cor do tema atualizado com sucesso!',
          })
        );
        dispatch({
          type: 'APP_UPDATE_ACTIVE_COMPANY',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'APP_UPDATE_ACTIVE_COMPANY_ERROR',
          payload: error,
        });
      });
    
  } else if (path === 'logo') {
    let data = new FormData();
    data.append('logo', value);
    dispatch(requestState(true, 'uploadingLogo'));
    request
      .post('{company}?_method=PUT', data)
      .then((response) => {
        dispatch(requestState(false, 'uploadingLogo'));
        dispatch(
          notification({
            message: 'Logo atualizada com sucesso!',
          })
        );
        dispatch({
          type: 'APP_UPDATE_ACTIVE_COMPANY',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'APP_UPDATE_ACTIVE_COMPANY_ERROR',
          payload: error,
        });
      });
  }


};

export const fetchContracts = (filter) => (dispatch) => {
  dispatch(requestState(true, 'contracts'));
  request
    .get('{company}/contract-model', { filter })
    .then((response) => {
      dispatch(requestState(false, 'contracts'));
      dispatch({
        type: 'LIST_CONTRACTS',
        payload: response.data,
        listInfo: response.resultsInfo,
      });
    })
    .catch((error) => { });
};

export const fetchHeaderFooterTemplates = (filter) => (dispatch) => {
  const newFilter = { ...filter, page: filter?.pageHeaderFooter };
  delete newFilter.pageHeaderFooter;
  dispatch(requestState(true, 'header-footer-template'));
  request
    .get('{company}/header-footer-template', { filter: newFilter })
    .then((response) => {
      dispatch(requestState(false, 'header-footer-template'));
      dispatch({
        type: 'LIST_HEADER_FOOTER_TEMPLATE',
        payload: response.data,
        listInfo: response.resultsInfo,
      });
    })
    .catch((error) => {});
};

export const fetchContractVariables = () => (dispatch) => {
  dispatch(requestState(true, 'contract-model-variables'));

  request
    .get('{company}/contract-model-variables')
    .then((response) => {
      dispatch(requestState(false, 'contract-model-variables'));
      dispatch({
        type: 'LIST_CONTRACT_MODEL_VARIABLES',
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const updateAnalysisCost = (value) => (dispatch) => {
  request
    .put('{company}/settings', {
      ['credit_analysis_cost']: value,
    })
    .then((response) => {
      dispatch(
        notification({
          isOpen: true,
          message: 'Configuração atualizada com sucesso',
        })
      );
    })
    .catch((error) => { });
};
