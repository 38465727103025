import React from 'react';
import moment from 'moment';

// Components
import Table from 'components/lib/Table';
import EmptyState from 'components/EmptyState';
import Grid from 'components/lib/Grid';
import formCreator from 'features/components/formCreator';
import Button from 'components/lib/Button';
import UserSelection from 'features/components/fieldsGroup/UserSelection';

export class ShareProposal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  render() {
    const { formIsWorking, formIsReady, history } = this.props;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <UserSelection {...this.props} defaultValue={[]} />
          </Grid.Col>
          <Grid.Col isNarrow isFlexBottom>
            <Button hasThemeColor isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} style={{ height: 42 }} submit>
              Enviar
            </Button>
          </Grid.Col>
        </Grid>
        <div style={{ maxHeight: 400, overflowY: 'auto' }}>
          <Grid>
            <Grid.Col>
              <Table
                items={history}
                cells={[
                  { name: 'Nome', path: 'name' },
                  { name: 'Cargo', path: 'profiles' },
                  {
                    name: 'Data do envio',
                    path: 'send_date',
                    render: (content) => moment(content, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY hh:mm'),
                  },
                ]}
                emptyState={<EmptyState />}
              />
            </Grid.Col>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  createRequest(fields) {
    const { proposalId } = this.props;
    this.props.submit(`{company}/proposal/${proposalId}/send-public-token`, fields);
  }
}

export default formCreator(ShareProposal);
