import React from 'react'
import moment from 'moment'

// Components
import { FormFieldDate, FormFieldTime } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import Button from 'components/lib/Button'

import formCreator from 'features/components/formCreator'
import { fieldProps } from 'features/components/formCreator'


export class KeysDeliveryDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this))
  }

  render() {
    const { formIsWorking, formIsReady, isClosed } = this.props

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col isNarrow>
            <FormFieldDate
              changeDate={this.changeDate.bind(this)}
              {...fieldProps(this.props, 'date')}
              minDate={moment()}
            />
          </Grid.Col>
          <Grid.Col isNarrow>
            <FormFieldTime {...fieldProps(this.props, 'time')} />
          </Grid.Col>
          {!isClosed && (
            <Grid.Col isNarrow isFlexBottom>
              <Button
                hasThemeColor
                isLoading={formIsWorking}
                isDisabled={!formIsReady || formIsWorking}
                submit
              >
                Salvar
              </Button>
            </Grid.Col>
          )}
        </Grid>
      </React.Fragment>
    )
  }

  changeDate(date) {
    this.setState({ date: date })
  }

  createRequest(fields) {
    const { processId } = this.props

    let date = `${moment(fields.date).format('YYYY-MM-DD')} ${fields.time}:00`

    let goodFields = { keys_delivered_at: date }

    this.props.submit(
      `{company}/process/${processId}/possession/set-keys-delivery-date`,
      goodFields,
      'post',
    )
  }
}

export default formCreator(KeysDeliveryDate)
