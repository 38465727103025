import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999999999999;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  background-color: #00000014;

  @media (min-width: 458px) {
    padding: 40px;
  }

  @media (min-width: 768px) {
    padding: 40px 100px;
  }
`;

export const Modal = styled.div`
  background-color: white;

  border-radius: 4px;

  max-height: 100%;

  overflow: auto;

  box-shadow: 0px 6px 20px #00000014;
`;

export const ModalHeader = styled.div`
  padding: 16px 24px 16px 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 16px;

  background-color: #f5f5f5;
`;

export const ModalBody = styled.div`
  padding: 20px;
`;
