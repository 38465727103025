import React from 'react';

import { fieldProps } from 'features/components/formCreator';

import Grid from 'components/lib/Grid';
import { FormFieldText } from 'components/lib/FormFields';

const OtherPersons = ({ formProps, index }) => (
  <Grid>
    <Grid.Col>
      <FormFieldText
        mask={['cpf']}
        label="CPF *"
        {...fieldProps(formProps, `legal_tenant.partners.${index}.document`)}
      />
    </Grid.Col>
    <Grid.Col>
      <FormFieldText
        label="Nome *"
        {...fieldProps(formProps, `legal_tenant.partners.${index}.name`)}
      />
    </Grid.Col>
    <Grid.Col>
      <FormFieldText
        label="% do capital social *"
        {...fieldProps(formProps, `legal_tenant.partners.${index}.percent`)}
      />
    </Grid.Col>
    <Grid.Col>
      <FormFieldText
        mask={['date']}
        label="Data de entrada *"
        {...fieldProps(formProps, `legal_tenant.partners.${index}.date_of_entry`)}
      />
    </Grid.Col>
  </Grid>
);

export default OtherPersons;
