import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

// Components
import Table from 'components/lib/Table';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import EmptyState from 'components/EmptyState';
import { FormFieldSwitch } from 'components/lib/FormFields';
import listWrapper from 'containers/Records/listWrapper';

import Profiles from './Profiles';
import LastActivity from './LastActivity';
import UserName from './UserName';
import Form from 'components/lib/Form';
import Grid from 'components/lib/Grid';
import { FormFieldText } from 'components/lib/FormFields';

const List = (props) => (
  <>
    <Form
      // getRef={ref => (this.form = ref)}
      onSubmit={() => {}}
      autoFocus>
      <Grid>
        <Grid.Col>
          <FormFieldText placeholder="Pesquisar" value="" onChange={debounce(200, props.handleFieldChanges.bind(this))} noValidate />
        </Grid.Col>
        <Grid.Col isNarrow>
          <Button hasThemeColor onClick={() => props.handleInativeList()}>
            Inativo
          </Button>{' '}
          <Button hasThemeColor onClick={() => props.handleAtivoList()}>
            Ativo
          </Button>{' '}
          <Button hasThemeColor onClick={() => props.handleAllList()}>
            Todos
          </Button>{' '}
        </Grid.Col>
      </Grid>
    </Form>
    <br />
    <Table
      items={props.items}
      isUpdatingRows={props.isWorking}
      onClick={props.handleRowClick}
      cells={[
        {
          name: 'Usuário',
          width: '25%',
          render: (content, row) => <UserName content={row} />,
        },
        {
          name: 'Perfil',
          path: 'profiles',
          isMultiline: true,
          render: (content) => <Profiles content={content} />,
        },
        {
          name: 'Ativo',
          path: 'is_active',
          shrink: true,
          align: 'center',
          render: (content, row) => {
            return <FormFieldSwitch defaultChecked={content} isDisabled={!row['last_activity']} onChange={props.handleStatusUpdate.bind(null, row)} />;
          },
        },
        {
          name: 'Último acesso',
          path: 'last_activity',
          shrink: true,
          align: 'center',
          render: (content, row) => <LastActivity 
          onClick={props.handleInviteResend.bind(null, row)} 
          onClickWhatsApp={props.handleInviteWhatsapp.bind(null, row)}
          onClickLink={props.handleInviteLink.bind(null, row)}
          content={row} />,
        },
        {
          name: 'Editar',
          // path: 'last_activity',
          shrink: true,
          align: 'center',
          render: (content, row) => (
            <Button isNeutral isFlat onClick={props.handleRowEdit.bind(null, row)}>
              <Icon name="fas fa-pen-square" />
            </Button>
          ),
        },
        {
          name: 'Excluir',
          // path: 'last_activity',
          shrink: true,
          align: 'center',
          render: (content, row) => (
            <Button isNeutral isFlat onClick={props.handleRowDelete.bind(null, row)}>
              <Icon name="fas fa-ban" />
            </Button>
          ),
        },
      ]}
      emptyState={<EmptyState isLoading={props.isWorking} />}
    />
  </>
);

List.defaultProps = {
  items: [],
  isWorking: false,
  handleRowEdit: () => {},
  handleStatusUpdate: () => {},
  handleInviteResend: () => {},
  handleInviteWhatsapp: () => {},
  handleInviteLink: () => {},
  handleUpdateSuccess: () => {},
};

export default listWrapper(List);
