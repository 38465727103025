import React from 'react';
import { connect } from 'react-redux';

// Components
import { FormFieldText } from 'components/lib/FormFields';
import Button from 'components/lib/Button';
import FlexBar from 'components/lib/FlexBar';

import formCreator from 'features/components/formCreator';
import { fieldProps } from 'features/components/formCreator';

// Utils
import * as commons from 'utils/UtilityStore/actions';
import integrations from '../../../services/integrations';

export class SuperlogicaIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
    this.toggleHidden = this.toggleHidden.bind(this);
    this.saveAndSubmit = this.saveAndSubmit.bind(this);
  }

  toggleHidden() {
    const { isEditing } = this.state;
    this.setState({
      isEditing: !isEditing,
    });
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  saveAndSubmit() {
    this.props.submitForm();
    return this.toggleHidden();
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;
    const { isEditing } = this.state;

    return (
      <div>
        <FlexBar>
          <FlexBar.Child isGrow>
            <FormFieldText {...fieldProps(this.props, 'appToken')} label="App Token" isDisabled={!isEditing} isHorizontal isFullWidth isFlat />
          </FlexBar.Child>
          <FlexBar.Child isGrow>
            <FormFieldText {...fieldProps(this.props, 'accessToken')} label="Access Token" isDisabled={!isEditing} isHorizontal isFullWidth isFlat />
          </FlexBar.Child>
          <FlexBar.Child>
            {!isEditing ? (
              <Button hasThemeColor onClick={this.toggleHidden} isHorizontal isFlat>
                Editar
              </Button>
            ) : (
              <Button hasThemeColor isLoading={formIsWorking} onClick={this.saveAndSubmit} isDisabled={formIsWorking} isHorizontal isFlat>
                Salvar
              </Button>
            )}
          </FlexBar.Child>
        </FlexBar>
      </div>
    );
  }

  createRequest(fields) {

    integrations.enable({
      companyId: this.props.activeCompany.id,
      enabled: true,
      appName: 'superlogica',
      appParams: fields
    })
    .then(({ success }) => {
      if (!success) throw new Error();
      this.props.fakeSubmit();
    })
    .catch((error) => {
      console.log(error);
    });

    // this.props.submit('{company}/settings', fields, 'put');
  }
}

export default connect((store) => ({}), { ...commons })(formCreator(SuperlogicaIntegration));
