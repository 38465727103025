import React from 'react'
import ReactToPrint from 'react-to-print'
import { request } from 'core/constants'

import { connect } from 'react-redux'
import * as commons from 'utils/UtilityStore/actions'

import Button from 'components/lib/Button'
import SectionWrapper from 'components/SectionWrapper'
import Proposal from 'components/Proposal'

import ShareProposal from './components/ShareProposal'
import ArchiveProposal from 'features/ArchiveProposal'

import withModal from 'components/HOCs/withModal'
import withFilePreview from 'components/HOCs/withFilePreview'
import Profile from 'components/Profile'

import { timeDelay, copyToClipboard } from 'utils/utils'

class ProcessProposal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shareHistory: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.shareHistory.length === 0 && props.current.proposal) {
      return {
        shareHistory: props.current.proposal.public_token_history,
      }
    }

    return null
  }

  render() {
    let current = this.props.current

    let company = this.props.company

    if (!current.proposal) return null

    let pageWidth = document.body.offsetWidth

    return (
      <SectionWrapper
        header='Proposta'
        customHeader={true}
        pageWidth={pageWidth}
        headerRight={
          <div className='buttons'>
            <Profile disabledIf={[6, 7]}>
              <Button isDark isFlat onClick={this.shareProposal.bind(this)}>
                Compartilhar
              </Button>
              <Button
                isDanger
                isFlat
                onClick={this.archiveProposal.bind(
                  this,
                  current.proposal.id,
                  company,
                )}
              >
                Arquivar
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Button isFlat hasThemeColor>
                    Imprimir
                  </Button>
                )}
                content={() => this.componentRef}
                copyStyles={true}
                bodyClass='print-padding'
              />
            </Profile>
          </div>
        }
      >
        <Proposal
          data={current.proposal}
          company={company}
          onClickFile={this.props.openFile}
          onClickResend={!this.state.isResending && this.resendProposal.bind(this)}
          onClickCopy={!this.state.isResending && this.copyProposalLink.bind(this)}
          ref={(element) => (this.componentRef = element)}
        />
      </SectionWrapper>
    )
  }

  shareProposal() {
    const { current } = this.props
    const { proposal } = current

    this.props.openModal(
      'Compartilhar proposta',
      <ShareProposal
        history={this.state.shareHistory}
        proposalId={proposal.id}
        onSuccess={this.updateHistory.bind(this)}
      />,
    )
  }

  resendProposal(resendTo, data) {
    const body = { [resendTo]: [data.id] }
    const { current } = this.props
    const { proposal } = current

    request
      .post(`{company}/proposal/${proposal.id}/send-to-parties`, body)
      .then((response) => {
        this.props.notification({
          message: 'Proposta reenviada com sucesso para ' + data.email,
        })
      })
      .catch((error) => {
        this.props.notification({
          message: 'Ocorreu um erro ao tentar reenviar a proposta',
          status: 'danger',
        })
      })
  }

  copyProposalLink(data) {
    if (this.state.isResending) return
    if (!data || !data.operation_tokens) return

    const tokens = data.operation_tokens

    let toDelete = []

    tokens.map((token, index) => {
      if (token.operation_code !== 'UPDATE_CLIENT_DATA') toDelete.push(index)
    })

    const newArr = tokens.filter((token) => token !== null)

    for (let i = 0; i < toDelete.length; i++) {
      delete tokens[toDelete[i]]
    }

    newArr.reverse()

    const path = `${IZEE_PROCESS_URL}${newArr[0].token}`

    copyToClipboard(path)

    return true
  }

  updateHistory(response) {
    if (response.isSuccess) {
      const history = this.state.shareHistory

      this.props.notification({ message: 'Proposta enviada com sucesso' })
      this.setState({ shareHistory: response.data })
      this.props.closeModal()
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar enviar a proposta por email',
        status: 'danger',
      })
    }
  }

  archiveProposal(proposalId, companyId) {
    this.props.openModal(
      'Arquivar proposta',
      <ArchiveProposal
        proposalId={proposalId}
        activeCompany={companyId}
        onSuccess={this.onArchiveProposalSuccess.bind(this)}
      />,
    )
  }

  onArchiveProposalSuccess(response) {
    if (response.isSuccess) {
      this.props.closeModal()
      let delayRedirect = timeDelay(400)

      delayRedirect(() => {
        this.props.history.push('/')
      })
    } else {
      this.props.notification({
        message: response.actionMessage,
        status: 'danger',
      })
    }
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    current: store.processDetails.current,
  }),
  { ...commons },
)(withFilePreview(withModal(ProcessProposal)))
