import React, { Fragment } from 'react';
import Button from 'components/lib/Button';
import withRedux from 'components/redux';
import Icon from 'components/lib/Icon';

import { CompanyHeader } from './styles';

import AppLogo from 'components/AppLogo';

class ModalHeader extends React.Component {
  constructor(props) {
    super(props);
    this.focusTop = React.createRef();
  }

  componentDidMount() {
    this.focusTop.current.focus();
  }

  render() {
    const {
      closeClick,
      title,
      secondButtonTitle,
      requestRegistrationClick,
      requestDeleteClick,
      companyInfo,
      hideCloseIcon,
      canCustomizeApp,
    } = this.props;

    let pageWidth = document.body.offsetWidth;
    let titleMobile = '';

    this.props.noTitleMobile && pageWidth < 768
      ? (titleMobile = ' noTitle')
      : (titleMobile = '');

    const styles = {
      requestRegistration: {
        borderWidth: 1,
        borderColor: '#D5DADF',
        borderStyle: 'solid',
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
        color: '#7F8996',
        fontWeight: 'bold',
        marginRight: 20,
      },
      requestDelete: {
        borderWidth: 1,
        borderColor: '#EB144C',
        borderStyle: 'solid',
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
        color: '#EB144C',
        fontWeight: 'bold',
        marginRight: 20,
      },
      close: {
        fontSize: 18,
        color: '#4a4a4a',
      },
    };

    const isApp = location.href.includes('/app/');

    return (
      <Fragment>
        {this.props.companyInfo ? (
          <CompanyHeader
            backgroundColor={(!isApp || canCustomizeApp) && companyInfo.themeColor}
          >
            <AppLogo name={companyInfo.companyName} {...this.props} />
          </CompanyHeader>
        ) : null}

        <div className="header-rfmodal">
          <div className="leftWrap-rfmodal">
            <div className="return-rfmodal">
              {!hideCloseIcon ? (
                <button className="back-button" onClick={closeClick}>
                  <Icon style={styles.close} name="far fa-chevron-left" />
                </button>
              ) : null}
            </div>
            <p className={'header-title-rfmodal' + titleMobile}>{title}</p>
          </div>
          <input
            style={{ width: 0, height: 0, display: 'none' }}
            ref={this.focusTop}
            tabIndex="-1"
          />
          <div className="rightWrap-rfmodal">
            {this.props.requestDeleteClick ? (
              <Button
                style={styles.requestDelete}
                onClick={requestDeleteClick}
              >
                Excluir
              </Button>
            ) : null}
            {this.props.requestRegistration ? (
              <Button
                style={styles.requestRegistration}
                onClick={requestRegistrationClick}
              >
                {secondButtonTitle}
              </Button>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRedux((state) => {
  const subscriptionPlan = state.app.activeCompany?.subscription_plan;
  const canCustomizeApp = subscriptionPlan ? subscriptionPlan.can_customize_app : true;

  return {canCustomizeApp}
})(ModalHeader);

