import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import Button from "components/lib/Button";
import Header from "components/lib/Header";
import Icon from "components/lib/Icon";

class ModalBody extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    if (this.state.isOpen != this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
      if (this.props.onOpen && this.props.isOpen == true) this.props.onOpen();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen != nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
      if (this.props.onOpen && nextProps.isOpen === true) this.props.onOpen();
    }
  }

  render() {
    const modalCSSClass = `fullscreen-modal${
      this.state.isOpen ? " is-active" : ""
    }${this.props.responsive === false ? "" : " is-responsive"}`;
    return (
      <div className={modalCSSClass}>
        <div className="fullscreen-modal-content">
          <div className="fullscreen-modal-body">
            <div className="close_modal" onClick={this.close.bind(this)}>
              <Icon name="fas fa-times" />
            </div>
            {this.props.children}
          </div>
        </div>
        <div
          className="fullscreen-modal-background"
          role="button"
          tabIndex="0"
          onKeyDown={
            !this.props.disableClose && !this.props.disableBackground
              ? this.close.bind(this)
              : null
          }
          onClick={
            !this.props.disableClose && !this.props.disableBackground
              ? this.close.bind(this)
              : null
          }
        />
      </div>
    );
  }

  close() {
    this.setState({ isOpen: false });
    if (this.props.onClose) this.props.onClose();
  }
}

const body = document.body;

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement("div");
  }

  componentDidMount() {
    body.appendChild(this.element);
  }

  componentWillUnmount() {
    body.removeChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}

const FullscreenModal = props => (
  <Portal>
    <ModalBody {...props} />
  </Portal>
);

export default FullscreenModal;
