import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import Modal from 'components/lib/Modal'
import Button from 'components/lib/Button'
import Text from 'components/lib/Text'

import { fb } from '../../../firebase'

import integrations from '../../'
import Trigger from './Trigger'

const Superlogica = ({ companyId, payload }) => {
  const [modalState, setModalState] = useState(false)
  const [requestState, setRequestState] = useState('')
  const [integrationData, setIntegrationData] = useState({})
  const [appData, setAppData] = useState({})

  useEffect(() => {
    integrations.list({ companyId }).then(({ success, data }) => {
      const superlogica = data?.filter(({ name }) => name === 'superlogica')[0]

      if (!success || !superlogica) return

      setIntegrationData(superlogica)

      if (!payload || !payload.id) return
      fb.process(payload.id, ({ success, data }) => {
        try {
          if (success && data) {
            if (data?.integrations?.superlogica) {
              const { integrations } = data
              const superlogica = integrations.superlogica
              const updatedAt = superlogica.updatedAt
              const format = 'DD/MM/YYYY H:mm:ss'

              superlogica.updatedAt = moment(updatedAt.toDate()).format(format)
              setAppData(superlogica)
            }
          }
        } catch (error) {
          console.log('setAppData fails')
        }
      })
    })
  }, [])

  // //

  const ownershipDate = payload.proposal.ownership_date
  const validityMonths = payload.proposal.validity_months
  const property = payload.proposal.properties[0]
  const clients = payload.proposal.clients
  const propertyOwners = payload.proposal.property_owners

  const openModal = () => setModalState(true)
  const closeModal = () => setModalState(false)

  if (!integrationData.enabled) return null

  const validaCpf = (persons) => {
    let valid = true

    persons.map((item) => {
      if (!item.cpf) {
        valid = false
        return
      }
    })

    return valid
  }

  const clientsCpfValid = validaCpf(clients)
  const propertyOwnersCpfValid = validaCpf(propertyOwners)

  const propertyValid = !(
    !property.type?.id ||
    !property.address?.zip_code ||
    !property.address?.address ||
    !property.address?.number ||
    !property.address?.neighborhood ||
    !property.address?.state ||
    !property.address?.city
  )

  const guaranteeCode = payload?.proposal?.guarantee?.code

  let guaranteeValueValid = true

  if (
    (guaranteeCode == 'GUARANTEE_SURETY_BOND' ||
      guaranteeCode == 'GUARANTEE_CAPITALIZATION' ||
      guaranteeCode == 'GUARANTEE_DEPOSIT') &&
    (!payload.guarantee_value || payload.guarantee_value == 0)
  ) {
    guaranteeValueValid = false
  }

  const payloadIsValid =
    ownershipDate &&
    validityMonths &&
    propertyValid &&
    clientsCpfValid &&
    propertyOwnersCpfValid &&
    guaranteeValueValid


  payload.companyId = companyId

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
        height: 32,
      }}
    >
      <Trigger onClick={openModal} />
      <Modal title='Superlógica' isOpen={modalState} onClose={closeModal}>
        <>
          {!payloadIsValid ? (
            <div>
              <Text>
                <b>
                  ATENÇÃO: os campos listados abaixo são informações obrigatórias.
                </b>
              </Text>
              {!ownershipDate && <Text> - Data da posse</Text>}
              {!validityMonths && <Text> - Vigência do contrato</Text>}
              {!propertyValid && <Text> - Endereço do imóvel</Text>}
              {!propertyOwnersCpfValid && <Text> - CPF dos proprietários</Text>}
              {!clientsCpfValid && <Text> - CPF dos Locatários</Text>}
              {!guaranteeValueValid && <Text> - Valor da garantia</Text>}
            </div>
          ) : null}
          {payloadIsValid && (
            <Text>
              Link do contrato:{' '}
              {appData.link ? (
                <a href={appData.link} target='_blank' rel='noreferrer'>
                  {appData.link}
                </a>
              ) : (
                'Indisponível'
              )}
            </Text>
          )}
          {appData.updatedAt && payloadIsValid ? (
            <Text>Última atualização: {appData.updatedAt}</Text>
          ) : null}
        </>
        {!appData.link && payloadIsValid ? <hr /> : null}
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            height: 32,
          }}
        >
          {!appData.link && payloadIsValid ? (
            <Button
              disabled={requestState === 'processing'}
              onClick={() => {
                setRequestState('processing')
                integrations
                  .apps({
                    appName: 'superlogica',
                    companyId,
                    payload,
                  })
                  .then(({ data }) => {
                    if (data.app?.response?.success) {
                      setRequestState('Dados enviados com sucesso!')
                    } else if (data?.app?.response?.data?.status === '206') {
                      setRequestState(data?.app?.response?.data?.data[0].msg)
                    } else {
                      setRequestState(
                        data.app.response.data.msg.replace(/<\/?[^>]+(>|$)/g, ''),
                      )
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    setRequestState('error')
                  })
              }}
            >
              Enviar contrato
            </Button>
          ) : null}
          &nbsp;&nbsp;
          <small>
            {requestState === 'processing'
              ? 'Enviando dados, aguarde...'
              : requestState}
          </small>
          {/* <small>{requestState === 'success' ? 'Contrato atualizado com sucesso!' : ''}</small> */}
          <small>
            {requestState === 'error'
              ? 'Ocorreu um erro, tente novamente mais tarde.'
              : ''}
          </small>
        </div>
      </Modal>
    </div>
  )
}

Superlogica.propTypes = {
  companyId: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    id: PropTypes.any,
  }).isRequired,
}

export default Superlogica
