import React from 'react';
import Pagination from 'components/lib/Pagination';
import Table from 'components/lib/Table';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import SectionWrapper from 'components/SectionWrapper';
import Modal from 'components/lib/Modal';
import EmptyState from 'components/EmptyState';
import FlexBar from 'components/lib/FlexBar';
import CreateHeaderFooterTemplate from 'features/CreateHeaderFooterTemplate';
import UpdateHeaderFooterTemplate from 'features/UpdateHeaderFooterTemplate';
import Remove from 'features/Remove';

export default function HeaderFooterModels({ company, moduleRef, headerFooterTemplateItems = [], headerFooterListInfo }) {
  return (
    <SectionWrapper
      header="Modelos de cabeçalho e rodapé"
      headerRight={
        <Button
          onClick={moduleRef.handleFeatureHeaderFooter.bind(moduleRef, <CreateHeaderFooterTemplate activeCompany={company} onSuccess={moduleRef.handleHeaderFooterUpdates.bind(moduleRef)} />)}
          isFlat
          hasThemeColor>
          Criar novo modelo
        </Button>
      }>
      <Table
        items={headerFooterTemplateItems}
        isUpdatingRows={moduleRef.props.isWorking['header-footer-template']}
        cells={[
          {
            name: 'Modelo',
            path: 'name',
          },
        ]}
        actionCell={(row) => (
          <React.Fragment>
            <Button
              onClick={moduleRef.handleUpdateHeaderFooter.bind(
                moduleRef,
                row.id
              )}
              isNeutral
              isFlat>
              <Icon name="fas fa-pen-square" />
            </Button>
            <Button
              onClick={moduleRef.handleFeatureHeaderFooter.bind(
                moduleRef,
                <Remove path={`company/${moduleRef.props.company.id}/header-footer-template/${row.id}`} display={row.name} onSuccess={moduleRef.handleHeaderFooterRemoval.bind(moduleRef, row)} />,
                'delete'
              )}
              isNeutral
              isFlat>
              <Icon name="fas fa-ban" />
            </Button>
          </React.Fragment>
        )}
        emptyState={<EmptyState isLoading={moduleRef.props.isWorking['header-footer-template']} />}
        footer={
          <FlexBar>
            <FlexBar.Child isGrow>
              <Pagination
                current={headerFooterListInfo.currentPage}
                total={headerFooterListInfo.total}
                itemsPerPage={headerFooterListInfo.perPage}
                onChange={moduleRef.handlePaginationHeaderFooter.bind(moduleRef)}
              />
            </FlexBar.Child>
          </FlexBar>
        }
      />
      <Modal isOpen={moduleRef.state.openModalHeaderFooter} onClose={moduleRef.handleModalHeaderFooterClose.bind(moduleRef)} title={moduleRef.state.modalTitleHeaderFooter} isWide responsive={false}>
        {moduleRef.state.featureHeaderFooter}
      </Modal>
    </SectionWrapper>
  );
}
