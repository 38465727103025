import React from 'react';

import Input, { propsObject as defaultProps } from 'components/lib/FormFields/components/Input';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

import fieldCreator from './fieldCreator';

const FormFieldCNPJ = (props) => {

  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Field {...props}>
      <Control {...props}>
        <Input
          {...defaultProps(props)}
          data-spec-selector={props.specSelector || props.name}
          name={props.name}
          isDanger={isValid || props.forceInvalid}
        />
      </Control>
      {props.forceInvalid && props.errorMessage ? <p className="help is-danger">{props.errorMessage}</p> : null}
    </Field>
  );
};

FormFieldCNPJ.defaultProps = {
  label: 'CNPJ',
  specSelector: 'CNPJ',
  name: 'CNPJ'
};

export default fieldCreator(FormFieldCNPJ, {
  masks: ['cnpj'],
  validators: ['cnpj']
});
