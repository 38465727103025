import React from 'react';

// Components
import Table from 'components/lib/Table';
import EmptyState from 'components/EmptyState';
import listWrapper from 'containers/Records/listWrapper';
import withFilter from 'components/HOCs/withFilter';
import moment from 'moment'

import Details from './Details';


const List = (props) => {

  
  props.createFilterRequest(props.onSearch);

  return (
    <Table
      items={props.items}
      cells={[
        {
          name: 'Quando',
          path: 'created_at',
          render: (content, row) => {
            const dateAndTime = row.created_at.split(' ')
            const date = dateAndTime[0]
            const time = dateAndTime[1]
            const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            const statusDate = `${formattedDate} às ${time}`
            return statusDate; }
        },
        {
          name: 'Quem Fez',
          path: 'causer_name',
          render: (content, row) => {
            return row.causer_name
          },
        },
        {
          name: 'Ação',
          patch: 'description',
          render: (content, row) => {
            return row.description
          },
        },
        {
          name: 'Onde',
          patch: 'subject_type_name',
          render: (content, row) => {
            return row.subject_type_name
          },
        },
        {
          name: 'Registro',
          patch: 'subject_name',
          render: (content, row) => {
            return row.subject_name
          },
        },
        {
          name: 'Detalhes',
          patch: 'details',
          width: '30%',
          render: (content, row) => (
            <Details
              data={row}
            />
          )
        },
      ]}
      emptyState={<EmptyState isLoading={props.isWorking} />}
    />
  );
}

List.defaultProps = {
  items: [],
  isWorking: false,
};

export default listWrapper(withFilter(List));
