import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  FormFieldText
} from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import SubmitButton from 'components/SubmitButton';
import formCreator, { fieldProps } from '../components/formCreator';

export class UpdateContract extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>

        {this.props.display ?
          <Grid>
            <Grid.Col>
              Tem certeza que deseja remover: {this.props.display}?
            </Grid.Col>
          </Grid>
          : 'Tem certeza que deseja remover este item?'}

        {this.props.isSafe ?
          <Grid>
            <Grid.Col>
              <FormFieldText
                label="Para prosseguir, digite a palavra REMOVER no campo abaixo"
                placeholder="REMOVER"
                validator={[{ 'equalsTo': 'REMOVER' }]}
                {...fieldProps(this.props, 'safeWord')}
              />
            </Grid.Col>
          </Grid>
          : null}

        <SubmitButton
          label="Remover"
          isDanger
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.submit(this.props.path, null, 'delete');
  }

};

UpdateContract.defaultProps = {
  display: null,
  isSafe: true
};

UpdateContract.propTypes = {
  path: PropTypes.string.isRequired,
  display: PropTypes.string,
  isSafe: PropTypes.bool
};

export default formCreator(UpdateContract);
