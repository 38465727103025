export default (props) => ({
  id: props.id,
  type: props.type || 'text',
  'data-spec-selector': props.specSelector || props.name || props.id,
  name: props.name || props.id,
  autoComplete: props.autoComplete || 'autocomplete-off',
  autoCorrect: props.autoCorrect,
  autoCapitalize: props.autoCapitalize,
  spellCheck: props.spellCheck,
  defaultValue: props.defaultValue,
  value: props.value,
  onClick: props.onClick,
  onChange: props.onChange,
  onKeyPress:props.onKeyPress,
  onKeyDown: props.onKeyDown,
  onKeyUp: props.onKeyUp,
  onInput: props.onInput,
  onFocus: props.onFocus,
  onBlur: props.onBlur,
  placeholder: props.placeholder,
  readOnly: props.readOnly,
  disabled: props.disabled || props.isDisabled,
  style: props.style,
  tabIndex: props.tabIndex,
  required: props.required
});

