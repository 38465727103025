const initialState = {
  current: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_PROPOSAL':
      state = {
        ...state,
        ...{
          current: action.payload,
        },
      };
      break;

    case 'CLEAR_PROPOSAL':
      state = {
        ...state,
        ...{
          current: {},
        },
      };
      break;

    default:
      break;
  }

  return state;
};
