import React from 'react';

import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const Layout = (props) => {

  const layoutCSSClass = `layout ${ parsePropsAsBulmaClasses(props) }`;

  return (
    <div className={layoutCSSClass}>
      {props.children}
    </div>
  );
};

Layout.Header = (props) => {

  const layoutHeaderCSSClass = `layout-header ${ parsePropsAsBulmaClasses(props) }`;

  return (
    <div className={layoutHeaderCSSClass}>
      {props.children}
    </div>
  );
};

Layout.Aside = (props) => {

  const layoutHeaderCSSClass = `layout-aside ${ parsePropsAsBulmaClasses(props) }`;

  return (
    <aside className={layoutHeaderCSSClass}>
      {props.children}
    </aside>
  );
};

Layout.Body = (props) => {

  const layoutBodyCSSClass = `layout-body ${ parsePropsAsBulmaClasses(props) }`;

  return (
    <div className={layoutBodyCSSClass}>
      {props.children}
    </div>
  );
};

Layout.Main = (props) => {

  const layoutMainCSSClass = `layout-main ${ parsePropsAsBulmaClasses(props) }`;

  return (
    <main className={layoutMainCSSClass} role="main">
      {props.children}
    </main>
  );
};

Layout.Footer = (props) => {

  const layoutFooterCSSClass = `layout-footer ${ parsePropsAsBulmaClasses(props) }`;

  return (
    <footer className={layoutFooterCSSClass} role="contentinfo">
      {props.children}
    </footer>
  );
};

export default Layout;
