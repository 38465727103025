export default function (current) {
  if (current.address) {
    current = { ...current.address, ...current };
    current.address = current.address.address;
    current.number = parseInt(current.number);
  }
  if (current.trading_address) {
    let trading_address = {
      trading_address_zip_code: current.trading_address.zip_code,
      trading_address_address: current.trading_address.address,
      trading_address_number: parseInt(current.trading_address.number),
      trading_address_additional_address: current.trading_address.additional_address,
      trading_address_state: current.trading_address.state,
      trading_address_city: current.trading_address.city,
      trading_address_neighborhood: current.trading_address.neighborhood,
    }
    current = { ...trading_address, ...current };
  }
  return current;
}
