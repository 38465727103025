import React from 'react';

import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';

const KeysTimelineStep = ({ icon, label, isAchieved, description, onClickInfo }) => (
  <div className={`keys-timeline-step ${isAchieved ? ' is-achieved' : ''}`}>
    {description && (
      <Button onClick={description && onClickInfo && onClickInfo.bind(null, label, description)} isBare>
        <Icon name="fas fa-info-circle" />
      </Button>
    )}

    <div className="keys-timeline-step-icon">
      <Icon name={`fas fa-lg fa-${isAchieved ? 'check' : icon}`} />
    </div>
    <span className="keys-timeline-step-label">{label}</span>
  </div>
);

export default KeysTimelineStep;
