import React from "react";

import Input, {
  propsObject as defaultProps
} from "components/lib/FormFields/components/Input";
import Field from "components/lib/FormFields/components/Field";
import Control from "components/lib/FormFields/components/Control";
import Icon from "components/lib/Icon";

import fieldCreator from "./fieldCreator";

const FormFieldCEP = props => {
  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Field {...props}>
      <Control {...props} hasIconsLeft>
        <Input
          {...defaultProps(props)}
          data-spec-selector={props.specSelector || props.name}
          name={props.name}
          isDanger={isValid || props.forceInvalid}
          {...props}
        />
        <Icon name="fas fa-map-marker-alt" isLeft />
      </Control>
      {props.forceInvalid && props.errorMessage ? (
        <p className="help is-danger">{props.errorMessage}</p>
      ) : null}
    </Field>
  );
};

FormFieldCEP.defaultProps = {
  label: "CEP",
  specSelector: "CEP",
  name: "CEP"
};

export default fieldCreator(FormFieldCEP, {
  masks: ["cep"],
  validators: ["cep"]
});
