import React from 'react';
import ReactQuill from 'react-quill';

let Embed = ReactQuill.Quill.import('blots/embed');

const startConditionString = 'Início Condição';
const endConditionString = 'Fim Condição';

const startListString = 'Início Lista';
const endListString = 'Fim Lista';

export default class CustomBlot extends Embed {
  static create(propriedades) {
    let node = super.create();
    const id = String(propriedades['data-id']);
    const start = String(propriedades['data-start']);
    const type = String(propriedades['data-type']);
    const attribute = String(propriedades['data-attribute']);
    const name = String(propriedades['data-name']);
    const object = String(propriedades['data-object']);

    node.dataset.id = id;
    node.dataset.start = start;
    node.dataset.type = type;
    node.dataset.attribute = attribute;
    node.dataset.name = name;
    node.dataset.object = object;

    const hasPlural = Boolean(propriedades?.['data-plural']);
    if (hasPlural) {
      node.dataset.plural = String(propriedades['data-plural']);
    }

    const startString = type === 'list' ? startListString : startConditionString;
    const endString = type === 'list' ? endListString : endConditionString;

    node.innerHTML = `${start === 'true' ? startString : endString} ${name}`;
    node.contentEditable = 'false';
    return node;
  }

  static value(domNode) {
    return [].slice.apply(domNode.attributes).reduce((all, { name, value }) => ({ ...all, [name]: value }), {});
  }
}

CustomBlot.blotName = 'operation-blot';
CustomBlot.tagName = 'span';
CustomBlot.className = 'operation-blot';
