import React from 'react';
import ReactTimeout from 'react-timeout';

const defaultOptions = {
  time: 300,
};

const LoadingContainer = (props) => (
  <div
    className={`loading-container ${
      props.isVisible ? 'animate-pulse is-visible' : ''
    }`}
  >
    {props.children}
  </div>
);

export function withLoading(Component, options = defaultOptions) {
  class LoadingContainerWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isVisible: true };
    }

    componentDidMount() {
      this.props.setTimeout(() => {
        this.setState({ isVisible: false });
      }, options.time);
    }

    componentWillUnmount() {
      this.setState({ isVisible: true });
    }

    render() {
      return (
        <React.Fragment>
          <LoadingContainer isVisible={this.state.isVisible}>
            <Component {...this.props} />
          </LoadingContainer>
        </React.Fragment>
      );
    }
  }

  LoadingContainerWrapper.displayName = `LoadingContainerWrapper(${getDisplayName(
    Component
  )})`;

  return ReactTimeout(LoadingContainerWrapper);
}

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export default LoadingContainer;
