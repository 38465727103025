import React from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux'

// Components
import ViewSelector from 'components/ViewSelector'
import ScreenHeader from 'components/ScreenHeader'

// Utils
import { ChildRoutes } from 'utils/RouterUtils'

export class Records extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getSnapshotBeforeUpdate() {
    const currentView = this.props.match.params.view
    if (!currentView) this.props.historyPush('records/property-owners')
    return null
  }

  render() {
    const currentView = this.props.match.params.view

    const views = [
      {
        name: 'Proprietários',
        code: 'property-owners',
        id: 'owners',
      },
      {
        name: 'Imóveis',
        code: 'properties',
        id: 'properties',
      },
      {
        name: 'Clientes',
        code: 'guests',
        id: 'guests',
      },
      {
        name: 'Fiadores',
        code: 'guarantors',
        id: 'guarantors',
      },
    ]

    const { history } = this.props

    const handleGoToRentalsList = () => {
      history.push(history.location.pathname.split('/records')[0] + '/rentals')
    }

    return (
      <React.Fragment>
        <ScreenHeader header='Cadastros básicos' onClick={handleGoToRentalsList}>
          <ViewSelector
            views={views}
            current={currentView}
            onClick={this.handleChangeView.bind(this)}
          />
        </ScreenHeader>

        <ChildRoutes {...this.props} />
      </React.Fragment>
    )
  }

  handleChangeView(view) {
    this.props.historyPush('records/' + view)
  }
}

Records.defaultProps = {}

Records.propTypes = {}

export default connect((store) => ({
  historyPush: store.app.helpers.historyPush,
}))(Records)
