import React from 'react';

import { fieldProps } from 'features/components/formCreator';

import { FormFieldSelect } from 'components/lib/FormFields';
import Header from 'components/lib/Header';
import Grid from 'components/lib/Grid';

import { FormSection } from '../../styles';

const physicalPersonAndResidentialGoalOptions = [
  {
    name: 'Cancelamento de apólice troca de imóvel',
  },
  {
    name: 'Casamento',
  },
  {
    name: 'Funcionário da empresa do retendente',
  },
  {
    name: 'Imóvel próximo de familiares',
  },
  {
    name: 'Independência',
  },
  {
    name: 'Locação adicional',
  },
  {
    name: 'Locação em vigor',
  },
  {
    name: 'Para terceiros',
  },
  {
    name: 'Proximo a instituição de ensino',
  },
  {
    name: 'Proximo ao trabalho',
  },
  {
    name: 'Redução de custo',
  },
  {
    name: 'Renovação com aumento de valores',
  },
  {
    name: 'Separação',
  },
  {
    name: 'Transferência de empresa',
  },
  {
    name: 'Troca de garantia atual locação',
  },
  {
    name: 'Venda de imóvel próprio',
  },
];

const physicalPersonAndComercialGoalOptions = [
  {
    name: 'Abertura de Filial',
  },
  {
    name: 'Sede de empresa',
  },
  {
    name: 'Troca de Garantia',
  },
  {
    name: 'Troca de local de sede',
  },
];

const legalPersonOptions = [
  {
    name: 'Abertura de Filial',
  },
  {
    name: 'Locação para moradia',
  },
  {
    name: 'Troca de Garantia',
  },
  {
    name: 'Troca de local de sede',
  },
];

const Contract = ({ isJuridical, goal, formProps }) => (
  <FormSection>
    <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
      Contrato
    </Header>

    <Grid>
      <Grid.Col>
        <FormFieldSelect
          label="Motivo da locação *"
          options={
            !isJuridical
              ? goal === 'GOAL_RESIDENTIAL'
                ? physicalPersonAndResidentialGoalOptions
                : physicalPersonAndComercialGoalOptions
              : legalPersonOptions
          }
          {...fieldProps(formProps, 'property.reason')}
        />
      </Grid.Col>
    </Grid>
  </FormSection>
);

export default Contract;
