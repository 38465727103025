import React from 'react';
import Text from 'components/lib/Text';
import Icon from 'components/lib/Icon';

const Evaluation = ({ status, comment }) => {

  let statusComponent = <span className="is-warning">Aguardando<Icon name="fas fa-xs fa-hand-rock" /></span>;

  if (status === 0) {
    statusComponent = <span className="is-danger">Recusado<Icon name="fas fa-xs fa-thumbs-down" /></span>;
  }

  if (status === 1) {
    statusComponent = <span className="is-success">Aceito<Icon name="fas fa-xs fa-thumbs-up" /></span>;
  }

  if (status === 2) {
    statusComponent = <span className="">Não participa<Icon name="fas fa-xs fa-hand-rock" /></span>;
  }

  return (
    <React.Fragment>
      {/* <div style={{ borderTop: '1px solid #f2f2f2', margin: '6px 0' }} /> */}
      <Text><strong>Situação:</strong> {statusComponent}</Text>
      {comment && <Text>Motivo: {comment}</Text>}
    </React.Fragment>
  );
};

export default Evaluation;
