import React from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
registerLocale('ptBR', ptBR)

import Field from 'components/lib/FormFields/components/Field'

import fieldCreator from './fieldCreator'

class FormFieldDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
    }
    this.inputContainer = React.createRef()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value?.length === 10) {
      const date = new Date(moment(props.value).format('YYYY/MM/DD'))

      props.onChange({
        target: {
          value: date,
        },
      })

      return {
        selected: date,
      }
    }
    return null
  }

  render() {
    const { isDisabled = false } = this.props

    const isValid =
      this.props.isValid !== null && !this.props.isValid && !this.props.isEmpty

    return (
      <Field {...this.props} hasAddons style={{ width: 141 }}>
        <DatePicker
          locale='ptBR'
          dateFormat='dd/MM/yyyy'
          disabledKeyboardNavigation
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          selected={this.state.selected}
          onChange={this.handleDateChanges.bind(this)}
          className='input-calendar'
          disabled={isDisabled}
          placeholderText='Data'
        />
        {this.props.forceInvalid && this.props.errorMessage ? (
          <p className='help is-danger'>{this.props.errorMessage}</p>
        ) : null}
      </Field>
    )
  }

  handleDateChanges(date) {
    this.setState({
      selected: date,
    })

    this.props.onChange({
      target: {
        value: new Date(moment(date).format('YYYY/MM/DD')),
      },
    })
    this.props.changeDate(moment(date).format('YYYY-MM-DD'))
  }
}

export default fieldCreator(FormFieldDate, {
  // masks: ['date'],
  validators: [{ minLength: 1 }],
})
