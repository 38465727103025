import React from 'react';

import { fieldProps } from 'features/components/formCreator';

import Header from 'components/lib/Header';

import Grid from 'components/lib/Grid';
import { FormFieldText } from 'components/lib/FormFields';

import { FormSection } from '../../styles';

const Property = ({ formProps }) => (
  <FormSection>
    <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>Imóvel</Header>

    {formProps.fields['property.reason']?.value === 'Locação para moradia' && (
      <Grid>
        <Grid.Col>
          <FormFieldText
            label="Nome do real ocupante *"
            {...fieldProps(formProps, 'property.real_occupant_name')}
          />
        </Grid.Col>
        <Grid.Col>
          <FormFieldText
            label="CPF do real ocupante"
            {...fieldProps(formProps, 'property.real_occupant_doc')}
          />
        </Grid.Col>
      </Grid>
    )}

    <Grid>
      <Grid.Col>
        <FormFieldText
          mask={['number']}
          label="Valor da energia"
          {...fieldProps(formProps, 'property.energy_bill')}
        />
      </Grid.Col>

      <Grid.Col>
        <FormFieldText
          mask={['number']}
          label="Valor do água"
          {...fieldProps(formProps, 'property.water_bill')}
        />
      </Grid.Col>

      <Grid.Col>
        <FormFieldText
          mask={['number']}
          label="Valor do gás canalizado"
          {...fieldProps(formProps, 'property.gas_bill')}
        />
      </Grid.Col>
    </Grid>
  </FormSection>
);

export default Property;
