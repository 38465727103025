import React from 'react'

import ReactTimeout from 'react-timeout'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import rollbar from '../../services/tracking/rollbar'
import { ga } from '../../services/firebase'

import { ChildRoutes } from 'utils/RouterUtils'

import SplashScreen, { withSplashScreen } from 'components/lib/SplashScreen'
import SetThemeColor from 'components/SetThemeColor'
import Notification from 'components/Notification'
import MainHeader from './components/MainHeader'
import Layout from 'components/lib/Layout'

import AppOnboarding from './components/AppOnboarding'

import * as actions from './actions'

export class AppWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeUser: null,
      showSplashScreen: true,
      isLeaving: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activeUser !== null && props.isSessionActive) {
      return {
        activeUser: props.activeUser,
        showSplashScreen: state.isLeaving,
      }
    }

    return null
  }

  componentDidMount() {
    ga.logCurrentPage()

    this.props.history.listen(() => {
      ga.logCurrentPage()
    })

    this.props.checkSession()
    this.setUrlCompanyId()
    document.addEventListener('click', () => {
      if (this.props.notification.isOpen) this.props.hideNotification()
    })

    this.props.setHelper('historyPush', this.handleRoutes.bind(this))
    this.props.setHelper('updateActiveUser', this.props.updateActiveUser)
    this.props.setHelper('updateActiveCompany', this.props.updateActiveCompany)
    this.props.setHelper('updateCompanyMeta', this.props.updateCompanyMeta)
    this.props.setHelper('showNotification', this.props.showNotification)
  }

  getSnapshotBeforeUpdate({ companies }) {
    this.props.checkSession()
    if (companies.length !== this.props.companies.length) this.setUrlCompanyId()
    if (this.props.isSessionActive === false) return true

    return null
  }

  componentDidUpdate(prevProps, prevState, sendUserToLogin) {
    if (sendUserToLogin && !this.state.isLeaving) this.handleLogout()
  }

  componentWillUnmount() {
    this.props.clearTimeout()
  }

  render() {
    const hasCompanies = this.props.companies.length > 0

    if (!this.state.activeUser) {
      return <SplashScreen isVisible={this.state.showSplashScreen} showSpinner />
    }

    const { activeUser, activeCompany } = this.props

    try {
      ga.setUserProperties({
        company_name: activeCompany.name,
        company_id: activeCompany.id,
        user_email: activeUser.email,
      })

      ga.setUserId(activeUser.id)
      rollbar.setPerson({
        id: activeUser.id,
        username: activeUser.name,
        email: activeUser.email,
        company: activeCompany.name,
      })

      if (window.Conpass) {
        window.Conpass.init({
          name: activeUser.name,
          email: activeUser.email,
          custom_fields: {
            companyId: activeCompany.id,
            userJoinedAt: moment(activeUser.created_at).toISOString(),
            subscriptionPlan: activeCompany.subscription_plan?.label,
          },
        })
      }

      movideskChatWidgetChangeWindowState('minimized')

      const companyDocument = activeCompany.cpf
        ? activeCompany.cpf.replace(/\./g, '').replace('-', '')
        : activeCompany.cnpj?.replace(/[^\d]+/g, '')

      movideskLogin({
        name: activeUser.name,
        email: activeUser.email,
        organizationCodeReference: companyDocument,
        stayConnected: false,
        emptySubject: false,
        startChat: false,
      })
    } catch (error) {}

    return (
      <Layout>
        <SplashScreen isVisible={this.state.showSplashScreen} showSpinner />

        {this.props.isSessionActive && !hasCompanies ? (
          <AppOnboarding {...this.props} activeUser={this.state.activeUser} />
        ) : null}

        <SetThemeColor color={this.props.companyMeta.themeColor} />

        <Notification
          isOpen={this.props.notification.isOpen}
          isSuccess={this.props.notification.status === 'success'}
          isDanger={this.props.notification.status === 'danger'}
          isWarning={this.props.notification.status === 'warning'}
          onClose={this.props.hideNotification}
        >
          {this.props.notification.message}
        </Notification>

        <MainHeader
          goto={this.handleRoutes.bind(this)}
          activeUser={this.state.activeUser}
          activeCompany={this.props.activeCompany}
          onClickLogout={this.handleLogout.bind(this)}
          onChangeCompany={this.handleActiveCompanyChanges.bind(this)}
        />

        <Layout.Body>
          <Layout.Main>
            {this.props.activeCompany ? (
              <ChildRoutes
                {...this.props}
                parentProps={{
                  activeUser: this.state.activeUser,
                  activeCompany: this.props.activeCompany,
                }}
              />
            ) : null}
          </Layout.Main>
        </Layout.Body>
        <div className='line-footer has-theme-color' />
      </Layout>
    )
  }

  handleRoutes(path) {
    if (!this.state.activeUser && !this.props.activeCompany) return
    const userId = this.state.activeUser.id
    const companyId = this.props.activeCompany.id

    let urlPath = `/app/${userId}/${companyId}/${path}`

    if (path === 'back') {
      this.props.history.goBack()

      return
    }
    if (path === '/') urlPath = `/app/${userId}/${companyId}/rentals`
    this.props.history.push(urlPath)
  }

  handleLogout() {
    this.setState({ showSplashScreen: true, isLeaving: true })

    this.props.destroySession()

    this.props.setTimeout(() => this.props.history.push('/auth'), 1000)

    movideskChatWidgetChangeWindowState('minimized')
  }

  getHomeUrl(company) {
    const recordsHome = company.subscription_plan?.maximum_parallel_processes === 0

    return recordsHome ? '/records/guests' : '/rentals'
  }

  handleActiveCompanyChanges(company) {
    this.setState({ showSplashScreen: true, isLeaving: true })

    this.props.updateUserMeta(
      'lastCompanyId',
      company.id,
      `${location.origin}/app/${this.state.activeUser.id}/${
        company.id
      }${this.getHomeUrl(company)}`,
    )
  }

  setUrlCompanyId() {
    const {
      hasCompanies,
      activeCompany,
      setCompanyById,
      userMeta,
      history,
      companies,
    } = this.props

    if (hasCompanies) {
      const paramsCompanyId = this.props.match.params.companyId

      if (paramsCompanyId) {
        if (!activeCompany) {
          setCompanyById(paramsCompanyId)
        } else if (userMeta?.lastCompanyId) {
          setCompanyById(userMeta.lastCompanyId)

          const activeCompany = companies.find(
            (company) => company.id === userMeta.lastCompanyId,
          )

          history.push(
            `/app/${this.state.activeUser.id}/${paramsCompanyId}${this.getHomeUrl(
              activeCompany,
            )}`,
          )
        }

        return
      }

      setCompanyById(companies[0].id)

      history.push(
        `/app/${this.state.activeUser.id}/${companies[0].id}${this.getHomeUrl(
          companies[0],
        )}`,
      )
    }
  }
}

AppWrapper.defaultProps = {
  isSessionActive: null,
}

AppWrapper.propTypes = { isSessionActive: PropTypes.bool }

export default connect(
  (store) => ({
    isSessionActive: store.app.isSessionActive,
    activeUser: store.app.activeUser,
    companies: store.app.companies,
    hasCompanies: store.app.hasCompanies,
    activeCompany: store.app.activeCompany,
    userMeta: store.app.userMeta,
    companyMeta: store.app.companyMeta,
    notification: store.commons.notification,
  }),
  { ...actions },
)(withSplashScreen(ReactTimeout(AppWrapper), { showSpinner: true, time: 1200 }))
