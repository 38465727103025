import React from 'react';
import PropTypes from 'prop-types';
import { request } from 'core/constants';
// Components

import formCreator, { fieldProps } from '../components/formCreator';
import Button from 'components/lib/Button';

export class StartProcessConfirmation extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>
        <div className="text-center">

        <Button style={{ marginRight: 30 }}
         isSuccess
         specSelector="button-start-process"
         isLoading={formIsWorking}
         isDisabled={!formIsReady || formIsWorking}
         submit>Sim</Button>

        <Button isDanger onClick={this.props.closeModal.bind(this)}>Não</Button>

        </div>

      </React.Fragment>
    );
  }

  createRequest(fields) {

    request
      .post(`{company}/proposal/${this.props.proposalId}/start-process`)
      .then((response) => {
        this.props.closeModal()
        this.props.onSuccess({ isSuccess: true, message: 'Processo iniciado com sucesso!!', data: response.data });
      })
      .catch((error) => {
        this.props.closeModal()
        this.props.onSuccess({ isSuccess: false, message: error.message });
      });
  }

};


export default formCreator(StartProcessConfirmation);
