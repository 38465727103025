import React from 'react';

// Components
import SubmitButton from 'components/SubmitButton';

import formCreator from '../components/formCreator';
import ConfirmPassword from '../components/fieldsGroup/ConfirmPassword';

export class UpdatePassword extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking } = this.props;

    return (
      <React.Fragment>

        <ConfirmPassword {...this.props} />

        <SubmitButton
          label="Alterar senha"
          isLoading={formIsWorking}
          isDisabled={!this.props.formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.submit('user', fields, 'put');
  }

};

UpdatePassword.defaultProps = {
  fields: {}
};

export default formCreator(UpdatePassword);
