import React from 'react'
import { connect } from 'react-redux'

// Components
import { FormFieldText, FormFieldSwitch } from 'components/lib/FormFields'
import Button from 'components/lib/Button'
import FlexBar from 'components/lib/FlexBar'

import formCreator from 'features/components/formCreator'
import { fieldProps } from 'features/components/formCreator'

// Utils
import * as commons from 'utils/UtilityStore/actions'
import integrations from '../../../services/integrations'
import { sanitizeToFloat } from '../../../shared'

export class FichacertaIntegration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.toggleHidden = this.toggleHidden.bind(this)
    this.saveAndSubmit = this.saveAndSubmit.bind(this)
  }

  toggleHidden() {
    const { isEditing } = this.state
    this.setState({
      isEditing: !isEditing,
    })
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this))
  }

  saveAndSubmit() {
    this.props.submitForm()
    return this.toggleHidden()
  }

  render() {
    const { formIsWorking, formIsReady, activeUser } = this.props
    const { isEditing } = this.state
    const userPermission = activeUser.role === 'ROLE_IZEE'

    return (
      <div>
        <FlexBar>
          <FlexBar.Child isGrow>
            <FormFieldSwitch
              {...fieldProps(this.props, 'enabled')}
              description='Habilitar a análise de ficha cadastral com o Ficha Certa'
              // defaultChecked={this.props.defaultValue.enabled}
              // type="checkbox"
              isDisabled={!isEditing}
            />
          </FlexBar.Child>
          <FlexBar.Child isGrow>
            <FormFieldText
              {...fieldProps(this.props, 'creditAnalysisCost')}
              label='Custo da análise'
              mask={['number']}
              isDisabled={!isEditing}
              isHorizontal
              isFullWidth
              isFlat
            />
          </FlexBar.Child>
          <FlexBar.Child isGrow>
            <FormFieldText
              {...fieldProps(this.props, 'creditAnalysisTickets')}
              label='Qtde tickets'
              mask={['onlyNumbers']}
              isDisabled={!isEditing}
              isHorizontal
              isFullWidth
              isFlat
            />
          </FlexBar.Child>
          <FlexBar.Child>
            {!isEditing ? (
              <Button
                hasThemeColor
                onClick={this.toggleHidden}
                isDisabled={!userPermission}
                isHorizontal
                isFlat
              >
                Editar
              </Button>
            ) : (
              <Button
                hasThemeColor
                isLoading={formIsWorking}
                onClick={this.saveAndSubmit}
                isDisabled={formIsWorking || !userPermission}
                isHorizontal
                isFlat
              >
                Salvar
              </Button>
            )}
          </FlexBar.Child>
        </FlexBar>
      </div>
    )
  }

  createRequest(fields) {
    const { activeUser } = this.props
    const userPermission = activeUser.role === 'ROLE_IZEE'

    const companyDocument = this.props.activeCompany.cnpj || this.props.activeCompany.cpf

    if (!userPermission) return

    integrations
      .enable({
        companyId: this.props.activeCompany.id,
        enabled: fields.enabled,
        appName: 'fichacerta',
        companyDocument,
        creditAnalysis: {
          creditAnalysisCost: sanitizeToFloat(fields.creditAnalysisCost),
          creditAnalysisTickets: sanitizeToFloat(fields.creditAnalysisTickets),
        },
      })
      .then(({ success }) => {
        if (!success) throw new Error()
        this.props.notification({
          message: 'Integração configurada com sucesso',
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export default connect((store) => ({}), { ...commons })(
  formCreator(FichacertaIntegration),
)
