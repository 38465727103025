import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Tooltip extends React.Component {

  constructor() {
    super();
    this.state = {
      style: {
        position: 'fixed',
        visibility: 'hidden',
        opacity: 0,
      }
    };
    this.onMouseOutHandler = this.onMouseOutHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener('wheel', this.onMouseOutHandler);
    window.addEventListener('touchmove', this.onMouseOutHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.onMouseOutHandler);
    window.removeEventListener('touchmove', this.onMouseOutHandler);
  }

  onMouseOverHandler() {
    const container = this.tooltipContainer.getBoundingClientRect();
    const tooltip = this.tooltip.getBoundingClientRect();
    this.setState({
      style: {
        position: 'fixed',
        zIndex: 99999999999,
        visibility: 'visible',
        opacity: 1,
        top: ((container.top - tooltip.height)) - 10,
        left: container.left - ((tooltip.width / 2) - (container.width / 2))
      }
    });
  }

  onMouseOutHandler() {
    this.setState({
      style: {
        position: 'fixed',
        visibility: 'hidden',
        opacity: 0,
      }
    });
  }

  render() {
    const { description, direction, children } = this.props;

    const tooltipClasses = classnames([
      'tooltip',
      `tooltip--${direction}`
    ]);


    return (
      <span>
        <span
          className={tooltipClasses}
          onMouseOver={this.onMouseOverHandler.bind(this)}
          onPointerDown={this.onMouseOverHandler.bind(this)}

          onMouseOut={this.onMouseOutHandler.bind(this)}
          onTouchMove={this.onMouseOutHandler.bind(this)}

          ref={(element) => this.tooltipContainer = element}>
          {children}
        </span>
        <span
          className="tooltip__description"
          style={this.state.style}
          ref={(element) => this.tooltip = element}>
          {description}
        </span>
      </span>
    );

  }

};

Tooltip.defaultProps = {
  direction: 'top'
};

Tooltip.propTypes = {};
