import request from 'axios'

const withToken = async (url, body) => {
  try {
    return new Promise((resolve, reject) => {
      request
        .post(url, body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  } catch (error) {
    throw new Error('withToken error: ' + error)
  }
}

const url = CONFIAX_INTEGRATIONS_API_URL

export default {
  confiax: async (body) => {
    try {
      const response = await withToken(`${url}`, body)

      return response
    } catch (error) {
      throw new Error(error)
    }
  },
}
