import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/lib/Button'

import Radium from 'radium'

const styles = {
  imageContainer: {
    width: '100%'
  },
  canvas: {
    display: 'block',
    margin: 'auto'
  }
}

class FilePreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scale: 1,
      rotate: 0
    }
    this.container = React.createRef()
  }

  componentWillUnmount() {
    ;[...document.querySelectorAll('body img.modal-img')].forEach(img => {
      img.remove()
    })
  }

  render() {
    const { source, ext, style } = this.props

    const image = document.createElement('img')
    image.setAttribute('style', 'width: 100%, max-width: 580px; height: auto;')
    image.setAttribute('src', source)
    image.setAttribute('class', 'modal-img')
    image.setAttribute('width', '580')
    document.body.appendChild(image)

    image.onload = () => {
      if (this.container.current) {
        let imgCanvas = this.container.current
        let imgContext = imgCanvas.getContext('2d')

        let size = {}
        let scale = this.state.scale

        if (this.state.rotate === 0)
          size = { w: image.width * scale, h: image.height * scale }
        if (this.state.rotate === 90)
          size = { w: image.height * scale, h: image.width * scale }
        if (this.state.rotate === 180)
          size = { w: image.width * scale, h: image.height * scale }
        if (this.state.rotate === 270)
          size = { w: image.height * scale, h: image.width * scale }

        imgCanvas.width = size.w
        imgCanvas.height = size.h

        imgContext.translate(size.w / 2, size.h / 2)

        imgContext.rotate((this.state.rotate * Math.PI) / 180)
        imgContext.scale(scale, scale) // somar tudo pela escala
        imgContext.drawImage(
          image,
          -(image.width / 2),
          -(image.height / 2),
          image.width,
          image.height
        )

        imgContext.restore()
      }
    }

    const { scale, rotate } = this.state

    return ext.toLowerCase() === 'pdf' ? (
      <iframe
        style={style}
        title='Document'
        width='100%'
        height='600'
        src={`${source}`}
      />
    ) : (
      <>
        <div style={styles.imageContainer}>
          <canvas ref={this.container} style={styles.canvas} />
        </div>
        <div
          className='buttons'
          style={{ justifyContent: 'center', marginTop: 15 }}
        >
          <Button isFlat hasThemeColor onClick={this.rotateFile.bind(this)}>
            Girar
          </Button>
          <Button isFlat hasThemeColor onClick={this.scaleFile.bind(this)}>
            {scale === 1 ? '2X' : '1X'}
          </Button>
        </div>
      </>
    )
  }

  rotateFile() {
    let degree = 0
    if (this.state.rotate === 0) degree = this.state.rotate + 90
    if (this.state.rotate === 90) degree = this.state.rotate + 90
    if (this.state.rotate === 180) degree = this.state.rotate + 90
    if (this.state.rotate === 270) degree = 0
    this.setState({ rotate: degree })
  }

  scaleFile() {
    let scale = 2
    if (this.state.scale === 2) scale = 1
    this.setState({ scale: scale })
  }
}

FilePreview.propTypes = {}

export default Radium(FilePreview)
