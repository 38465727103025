import React, { useState, useEffect } from 'react';

// Components
import Grid from 'components/lib/Grid';

import formCreator from 'features/components/formCreator';
import uploadFile from '../../../../../uploadFile';

export const UploadReceipt = (props) => {
  const [isUpload, setUpload] = useState(false);
  const [progress, setProgress] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [label, setLabel] = useState('Enviar o contrato assinado');

  useEffect(() => {
    props.createRequest(createRequest);
  }, []);

  const progressBar = (
    <strong
      style={{
        color: isUpload ? null : '#39c357',
      }}>
      {progress ? ` (${progress === 100 ? (isUpload ? 'Aguarde...' : 'Upload concluído') : progress + '%'})` : ''}
    </strong>
  );

  const uploadProgress = (event) => {
    let progress = (event.loaded * 100) / event.total;
    setProgress(Math.round(progress));
  };

  const getFile = async (evt) => {
    if (!evt) return;

    setUpload(true);
    setFileName(evt.target.files[0].name);
    setLabel(`Aguarde...`);

    const { companyId } = props;

    const fileId = await uploadFile(evt.target.files[0], uploadProgress, companyId);

    createRequest(fileId);
    setLabel(`Contrato enviado`);
    setUpload(false);

    if (fileId) {
      const success = {
        isSuccess: true,
      };
      return props.onSuccess(success);
    } else {
      const success = {
        isSuccess: false,
      };
      return props.onSuccess(success);
    }
  };

  const createRequest = (fileId) => {
    const { processId } = props;
    const path = `{company}/process/${processId}/contract/upload-signed`;
    props.submit(path, { file_id: fileId });
  };

  return (
    <Grid>
      <Grid.Col>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">
                {label} {isUpload ? (progress ? `${progress} %` : null) : null}
              </label>
              <span className="control">
                <div className="file has-name">
                  <label className="file-label">
                    <input className="file-input" type="file" onChange={getFile} />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload"></i>
                      </span>
                    </span>
                    <span className="file-name">{fileName}</span>
                  </label>
                </div>
              </span>
            </div>
          </div>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default formCreator(UploadReceipt, { submitOnChange: true });
