import React from 'react';
import ReactTimeout from 'react-timeout';

// Redux
import * as actions from './actions';
import withRedux from 'components/redux';
import { ROLE } from 'components/constants';

// Components
import ViewSelector from 'components/ViewSelector';
import ScreenHeader from 'components/ScreenHeader';

// Utils
import { ChildRoutes } from 'utils/RouterUtils';

const forbiddenRoles = [ROLE.PROFILE_INSPECTOR, ROLE.PROFILE_AGENT, ROLE.PROFILE_JURIDICAL];

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.historyPush('settings/members');
    const current = this.getCurrentView();
    if (current) {
      this.handleChangeView(current);
    }
  }

  getSnapshotBeforeUpdate() {
    const currentView = this.props.match.params.view;
    if (!currentView) this.props.historyPush('settings/general');
    return null;
  }

  componentDidUpdate() {
    if (this.props.forbiddenAccess(forbiddenRoles)) {
      this.props.historyPush('/');
    }
  }

  render() {
    const current = this.getCurrentView();
    if (!current) {
      return null;
    }

    const views = this.getViews();

    return (
      <React.Fragment>
        <ScreenHeader header="Configurações da empresa" onClick={this.props.historyPush.bind(this)}>
          <ViewSelector views={views} current={current} onClick={this.handleChangeView.bind(this)} />
        </ScreenHeader>

        <div style={{position: 'relative', flex: 1, overflowY: 'hidden', overflowX: 'hidden', display: 'flex', flexDirection: 'column'}}>
          <ChildRoutes {...this.props} />
        </div>

      </React.Fragment>
    );
  }

  handleChangeView(view) {
    this.props.historyPush('settings/' + view);
  }

  getCurrentView() {
    const currentView = this.props.match.params.view;
    const views = this.getViews();
    if (!views.length) {
      return null;
    }
    return views.find((view) => view.code === currentView) ? currentView : views[0].code;
  }

  getViews = () => {
    const { forbiddenAccess, activeUser } = this.props;
    const subscriptionPlan = this.props.company?.subscription_plan


    if (forbiddenAccess(forbiddenRoles)) {
      return [];
    }

    return [
      {
        name: 'Geral',
        code: 'general',
        hidden: forbiddenAccess([ROLE.PROFILE_MANAGER]),
      },
      {
        name: 'Avançado',
        icon: (subscriptionPlan && !subscriptionPlan?.can_access_advanced) ? 'fas fa-star is-warning' : '',
        code: 'advanced',
        hidden: forbiddenAccess([ROLE.PROFILE_MANAGER])
      },
      {
        name: 'Usuários',
        code: 'members',
      },
      {
        name: 'Integrações',
        icon: (subscriptionPlan && !subscriptionPlan?.can_access_integrations) ? 'fas fa-star is-warning' : '',
        code: 'integrations',
        hidden: forbiddenAccess([ROLE.PROFILE_MANAGER]),
      },
      {
        name: 'Assinatura',
        code: 'billing',
        hidden: forbiddenAccess([ROLE.PROFILE_MANAGER]),
      },
      {
        name: 'Atividades',
        code: 'logs',
        hidden: forbiddenAccess([ROLE.PROFILE_MANAGER]),
      },
    ].filter((view) => !view.hidden);
  };
}

Settings.defaultProps = {};

Settings.propTypes = {};

export default withRedux(actions)(ReactTimeout(Settings));
