import Surveys from './containers/Surveys';
import Analysis from './containers/Analysis';

const routes = [
  {
    name: 'Surveys',
    path: '/app/:userId/:companyId/services/surveys',
    component: Surveys,
  },
  {
    name: 'Analysis',
    path: '/app/:userId/:companyId/services/analysis',
    component: Analysis,
  }
];

export default routes;
