import React from 'react'

import ModalPlanRestrictionSVG from '../../assets/svgs/ModalPlanRestriction'

import { ga } from '../../../../izee-app/src/services/firebase'

import withRedux from 'components/redux'
import Text from 'components/lib/Text'

import { Container, Modal, BackButton, Button } from './styles'

const redirectUrl =
  'https://www.redevistorias.com.br/unificacao/?redirectApp=redeIzee'

const ModalPlanRestriction = ({ activeUser, activeCompany }) => {
  const handleClick = () => {
    window.open(redirectUrl, '_blank')

    ga.logEvent('change_plan', {
      company_name: activeCompany.name,
      company_plan: activeCompany.subscription_plan?.label,
      user_email: activeUser.email,
      user_name: activeUser.name,
    })
  }

  return (
    <Container>
      <Modal>
        <BackButton onClick={() => history.back()}>{'< voltar'}</BackButton>

        <ModalPlanRestrictionSVG />
        <br />
        <br />
        <br />
        <Text>
          <strong>Sua imobiliária 100% digital!</strong>
        </Text>
        <Text>
          Esteira Digital de Locação e Venda, Digitalização e Captação de Cadastros,
          Editor de Contratos, Integrações, Garantias Personalizadas, Assinatura
          Eletrônica.
        </Text>
        <Text>E muito mais!</Text>
        <Button onClick={handleClick}>Quero um plano completo</Button>
      </Modal>
    </Container>
  )
}

export default withRedux((state) => ({
  activeUser: state.app.activeUser,
  activeCompany: state.app.activeCompany,
}))(ModalPlanRestriction)
