import React from 'react';
import Grid from '../../components/lib/Grid';
import { FormFieldTokenSelector } from '../../components/lib/FormFields';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import CustomBlot from './CustomBlot';
import MergeBlot from './MergeBlot';
import rawHtmlStringToBlade from './rawHtmlStringToBlade';

export default class EditorWithTokens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.props?.defaultValue?.template || '',
    };
    this.editorRef = null;

    const AlignStyle = ReactQuill.Quill.import('attributors/style/align');
    const BackgroundStyle = ReactQuill.Quill.import('attributors/style/background');
    const ColorStyle = ReactQuill.Quill.import('attributors/style/color');
    const DirectionStyle = ReactQuill.Quill.import('attributors/style/direction');
    const FontStyle = ReactQuill.Quill.import('attributors/style/font');
    const SizeStyle = ReactQuill.Quill.import('attributors/style/size');

    SizeStyle.whitelist = ['8px', '9px', '10px', '11px', '12px', '14px', '18px', '24px', '30px', '36px'];

    ReactQuill.Quill.register(AlignStyle, true);
    ReactQuill.Quill.register(BackgroundStyle, true);
    ReactQuill.Quill.register(ColorStyle, true);
    ReactQuill.Quill.register(DirectionStyle, true);
    ReactQuill.Quill.register(FontStyle, true);
    ReactQuill.Quill.register(SizeStyle, true);
    ReactQuill.Quill.register(CustomBlot, true);
    ReactQuill.Quill.register(MergeBlot, true);

    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }],
        [{ header: 1 }, { header: 2 }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        [{ size: ['8px', '9px', '10px', '11px', '12px', '14px', '18px', '24px', '30px', '36px'] }],
        [{ font: [] }],
        ['image'],
      ],
    };
    this.formats = ['header', 'font', 'background', 'color', 'size', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'script', 'align', 'operation-blot', 'merge-blot', 'image'];
  }

  componentDidMount() {
    const editorContent = this.state.editorState;

    if (this.props.handleFieldChanges) {
      this.props.handleFieldChanges('meta', editorContent, {
        isValid: true,
        value: editorContent,
      });
      this.props.handleFieldChanges('template', rawHtmlStringToBlade(editorContent), {
        isValid: true,
        value: rawHtmlStringToBlade(editorContent),
      });
    }
  }

  render() {
    return (
      <div className="contract-editor">
        <Grid>
          <Grid.Col isTwoThirds>
            <div className="editor-wrapper">
              <ReactQuill
                theme="snow"
                tabIndex={0}
                ref={(editor) => (this.editorRef = editor)}
                defaultValue={this.state.editorState}
                placeholder={''}
                onChange={this.handleEditorChanges.bind(this)}
                modules={this.modules}
                formats={this.formats}
              />
            </div>
          </Grid.Col>
          <Grid.Col>
            <FormFieldTokenSelector
              editorRef={this.editorRef}
              info="Selecione as variáveis que serão substituídas por informações."
              list={this.props.tokenList}
              shouldInsertRawValue={this.props.shouldInsertRawValue}
            />
          </Grid.Col>
        </Grid>
      </div>
    );
  }

  handleEditorChanges(editorState) {
    this.setState({ editorState });

    if (this.props.handleFieldChanges) {
      this.props.handleFieldChanges('meta', editorState, {
        isValid: true,
        value: editorState,
      });
      this.props.handleFieldChanges('template', rawHtmlStringToBlade(editorState), {
        isValid: true,
        value: rawHtmlStringToBlade(editorState),
      });
    }
  }
}
