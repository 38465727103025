import React from "react";
import PropTypes from "prop-types";

import parsePropsAsBulmaClasses from "components/lib/_utils/parsePropsAsBulmaClasses";
import { setFormInitialFocus } from "utils/utils";

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.getRef(this.form.current);
    this.setInitialFocus();
  }

  handleKeyPress(e) {
    const keys = {
      ENTER: "Enter"
    };
    // console.log("keypress", e.key === keys.ENTER);
    // console.log(e.target.nodeName != "TEXTAREA");
    (e.key === keys.ENTER && e.target.nodeName != "TEXTAREA") && (e.key === keys.ENTER && e.view.location.pathname != '/auth') ? e.preventDefault() : null;
  }

  render() {
    const formCSSClass = `form ${parsePropsAsBulmaClasses(this.props)}`;

    return (
      <form
        id={this.props.id}
        data-spec-selector={
          this.props.specSelector || this.props.name || this.props.id
        }
        name={this.props.name || this.props.id}
        ref={this.form}
        onSubmit={event => {
          event.preventDefault();
          this.props.onSubmit ? this.props.onSubmit(event) : null;
        }}
        onFocus={event => {
          this.props.onFocus ? this.props.onFocus(event) : null;
        }}
        onKeyPress={e => this.handleKeyPress(e)}
        className={formCSSClass}
        autoComplete="false"
      >
        {this.props.children}
      </form>
    );
  }

  setInitialFocus() {
    const form = this.form;
    if (this.props.autoFocus) setFormInitialFocus(form.current);
  }
}

Form.defaultProps = {
  getRef: () => {}
};
