import React from 'react';
import Text from 'components/lib/Text';

class Name extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isTypeJuridical = this.props.cnpj ? true : false;
    const isMain = this.props.main;

    return (
      <React.Fragment>
        {isTypeJuridical ? (
          <div>
            <Text>
              <strong>
                <span style={{ fontSize: 14, lineHeight: '24px' }}>
                  {this.props.corporate_name} {isMain && '(Principal)'}
                </span>
              </strong>
              &nbsp;&nbsp;{this.props.children}
            </Text>
            <Text>CNPJ: {this.props.cnpj}</Text>
            <Text>Nome Fantasia: {this.props.trading_name}</Text>
            <Text>Cadastro Municipal: {this.props.municipal_enrollment}</Text>
            <Text>Cadastro Estadual: {this.props.state_enrollment}</Text>
            <div className="section-identifier mt-3 mb-2">
              <h1 className="form-section-title">Dados do Responsável</h1>
            </div>
            <Text>Nome: {this.props.name}</Text>
          </div>
        ) : (
          <div>
            <Text>
              <strong>
                <span style={{ fontSize: 14, lineHeight: '24px' }}>
                  {this.props.name} {isMain && '(Principal)'}
                </span>
              </strong>
              &nbsp;&nbsp;{this.props.children}
            </Text>
          </div>
        )}
      </React.Fragment>
    );
  }
}

// const Name = (props) => {

//   const isMain = props.main;

//   const hasCallback = props.onClickResend;
//   const hasToken = props.onClickCopy;

//   const formatedName = (
//     <strong>
//       <span style={{ fontSize: 14 }}>{props.name} {isMain && '(Principal)'}</span>
//     </strong>
//   );

//   // const nameComponent = hasCallback ? <ButtonWrapper onClick={props.onClickResend} value="(Reenviar)">{formatedName}</ButtonWrapper> : formatedName;
//   const nameComponent = formatedName;
//   const copyComponent = hasToken ? <ButtonWrapper onClick={props.onClickCopy} title={props.title} value="(Copiar Link)" message=" Copiado!"></ButtonWrapper> : ''

//   return (
//     <Text>{nameComponent}&nbsp;{copyComponent}&nbsp;{props.children}</Text>
//   );
// };

export default Name;
