import React from 'react';

// Components
import { FormFieldCheckbox } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Button from 'components/lib/Button';

import formCreator from 'features/components/formCreator';

export class ApproveGuarantee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementIsClosed: false,
    };
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;
    const submitIsDisabled = !formIsReady || formIsWorking || !this.state.requirementIsClosed;

    return (
      <React.Fragment>
        <FormFieldCheckbox description="Declaro que o usuário está apto para prosseguir com a locação do imóvel." onChange={this.acceptTerms.bind(this)} />
        <Button hasThemeColor isLoading={formIsWorking} isDisabled={submitIsDisabled} submit>
          Aprovar garantia
        </Button>
      </React.Fragment>
    );
  }

  acceptTerms(value, field) {
    this.setState({
      requirementIsClosed: value,
    });
  }

  createRequest(fields) {
    const { processId } = this.props;
    this.props.submit(`{company}/process/${processId}/guarantee/validate-guarantee`, { guarantee_evaluation: true });
  }
}

export default formCreator(ApproveGuarantee);
