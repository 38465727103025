import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f3f6fa;

  position: absolute;
  inset: 0;
  z-index: 99999999;

  display: flex;
  align-items: center;
  justify-content: center;

  /* SET FONT */
  font-family: 'Roboto';
  font-weight: 300;

  & * .checkbox input:checked ~ .checkbox-token {
    background-color: #2091ea;
    border: none;
  }
  & * input:checked ~ .switch-slider {
    background-color: #2091ea;
    border: none;
  }

  & * button {
    font-family: 'Roboto' !important;
    font-weight: 300 !important;
  }

  @media (min-width: 600px) {
    padding: 40px;
  }

  @media (min-width: 800px) {
    padding: 80px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;

  padding: 42px 90px;

  text-align: center;

  border-radius: 4px;

  background-color: white;

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  @media (max-width: 600px) {
    height: 100%;

    gap: 45px;
  }
`;

export const Text = styled.span`
  font-size: 18px;
`;
