import React from 'react'
import Icon from 'components/lib/Icon'
import ProcessTask from 'services/ProcessTask'

export default function (tabs, requirements) {
  tabs.map((item) => {
    let status = false

    switch (item.code) {
      case 'proposal':
        item.name = (
          <span>
            Proposta &nbsp;
            <Icon name='far fa-xs fa-check-circle' />
          </span>
        )
        break
      case 'overview':
        status = ProcessTask(requirements, 'REQUIREMENT_OVERVIEW').status
        if (status)
          item.name = (
            <span>
              Análise &nbsp;
              <Icon name='far fa-xs fa-check-circle' />
            </span>
          )
        break
      case 'guarantee':
        status = ProcessTask(requirements, 'REQUIREMENT_GUARANTEE').status
        if (status)
          item.name = (
            <span>
              Garantia &nbsp;
              <Icon name='far fa-xs fa-check-circle' />
            </span>
          )
        break
      case 'juridical':
        status = ProcessTask(requirements, 'REQUIREMENT_JURIDICAL').status
        if (status)
          item.name = (
            <span>
              Jurídico &nbsp;
              <Icon name='far fa-xs fa-check-circle' />
            </span>
          )
        break
      case 'contract':
        status = ProcessTask(requirements, 'REQUIREMENT_CONTRACT').status
        if (status)
          item.name = (
            <span>
              Contrato &nbsp;
              <Icon name='far fa-xs fa-check-circle' />
            </span>
          )
        break
      case 'survey':
        status = ProcessTask(requirements, 'REQUIREMENT_INSPECTION').status
        if (status)
          item.name = (
            <span>
              Vistoria &nbsp;
              <Icon name='far fa-xs fa-check-circle' />
            </span>
          )
        break
      case 'keys':
        status = ProcessTask(requirements, 'REQUIREMENT_POSSESSION').status
        if (status)
          item.name = (
            <span>
              Posse &nbsp;
              <Icon name='far fa-xs fa-check-circle' />
            </span>
          )
        break
    }

    return item
  })

  return tabs
}
