import React, { useState, useEffect } from 'react';
import { Callout } from '@blueprintjs/core';

import { request } from 'core/constants';

// Components
import { FormFieldSwitch } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Text from 'components/lib/Text';
import Space from 'components/lib/Space';
import Button from 'components/lib/Button';

import formCreator from 'features/components/formCreator';

import SignersList from './SignersList';
import UpdatedAt from './UpdatedAt';

import { deepKey, copyToClipboard } from 'utils/utils';

export const Settings = (props) => {
  const { proposal } = props;

  const [signMethod, setSignMethod] = useState();
  const [defaultSign, setDefaultSign] = useState();
  const [hasFormErrors, setFormErrors] = useState(false);
  const [signers, setSigners] = useState([{ name: '', email: '', is_witness: '', signs_for_owner: false, cpf: '' }]);

  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const signMethod = proposal.contract.sign_method;
    setDefaultSign(signMethod);
    setSignMethod(signMethod);

    const {
      contract: { signers },
    } = proposal;

    setSigners(signers);
  }, [proposal]);

  const chooseSignMethod = (method) => {
    setSignMethod(method);
  };

  const updateSigners = (field, value, index) => {
    const updatedSigners = [...signers];

    updatedSigners[index][field] = value;
    setSigners(updatedSigners);
  };


  const addNewSigner = () => {
    setSigners((oldSigners) => [...oldSigners, { name: '', email: '', is_witness: '', signs_for_owner: false, cpf: '' }]);
  };

  const copyLink = (item) => {
    const href = deepKey(item, 'signature_link');
    if (href) {
      copyToClipboard(href);
      return props.notification({
        message: 'Link de assinatura copiado para a área de transferência',
        status: 'success',
      });
    } else {
      return props.notification({
        message: 'Link de assinatura indisponível',
        status: 'danger',
      });
    }
  };

  const removeSigner = (index) => {
    if (signers.length === 1) {
      return;
    }
    const updatedSigners = [...signers];
    updatedSigners.splice(index, 1);
    setSigners(updatedSigners);
    setError();
  };

  const createRequest = async () => {
    try {
      const { processId, onSuccess } = props;

      const data = {
        owners_sign: false,
        sign_method: signMethod,
        signers,
      };

      const endpoint = `{company}/process/${processId}/contract/define-sign-method`;

      setError();
      setRequesting(true);
      await request.post(endpoint, data);
      onSuccess({ isSuccess: true });
    } catch (error) {
      setError(error);
    } finally {
      setRequesting(false);
    }
  };

  const { formIsWorking, formIsReady, isClosed } = props;
  const submitIsDisabled = (!formIsReady || formIsWorking || !signMethod || hasFormErrors) && signMethod !== 'PRINT';

  return (
    <>
      <Text>Enviar contrato para assinatura utilizando:</Text>

      <Space />

      <Grid>
        <Grid.Col isNarrow>
          <FormFieldSwitch
            id="autentique"
            name="sign_method"
            type="radio"
            description="Assinatura eletrônica"
            checked={signMethod === 'AUTENTIQUE'}
            isDisabled={isClosed}
            onClick={() => chooseSignMethod('AUTENTIQUE')}
          />
          <FormFieldSwitch
            id="download"
            name="sign_method"
            type="radio"
            description="Contrato físico"
            checked={signMethod === 'PRINT'}
            isDisabled={isClosed}
            onClick={() => chooseSignMethod('PRINT')}
          />
        </Grid.Col>
      </Grid>

      <Space />

      <UpdatedAt signers={proposal.contract.signers ? proposal.contract.signers[0] : null} defaultSign={defaultSign} signMethod={signMethod} />

      <Space />

      <Text>Quem deverá assinar o contrato? Adicione nome e email:</Text>

      <Space />

      <SignersList
        disabled={submitIsDisabled}
        signers={signers}
        updateSigners={updateSigners}
        removeSigner={removeSigner}
        addNewSigner={addNewSigner}
        errors={error?.errors || []}
        copyLink={copyLink}
      />

      <Grid>
        {!isClosed && (
          <Grid.Col isRight>
            <Grid.Col>
              <Button hasThemeColor isLoading={formIsWorking || requesting} isDisabled={submitIsDisabled} onClick={createRequest}>
                Enviar Contrato
              </Button>
            </Grid.Col>
          </Grid.Col>
        )}
      </Grid>

      {error && <Callout intent="danger">{error.message}</Callout>}
    </>
  );
};

export default formCreator(Settings);
