import { getTypeOf } from 'utils/utils';

export default (errors) => {
  if (!errors && getTypeOf(errors) !== 'Array' || errors.length < 1) return {};

  if (getTypeOf(errors) !== 'Array') return {};

  let errorsObject = {};

  errors.forEach((item) => {
    errorsObject[item.field] = item.message;
  });

  return errorsObject;

};
