import styled from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-bottom: 7px;

  & > div {
    flex: 50%;
  }

  & > div:nth-child(2n - 1) {
    padding-right: 7px;
  }

  & > div:nth-child(2n) {
    padding-left: 7px;
  }

  @media (max-width: 600px) {
    flex-wrap: initial;
    flex-direction: column;
    align-items: initial;

    & > div:nth-child(2n - 1) {
      padding-right: 0px;
    }

    & > div:nth-child(2n) {
      padding-left: 0px;
    }
  }
`;
