import React, { Component } from 'react';

import { currency, date } from "utils/utils";


class Details extends Component {

  constructor(props) {
    super(props);
  }

  formatData(item) {

    switch (item.attribute) {

      case 'ownership_date':
        return date(item.value)

      case 'birth_date':
        return date(item.value)

      case 'created_at':
        return date(item.value)

      case 'rent_value':
        return currency(item.value);

      case 'income':
        return currency(item.value);

      case 'spouse_is_student':
        return item.value === 0 ? 'Não' : 'Sim';

      default:
        return item.value
    }
  }

  render() {
    const { data } = this.props
    const values = []

    switch (data.code) {

      case 'CREATED':
        data.details.new?.forEach((item) => {
          values.push(<span><strong>{item.name}:</strong> {this.formatData(item)} <br /> </span>)
        })
        break;

      case 'UPDATED':
        data.details.new?.forEach((item, index) => {
          values.push(<span><strong>{data.details.old[index].name}</strong>: {this.formatData(data.details.old[index])} <strong>Para:</strong> {this.formatData(item)} <br /> </span>)
        })
        break;

      case 'DELETED':
        data.details.old?.forEach((item) => {
          values.push(<span><strong>{item.name}:</strong> {this.formatData(item)} <br /> </span>)
        })
        break;

      case 'REQUESTED_CONTRACT_SIGNATURE':
        data.details.signers?.forEach((item) => {
          item.forEach((atributo) => {
            values.push(<span><strong>{atributo.name}:</strong> {this.formatData(atributo)} <br /> </span>)
          })
        })
        break;

      case 'REQUESTED_INSPECTION':
        data.details.inspection?.forEach((item) => {
          values.push(<span><strong>{item.name}:</strong> {this.formatData(item)} <br /> </span>)
        })
        data.details.property?.forEach((item) => {
          values.push(<span><strong>{item.name}:</strong> {this.formatData(item)} <br /> </span>)
        })
        break;

      case 'REQUESTED_CREDIT_ANALYSIS':
        data.details.clients?.forEach((item) => {
          item.forEach((atributo) => {
            values.push(<span><strong>{atributo.name}:</strong> {this.formatData(atributo)} <br /> </span>)
          })
        })
        data.details.guarantors?.forEach((item) => {
          item.forEach((atributo) => {
            values.push(<span><strong>{atributo.name}:</strong> {this.formatData(atributo)} <br /> </span>)
          })
        })
        break;

      case 'REQUESTED_TO_COMPLETE_REGISTER':
        data.details.forEach((item) => {
          values.push(<span><strong>{item.name}:</strong> {this.formatData(item)} <br /> </span>)
        })
        break;

      case 'REQUESTED_DOCUMENTS':
        data.details.Documentos?.forEach((item) => {
          values.push(<span> {item} <br /> </span>)
        })
        break;
    }

    return values

  }

};

export default Details;
