import React from 'react';

import ContentEditable from 'react-contenteditable';

import { Container, Modal, Button } from './styles';

const TermModal = ({ content, setShowTerms }) => (
  <Container onClick={() => setShowTerms('')}>
    <Modal onClick={(e) => e.stopPropagation()}>
      <ContentEditable html={content} disabled={true} />

      <Button onClick={() => setShowTerms('')}>Concluir</Button>
    </Modal>
  </Container>
);

export default TermModal;
