import React from 'react';

// Components
import { FormFieldText } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

import formCreator from 'features/components/formCreator';
import { fieldProps } from 'features/components/formCreator';

import withFilePreview from 'components/HOCs/withFilePreview';

export class UploadSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  render() {
    const { formIsWorking, formIsReady, defaultValue } = this.props;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <FormFieldText {...fieldProps(this.props, 'file_ref')} isDisabled={this.props.isClosed} />
            {/* )} */}
          </Grid.Col>
          {!this.props.isClosed && (
            <Grid.Col isNarrow>
              <Button hasThemeColor isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} submit>
                Enviar
              </Button>
            </Grid.Col>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  openLink(ref) {
    const newTab = window.open(ref, '_blank');
    newTab.focus();
  }

  createRequest(fields) {
    const { processId } = this.props;
    this.props.submit(`{company}/process/${processId}/inspection/upload`, {
      file_ref: fields['file_ref'],
    });
  }
}

export default formCreator(withFilePreview(UploadSurvey));
