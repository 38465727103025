import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

// Components
import { FormFieldSwitch } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import { FormFieldSwitchContainer } from './styles'

import { baseURL } from 'core/constants'
import request from 'axios'

const ToggleRequirement = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [isConsultant, setConsultant] = useState(false)

  useEffect(() => {
    const profiles = props.activeCompany.profiles

    profiles.map((profile) =>
      profile.code === 'PROFILE_AGENT' ? setConsultant(true) : null,
    )
  }, [])

  const createRequest = async () => {
    setLoading(true)

    const { processId, requirement, companyId } = props
    const url = `${baseURL}company/${companyId}/process/${processId}/${requirement}/toggle-requirement`
    const response = await request.post(url)

    setLoading(false)

    if (response.success) {
      const success = {
        isSuccess: true,
      }
      return props.onSuccess(success)
    } else {
      const success = {
        isSuccess: false,
      }
      return props.onSuccess(success)
    }
  }

  const { requirementStatus } = props

  return (
    <Grid>
      <FormFieldSwitchContainer>
        <FormFieldSwitch
          id='requirement_closed'
          name='requirement_closed'
          type='radio'
          description='Etapa encerrada?'
          checked={requirementStatus}
          onClick={() => createRequest()}
          isDisabled={isLoading || isConsultant}
        />
      </FormFieldSwitchContainer>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  activeCompany: state.app.activeCompany,
  activeUser: state.app.activeUser,
})

export default connect(mapStateToProps)(ToggleRequirement)
