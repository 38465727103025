import React, { Component, Fragment } from 'react'
import DocumentName from './DocumentName'
import MessageBox from './MessageBox'
import EmailButtons from './EmailButtons'
import request from 'axios'
import { baseURL } from 'core/constants'
import * as types from '../../types'

class RequestByEmail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      copied: false,
      sentEmail: false,
      emailIsWorking: false,
      copyIsWorking: false,
      url: '',
    }
  }

  handleMessage(message) {
    this.setState({ message })
  }

  copyToClipboard(str, identifier, whatsapp) {
    const stringSplit = str.split('/')
    const userHash = stringSplit[stringSplit.length - 1]

    let url = ''
    const { fullForm } = this.props

    fullForm
      ? (url = `${IZEE_PROCESS_URL}public-edit/${userHash}/${identifier}`)
      : (url = `${IZEE_PROCESS_URL}public-edit/${userHash}/true/${identifier}`)
    // const url = `http://localhost:8081/public-edit/${userHash}/true/${identifier}`;

    const el = document.createElement('textarea')

    el.value = url
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    whatsapp ? this.setState({ url: url }) : this.setState({ copied: true })
  }

  publicLink(whatsapp) {
    const { userId, companyId, entityName } = this.props

    whatsapp == null || undefined ? (whatsapp = false) : null
    let entity = entityName

    let identifier = null

    whatsapp ? null : this.setState({ copyIsWorking: true })

    if (entityName === 'propertyOwner') entity = 'property-owner'

    switch (entityName) {
      case 'proposal':
        identifier = 1
        break
      case 'property':
        identifier = 2
        break
      case 'propertyOwner':
        identifier = 3
        break
      case 'client':
        identifier = 4
        break
      case 'guarantor':
        identifier = 5
        break
    }

    return new Promise((resolve, reject) => {
      request
        .get(`${baseURL}company/${companyId}/${entity}/link/${userId}`)
        .then((response) => {
          resolve(this.copyToClipboard(response.sucesso, identifier, whatsapp))
          this.setState({ copyIsWorking: false })
        })
        .catch((error) => {
          reject('Algo deu errado ', error)
          this.setState({ copyIsWorking: false })
        })
    })
  }

  sendEmail() {
    const { fullForm } = this.props

    this.setState({ emailIsWorking: true })

    if (!fullForm) {
      const { entityName } = this.props

      let entity = entityName

      if (entityName === 'propertyOwner') entity = 'property-owner'

      const url = `${baseURL}register/${entity}/email`

      const { userId, documents } = this.props
      const docsNames = []

      documents.map((document) => {
        if (document.status === types.STATUS_APPROVED) return
        if (document.status === types.STATUS_ANALYSIS) return
        docsNames.push(document.name)
      })

      const formData = {
        owner_id: userId,
        documents: docsNames,
      }

      return new Promise((resolve, reject) => {
        request
          .post(url, formData)
          .then((response) => {
            console.log(response)
            this.setState({ sentEmail: true, emailIsWorking: false })
          })
          .catch((error) => {
            console.log(error)
            this.setState({ sentEmail: false, emailIsWorking: false })
          })
      })
    } else {
      // console.log("props[EMAIL]", this.props);
      const { updateForm, requestForm, companyId, userId, entityName } = this.props

      let url = ''

      let entityNumber = ''

      switch (entityName) {
        case 'propertyOwner':
          entityNumber = 3
          break
        case 'client':
          entityNumber = 4
          break
        case 'guarantor':
          entityNumber = 5
          break
      }

      if (updateForm) {
        url = `${baseURL}company/${companyId}/request/update/entity/${entityNumber}/send-email`
      } else if (requestForm) {
        url = `${baseURL}company/${companyId}/request/register/entity/${entityNumber}/send-email`
      }

      const data = {
        owner_id: userId,
      }

      return new Promise((resolve, reject) => {
        request
          .post(url, data)
          .then((response) => {
            console.log(response)
            this.setState({ sentEmail: true, emailIsWorking: false })
          })
          .catch((error) => {
            console.log(error)
            this.setState({ sentEmail: false, emailIsWorking: false })
          })
      })
    }
  }

  handleClick(action) {
    switch (action) {
      case 'request':
        return this.sendEmail()
      case 'link':
        return this.publicLink()
      case 'whatsapp':
        let whatsapp = true

        return this.publicLink(whatsapp)
    }
  }

  render() {
    const { documents, title } = this.props
    const { copied, sentEmail, copyIsWorking, emailIsWorking } = this.state

    let pageWidth = document.body.offsetWidth

    let confirmationTrue = this.props.confirmationTrue

    confirmationTrue == undefined || null
      ? (confirmationTrue = false)
      : (confirmationTrue = true)

    return (
      <Fragment>
        <div className='email_title'>{title}</div>
        <div className='docs_name'>
          <DocumentName documents={documents} />
        </div>
        {this.props.noMessagebox ? null : (
          <div className='message_box'>
            <MessageBox
              style={{ fontSize: 16, color: '#5F6776' }}
              labelStyle={{ color: '#5F6776', fontWeight: 'bold' }}
              title='Mensagem :'
              message={this.handleMessage.bind(this)}
            />
          </div>
        )}
        <div className='actions-buttons'>
          <EmailButtons
            copyIsWorking={copyIsWorking}
            emailIsWorking={emailIsWorking}
            confirmation={confirmationTrue}
            click={this.handleClick.bind(this)}
            copied={copied}
            sentEmail={sentEmail}
          />
        </div>
        {pageWidth < 768 ? (
          <div className='container-whatsapp'>
            <a
              onClick={() => this.handleClick('whatsapp')}
              className='whatsapp-button'
              href={'whatsapp://send?text=' + this.state.url}
              data-action='share/whatsapp/share'
            >
              Compartilhar via WhatsApp
            </a>
          </div>
        ) : null}
      </Fragment>
    )
  }
}

export default RequestByEmail
