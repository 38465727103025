import styled from 'styled-components';

export const AddButtonWrapper = styled.div`
  display: flex;
  flex-basis: 18%;
  align-items: center;
  justify-content: center;

  margin-left: 8px;
  padding: 0px 8px;

  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);

  text-align: center;

  cursor: pointer;
`;

export const AddButtonText = styled.span`
  font-size: 14px;
  color: #7f8996;
`;
