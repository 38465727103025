import React from 'react'

// Components
import { FormFieldText, FormFieldSwitch } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import { fieldProps } from '../formCreator'

const UserInvite = props => (
  <React.Fragment>
    <Grid>
      <Grid.Col>
        <FormFieldText label='Nome' {...fieldProps(props, 'name')} />
      </Grid.Col>
    </Grid>
    <Grid>
      <Grid.Col>
        <FormFieldText label='Email' {...fieldProps(props, 'email')} />
      </Grid.Col>
    </Grid>
    {/* <Grid>
      <Grid.Col isNarrow>
        <FormFieldSwitch
          {...fieldProps(props, 'has_procuration')}
          description="Assina o contrato pelo proprietário?"
          info="O usuário possui uma procuração para assinar o contrato pelo proprietário"
        />
      </Grid.Col>
    </Grid> */}
  </React.Fragment>
)

export default UserInvite
