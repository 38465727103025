import React from 'react';
import moment from 'moment';

import { request } from 'core/constants';
import { baseURL } from 'core/constants';

// Components
import { FormFieldText, FormFieldDate, FormFieldFile } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import Dropdown from 'components/lib/Dropdown';

import formCreator from 'features/components/formCreator';
import { fieldProps } from 'features/components/formCreator';

// Utils
import { goodObject, rawNumber } from 'utils/utils';

import uploadFile from '../../../../../../uploadFile';
import withFilePreview from 'components/HOCs/withFilePreview';
import withModal from 'components/HOCs/withModal';

export class SendReceiptAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpload: false,
    };
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  render() {
    const { formIsWorking, formIsReady, defaultValue } = this.props;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <FormFieldFile
              onChange={this.sendFile.bind(this)}
              isLoading={this.state.isUpload}
              isDisabled={this.state.isUpload}
              label="Enviar boleto para pagamento da garantia"
              accept="image/*, .pdf"
            />
          </Grid.Col>
          {!this.props.isClosed && (
            <Grid.Col isNarrow isFlexBottom>
              <Dropdown
                isRight
                isDisabled={!this.state.documentId && !defaultValue.bank_slip_file?.id}
                trigger={
                  <Button
                  isDisabled={!this.state.documentId && !defaultValue.bank_slip_file?.id}
                    hasThemeColor >
                    Enviar por:
                    <Icon name="fas fa-arrow-down" />
                  </Button>
                }>
                <React.Fragment>
                  <Dropdown.Item>
                    <Button
                      onClick={() => this.createRequest(this.props.fields, 'email')}
                      isBare
                      isFullWidth
                      isMedium>
                      <Icon name="far fa-envelope" />
                        Email
                      </Button>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Button
                      onClick={() => this.createRequest(this.props.fields, 'whatsapp')}
                      isBare
                      isFullWidth
                      isMedium>
                      <svg style={{ marginLeft: '4', marginRight: '4' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" /></svg>
                        WhatsApp
                      </Button>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Button
                      onClick={() => this.createRequest(this.props.fields, 'link')}
                      isBare
                      isFullWidth
                      isMedium>
                      <Icon name="fas fa-link" />
                        Copiar URL
                      </Button>
                  </Dropdown.Item>


                </React.Fragment>
              </Dropdown>
              {/* <Button hasThemeColor isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking || !this.state.documentId} submit>
                {defaultValue.bank_slip_file ? 
                'Reenviar' : 
                'Enviar'}
              </Button> */}
            </Grid.Col>
          )}
        </Grid>
        <Grid>
          <Grid.Col>
            {defaultValue.bank_slip_file && (
              <div className="buttons-flat">
                <Icon name="fas fa-sm fa-receipt" style={{ marginLeft: -4 }} />
                <Button isLink isDark onClick={this.props.openFile.bind(this, defaultValue.bank_slip_file)}>
                  Arquivo do boleto que já foi enviado
                </Button>
              </div>
            )}
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  uploadProgress(event) {
    let progress = (event.loaded * 100) / event.total;
    this.setState({ progress: Math.round(progress) });
  }

  async sendFile(file, field) {
    if (!field.value) return;

    const { companyId } = this.props;

    this.setState({ isUpload: true, progress: null });

    const documentId = await uploadFile(file[0].raw, this.uploadProgress.bind(this), companyId);

    this.setState({ documentId, isUpload: false });
  }

  createRequest(fields, type) {
    const { processId } = this.props;
    const { companyId } = this.props;

    let data = {}
    if (this.state.documentId) {
      data['bank_slip_file_id'] = this.state.documentId;
    }

    if (type == 'email') {
      data['send_email'] = true;
    }
    request
      .post(`${baseURL}company/${companyId}/process/${processId}/guarantee/request-payment`, data)
      .then((response) => {
        return this.props.onSuccess({ isSuccess: true, type: type, response: response.data });
      })
      .catch((error) => {
        return this.props.onSuccess({ isSuccess: false });
      });

    // this.props.submit(`{company}/process/${processId}/guarantee/request-payment`, data);
  }
}

export default formCreator(withFilePreview(withModal(SendReceiptAccount)));
