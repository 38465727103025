import styled from 'styled-components'

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.documentSaved ? '#2B313D0D' : '#76FF9A')};
  max-width: ${(props) => (!props.isMobile ? '180px' : '100%')};
  border-radius: 4px;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 6px 18px;
  }
`

export const Input = styled.input.attrs(() => ({ type: 'file' }))`
  height: 0;
  overflow: hidden;
  width: 0;

  + label {
    color: ${(props) => (props.documentSaved ? '#fff' : '#808996')};
    cursor: pointer;
    padding: 6px 18px;
  }
`
export const Loading = styled.div`
  display: flex;
  padding: 5px;
  cursor: not-allowed;
  min-width: 180px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`
