export default props => ({
  id: props.id,
  'data-spec-selector': props.specSelector || props.name || props.id,
  name: props.name || props.id,
  autoComplete: props.autoComplete,
  value: props.value,
  defaultValue: props.defaultValue,
  onClick: props.onClick,
  onChange: props.onChange,
  onInput: props.onInput,
  onFocus: props.onFocus,
  onBlur: props.onBlur,
  placeholder: props.placeholder,
  disabled: props.disabled || props.isDisabled,
  style: props.style,
  tabIndex: props.tabIndex
})
