import React from 'react'
import Icon from 'components/lib/Icon'

class FileName extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { row } = this.props

    return (
      <React.Fragment>
        {row.file_name && (
          <span
            onClick={() => this.props.handleViewDocument(row)}
            style={{ wordBreak: 'break-all', cursor: 'pointer', color: '#2185D0' }}
          >
            {' '}
            <Icon name='fas fa-eye' /> {row.file_name}{' '}
          </span>
        )}
      </React.Fragment>
    )
  }
}
export default FileName
