import React from 'react'

import RedeSeguros from '../../../../../../assets/svgs/RedeSeguros'

import Header from 'components/lib/Header'
import Button from 'components/lib/Button'

import moment from 'moment'

import { TableContainer, HeadContainer, RowContainer } from './styles'

// eslint-disable-next-line no-undef
const RedeConfiaxUrl = APP_REDE_CONFIAX_URL
const token = localStorage.getItem('izee-token')

const Contratar = (bondInsuranceId, bondInsuranceanalysisId) => {
  window.open(
    `${RedeConfiaxUrl}#access_token=${token}&token_type=Bearer&expires_in=&bondInsuranceId=${bondInsuranceId}&bondInsuranceanalysisId=${bondInsuranceanalysisId}`,
    '_blank',
  )
}

const Analysis = ({ quote, downloadPolicy }) => {
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <RedeSeguros />
      </div>
      <Header
        isTiny
        isUppercase
        subtitle={<hr style={{ margin: '4px 0px 16px 0px' }} />}
      >
        <div>Cotações</div>
      </Header>

      <TableContainer>
        <HeadContainer>
          <span>Nome</span>
          <span>Valor</span>
          <span>Status</span>
          <span>Expira em</span>
          <span>Ações</span>
        </HeadContainer>
        {quote.bond_insurance_analysis.map((analyse, i) => (
          <RowContainer key={i}>
            <span>{analyse.insurer.name}</span>
            <span>
              {analyse.status == 'Aprovado' || analyse.status == 'Aguardando Biometria'
                ? analyse.insurer.code == 'GARANTTI'
                  ? analyse.insurer_response
                    ? analyse.insurer_response.map((plan, i) =>
                        quote.purpose == 'RESIDENTIAL' ? (
                          <span key={i}>
                            {`${plan.description}: ${
                              plan.rent_months_count
                            } x R$ ${Number(
                              plan.residentialfee_assurance_month_amount,
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} `}
                            <br />
                          </span>
                        ) : (
                          <span key={i}>
                            {`${plan.description}: ${
                              plan.rent_months_count
                            } x R$ ${Number(
                              plan.commercialfee_assurance_month_amount,
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} `}
                            <br />
                          </span>
                        ),
                      )
                    : analyse.value
                    ? `${quote.property.validity} x R$ ${Number(
                        analyse.value,
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : ''
                  : analyse.insurer.code == 'PORTO_ESSENCIAL'
                  ? analyse.insurer_response
                    ? analyse.insurer_response.map((plan, i) => (
                        <span key={i}>
                          {`${plan.nome_seguro}: ${
                            plan.quantidade_parcela
                          } x R$ ${Number(plan.valor_parcela).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          )} `}
                          <br />
                        </span>
                      ))
                    : analyse.value
                    ? `${quote.property.validity} x R$ ${Number(
                        analyse.value,
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : ''
                  : analyse.value
                  ? `${quote.property.validity} x R$ ${Number(
                      analyse.value,
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : ''
                : ''}
            </span>
            <span>{analyse.status}</span>
            <span>{moment(analyse.expiration_date).format('DD/MM/YYYY')}</span>
            <span>
              {analyse.insurer_proposal_id ? (
                analyse?.bond_insurance_hired_data?.insurer_policy_id ? (
                  <Button
                    isFlat
                    hasThemeColor
                    onClick={() => downloadPolicy(analyse.bond_insurance_id)}
                  >
                    Baixar apólice
                  </Button>
                ) : (
                  <Button isDisabled isFlat hasThemeColor>
                    Contratação solicitada
                  </Button>
                )
              ) : (
                analyse.status === 'Aprovado' &&
                !quote.bond_insurance_analysis.some(
                  (el) => el.insurer_proposal_id,
                ) && (
                  <Button
                    isFlat
                    hasThemeColor
                    onClick={() => Contratar(quote.id, analyse.id)}
                  >
                    Contratar
                  </Button>
                )
              )}
            </span>
          </RowContainer>
        ))}
      </TableContainer>
      {quote.bond_insurance_analysis.some((el) => el.insurer_proposal_id) &&
        !quote.bond_insurance_analysis.some(
          (el) => el?.bond_insurance_hired_data?.insurer_policy_id,
        ) && (
          <div style={{ marginTop: '10px' }}>
            <span>
              Após solicitar a contratação, acesse o painel da Rede Seguros para
              continuar o processo. &nbsp;
            </span>
            <a
              href={`${RedeConfiaxUrl}#access_token=${token}&token_type=Bearer&expires_in=`}
              target='_blank'
              rel='noreferrer'
            >
              Acessar o painel.
            </a>
          </div>
        )}
    </>
  )
}

export default Analysis
