/**
 * @class RouterParams
 * @author @cspilhere
 */

class RouterParams {

  constructor() {
    this.params = {};
    this.constants = {};
  }

  getParam(key) {
    return this.params[key];
  }

  getConstant(key) {
    if (!this) return;
    if (typeof this.constants[key] === 'function') return this.constants[key](this.params);
    else return this.constants[key];
  }

  setConstant(newConstantKey, value) {
    this.constants[newConstantKey] = value;
  }

  setParam(newParamKey, value) {
    this.params[newParamKey] = value;
  }

  setup() {
    this.constant = (key) => this.getConstant(key);
    this.param = (key) => this.getParam(key);
  }

};

// Export RouterParams
let Instance = null;
if (!(Instance instanceof RouterParams)) Instance = new RouterParams();
export default Instance;
