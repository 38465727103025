import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  @media (max-width: 365px) {
    display: ${({ responsive }) => responsive && 'none'};
  }
`

export const Name = styled.span.attrs({
  className: 'has-text-weight-bold text-truncate',
})`
  color: white;
  font-size: 16;

  @media (max-width: 550px) {
    display: none;
  }
`
