import React from 'react'

import * as actions from './actions'
import withRedux from 'components/redux'

import * as commons from 'utils/UtilityStore/actions'

// Components
import BodyContainer from 'components/lib/BodyContainer'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import ScreenHeaderPrimary from 'components/ScreenHeaderPrimary'
// Utils
import { ChildRoutes } from 'utils/RouterUtils'

class Marketplace extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { historyPush } = this.props
    const current = this.getCurrentView()

    if (!current) {
      return historyPush('/')
    }

    if (!current.isSame) {
      historyPush(`services/${current.view}`)
    }
  }

  getSnapshotBeforeUpdate() {
    const { view } = this.props.match.params

    if (!view) this.props.historyPush('/')

    return null
  }

  render() {
    const currentView = this.getCurrentView()

    if (!currentView) {
      return null
    }

    const views = this.getViews()

    return (
      <>
        <ScreenHeaderPrimary {...this.props} />

        <div className='services-content'>
          <div className='sidebar'>
            <h2 className='text-center'>Serviços</h2>
            <hr style={{ margin: '1rem 0' }} />
            <ul className='sidebar-menu'>
              {views.map((item, index) => {
                let isActive = true

                if (item.isHidden) return null
                if (item.isActive !== null && item.isActive !== undefined)
                  isActive = item.isActive

                return (
                  <li className='sidebar-menu-item' key={index}>
                    <Button
                      isBare
                      isFullWidth
                      isDisabled={!isActive}
                      isSelected={currentView.view === item.code}
                      onClick={this.handleChangeView.bind(this, item.code)}
                    >
                      <Icon name={item.icon} />
                      <span>{item.name}</span>
                    </Button>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='right-content'>
            <BodyContainer>
              <ChildRoutes {...this.props} />
            </BodyContainer>
          </div>
        </div>
      </>
    )
  }

  getCurrentView() {
    const currentView = this.props.match.params.view
    const views = this.getViews()

    if (!views.length) {
      return null
    }

    const current = views.find((view) => view.code === currentView)
      ? currentView
      : views[0].code

    return {
      view: current,
      isSame: current === currentView,
    }
  }

  getViews = () => {
    return [
      {
        name: 'Análise de Crédito',
        code: 'analysis',
        id: 'analysis',
        icon: 'fas fa-diagnoses',
        isActive: true,
        isHidden: false,
        remove: false,
      },
      {
        name: 'Vistorias',
        code: 'surveys',
        id: 'surveys',
        icon: 'fas fa-eye',
        isActive: true,
        isHidden: false,
        remove: false,
      },
    ].filter((view) => !view.remove && !view.isHidden)
  }

  handleChangeView(view) {
    this.props.historyPush(`services/${view}`)
  }
}

export default withRedux({ ...actions, ...commons })(Marketplace)
