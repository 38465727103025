import React, { useRef } from 'react';

const MoviDesk = () => {
  const showMoviDesk = useRef(false);

  const handleShowMovidesk = () => {
    if (showMoviDesk.current) {
      showMoviDesk.current = false;
      movideskChatWidgetChangeWindowState('minimized');
    } else {
      showMoviDesk.current = true;
      movideskChatWidgetChangeWindowState('maximized');
    }
  };

  return (
    <div
      aria-hidden
      onClick={handleShowMovidesk}
      className="header-button-container">
      <svg width="22" height="22" viewBox="0 0 21 15" fill="white">
        <path d="M0.5 10.6681L3.49839 9.88529C4.68334 10.7163 6.0968 11.1588 7.54344 11.1521C7.37583 10.6866 7.28584 10.1935 7.28584 9.683C7.28584 6.84541 10.0666 4.54462 13.5009 4.54462C13.5145 4.54462 13.5282 4.54466 13.5419 4.54473C12.778 2.42263 10.4009 0.875 7.58431 0.875C4.15006 0.875 1.36929 3.17578 1.36929 6.01338C1.37259 6.82991 1.60236 7.62951 2.03305 8.32323L0.5 10.6681Z" />
        <path d="M17.7224 13.2448L20.3638 13.9297L19.0133 11.8782C19.3927 11.2713 19.5951 10.5718 19.598 9.85744C19.598 7.37493 17.1484 5.36206 14.123 5.36206C11.0977 5.36206 8.6481 7.37493 8.6481 9.85744C8.6481 12.3399 11.0977 14.3528 14.123 14.3528C15.41 14.3662 16.6687 13.9787 17.7224 13.2448Z" />
      </svg>
    </div>
  );
};

export default MoviDesk;
