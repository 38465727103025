import React from 'react';

import { connect } from 'react-redux';

import { currency, timeDelay, copyToClipboard } from 'utils/utils';
import * as commons from 'utils/UtilityStore/actions';

import { ga } from '../../../../../services/firebase';
import Confiax from '../../../../../services/integrations-confiax/components/Confiax';

import SectionGroup from 'features/AnalysisProposal/components/SectionGroup';
import HeadRight from 'features/AnalysisProposal/components/HeadRight';
import RejectGuarantee from 'features/RejectGuarantee';

import withFilePreview from 'components/HOCs/withFilePreview';
import SectionWrapper from 'components/SectionWrapper';
import withModal from 'components/HOCs/withModal';
import Documents from 'components/Documents';

import { FormFieldSwitch } from 'components/lib/FormFields';
import Header from 'components/lib/Header';
import Button from 'components/lib/Button';
import Modal from 'components/lib/Modal';
import Space from 'components/lib/Space';
import Grid from 'components/lib/Grid';
import Icon from 'components/lib/Icon';
import Text from 'components/lib/Text';

import SaveGuaranteeProtocol from './guarantee/SaveGuaranteeProtocol';
import SendReceiptAccount from './guarantee/SendReceiptAccount';
import ApproveGuarantee from './guarantee/ApproveGuarantee';
import SendInvoice from './guarantee/SendInvoice';

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    const { current } = this.props;
    const { bank_slip_file } = current;
    const paymentMethod = bank_slip_file ? 'invoice' : 'bank';
    this.state = {
      paymentMethod,
      redeConfiaxErrorTitle: '',
      redeConfiaxErrorState: false,
      redeConfiaxErrorList: [],
    };
  }

  render() {
    const {
      activeCompany: { id: companyId },
    } = this.props;

    const { current, isApproved, isRejected } = this.props;

    const {
      proposal = {},
      id,
      receipt_file,
      bank_slip_file,
      guarantee_value,
      guarantee_denial_comment,
    } = current;

    const { guarantee = null } = proposal;

    const guaranteeCode = guarantee && guarantee.code;
    const hasReceipt = !!receipt_file;

    const paymentMethod =
      this.state.paymentMethod || (bank_slip_file ? 'invoice' : 'bank');

    let flow = null;

    const showSendReceipt =
      guaranteeCode === 'GUARANTEE_CAPITALIZATION' ||
      guaranteeCode === 'GUARANTEE_DEPOSIT';

    if (showSendReceipt) {
      flow =
        paymentMethod === 'bank' ? (
          <SendReceiptAccount
            companyId={companyId}
            processId={id}
            defaultValue={{ ...current }}
            isClosed={hasReceipt}
            onSuccess={this.sendPaymentSuccess.bind(this)}
            receipt_file={receipt_file}
            guaranteeCode={guaranteeCode}
            paymentMethod={paymentMethod}
            guarantee_value={guarantee_value}
            choosePaymentMethod={this.choosePaymentMethod.bind(this)}
          />
        ) : (
          <SendInvoice
            companyId={companyId}
            processId={id}
            defaultValue={{ ...current }}
            isClosed={hasReceipt}
            onSuccess={this.sendPaymentSuccess.bind(this)}
          />
        );
    }

    if (guaranteeCode === 'GUARANTEE_SURETY_BOND' && !isApproved) {
      flow = (
        <ApproveGuarantee
          processId={id}
          onSuccess={this.approveGuaranteeSuccess.bind(this)}
        />
      );
    }

    if (guaranteeCode === 'GUARANTEE_CREDPAGO' && !isApproved) {
      flow = (
        <ApproveGuarantee
          processId={id}
          onSuccess={this.approveGuaranteeSuccess.bind(this)}
        />
      );
    }

    const renderGuaranteeDenied = () => (
      <>
        {guarantee_denial_comment && (
          <>
            <Text>Motivo da rejeição:</Text>
            <Text style={{ fontWeight: 'bold', color: 'red' }}>
              {guarantee_denial_comment}
            </Text>
            <Space />
          </>
        )}
      </>
    );

    const guaranteeContain = () => (
      <>
        <Confiax
          props={this.props}
          company={this.props.activeCompany}
          proposal={current.proposal}
        />
        {renderGuaranteeDenied()}
      </>
    );

    const guaranteeDocuments = () => (
      <Documents entityId={current.id} entityName="processInsurance" noRequest />
    );

    const showGuaranteePaymentMethod =
      !receipt_file &&
      (guaranteeCode === 'GUARANTEE_CAPITALIZATION' ||
        guaranteeCode === 'GUARANTEE_DEPOSIT');

    const guaranteeData = () => (
      <>
        {showGuaranteePaymentMethod && (
          <>
            <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
              Método
            </Header>

            <Text>Valor da garantia: R$ {currency(guarantee_value)}</Text>
            <Space />
            <Text>Solicitar pagamento da garantia:</Text>
            <Space />

            <Grid>
              <Grid.Col isNarrow>
                <FormFieldSwitch
                  id="invoice"
                  name="paymentMethod"
                  type="radio"
                  description="Boleto"
                  checked={paymentMethod === 'invoice'}
                  defaultChecked={paymentMethod === 'invoice'}
                  onClick={() => this.choosePaymentMethod('invoice')}
                />
              </Grid.Col>
              <Grid.Col isNarrow>
                <FormFieldSwitch
                  id="bank"
                  name="paymentMethod"
                  type="radio"
                  description="Transferência bancária"
                  checked={paymentMethod === 'bank'}
                  defaultChecked={paymentMethod === 'bank'}
                  onClick={() => this.choosePaymentMethod('bank')}
                />
              </Grid.Col>
            </Grid>
          </>
        )}

        <Header
          style={{ marginTop: 4 }}
          subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}
        />

        {flow}
      </>
    );

    const guaranteeReceipt = () => {
      if (
        guaranteeCode === 'GUARANTEE_CAPITALIZATION' ||
        guaranteeCode === 'GUARANTEE_DEPOSIT'
      ) {
        return (
          <SaveGuaranteeProtocol
            guaranteeCode={guaranteeCode}
            processId={id}
            defaultValue={{ ...current }}
            fetchFile={this.props.openFile.bind(this, receipt_file)}
            hasReceipt={hasReceipt}
            isClosed={hasReceipt}
            guaranteeValue={currency(guarantee_value)}
            onSuccess={this.sendPaymentSuccess.bind(this)}
          />
        );
      }
    };

    const extraButtons = isRejected ? (
      <Button isBare isFullWidth isMedium>
        <Icon name="fas fa-times-circle" />
        Garantia Rejeitada
      </Button>
    ) : !isApproved ? (
      <Button
        onClick={() => {
          ga.logEvent('options_menu', { custom_value: 'reject-guarantee' });
          this.rejectGuarantee.bind(this)();
        }}
        isBare
        isFullWidth
        isMedium
      >
        <Icon name="fas fa-times-circle" />
        Rejeitar garantia
      </Button>
    ) : (
      <Button isBare isFullWidth isMedium>
        <Icon name="fas fa-check" />
        Garantia Aprovada
      </Button>
    );

    const items = [
      {
        contain: guaranteeContain(),
        icon: 'fa-shield-check',
        open: true,
        title: `Garantia (${guarantee?.name})`,
      },
      {
        contain: guaranteeData(),
        icon: 'fa-donate',
        open: false,
        title: 'Formas de pagamento',
      },
      {
        contain: guaranteeReceipt(),
        icon: 'fa-receipt',
        open: false,
        title: 'Comprovantes',
      },
      {
        contain: guaranteeDocuments(),
        icon: 'fa-clipboard-list',
        open: false,
        title: 'Documentos',
      },
    ];

    return (
      <>
        <div className="print-wrap">
          <SectionWrapper
            customHeader={true}
            header={<p>Garantia</p>}
            headerRight={HeadRight({ extraButtons })}
          >
            <SectionGroup items={items} />
          </SectionWrapper>
        </div>
        <Modal
          title={this.state.redeConfiaxErrorTitle}
          isOpen={this.state.redeConfiaxErrorState}
        >
          {this.state.redeConfiaxErrorList.map((error, i) => (
            <p key={i}>{error}</p>
          ))}
        </Modal>
      </>
    );
  }

  choosePaymentMethod(type) {
    if (this.state.paymentMethod !== type) {
      this.setState({ paymentMethod: type });
    } else {
      this.setState({ paymentMethod: type === 'invoice' ? 'bank' : 'invoice' });
    }
  }

  rejectGuarantee() {
    const { current } = this.props;

    this.props.openModal(
      'Rejeitar garantia',
      <RejectGuarantee
        path={`{company}/process/${current.id}/guarantee/validate-guarantee`}
        onSuccess={this.onRejectGuaranteeSuccess.bind(this)}
      />
    );
  }

  onSuccessRedeConfiax(isSuccess, error) {
    const { current } = this.props;

    if (isSuccess) {
      this.props.notification({
        message: 'Rede Seguradora adicionada com sucesso!',
      });
      this.props.fetch(current.id);
    } else {
      this.setState({
        redeConfiaxErrorTitle: error.message,
        redeConfiaxErrorState: true,
        redeConfiaxErrorList: error.errors,
      });
    }
  }

  onRejectGuaranteeSuccess(response) {
    if (response.isSuccess) {
      this.props.closeModal();
      let delayRedirect = timeDelay(400);
      delayRedirect(() => {
        window.location.reload();
      });
    } else {
      this.props.notification({
        message: response.actionMessage,
        status: 'danger',
      });
    }
  }

  approveGuaranteeSuccess({ isSuccess }) {
    const { current } = this.props;

    if (isSuccess) {
      this.props.notification({ message: 'Garantia aprovada com sucesso' });
      let delayRedirect = timeDelay(400);
      delayRedirect(() => {
        this.props.fetch(current.id);
        // window.location.reload()
      });
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar aprovar a garantia',
        status: 'danger',
      });
    }
  }

  sendPaymentSuccess({ isSuccess, type, response }) {
    const { current } = this.props;

    if (isSuccess) {
      this.props.notification({
        message: 'Informações da garantia atualizadas com sucesso',
      });
      if (type == 'whatsapp') {
        this.whatsAppShare(response);
      }
      if (type == 'link') {
        this.copyToClipboard(response);
      }
      this.props.fetch(current.id);
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar atualizar as informações da garantia',
        status: 'danger',
      });
    }
  }

  copyToClipboard(response) {
    const path = response.proposal.upload_receipt_url;

    copyToClipboard(path);
    this.props.notification({
      message: 'Link copiado com sucesso',
      status: 'success',
    });
  }

  whatsAppShare(response) {
    let body = `${this.props.activeCompany.name}: Para dar continuidade ao processo de locação é necessário o pagamento do valor da garantia. Basta clicar no link abaixo e seguir os passos. É rápido e fácil =)`;
    let url = response.proposal.upload_receipt_url;
    let link = `https://api.whatsapp.com/send?text=${body}%0a${url}`;

    let left =
      window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 520 / 2;
    let top =
      window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 570 / 2;

    window.open(
      link,
      '',
      `height=570,width=520,left=${left},top=${top}location=no,scrollbars=yes,status=no,toolbar=no,directories=no,menubar=no,resizable=no,centerscreen=yes,chrome=yes`
    );
  }
}

export default connect((store) => ({ activeCompany: store.app.activeCompany }), {
  ...commons,
})(withFilePreview(withModal(Settings)));
