import React from 'react'

import Address from 'components/Proposal/components/Address'
import Evaluation from 'components/Proposal/components/Evaluation'
import HeadRight from './HeadRight'
import SectionGroup from './SectionGroup'
import SectionWrapper from 'components/SectionWrapper'
import { currency } from 'components/Proposal/components/utils'

import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'

import Logs from 'components/Logs'

const HeadWrap = (props) => {
  const {
    fichacerta,
    current,
    guarantorList,
    negotiationInfo,
    isGuarantorValid,
    isPropValid,
    isClientGuarantee,
    isClientValid,
    optionsInfo,
    ownerList,
    propertyInfo,
    clientList,
    acquisitionFees,
  } = props

  const { proposal } = current

  const isRentalProposal = proposal.type === 'TYPE_RENTAL'
  const isSaleProposal = proposal.type === 'TYPE_SALE'

  if (
    !current ||
    !proposal ||
    !proposal.properties ||
    !proposal.properties.length === 0
  )
    return null

  const guarantee = proposal.guarantee || null
  const property = proposal.properties[0] || proposal.temporary_property

  const guarantorTitle =
    guarantee && guarantee.name ? `Garantia (${guarantee.name})` : 'Garantias'

  const showGuarantors = guarantorList.length ? guarantorList : []

  const propertyValue = currency(
    isRentalProposal ? proposal.rent_value : proposal.sale_value,
  )
  const titleRentValue = `Valor ${
    isRentalProposal
      ? 'do aluguel'
      : isSaleProposal
        ? 'da venda'
        : 'da administração'
  }: ${propertyValue ? 'R$ ' + propertyValue : ''}`

  const renderPropertyTitle = `Imóvel ${
    property?.type ? `(${property?.type?.name})` : ''
  }
  		${property?.name ? ` // Apelido: ${property?.name}` : ''}`

  const renderPropertyContain = ({ address }) => {
    return (
      <React.Fragment>
        <strong>
          <span style={{ fontSize: 14 }}>{property.name}</span>
        </strong>
        &nbsp;&nbsp;
        {proposal?.properties[0] && (
          <Button
            isBare
            alt='Editar o cadastro'
            onClick={
              props.onEditProperty && props.onEditProperty.bind(null, property)
            }
          >
            <Icon name='fas fa-pen-square' />
          </Button>
        )}
        <p>
          <strong>
            <span style={{ fontSize: 14 }}>Matrícula</span>
          </strong>
          : {property.registration}
        </p>
        <p>
          <strong>
            <span style={{ fontSize: 14 }}>Inscrição Imobiliária</span>
          </strong>
          : {property.real_state_enrollment}
        </p>
        <Address address={address} />
      </React.Fragment>
    )
  }

  const items = [
    ...(property
      ? [
          {
            contain: renderPropertyContain(property),
            icon: 'fa-home',
            open: false,
            title: renderPropertyTitle,
            type: 'imovel',
          },
        ]
      : []),
    {
      contain: ownerList,
      evaluation: <Evaluation status={isPropValid} />,
      icon: 'fa-user',
      open: false,
      title: 'Proprietários',
      type: 'props',
    },
    ...(isRentalProposal || isSaleProposal
      ? [
          {
            contain: clientList,
            evaluation: <Evaluation status={isClientValid} />,
            icon: 'fa-user',
            open: false,
            title: 'Clientes',
            type: 'clients',
          },
        ]
      : []),
    ...(isRentalProposal
      ? [
          {
            contain: showGuarantors,
            evaluation: isClientGuarantee && (
              <Evaluation status={isGuarantorValid} />
            ),
            icon: 'fa-shield-check',
            open: false,
            title: guarantorTitle,
            type: 'guarantor',
          },
        ]
      : []),
    ...(isRentalProposal || isSaleProposal
      ? [
          {
            contain: fichacerta,
            icon: 'fa-diagnoses',
            open: false,
            title: 'Análise do Processo',
          },
        ]
      : []),
    {
      contain: acquisitionFees ? acquisitionFees : propertyInfo,
      icon: 'fa-hand-holding-usd',
      open: false,
      title: titleRentValue,
      type: 'rentValue',
    },
    {
      contain: negotiationInfo,
      icon: 'fa-handshake',
      open: false,
      title: 'Negociação',
      type: 'negotiation',
    },
    ...(isRentalProposal || isSaleProposal
      ? [
          {
            contain: optionsInfo,
            icon: 'fa-calendar-check',
            open: true,
            title: 'Datas e Prazos',
            type: 'keys_location',
          },
          {
            contain: <Logs proposalId={proposal.id} />,
            icon: 'fa-calendar-check',
            open: false,
            title: 'Logs',
            type: 'logs',
          },
        ]
      : []),
  ]

  return (
    <SectionWrapper
      customHeader={true}
      header={
        <p>{`Processo de ${
          isRentalProposal ? 'locação' : isSaleProposal ? 'venda' : 'administração'
        }`}</p>
      }
      headerRight={<HeadRight {...props} isEditDisabled={false} />}
    >
      <SectionGroup items={items} />
    </SectionWrapper>
  )
}

export default HeadWrap
