export default (props) => ({
  id: props.id || props.name,
  type: props.type || 'checkbox',
  'data-spec-selector': props.specSelector || props.name || props.id,
  name: props.name || props.id,
  value: props.value,
  defaultChecked: props.defaultChecked,
  checked: props.checked,
  onClick: props.onClick,
  onChange: props.onChange,
  onKeyDown: props.onKeyDown,
  onKeyUp: props.onKeyUp,
  onInput: props.onInput,
  onFocus: props.onFocus,
  onBlur: props.onBlur,
  placeholder: props.placeholder,
  disabled: props.disabled || props.isDisabled,
  style: props.style
});
