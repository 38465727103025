import React from "react";
import PropTypes from "prop-types";

import parsePropsAsBulmaClasses from "components/lib/_utils/parsePropsAsBulmaClasses";

const Text = props => {
  const textCSSClass = `text ${parsePropsAsBulmaClasses(props)}`;
  return (
    <p className={textCSSClass} style={props.style}>
      {props.children}
    </p>
  );
};

Text.propTypes = {
  children: PropTypes.any
};

export default Text;
