import React from "react";
import * as types from "../../types";

const DocumentName = props =>
  props.documents.map((document, id) => {
    if (document.status === types.STATUS_ANALYSIS) return null;
    if (document.status === types.STATUS_APPROVED) return null;
    return (
      <div className="doc_name" key={id}>
        - {document.name}
      </div>
    );
  });

export default DocumentName;
