import styled from 'styled-components'

export const TableContainer = styled.div`
  background-color: white;

  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(even) {
    background-color: #fafafa;
  }

  & > span:first-child {
    text-align: left;
  }
  & > span {
    flex: 1;
    text-align: left;

    padding: 10px 18px;

    border-right: 1px solid #d1d1d1;
  }

  & > span:last-child {
    text-align: center;

    border-right: none;
  }
`

export const HeadContainer = styled(RowContainer)`
  background-color: #5f6775;

  & > span {
    font-size: 15px;
    font-weight: 500;
    color: white;

    padding: 10px 18px;

    border-right: 1px solid #d1d1d1;
  }
`
