import React from 'react';
import moment from 'moment';

// Components
import { FormFieldDate } from 'components/lib/FormFields';
import formCreator from 'features/components/formCreator';
import { fieldProps } from 'features/components/formCreator';

// Utils
import { goodObject } from 'utils/utils';

export class OwnershipDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  render() {
    const { canEdit = true } = this.props;

    return (
      <FormFieldDate
        changeDate={this.props.changeDate}
        disabledInput={!canEdit}
        {...fieldProps(this.props, 'ownership_date')}
        isDisabled={!canEdit}
        label="Posse aproximada"
        valueDefault={this.props.dataValue}
      />
    );
  }

  createRequest(fields) {
    const { processId } = this.props;
    let goodFields = goodObject(fields, {
      ownership_date: {
        path: 'ownership_date',
        format: (value) => moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      },
    });
    this.props.submit(`{company}/process/${processId}/overview/define-ownership-date`, goodFields, 'post');
  }
}

export default formCreator(OwnershipDate, { submitOnChange: false });
