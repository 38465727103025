const initialState = {
  items: [],
  listInfo: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'FETCH_USERS':
      state = {
        ...state,
        ...{
          items: action.payload,
          listInfo: action.listInfo,
        },
      };
      break;

    case 'UPDATE_USERS':
      state = {
        ...state,
        ...{
          items: state.items.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          }),
        },
      };
      break;

    default:
      break;
  }

  return state;
};

// const initialState = {
//   userList: [],
//   listInfo: {},
//   isFetching: {}
// };

// export default (state = initialState, action = {}) => {

//   switch (action.type) {

//   case 'IS_FETCHING':
//     let isFetching = { ...state.isFetching };
//     if (action.valueKey === 'all') {
//       Object.keys(isFetching).forEach((item) => isFetching[item] = action.payload);
//     } else {
//       isFetching = { ...isFetching, ...{ [action.valueKey]: action.payload } };
//     }
//     state = { ...state, ...{ isFetching: isFetching } };
//     break;

//   case 'LIST_USERS':
//     state = {
//       ...state,
//       ...{
//         userList: action.payload,
//         listInfo: action.listInfo
//       }
//     };
//     break;

//   case 'UPDATE_USER_LIST':
//     const updatedList = state.userList.map((user) => {
//       if (user.id === action.payload.id) {
//         return action.payload;
//       }
//       return user;
//     });
//     state = {
//       ...state,
//       ...{
//         userList: updatedList
//       }
//     };
//     break;

//   default:
//     break;
//   }

//   return state;
// };
