import Rollbar from 'rollbar';

export default {
  init: () => {
    window.rollbar = new Rollbar({
      accessToken: ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: IZEE_APP_ENVIRONMENT
      }
    });
  },
  setPerson: (payload) => {
    rollbar.configure({
      payload: {
        person: payload
      },
    });
  }
};
