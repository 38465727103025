import React, { Component } from 'react'
import { map, find } from 'lodash'
import { ROLE } from 'components/constants'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import * as types from '../types'

class ActionsBar extends Component {
  hasPendingDocs() {
    const { allDocs } = this.props

    let hasPendingDocs = false

    allDocs.map((doc) => {
      if (doc.status === types.STATUS_REJECTED) hasPendingDocs = true
      if (doc.status === types.STATUS_WAITING) hasPendingDocs = true
    })

    return hasPendingDocs
  }

  renderButton = ({ disabled, icon, index, name }) => (
    <div key={index} className='Item'>
      <Button
        isWhite
        isSmall
        isTransparent
        normal
        disabled={disabled}
        onClick={() => this.props.handleActions(name)}
        extraStyles={{
          backgroundColor: '#DDDDDD !important',
          border: '1px solid #AAADB1',
          borderRadius: '4px',
          opacity: disabled ? 0.9 : 1,
        }}
        childrenExtraStyles={{ color: '#000', fontWeight: 400 }}
      >
        <Icon name={icon} />

        {name}
      </Button>
    </div>
  )

  render() {
    const { isAgent, forbiddenAccess } = this.props

    const forbiddenJuridicalAndInspector = forbiddenAccess([
      ROLE.PROFILE_INSPECTOR,
      ROLE.PROFILE_JURIDICAL,
    ])

    const actions = [
      {
        icon: 'fas fa-eye',
        name: 'Visualizar',
      },
      {
        icon: 'fas fa-arrow-from-top',
        name: 'Download',
        remove: isAgent || forbiddenJuridicalAndInspector,
      },
      {
        icon: 'fas fa-paper-plane',
        name: 'Solicitar documento',
        remove:
          forbiddenJuridicalAndInspector || this.props.entityName == 'property',
      },
      {
        icon: 'fas fa-thumbs-up',
        name: 'Aceitar',
        remove: forbiddenJuridicalAndInspector,
      },
      {
        icon: 'fas fa-ban',
        name: 'Recusar',
        remove: forbiddenJuridicalAndInspector,
      },
      {
        icon: 'fas fa-times-circle',
        name: 'Deletar',
        remove: forbiddenJuridicalAndInspector,
      },
    ].filter((x) => !x.remove)

    return actions.map((action, i) => {
      const { icon, name } = action
      const { selected, documents, noChangeStatus, noDownload, noRequest } =
        this.props

      const selectedSize = selected.length

      if (noChangeStatus && (name === 'Aceitar' || name === 'Recusar')) {
        return this.renderButton({ disabled: true, icon, index: i, name })
      }

      if (noRequest && name === 'Solicitar documento') {
        return this.renderButton({ disabled: true, icon, index: i, name })
      }

      if (noDownload && name === 'Download') {
        return this.renderButton({ disabled: true, icon, index: i, name })
      }

      let waiting = 0,
        analysis = 0,
        approved = 0,
        rejected = 0,
        havedocs = 0

      map(selected, (item) => {
        const doc = find(documents, { id: item })

        if (doc) {
          waiting = doc.status === types.STATUS_WAITING ? waiting + 1 : waiting
          analysis = doc.status === types.STATUS_ANALYSIS ? analysis + 1 : analysis
          approved = doc.status === types.STATUS_APPROVED ? approved + 1 : approved
          rejected = doc.status === types.STATUS_REJECTED ? rejected + 1 : rejected
        }
      })

      havedocs = havedocs + rejected + analysis + approved

      let disabled = true

      if (selected.length > 0) disabled = false

      const hasPendingDocs = this.hasPendingDocs()

      if (havedocs === 0 && name === 'Solicitar documento' && hasPendingDocs)
        disabled = false
      if (name === 'Download' && (havedocs === 0 || waiting)) disabled = true
      if (name === 'Recusar' && (havedocs === 0 || selectedSize > 1)) disabled = true
      if (name === 'Visualizar' && (havedocs === 0 || waiting)) disabled = true
      if (name === 'Aceitar' && (havedocs === 0 || waiting || rejected | approved))
        disabled = true
      if (selected.length > 0 && !hasPendingDocs && name === 'Solicitar documento')
        disabled = true

      return this.renderButton({ disabled, icon, index: i, name })
    })
  }
}

ActionsBar.defaultProps = {
  noChangeStatus: false,
  noDownload: false,
}

export default ActionsBar
