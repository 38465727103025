import React from 'react'

import ReactDOM from 'react-dom'
import cx from 'classnames'

import Button from 'components/lib/Button'
import Header from 'components/lib/Header'
import Icon from 'components/lib/Icon'

class ModalBody extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    if (this.state.isOpen != this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
      if (this.props.onOpen && this.props.isOpen == true) this.props.onOpen()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen != nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen })
      if (this.props.onOpen && nextProps.isOpen === true) this.props.onOpen()
    }
  }

  render() {
    const { isOpen } = this.state
    const {
      responsive,
      containerStyle,
      headerStyle,
      title,
      disableClose,
      bodyStyle,
      children,
      backgroundContentStyle,
      isWide,
      isScrollable = true,
    } = this.props

    return (
      <div
        className={cx('custom-modal', {
          'is-active': isOpen,
          'is-responsive': Boolean(responsive),
        })}
      >
        <div
          className={cx('custom-modal-content', { 'is-wide': isWide })}
          style={containerStyle}
        >
          <header className='custom-modal-header' style={headerStyle}>
            <Header>{title}</Header>
            {!disableClose && (
              <Button isBare onClick={this.close.bind(this)}>
                <Icon name='fas fa-times' />
              </Button>
            )}
          </header>
          <div
            className='custom-modal-body'
            style={{
              ...bodyStyle,
              ...(!isScrollable ? { overflowY: 'inherit' } : {}),
            }}
          >
            {children}
          </div>
        </div>
        <div
          style={backgroundContentStyle}
          className='custom-modal-background'
          role='button'
          tabIndex='0'
        />
      </div>
    )
  }

  close() {
    this.setState({ isOpen: false })
    if (this.props.onClose) this.props.onClose()
  }
}

const body = document.body

class Portal extends React.Component {
  constructor(props) {
    super(props)
    this.element = document.createElement('div')
  }

  componentDidMount() {
    body.appendChild(this.element)
  }

  componentWillUnmount() {
    body.removeChild(this.element)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element)
  }
}

const Modal = (props) => (
  <Portal>
    <ModalBody {...props} />
  </Portal>
)

export default Modal
