import React from 'react'
import ProgressCounter from './progressCounter'

import { map } from 'lodash'

class ProgressBar extends React.Component {
  renderCircle(item, index, selected, callBack, isInvalid) {
    const { sectionNumber, progressTitle } = item
    const { themeColor } = this.props
    return (
      <ProgressCounter
        key={index}
        selected={selected}
        sectionNumber={sectionNumber}
        title={progressTitle}
        callBack={callBack}
        isInvalid={isInvalid}
        themeColor={themeColor}
      />
    )
  }
  render() {
    const {
      sections,
      selected,
      selectedSection,
      callBack,
      isInvalid
    } = this.props
    return (
      <div className='progress-bar-container'>
        {map(sections, (item, index) => {
          return this.renderCircle(item, index, selected, callBack, isInvalid)
        })}
      </div>
    )
  }
}

export default ProgressBar
