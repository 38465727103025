import styled from 'styled-components';

export const FilterOnAlert = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: red;
  z-index: 2;
`;
