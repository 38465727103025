import React from 'react';
import ReactTimeout from 'react-timeout';

import Modal from 'components/lib/Modal';

export default function withModal(Component) {
  class WithModalWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modalTitle: '',
        modalIsOpen: false,
        component: null,
        isScrollable: true,
      };
    }

    render() {
      return (
        <React.Fragment>
          <Component
            {...this.props}
            openModal={this.openModal.bind(this)}
            closeModal={this.closeModal.bind(this)}
          />

          <Modal
            title={this.state.modalTitle}
            isOpen={this.state.modalIsOpen}
            isScrollable={this.state.isScrollable}
            onClose={this.closeModal.bind(this)}
          >
            {this.state.component}
          </Modal>
        </React.Fragment>
      );
    }

    openModal(modalTitle, component, modalIsOpen = true, isScrollable = true) {
      this.setState({
        modalTitle,
        modalIsOpen,
        component,
        isScrollable,
      });
    }

    closeModal() {
      this.setState({
        modalTitle: null,
        modalIsOpen: false,
        component: null,
      });
    }
  }

  WithModalWrapper.displayName = `WithModalWrapper(${getDisplayName(Component)})`;

  return ReactTimeout(WithModalWrapper);
}

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}
