import React, { useState } from 'react'

import PapaParse from 'papaparse'

import { request } from 'core/constants'

import returnFormattedFields from 'utils/returnFormattedFields'
import * as commons from 'utils/UtilityStore/actions'

import { FormFieldFile } from 'components/lib/FormFields'
import Header from 'components/lib/Header'

import withRedux from 'components/redux'

import PropertiesCompare from './components/PropertiesCompare'

import { Container } from './styles'

const ImportCSV = (props) => {
  const {
    payloadProperties,
    importUrl,
    validateUrl,
    entityName = 'Entidades',
    notification,
  } = props

  const [validationErrors, setValidationErrors] = useState({})
  const [possibleHeaders, setPossibleHeaders] = useState([])
  const [fileInputError, setFileInputError] = useState('')
  const [csvRows, setCsvRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [objectsValidated, setObjectsValidated] = useState({})

  const handleAddFile = async (files) => {
    if (files[0]) {
      if (files[0].ext === 'csv') {
        setFileInputError('')

        const file = await files[0].preview()

        PapaParse.parse(file, {
          download: true,
          header: true,
          skipEmptyLines: true,
          complete: ({ data, meta }) => {
            const headers = meta.fields.filter((f) => f)

            setPossibleHeaders(
              headers
                .map((name) => ({ name }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            )
            setValidationErrors({})
            setCsvRows(data)
          },
        })
      } else {
        setPossibleHeaders([])
        setFileInputError('O Arquivo inserido precisar ser um CSV.')
      }
    }
  }

  const formatCsvRows = async (propertyNameComparedObject) => {
    const dataWithComparedNames = csvRows.map((csvRow) => {
      const newRow = Object.entries(propertyNameComparedObject).reduce(
        (acc, [propertyName, propertyNameInTable]) => {
          if (!acc[propertyName]) {
            acc[propertyName] = csvRow[propertyNameInTable]
          }

          return acc
        },
        {},
      )

      const formattedObject = returnFormattedFields(newRow, false)

      return formattedObject
    })

    return dataWithComparedNames
  }

  const handleConfirmImport = async (propertyNameComparedObject) => {
    try {
      setIsLoading(true)
      setValidated(true)
      const dataWithComparedNames = await formatCsvRows(propertyNameComparedObject)

      setObjectsValidated(propertyNameComparedObject)

      await request.post(
        `/company/${props.company.id}${importUrl}`,
        dataWithComparedNames,
      )

      notification({
        message: `${entityName} importados com sucesso!`,
      })

      history.back()

      setIsLoading(false)
    } catch (err) {
      setValidationErrors(err?.errors ? err.errors : [])
      setIsLoading(false)

      notification({
        message: err?.message ? err.message : 'Erro ao importar',
        status: err ? 'warning' : 'danger',
      })
    }
  }

  return (
    <Container>
      <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
        Importar CSV
      </Header>

      <FormFieldFile
        onChange={handleAddFile}
        forceInvalid={!!fileInputError}
        errorMessage={fileInputError}
        accept='.csv'
        placeholder='Selecione um arquivo CSV'
      />

      {possibleHeaders.length > 0 && (
        <PropertiesCompare
          properties={payloadProperties}
          onConfirm={handleConfirmImport}
          possibleHeaders={possibleHeaders}
          validationErrors={validationErrors}
          isLoading={isLoading}
          validated={validated}
          objectsValidated={objectsValidated}
        />
      )}
    </Container>
  )
}

export default withRedux(() => ({}), { ...commons })(ImportCSV)
