import { request } from 'core/constants';
import { requestState, notification } from 'utils/UtilityStore/actions';

export const fetch = (filter, hasSuccessNotification) => (dispatch, store) => {
  if (hasSuccessNotification) {
    dispatch(
      notification({
        isOpen: true,
        message: hasSuccessNotification,
      })
    );
  }
  dispatch(requestState(true, 'members'));
  request
    .get('{company}/user', { filter: filter })
    .then((response) => {
      dispatch(requestState(false, 'members'));
      dispatch({
        type: 'FETCH_USERS',
        payload: response.data.filter((item) => item.id !== store().app.activeUser.id),
        listInfo: response.resultsInfo,
      });
    })
    .catch((error) => {});
};

export const updateItems = (newValue) => (dispatch) => {
  dispatch(
    notification({
      isOpen: true,
      message: 'Usuário atualizado com sucesso',
    })
  );
  dispatch({
    type: 'UPDATE_USERS',
    payload: newValue,
  });
};

export const updateStatus = (userId, value) => (dispatch) => {
  request
    .put(`{company}/user/${userId}`, { is_active: value })
    .then((response) => {
      dispatch(updateItems(response.data));
    })
    .catch((error) => {});
};

export const resendInvite = (userId) => (dispatch) => {
  request
    .post(`{company}/user/${userId}/resend-invite`, {
      return_to: location.origin + '/auth',
    })
    .then((response) => {
      dispatch(
        notification({
          isOpen: true,
          message: response.message,
        })
      );
    })
    .catch((error) => {
      dispatch(
        notification({
          isOpen: true,
          message: error.message,
          status: 'danger',
        })
      );
    });
};
