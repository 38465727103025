import { request } from 'core/constants';
import { requestState } from 'utils/UtilityStore/actions';

export const fetch = (propertyOwnerId) => (dispatch) => {
  dispatch(requestState(true, 'propertyOwner'));
  request
    .get(`{company}/property-owner/${propertyOwnerId}`)
    .then((response) => {
      dispatch(requestState(false, 'propertyOwner'));
      dispatch({
        type: 'FETCH_PROPERTY_OWNER',
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const clear = () => (dispatch) => {
  dispatch(requestState(null, 'propertyOwner'));
  dispatch({
    type: 'CLEAR_PROPERTY_OWNER',
  });
};
