import React from 'react';

import { baseURL } from 'core/constants';
import request from 'axios';

// Redux
import { connect } from 'react-redux';
import * as commons from 'utils/UtilityStore/actions';

// Components
import BodyContainer from 'components/lib/BodyContainer';
import ScreenHeader from 'components/ScreenHeader';
import SectionWrapper from 'components/SectionWrapper';
import Modal from 'components/lib/Modal';

import CreateProcess from './components/CreateProcess';

export class ProcessNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
      guarantorsIds: [],
    };
  }

  render() {
    return (
      <React.Fragment>
        <ScreenHeader header="Nova proposta" onClick={this.props.historyPush.bind(this)} />

        <BodyContainer isPadded>
          <SectionWrapper style={{ marginBottom: 100 }} header="Informações da proposta">
            <CreateProcess activeCompany={this.props.company} onSuccess={this.onSuccessHandler.bind(this)} onSelectGuarantor={this.onSelectGuarantor.bind(this)} />
          </SectionWrapper>
        </BodyContainer>

        <Modal title={this.state.modalTitle} isOpen={this.state.modalIsOpen} onClose={this.closeModal.bind(this)}>
          {this.state.feature}
        </Modal>
      </React.Fragment>
    );
  }

  closeModal() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    });
  }

  openModal(feature) {
    this.setState({
      modalTitle: 'Editar usuário da empresa',
      modalIsOpen: true,
      feature: feature,
    });
  }

  onSelectGuarantor(guarantorsIds) {
    console.log('guarantorsIds', guarantorsIds);
    this.setState({ guarantorsIds });
  }

  onSuccessHandler(response) {
    if (!response.isSuccess) {
      this.props.notification({
        message: 'Ocorreu um erro ao criar a proposta',
        status: 'danger',
      });
      return;
    }
    this.props.notification({
      message: 'Proposta criada com sucesso',
    });
    const homePath = this.props.location.pathname.replace('process/new', '');

    new Promise((resolve, reject) => {
      const token = localStorage.getItem('izee-token');
      const companyId = this.props.company.id;
      const url = `${baseURL}company/${companyId}/guarantor/associate-proposal`;
      const formData = {};
      const data = [];
      const proposalId = response.data.id;
      const { guarantorsIds } = this.state;
      const headers = `Authorization: Bearer ${token}`;

      guarantorsIds.map((id) => {
        data.push({
          proposal_id: proposalId,
          guarantor_id: id,
        });
      });

      formData['data'] = data;

      request
        .post(url, formData, headers)
        .then((response) => {
          resolve(this.props.history.push(`${homePath}proposal/${proposalId}`));
        })
        .catch((err) => {
          console.log('err', err);
          reject(this.props.history.push(`${homePath}proposal/${proposalId}`));
        });
    });
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    historyPush: store.app.helpers.historyPush,
  }),
  { ...commons }
)(ProcessNew);
