const getAtt = (el, att) => el.getAttribute(`data-${att}`); //get data attribute from DOM element

const mergeStatement = (object, attribute) => `{!! $${object}['${attribute}'] !!}`;

const ifStatementStart = (object, attribute) => `@if ($${object}['${attribute}'])`;
const ifStatementEnd = () => `@endif`;

const forStatementStart = (attributePlural, attribute) => `@foreach ($${attributePlural} as $${attribute})`;
const forStatementEnd = () => `@endforeach`;

const rawHtmlStringToBlade = (editorRawText) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = editorRawText;

  tmp.querySelectorAll('.merge-blot').forEach((el) => {
    const newTextEl = mergeStatement(getAtt(el, 'object'), getAtt(el, 'attribute'));
    el.replaceWith(newTextEl);
  });

  tmp.querySelectorAll('.operation-blot').forEach((el) => {
    const elType = getAtt(el, 'type');
    const elStart = getAtt(el, 'start');
    const isStartTag = elStart.toLowerCase() === 'true';
    const isEndTag = elStart.toLowerCase() === 'false';

    switch (elType) {
      case 'list':
        let listContent = '';

        if (isStartTag) {
          listContent = forStatementStart(getAtt(el, 'plural'), getAtt(el, 'object'));
        } else if (isEndTag) {
          listContent = forStatementEnd();
        }

        el.replaceWith(listContent);
        break;

      case 'conditional':
        let condicionalContent = '';

        if (isStartTag) {
          condicionalContent = ifStatementStart(getAtt(el, 'object'), getAtt(el, 'attribute'));
        } else if (isEndTag) {
          condicionalContent = ifStatementEnd();
        }

        el.replaceWith(condicionalContent);
        break;

      default:
        break;
    }
  });

  let before = document.createElement('DIV');
  before.innerHTML = editorRawText;

  const after = tmp.innerHTML;
  return after;
};

export default rawHtmlStringToBlade;
