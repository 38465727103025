import React from 'react';

import Select, { propsObject as defaultProps } from 'components/lib/FormFields/components/Select';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

import fieldCreator from './fieldCreator';

const FormFieldSelect = (props) => {

  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Field {...props}>
      <Control {...props}>
        <Select
          {...defaultProps(props)}
          options={props.options}
          valueKey={props.valueKey}
          labelKey={props.labelKey}
          hideBlankOption={props.hideBlankOption}
          isDanger={isValid || props.forceInvalid}
          style={ props.forceInvalid ? { borderColor:'#ff0000' } : null}
        />
      </Control>
      {props.forceInvalid && props.errorMessage ? <p className="help is-danger">{props.errorMessage}</p> : null}
    </Field>
  );
};

export default fieldCreator(FormFieldSelect, {
  masks: [],
  validators: [{ 'minLength': 1 }]
});
