import React from 'react';
import PropTypes from 'prop-types';

import gravatar from 'gravatar';

const Gravatar = (props) => {
  const size = props.size || 240;
  return (
    <img
      src={gravatar.url(props.email, { size: size, d: 'mp' })}
      alt={props.alt}
    />
  );
};

Gravatar.propTypes = {
  email: PropTypes.string.isRequired
};

export default Gravatar;
