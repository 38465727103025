import React from 'react'

import { connect } from 'react-redux'
import * as actions from './actions'
import * as commons from 'utils/UtilityStore/actions'
import AddDocument from '../Documents/AddDocument.js'
import documentList from './documentList.js'
import { request } from 'core/constants'

// Components
import Button from 'components/lib/Button'
import Table from 'components/lib/Table'
import BodyContainer from 'components/lib/BodyContainer'
import SectionWrapper from 'components/SectionWrapper'
import UpdateProperty from 'features/UpdateProperty'
import Icon from 'components/lib/Icon'
import PropertyOwnerSelection from 'features/components/fieldsGroup/PropertyOwnerSelection'

import ConfirmExcludeModal from 'components/ConfirmExcludeModal'

class PropertyDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      documents: {},
      documentsLength: 0,
      showExcludeModal: false,
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.propertyId)
  }

  componentWillUnmount() {
    this.props.clear()
  }

  handlePropertyOwners = (string, propertyOwners) => {
    const { id: companyId } = this.props.activeCompany
    const { id: propertyId, name } = this.props.current

    const url = `/company/${companyId}/property/${propertyId}`
    const data = { name }

    if (propertyOwners.length > 0) data.property_owner_ids = propertyOwners

    request
      .put(url, data)
      .then(() => {
        this.props.fetch(propertyId)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async requestDelete() {
    try {
      const deleteUrl = `/company/${this.props.activeCompany.id}/property/${this.props.match.params.propertyId}`

      await request.delete(deleteUrl)

      this.props.notification({
        message: 'Registro excluído com sucesso',
        status: 'success',
      })

      this.props.history.goBack()
    } catch (erro) {
      this.props.notification({
        message: erro.message || 'Erro ao tentar excluir o Imóvel',
        status: 'danger',
      })
    }
  }
  openDeleteModal() {
    this.setState({
      showExcludeModal: true,
    })
  }

  render() {
    let current = this.props.current

    if (current.address) {
      current = { ...current.address, ...current }
      current.address = current.address.address
      current.number = parseInt(current.number)
    }

    let trading_address = {}

    if (current.trading_address) {
      trading_address = {
        trading_address_zip_code: current.trading_address.zip_code,
        trading_address_address: current.trading_address.address,
        trading_address_number: parseInt(current.trading_address.number),
        trading_address_additional_address:
          current.trading_address.additional_address,
        trading_address_state: current.trading_address.state,
        trading_address_city: current.trading_address.city,
        trading_address_neighborhood: current.trading_address.neighborhood,
      }
    }

    current = { ...trading_address, ...current }

    const hasPropertyOwners = current.property_owners ? true : false

    const styles = {
      requestDelete: {
        borderWidth: 1,
        borderColor: '#EB144C',
        borderStyle: 'solid',
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
        color: '#EB144C',
        fontWeight: 'bold',
        marginRight: 20,
      },
    }

    const profileAdmin = this.props.activeCompany.profiles.find(
      (profile) =>
        // profile.code == 'PROFILE_COMPANY_OWNER_PARTNER' ||
        // profile.code == 'PROFILE_MANAGER' ||
        profile.code == 'PROFILE_ADMIN',
    )
    const userIzee = this.props.activeUser.role === 'ROLE_IZEE'
    const profileUpdateCreator = profileAdmin || userIzee

    return (
      <BodyContainer isLoading={!this.props.current.id}>
        {this.props.current.id && (
          <>
            <SectionWrapper
              header='Dados do imóvel'
              headerRight={
                <Button
                  onClick={() => this.openDeleteModal()}
                  style={styles.requestDelete}
                >
                  Excluir
                </Button>
              }
            >
              <UpdateProperty
                profileUpdateCreator={profileUpdateCreator}
                defaultValue={current}
                onSuccess={this.handleUpdateSuccess.bind(this)}
              />
            </SectionWrapper>
            <SectionWrapper header='Proprietários'>
              <Table
                items={this.props.current['property_owners'] || []}
                onClickRow={this.openRow.bind(this)}
                cells={[
                  { name: 'Nome', path: 'name' },
                  { name: 'Email', path: 'email' },
                ]}
                actionCell={(row) => (
                  <div className='buttons'>
                    <Button
                      isNeutral
                      isFlat
                      title='Editar'
                      onClick={this.openRow.bind(this, row)}
                    >
                      <Icon name='fas fa-pen-square' />
                    </Button>
                  </div>
                )}
              />
            </SectionWrapper>
            {hasPropertyOwners ? (
              <SectionWrapper header='Gerenciar Proprietários'>
                <PropertyOwnerSelection
                  showAddition
                  propertyOwners={this.props.current.property_owners}
                  handleFieldChanges={(string, propertyOwners) =>
                    this.handlePropertyOwners(string, propertyOwners)
                  }
                ></PropertyOwnerSelection>
              </SectionWrapper>
            ) : null}
            {this.state.showExcludeModal && (
              <ConfirmExcludeModal
                title='Excluir Fiador?'
                text='Confirmar exclusão deste fiador?'
                onClose={() =>
                  this.setState({
                    showExcludeModal: false,
                  })
                }
                onExclude={this.requestDelete.bind(this)}
              />
            )}
          </>
        )}
      </BodyContainer>
    )
  }

  renderDocuments() {
    const { propertyId } = this.props.match.params

    return documentList.map((item) => {
      return (
        <AddDocument
          name={item.name}
          onUpload={this.updateFileList.bind(this, item.name)}
          onAddFile={this.onAddFile.bind(this)}
          onInit={this.onAddFile.bind(this)}
          keyId={propertyId}
          typeRegister={'property'}
          key={item.id}
        />
      )
    })
  }

  onAddFile(documents) {
    let length = documents.length + this.state.documentsLength

    this.setState({ documentsLength: length })
  }

  updateFileList(listName, fileList) {
    const documents = this.state.documents

    documents[listName] = fileList
    this.setState({ documents })
  }

  openRow(row) {
    const { userId, companyId } = this.props.match.params

    this.props.history.push(
      `/app/${userId}/${companyId}/records/property-owners/${row.id}`,
    )
  }

  handleUpdateSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Dados do imóvel atualizados com sucesso',
      })
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao atualizar o imóvel',
        status: 'danger',
      })
    }
  }
}

export default connect(
  (store) => ({
    current: store.property.current,
    isWorking: store.commons.isWorking,
  }),
  { ...actions, ...commons },
)(PropertyDetails)
