import { uuid } from 'utils/utils';

const initialState = {
  isSuccess: null,
  actionMessage: '',
  actionKey: uuid(),
  errors: [],
  loginData: null,
  savedEmail: '',
  userExists: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'AUTH_ACTION_SUCCESS':
      state = {
        ...state,
        ...{
          isSuccess: true,
          actionKey: uuid(),
          loginData: action.payload.user,
          actionMessage: action.payload?.message,
          data: action.payload.data,
          errors: [],
        },
      };
      break;

    case 'AUTH_USER_EMAIL_CHECKED':
      state = {
        ...state,
        ...{
          isSuccess: action.payload.data.exists,
          actionKey: uuid(),
          userExists: action.payload.data.exists,
          actionMessage: action.payload.message,
        },
      };
      break;

    case 'AUTH_SIGN_OUT':
      state = {
        ...state,
        ...initialState,
      };
      break;

    case 'AUTH_ACTION_ERROR':
      state = {
        ...state,
        ...{
          isSuccess: false,
          actionKey: uuid(),
          actionMessage: action?.payload?.message,
          errors: action?.payload?.errors,
        },
      };
      break;

    case 'AUTH_SAVE_EMAIL':
      state = {
        ...state,
        ...{ savedEmail: action?.payload },
      };
      break;

    default:
      break;
  }

  return state;
};
