import React from "react";

import _propsObject from "./propsObject";

import parsePropsAsBulmaClasses from "components/lib/_utils/parsePropsAsBulmaClasses";

const Input = props => {
  const { numbersOnly } = props;

  const inputCSSClass = `input ${parsePropsAsBulmaClasses(props)}`;

  if (numbersOnly)
    return (
      <input
        inputMode ="decimal"
        className={inputCSSClass}
        {..._propsObject(props)}
      />
    );
  return <input className={inputCSSClass} {..._propsObject(props)} />;
};

export const propsObject = _propsObject;

export default Input;
