import Axios from 'axios';
import { baseURL } from 'core/constants';

let isUploadingFile = false;

const axios = Axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('izee-token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['Content-Type'] = isUploadingFile ? 'application/x-www-form-urlencoded' : 'application/json';
  isUploadingFile = false;
  return config;
});

const convertIntoQueryString = (endpoint, data) => {
  if (!data) {
    return endpoint;
  } else if (typeof data === 'string') {
    return `${endpoint}?${data}`;
  }

  const qs = Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&');

  return `${endpoint}?${qs}`;
};

const getBodyData = (data) => {
  if (!data) {
    return {};
  }

  if (!data.file) {
    return data;
  }

  const form = new FormData();

  const { file, ...rest } = data;

  form.append('file', file, 'image.png');

  Object.keys(rest).forEach((key) => form.append(key, rest[key]));
  isUploadingFile = true;

  return form;
};

const manageResponse = (method) => method.then((rs) => rs.data).catch((error) => Promise.reject({ ...error, error: error.response?.data?.message ?? 'Erro ao processar' }));

const get = (endpoint, queryString = null) => manageResponse(axios.get(convertIntoQueryString(endpoint, queryString)));
const post = (endpoint, body = null) => manageResponse(axios.post(endpoint, getBodyData(body)));
const put = (endpoint, body = null) => manageResponse(axios.put(endpoint, getBodyData(body)));
const deleteItem = (endpoint, queryString = null) => manageResponse(axios.delete(convertIntoQueryString(endpoint, queryString)));

export default {
  get,
  post,
  put,
  delete: deleteItem,
};
