import React from 'react';
import PropTypes from 'prop-types';

// Components
import Space from 'components/lib/Space';
import FlexBar from 'components/lib/FlexBar';
import Pagination from 'components/lib/Pagination';
import BodyContainer from 'components/lib/BodyContainer';
import SectionWrapper from 'components/SectionWrapper';

const listCreator = (Component) => {
  class ListWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.body = React.createRef();
    }

    render() {
      const pagination = this.props.pagination;
      return (
        <BodyContainer getRef={(ref) => (this.body = ref)}>
          <SectionWrapper pageWidth={this.props.pageWidth} customHeader={this.props.customHeader} header={this.props.listName} isMedium={this.props.isMedium} headerRight={this.props.actionButton}>
            <Component {...this.props} {...this.state} />

            <Space />

            <FlexBar>
              <FlexBar.Child isGrow>
                <Pagination current={pagination.currentPage} total={pagination.total} itemsPerPage={pagination.perPage} onChange={this.handlePageUpdate.bind(this)} />
              </FlexBar.Child>
            </FlexBar>
          </SectionWrapper>
        </BodyContainer>
      );
    }

    handlePageUpdate(newPage) {
      this.body.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });
      this.props.onChangePage(newPage);
    }
  }

  ListWrapper.displayName = `ListWrapper(${getDisplayName(Component)})`;

  ListWrapper.defaultProps = {
    onChangePage: () => {},
    pagination: {
      currentPage: 1,
      total: 0,
      perPage: 10,
    },
  };

  return ListWrapper;
};

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export default listCreator;
