import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 8px;
`;

export const TermContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > .field {
    margin-right: 4px;
    margin-bottom: 0px !important;
  }
`;

export const Text = styled.span`
  font-size: 17px;
  color: #7f8996;
`;

export const LinkText = styled.span`
  font-size: 17px;
  color: #2091ea;
  text-decoration: underline;

  cursor: pointer;
  user-select: none;
`;
