export default [
  {
    id: 0,
    filterBy: '*',
    name: 'Documento com foto',
  },
  {
    id: 1,
    filterBy: '*',
    name: 'Comprovante de residência',
  },
  {
    id: 2,
    filterBy: '*',
    name: 'Comprovante de renda',
  },
  {
    id: 3,
    filterBy: 4,
    name: 'Frente do cartão de crédito',
  },
  {
    id: 4,
    filterBy: 4,
    name: 'Última fatura paga do cartão de crédito',
    // }, {
    //   id: 4,
    //   filterBy: 5,
    //   name: 'Comprovante de matrícula'
  },
];
