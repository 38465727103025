/**
 * @class JsonParse
 * @author @cspilhere
 */

import { deepKey, getTypeOf } from 'utils/utils';

class JsonParse {

  constructor() {
    this.json = undefined;
  }

  create(string) {
    this.json = deserialize(string) || {};
    if (getTypeOf(this.json) !== 'Object') this.json = {};
    return this.json;
  }

  createInstance() {
    return new JsonParse();
  }

  // setProp(prop) {
  //   this.json = {
  //     ...this.json,
  //     ...prop
  //   };
  //   return this.json;
  // };

  set(path, value) {
    deepKey(this.json, path, value);
    return this.json;
  };

  get(path) {
    return deepKey(this.json, path);
  };

  all() {
    return this.json;
  };

  toString() {
    return serialize(this.json);
  };

};

function serialize(value) {
  return JSON.stringify(value);
}

function deserialize(value) {
  if (typeof value !== 'string') return undefined;
  try {
    return JSON.parse(value);
  } catch (error) {
    return value || undefined;
  }
};

// Export JsonParse
// let Instance = null;
// if (!(Instance instanceof JsonParse)) Instance = new JsonParse();
export default new JsonParse();
