import request from 'axios';
import { baseURL } from 'core/constants';
import { toQuery } from './OAuth2/utils';

request.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response?.data || error.response)
);

export const checkUserEmail = (body) => (dispatch) => {
  request
    .post(baseURL + 'auth/check-user', body)
    .then((response) => {
      if (!response.data.migrated) {
        return dispatch({
          type: 'AUTH_USER_EMAIL_CHECKED',
          payload: {
            data: response.data,
            message: !response.data.exists && 'Usuário ou Email não encontrado',
          },
        });
      }

      const authorizationUrl = OAUTH2_LOGIN_URL;
      const params = {
        client_id: 'redeizee',
        redirect_uri: IZEE_APP_URL + 'auth',
        response_type: 'token',
        username: body.username,
        scope: OAUTH2_SCOPES,
      };
      const paramsFormated = toQuery(params);
      window.location.href = `${authorizationUrl}?${paramsFormated}`;
    })
    .catch((error) => {
      dispatch({
        type: 'AUTH_ACTION_ERROR',
        payload: error,
      });
    });
};

export const login = (body) => (dispatch) => {
  request
    .post(baseURL + 'auth/login', body)
    .then((response) => {
      if (response.code == 'authentication.login.success') {
        const authorizationUrl = OAUTH2_REGISTER_URL;
        const params = {
          client_id: 'redeizee',
          redirect_uri: IZEE_APP_URL + 'auth',
          response_type: 'token',
          username: body.email,
          email: body.email,
          origin: 'IZEE',
          scope: OAUTH2_SCOPES,
        };
        const paramsFormated = toQuery(params);
        window.location.href = `${authorizationUrl}?${paramsFormated}`;
      } else {
        dispatch({
          type: 'AUTH_ACTION_ERROR',
          payload: error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: 'AUTH_ACTION_ERROR',
        payload: error,
      });
    });
};

export const recovery = (body) => (dispatch) => {
  request
    .post(baseURL + 'auth/recover-password', body)
    .then((response) => {
      dispatch({
        type: 'AUTH_ACTION_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'AUTH_ACTION_ERROR',
        payload: error,
      });
    });
};

export const register = (body) => (dispatch) => {
  request
    .post(baseURL + 'auth/register/' + body.hash, body)
    .then((response) => {
      dispatch({
        type: 'AUTH_ACTION_SUCCESS',
        payload: response.data,
      });
      localStorage.setItem('izee-token', response.data.token);
      dispatch({
        type: 'APP_SET_USER',
        payload: response.data.user,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'AUTH_ACTION_ERROR',
        payload: error,
      });
    });
};

export const invite = (body) => (dispatch) => {
  request
    .post(baseURL + 'auth/invite-company-owner', body)
    .then((response) => {
      dispatch({
        type: 'AUTH_ACTION_SUCCESS',
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'AUTH_ACTION_ERROR',
        payload: error,
      });
    });
};

export const saveEmail = (email) => (dispatch) => {
  dispatch({
    type: 'AUTH_SAVE_EMAIL',
    payload: email,
  });
};

export const resetUserEmailCheck = () => (dispatch) => {
  dispatch({
    type: 'AUTH_SIGN_OUT',
  });
};
