import React from 'react';

// Components
import Space from 'components/lib/Space';
import Button from 'components/lib/Button';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';
import { FormFieldEmail, FormFieldText } from 'components/lib/FormFields';
import Assistant from 'components/Assistant';
import SelectPlan from 'components/SelectPlan';

import actionCreator from '../authCreator';

// Utils
import { goodObject } from 'utils/utils';

export class Invite extends React.Component {
  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {
    const { formIsWorking, handleFormField, fieldHasError, fieldErrors } =
      this.props;

    return (
      <React.Fragment>
        <Assistant
          words={'Olá! Quem vamos convidar hoje?'}
          style={{ marginBottom: 30 }}
        />

        <FormFieldText
          label="Nome"
          name="name"
          isDisabled={formIsWorking}
          onChange={handleFormField.bind(this, 'name')}
        />

        <FormFieldEmail
          isDisabled={formIsWorking}
          forceInvalid={fieldHasError && fieldErrors['email']}
          errorMessage={fieldErrors['email']}
          onChange={handleFormField.bind(this, 'email')}
        />

        <SelectPlan
          forceInvalid={fieldHasError && fieldErrors['subscription_plan_id']}
          errorMessage={fieldErrors['subscription_plan_id']}
          onChange={handleFormField.bind(this, 'plan')}
        />

        <Space />

        <Field>
          <Control>
            <div style={{ width: '100%', display: 'inline-flex' }}>
              <Button
                isFullWidth
                isLarge
                isLoading={formIsWorking}
                isDisabled={formIsWorking}
                submit>
                Convidar
              </Button>
            </div>
          </Control>
        </Field>
      </React.Fragment>
    );
  }

  whatsAppShare(url) {
    let body =
      'Rede Izee: Convite para nova imobiliária. Clique no link para continuar.';
    let link = `https://api.whatsapp.com/send?text=${body}%0a${url}`;
    let left =
      window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 520 / 2;
    let top =
      window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 570 / 2;
    window.open(
      link,
      '',
      `height=570,width=520,left=${left},top=${top}location=no,scrollbars=yes,status=no,toolbar=no,directories=no,menubar=no,resizable=no,centerscreen=yes,chrome=yes`
    );
  }

  createRequest(fields) {
    let goodFields = goodObject(fields, {
      name: 'name.value',
      email: 'email.value',
      subscription_plan_id: 'plan.value',
    });
    goodFields['return_to'] = location.origin + '/auth';
    this.props.invite(goodFields);
  }
}

export default actionCreator(Invite);
