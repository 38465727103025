import React from 'react';
import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

export default class BodyContainer extends React.Component {

  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.pathname = '';
  }

  componentDidMount() {
    this.props.getRef(this.container.current);
    this.pathname = location.pathname;
  }

  getSnapshotBeforeUpdate() {
    return null;
  }

  componentDidUpdate() {
    if (this.pathname !== location.pathname) {
      this.pathname = location.pathname;
      this.container.current.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': 0
      });
    }
  }

  render() {

    const hasLoadingProp = this.props.isLoading !== null && this.props.isLoading !== undefined;
    const isLoading = hasLoadingProp && this.props.isLoading;

    let CSSClass = `body-container ${
      parsePropsAsBulmaClasses(this.props)
    }${
      hasLoadingProp ? ' has-loading' : ''
    }${
      isLoading ? ' is-loading' : ''
    }`;

    return (
      <div className={CSSClass} ref={this.container} style={this.props.style}>
        {isLoading ? <div className="empty-state-spinner" /> : null}
        {this.props.children}
      </div>
    );
  }

};

BodyContainer.defaultProps = {
  getRef: () => {}
};
