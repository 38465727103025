import React, { useState } from 'react'

import request from 'axios'

import * as types from '../types'
import { Input, InputContainer, Loading } from './styles'
import Icon from 'components/lib/Icon'

const entityIdMap = {
  proposal: 1,
  property: 2,
  propertyOwner: 3,
  client: 4,
  guarantor: 5,
  processPossession: 6,
  processInsurance: 7,
}

const FileButton = (props) => {
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)

  const uploadDocument = (event) => {
    if (!event) return

    setUploading(true)
    const { docsUrl, row, entityName, bearerToken } = props
    const file = event.target.files[0]

    const dbEntityId = entityIdMap?.[entityName] || '' // Default to empty string if not found

    const formData = new FormData()

    formData.append('file', file)
    formData.append('status', 'STATUS_ANALYSIS')
    formData.append('id', row.id)
    formData.append('name', row.name)
    formData.append('entity_id', dbEntityId)
    formData.append('owner_document', props.entityId)

    const config = {
      headers: bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {},
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )

        setProgress(percentCompleted)
      },
    }

    request
      .post(docsUrl, formData, config)
      .then(() => {
        setUploading(false)
        props.refresh()
      })
      .catch(() => {
        setUploading(false)
        props.refresh()
      })
  }

  const { row, isMobile, className } = props
  const { status } = row

  let button = null

  const label = props.label || null
  const showIcon = props.showIcon

  switch (status) {
    case types.STATUS_WAITING:
      button = (
        <InputContainer documentSaved={false} isMobile={isMobile}>
          <Input type='file' id={`document_${row.id}`} onChange={uploadDocument} />

          <label className={className} htmlFor={`document_${row.id}`}>
            {(showIcon || typeof showIcon == 'undefined') && (
              <Icon name='fas fa-upload' />
            )}

            <span>{label ? label : 'Adicionar documento'}</span>
          </label>
        </InputContainer>
      )
      break
    case types.STATUS_APPROVED:
    case types.STATUS_ANALYSIS:
    case types.STATUS_REJECTED:
      button = (
        <InputContainer documentSaved isMobile={isMobile}>
          <Input type='file' id={`document_${row.id}`} onChange={uploadDocument} />

          <label className={className} htmlFor={`document_${row.id}`}>
            {(showIcon || typeof showIcon == 'undefined') && (
              <Icon name='fas fa-upload' />
            )}

            <span>{label ? label : 'Reenviar Documento'}</span>
          </label>
        </InputContainer>
      )
  }

  if (uploading) {
    button = (
      <InputContainer isMobile={isMobile}>
        <Loading>Carregando... {progress}%</Loading>
      </InputContainer>
    )
  }

  return button
}

export default FileButton
