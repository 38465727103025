import React, { useEffect, useState, useRef } from 'react'

import axios from 'axios'

import { request } from 'core/constants'

import { notification } from 'utils/UtilityStore/actions'

import LoadingContainer from 'components/lib/LoadingContainer'
import { FormFieldSwitch } from 'components/lib/FormFields'
import Dropdown from 'components/lib/Dropdown'
import Button from 'components/lib/Button'
import withRedux from 'components/redux'
import Icon from 'components/lib/Icon'

import { updateUserMeta } from './actions'

import { FilterOnAlert } from './styles'

const ListFilter = (props) => {
  const { submitForm, initialProposalFilters, notification, updateUserMeta } = props

  const cancelTokenRef = useRef(null)

  const [proposalFilters, setProposalFilters] = useState(initialProposalFilters)

  const [loadingFilters, setLoadingFilters] = useState(true)

  const [filters, setFilters] = useState([])

  const getFilters = async () => {
    try {
      const { data } = await request.get('proposal-status')

      if (window.location.pathname.includes('property-capture')) {
        const codesToRemove = [
          'STATUS_HANDOVER_KEYS',
          'STATUS_REFUSED',
          'STATUS_NEW',
        ]
        const filteredArray = data.filter(
          (item) => !codesToRemove.includes(item.code),
        )

        setFilters(filteredArray)
      } else {
        setFilters(data)
      }
    } catch (err) {
      notification({
        message: 'Não foi possível carregar os filtros',
        status: 'danger',
      })
    } finally {
      setLoadingFilters(false)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  const handleFilterChange = async (filter) => {
    const newUserFilters = {
      ...proposalFilters,
      ...{
        [filter]: !proposalFilters[filter],
      },
    }

    setProposalFilters(newUserFilters)

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel()
    }

    try {
      cancelTokenRef.current = axios.CancelToken.source()

      const { data } = await request.put(
        'me',
        { meta: JSON.stringify({ proposalFilters: newUserFilters }) },
        { cancelToken: cancelTokenRef.current.token },
      )

      updateUserMeta(data)

      submitForm()
    } catch (err) {
      if (err) {
        notification({
          message: 'Não foi possível alterar os filtros',
          status: 'danger',
        })
      }
    }
  }

  return (
    <Dropdown
      isRight
      keepOpen
      trigger={
        <Button
          hasThemeColor
          style={{
            padding: '6px 13px 6px 4px',
          }}
        >
          <FilterOnAlert
            style={{
              opacity: Object.values(proposalFilters).find((f) => f) ? 1 : 0,
            }}
          />
          <Icon name='fa fa-filter' />
        </Button>
      }
    >
      <LoadingContainer isVisible={loadingFilters}>
        {filters.map((filter) => (
          <Dropdown.Item key={filter.id}>
            <FormFieldSwitch
              description={filter.name}
              onClick={() => handleFilterChange(filter.code)}
              defaultChecked={proposalFilters[filter.code] || false}
            />
          </Dropdown.Item>
        ))}
      </LoadingContainer>
    </Dropdown>
  )
}

export default withRedux(
  (state) => ({
    initialProposalFilters: state.app.userMeta?.proposalFilters || {},
  }),
  {
    updateUserMeta,
    notification,
  },
)(ListFilter)
