import { request } from 'core/constants'
import { requestState } from 'utils/UtilityStore/actions'

export const fetch =
  (filter, order = null, orderDirection = null) =>
  (dispatch) => {
    dispatch(requestState(true, 'clients'))
    const orderBy = order && orderDirection ? order + ',' + orderDirection : ''

    request
      .get(
        `{company}/client?page=${filter?.page || 1}&search=${
          filter?.search || ''
        }&orderBy=${orderBy}`,
      )
      .then((response) => {
        dispatch(requestState(false, 'clients'))
        dispatch({
          type: 'FETCH_CLIENTS',
          payload: response.data,
          listInfo: response.resultsInfo,
        })
      })
      .catch((error) => {})
  }
