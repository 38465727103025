import React from 'react';

import File, { propsObject as defaultProps } from 'components/lib/FormFields/components/File';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

import fieldCreator from './fieldCreator';

class FormFieldFile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filename: null,
      files: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.filename === null) {
      return {
        filename: props.filename
      };
    }
    return null;
  }

  render() {

    const isValid = this.props.isValid !== null && !this.props.isValid && !this.props.isEmpty;
    const showMessage = this.props.forceInvalid && this.props.errorMessage;

    if (this.props.render) {
      return (
        <File
          {...defaultProps(this.props)}
          label={this.props.label}
          onChange={this.handleFileChanges.bind(this)}>
          {this.props.render({
            ...this.props,
            ...this.state,
            filename: this.state.filename,
            isValid: isValid,
            showMessage: showMessage
          })}
        </File>
      );
    }

    return (
      <Field {...this.props}>
        <Control {...this.props}>
          <File
            {...defaultProps(this.props)}
            hasName
            filename={this.state.filename}
            description={this.props.description}
            onChange={this.handleFileChanges.bind(this)}
            isDanger={isValid || this.props.forceInvalid}
          />
        </Control>
        {showMessage ? <p className="help is-danger">{this.props.errorMessage}</p> : null}
      </Field>
    );

  }

  handleFileChanges(event) {

    const files = [];
    let filename = [];

    const readAsText = this.props.readAsText;

    function readFiles(file) {
      filename.push(file.name);
      files.push({
        raw: file,
        name: file.name,
        size: file.size,
        ext: file.name ? file.name.match(/[^.]+$/)[0] : null,
        preview: () => new Promise((resolve) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve(reader.result));
          if (readAsText) {
            reader.readAsText(file);
          } else {
            reader.readAsDataURL(file);
          }
        })
      });
    }

    if (event.target.files) {
      [...event.target.files].forEach(readFiles);
    }

    this.setState({
      filename: filename.join(', '),
      files: files
    });

    this.props.onChange({
      target: {
        type: 'file',
        value: files
      }
    });

  }

};

export default fieldCreator(FormFieldFile, {
  masks: [],
  validators: []
});
