import React from 'react';

// Components
import { FormFieldCheckbox } from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import Space from 'components/lib/Space';
import Header from 'components/lib/Header';
import Button from 'components/lib/Button';

import { fb } from '../../../firebase';

import integrations from '../../';

export default class EditSolicitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantsIncluded: [],
      applicantsNotIncluded: [],
      isLoading: false,
      applicantValid: true,
      solicitation: null,
      message: '',
    };
  }

  componentDidMount() {
    this.getSolicitation();
  }

  getSolicitation() {
    const { companyId, solicitationId } = this.props;

    this.setState({ isLoading: true, message: 'Buscando Solicitação...' });

    const onError = (message, error) => {
      console.log(message, error);

      this.props.notification({
        message:
          error.message || 'Não foi possível carregar os dados, tente novamente',
        status: 'danger',
      });

      this.props.setModalState(false);
    };

    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'DETAILS',
          companyId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (data.app.response.success) {
          this.setState({ solicitation: data.app.response.data });

          fb.fichacertaSolicitationById(solicitationId, ({ success, data }) => {
            try {
              if (success && data) {
                this.formatapplicants(Object.values(data.applicants));
              }
            } catch (error) {
              onError(
                'Error: editSolicitation, function fichacertaSolicitationById',
                error
              );
            }
          });

          this.setState({ isLoading: false, message: '' });
        } else {
          console.log('Fichacerta detalhes da solicitação falhou', success, data);

          this.props.notification({
            message: data.app.response.message || 'Falha ao receber detalhes',
            status: 'danger',
          });

          this.props.setModalState(false);
        }
      })
      .catch((error) => {
        console.error(
          'Error: editProposalSolicitation, get DETAILS from ficha certa',
          error
        );

        this.props.notification({
          message:
            error.message || 'Não foi possível carregar os dados, tente novamente',
          status: 'danger',
        });

        this.props.setModalState(false);
      });
  }

  formatapplicants(applicantsIncluded) {
    const { clients, guarantors } = this.props;
    const applicantsNotIncluded = [];

    if (clients?.length > 0) {
      clients.map((item) => {
        if (item.cnpj) {
          if (
            !applicantsIncluded.find(
              (a) =>
                a.cnpj?.replace(/[^\d]+/g, '') == item.cnpj?.replace(/[^\d]+/g, '')
            )
          ) {
            applicantsNotIncluded.push({
              applicant: { ...item, izeeApplicantType: 'INQUILINO-PJ' },
              key: item.email + item.id,
              applicantType: 'OUTROS',
            });
          }
        } else {
          if (
            !applicantsIncluded.find(
              (a) =>
                a.cpf?.replace(/\./g, '').replace('-', '') ==
                item.cpf?.replace(/\./g, '').replace('-', '')
            )
          ) {
            applicantsNotIncluded.push({
              applicant: item,
              key: item.email,
              applicantType: 'INQUILINO',
            });
          }
          if (item.spouse_email) {
            if (
              !applicantsIncluded.find(
                (a) =>
                  a.cpf?.replace(/\./g, '').replace('-', '') ==
                  item.spouse_cpf?.replace(/\./g, '').replace('-', '')
              )
            ) {
              applicantsNotIncluded.push({
                applicant: item,
                key: item.spouse_email,
                applicantType: 'CONJUGE_INQUILINO',
              });
            }
          }
        }
      });
    }

    if (guarantors?.length > 0) {
      guarantors.map((item) => {
        if (item.cnpj) {
          if (
            !applicantsIncluded.find(
              (a) =>
                a.cnpj?.replace(/[^\d]+/g, '') == item.cnpj?.replace(/[^\d]+/g, '')
            )
          ) {
            applicantsNotIncluded.push({
              applicant: { ...item, izeeApplicantType: 'FIADOR-PJ' },
              key: item.email + item.id,
              applicantType: 'OUTROS',
            });
          }
        } else {
          if (
            !applicantsIncluded.find(
              (a) =>
                a.cpf?.replace(/\./g, '').replace('-', '') ==
                item.cpf?.replace(/\./g, '').replace('-', '')
            )
          ) {
            applicantsNotIncluded.push({
              applicant: item,
              key: item.email,
              applicantType: 'FIADOR',
            });
          }
          if (item.spouse_email) {
            if (
              !applicantsIncluded.find(
                (a) =>
                  a.cpf?.replace(/\./g, '').replace('-', '') ==
                  item.spouse_cpf?.replace(/\./g, '').replace('-', '')
              )
            ) {
              applicantsNotIncluded.push({
                applicant: item,
                key: item.spouse_email,
                applicantType: 'CONJUGE_FIADOR',
              });
            }
          }
        }
      });
    }
    this.setState({
      applicantsNotIncluded: applicantsNotIncluded,
      applicantsIncluded: applicantsIncluded,
    });
  }

  formatName(item) {
    if (item.applicant) {
      // nao incluidos
      switch (item.applicantType) {
        case 'OUTROS':
          return item.applicant.corporate_name + ' - Empresa';

        case 'INQUILINO':
          return item.applicant.name + ' - Cliente';

        case 'FIADOR':
          return item.applicant.name + ' - Fiador';

        case 'CONJUGE_INQUILINO':
          return item.applicant.spouse_name + ' - Conjuge do(a) cliente';

        case 'CONJUGE_FIADOR':
          return item.applicant.spouse_name + ' - Conjuge do Fiador';
      }
    }
    else {
      // incluidos
      switch (item.applicantType) {
        case 'OUTROS':
          return item.corporate_name + ' - Empresa';

        case 'INQUILINO':
          return item.name + ' - Cliente';

        case 'FIADOR':
          return item.name + ' - Fiador';

        case 'CONJUGE_INQUILINO':
          return item.name + ' - Conjuge do(a) cliente';

        case 'CONJUGE_FIADOR':
          return item.name + ' - Conjuge do Fiador';
      }
    }
  }

  render() {
    const insufficientCredits = this.props.creditAnalysisTickets <= 0;
    const solicitation = this.state.solicitation;

    return (
      <React.Fragment>
        {solicitation && !this.state.isLoading ? (
          <>
            <div>
              <Header
                isTiny
                isUppercase
                subtitle={<hr style={{ marginTop: '0.5rem' }} />}
              >
                Locação
                <Button
                  isSmall
                  disabled={this.state.isLoading}
                  onClick={() => this.updateSolicitation()}
                >
                  Atualizar informações do processo
                </Button>
              </Header>

              <Grid>
                {solicitation?.locacao && (
                  <Grid.Col>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Código do imóvel</b>: {solicitation.locacao?.codigo_imovel}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Proprietário do imóvel</b>:{' '}
                      {solicitation.locacao?.proprietario}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Tipo de imovel</b>: {solicitation.locacao?.tipo_imovel}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Valor do aluguel</b>: {solicitation.locacao?.aluguel}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Valor do condomínio</b>: {solicitation.locacao?.condominio}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Valor do IPTU</b>: {solicitation.locacao?.iptu}
                    </p>
                  </Grid.Col>
                )}
                {solicitation?.locacao?.endereco?.[0] && (
                  <Grid.Col>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Endereço</b>: {solicitation.locacao.endereco[0].logradouro}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Número</b>: {solicitation.locacao.endereco[0].numero}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Complemento</b>:{' '}
                      {solicitation.locacao.endereco[0].complemento}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Bairro</b>: {solicitation.locacao.endereco[0].bairro}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Cidade</b>: {solicitation.locacao.endereco[0].cidade}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Estado</b>: {solicitation.locacao.endereco[0].uf}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Cep</b>: {solicitation.locacao.endereco[0].cep}
                    </p>
                  </Grid.Col>
                )}
              </Grid>
            </div>
            <div className="mt-3">
              <Header
                isTiny
                isUppercase
                subtitle={<hr style={{ marginTop: '0.5rem' }} />}
              >
                Pretendentes incluídos
              </Header>

              <Grid>
                <Grid.Col>
                  {this.state.applicantsIncluded && (
                    <div>
                      {this.state.applicantsIncluded.map((item, index) => {
                        return (
                          <div
                            className="mb-2"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {this.formatName(item)}
                            <Button
                              style={{ marginLeft: '10px' }}
                              isSmall
                              disabled={this.state.isLoading}
                              onClick={() => this.updateApplicant(item)}
                            >
                              Atualizar Pretendente
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Grid.Col>
              </Grid>
            </div>

            {this.state.applicantsNotIncluded.length > 0 && (
              <div className="mt-3">
                <Header
                  isTiny
                  isUppercase
                  subtitle={<hr style={{ marginTop: '0.5rem' }} />}
                >
                  Pretendentes não incluídos
                </Header>

                <Grid>
                  <Grid.Col>
                    <div className="text-right mb-2">
                      Créditos restantes: {this.props.creditAnalysisTickets}
                    </div>
                    {!this.state.applicantValid && (
                      <div className="mb-4">
                        <p>
                          Informe os dados do pretendente para incluir na análise:
                        </p>
                        <ul>
                          <li>
                            <b>- Nome</b>
                          </li>
                          <li>
                            <b>- CPF</b>
                          </li>
                          <li>
                            <b>- Renda</b>
                          </li>
                          <li>
                            <b>- Fonte de Renda</b>
                          </li>
                        </ul>
                      </div>
                    )}
                    {insufficientCredits && (
                      <div className="mb-4">
                        <p>
                          <b>
                            Quantidade de créditos insuficiente para incluir novos
                            pretendentes.
                          </b>
                        </p>
                      </div>
                    )}

                    {this.state.applicantsNotIncluded.map((item, index) => {
                      return (
                        <div
                          className="mb-2"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {this.formatName(item)}
                          <Button
                            style={{ marginLeft: '10px' }}
                            isSmall
                            disabled={this.state.isLoading || insufficientCredits}
                            onClick={() => this.addApplicant(item)}
                          >
                            Incluir Pretendente
                          </Button>
                        </div>
                      );
                    })}

                    <div className="mb-2 mt-3">
                      * Cada pretendente incuido irá consumir 1 credito.
                    </div>
                  </Grid.Col>
                </Grid>
              </div>
            )}
          </>
        ) : (
          <div>
            <div style={{ height: '250px', marginTop: 100 }}>
              <div
                style={{ margin: '0 auto', padding: '45px 60px' }}
                className="empty-state-spinner"
              />
              <p className="text-center mt-2">
                {' '}
                <strong>{this.state.message}</strong>
              </p>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  validateApplicant(item) {
    let isValid = true;

    if (
      item.applicantType == 'OUTROS' &&
      (!item.applicant.corporate_name || !item.applicant.cnpj)
    ) {
      isValid = false;
    }

    if (
      (item.applicantType == 'INQUILINO' || item.applicantType == 'FIADOR') &&
      (!item.applicant.name || !item.applicant.cpf || !item.applicant.income_source)
    ) {
      isValid = false;
    }

    if (
      (item.applicantType == 'CONJUGE_INQUILINO' ||
        item.applicantType == 'CONJUGE_FIADOR') &&
      (!item.applicant.spouse_name ||
        !item.applicant.spouse_cpf ||
        !item.applicant.spouse_income_source)
    ) {
      isValid = false;
    }
    this.setState({ applicantValid: isValid });
    return isValid;
  }

  addApplicant(item) {
    let isValid = this.validateApplicant(item);

    if (!isValid) {
      return;
    }

    const { companyId, companyDocument, solicitationId, proposalId } = this.props;

    this.setState({ isLoading: true, message: 'Adicionando pretendente...' });
    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'APPLICANT_ADD',
          companyId,
          companyDocument,
          proposalId: proposalId,
          solicitationId: solicitationId,
          applicant: item.applicant,
          applicantType: item.applicantType,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao adicionar pretendente, retorna o erro
          this.setState({ isLoading: false });
          this.props.onSuccess(success, data, true);
          return;
        }

        this.executeReport(
          companyId,
          proposalId,
          solicitationId,
          success,
          data,
          'Pretendente adiconado'
        ); // executa o laudo
      })
      .catch((error) => {
        console.log('Fichacerta create error', error);
      });
  }

  updateApplicant(item) {

    const {
      clients,
      guarantors,
      companyId,
      solicitationId,
      proposalId,
      property,
      propertyOwner,
    } = this.props;
    let applicantUpdate = {};

    if (item.applicantType === 'OUTROS' && item.izeeApplicantType === 'INQUILINO-PJ') {
      applicantUpdate = clients.find(
        (user) =>
          user.cnpj?.replace(/[^\d]+/g,'') ==
          item.cnpj?.replace(/[^\d]+/g,'')
      );
    }
    if (item.applicantType === 'OUTROS' && item.izeeApplicantType === 'FIADOR-PJ') {
      applicantUpdate = guarantors.find(
        (user) =>
          user.cnpj?.replace(/[^\d]+/g,'') ==
          item.cnpj?.replace(/[^\d]+/g,'')
      );
    }
    if (item.applicantType === 'INQUILINO') {
      applicantUpdate = clients.find(
        (user) =>
          user.cpf?.replace(/\./g, '').replace('-', '') ==
          item.cpf?.replace(/\./g, '').replace('-', '')
      );
    }
    if (item.applicantType === 'CONJUGE_INQUILINO') {
      applicantUpdate = clients.find(
        (user) =>
          user.spouse_cpf?.replace(/\./g, '').replace('-', '') ==
          item.cpf?.replace(/\./g, '').replace('-', '')
      );
    }
    if (item.applicantType === 'FIADOR') {
      applicantUpdate = guarantors.find(
        (user) =>
          user.cpf?.replace(/\./g, '').replace('-', '') ==
          item.cpf?.replace(/\./g, '').replace('-', '')
      );
    }
    if (item.applicantType === 'CONJUGE_FIADOR') {
      applicantUpdate = guarantors.find(
        (user) =>
          user.spouse_cpf?.replace(/\./g, '').replace('-', '') ==
          item.cpf?.replace(/\./g, '').replace('-', '')
      );
    }


    this.setState({
      isLoading: true,
      message: 'Atualizando informações do pretendente...',
    });
    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'APPLICANT_UPDATE',
          companyId,
          proposalId: proposalId,
          solicitationId: solicitationId,
          applicantId: item.id,
          property,
          propertyOwner,
          applicant: applicantUpdate,
          applicantType: item.applicantType,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao atualizar pretendente, retorna o erro
          this.setState({ isLoading: false });
          this.props.onSuccess(success, data, true);
          return;
        }

        this.reprocessReport(
          companyId,
          proposalId,
          solicitationId,
          success,
          data,
          'Análise do pretendente atualizada'
        ); // reprocessa o laudo
      })
      .catch((error) => {
        console.log('Fichacerta create error', error);
      });
  }

  updateSolicitation() {
    const { companyId, solicitationId, proposalId, property, propertyOwner } =
      this.props;

    this.setState({
      isLoading: true,
      message: 'Atualizando informações do processo...',
    });
    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'UPDATE',
          companyId,
          proposalId: proposalId,
          solicitationId: solicitationId,
          property,
          propertyOwner,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao atualizar a solicitação, retorna o erro
          this.setState({ isLoading: false });
          this.props.onSuccess(success, data, true);
          return;
        }

        this.reprocessReport(
          companyId,
          proposalId,
          solicitationId,
          success,
          data,
          'Análise da locação atualizada'
        ); // reprocessa o laudo
      })
      .catch((error) => {
        console.log('Fichacerta create error', error);
      });
  }

  executeReport(
    companyId,
    proposalId,
    solicitationId,
    successCreate,
    dataCreate,
    message
  ) {
    this.setState({ message: 'Solicitando laudo do pretendente...' });
    integrations
      .apps({
        // executa o laudo
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_EXECUTE',
          companyId,
          proposalId: proposalId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro na criação do laudo, retorna o erro
          this.setState({ isLoading: false, message: '' });
          this.props.onSuccess(success, data, true);
          return;
        }

        this.getSolicitation();
        this.setState({ isLoading: false, message: '' });
        this.props.onSuccess(successCreate, dataCreate, true, message);

        return { success, data };
      })
      .catch((error) => {
        console.log('Fichacerta create error', error);
      });
  }

  reprocessReport(
    companyId,
    proposalId,
    solicitationId,
    successCreate,
    dataCreate,
    message
  ) {
    this.setState({ message: 'Reprocessando laudo...' });
    integrations
      .apps({
        // reprocessa o laudo
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_REPROCESS',
          companyId,
          proposalId: proposalId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao reprocessar o laudo, retorna o erro
          this.setState({ isLoading: false });
          this.props.onSuccess(success, data, true);
          return;
        }

        this.getSolicitation();
        this.setState({ isLoading: false, message: '' });
        this.props.onSuccess(successCreate, dataCreate, true, message);

        return { success, data };
      })
      .catch((error) => {
        console.log('Fichacerta create error', error);
      });
  }
}
