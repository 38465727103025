import React from 'react';

// Components
import SubmitButton from 'components/SubmitButton';

import formCreator from '../components/formCreator';
import Company from '../components/fieldsGroup/Company';
import FullAddress from '../components/fieldsGroup/FullAddress';

export class UpdateCompany extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>

        <Company {...this.props} />

        <FullAddress {...this.props} noValidate />

        <SubmitButton
          label="Salvar"
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {
    let { defaultValue } = this.props;
    
    if ((defaultValue?.cnpj || defaultValue?.cpf) && this.props.activeUser?.role != 'ROLE_IZEE') {
      delete fields.cnpj;
      delete fields.cpf;
    }

    this.props.submit(`company/${defaultValue.id}`, fields, 'put');
  }

};

export default formCreator(UpdateCompany);
