import React, { useState, useEffect } from 'react'

import { FormFieldSelect } from 'components/lib/FormFields'
import Button from 'components/lib/Button'
import Header from 'components/lib/Header'
import Grid from 'components/lib/Grid'
import Icon from 'components/lib/Icon'
import Modal from 'components/lib/Modal'

import request from 'axios'
import { baseURL } from 'core/constants'

import {
  Container,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Text,
  ErrorContainer,
  ErrorText,
  SmallErrorText,
  ButtonContainer,
  ButtonInfo,
  ErrorContainerLines,
} from './styles'

const PropertiesCompare = (props) => {
  const {
    properties,
    possibleHeaders,
    onConfirm,
    validationErrors,
    isLoading,
    validated,
    objectsValidated,
  } = props

  const [propertiesNameCompared, setPropertiesNameCompared] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [propertyTypes, setPropertyTypes] = useState([])
  const [maritalStatusTypes, setMaritalStatusTypes] = useState([])
  const [captions, setCaptions] = useState([])

  useEffect(() => {
    async function fetchCaptions() {
      try {
        await request
          .get(`${baseURL}marital-status?orderBy=id,asc`)
          .then((response) => {
            setMaritalStatusTypes(response.data)
          })
          .catch(() => {})

        await request
          .get(`${baseURL}properties-type?orderBy=id,asc`)
          .then((response) => {
            let formatedItens = response.map((item) => {
              return {
                ...item,
                name: item.description,
              }
            })

            setPropertyTypes(formatedItens)
          })
          .catch(() => {})
      } catch (error) {
        console.log(error)
      }
    }
    fetchCaptions()
  }, [])

  const handleChangePropertyComparison = async (property, comparisonProperty) => {
    const newPropertiesNameCompared = {
      ...propertiesNameCompared,
      ...{
        [property]: comparisonProperty,
      },
    }

    if (!property || !comparisonProperty) {
      delete newPropertiesNameCompared[property]
    }

    setPropertiesNameCompared(newPropertiesNameCompared)
  }

  const handleConfirmClick = () => onConfirm(propertiesNameCompared)

  const showModalCaption = (name) => {
    if (name == 'property.type_id') {
      setCaptions(propertyTypes)
    } else {
      setCaptions(maritalStatusTypes)
    }
    setIsModalOpen(true)
  }

  const closeModalCaption = () => {
    setIsModalOpen(false)
  }

  return (
    <Container>
      <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
        Ajustar campos
      </Header>

      <Grid.Col>
        <TableRow>
          <TableHeader>
            <Grid.Col>
              <Text>Campo na Izee</Text>
            </Grid.Col>
          </TableHeader>

          <TableHeader>
            <Grid.Col>
              <Text>Campo no CSV</Text>
            </Grid.Col>
          </TableHeader>

          <TableHeader>
            <Grid.Col>
              <Text>Validação</Text>
            </Grid.Col>
          </TableHeader>
        </TableRow>

        <TableBody>
          {properties.map((property, i) => (
            <TableRow key={i}>
              <TableCell>
                <Text>
                  {property.label} {property.required && ' *'}
                  {(property.name == 'property.type_id' ||
                    property.name == 'property_owner.marital_status_id') && (
                    <ButtonInfo
                      title='Legenda'
                      onClick={() => showModalCaption(property.name)}
                    >
                      <Icon name='far fa-sm fa-list' />
                    </ButtonInfo>
                  )}
                </Text>
              </TableCell>

              <TableCell>
                <FormFieldSelect
                  onChange={(v) => handleChangePropertyComparison(property.name, v)}
                  options={possibleHeaders}
                />
              </TableCell>

              <TableCell>
                {isLoading ? (
                  <Button isBare isLoading hasThemeColor></Button>
                ) : (
                  validated && (
                    <ErrorContainer>
                      {validationErrors[property.name]
                        ? validationErrors[property.name].map((error, i) => (
                            <ErrorContainer
                              key={`${property.name}_error_${i}`}
                              title={error.rows.map((row) => row + 2).join(', ')}
                            >
                              <ErrorText>- {error.message}</ErrorText>
                              <ErrorContainerLines>
                                <SmallErrorText>
                                  Linhas:{' '}
                                  {error.rows.map((row) => row + 2).join(', ')}
                                </SmallErrorText>
                              </ErrorContainerLines>
                            </ErrorContainer>
                          ))
                        : Object.keys(objectsValidated).indexOf(property.name) >
                            -1 && (
                            <span className='icon'>
                              <i className='fas fa-lg fa-check is-success'></i>
                            </span>
                          )}
                    </ErrorContainer>
                  )
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Grid.Col>

      <ButtonContainer>
        <Button
          disabled={Object.keys(propertiesNameCompared).length == 0 || isLoading}
          hasThemeColor
          submit
          onClick={handleConfirmClick}
        >
          Concluir Importação
        </Button>
      </ButtonContainer>

      <Modal
        isOpen={isLoading ? isLoading : isModalOpen}
        onClose={isLoading ? false : closeModalCaption}
        disableClose={isLoading}
        responsive={true}
        title={isLoading ? 'Aguarde' : 'Legenda'}
      >
        {isLoading ? (
          <>
          <p style={{fontSize: 18}} className='text-center'>Aguarde, processando importação...</p>
            <div>
              <div style={{ height: '250px', marginTop: 100 }}>
                <div
                  style={{ margin: '0 auto', padding: '45px 60px' }}
                  className='empty-state-spinner'
                />
              </div>
            </div>
          </>
        ) : (
          captions.map((item, i) => (
            <p key={i}>
              {item.id} - {item.name}
            </p>
          ))
        )}
      </Modal>
    </Container>
  )
}

export default PropertiesCompare
