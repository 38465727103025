import React from 'react';

import Input, { propsObject as defaultProps } from 'components/lib/FormFields/components/Input';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

import fieldCreator from './fieldCreator';
import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';

const FormFieldPassword = (props) => {

  const [passwordShown, setPasswordShown] = React.useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Field {...props}>
      <Control {...props}>
        <Input
          {...defaultProps(props)}
          type={passwordShown ? "text" : "password"}
          // type="password"
          data-spec-selector={props.specSelector || props.name}
          name={props.name}
          isDanger={isValid || props.forceInvalid}
        />
        <Button isBare style={{background: 'transparent', position: 'absolute', right: 10, top: 0, bottom: 0}} onClick={togglePasswordVisiblity}>
          <Icon  name="far fa-eye" />
        </Button>
        
      </Control>
      {props.forceInvalid && props.errorMessage ? <p className="help is-danger">{props.errorMessage}</p> : null}
    </Field>
  );
};

FormFieldPassword.defaultProps = {
  label: 'Password',
  specSelector: 'Password',
  name: 'Password'
};

export default fieldCreator(FormFieldPassword, {
  masks: [],
  validators: [{ 'minLength': 6 }]
});
