import React from 'react';

import Checkbox, { propsObject as defaultProps } from 'components/lib/FormFields/components/Checkbox';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

import fieldCreator from './fieldCreator';

const FormFieldCheckbox = (props) => {

  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Field label={props.label}>
      <Control {...props} isLoading={false} hasCheckbox>
        <Checkbox
          {...defaultProps(props)}
          description={props.description}
          info={props.info}
          checked={props.checked}
          isLoading={props.isLoading}
          isDanger={isValid || props.forceInvalid}
        />
      </Control>
      {props.forceInvalid && props.errorMessage ? <p className="help is-danger">{props.errorMessage}</p> : null}
    </Field>
  );
};

export default fieldCreator(FormFieldCheckbox, {
  masks: [],
  validators: [{ 'minLength': 1 }],
  valueType: 'boolean'
});
