import React from 'react';

import CompanyCreatedSvg from '../../../../../../assets/svgs/Onboarding';

import { request } from 'core/constants';

import { Container, Text, ButtonsContainer, Button } from './styles';

const CompanyCreated = ({ createdCompanyId }) => {
  const handleGoToPainel = () => location.reload();

  const handleRegisterExamples = async () => {
    await request.post(`company/${createdCompanyId}/create-example-registers`);

    handleGoToPainel();
  };

  return (
    <Container>
      <CompanyCreatedSvg />

      <Text>
        Aqui vai uma sugestão: adicione alguns clientes para iniciarmos os testes de
        nova proposta!
      </Text>

      <ButtonsContainer>
        <Button onClick={handleGoToPainel}>Ir direto para o painel</Button>
        <Button onClick={handleRegisterExamples}>
          Carregar cadastros como exemplo
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default CompanyCreated;
