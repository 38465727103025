import React from 'react';
import Constants from 'services/Constants';

export const disabledIf = (profile) => {

  let hasPermission = true;

  if (!profile) return true;

  if (Constants.get('profiles')) {

    const profiles = Constants.get('profiles').filter((item) => {
      return profile.indexOf(item.id) !== -1;
    });

    if (profiles.length > 0) hasPermission = false;

  }

  if (!hasPermission) return false;

  return true;

};

const Profile = (props) => {

  let hasPermission = true;

  if (!props.disabledIf) return props.children;

  if (Constants.get('profiles')) {

    const profiles = Constants.get('profiles').filter((item) => {
      return props.disabledIf.indexOf(item.id) !== -1;
    });

    if (profiles.length > 0) hasPermission = false;

  }

  if (!hasPermission) return null;

  return props.children;

};

Profile.propTypes = {};

export default Profile;
