import React from "react";
import Icon from "components/lib/Icon";
import Space from "components/lib/Space";

const styles = {
  failure: {
    width: 80,
    height: 80,
    fontSize: 80,
    color: "#DE0000"
  },
  success: {
    width: 80,
    height: 80,
    fontSize: 80,
    color: "#39C357"
  }
};

class ConfirmationMessage extends React.Component {
  renderImage(success) {
    if (!success) {
      return <Icon name="fal fa-times-circle" style={styles.failure} />;
    } else {
      return <Icon name="fal fa-check-circle" style={styles.success} />;
    }
  }
  render() {
    const { success, message } = this.props;
    return (
      <div className="confirmation-message-container">
        {this.renderImage(success)}
        <Space />
        <p className="confirmation-message">{message}</p>
      </div>
    );
  }
}
export default ConfirmationMessage;
