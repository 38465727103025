import React from 'react';

// Components
import { FormFieldDate } from 'components/lib/FormFields';
import formCreator from 'features/components/formCreator';

export class OwnershipDate extends React.Component {
  constructor(props) {
    super(props);

    this.label = props.label || 'Posse aproximada';

    this.state = {};
  }

  render() {
    return <FormFieldDate changeDate={(e) => this.props.onChange(e)} label={this.label} valueDefault={this.props.defaultValue} />;
  }
}

export default formCreator(OwnershipDate, { submitOnChange: false });
