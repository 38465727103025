export default (props) => ({
  id: props.id,
  type: 'file',
  multiple: props.multiple || props.isMultiple,
  accept: props.accept,
  'data-spec-selector': props.specSelector || props.name || props.id,
  name: props.name || props.id,
  onClick: props.onClick,
  onChange: props.onChange,
  onKeyDown: props.onKeyDown,
  onKeyUp: props.onKeyUp,
  onInput: props.onInput,
  onFocus: props.onFocus,
  onBlur: props.onBlur,
  placeholder: props.placeholder,
  disabled: props.disabled || props.isDisabled,
  style: props.style
});
