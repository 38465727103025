import React from 'react';
import PropTypes from 'prop-types';

import FlexBar from 'components/lib/FlexBar';
import Header from 'components/lib/Header';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';

const ScreenHeader = (props) => (
  <FlexBar isSecondary hasNeutralBackground hasShadow>
    <FlexBar.Child isGrow>
      {!props.hideHomeButton ?
        <Button
          isFlat
          isBare
          style={{ marginLeft: 1, marginRight: -5 }}
          onClick={props.onClick.bind(this, 'back')}>
          <Icon name="far fa-chevron-left" />
        </Button> : null
      }
      <Header isUppercase>{props.header}</Header>
    </FlexBar.Child>
    {props.children ? (
      <FlexBar.Child>
        {props.children}
      </FlexBar.Child>
    ) : null}
  </FlexBar>
);

ScreenHeader.defaultProps = {
  onClick: () => {}
};

ScreenHeader.propTypes = {
  onClick: PropTypes.func
};

export default ScreenHeader;
