import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Modal from 'components/lib/Modal';
import Button from 'components/lib/Button';
import Table from 'components/lib/Table';
import Header from 'components/lib/Header';
import Icon from 'components/lib/Icon';

import { fb } from '../../../firebase';

import integrations from '../../';
import CreateProposalSolicitation from './createProposalSolicitation';
import CreateSolicitation from './createSolicitation';
import EditProposalSolicitation from './editProposalSolicitation';
import EditSolicitation from './editSolicitation';
import SummaryReport from './summaryReport';

import request from 'axios';
import { baseURL } from 'core/constants';

const Fichacerta = ({ companyId, payload = {}, props }) => {
  const [modalState, setModalState] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalFeature, setModalFeature] = useState(null);
  const [requestState, setRequestState] = useState('');
  const [integrationData, setIntegrationData] = useState({});
  const [appData, setAppData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [paginateNext, setPaginateNext] = useState(false);
  const [paginatePrev, setPaginatePrev] = useState(false);
  const [itemPrev, setItemPrev] = useState(null);
  const [itemNext, setItemNext] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [lastItem, setLastItem] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      integrations.list({ companyId }).then(({ success, data }) => {
        const fichacerta = data?.filter(({ name }) => name === 'fichacerta')[0];

        if (!success || !fichacerta || !fichacerta.enabled) {
          setIsLoading(false);
          return;
        }

        setIntegrationData(fichacerta);
        let filtros = [];
        if (paginatePrev && itemPrev) {
          filtros['first'] = itemPrev;
        } else {
          filtros['first'] = null;
        }
        if (paginateNext && itemNext) {
          filtros['last'] = itemNext;
        } else {
          filtros['last'] = null;
        }

        if (payload.id) {
          filtros['proposalId'] = payload.id;
        }

        fb.fichacertaSolicitations(companyId, filtros, ({ success, data }) => {
          try {
            setPaginatePrev(false);
            setPaginateNext(false);
            if (success && data) {
              let format = 'DD/MM/YYYY H:mm:ss';

              let solicitations = data.map((item) => ({
                ...item,
                createdAt: moment(item.createdAt.toDate()).format(format),
                updatedAt: moment(item.updatedAt.toDate()).format(format),
                finishedAt: moment(item.finishedAt?.toDate()).format(format),
              }));

              if (solicitations.length > 0) {
                setAppData(solicitations);
                setItemPrev(solicitations[0]);
                setItemNext(solicitations[solicitations.length - 1]);
                setLastItem(null);
                if (!firstItem) {
                  setFirstItem(solicitations[0]);
                }
                if (solicitations.length < 10) {
                  setLastItem(solicitations[solicitations.length - 1]);
                }
              } else {
                setLastItem(appData[appData.length - 1]);
              }

              setIsLoading(false);
            }
          } catch (error) {
            console.log('setAppData fails');
            console.log(error);
          }
        });
      });
    }
    fetchData();
  }, [refreshKey]);

  if (isLoading) {
    return (
      <div>
        <div style={{ height: '250px', marginTop: 100 }}>
          <div
            style={{ margin: '0 auto', padding: '45px 60px' }}
            className="empty-state-spinner"
          />
        </div>
      </div>
    );
  }

  if (!integrationData.enabled) {
    return (
      <h2 className="mb-3 text-center">
        <strong style={{ fontSize: '16px' }}>
          Não possui integração de Análise de Crédito habilitada.
        </strong>
      </h2>
    );
  }

  const profiles = props.activeCompany.profiles.find(
    (profile) =>
      profile.code == 'PROFILE_COMPANY_OWNER_PARTNER' ||
      profile.code == 'PROFILE_ADMIN' ||
      profile.code == 'PROFILE_MANAGER'
  );
  const userIzee = props.activeUser.role === 'ROLE_IZEE';
  const userId = props.activeUser.id;

  const handleUpdateSuccess = (success, data, setModal, message) => {
    setRefreshKey((oldKey) => oldKey + 1);
    if (data.app.response.success) {
      props.notification({
        message: message ? message : data.app.response.message,
      });
      setModalState(setModal);
    } else {
      props.notification({
        message: data.app.response.message,
        status: 'danger',
      });
      console.log('Fichacerta falhou', success, data);
      setModalState(setModal);
    }
  };

  const createProposalSolicitation = () => {
    const clients = payload.clients;
    const guarantors = payload.guarantors;
    const property = payload.properties?.[0];
    const propertyOwner = payload.property_owners?.[0];

    property.rent_value = payload.rent_value;

    setModalState(true);
    setModalTitle('Ficha Certa');
    setModalFeature(
      <CreateProposalSolicitation
        creditAnalysisTickets={integrationData.creditAnalysis.creditAnalysisTickets}
        onSuccess={handleUpdateSuccess.bind(this)}
        clients={clients}
        guarantors={guarantors}
        companyId={companyId}
        companyDocument={props.activeCompany.cnpj || props.activeCompany.cpf}
        proposalId={payload.id}
        property={property}
        propertyOwner={propertyOwner}
      />
    );
  };

  const createSolicitation = () => {
    setModalState(true);
    setModalTitle('Ficha Certa');
    setModalFeature(
      <CreateSolicitation
        companyId={companyId}
        companyDocument={props.activeCompany.cnpj || props.activeCompany.cpf}
        creditAnalysisTickets={integrationData.creditAnalysis.creditAnalysisTickets}
        onSuccess={handleUpdateSuccess.bind(this)}
      />
    );
  };

  const editProposalSolicitation = (solicitatioId, proposalId) => {
    setModalState(true);
    setModalTitle('Ficha Certa');
    setModalFeature(
      <div>
        <div style={{ height: '250px', marginTop: 100 }}>
          <div
            style={{ margin: '0 auto', padding: '45px 60px' }}
            className="empty-state-spinner"
          />
        </div>
      </div>
    );

    request
      .get(`${baseURL}company/${companyId}/proposal/${proposalId}`)
      .then((response) => {
        const proposal = response.data;
        const clients = proposal.clients;
        const guarantors = proposal.guarantors;
        const property = proposal.properties?.[0];
        const propertyOwner = proposal.property_owners?.[0];
        property.rent_value = proposal.rent_value;

        setModalFeature(
          <EditProposalSolicitation
            creditAnalysisTickets={
              integrationData.creditAnalysis.creditAnalysisTickets
            }
            onSuccess={handleUpdateSuccess.bind(this)}
            clients={clients}
            guarantors={guarantors}
            companyId={companyId}
            companyDocument={props.activeCompany.cnpj || props.activeCompany.cpf}
            solicitationId={solicitatioId}
            proposalId={proposalId}
            property={property}
            propertyOwner={propertyOwner}
            setModalState={setModalState}
            notification={props.notification}
          />
        );
      })
      .catch((response) => {
        console.log(response);
      });
  };

  const editSolicitation = (solicitation) => {
    let { applicants, id, propertyIzeeId } = solicitation;

    setModalState(true);
    setModalTitle('Ficha Certa');
    setModalFeature(
      <div>
        <div style={{ height: '250px', marginTop: 100 }}>
          <div
            style={{ margin: '0 auto', padding: '45px 60px' }}
            className="empty-state-spinner"
          />
        </div>
      </div>
    );
    setModalFeature(
      <EditSolicitation
        creditAnalysisTickets={integrationData.creditAnalysis.creditAnalysisTickets}
        onSuccess={handleUpdateSuccess.bind(this)}
        companyId={companyId}
        companyDocument={props.activeCompany.cnpj || props.activeCompany.cpf}
        solicitationId={id}
        applicants={applicants}
        propertyIzeeId={propertyIzeeId}
        setModalState={setModalState}
        notification={props.notification}
      />
    );
  };

  const viewSummaryReport = (solicitatioId) => {
    setModalState(true);
    setModalTitle('Ficha Certa - Resumo da análise');
    setModalFeature(
      <SummaryReport
        companyId={companyId}
        solicitationId={solicitatioId}
        setModalState={setModalState}
        notification={props.notification}
      />
    );
  };

  const printReport = (solicitatioId) => {
    setRequestState('processing');
    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_PRINT',
          companyId,
          solicitationId: solicitatioId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao buscar link de impressão, retorna o erro
          setRequestState('error');
          props.notification({
            message: data.app.response.message,
            status: 'danger',
          });
          console.log('Fichacerta falhou', success, data);
        }

        if (data.app.response.data.match('http')) {
          window.open(data.app.response.data, '_blank');
        } else {
          props.notification({
            message: data.app.response.data,
            status: 'danger',
          });
        }

        setRequestState(data);
      })
      .catch((error) => {
        console.log('Fichacerta integration error', error);
        setRequestState('error');
      });
  };

  const printReportLinkGenerate = (solicitatioId) => {
    setRequestState('processing');
    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_LINK_PRINT',
          companyId,
          solicitationId: solicitatioId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao buscar link de impressão, retorna o erro
          setRequestState('error');
          props.notification({
            message: data.app.response.message,
            status: 'danger',
          });
          console.log('Fichacerta falhou', success, data);
          return;
        }

        props.notification({
          message:
            'O link para download está sendo gerado, voce poderá fazer o download do laudo em alguns instantes!',
        });

        setRequestState(data);
      })
      .catch((error) => {
        console.log('Fichacerta integration error', error);
        setRequestState('error');
      });
  };

  const paginatePrevPage = () => {
    setPaginatePrev(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };
  const paginateNextPage = () => {
    setPaginateNext(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const handleModalClose = () => {
    setModalState(false);
    setModalTitle('');
    setModalFeature(null);
  };

  const formatApplicantTable = (itens) => {
    let applicants = [];
    Object.values(itens).map((item) => {
      applicants.push(item);
    });
    return applicants;
  };

  return (
    <div>
      <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
        <div>
          Créditos restantes: {integrationData.creditAnalysis.creditAnalysisTickets}
        </div>

        {payload.id ? (
          <Button
            style={{ marginRight: '5px' }}
            isMedium
            disabled={requestState === 'processing'}
            onClick={createProposalSolicitation.bind(this)}>
            Nova Análise
          </Button>
        ) : (
          <Button
            style={{ marginRight: '5px' }}
            isMedium
            disabled={requestState === 'processing'}
            onClick={createSolicitation.bind(this)}>
            Nova Análise
          </Button>
        )}
      </Header>

      {appData.length > 0 && (
        <div>
          <h2 className="mb-3">
            <strong style={{ fontSize: '16px' }}>Solicitações de análise</strong>
          </h2>
          <Table
            items={appData}
            cells={[
              {
                name: 'Data',
                path: 'createdAt',
                align: 'center',
              },
              {
                name: 'Pretendentes',
                path: 'applicants',
                align: 'center',
                render: (content, row) => (
                  <div>
                    {formatApplicantTable(row.applicants).map((item) => (
                      <p className="mb-0">
                        {item.corporate_name ? item.corporate_name : item.name}
                      </p>
                    ))}
                  </div>
                ),
              },
              {
                name: 'Créditos',
                path: 'applicants',
                align: 'center',
                render: (content, row) => (
                  <div>{formatApplicantTable(row.applicants).length}</div>
                ),
              },
              {
                name: 'Proposta',
                path: 'proposalId',
                align: 'center',
                render: (content, row) => (
                  <div>
                    {row.proposalId ? (
                      <Button
                        isBare
                        onClick={() =>
                          props.history.push(
                            `/app/${userId}/${row.companyId}/proposal/${row.proposalId}`
                          )
                        }>
                        #{row.proposalId}
                      </Button>
                    ) : (
                      'Sem Proposta'
                    )}
                  </div>
                ),
              },
              {
                name: 'Status',
                path: 'status',
                align: 'center',
                render: (content, row) => (
                  <div>
                    {row.status === 'CONCLUIDA' ? (
                      <span className="tag has-green">{row.status}</span>
                    ) : (
                      <span className="tag has-yellow">{row.status}</span>
                    )}
                  </div>
                ),
              },
              {
                name: 'Ações',
                align: 'center',
                render: (content, row) => (
                  <div>
                    {(profiles || userIzee) && (
                      <>
                        {row.proposalId ? (
                          <Button
                            title="Editar Solicitação"
                            style={{ marginRight: '5px' }}
                            isMedium
                            disabled={requestState === 'processing'}
                            onClick={editProposalSolicitation.bind(
                              this,
                              row.id,
                              row.proposalId
                            )}>
                            <Icon name="fas fa-edit" />
                          </Button>
                        ) : (
                          <Button
                            title="Editar Solicitação"
                            style={{ marginRight: '5px' }}
                            isMedium
                            disabled={requestState === 'processing'}
                            onClick={editSolicitation.bind(this, row)}>
                            <Icon name="fas fa-edit" />
                          </Button>
                        )}
                      </>
                    )}

                    {row.status == 'CONCLUIDA' && (
                      <>
                        {(profiles || userIzee) && (
                          <>
                            <Button
                              title="Regerar link de Impressão"
                              style={{ marginRight: '5px' }}
                              isMedium
                              disabled={requestState === 'processing'}
                              onClick={printReportLinkGenerate.bind(this, row.id)}>
                              <Icon name="fas fa-link" />
                            </Button>

                            <Button
                              title="Imprimir Laudo"
                              style={{ marginRight: '5px' }}
                              isMedium
                              disabled={requestState === 'processing'}
                              onClick={printReport.bind(this, row.id)}>
                              <Icon name="fas fa-print" />
                            </Button>
                          </>
                        )}

                        <Button
                          title="Ver resumo"
                          style={{ marginRight: '5px' }}
                          isMedium
                          disabled={requestState === 'processing'}
                          onClick={viewSummaryReport.bind(this, row.id)}>
                          <Icon name="fas fa-eye" />
                        </Button>
                      </>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}

      <div className="mt-5 mb-5 py-5 text-center">
        <Button
          title="anterior"
          style={{ marginRight: '5px' }}
          isMedium
          disabled={firstItem?.id == itemPrev?.id}
          onClick={() => paginatePrevPage()}>
          Anterior
        </Button>
        <Button
          title="Proxima"
          style={{ marginRight: '5px' }}
          isMedium
          disabled={lastItem?.id == itemNext?.id}
          onClick={() => paginateNextPage()}>
          Proxima
        </Button>
      </div>

      <Modal
        title={modalTitle}
        isOpen={modalState}
        onClose={handleModalClose.bind(this)}>
        {modalFeature}
      </Modal>
    </div>
  );
};

Fichacerta.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default Fichacerta;
