import { request } from 'core/constants';
import { requestState } from 'utils/UtilityStore/actions';

export const fetch = (clientId) => (dispatch) => {
  dispatch(requestState(true, 'client'));
  request
    .get(`{company}/client/${clientId}`)
    .then((response) => {
      dispatch(requestState(false, 'client'));
      dispatch({
        type: 'FETCH_CLIENT',
        payload: response.data,
      });
    })
    .catch((error) => {});
};

export const clear = () => (dispatch) => {
  dispatch(requestState(null, 'client'));
  dispatch({
    type: 'CLEAR_CLIENT',
  });
};
