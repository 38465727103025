import React from 'react';

import parsePropsAsBulmaClasses from '../lib/_utils/parsePropsAsBulmaClasses';

const Alert = (props) => {

  const notificationCSSClass = `notification ${
    parsePropsAsBulmaClasses(props)
  }`;

  return (
    <div className={notificationCSSClass}>
      <button className="delete" onClick={props.onClose} />
      {props.children}
    </div>
  );

};

Alert.propTypes = {};

export default Alert;
