import React from 'react'

import moment from 'moment'

import listWrapper from 'containers/Records/listWrapper'

import UserName from 'components/TableComponents/UserName'
import withFilter from 'components/HOCs/withFilter'
import EmptyState from 'components/EmptyState'

import Table from 'components/lib/Table'

import ListRecordActionCell from '../../../components/ListRecordActionCell'

const List = (props) => {
  props.createFilterRequest(props.onSearch)

  return (
    <Table
      lastOrder={props.lastOrder}
      orderDirection={props.orderDirection}
      onSort={props.orderBy}
      items={props.items}
      isUpdatingRows={props.isWorking}
      onClickRow={props.handleRowClick}
      cells={[
        {
          name: 'Responsável',
          path: 'creator.name',
          render: (_, content) => {
            if (content && content.creator) {
              return content.creator.name || content.creator.email
            }

            return 'Sistema'
          },
        },
        {
          name: 'Nome',
          render: (_, row) => <UserName content={row} />,
        },
        {
          name: 'CPF/CNPJ',
          render: (_, row) => <span>{row.cnpj ? row.cnpj : row.cpf}</span>,
        },
        {
          name: 'Telefone',
          path: 'phone',
        },
        {
          name: 'Endereço',
          path: 'address.neighborhood',
          render: (content, row) =>
            content ? (
              <span>
                {row.address.neighborhood}
                <br />
                {row.address.city} - {row.address.state}
              </span>
            ) : null,
        },
        {
          name: 'Data do Cadastro',
          path: 'created_at',
          align: 'center',
          render: (content) => moment(content).format('DD/MM/YYYY'),
        },
      ]}
      actionCell={(row) => (
        <ListRecordActionCell
          handleEditRow={props.handleEditRow.bind(null, row)}
          handleExcludeRow={props.handleExcludeRow.bind(null, row)}
        />
      )}
      emptyState={<EmptyState isLoading={props.isWorking} />}
    />
  )
}

List.defaultProps = {
  items: [],
  isWorking: false,
  handleEditRow: () => {},
  handleExcludeRow: () => {},
}

export default listWrapper(withFilter(List))
