import React from 'react';

import lodashMap from 'lodash/map';

import BodyContainer from 'components/lib/BodyContainer';
import SectionWrapper from 'components/SectionWrapper';
import SelectPlan from 'components/SelectPlan';

export const Billing = (props) => {
  const { company } = props;

  const subscriptionPlan = company.subscription_plan || {};

  const listPeriod = [];
  const years = [2019];
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const newPeriod = (k, name) => ({ id: k, name });

  lodashMap(years, (y) => {
    lodashMap(months, (m, i) => {
      listPeriod.push(newPeriod(i, `${y} ${m}`));
    });
  });

  listPeriod.reverse();

  return (
    <BodyContainer>
      <SectionWrapper header="Assinatura">
        {!subscriptionPlan.id && (
          <h2 style={{ fontSize: 18 }}>
            <strong>Sem plano de assinatura.</strong>
          </h2>
        )}

        <SelectPlan
          onChange={() => {}}
          defaultValue={subscriptionPlan}
          activeUser={props.activeUser}
          companyId={company.id}
          disabled={props.activeUser.role != 'ROLE_IZEE'}
        />
      </SectionWrapper>
    </BodyContainer>
  );
};

export default Billing;
