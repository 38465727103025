import React, { useState, useEffect } from 'react';

import { request } from 'core/constants';

import { FormFieldCheckbox } from 'components/lib/FormFields';

import { Container, TermContainer, Text, LinkText } from './styles';
import TermModal from './TermModal';

const Terms = (props) => {
  const { useTerms, setUseTerms, privacyPolitics, setPrivacyPolitics } = props;

  const [showTerms, setShowTerms] = useState('');
  const [usageTerms, setUsageTerms] = useState(null);
  const [privacyTerms, setPrivacyTerms] = useState(null);

  const fetchTerms = async () => {
    try {
      const { data } = await request.get('user-term');

      const privacyTerms = data.find(
        (term) => term.code === 'USER_TERM_PRIVACY_POLICY'
      ).source;
      const usageTerms = data.find(
        (term) => term.code === 'USER_TERM_USAGE_TERMS'
      ).source;

      setPrivacyTerms(privacyTerms);
      setUsageTerms(usageTerms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTerms();
  }, []);

  const handleShowTerms = (term) => {
    setShowTerms(term);
  };

  return (
    <Container>
      <TermContainer>
        <FormFieldCheckbox
          defaultChecked={useTerms}
          onClick={() => setUseTerms(!useTerms)}
        />
        <Text>
          {'Aceito as '}
          <LinkText onClick={() => handleShowTerms('use')}>termos de uso.</LinkText>
        </Text>
      </TermContainer>

      <TermContainer>
        <FormFieldCheckbox
          defaultChecked={privacyPolitics}
          onClick={() => setPrivacyPolitics(!privacyPolitics)}
        />
        <Text>
          {'Aceito as '}
          <LinkText onClick={() => handleShowTerms('privacy')}>
            políticas de privacidade
          </LinkText>
        </Text>
      </TermContainer>

      {showTerms && (
        <TermModal
          content={showTerms === 'use' ? usageTerms : privacyTerms}
          setShowTerms={setShowTerms}
        />
      )}
    </Container>
  );
};

export default Terms;
