import React from 'react'

import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'

const ViewSelector = (props) => {
  const views = props.views.map((item, index) => {
    let isActive = true

    if (item.isHidden) return null
    if (item.isActive !== null && item.isActive !== undefined)
      isActive = item.isActive

    return (
      <Button
        isTab
        isDisabled={!isActive}
        isSelected={props.current === item.code}
        onClick={item.code && props.onClick.bind(null, item.code)}
        key={item.code || index + 1}
        role={item.code ? item.code : null}
        id={item.id}
      >
        {item.name} {item.icon ? <Icon isSmall name={item.icon} /> : ''}
      </Button>
    )
  })

  return <div className='buttons has-addons'>{views}</div>
}

ViewSelector.propTypes = {}

export default ViewSelector
