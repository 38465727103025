import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from 'components/SubmitButton';
import formCreator from '../components/formCreator';
import ContractEditor from '../components/fieldsGroup/ContractEditor';
import { ga } from '../../../../izee-app/src/services/firebase';

export class UpdateContract extends React.Component {
  componentDidMount() {
    this.props.createRequest(this.createRequest);
    ga.logEvent('screen_view', { 'screen_name' : 'contract-modal' });
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;
    const hideStyle = { display: 'none' };

    return (
      <>
        <div style={!formIsWorking ? hideStyle : {}}>
          <div style={{ height: '350px', marginTop: 200 }}>
            <div style={{ margin: '0 auto', padding: '45px 60px' }} className="empty-state-spinner" />
          </div>
        </div>
        <div style={formIsWorking ? hideStyle : {}}>
          <ContractEditor {...this.props} />
        </div>
        <SubmitButton label="Salvar" isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} />
      </>
    );
  }

  createRequest(fields) {
    this.props.submit(`company/${this.props.activeCompany.id}/contract-model/${this.props.defaultValue.id}`, fields, 'put');

    if (this.props.closeModal) {
      this.props.closeModal();
    }
  }
}

UpdateContract.defaultProps = {
  fields: {},
  defaultValue: {},
  activeCompany: {},
};

UpdateContract.propTypes = {
  activeCompany: PropTypes.object.isRequired,
};

export default formCreator(UpdateContract);
