import request from 'axios';
import { baseURL } from 'core/constants';
import { requestState } from 'utils/UtilityStore/actions';

export const fetchCompany = (hash) => (dispatch) => {
  dispatch(requestState(true, 'publicCompany'));
  request
    .get(`${baseURL}public/company`, {
      headers: { Authorization: 'Bearer ' + hash },
    })
    .then((response) => {
      dispatch(requestState(false, 'publicCompany'));
      dispatch({
        type: 'PUBLIC_FETCH_COMPANY',
        payload: response.data,
      });
    })
    .catch((error) => {});
};
