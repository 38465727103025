export const baseURL = IZEE_API_URL;
// export const uploadURL = IZEE_UPLOAD;

import axios from 'axios';

import JsonParse from 'services/JsonParse';

export const UserMeta = JsonParse.createInstance();
export const CompanyMeta = JsonParse.createInstance();

export const request = axios.create({
  baseURL: baseURL,
});

request.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response?.data)
);
