import Properties from 'containers/Properties'
import PropertyOwners from 'containers/PropertyOwners'
import ImportPropertiesWithCSV from 'containers/ImportPropertiesWithCSV'
import GuestUsers from 'containers/GuestUsers'
import ClientDetails from 'containers/ClientDetails'
import Guarantors from 'containers/Guarantors'
import GuarantorDetails from 'containers/GuarantorDetails'
import PropertyOwnerDetails from 'containers/PropertyOwnerDetails'
import PropertyDetails from 'containers/PropertyDetails'

const routes = [
  {
    name: 'Properties',
    path: '/app/:userId/:companyId/records/properties',
    exact: true,
    component: Properties,
  },
  {
    name: 'Import Properties With CSV',
    path: '/app/:userId/:companyId/records/properties/import',
    exact: true,
    component: ImportPropertiesWithCSV,
  },
  {
    name: 'Property Details',
    path: '/app/:userId/:companyId/records/properties/:propertyId',
    exact: true,
    component: PropertyDetails,
  },
  {
    name: 'Property Owners',
    path: '/app/:userId/:companyId/records/property-owners',
    exact: true,
    component: PropertyOwners,
  },
  {
    name: 'Property Owner Details',
    path: '/app/:userId/:companyId/records/property-owners/:propertyOwnerId',
    exact: true,
    component: PropertyOwnerDetails,
  },
  {
    name: 'Guest Users',
    path: '/app/:userId/:companyId/records/guests',
    exact: true,
    component: GuestUsers,
  },
  {
    name: 'Client details',
    path: '/app/:userId/:companyId/records/guests/:clientId',
    exact: true,
    component: ClientDetails,
  },
  {
    name: 'Guarantors',
    path: '/app/:userId/:companyId/records/guarantors',
    exact: true,
    component: Guarantors,
  },
  {
    name: 'Guarantor details',
    path: '/app/:userId/:companyId/records/guarantors/:guarantorId',
    exact: true,
    component: GuarantorDetails,
  },
]

export default routes
