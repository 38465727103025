import React from 'react';
import Title from 'components/lib/Title';

class SectionIdentifier extends React.Component {

	renderSectionIdentifier() {
		const { title, isNarrow } = this.props;
		let narrow = ""
		isNarrow ? (narrow = " narrow") : (narrow = "")
		return(
			<div className={"section-identifier" + narrow}>
    		<h1 className="form-section-title">{title}</h1>
   		</div>
		)
	}

	renderModalIdentifier() {
		const { selectedSection } = this.props;
		const { sectionTitle } = selectedSection;
		// console.log(selectedSection, sectionTitle)
		return(
			<div className="section-identifier">
    		<h1 className="form-section-title">{sectionTitle}</h1>
   		</div>
		)
	}
	render() {
		const { selectedSection, title } = this.props;
		return (
			<React.Fragment>
				{ 
					this.props.modal ? 
						this.renderModalIdentifier(selectedSection) : 
							this.renderSectionIdentifier(title) 
				}
			</React.Fragment>
		)
	}
}
export default SectionIdentifier;