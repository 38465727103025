import { request } from 'core/constants'
import { UserMeta, CompanyMeta } from 'core/constants'

export const checkSession = () => (dispatch, store) => {
  if (!localStorage.getItem('izee-token')) {
    localStorage.removeItem('izee-token')
    dispatch({ type: 'APP_DESTROY_SESSION' })
    return
  }
  request.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('izee-token')
  if (!store().app.activeUser) {
    request
      .get('me')
      .then((response) => {
        dispatch({
          type: 'APP_SET_USER',
          payload: response.data,
        })
      })
      .catch((error) => {
        console.log(error)
        localStorage.removeItem('izee-token')
        dispatch({
          type: 'APP_SET_USER_ERROR',
          payload: error,
        })
      })
  }
}

export const destroySession = () => (dispatch) => {
  localStorage.removeItem('izee-token')
  dispatch({ type: 'APP_DESTROY_SESSION' })
}

export const setCompanyById = (companyId) => (dispatch) => {
  request.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('izee-token')

  request
    .get(`company/${companyId}`)
    .then((response) => {
      dispatch({
        type: 'APP_SET_COMPANY_BY_ID',
        payload: response.data,
      })
    })
    .catch((error) => {
      console.log(error)
      localStorage.removeItem('izee-token')
      dispatch({
        type: 'APP_SET_USER_ERROR',
        payload: error,
      })
    })
}

export const updateActiveUser = (userData) => (dispatch) => {
  dispatch({
    type: 'APP_SET_USER',
    payload: userData,
  })
}

export const updateActiveCompany = (companyData) => (dispatch) => {
  dispatch({
    type: 'APP_UPDATE_ACTIVE_COMPANY',
    payload: companyData,
  })
}

export const setHelper = (name, helper) => (dispatch) => {
  dispatch({
    type: 'APP_SET_HELPER',
    payload: {
      helper: helper,
      name: name,
    },
  })
}

export const updateUserMeta = (path, value, newLocation) => (dispatch, store) => {
  const activeUser = store().app.activeUser
  if (!activeUser) return
  UserMeta.set(path, value)
  request
    .put('me', {
      meta: UserMeta.toString(),
    })
    .then((response) => {
      if (path === 'lastCompanyId') {
        location.href = newLocation
        return
      }
      dispatch({
        type: 'APP_SET_USER',
        payload: response.data,
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: 'APP_SET_USER_ERROR',
        payload: error,
      })
    })
}

export const updateCompanyMeta = (path, value) => (dispatch, store) => {
  const activeCompany = store().app.activeCompany
  if (!activeCompany) return
  CompanyMeta.set(path, value)
  request
    .put(`company/${activeCompany.id}`, {
      meta: CompanyMeta.toString(),
    })
    .then((response) => {
      dispatch({
        type: 'APP_UPDATE_ACTIVE_COMPANY',
        payload: response.data,
      })
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: 'APP_UPDATE_ACTIVE_COMPANY_ERROR',
        payload: error,
      })
    })
}

export const hideNotification = () => (dispatch) => {
  dispatch({ type: 'HIDE_NOTIFICATION' })
}
