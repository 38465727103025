import React from 'react';

import Input, { propsObject as defaultProps } from 'components/lib/FormFields/components/Input';
import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';

import fieldCreator from './fieldCreator';

const FormFieldEmail = (props) => {

  const isValid = props.isValid !== null && !props.isValid && !props.isEmpty;

  return (
    <Field {...props}>
      <Control {...props}>
        <Input
          {...defaultProps(props)}
          type="email"
          data-spec-selector={props.specSelector || props.name}
          name={props.name}
          isDanger={isValid || props.forceInvalid}
        />
      </Control>
      {props.errorMessage ? <p className="help is-danger">{props.errorMessage}</p> : null}
    </Field>
  );
};

FormFieldEmail.defaultProps = {
  label: 'Email',
  specSelector: 'Email',
  name: 'Email'
};

export default fieldCreator(FormFieldEmail, {
  masks: [],
  validators: [{ 'minLength': 8 }, 'email']
});
