import React from 'react';

// Components
import Grid from 'components/lib/Grid';
import Button from 'components/lib/Button';

import formCreator from 'features/components/formCreator';

export class SendSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this));
  }

  render() {
    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>
        <Grid>
          {!this.props.isClosed && (
            <Grid.Col isNarrow>
              <Button hasThemeColor isLoading={formIsWorking} isDisabled={!formIsReady || formIsWorking} submit>
                Enviar vistoria para o proprietário
              </Button>
            </Grid.Col>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  createRequest() {
    const { processId } = this.props;
    this.props.submit(`{company}/process/${processId}/inspection/send`, {
      sent_to_owner: true,
    });
  }
}

export default formCreator(SendSurvey);
