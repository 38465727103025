import React, { useState } from 'react'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'

import { LIST_OPTIONS } from '../../../../../constants/documentList'

import { updateCompanyMeta } from './action'

import DocumentRequest from 'components/DocumentsRequest/DocumentsRequest'
import SubmitButton from 'components/SubmitButton'

const DocumentListModal = (props) => {
  const [documentListsOptionValue, setDocumentListsOptionValue] = useState(
    props.defaultListOption && props.defaultListOption,
  )

  const [documentLists, setDocumentLists] = useState([])

  const [loading, setLoading] = useState(false)

  const listOption = LIST_OPTIONS.filter(
    (option) => !props.documentLists.find((doc) => doc.id === option.id),
  )

  const handleChangeDocumentListOption = ({ id, label }) =>
    setDocumentListsOptionValue({
      id,
      label,
    })

  const handleChangeClientsDocuments = (value) =>
    setDocumentLists(value.map((item) => item.name))

  const handleSave = async () => {
    setLoading(true)

    const newDocumentListsOption = {
      id: documentListsOptionValue.id,
      label: documentListsOptionValue.label,
      list: documentLists,
    }

    if (props.isRemoving) {
      const newDocumentLists = props.documentLists.filter(
        (documentList) => documentList.id !== documentListsOptionValue.id,
      )

      const stringifiedData = {
        meta: JSON.stringify({
          ...props.companyMeta,
          documentLists: newDocumentLists,
        }),
      }

      await props.updateCompanyMeta(props.companyId, stringifiedData)

      props.handleCloseModal()

      return
    }

    if (!props.isEditing || (props.isEditing && documentLists.length > 0)) {
      const documentListExist = props.documentLists.find(
        (documentList) => documentList.id === documentListsOptionValue.id,
      )

      const newDocumentLists = !documentListExist
        ? [...props.documentLists, newDocumentListsOption]
        : props.documentLists.map((documentList) =>
            documentList.id === documentListsOptionValue.id
              ? newDocumentListsOption
              : documentList,
          )

      const stringifiedData = {
        meta: JSON.stringify({
          ...props.companyMeta,
          documentLists: newDocumentLists,
        }),
      }

      await props.updateCompanyMeta(props.companyId, stringifiedData)
    }

    props.handleCloseModal()
  }

  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <span>Sem opções</span>
    </components.NoOptionsMessage>
  )

  return (
    <>
      <label className='label'>Lista aparece em</label>
      <Select
        defaultValue={documentListsOptionValue}
        isDisabled={props.isEditing || props.isRemoving}
        placeholder='Selecione uma opção de lista'
        onChange={handleChangeDocumentListOption}
        options={listOption}
        getOptionValue={(option) => option.label}
        isClearable={false}
        isSearchable={false}
        controlShouldRenderValue
        components={{ NoOptionsMessage }}
      />

      <div style={{ marginTop: '1.4rem' }}>
        <DocumentRequest
          defaultMultiOption={props.defaultDocuments}
          isDisabled={props.isRemoving}
          title={'Solicitar documentos aos clientes'}
          onChange={handleChangeClientsDocuments}
        />
      </div>

      <SubmitButton
        onClick={handleSave}
        label={props.isRemoving ? 'Remover' : 'Salvar'}
        isLoading={loading}
        isDisabled={!documentListsOptionValue}
      />
    </>
  )
}

export default connect(
  (store) => ({
    companyId: store.app.activeCompany.id,
    companyMeta: store.app.companyMeta,
    documentLists: store.app.companyMeta.documentLists || [],
  }),
  { updateCompanyMeta },
)(DocumentListModal)
