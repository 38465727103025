import React, { Component } from 'react'

import 'react-quill/dist/quill.snow.css'

import EditorWithTokens from '../../../components/EditorWithTokens'
import Button from 'components/lib/Button'
import Grid from 'components/lib/Grid'
import { FormFieldText, FormFieldSelect } from 'components/lib/FormFields'

import { fieldProps } from '../formCreator'

export default class ContractEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contractNamesByType: {
        TYPE_RENTAL: 'Locação',
        TYPE_SALE: 'Venda',
        TYPE_ACQUISITION: 'Captação',
      },
      contractTypes: ['TYPE_RENTAL', 'TYPE_SALE', 'TYPE_ACQUISITION'],
      editorState: this.props.defaultValue['meta'] || '',
      type: this.props.defaultValue.type || '',
    }
  }

  componentDidMount() {
    this.props.updateFieldValues([
      {
        fieldName: 'type',
        field: {
          value: this.props.defaultValue.type || 'TYPE_RENTAL',
          isValid: true,
        },
      },
    ])
    this.props.handleFieldChanges('meta', this.state.editorState, {
      isValid: this.state.editorState.length > 0,
      value: this.state.editorState,
    })
  }

  handleChangeType(value) {
    if (!this.state.contractTypes.includes(value)) {
      throw new Error('Invalid contract type provided')
    }

    this.setState({ type: value })

    this.props.updateFieldValues([
      {
        fieldName: 'type',
        field: { value, isValid: true },
      },
    ])

    if (value !== 'TYPE_RENTAL') {
      this.props.updateFieldValues([
        {
          fieldName: 'guarantee_id',
          field: { value: '', isValid: true },
        },
      ])
    }
  }

  render() {
    const { contractNamesByType, contractTypes, editorState, type } = this.state

    const isSaleOrAcquisitionContractTypeSelected =
      type === 'TYPE_SALE' || type === 'TYPE_ACQUISITION'

    return (
      <div className='contract-editor'>
        <Grid>
          <Grid.Col>
            <FormFieldText
              {...fieldProps(this.props, 'name')}
              label='Nome do modelo de contrato'
              info='Dê um nome ao modelo do contrato'
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col isTwoThirds>
            <Grid>
              <Grid.Col>
                <div className='contract-type-options'>
                  {contractTypes.map((contractType) => (
                    <Button
                      key={contractType}
                      isTab
                      isSelected={type === contractType}
                      onClick={() => this.handleChangeType(contractType)}
                    >
                      {contractNamesByType[contractType]}
                    </Button>
                  ))}
                </div>
              </Grid.Col>

              <Grid.Col isFourFifths>
                <FormFieldSelect
                  {...fieldProps(this.props, 'guarantee_id')}
                  label='Garantia associada'
                  info='Selecione o tipo de garantia em que este contrato deverá ser utilizado'
                  valueKey='id'
                  disabled={isSaleOrAcquisitionContractTypeSelected}
                  noValidate={isSaleOrAcquisitionContractTypeSelected}
                  options={this.props?.activeCompany?.guarantees || []}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col>
            <FormFieldSelect
              {...fieldProps(this.props, 'header_footer_template_id')}
              label='Cabeçalho/Rodapé'
              info='Seleciona o tipo de cabeçalho e rodapé que este contrato utilizará'
              valueKey='id'
              options={this.props?.headerFooterTemplateItems || []}
              noValidate
            />
          </Grid.Col>
        </Grid>

        <EditorWithTokens
          tokenList={this.props.listContractModelVariables}
          defaultValue={{ template: editorState }}
          handleFieldChanges={this.props.handleFieldChanges}
        />
      </div>
    )
  }
}
