import React from 'react';
// Utils
import parsePropsAsBulmaClasses from 'components/lib/_utils/parsePropsAsBulmaClasses';

const FlexBar = (props) => {
  let flexbarCSSClass = `flexbar ${parsePropsAsBulmaClasses(props)}${
    props.className ? ' ' + props.className : ''
  }`;
  return (
    <div data-spec-selector={props.specSelector} className={flexbarCSSClass}>
      {props.children}
    </div>
  );
};

FlexBar.Child = (props) => {
  let flexbarChildCSSClass = `flexbar-child ${parsePropsAsBulmaClasses(props)}${
    props.className ? ' ' + props.className : ''
  }`;
  return (
    <div
      data-spec-selector={props.specSelector}
      className={flexbarChildCSSClass}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default FlexBar;
