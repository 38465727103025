import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 44px;
`

export const TableHeader = styled.div`
  flex: 1;
  color: white;

  background-color: #5f6775;
`

export const TableBody = styled.div`
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-left: -7.2px;
  margin-right: -7.2px;

  border: 1px solid lightgray;
  border-top: none;
  border-right: none;
`

export const TableRow = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-left: -7.2px;
  margin-right: -7.2px;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  text-transform: none;

  &:last-child > div {
    border-bottom: none !important;
  }
`

export const TableCell = styled.div`
  display: flex;
  align-items: center;

  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.45rem 0.75rem;

  border: 1px solid lightgray;
  border-top: none;
  border-right: none;

  & * {
    flex: 1;
  }
`

export const Text = styled.span`
  font-family: 'Roboto';
  font-size: 16px;
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ErrorContainerLines = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`

export const ErrorText = styled(Text)`
  font-size: 14px;
  color: #eb144c;
`

export const SmallErrorText = styled(ErrorText)`
  font-size: 13px;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (min-width: 600px) {
    justify-content: flex-end;
  }
`
export const ButtonInfo = styled.button`
  padding: 0;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: rgba(95, 103, 117, 0.2);
`
