import React from 'react';
import ReactTimeout from 'react-timeout';
import differenceBy from 'lodash/differenceBy';

import AsyncSelect from 'react-select/async';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { components } from 'react-select';

import Field from 'components/lib/FormFields/components/Field';
import Control from 'components/lib/FormFields/components/Control';
import Icon from 'components/lib/Icon';
import Text from 'components/lib/Text';

import Modal from 'components/lib/Modal';
import AddNewGuarantor from 'features/AddNewGuarantor';

import AddButton from './components/AddButton';
import MultiValueLabel from './components/MultiValueLabel';
import MultiValueRemove from './components/MultiValueRemove';

import { request } from 'core/constants';
class GuarantorSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      options: (this.props.defaultValue || {}).guarantors || [],
      isWorking: false,
      noOptions: false,
    };
    this.asyncRef = React.createRef();
    this.proposalId = this.props.proposalId ?? 0;
    this.proposalOnly = this.props.proposalOnly ?? false;
    this.debouncePromiseOptions = AwesomeDebouncePromise(
      this.promiseOptions.bind(this),
      400
    );
  }

  componentDidMount() {
    if ((this.props.defaultValue || {}).guarantors || [].length > 0) {
      const defaultOptions = (this.props.defaultValue || {}).guarantors || [];
      this.props.handleFieldChanges(
        'guarantors',
        [...defaultOptions].map((item) => ({
          id: item.id,
          main: item.id === this.state.mainGuarantorId,
        })),
        {
          isValid: defaultOptions.length > 0,
          value: [...defaultOptions].map((item) => ({
            id: item.id,
            main: item.id === this.state.mainGuarantorId,
          })),
        }
      );
    }
  }

  onAdded(result) {
    if (result.isSuccess) {
      this.setState({
        modalIsOpen: false,
        options: [
          ...this.state.options,
          { id: result.data.id, name: result.data.name, email: result.data.email },
        ],
      });

      this.onChange(this.state.options);
    } else {
      this.props.notification({
        message: 'Não foi possível adicionar esse Fiador',
        status: 'danger',
      });
    }
  }

  onChange(options) {
    const {
      guarantors,
      canRemove = true,
      onSelect,
      handleFieldChanges,
    } = this.props;
    if (!canRemove) {
      return;
    }

    // Check if removed items can be removed
    const items = guarantors || this.state.options;
    if (
      options.length < items.length &&
      !!items.find((option) => option.forbiddenRemove)
    ) {
      const mustRemainItems = differenceBy(items, options, 'id').filter(
        (option) => option.forbiddenRemove
      );
      options = [...options, ...mustRemainItems];
    }

    this.setState({
      options,
      inputValue: '',
    });

    onSelect && onSelect(options);

    handleFieldChanges(
      'guarantors',
      [...options].map((item) => ({
        id: item.id,
        main: item.id === this.state.mainGuarantorId,
      })),
      {
        isValid: options.length > 0,
        value: [...options].map((item) => ({
          id: item.id,
          main: item.id === this.state.mainGuarantorId,
        })),
      }
    );
  }

  promiseOptions(inputValue) {
    const { formatPeople } = this.props;
    return new Promise((resolve, reject) => {
      let url = `{company}/guarantor/search?q=${inputValue}`;
      if (this.proposalOnly && this.proposalId > 0) {
        url += '&relations[proposals][proposals.id]=' + this.proposalId;
      }

      request
        .get(url)
        .then((response) => {
          resolve(formatPeople ? formatPeople(response.data) : response.data);
        })
        .catch(() => reject());
    });
  }

  render() {
    const { showAddition, disabled = false, guarantors } = this.props;

    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <Icon name="fas fa-user" />
      </components.DropdownIndicator>
    );

    const Option = (props) => {
      const data = props.data || {};
      return (
        <components.Option {...props}>
          {data.cnpj ? (
            <div>
              <span style={{ fontSize: 14, fontWeight: 700, color: '#4a4a4a' }}>
                {data.corporate_name || 'Sem nome'}
              </span>
              <br />
              <span style={{ fontSize: 12 }}>{data.cnpj}</span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: 14, fontWeight: 700, color: '#4a4a4a' }}>
                {data.name || 'Sem nome'}
              </span>
              <br />
              {data.withSpouse && (
                <>
                  <br />
                  <span style={{ fontSize: 12 }}>Cônjuge: {data.spouse_name}</span>
                </>
              )}
              <span style={{ fontSize: 12 }}>{data.email}</span>
            </div>
          )}
        </components.Option>
      );
    };

    const LoadingIndicator = (props) => null;

    return (
      <React.Fragment>
        <Field label="Fiadores">
          <Control>
            <div className="react-select-wrapper" style={{ display: 'flex' }}>
              <AsyncSelect
                defaultOptions
                ref={(ref) => (this.asyncRef = ref)}
                classNamePrefix="react-select"
                defaultValue={[]}
                name="guarantor"
                isMulti
                isDisabled={disabled || this.state.isWorking}
                isLoading={this.state.isWorking}
                value={guarantors}
                loadingMessage={() => 'Pesquisando por fiadores...'}
                noOptionsMessage={() => <span>Nenhum fiador encontrado</span>}
                placeholder="Selecionar fiadores"
                menuPosition="fixed"
                maxMenuHeight={162}
                minMenuHeight={100}
                onInputChange={this.handleInputChange.bind(this)}
                inputValue={this.state.inputValue}
                onChange={this.onChange.bind(this)}
                isClearable={false}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                components={{
                  MultiValueLabel,
                  MultiValueRemove,
                  DropdownIndicator,
                  LoadingIndicator,
                  Option,
                }}
                loadOptions={this.debouncePromiseOptions.bind(this)}
              />
              {showAddition ? (
                <AddButton onClick={() => this.setState({ modalIsOpen: true })} />
              ) : null}
            </div>
          </Control>
        </Field>
        <Modal
          title="Criar novo Fiador"
          isOpen={this.state.modalIsOpen}
          onClose={() => {
            this.setState({ modalIsOpen: false });
          }}
          backgroundContentStyle={{
            display: 'block',
          }}
        >
          <AddNewGuarantor onSuccess={this.onAdded.bind(this)} />
        </Modal>
      </React.Fragment>
    );
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  }
}

export default ReactTimeout(GuarantorSelection);
