import React, { Component } from "react";

// Components
import Icon from "components/lib/Icon";

export class Success extends Component {
  handleMessage() {
    const { msg } = this.props;
    if (msg !== "") {
      return <span>Documento(s) {msg} com sucesso</span>;
    }

    return <span>Ação realizada com sucesso</span>;
  }

  render() {
    return (
      <div
        className="feedback-container is-success"
        style={{ position: "inherit", margin: "auto" }}
      >
        <div className="feedback-container-success">
          <Icon name="fas fa-shield-check" />
          <br />
          {this.handleMessage()}
        </div>
      </div>
    );
  }
}

export default Success;
