import React from 'react';

// Components
import { FormFieldText } from 'components/lib/FormFields';

export class ValidityMonths extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <FormFieldText 
        label="Vigência (meses)" 
        type="number" 
        forceInvalid={!this.props.isValid} 
        isValid={this.props.isValid} 
        errorMessage={this.props.errorMessage}
        style={{ width: 140 }} 
        onChange={(e) => this.props.onChange(e)} 
        valueDefault={this.props.defaultValue} />
      </React.Fragment>
    );
  }
}

// export default formCreator(Settings)
export default ValidityMonths;
