import React from 'react'

import Constants from '../../../../constants'
import withRedux from 'components/redux'
import * as actions from './actions'
import * as commons from 'utils/UtilityStore/actions'
import * as mainActions from '../../actions'

import { request } from 'core/constants'
import moment from 'moment'

import Button from 'components/lib/Button'
import Text from 'components/lib/Text'
import Space from 'components/lib/Space'
import Icon from 'components/lib/Icon'

import SectionWrapper from 'components/SectionWrapper'
import ContractUpload from './components/ContractUpload'
import Settings from './components/Settings'

import ProcessTask from 'services/ProcessTask'

import CloseRequirement from 'features/CloseRequirement'
import ToggleRequirement from 'features/ToggleRequirement'

import HeadRight from 'features/AnalysisProposal/components/HeadRight'
import SectionGroup from 'features/AnalysisProposal/components/SectionGroup'

import { deepKey } from 'utils/utils'
import { ga } from '../../../../services/firebase'

class Contract extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      updatingStatus: false,
    }
  }

  componentDidMount() {
    const currentContract = deepKey(
      this.props.current,
      'proposal.contract.unsignedFile',
    )

    if (!currentContract) return
    this.props.fetchReadyContract((currentContract || {}).id)

    const allButtons = document.querySelectorAll('#form-blocked button')

    for (let i = 0; i < allButtons.length; i++) {
      const currentButton = allButtons[i]

      const clone = currentButton.cloneNode(true)

      const parent = currentButton.parentNode

      parent.appendChild(clone)

      currentButton.remove()
    }
  }

  getSnapshotBeforeUpdate() {
    if (this.props.contract) return null
    const currentContract = deepKey(
      this.props.current,
      'proposal.contract.unsignedFile',
    )

    if (!currentContract) return
    if (this.props.isWorking.readyContract) return null
    this.props.fetchReadyContract((currentContract || {}).id)

    return null
  }

  componentDidUpdate() {}

  render() {
    const { company, current, activeCompany, isAgent = false } = this.props
    const { proposal, id: processId } = current

    if (!proposal) return null

    const { requirements } = current

    const juridicalStatus = ProcessTask(requirements, 'REQUIREMENT_JURIDICAL').status
    const insuranceStatus =
      proposal?.type === 'TYPE_ACQUISITION' ||
      proposal?.guarantee?.code === Constants.GUARANTEE.GUARANTOR ||
      ProcessTask(requirements, 'REQUIREMENT_GUARANTEE').status
    const status = ProcessTask(requirements, 'REQUIREMENT_CONTRACT').status

    const signMethod = deepKey(proposal, 'contract.sign_method')
    const signedFile = deepKey(proposal, 'contract.signedFile')

    const contractStatus = proposal.contract ? proposal.contract.status : {}

    const contractIsReady = juridicalStatus && insuranceStatus

    // formata a data de atualizacao do status do contrato
    const dateAndTime = proposal.contract.status_updated_at.split(' ')
    const date = dateAndTime[0]
    const time = dateAndTime[1]
    const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    const statusDate = `${formattedDate} às ${time}`

    const items = [
      {
        contain: (
          <SectionWrapper>
            {contractIsReady ? (
              <iframe
                title='Document'
                width='100%'
                style={{ height: 600 }}
                src={(this.props.contract || {}).source}
              />
            ) : (
              <Text>*O Contrato ainda não está pronto para assinatura.</Text>
            )}
          </SectionWrapper>
        ),
        icon: 'fa-eye',
        open: false,
        title: 'Visualização',
      },
      {
        contain: (
          <SectionWrapper>
            {contractIsReady && (
              <React.Fragment>
                <Settings
                  disabledAutoFocus
                  notification={this.props.notification}
                  processId={processId}
                  proposal={proposal}
                  onSuccess={this.onSuccessHandler.bind(this)}
                />
                {signMethod === 'PRINT' && (
                  <SectionWrapper header='Contrato assinado'>
                    {signedFile && (
                      <React.Fragment>
                        <Text>
                          <strong>Você já enviou um contrato assinado.</strong>
                        </Text>
                        <Space isSmall />
                      </React.Fragment>
                    )}
                    <ContractUpload
                      disabledAutoFocus
                      processId={processId}
                      proposal={proposal}
                      onSuccess={this.onUploadSuccess.bind(this)}
                      companyId={company ? company.id : ''}
                    />
                  </SectionWrapper>
                )}
              </React.Fragment>
            )}
          </SectionWrapper>
        ),
        icon: 'fa-pen-alt',
        open: true,
        title: 'Assinaturas',
      },
    ]

    const extraButtons = (
      <>
        <Button
          onClick={() => {
            ga.logEvent('options_menu', { custom_value: 'download-contract' })
            this.downloadFile.bind(this)()
          }}
          disabled={!contractIsReady}
          isBare
          isFullWidth
          isMedium
        >
          <Icon name='fas fa-eye' />
          Contrato original
        </Button>

        {contractStatus.code === 'STATUS_SENT' && (
          <Button isBare isFullWidth isMedium disabled>
            <Icon name='fas fa-hourglass-start' />
            Aguardando assinaturas
          </Button>
        )}
        {contractStatus.code === 'STATUS_SIGNED' && (
          <Button
            onClick={() => {
              ga.logEvent('options_menu', {
                custom_value: 'download-contract-signed',
              })
              this.downloadSignedFile.bind(this)()
            }}
            isBare
            isFullWidth
            isMedium
          >
            <Icon name='fas fa-eye' />
            Contrato Assinado
          </Button>
        )}
      </>
    )

    return isAgent ? (
      <form id='form-blocked'>
        <fieldset disabled='disabled'>
          <div ref={(element) => (this.componentRef = element)}>
            <div className='print-wrap'>
              <SectionWrapper
                customHeader={true}
                header={<p>Contrato</p>}
                headerRight={HeadRight({ extraButtons })}
              >
                <SectionGroup items={items} />

                {activeCompany && proposal.type !== 'TYPE_ACQUISITION' && (
                  <SectionWrapper header='Encerrar etapa'>
                    <ToggleRequirement
                      processId={processId}
                      onSuccess={this.closeRequirementSuccess.bind(this)}
                      requirement='contract'
                      requirementStatus={status}
                      companyId={activeCompany.id}
                    />
                  </SectionWrapper>
                )}

                {activeCompany &&
                  proposal.type === 'TYPE_ACQUISITION' &&
                  !status && (
                    <SectionWrapper header='Finalizar processo'>
                      <CloseRequirement
                        processId={processId}
                        onSuccess={this.closeRequirementSuccess.bind(this)}
                        requirement='contract'
                        companyId={activeCompany.id}
                      />
                    </SectionWrapper>
                  )}
              </SectionWrapper>
            </div>
          </div>
        </fieldset>
      </form>
    ) : (
      <div ref={(element) => (this.componentRef = element)}>
        <div className='print-wrap'>
          <SectionWrapper
            customHeader={true}
            header={
              <p>
                Contrato
                {contractStatus.code === 'STATUS_UNSENT' && (
                  <span style={{ marginLeft: 10 }} className='tag has-grey'>
                    {contractStatus.name}
                  </span>
                )}
                {contractStatus.code === 'STATUS_SENT' && (
                  <span style={{ marginLeft: 10 }} className='tag has-yellow'>
                    {contractStatus.name}
                  </span>
                )}
                {contractStatus.code === 'STATUS_REJECTED' && (
                  <span style={{ marginLeft: 10 }} className='tag has-red'>
                    {contractStatus.name}
                  </span>
                )}
                {contractStatus.code === 'STATUS_SIGNED' && (
                  <span style={{ marginLeft: 10 }} className='tag has-green'>
                    {contractStatus.name}
                  </span>
                )}
                <Button
                  disabled={this.state.updatingStatus}
                  onClick={this.updateContractStatus.bind(this, processId)}
                  style={{ marginLeft: 10 }}
                  isBare
                  alt='Atualizar Status do Contrato'
                >
                  <Icon name='fas fa-xs fa-repeat-alt' />
                </Button>
                <span
                  style={{
                    marginLeft: 10,
                    fontSize: 11,
                    fontWeight: 400,
                    textTransform: 'lowercase',
                  }}
                >
                  Status do contrato atualizado em: {statusDate}
                </span>
              </p>
            }
            headerRight={HeadRight({ extraButtons })}
          >
            <SectionGroup items={items} />

            {activeCompany && proposal.type !== 'TYPE_ACQUISITION' && (
              <SectionWrapper header='Encerrar etapa'>
                <ToggleRequirement
                  processId={processId}
                  onSuccess={this.closeRequirementSuccess.bind(this)}
                  requirement='contract'
                  requirementStatus={status}
                  companyId={activeCompany.id}
                />
              </SectionWrapper>
            )}

            {activeCompany && proposal.type === 'TYPE_ACQUISITION' && !status && (
              <SectionWrapper header='Finalizar processo'>
                <CloseRequirement
                  processId={processId}
                  onSuccess={this.closeRequirementSuccess.bind(this)}
                  requirement='contract'
                  companyId={activeCompany.id}
                />
              </SectionWrapper>
            )}
          </SectionWrapper>
        </div>
      </div>
    )
  }

  updateContractStatus(processId) {
    this.setState({ updatingStatus: true })
    request
      .get(`{company}/process/${processId}/contract/update-status`)
      .then(() => {
        this.setState({ updatingStatus: false })
        this.props.notification({
          message: 'Status do contrato atualizado com sucesso!',
        })
        this.props.fetch(this.props.match.params.processId)
      })
      .catch(() => {
        this.setState({ updatingStatus: false })
        this.props.notification({
          message: 'Ocorreu um erro ao tentar atualizar o status do contrato',
          status: 'danger',
        })
      })
  }

  onUploadSuccess(response) {
    if (response.isSuccess) {
      this.props.notification({
        message: 'Envio do contrato assinado com sucesso',
      })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: response.actionMessage,
        status: 'danger',
      })
    }
  }

  onSuccessHandler({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Opções do contrato atualizadas com sucesso',
      })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar atualizar as opções do contrato',
        status: 'danger',
      })
    }
  }

  closeRequirementSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({ message: 'Etapa alterada com sucesso' })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar alterar a etapa',
        status: 'danger',
      })
    }
  }

  downloadSignedFile() {
    if (!this.props.contract) return
    const { current } = this.props
    const { proposal } = current
    const href = deepKey(proposal, 'contract.signedFile.source')

    if (href) {
      const element = document.createElement('A')

      element.target = '_blank'
      element.href = href
      element.download =
        'contrato-de-locacao-assinado' +
        current.proposal.code.replace(' ', '') +
        '.pdf'
      element.click()
    } else {
      return this.props.notification({
        message: 'O contrato assinado ainda não está disponível',
        status: 'danger',
      })
    }
  }

  downloadFile() {
    if (!this.props.contract) return
    const { current } = this.props
    const element = document.createElement('A')

    element.target = '_blank'
    element.href = this.props.contract.source
    element.download =
      'contrato-de-locacao-' + current.proposal.code.replace(' ', '') + '.pdf'
    element.click()
  }
}

export default withRedux(
  ({ processDetails: { contract, current } }) => ({
    current,
    contract,
  }),
  { ...actions, ...mainActions, ...commons },
)(Contract)
