import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/lib/Icon'

import { getTypeOf, deepKey, filterArrayBy } from 'utils/utils'

export default class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.items,
      invertOrder: true,
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)) {
      this.setState({ items: this.props.items })
    }

    return null
  }

  render() {
    const tableHeader = this.props.cells.map((item, index) => {
      const isObject = getTypeOf(item) === 'Object'
      const headerLabel = isObject ? item.name : item
      const sortCallback = item.path ? this.sort.bind(this, item.path) : null

      return (
        <th
          key={index}
          className='datatable-cell'
          style={{
            cursor: item.path ? 'pointer' : null,
            textAlign: item.align,
            width: item.width,
          }}
          width={item.shrink ? '1px' : 'auto'}
          onClick={sortCallback}
        >
          <span className={item.path ? 'is-sortable' : null}>
            {headerLabel}
            {this.props.lastOrder && this.props.lastOrder == item.path ? (
              this.props.orderDirection == 'asc' ? (
                <Icon name='fas fa-long-arrow-down' />
              ) : (
                <Icon name='fas fa-long-arrow-up' />
              )
            ) : (
              ''
            )}
          </span>
        </th>
      )
    })

    const Cells = (props) => {
      const rowKeys = Object.keys(props)

      return this.props.cells.map((item, index) => {
        const content = !item.render ? (itemData) => itemData : item.render

        let contentData = props[rowKeys[index]]

        if (item.path) contentData = deepKey(props, item.path)
        const CSSClass = `datatable-cell ${
          this.props.isUpdatingRows ? 'opacity has-text-grey-light' : ''
        }${item.isMultiline ? ' is-multiline' : ''}`

        return (
          <td
            className={CSSClass}
            data-label={item.name}
            style={{ textAlign: item.align }}
            key={index}
          >
            <span
              onClick={
                item.onClick ? item.onClick.bind(null, props[rowKeys[index]]) : null
              }
              onKeyDown={
                item.onClick ? item.onClick.bind(null, props[rowKeys[index]]) : null
              }
              tabIndex='0'
              role='button'
            >
              {content(contentData, props)}
            </span>
          </td>
        )
      })
    }

    const hasActionCell = this.props.actionCell

    const tableRows = this.state.items.map((item, index) => {
      const onClickRow = this.props.onClickRow

      return (
        <tr
          className='datatable-row has-hover'
          onClick={onClickRow ? onClickRow.bind(null, item) : null}
          onKeyDown={onClickRow ? onClickRow.bind(null, item) : null}
          tabIndex='0'
          role='button'
          style={{ cursor: onClickRow ? 'pointer' : null }}
          key={index}
        >
          <Cells {...item} />
          {hasActionCell ? (
            <td className='datatable-cell'>
              <div className='button-fend'>{this.props.actionCell(item)}</div>
            </td>
          ) : null}
        </tr>
      )
    })

    const isEmpty = this.state.items.length < 1

    return (
      <React.Fragment>
        {/* Filter implementation */}
        {/* <input onChange={this.handleFilter.bind(this)} /> */}

        {isEmpty ? (
          <div className='datatable-empty'>
            <table className='datatable'>
              <thead className='datatable-head'>
                <tr className='datatable-row'>{tableHeader}</tr>
              </thead>
              <tbody className='datatable-body'>
                <tr className='datatable-row'>
                  <td className='datatable-cell' colSpan='100'>
                    {this.props.emptyState ? this.props.emptyState : '. . .'}
                  </td>
                </tr>
              </tbody>
              {this.props.footer ? (
                <tfoot className='datatable-footer'>
                  <tr className='datatable-row'>
                    <td className='datatable-cell' colSpan='100' />
                  </tr>
                </tfoot>
              ) : null}
            </table>
          </div>
        ) : (
          <table className='datatable'>
            <thead className='datatable-head'>
              <tr className='datatable-row'>
                {tableHeader}
                {hasActionCell ? <th className='datatable-cell' width='1' /> : null}
              </tr>
            </thead>
            <tbody className='datatable-body'>{tableRows}</tbody>
            {this.props.footer ? (
              <tfoot className='datatable-footer'>
                <tr className='datatable-row'>
                  <td className='datatable-cell' colSpan='100'>
                    {this.props.footer}
                  </td>
                </tr>
              </tfoot>
            ) : null}
          </table>
        )}
      </React.Fragment>
    )
  }

  handleFilter({ target }) {
    let items = filterArrayBy(this.props.items, target.value)

    this.setState({ items: items })
  }

  sort(orderBy) {
    if (this.props.onSort) {
      this.props.onSort(orderBy)

      return
    }
    let items = this.state.items

    if (this.state.invertOrder) {
      items.sort((array1, array2) => {
        if (deepKey(array1, orderBy) > deepKey(array2, orderBy)) return 1
        if (deepKey(array1, orderBy) < deepKey(array2, orderBy)) return -1

        return 0
      })
    } else {
      items.sort((array1, array2) => {
        if (deepKey(array1, orderBy) < deepKey(array2, orderBy)) return 1
        if (deepKey(array1, orderBy) > deepKey(array2, orderBy)) return -1

        return 0
      })
    }
    this.setState({ items: items, invertOrder: !this.state.invertOrder })
  }
}

Table.defaultProps = {
  onSort: null,
  onClickRow: null,
}

Table.propTypes = {
  cells: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  onClickRow: PropTypes.func,
}
