import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubmitButton from 'components/SubmitButton';
import formCreator from '../components/formCreator';
import EditorWithToken from '../../components/EditorWithTokens';
import { ButtonWrapper, ReadyContractWrapper } from './styles';

class EditContract extends Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = { template: props?.template || '' };
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  handleChange(evt) {
    this.setState({ template: evt.target.value });
  }

  createRequest() {
    const { template } = this.state;
    if (!template) return;
    const { processId, submitForm, submit } = this.props;
    const url = `{company}/process/${processId}/juridical/update-contract`;
    submitForm();
    submit(url, { contents: template }, 'put');

    if (this.props.closeModal) {
      this.props.closeModal();
    }
  }

  render() {
    const { template } = this.state;
    const { isProcessing, tokenOptions } = this.props;
    const hideStyle = { display: 'none' };

    return (
      <>
        <ReadyContractWrapper>
          <div style={!isProcessing ? hideStyle : {}}>
            <div style={{ height: '350px', marginTop: 200 }}>
              <div style={{ margin: '0 auto', padding: '45px 60px' }} className="empty-state-spinner" />
            </div>
          </div>
          <div style={isProcessing ? hideStyle : {}}>
            <EditorWithToken
              defaultValue={{ template }}
              handleFieldChanges={(t, content) => {
                this.setState({ template: content });
              }}
              tokenList={tokenOptions || []}
              shouldInsertRawValue
            />
          </div>
        </ReadyContractWrapper>
        <ButtonWrapper>
          <SubmitButton label="Salvar" hasThemeColor onClick={this.createRequest.bind(this)} isLoading={isProcessing} />
        </ButtonWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  activeCompany: state.app.activeCompany,
});

export default formCreator(connect(mapStateToProps)(EditContract), {
  noForm: true,
});
