import React from 'react'

import listWrapper from 'containers/Records/listWrapper'

import withFilter from 'components/HOCs/withFilter'
import EmptyState from 'components/EmptyState'

import Table from 'components/lib/Table'

import ListRecordActionCell from '../../../components/ListRecordActionCell'

const List = (props) => {
  props.createFilterRequest(props.onSearch)

  return (
    <Table
      lastOrder={props.lastOrder}
      orderDirection={props.orderDirection}
      onSort={props.orderBy}
      items={props.items}
      isUpdatingRows={props.isWorking}
      onClickRow={props.handleRowClick}
      cells={[
        {
          name: 'Código',
          path: 'code',
        },
        {
          name: 'Responsável',
          path: 'creator.name',
          render: (_, row) => {
            if (row && row.creator) {
              return row.creator.name || row.creator.email
            }

            return 'Sistema'
          },
        },
        {
          name: 'Nome',
          path: 'name',
          width: '40%',
        },
        {
          name: 'Endereço',
          path: 'address.neighborhood',
          render: (content, row) =>
            content ? (
              <span>
                {row.address.neighborhood}
                <br />
                {row.address.city ? row.address.city + ' - ' : null}
                {row.address.state}
              </span>
            ) : null,
        },
        {
          name: 'Tipo',
          path: 'type.description',
          render: (content, row) => <span>{row.type?.name}</span>,
        },
      ]}
      actionCell={(row) => (
        <ListRecordActionCell
          handleEditRow={props.handleEditRow.bind(null, row)}
          handleExcludeRow={props.handleExcludeRow.bind(null, row)}
        />
      )}
      emptyState={<EmptyState isLoading={props.isWorking} />}
    />
  )
}

List.defaultProps = {
  items: [],
  isWorking: false,
  handleEditRow: () => {},
  handleExcludeRow: () => {},
}

export default listWrapper(withFilter(List))
