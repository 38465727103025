import React from 'react';
import { request } from 'core/constants';
import moment from 'moment';

// Components
import Icon from 'components/lib/Icon';
import Table from 'components/lib/Table';
import Text from 'components/lib/Text';
import EmptyState from 'components/EmptyState';
import Grid from 'components/lib/Grid';
import { FormFieldText } from 'components/lib/FormFields';
import Button from 'components/lib/Button';
import UserSelection from 'features/components/fieldsGroup/UserSelection';

import { fb, ga } from '../../../../../services/firebase';

export class CreditAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      statusMessage: 'Aguardando análise...',
      isWorking: false,
      content: null,
    };
  }

  componentDidMount() {}

  render() {
    const { userData } = this.props;

    const { status, statusMessage, isWorking, content } = this.state;

    const statusIcon = status === 'good' ? 'fas fa-thumbs-up' : 'fas fa-thumbs-down';

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <Text>
              Deseja analizar o usuário: {userData.name} {status && <Icon name={statusIcon} />}
            </Text>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col isNarrow>
            <Button hasThemeColor onClick={this.checkUser.bind(this)} isLoading={isWorking} isDisabled={isWorking}>
              Analisar usuário
            </Button>
          </Grid.Col>
          <Grid.Col>
            <FormFieldText valueDefault={statusMessage} isDisabled />
          </Grid.Col>
        </Grid>
        {content && (
          <Grid>
            <Grid.Col>
              <Text>
                {/* {content.data_conclusao}
                {content.data_criacao}
                {content.data_modificacao}
                {content.id}
                {content.status} */}
                <strong>Ações JF: </strong>
                {content.acoes_jf || 'Nada Consta'}&nbsp;/&nbsp;
                <strong>Ações TJ: </strong>
                {content.acoes_tj || 'Nada Consta'}&nbsp;/&nbsp;
                <strong>Cheques: </strong>
                {content.cheques || 'Nada Consta'}&nbsp;/&nbsp;
                <strong>Documento: </strong>
                {content.documento || 'Nada Consta'}&nbsp;/&nbsp;
                <strong>Restrições: </strong>
                {content.restricoes || 'Nada Consta'}&nbsp;/&nbsp;
                <strong>Títulos: </strong>
                {content.titulos || 'Nada Consta'}
              </Text>
            </Grid.Col>
          </Grid>
        )}
      </React.Fragment>
    );
  }

  checkUser() {
    const { userData } = this.props;

    this.setState({
      statusMessage: 'Analisando...',
      isWorking: true,
    });

    const path = `{company}/${userData.type}/${userData.id}/credit-analysis`;

    request
      .get(path)
      .then((response) => {
        this.setState({
          statusMessage: 'Concluído!',
          isWorking: false,
          content: { ...response.data.dados.situacao, ...response.data.laudo.pesquisa },
        });
      })
      .catch(() => {
        this.setState({
          statusMessage: 'Ocorreu um erro na consulta!',
          isWorking: false,
        });
      });
  }
}

export default CreditAnalysis;
