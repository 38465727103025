import React, { Component, Fragment } from 'react'
import map from 'lodash/map'
import request from 'axios'

import { baseURL } from 'core/constants'
import withRedux from 'components/redux'
import { ROLE } from 'components/constants'

import Button from 'components/lib/Button'
import ActionsBar from './components/ActionsBar'
import DocsTable from './components/DocsTable'
import ShowFiles from './components/ShowFiles'
import * as types from './types'
import FullscreenModal from 'components/lib/FullscreenModal'
import Modal from 'components/lib/Modal'
import { FormFieldTextArea } from 'components/lib/FormFields'
import Icon from 'components/lib/Icon'
import Success from './components/Success'
import Failed from './components/Failed'
import RequestByEmail from './components/Email'
import DocumentRequest from 'components/DocumentsRequest/DocumentsRequest'
import { DOCUMENT_LIST_ID } from '../../../../izee-app/src/constants/documentList'

class Documents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addDoc: '',
      documents: [],
      selected: [],
      inputValue: '',
      isWorking: false,
      options: [],
      openModal: false,
      smallOpenModal: false,
      smallFeature: null,
      reason: '',
      pass: false,
      percent: 0,
      uploadId: null,
      allChecked: false,
      clear: false,
    }
    this.asyncRef = React.createRef()
    this.handleUrl = this.handleUrl.bind(this)
    this.handleActionModal = this.handleActionModal.bind(this)
    this.handleFeature = this.handleFeature.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleTextArea = this.handleTextArea.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  get forbiddenForJuridicalAndInspector() {
    return this.props.forbiddenAccess([
      ROLE.PROFILE_INSPECTOR,
      ROLE.PROFILE_JURIDICAL,
    ])
  }

  componentDidUpdate() {
    const { docLoad } = this.state

    if (!docLoad) {
      this.loadDocs()
    }
  }

  componentDidMount() {
    let pageWidth = document.body.offsetWidth

    this.setState({ pageWidth })
  }

  handleTextArea(reason) {
    this.setState({ reason })
  }

  handleSelected(content) {
    const { selected } = this.state

    let index = selected.indexOf(content)

    if (index === -1) {
      selected.push(content)
    } else {
      selected.splice(index, 1)
    }
    this.setState({ selected })
  }

  getDocumentByIndex(index) {
    const { documents } = this.state

    return documents[index]
  }

  getDocumentById(id) {
    const { documents } = this.state
    const size = documents.length

    for (let i = 0; i < size; i++) {
      if (documents[i].id === id) return documents[i]
    }
  }

  handleDownload() {
    map(this.state.selected, (name, i) => {
      let doc = this.getDocumentById(name)

      if (doc.new_source) {
        fetch(doc.new_source)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.style.display = 'none'
            a.href = url
            a.download = doc.file_name
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
          })
          .catch((error) => console.log(error))
      }
    })
  }

  handleEmail() {
    const { documents } = this.state
    const {
      entityId,
      app: {
        activeCompany: { id: companyId },
      },
      entityName,
    } = this.props

    return this.setState({
      smallOpenModal: true,
      smallFeature: (
        <RequestByEmail
          noMessagebox={this.props.noMessagebox}
          title='Você deseja solicitar o(s) documento(s):'
          documents={documents}
          userId={entityId}
          companyId={companyId}
          entityName={entityName}
        />
      ),
    })
  }

  handleModalClose() {
    this.setState({
      openModal: false,
      smallOpenModal: false,
      pass: false,
      buttonLoading: false,
    })
  }

  handleConfirm(name) {
    const spinner = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Icon style={{ fontSize: '2rem' }} name='fas fa-spinner fa-pulse' />
      </div>
    )

    this.setState({ pass: true, smallFeature: spinner }, () => {
      this.handleActions(name)
    })
  }

  handleCreateFeature(name, documents) {
    let title = '',
      approveButton = '',
      cancelButton = '',
      hasReason = null,
      isSuccess = null,
      feature = null

    switch (name) {
      case 'Aceitar':
        title = 'Você deseja aceitar o(s) documento(s):'
        hasReason = false
        approveButton = 'Aceitar documento(s)'
        cancelButton = 'Cancelar'
        isSuccess = true
        feature = this.handleFeature(
          title,
          documents,
          hasReason,
          approveButton,
          cancelButton,
          isSuccess,
          name,
        )

        return this.setState({
          smallOpenModal: true,
          smallFeature: feature,
        })

      case 'Recusar':
        title = 'Você deseja recusar o(s) documento(s):'
        hasReason = true
        approveButton = 'Recusar documento(s)'
        cancelButton = 'Cancelar'
        isSuccess = false
        feature = this.handleFeature(
          title,
          documents,
          hasReason,
          approveButton,
          cancelButton,
          isSuccess,
          name,
        )

        return this.setState({
          smallOpenModal: true,
          smallFeature: feature,
        })

      case 'Deletar':
        title = 'Você deseja deletar o(s) documento(s):'
        hasReason = false
        approveButton = 'Deletar documento(s)'
        cancelButton = 'Cancelar'
        isSuccess = false
        feature = this.handleFeature(
          title,
          documents,
          hasReason,
          approveButton,
          cancelButton,
          isSuccess,
          name,
        )

        return this.setState({
          smallOpenModal: true,
          smallFeature: feature,
        })
    }
  }

  handleFeature(
    title,
    documents,
    hasReason,
    approveButton,
    cancelButton,
    isSuccess,
    name,
  ) {
    const buttonsStyle = {
      width: 190,
      borderRadius: 3,
      paddingTop: 10,
      paddingBottom: 10,
    }

    return (
      <Fragment>
        <div className='actions-title'>{title}</div>

        <div className='document-name-container'>
          {documents.map((document, i) => (
            <div className='document-name' key={i}>
              - {document.name}
              <br />
            </div>
          ))}
        </div>

        {hasReason && (
          <FormFieldTextArea
            style={{ fontSize: 16, color: '#5F6776' }}
            labelStyle={{ color: '#5F6776', fontWeight: 'bold' }}
            label='Qual o motivo?'
            onChange={(reason) => this.handleTextArea(reason)}
          />
        )}

        <div className='actions-buttons'>
          <Button
            isLight
            style={buttonsStyle}
            normal
            onClick={this.handleModalClose.bind(this)}
          >
            {cancelButton}
          </Button>

          {isSuccess ? (
            <Button
              normal
              style={buttonsStyle}
              isSuccess
              onClick={() => this.handleConfirm(name)}
            >
              {approveButton}
            </Button>
          ) : (
            <Button
              normal
              style={buttonsStyle}
              isDanger
              onClick={() => this.handleConfirm(name)}
            >
              {approveButton}
            </Button>
          )}
        </div>
      </Fragment>
    )
  }

  handleViewDocument(row) {
    this.clearSelected()
    this.setState({ selected: [row.id] })
    const docs = []

    docs.push(row)

    const handleHideModal = () =>
      this.setState({
        openModal: false,
      })

    this.setState({
      openModal: true,
      feature: (
        <ShowFiles
          {...this.props}
          noChangeStatus={this.forbiddenForJuridicalAndInspector}
          documents={docs}
          noDownload={true}
          handleHideModal={handleHideModal}
          loadDocs={this.loadDocs.bind(this)}
          handleView={this.handleView.bind(this)}
          clearSelected={this.clearSelected.bind(this)}
        />
      ),
    })
  }

  handleView() {
    const { selected } = this.state
    const docs = []

    selected.map((id) => {
      const doc = this.getDocumentById(id)

      if (doc.status === types.STATUS_WAITING) return
      docs.push(doc)
    })
    this.setState({
      openModal: true,
      feature: (
        <ShowFiles
          {...this.props}
          noChangeStatus={this.forbiddenForJuridicalAndInspector}
          documents={docs}
          noDownload={true}
          loadDocs={this.loadDocs.bind(this)}
          handleView={this.handleView.bind(this)}
          clearSelected={this.clearSelected.bind(this)}
        />
      ),
    })
  }

  clearSelected() {
    this.setState({ selected: [] })
  }

  handleActions(name) {
    const { pass } = this.state

    switch (name) {
      case 'Visualizar':
        return this.handleView()
      case 'Download':
        return this.handleDownload()
      case 'Solicitar documento':
        return this.handleEmail()
      case 'Aceitar':
        if (pass) {
          return this.handleApprove()
        }

        return this.handleActionModal(this.state.selected, name)

      case 'Recusar':
        if (pass) {
          return this.handleReject()
        }

        return this.handleActionModal(this.state.selected, name)

      case 'Deletar':
        if (pass) {
          return this.handleDelete()
        }

        return this.handleActionModal(this.state.selected, name)
    }
  }

  handleUrl(type, docId = null) {
    const { entityName, entityId, publicURL, bearerToken } = this.props

    const prefixURL = publicURL ? `${baseURL}public/` : `${baseURL}`

    let dbEntityId = null

    switch (entityName) {
      case 'proposal':
        dbEntityId = 1
        break
      case 'property':
        dbEntityId = 2
        break
      case 'propertyOwner':
        dbEntityId = 3
        break
      case 'client':
        dbEntityId = 4
        break
      case 'guarantor':
        dbEntityId = 5
        break
      case 'processPossession':
        dbEntityId = 6
        break
      case 'processInsurance':
        dbEntityId = 7
        break
    }

    switch (type) {
      case 'show':
        if (bearerToken)
          return `${baseURL}register/entity/${dbEntityId}/show/${entityId}/owner`

        return `${prefixURL}entity/${dbEntityId}/show/${entityId}/owner`
      case 'upload':
        if (bearerToken)
          return `${prefixURL}register/entity/${dbEntityId}/upload/file`

        return `${prefixURL}entity/${dbEntityId}/upload/file`
      case 'update':
        if (bearerToken)
          return `${prefixURL}register/entity/${dbEntityId}/update/file`

        return `${prefixURL}entity/${dbEntityId}/update/file`
      case 'delete':
        if (bearerToken)
          return `${baseURL}register/entity/${dbEntityId}/delete/${docId}/file`

        return `${prefixURL}entity/${dbEntityId}/delete/${docId}/file`
    }
  }

  loadDocs(callback = null) {
    const { clientDocumentList, entityId, entityName, publicURL } = this.props

    if (!entityId || !entityName) return

    const type = 'show'
    const url = this.handleUrl(type)

    const { bearerToken } = this.props

    const config = bearerToken
      ? {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Access-Control-Allow-Origin': true,
          },
        }
      : {
          'Access-Control-Allow-Origin': true,
        }

    request
      .get(url, config)
      .then((response) => {
        let result = []

        if (response instanceof Array) {
          result = response
        } else {
          result = response.data
        }

        this.setState(
          {
            docLoad: true,
            documents: result,
          },
          () => {
            if (callback) callback()
          },
        )

        if (
          entityName === 'client' &&
          publicURL &&
          clientDocumentList?.length &&
          !result.length
        ) {
          let docs = clientDocumentList?.map((doc) => ({ name: doc, code: doc }))

          this.handleNewDocs(docs)
        }
      })
      .catch((err) => console.log('erro', err))
  }

  handleActionModal(ids, action) {
    const docs = []

    ids.map((id) => {
      const doc = this.getDocumentById(id)

      if (doc.status === types.STATUS_WAITING) return
      docs.push(doc)
    })

    return this.handleCreateFeature(action, docs)
  }

  handleApprove() {
    const msg = 'aprovado(s)'

    let promises = []

    map(this.state.selected, (name, i) => {
      let new_doc = {
        id: name,
        status: types.STATUS_APPROVED,
      }

      let _p = this.uploadDocument(null, new_doc, msg)

      promises.push(_p)
    })
    let callback = this.loadDocs.bind(this)

    return Promise.all(promises).then(() => {
      return callback()
    })
  }

  handleDelete() {
    const msg = 'deletado(s)'

    let promises = []
    const { bearerToken } = this.props
    const config = bearerToken
      ? {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Access-Control-Allow-Origin': true,
          },
        }
      : {
          'Access-Control-Allow-Origin': true,
        }

    map(this.state.selected, (name, i) => {
      let url = this.handleUrl('delete', name)

      let _p = new Promise((resolve, reject) => {
        return request.delete(url, config).then(() => {
          return resolve(this.handleUpdate(true, msg))
        })
      })

      promises.push(_p)
    })

    let callback = this.loadDocs.bind(this)

    return Promise.all(promises).then(() => {
      this.setState({ selected: [] }, callback())
    })
  }

  async handleNewDocs(docs = []) {
    let promises = []

    let addDoc = this.state.addDoc || docs || []

    addDoc.map((doc) => {
      let new_doc = {
        name: doc.name,
        status: types.STATUS_WAITING,
      }

      let _p = this.uploadDocument(null, new_doc, doc.name)

      promises.push(_p)
    })

    this.setState({ addDoc: [] })

    let callback = this.loadDocs.bind(this)

    await Promise.all(promises).then(() => {
      this.setState({ clear: true }, callback())
    })

    return
  }

  handleReject() {
    const msg = 'rejeitado(s)'

    let promises = []

    map(this.state.selected, (name, i) => {
      let new_doc = {
        id: name,
        status: types.STATUS_REJECTED,
      }

      let _p = this.uploadDocument(null, new_doc, msg)

      promises.push(_p)
    })
    let callback = this.loadDocs.bind(this)

    return Promise.all(promises).then(() => {
      return callback()
    })
  }

  handleSavedDoc(row) {
    const updatedRow = { ...row }

    updatedRow.sent = !updatedRow.sent
    const newDocuments = this.state.documents

    newDocuments[row.index] = updatedRow
    this.setState({
      documents: newDocuments,
    })
  }

  handleUpdate(success, msg) {
    if (success) {
      return this.setState({
        smallFeature: <Success msg={msg} />,
        selected: [],
      })
    }

    return this.setState({
      smallFeature: <Failed msg={msg} />,
      selected: [],
    })
  }

  handleDbEntityId() {
    const { entityName } = this.props

    switch (entityName) {
      case 'proposal':
        return 1
      case 'property':
        return 2
      case 'propertyOwner':
        return 3
      case 'client':
        return 4
      case 'guarantor':
        return 5
      case 'processPossession':
        return 6
      case 'processInsurance':
        return 7
    }
  }

  uploadDocument(event, row, msg = null) {
    const { entityId } = this.props
    const upload = new FormData()

    let { status, name, id } = row

    let file = event ? event.nativeEvent.target.files : null

    let method = 'update'

    status = file ? types.STATUS_ANALYSIS : status
    switch (status) {
      case types.STATUS_WAITING:
        method = 'upload'
        upload.append('name', name)
        upload.append('owner_document', entityId)
        upload.append('entity_id', this.handleDbEntityId())
        break
      case types.STATUS_ANALYSIS:
        upload.append('file', file ? file[0] : null)
        upload.append('name', name)
        upload.append('id', id)
        upload.append('entity_id', this.handleDbEntityId())
        upload.append('owner_document', entityId)
        break
      case types.STATUS_APPROVED:
        upload.append('id', id)
        upload.append('entity_id', this.handleDbEntityId())
        upload.append('owner_document', entityId)
        break
      case types.STATUS_REJECTED:
        const { reason } = this.state

        upload.append('comments', reason)
        upload.append('id', id)
        upload.append('entity_id', this.handleDbEntityId())
        upload.append('owner_document', entityId)
        break
      default:
        return
    }

    upload.append('status', status)

    let url = this.handleUrl(method)

    const { bearerToken } = this.props

    const config = bearerToken
      ? {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Access-Control-Allow-Origin': true,
          },
          onUploadProgress: (progressEvent) => {
            const { total, loaded } = progressEvent
            const percent = ((loaded * 100) / total).toFixed(0)

            this.setState({ percent })
          },
        }
      : {
          'Access-Control-Allow-Origin': true,
          onUploadProgress: (progressEvent) => {
            const { total, loaded } = progressEvent
            const percent = ((loaded * 100) / total).toFixed(0)

            this.setState({ percent })
          },
        }

    return new Promise((resolve, reject) => {
      return request.post(url, upload, config).then((response) => {
        if (response.success) {
          return resolve(this.handleUpdate(true, msg))
        } else {
          return resolve(this.handleUpdate(false, msg))
        }
      })
    })
  }

  handleOnChangeRow(e, row) {
    return this.uploadDocument(e, row).then(() => {
      return this.loadDocs()
    })
  }

  handleAddChange(e) {
    this.setState({ addDoc: e })
  }

  uploading({ id }) {
    return this.setState({ uploadId: id })
  }

  handleSelectAll() {
    const { selected, documents } = this.state

    const selectedSize = selected.length
    const docsSize = documents.length

    if (selectedSize === docsSize) {
      return this.setState({ selected: [], allChecked: false })
    }

    const allSelected = []

    documents.map((document) => allSelected.push(document.id))

    return this.setState({ selected: allSelected, allChecked: true })
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      this.handleNewDocs()
    }
  }

  render() {
    const {
      documents,
      openModal,
      feature,
      smallFeature,
      smallOpenModal,
      allChecked,
    } = this.state

    const { isMultiStep, noAddDoc: noAddDocProps } = this.props

    const noAddDoc = noAddDocProps || this.forbiddenForJuridicalAndInspector

    let docsToTable = map(documents, (item) => {
      return {
        id: item.id,
        name: item.name,
        status: item.status
          ? item.status
          : item.new_source
          ? types.STATUS_ANALYSIS
          : types.STATUS_WAITING,
        file_name: item.file_name,
        new_source: item.new_source,
        comments: item.comments,
      }
    })

    return !isMultiStep ? (
      <React.Fragment>
        {!noAddDoc && (
          <div className='docsInput'>
            <DocumentRequest
              title='Solicitar documentos'
              onChange={this.handleAddChange.bind(this)}
              defaultValue={this.state.addDoc}
              clear={this.state.clear}
              onClear={() => {
                this.setState({ clear: false })
              }}
            />
            <Button hasThemeColor onClick={this.handleNewDocs.bind(this)}>
              +
            </Button>
          </div>
        )}

        <div className='docsTable'>
          <DocsTable
            {...this.props}
            items={docsToTable}
            hideUpload={this.forbiddenForJuridicalAndInspector}
            selected={this.state.selected}
            handleSavedDoc={this.handleSavedDoc.bind(this)}
            handleSelectRow={this.handleSelected.bind(this)}
            handleSelectAll={this.handleSelectAll.bind(this)}
            formFieldChange={this.handleOnChangeRow.bind(this)}
            handleActions={this.handleActions.bind(this)}
            handleViewDocument={this.handleViewDocument.bind(this)}
            refresh={this.loadDocs.bind(this)}
            docsUrl={this.handleUrl('update')}
            allChecked={allChecked}
          />
        </div>

        <div className='ActionsBar'>
          <ActionsBar
            {...this.props}
            selected={this.state.selected}
            documents={this.state.documents}
            handleActions={this.handleActions.bind(this)}
            allDocs={docsToTable}
          />
        </div>

        <FullscreenModal
          docs={this.state.documents}
          isOpen={openModal}
          onClose={this.handleModalClose.bind(this)}
          responsive={true}
          {...this.props}
        >
          {feature}
        </FullscreenModal>

        <Modal
          isOpen={smallOpenModal}
          onClose={this.handleModalClose.bind(this)}
          responsive={true}
        >
          {smallFeature}
        </Modal>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {!noAddDoc && (
          <div className='docsInput'>
            <DocumentRequest
              title='Solicitar documentos'
              onChange={this.handleAddChange.bind(this)}
              defaultValue={this.state.addDoc}
              clear={this.state.clear}
              onClear={() => this.setState({ clear: false })}
              defaultMultiOption={[]}
            />
            <Button hasThemeColor onClick={this.handleNewDocs.bind(this)}>
              +
            </Button>
          </div>
        )}

        <div className='docsTable'>
          <DocsTable
            {...this.props}
            items={docsToTable}
            selected={this.state.selected}
            handleSavedDoc={this.handleSavedDoc.bind(this)}
            handleSelectRow={this.handleSelected.bind(this)}
            formFieldChange={this.handleOnChangeRow.bind(this)}
            handleSelectAll={this.handleSelectAll.bind(this)}
            handleActions={this.handleActions.bind(this)}
            handleViewDocument={this.handleViewDocument.bind(this)}
            refresh={this.loadDocs.bind(this)}
            docsUrl={this.handleUrl('update')}
            allChecked={allChecked}
          />
        </div>

        <div className='ActionsBar'>
          <ActionsBar
            {...this.props}
            selected={this.state.selected}
            documents={this.state.documents}
            handleActions={this.handleActions.bind(this)}
            allDocs={docsToTable}
          />
        </div>

        <FullscreenModal
          docs={this.state.documents}
          isOpen={openModal}
          onClose={this.handleModalClose.bind(this)}
          responsive={true}
          {...this.props}
        >
          {feature}
        </FullscreenModal>

        <Modal
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          isOpen={smallOpenModal}
          onClose={this.handleModalClose.bind(this)}
          responsive={true}
        >
          {smallFeature}
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRedux((store) => {
  return {
    clientDocumentList:
      store?.app?.hashData?.company?.meta?.documentLists?.find(
        (list) => list.id === DOCUMENT_LIST_ID.CONFIRM_PROPOSAL_CLIENT,
      )?.list || [],
  }
})(Documents)
