import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  FormFieldText
} from 'components/lib/FormFields';
import Grid from 'components/lib/Grid';
import SubmitButton from 'components/SubmitButton';
import formCreator, { fieldProps } from '../components/formCreator';

export class ArchiveProposal extends React.Component {

  componentDidMount() {
    this.props.createRequest(this.createRequest);
  }

  render() {

    const { formIsWorking, formIsReady } = this.props;

    return (
      <React.Fragment>

        <Grid>
          <Grid.Col>
            <FormFieldText
              label="Motivo"
              {...fieldProps(this.props, 'status_comment')}
            />
          </Grid.Col>
        </Grid>

        <SubmitButton
          label="Arquivar"
          isDanger
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />

      </React.Fragment>
    );
  }

  createRequest(fields) {
    this.props.submit(`company/${
      this.props.activeCompany.id
    }/proposal/${
      this.props.proposalId
    }/archive`, fields, 'post');
  }

};

ArchiveProposal.defaultProps = {};

ArchiveProposal.propTypes = {
  activeCompany: PropTypes.object.isRequired,
};

export default formCreator(ArchiveProposal);
