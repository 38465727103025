import React from 'react'

import { connect } from 'react-redux'

import { request } from 'core/constants'

import * as commons from 'utils/UtilityStore/actions'
import { copyToClipboard } from 'utils/utils'

import CreatePropertyOwner from 'features/PropertyOwner/CreatePropertyOwner'
import RequestRegistration from 'features/RequestRegistration'

import RequestByEmail from 'components/Documents/components/Email'
import ConfirmationMessage from 'components/ConfirmationMessage'
import List from './components/List'

import ConfirmationModal from 'components/lib/Modal'
import Modal from 'components/lib/RegisterFormModal'
import SmallModal from 'components/lib/Modal'
import Button from 'components/lib/Button'

import ConfirmExcludeModal from 'components/ConfirmExcludeModal'

import * as actions from './actions'

class PropertyOwners extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastOrder: '',
      orderDirection: '',
      companyInfo: {
        companyName: '',
        themeColor: '',
      },
      isCreatingPropertyOwner: false,
      isRequestingOwner: false,
      requestModalOpen: false,
      filter: {},
      propertyOwnerDocuments: [],
      propertyOwnerFields: {
        propertyOwnerName: '',
        propertyOwnerEmail: '',
        propertyOwnerDocs: '',
      },
      success: '',
      message: '',
      formIsWorking: false,
      smallModalOpen: false,
      confirmationModalOpen: false,
      showExcludeModal: false,
      excludeRowId: null,
    }
  }

  componentDidMount() {
    this.props.fetch(this.state.filter)
    const companyInfo = {
      companyName: this.props.company.name,
      themeColor: this.props.companyMeta.themeColor,
    }
    this.setState({ companyInfo })
  }

  createPropertyOwner = () => {
    this.setState({
      isCreatingPropertyOwner: true,
    })
  }

  closeCreatePropertyOwner = () => {
    this.setState(
      {
        isCreatingPropertyOwner: false,
      },
      () => {
        this.props.fetch(this.state.filter)
      },
    )
  }

  requestRegistration = () => {
    this.setState({ isRequestingOwner: true, requestModalOpen: true })
  }

  validateNewPropertyOwner = () => {
    const { propertyOwnerName, propertyOwnerEmail } = this.state.propertyOwnerFields

    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)

    const fieldErrors = {
      name: [],
      email: [],
    }

    let isValid = true

    if (propertyOwnerName === '') {
      fieldErrors['name'] = ['Nome inválido', true]
      isValid = false
    } else {
      delete fieldErrors['name']
    }

    if (!emailRegex.test(propertyOwnerEmail)) {
      fieldErrors['email'] = ['Email inválido', true]
      isValid = false
    } else {
      delete fieldErrors['email']
    }

    this.setState({ fieldErrors })

    return isValid
  }

  updatePropertyOwnerList = () => {
    setTimeout(() => {
      this.props.fetch(this.state.filter)
    }, 500)
  }

  handleRegisterPropertyOwner = () => {
    const isValid = this.validateNewPropertyOwner()

    this.setState({ formIsWorking: true })

    if (!isValid) return

    const companyId = this.props.company.id
    const url = `/company/${companyId}/property-owner`
    const { propertyOwnerFields } = this.state
    const data = {
      name: propertyOwnerFields.propertyOwnerName,
      email: propertyOwnerFields.propertyOwnerEmail,
    }

    request
      .post(url, data)
      .then((res) => {
        this.setState(
          {
            activeId: res.data.id,
          },
          async () => {
            this.updatePropertyOwnerList()

            const docsUrl = '/entity/4/upload/file'

            const {
              propertyOwnerFields: { propertyOwnerDocs },
            } = this.state

            let requests = propertyOwnerDocs.map(
              (document) =>
                new Promise((resolve) => {
                  const data = new FormData()

                  data.append('name', document.name)
                  data.append('owner_document', this.state.activeId)
                  data.append('entity_id', '3')
                  data.append('status', 'STATUS_WAITING')

                  resolve(
                    request.post(docsUrl, data).catch((error) => {
                      console.log('error', error)
                    }),
                  )
                }),
            )

            await Promise.all(requests).then(() => {
              this.setState({
                formIsWorking: false,
                smallModalOpen: true,
                success: true,
                message: 'Proprietário cadastado com sucesso!',
              })
              setTimeout(() => {
                this.setState({
                  confirmationModalOpen: false,
                  modalIsOpen: false,
                  modalTitle: '',
                })
              }, 2500)
            })
          },
        )
      })
      .catch((error) => {
        const { errors } = error
        const message = errors[0].message
        this.setState({
          formIsWorking: false,
          confirmationModalOpen: true,
          success: false,
          message: message,
        })
      })
  }

  requestRegistrationClose = () => {
    this.setState(
      {
        modalTitle: 'Solicitar Cadastro de proprietário',
        isRequestingOwner: false,
        requestModalOpen: false,
      },
      () => {
        this.updatePropertyOwnerList()
      },
    )
  }

  selectPropertyOwner = (name, e) => {
    const updatedFields = { ...this.state.propertyOwnerFields }
    updatedFields[name] = e
    this.setState({ propertyOwnerFields: updatedFields })
  }

  smallModalClose = () => {
    this.setState({ smallModalOpen: false, requestModalOpen: false }, () => {
      this.updatePropertyOwnerList()
    })
  }

  render() {
    const { isCreatingPropertyOwner } = this.state

    const styles = {
      requestRegistration: {
        borderWidth: 1,
        borderColor: '#D5DADF',
        borderStyle: 'solid',
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
        color: '#7F8996',
        fontWeight: 'bold',
        marginRight: '0.5rem',
      },
    }

    const formConfigRequestReg = {
      selected: 0,
      sections: [
        {
          formTitle: 'Solicitar cadastro de proprietário',
          buttonTitle: 'Solicitar cadastro',
          customBody: true,
          progressBar: false,
          sectionNumber: '',
          sectionTitle: '',
          progressTitle: '',
        },
      ],
    }

    const { companyInfo } = this.state

    return (
      <>
        {this.state.showExcludeModal && (
          <ConfirmExcludeModal
            title='Excluir Proprietário?'
            text='Confirmar exclusão deste Proprietário?'
            onClose={() =>
              this.setState({
                showExcludeModal: false,
                excludeRowId: null,
              })
            }
            onExclude={this.excludeRow.bind(this, this.state.excludeRowId)}
          />
        )}

        <List
          listName='Proprietários'
          items={this.props.items}
          pagination={this.props.listInfo}
          isWorking={this.props.isWorking}
          handleEditRow={this.openRow.bind(this)}
          handleExcludeRow={this.openExcludeModal.bind(this)}
          onChangePage={this.handlePagination.bind(this)}
          onSearch={this.searchList.bind(this)}
          orderBy={this.orderBy.bind(this)}
          lastOrder={this.state.lastOrder}
          orderDirection={this.state.orderDirection}
          isMedium={true}
          actionButton={
            <div className='buttons'>
              <Button
                onClick={this.requestRegistration}
                isFlat
                style={styles.requestRegistration}
              >
                Solicitar Novo
              </Button>
              <Button onClick={this.createPropertyOwner} isFlat hasThemeColor>
                Adicionar proprietário
              </Button>
            </div>
          }
        />

        {isCreatingPropertyOwner ? (
          <CreatePropertyOwner
            isApp
            push={this.props.history.push}
            activeUser={this.props.activeUser.id}
            activeCompany={this.props.activeCompany.id}
            companyInfo={companyInfo}
            companyId={this.props.company.id}
            closeCreatePropertyOwner={this.closeCreatePropertyOwner}
          />
        ) : (
          <Modal
            noTitleMobile
            isApp
            push={this.props.history.push}
            hideForwardButton={true}
            hideBackButton={true}
            activeUser={this.props.activeUser.id}
            activeCompany={this.props.activeCompany.id}
            isOpen={this.state.requestModalOpen}
            forward={this.handleRegisterPropertyOwner}
            close={this.requestRegistrationClose}
            onClose={this.requestRegistrationClose}
            isLoading={this.state.formIsWorking}
            formConfig={formConfigRequestReg}
            companyInfo={companyInfo}
          >
            <RequestRegistration
              {...this.props}
              {...this.state}
              sendPropertyOwner={this.selectPropertyOwner.bind(this)}
            />
          </Modal>
        )}

        <SmallModal
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          backgroundContentStyle={{ display: 'block' }}
          isOpen={this.state.smallModalOpen}
          onClose={this.smallModalClose}
        >
          <RequestByEmail
            fullForm
            noMessagebox
            requestForm
            confirmationTrue
            title='Como você deseja solicitar o cadastro?'
            userId={this.state.activeId}
            companyId={this.props.company.id}
            entityName={'propertyOwner'}
            documents={this.state.propertyOwnerDocuments}
          />
        </SmallModal>

        <ConfirmationModal
          disableClose={!!this.state.success}
          containerStyle={{ maxWidth: 500 }}
          headerStyle={{ backgroundColor: 'transparent' }}
          bodyStyle={{
            paddingTop: 0,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          backgroundContentStyle={{ display: 'block' }}
          isOpen={this.state.confirmationModalOpen}
        >
          <ConfirmationMessage
            success={this.state.success}
            message={this.state.message}
          />
        </ConfirmationModal>
      </>
    )
  }

  searchList(keywords) {
    const filter = { ...this.state.filter, ...{ search: keywords } }
    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  orderBy(order) {
    const filter = { ...this.state.filter, page: 1 }

    let orderDirection = ''

    if (this.state.lastOrder == order) {
      if (this.state.orderDirection == 'desc') {
        this.setState({ orderDirection: 'asc' })
        orderDirection = 'asc'
      } else if (this.state.orderDirection == 'asc') {
        this.setState({ orderDirection: 'desc' })
        orderDirection = 'desc'
      }
    } else {
      this.setState({ orderDirection: 'asc' })
      orderDirection = 'asc'
    }

    this.props.fetch(filter, order, orderDirection)
    this.setState({ lastOrder: order })
  }

  handlePagination(page) {
    const filter = { ...this.state.filter, ...{ page: page } }
    this.setState({ filter: filter })
    if (this.state.lastOrder && this.state.orderDirection) {
      this.props.fetch(filter, this.state.lastOrder, this.state.orderDirection)
    } else {
      this.props.fetch(filter)
    }
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    })
  }

  handleCreateSuccess() {
    this.props.notification({
      message: 'Proprietário cadastrado com sucesso',
    })
    this.props.fetch()
  }

  handleFeature() {
    this.setState({
      modalTitle: 'Adicionar proprietário',
      modalIsOpen: true,
      feature: (
        <CreatePropertyOwner onSuccess={this.handleCreateSuccess.bind(this)} />
      ),
    })
  }

  handleExternalFormUrl(url) {
    copyToClipboard(url)
    this.props.notification({
      message: 'Url copiada para área de transferência',
    })
  }

  openRow(row) {
    this.props.history.push(this.props.location.pathname + '/' + row.id)
  }

  openExcludeModal(row) {
    this.setState({
      showExcludeModal: true,
      excludeRowId: row.id,
    })
  }

  async excludeRow(rowId) {
    try {
      const deleteUrl = `/company/${this.props.activeCompany.id}/property-owner/${rowId}`

      await request.delete(deleteUrl)
      this.props.notification({
        message: 'Registro excluído com sucesso',
        status: 'success',
      })
      this.props.fetch(this.state.filter)
    } catch (erro) {
      this.props.notification({
        message: erro.message || 'Erro ao tentar excluir o Proprietário',
        status: 'danger',
      })
    }
  }
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    items: store.propertyOwners.items,
    listInfo: store.propertyOwners.listInfo,
    isWorking: store.commons.isWorking.propertyOwners,
  }),
  { ...actions, ...commons },
)(PropertyOwners)
