import React from 'react';
import request from 'axios';
import { baseURL } from 'core/constants';

// Components
import Grid from 'components/lib/Grid';
import Header from 'components/lib/Header';
import Button from 'components/lib/Button';

import PropertySelection from 'features/components/fieldsGroup/PropertySelection';
import GuarantorSelection from 'features/components/fieldsGroup/GuarantorSelection';
import ClientSelection from 'features/components/fieldsGroup/ClientSelection';

const redeVistoriasUrl = REDE_VISTORIAS_URL;
const redeVistoriasWebhook = WEBHOOK_VISTORIAS_URL;

export default class CreateSurvey extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      guarantors: [],
      property: {},
      propertyOwner: [],
      rvLoaded: false,
    };
  }

  selectedClientsHandler(clients) {
    this.setState({ clients })
  }

  selectedGuarantorsHandler(guarantors) {
    this.setState({ guarantors })
  }

  selectedPropertyHandler(property) {
    this.setState({
      property: property,
      propertyOwner: property.property_owners || []
    })
  }

  handleFieldChanges() {
    
  }

  render() {
    const {
      rvLoaded,
      clients,
      guarantors,
      property } = this.state

    return (
      <React.Fragment>

        <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
          Selecione os dados para criar a vistoria
        </Header>
        <Grid>
          <Grid.Col>

            {!rvLoaded ? (
              <div className='create-solicitation-fc'>

                <PropertySelection
                  {...this.props}
                  onSelect={this.selectedPropertyHandler.bind(this)}
                  isValid={true}
                  // errorMessage="Informe pelo menos um imóvel"
                  // showAddition={false}
                  handleFieldChanges={this.handleFieldChanges.bind(this)}
                />

                <ClientSelection
                  {...this.props}
                  onSelect={this.selectedClientsHandler.bind(this)}
                  isValid={true}
                  clients={clients}
                  handleFieldChanges={this.handleFieldChanges.bind(this)}
                />

                <GuarantorSelection
                  {...this.props}
                  onSelect={this.selectedGuarantorsHandler.bind(this)}
                  isValid={true}
                  // showAddition={false}
                  guarantors={guarantors}
                  handleFieldChanges={this.handleFieldChanges.bind(this)}
                />

                <div className="text-right mt-3">
                  <Button
                    style={{ marginRight: '5px' }}
                    isSmall
                    disabled={!property.id}
                    onClick={() => this.loadingRv()}>
                    Avancar
                  </Button>
                </div>
              </div>
            ) : null}



            <div className="sdk-rv-modal" id="sdk-rv"></div>

          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  loadingRv() {
    const { company, onSuccess } = this.props;
    const {
      clients,
      guarantors,
      property,
      propertyOwner
    } = this.state

    !(function (r, v, s, d, k, i, n) {
      if (r.rv) return;
      k = r.rv = function () {
        k.queue.push(arguments);
      };
      k.queue = [];
      i = v.createElement(s);
      i.async = !0;
      i.src = d;
      n = v.getElementsByTagName(s)[0];
      n.parentNode.insertBefore(i, n);
    })(window, document, 'script', redeVistoriasUrl);

    // Identificação do parceiro
    rv('partner', 'Izee');

    // CPF/CNPJ utilizado para identificar clientes que já possuem integração
    if(company.cnpj){
      rv('document', company.cnpj);
    } else if(company.cpf){
      rv('document', company.cpf);
    }
    
    // Tipo de vistoria solicitada
    rv('orderType', 'entrada');

    // Dados do Imovel
    rv('property', {
      building_id: property.code, //identificacao
      furnished: 'unfurnished', //mobiliada
      building_type: property.type ? property.type.name : '', //tipo
      purpose: 'Residencial', //proposito
      area: property.area,
      address: {
        neighborhood: property.address.neighborhood,
        state: property.address.state,
        city: property.address.city,
        zipcode: property.address.zip_code,
        street: property.address.address,
        number: property.address.number,
        complement: property.address.additional_address,
      },
    });

    // assinaturas
    const administradora = [
      {
        name: company.name,
        signature: true,
      },
    ];

    const peopleOwners = [];
    if (propertyOwner.length > 0) {
      propertyOwner.forEach(function (valor, chave) {
        peopleOwners.push({ name: valor.name, signature: true });
      });
    } else {
      peopleOwners.push({ name: '', signature: false });
    }

    const peopleClients = [];
    if (clients.length > 0) {
      clients.forEach(function (valor, chave) {
        peopleClients.push({ name: valor.name, signature: true });
      });
    } else {
      peopleClients.push({ name: '', signature: false });
    }

    const peopleGuarantors = [];
    if (guarantors.length > 0) {
      guarantors.forEach(function (valor, chave) {
        peopleGuarantors.push({ name: valor.name, signature: true });
      });
    } else {
      peopleGuarantors.push({ name: '', signature: false });
    }

    rv('people', [
      {
        id: 'propertyManager',
        title: 'Administradora',
        content: administradora,
      },
      {
        id: 'owner',
        title: 'Locador',
        content: peopleOwners,
      },
      {
        id: 'tenant',
        title: 'Cliente',
        content: peopleClients,
      },
      {
        id: 'guarantor',
        title: 'Fiador',
        content: peopleGuarantors,
      },
    ]);

    // webhook
    rv('webhook', redeVistoriasWebhook);
    // Evento com o retorno da solicitação (ver documentação).
    rv('on', 'order.created', function (code, price) {
      const data = {
        code: code,
        price: price,
        status: 'Aguardando',
        property_id: property.id,
      };
      request
        .post(`${baseURL}company/${company.id}/rede-vistoria`, data)
        .then((response) => {
          return onSuccess(true, response);
        })
        .catch((error) => {
          console.log(error)
          return onSuccess(true, response);
        });
    });

    this.setState({rvLoaded: true})

    // ID elemento html onde o formulario deve ser montado.
    rv('init', 'sdk-rv');
  }
  

};
